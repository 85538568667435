import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';

interface IModalProps extends DialogProps {
  title: string;
  toolbar?: React.ReactNode;
  onCloseModal: (event?: React.SyntheticEvent) => void;
  children: React.ReactNode | React.ReactNodeArray;
}
export function Modal({ title, toolbar, onCloseModal, children, ...props }: IModalProps) {
  const classes = useStyles();
  return (
    <Dialog {...props}>
      <DialogTitle classes={{ root: classes.title }}>
        <Box display="flex" alignItems="center">
          <Box fontSize="1.75rem" fontWeight="bold">
            {title}
          </Box>
          <Box ml="auto" alignSelf="flex-start">
            <IconButton aria-label="close" size="medium" onClick={onCloseModal} data-test="closeButton">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>{children}</DialogContent>
      {!!toolbar && <DialogActions>{toolbar}</DialogActions>}
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(0, 4),
  },
}));
