import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import Typography from '@material-ui/core/Typography';

import { ListWrapper } from '../../Layouts/ListWrapper';

export function EmptyDocumentsList() {
  const { t } = useTranslation();

  return (
    <ListWrapper>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={6}
      >
        <FormatListBulletedRoundedIcon style={{ fontSize: 88, color: '#E0E0E0' }} />
        <Typography align="center" color="textSecondary">
          {t('documents:empty')}
        </Typography>
      </Box>
    </ListWrapper>
  );
}
