import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { ContentItemView, GroupFolderControllerApiFactory } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

type MembersByDateListProviderType = QueryObserverResult<ContentItemView[]>;

const MembersByDateListContext = createContext<MembersByDateListProviderType | null>(null);
MembersByDateListContext.displayName = `${upperFirst(RESOURCE_NAME)}ListByDate`;

export function useMembersByDateListProvider(): MembersByDateListProviderType {
  const contextState = useContext(MembersByDateListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMembersByDateListProvider.name} must be used within a ${MembersByDateListContext.displayName} context`
    );
  }
  return contextState;
}

interface MembersByDateListProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  date: string;
  queryOptions?: UseQueryOptions<ContentItemView[]>;
}

export function MembersByDateListProvider({
  businessUnitId,
  sectionId,
  groupFolderId,
  date,
  queryOptions,
  children,
}: React.PropsWithChildren<MembersByDateListProviderProps>) {
  const value = useQuery<ContentItemView[]>(
    [RESOURCE_NAME, 'listByDate', { businessUnitId, sectionId, groupFolderId, date }],
    async () => {
      return GroupFolderApi.getContentForDate(businessUnitId, sectionId, groupFolderId, date).then(resp => resp.data);
    },
    queryOptions
  );
  return <MembersByDateListContext.Provider value={value}>{children}</MembersByDateListContext.Provider>;
}
