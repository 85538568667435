import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { SearchResult } from './SearchResult';
import React, { useState } from 'react';
import { DocumentSearchResultItem } from './DocumentSearchResultItem';
import { SearchDocumentProvider } from 'api/SearchProviders';
import { DocumentsSearchMoreResults } from './DocumentsSearchMoreResults';

export function DocumentsSearchResults({ searchText, data }: any) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  if (data.documents.length === 0) {
    return null;
  }

  const more = () => {
    setShowMore(true);
  };

  const hide = () => {
    setShowMore(false);
  };

  return (
    <>
      <SearchResult
        title={t('common:searchResults.documents')}
        hasMore={data.hasMoreDocuments}
        onShowMore={more}
        onHideMore={hide}
        showMore={showMore}
      />
      {data.documents.map((document: any, i: number) => (
        <Box key={i}>
          <DocumentSearchResultItem document={document} />
        </Box>
      ))}
      {showMore && (
        <Box>
          <SearchDocumentProvider searchText={searchText}>
            <DocumentsSearchMoreResults />
          </SearchDocumentProvider>
        </Box>
      )}
    </>
  );
}
