import React, { Ref } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';

import { getDocumentIconURL, getFolderIconURL } from 'api/http';
import { ContentItemView, UserSessionStateViewRoleEnum } from 'api/generated';
import { useAuthProvider } from 'api/AuthProviders';
import { i18nDateFnsLocaleMap } from 'i18n';
import { getUrlFilter } from 'utils';

import { FolderActionsButton } from '../ActionButtons/FolderActionsButton';

import compact from 'lodash/fp/compact';
import dropRight from 'lodash/fp/dropRight';
import last from 'lodash/fp/last';
import split from 'lodash/fp/split';
import format from 'date-fns/format';

interface DocumentsListItemFolderProps {
  listItem: ContentItemView;
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
  forwardedRef?: Ref<HTMLAnchorElement>;
}

function DocumentsListItemFolder({
  listItem,
  businessUnitId,
  sectionId,
  parentFolderId,
  forwardedRef = null,
}: DocumentsListItemFolderProps) {
  const classes = useFolderItemStyles();
  const location = useLocation();
  const { role } = useAuthProvider();
  const {
    i18n: { language },
  } = useTranslation();
  const basePath = getBasePath(location.pathname);

  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;
  const isResponsible = role === UserSessionStateViewRoleEnum.RESPONSIBLE;

  const formatOptions = {
    locale: i18nDateFnsLocaleMap[language],
    weekStartsOn: 1 as any,
    addSuffix: true,
  };

  return (
    <Link to={`${basePath}/parentFolderId/${listItem.id}`} ref={forwardedRef}>
      <ListItem classes={{ root: classes.root }} button>
        <ListItemAvatar classes={{ root: classes.icon }}>
          <img
            width={32}
            alt=""
            src={
              listItem.dataType === 'DOCUMENT'
                ? getDocumentIconURL(businessUnitId, sectionId, listItem.id)
                : getFolderIconURL(businessUnitId, sectionId, listItem.id)
            }
            className={classes.iconImage}
          />
        </ListItemAvatar>
        <ListItemText classes={{ primary: classes.info }}>
          <Box display="flex" alignItems="center" component="span" width="100%">
            <Box component="span" flexGrow={1}>
              {listItem.title}
            </Box>
            <Box className={classes.date}>
              {format(new Date(listItem.lastActivityDate!), 'dd.MM.yy HH:mm', formatOptions)}
            </Box>
          </Box>
        </ListItemText>
        {(isAdmin || isResponsible) && (
          <ListItemSecondaryAction className={classes.actions}>
            <FolderActionsButton
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              folderId={listItem.id}
              parentFolderId={parentFolderId}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Divider />
    </Link>
  );
}

export default React.forwardRef((props: DocumentsListItemFolderProps, ref) => (
  <DocumentsListItemFolder {...props} forwardedRef={ref as Ref<HTMLAnchorElement> | undefined} />
));

export const useFolderItemStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    padding: theme.spacing(1.25, 2.25),
    height: 56,
  },
  icon: {
    minWidth: 48,
    display: 'flex',
    alignItems: 'center',
  },
  iconImage: {},
  info: {
    fontSize: theme.typography.subtitle1.fontSize,
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(5.5),
    fontSize: theme.typography.body2.fontSize,
  },
  actions: {
    paddingLeft: theme.spacing(2),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// utils
function getBasePath(pathname: string) {
  const paths = compact(split('/', pathname));
  const lastPath = last(paths) || '';
  const filter = getUrlFilter(lastPath);
  let basePath = pathname;
  if (paths.includes('parentFolderId')) {
    basePath = dropRight(2, paths).join('/');
    return `/${basePath}${filter ? `?${filter}` : ''}`;
  }
  return pathname;
}
