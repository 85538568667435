import format from 'date-fns/format';
import ruDateFnsLocale from 'date-fns/locale/ru';
import enDateFnsLocale from 'date-fns/locale/en-US';

import DefaultDateFnsUtils from '@date-io/date-fns';

export const i18nDateFnsLocaleMap: { [key: string]: Locale } = {
  en: enDateFnsLocale,
  ru: ruDateFnsLocale,
  'ru-RU': ruDateFnsLocale,
  'en-US': enDateFnsLocale,
};

export class DateFnsUtils extends DefaultDateFnsUtils {
  getDatePickerHeaderText(date: any) {
    return format(date, 'dd MMMM yyyy', { locale: this.locale });
  }
  getCalendarHeaderText(date: any) {
    return format(date, 'LLLL yyyy', { locale: this.locale });
  }
}
