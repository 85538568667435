import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { ApiConfiguration, FileApiConfiguration, invalidateFallbackImageCache } from 'api/http';
import { AccountControllerApiFactory, AccountWithUserUpdateRequest, FileControllerApiFactory } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

export type AccountWithUserAndPhotoUpdateRequest = {
  photo?: File;
} & AccountWithUserUpdateRequest;

type AccountUpdateProviderType = UseMutationResult<
  AccountWithUserUpdateRequest,
  AxiosError<unknown>,
  AccountWithUserAndPhotoUpdateRequest,
  unknown
>;

const AccountUpdateContext = createContext<AccountUpdateProviderType | null>(null);
AccountUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}Update`;

export function useAccountUpdateProvider(): AccountUpdateProviderType {
  const contextState = useContext(AccountUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useAccountUpdateProvider.name} must be used within a ${AccountUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface AccountUpdateProviderProps {
  queryOptions?: UseMutationOptions<
    AccountWithUserUpdateRequest,
    AxiosError<unknown>,
    AccountWithUserAndPhotoUpdateRequest,
    unknown
  >;
}
export function AccountUpdateProvider(props: React.PropsWithChildren<AccountUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const updateDefaultOptions: AccountUpdateProviderProps['queryOptions'] = {
    onSuccess: (user, variables) => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries([RESOURCE_NAME, { id: user.accountId }]);
      if (variables.photo) {
        invalidateFallbackImageCache();
      }
    },
  };
  const update = useMutation<AccountWithUserUpdateRequest, AxiosError<unknown>, AccountWithUserAndPhotoUpdateRequest>(
    async (data: AccountWithUserAndPhotoUpdateRequest) => {
      if (data.photo) {
        const photo = data.photo;
        const file = await FileApi.upload(photo, photo.name).then(resp => resp.data);
        data.photoId = file.id;
      }
      const params = omit(data, ['photo']);
      return AccountApi.update(params).then(resp => resp.data);
    },
    {
      ...updateDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <AccountUpdateContext.Provider value={update}>{props.children}</AccountUpdateContext.Provider>;
}
