import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getFileOfDocumentURL } from 'api/http';
import { DocumentMetaUpdateRequestLanguageEnum } from 'api/generated';
import {
  DocumentArchiveProvider,
  DocumentDeleteProvider,
  DocumentDetailsWithUpdateProvider,
  DocumentUpdateProvider,
  DocumentWithFilesUpdateRequest,
  useDocumentArchiveProvider,
  useDocumentDetailsWithUpdateProvider,
  useDocumentUpdateProvider,
} from 'api/DocumentProviders/Documents';
import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { DocumentForm, DocumentFormValues } from './DocumentForm';
import { DocumentRequiredRouterParams } from './interfaces';

import find from 'lodash/find';
import { isFileMetaView } from 'api/DocumentProviders/Documents/type-helpers';
import { DeleteDocumentConfirmDialog } from './DeleteDocumentConfirmDialog';

export function EditDocumentDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<DocumentRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const documentId = parseInt(match.params.documentId || '', 10);

  //NOTE: '3' means go up to three parents '*/document/:documentId/edit' from current url
  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url, 3));

  return (
    <Modal
      title={t('documents:dialog.editTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={EditDocumentDialog.name}
    >
      <DocumentDetailsWithUpdateProvider businessUnitId={businessUnitId} sectionId={sectionId} documentId={documentId}>
        <DocumentUpdateProvider
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          documentId={documentId}
          queryOptions={{ onError: catchError }}
        >
          <DocumentArchiveProvider
            businessUnitId={businessUnitId}
            sectionId={sectionId}
            documentId={documentId}
            queryOptions={{ onError: catchError }}
          >
            <DocumentUpdate businessUnitId={businessUnitId} sectionId={sectionId} documentId={documentId} />
          </DocumentArchiveProvider>
        </DocumentUpdateProvider>
      </DocumentDetailsWithUpdateProvider>
    </Modal>
  );
}

interface DocumentUpdateProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
}
function DocumentUpdate({ businessUnitId, sectionId, documentId }: DocumentUpdateProps) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    controllers: {
      archive: { mutateAsync: archive },
      unarchive: { mutateAsync: unarchive },
    },
  } = useDocumentArchiveProvider();

  const { mutateAsync } = useDocumentUpdateProvider();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { data: document } = useDocumentDetailsWithUpdateProvider();

  const ruDocumentMeta = find(document?.documentMetas, { language: DocumentMetaUpdateRequestLanguageEnum.RUSSIAN });
  const enDocumentMeta = find(document?.documentMetas, { language: DocumentMetaUpdateRequestLanguageEnum.ENGLISH });

  const initialValues: DocumentFormValues = {
    name: ruDocumentMeta?.title || '',
    nameEn: enDocumentMeta?.title || '',
    pdfFile: ruDocumentMeta?.pdfFileMetaView
      ? {
          ...ruDocumentMeta?.pdfFileMetaView,
          downloadUrl: getFileOfDocumentURL(businessUnitId, sectionId, documentId, ruDocumentMeta?.pdfFileMetaView.id),
        }
      : undefined,
    originalFile: ruDocumentMeta?.originalFileMetaView
      ? {
          ...ruDocumentMeta?.originalFileMetaView,
          downloadUrl: getFileOfDocumentURL(
            businessUnitId,
            sectionId,
            documentId,
            ruDocumentMeta?.originalFileMetaView.id
          ),
        }
      : undefined,
    pdfFileEn: enDocumentMeta?.pdfFileMetaView
      ? {
          ...enDocumentMeta?.pdfFileMetaView,
          downloadUrl: getFileOfDocumentURL(businessUnitId, sectionId, documentId, enDocumentMeta?.pdfFileMetaView.id),
        }
      : undefined,
    originalFileEn: enDocumentMeta?.originalFileMetaView
      ? {
          ...enDocumentMeta?.originalFileMetaView,
          downloadUrl: getFileOfDocumentURL(
            businessUnitId,
            sectionId,
            documentId,
            enDocumentMeta?.originalFileMetaView.id
          ),
        }
      : undefined,
    status: document?.status,
  };

  const onSubmit = useCallback(
    async (values: DocumentFormValues) => {
      const documentMetas: DocumentWithFilesUpdateRequest['documentMetas'] = [];
      if (values.pdfFile || values.originalFile) {
        documentMetas.push({
          title: values.name,
          language: DocumentMetaUpdateRequestLanguageEnum.RUSSIAN,
          ...(values.pdfFile
            ? isFileMetaView(values.pdfFile)
              ? { pdfFileMetaView: values.pdfFile }
              : { pdfFile: values.pdfFile }
            : {}),
          ...(values.originalFile
            ? isFileMetaView(values.originalFile)
              ? { originalFileMetaView: values.originalFile }
              : { originalFile: values.originalFile }
            : {}),
        });
      }
      if (values.pdfFileEn || values.originalFileEn) {
        documentMetas.push({
          title: values.nameEn,
          language: DocumentMetaUpdateRequestLanguageEnum.ENGLISH,
          ...(values.pdfFileEn
            ? isFileMetaView(values.pdfFileEn)
              ? { pdfFileMetaView: values.pdfFileEn }
              : { pdfFile: values.pdfFileEn }
            : {}),
          ...(values.originalFileEn
            ? isFileMetaView(values.originalFileEn)
              ? { originalFileMetaView: values.originalFileEn }
              : { originalFile: values.originalFileEn }
            : {}),
        });
      }
      return mutateAsync({ documentMetas, version: document!.version }).then(() =>
        history.push(`${makeParentUrl(match.url)}/view`)
      );
    },
    [document, history, match.url, mutateAsync]
  );

  const onArchive = useCallback(() => {
    if (document) {
      archive(document.version).then(() => history.push(makeParentUrl(match.url, 3)));
    }
  }, [archive, history, match.url, document]);

  const onUnarchive = useCallback(() => {
    if (document) {
      unarchive(document.version).then(() => history.push(makeParentUrl(match.url, 3)));
    }
  }, [history, match.url, document, unarchive]);

  const handleRemove = useCallback(() => {
    if (document) {
      setOpenConfirm(true);
    }
  }, [document]);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const onDelete = useCallback(() => {
    setOpenConfirm(false);
    history.push(makeParentUrl(match.url, 3));
  }, [history, match.url]);

  return (
    <>
      <DocumentForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitLabel={t('documents:form.save')}
        onArchive={onArchive}
        archiveLabel={t('documents:form.archive')}
        onUnarchive={onUnarchive}
        unarchiveLabel={t('documents:form.unarchive')}
        removeLabel={t('documents:actionsMenu.remove')}
        onRemove={handleRemove}
      />
      <DocumentDeleteProvider businessUnitId={businessUnitId} sectionId={sectionId} documentId={documentId}>
        <DeleteDocumentConfirmDialog open={openConfirm} onDelete={onDelete} onClose={closeConfirmDialog} />
      </DocumentDeleteProvider>
    </>
  );
}
