/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SecretaryCreateRequest } from '../views';
// @ts-ignore
import { SecretaryUpdateRequest } from '../views';
// @ts-ignore
import { UserShortView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create the secretary (not primary user)
     * @param {SecretaryCreateRequest} secretaryCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSecretary: async (
      secretaryCreateRequest: SecretaryCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'secretaryCreateRequest' is not null or undefined
      assertParamExists('createSecretary', 'secretaryCreateRequest', secretaryCreateRequest);
      const localVarPath = `/usermanager/user/secretary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        secretaryCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark user as deleted
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteUser', 'id', id);
      const localVarPath = `/usermanager/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all secretaries of the account
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretaries: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('getSecretaries', 'accountId', accountId);
      const localVarPath = `/usermanager/user/{accountId}/secretary`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for the specified secretary
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretaryUpdateRequestById: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSecretaryUpdateRequestById', 'id', id);
      const localVarPath = `/usermanager/user/secretary/{id}/edit`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lock user
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('lockUser', 'id', id);
      const localVarPath = `/usermanager/user/secretary/{id}/lock`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlock user
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('unlockUser', 'id', id);
      const localVarPath = `/usermanager/user/secretary/{id}/unlock`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the secretary (should be used to not primary user updating )
     * @param {SecretaryUpdateRequest} secretaryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (secretaryUpdateRequest: SecretaryUpdateRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'secretaryUpdateRequest' is not null or undefined
      assertParamExists('updateUser', 'secretaryUpdateRequest', secretaryUpdateRequest);
      const localVarPath = `/usermanager/user/secretary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        secretaryUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create the secretary (not primary user)
     * @param {SecretaryCreateRequest} secretaryCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSecretary(
      secretaryCreateRequest: SecretaryCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretaryUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSecretary(secretaryCreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Mark user as deleted
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get all secretaries of the account
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSecretaries(
      accountId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretaries(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for the specified secretary
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSecretaryUpdateRequestById(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretaryUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretaryUpdateRequestById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Lock user
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockUser(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretaryUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lockUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Unlock user
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlockUser(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretaryUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlockUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the secretary (should be used to not primary user updating )
     * @param {SecretaryUpdateRequest} secretaryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      secretaryUpdateRequest: SecretaryUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretaryUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(secretaryUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Create the secretary (not primary user)
     * @param {SecretaryCreateRequest} secretaryCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSecretary(
      secretaryCreateRequest: SecretaryCreateRequest,
      options?: any
    ): AxiosPromise<SecretaryUpdateRequest> {
      return localVarFp.createSecretary(secretaryCreateRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Mark user as deleted
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: number, options?: any): AxiosPromise<number> {
      return localVarFp.deleteUser(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all secretaries of the account
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretaries(accountId: number, options?: any): AxiosPromise<Array<UserShortView>> {
      return localVarFp.getSecretaries(accountId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for the specified secretary
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecretaryUpdateRequestById(id: number, options?: any): AxiosPromise<SecretaryUpdateRequest> {
      return localVarFp.getSecretaryUpdateRequestById(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lock user
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockUser(id: number, options?: any): AxiosPromise<SecretaryUpdateRequest> {
      return localVarFp.lockUser(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unlock user
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockUser(id: number, options?: any): AxiosPromise<SecretaryUpdateRequest> {
      return localVarFp.unlockUser(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the secretary (should be used to not primary user updating )
     * @param {SecretaryUpdateRequest} secretaryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(secretaryUpdateRequest: SecretaryUpdateRequest, options?: any): AxiosPromise<SecretaryUpdateRequest> {
      return localVarFp.updateUser(secretaryUpdateRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UserControllerApi - interface
 * @export
 * @interface UserControllerApi
 */
export interface UserControllerApiInterface {
  /**
   *
   * @summary Create the secretary (not primary user)
   * @param {SecretaryCreateRequest} secretaryCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  createSecretary(secretaryCreateRequest: SecretaryCreateRequest, options?: any): AxiosPromise<SecretaryUpdateRequest>;

  /**
   *
   * @summary Mark user as deleted
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  deleteUser(id: number, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Get all secretaries of the account
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  getSecretaries(accountId: number, options?: any): AxiosPromise<Array<UserShortView>>;

  /**
   *
   * @summary Get the \'UpdateRequest\' for the specified secretary
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  getSecretaryUpdateRequestById(id: number, options?: any): AxiosPromise<SecretaryUpdateRequest>;

  /**
   *
   * @summary Lock user
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  lockUser(id: number, options?: any): AxiosPromise<SecretaryUpdateRequest>;

  /**
   *
   * @summary Unlock user
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  unlockUser(id: number, options?: any): AxiosPromise<SecretaryUpdateRequest>;

  /**
   *
   * @summary Update the secretary (should be used to not primary user updating )
   * @param {SecretaryUpdateRequest} secretaryUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  updateUser(secretaryUpdateRequest: SecretaryUpdateRequest, options?: any): AxiosPromise<SecretaryUpdateRequest>;
}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI implements UserControllerApiInterface {
  /**
   *
   * @summary Create the secretary (not primary user)
   * @param {SecretaryCreateRequest} secretaryCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public createSecretary(secretaryCreateRequest: SecretaryCreateRequest, options?: any) {
    return UserControllerApiFp(this.configuration)
      .createSecretary(secretaryCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark user as deleted
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public deleteUser(id: number, options?: any) {
    return UserControllerApiFp(this.configuration)
      .deleteUser(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all secretaries of the account
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getSecretaries(accountId: number, options?: any) {
    return UserControllerApiFp(this.configuration)
      .getSecretaries(accountId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the \'UpdateRequest\' for the specified secretary
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getSecretaryUpdateRequestById(id: number, options?: any) {
    return UserControllerApiFp(this.configuration)
      .getSecretaryUpdateRequestById(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lock user
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public lockUser(id: number, options?: any) {
    return UserControllerApiFp(this.configuration)
      .lockUser(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlock user
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public unlockUser(id: number, options?: any) {
    return UserControllerApiFp(this.configuration)
      .unlockUser(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the secretary (should be used to not primary user updating )
   * @param {SecretaryUpdateRequest} secretaryUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateUser(secretaryUpdateRequest: SecretaryUpdateRequest, options?: any) {
    return UserControllerApiFp(this.configuration)
      .updateUser(secretaryUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
