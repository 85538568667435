import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ProfileIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="76" height="76" viewBox="0 0 76 76" fill="none" {...props}>
      <path
        d="M38.0037 71.7452L53.0596 67.9431C61.6534 60.8071 67.1272 50.0436 67.1272 38.0004C67.1272 19.4244 54.1075 3.88934 36.6957 0.0223999C16.3128 0.709814 0 17.45 0 38.0003C0 48.556 4.30469 58.1055 11.2533 64.9916L38.0037 71.7452Z"
        fill="#F5F6FA"
      />
      <path
        d="M66.3388 38C66.3388 50.4561 60.3448 61.5112 51.0847 68.4414L64.7455 64.9916C71.6946 58.1056 76.0001 48.556 76.0001 38C76.0001 17.0132 58.9869 0 38 0C36.3637 0 34.7513 0.103609 33.1694 0.304297C51.8754 2.67752 66.3388 18.6497 66.3388 38Z"
        fill="#F5F6FA"
      />
      <path
        d="M47.444 14.3913C47.444 12.4505 45.8707 10.8772 43.9299 10.8772H31.82C27.3534 10.8772 23.7324 14.4982 23.7324 18.9648V27.9442H52.2635V19.0922C52.2635 17.1514 50.6902 15.5781 48.7493 15.5781H48.6309C47.9754 15.5781 47.444 15.0468 47.444 14.3913Z"
        fill="#C9C4CC"
      />
      <path
        d="M48.7493 15.5779H48.6308C47.9753 15.5779 47.4439 15.0465 47.4439 14.391C47.4439 12.4502 45.8706 10.8769 43.9298 10.8769H42.6021V27.9439H52.2632V19.0919C52.2634 17.1513 50.69 15.5779 48.7493 15.5779Z"
        fill="#BCB7BF"
      />
      <path
        d="M63.0927 55.2712C62.1637 54.3535 61.0145 53.6439 59.7099 53.2309L49.3354 49.9463L38.0025 49.548L26.6649 49.9463L16.2904 53.2309C12.7808 54.3421 10.3955 57.5991 10.3955 61.2805V64.1148C17.0439 71.14 26.3453 75.6288 36.6961 75.9779C48.2932 73.4021 57.9418 65.6502 63.0927 55.2712Z"
        fill="#C9C4CC"
      />
      <path
        d="M65.6048 64.1149V61.2806C65.6048 58.6776 64.4114 56.2879 62.472 54.7178C56.9634 65.9435 46.0813 74.0544 33.1785 75.6945C34.7359 75.8918 36.3221 75.997 37.9323 75.9999C39.4795 75.9975 41.0062 75.9078 42.5078 75.7353C51.5671 74.6648 59.6501 70.4073 65.6048 64.1149Z"
        fill="#C9C4CC"
      />
      <path
        d="M38.8604 75.4311C41.1595 70.8665 47.1464 57.9055 45.4393 49.8094L38.0024 46.915L30.5585 49.8096C28.7987 58.1555 35.215 71.671 37.3393 75.8292C37.8502 75.7066 38.3574 75.5737 38.8604 75.4311Z"
        fill="#EEECF0"
      />
      <path
        d="M42.7792 71.8122L39.7639 59.9495H36.236L33.228 71.7798L36.9476 75.9208C37.8918 75.7045 38.8229 75.4538 39.7397 75.1701L42.7792 71.8122Z"
        fill="#BCB7BF"
      />
      <path
        d="M37.0061 75.9858C37.3141 75.9938 37.6227 75.9993 37.9325 75.9999C38.291 75.9993 38.6482 75.9931 39.0045 75.9832L40.9204 73.8666C39.4124 74.3956 37.8611 74.8323 36.2729 75.1697L37.0061 75.9858Z"
        fill="#BCB7BF"
      />
      <path
        d="M44.3495 48.7144V42.0249H31.6462V48.7144C31.6462 52.2223 37.9979 56.054 37.9979 56.054C37.9979 56.054 44.3495 52.2223 44.3495 48.7144Z"
        fill="#F5F3F3"
      />
      <path
        d="M52.2634 26.7657H49.8352C48.4847 26.7657 47.3899 25.6709 47.3899 24.3205V22.9823C47.3899 21.2232 45.6688 19.9794 43.9986 20.5314C40.1031 21.819 35.897 21.8193 32.0014 20.5323L31.9968 20.5309C30.3266 19.9791 28.6058 21.2229 28.6058 22.9819V24.3206C28.6058 25.6711 27.511 26.7658 26.1606 26.7658H23.7324V30.7644C23.7324 31.7861 24.5607 32.6146 25.5825 32.6146C25.905 32.6146 26.1672 32.867 26.1876 33.1889C26.5777 39.374 31.7146 44.2703 37.998 44.2703C44.2814 44.2703 49.4183 39.374 49.8084 33.1889C49.8288 32.8672 50.0911 32.6146 50.4135 32.6146C51.4352 32.6146 52.2636 31.7863 52.2636 30.7644V26.7657H52.2634Z"
        fill="white"
      />
      <path
        d="M49.8352 26.7656C48.4847 26.7656 47.39 25.6709 47.39 24.3204V22.9823C47.39 21.2231 45.6688 19.9794 43.9986 20.5314C43.5373 20.6838 43.0713 20.8168 42.6022 20.9332V34.14C42.5372 38.437 40.1827 42.1767 36.7056 44.1978C37.1302 44.244 37.561 44.27 37.9979 44.27C44.2812 44.27 49.4182 39.3736 49.8083 33.1885C49.8286 32.8669 50.0909 32.6142 50.4133 32.6142C51.435 32.6142 52.2635 31.7859 52.2635 30.7641V26.7655H49.8352V26.7656Z"
        fill="white"
      />
      <path
        d="M31.6463 46.915L26.665 50.9625L31.8889 57.4214C32.5243 58.0401 33.5245 58.0781 34.2052 57.5096L38.1336 54.2281L31.6463 46.915Z"
        fill="white"
      />
      <path
        d="M44.3519 46.915L38.1335 54.2281L41.8258 57.3851C42.5022 57.9634 43.5071 57.9343 44.149 57.3178L49.3354 50.9625L44.3519 46.915Z"
        fill="white"
      />
      <path
        d="M31.6461 46.915L24.3347 50.5071C23.7781 50.7805 23.5545 51.4577 23.8388 52.0088L25.2712 54.7861C25.6424 55.5057 25.5054 56.3827 24.9325 56.9548C24.6375 57.2491 24.3181 57.568 24.0412 57.8443C23.5458 58.3389 23.3678 59.0697 23.586 59.735C25.3154 65.007 33.6389 73.0688 36.7889 75.9856C37.0007 75.9913 37.213 75.9951 37.4257 75.9975C35.1414 71.5543 27.0467 54.6737 31.6461 46.915Z"
        fill="#BCB7BF"
      />
      <path
        d="M51.0653 56.9546C50.4922 56.3825 50.3553 55.5056 50.7265 54.7859L52.159 52.0087C52.4432 51.4575 52.2197 50.7804 51.663 50.5069L44.3516 46.915C48.9499 54.6716 40.8608 71.5451 38.574 75.9939C38.7889 75.9902 39.0034 75.9849 39.2174 75.9778C42.374 73.0543 50.6839 65.0023 52.4118 59.7351C52.63 59.0698 52.452 58.3391 51.9565 57.8445C51.6797 57.568 51.3602 57.249 51.0653 56.9546Z"
        fill="#BCB7BF"
      />
      <path d="M38.1334 54.228L34.2651 57.4586L36.2361 59.9495H39.764L41.7351 57.3116L38.1334 54.228Z" fill="#BCB7BF" />
    </SvgIcon>
  );
}

export default ProfileIcon;
