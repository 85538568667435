import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useBusinessUnitDeleteProvider } from 'api/BusinessUnitProviders';
import { DeleteConfirmDialog } from '../../../All/ConfirmDialogs';

interface DeleteProjectConfirmDialogProps {
  open: boolean;
  onClose: (event?: any) => void;
  onDelete: (event?: any) => void;
}

export function DeleteProjectConfirmDialog({ open, onClose, onDelete }: DeleteProjectConfirmDialogProps) {
  const { t } = useTranslation();

  const { mutateAsync: deleteProject, isLoading } = useBusinessUnitDeleteProvider();

  const handleRemove = useCallback(
    async event => {
      await deleteProject({}).then(() => {
        onDelete();
      });
    },
    [deleteProject, onDelete]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={handleRemove}
      title={t('businessUnit:deleteProjectConfirmDialog.title')}
      confirmText={t('businessUnit:deleteProjectConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}
