import React, { useCallback } from 'react';

import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';
import classnames from 'classnames';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { BusinessUnitShortView, BusinessUnitShortViewStatusEnum } from 'api/generated';
import { getBusinessUnitIconURL } from 'api/http';

import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { BusinessUnitStatus } from './BusinessUnitStatus';
import { Avatar } from '@material-ui/core';

export function SortableBusinessUnitListItem({
  currentBusinessUnitId,
  businessUnit,
  hasDnd,
  disabledDnd,
  basePath,
}: {
  currentBusinessUnitId: number | null;
  businessUnit: BusinessUnitShortView;
  hasDnd: boolean;
  disabledDnd: boolean;
  basePath: 'companies' | 'projects';
}) {
  const history = useHistory();
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: businessUnit.id.toString(),
    disabled: disabledDnd || !hasDnd,
  });

  const style = { transform: CSS.Transform.toString(transform), transition } as React.CSSProperties;

  const onSelectBusinessUnit = useCallback(() => {
    queryClient.invalidateQueries(`${basePath}SectionsList`);
    history.push(`/${basePath}/${businessUnit.id}`);
  }, [basePath, history, queryClient, businessUnit.id]);

  return (
    <ListItem
      button
      ref={setNodeRef}
      style={style}
      selected={currentBusinessUnitId === businessUnit.id}
      classes={{
        root: classnames(classes.item, { dragging: isDragging, [classes.draggableItem]: hasDnd }),
        gutters: hasDnd ? classes.guttersDraggable : undefined,
      }}
      onClick={onSelectBusinessUnit}
      {...attributes}
    >
      {hasDnd && (
        <ListItemIcon
          className={classnames(classes.itemIcon, { dragging: isDragging })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          {...listeners}
        >
          <DragIndicatorIcon />
        </ListItemIcon>
      )}
      <ListItemAvatar>
        <Avatar alt={businessUnit.title} src={getBusinessUnitIconURL(businessUnit.id)} className={classes.avatar} />
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.titleSpan }}
        primary={
          <Box
            component="span"
            color={businessUnit.status === BusinessUnitShortViewStatusEnum.ARCHIVED ? 'text.disabled' : 'text.primary'}
          >
            {businessUnit.title}
          </Box>
        }
        secondary={
          businessUnit.status === BusinessUnitShortViewStatusEnum.ARCHIVED ? (
            <BusinessUnitStatus status={businessUnit.status} />
          ) : null
        }
      />
    </ListItem>
  );
}
const useStyles = makeStyles(theme => ({
  avatar: {},
  item: {
    '&.dragging': {
      boxShadow: '0px 26px 32px rgba(0, 0, 0, 0.15)',
      zIndex: theme.zIndex.tooltip,
    },
  },
  draggableItem: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: '100%',
      backgroundColor: theme.palette.primary.dark,
      display: 'none',
    },
    '&:hover': {
      '&:before': {
        display: 'block',
      },
    },
    '&.dragging': {
      '&:before': {
        display: 'block',
      },
    },
  },
  itemIcon: {
    minWidth: 22,
    cursor: 'grab',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&.dragging': {
      cursor: 'grabbing',
    },
  },
  guttersDraggable: {
    paddingLeft: 0,
  },
  titleSpan: {
    width: '100%',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
