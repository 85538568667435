import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import { useAuthProvider } from 'api/AuthProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';

import { InventoryIcon } from 'components/icons';
import { personsAndGroupsHeaderHeight } from 'styles/constants';

export function PersonAndGroupsHeader({ parentFolderId }: { parentFolderId: number }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { role } = useAuthProvider();

  const canAddMemberPosition = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;

  const goToChangelogHandler = useCallback(() => {
    const query = new URLSearchParams(history.location.search);
    query.set('groupFolderId', parentFolderId.toString());
    history.push(`${url}/changelog?${query.toString()}`);
  }, [history, parentFolderId, url]);

  const goToAddMembersPositionHandler = useCallback(() => {
    const query = new URLSearchParams(history.location.search);
    query.set('groupFolderId', parentFolderId.toString());
    history.push(`${url}/add_member_position?${query.toString()}`);
  }, [history, parentFolderId, url]);

  return (
    <Box
      height={personsAndGroupsHeaderHeight}
      py={2}
      width="100%"
      color="grey.600"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {canAddMemberPosition && <AddMemberPositionButton onClick={goToAddMembersPositionHandler} />}
      <Box flexGrow={1} textAlign="right">
        <XSButton onClick={goToChangelogHandler} size="small" color="inherit" startIcon={<InventoryIcon />}>
          {t('persons:changelog')}
        </XSButton>
      </Box>
    </Box>
  );
}

export function AddMemberPositionButton({ onClick }: { onClick: React.MouseEventHandler }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box onClick={onClick} display="flex" alignItems="center" component="a">
      <IconButton classes={{ root: classes.icon }}>
        <AddIcon />
      </IconButton>
      <Box color="theme.palette.text.secondary">{t('persons:addMemberPosition')}</Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: '#83899B',
    backgroundColor: '#ECEEF3',
    marginRight: theme.spacing(2),
  },
}));

export const XSButton = withStyles(theme => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
  },
}))(Button);
