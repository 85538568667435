import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { PersonControllerApiFactory, PersonSimpleView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const PersonApi = PersonControllerApiFactory(ApiConfiguration);

type SimplePersonsListProviderType = QueryObserverResult<PersonSimpleView[]>;

const SimplePersonsListContext = createContext<SimplePersonsListProviderType | null>(null);
SimplePersonsListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useSimplePersonsListProvider(): SimplePersonsListProviderType {
  const contextState = useContext(SimplePersonsListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSimplePersonsListProvider.name} must be used within a ${SimplePersonsListContext.displayName} context`
    );
  }
  return contextState;
}

interface SimplePersonsListProviderProps {
  businessUnitId: number;
  queryOptions?: UseQueryOptions<PersonSimpleView[]>;
}

export function SimplePersonsListProvider(props: React.PropsWithChildren<SimplePersonsListProviderProps>) {
  const value = useQuery<PersonSimpleView[]>(
    RESOURCE_NAME,
    () => PersonApi.getPersonsByBusinessUnit2(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <SimplePersonsListContext.Provider value={value}>{props.children}</SimplePersonsListContext.Provider>;
}
