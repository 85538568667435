import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import { BusinessUnitSectionShortViewPermissionsEnum } from 'api/generated';
import { getSectionPreviewFileURL } from 'api/http';
import { useErrorCatcher } from 'api/notifications';
import {
  SectionAccessProvider,
  SectionAccessUpdateProvider,
  SectionInfoProvider,
  SectionUpdateProvider,
  useSectionAccessProvider,
  useSectionAccessUpdateProvider,
  useSectionInfoProvider,
  useSectionUpdateProvider,
} from 'api/SectionsProviders';
import { SectionUpdateRequest } from 'api/SectionsProviders/SectionUpdateProvider';

import { Modal, Spinner } from 'components/material';
import { SectionForm, SectionFormValues } from './SectionForm';
import { BusinessUnitPageRouterParams } from '../interfaces';

import has from 'lodash/has';
import omit from 'lodash/omit';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { getAccessInfoForRequest } from './helpers';

export function EditSectionDialog({
  open,
  onClose,
  sectionId,
  permissions,
}: {
  open: boolean;
  onClose: () => void;
  sectionId: number;
  permissions: BusinessUnitSectionShortViewPermissionsEnum[];
}) {
  const { t } = useTranslation();
  const [disabledCloseButton, setDisabledCloseButton] = React.useState(false);
  const catchError = useErrorCatcher();
  const match = useRouteMatch<BusinessUnitPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const action = (key: string) => (
    <Button onClick={() => closeSnackbar(key)} size="small" classes={{ label: classes.label }}>
      {t('common:ok')}
    </Button>
  );

  const handleClose = () => {
    if (disabledCloseButton) {
      enqueueSnackbar(t('projects:dialog.blockCloseDialogWhileDocumentLoading'), {
        variant: 'info',
        preventDuplicate: true,
        action,
      });
    } else {
      onClose();
    }
  };

  return (
    <EditSectionDialogContext.Provider value={{ disabledCloseButton, setDisabledCloseButton }}>
      <Modal
        title={t('businessUnit:dialog.sectionCard')}
        open={open}
        disableBackdropClick
        onCloseModal={handleClose}
        maxWidth="sm"
        fullWidth
        data-test={EditSectionDialog.name}
      >
        <SectionAccessUpdateProvider queryOptions={{ onError: catchError }}>
          <SectionAccessProvider
            businessUnitId={businessUnitId}
            sectionId={sectionId}
            queryOptions={{ onError: catchError }}
          >
            <SectionInfoProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              queryOptions={{ onError: catchError }}
            >
              <SectionUpdateProvider
                sectionId={sectionId}
                businessUnitId={businessUnitId}
                queryOptions={{ onError: catchError }}
              >
                <SectionUpdate
                  onClose={onClose}
                  businessUnitId={businessUnitId}
                  sectionId={sectionId}
                  permissions={permissions}
                />
              </SectionUpdateProvider>
            </SectionInfoProvider>
          </SectionAccessProvider>
        </SectionAccessUpdateProvider>
      </Modal>
    </EditSectionDialogContext.Provider>
  );
}

function SectionUpdate({
  onClose,
  businessUnitId,
  permissions,
  sectionId,
}: {
  onClose: () => void;
  businessUnitId: number;
  sectionId: number;
  permissions: BusinessUnitSectionShortViewPermissionsEnum[];
}) {
  const { setDisabledCloseButton } = React.useContext(EditSectionDialogContext);
  const { mutateAsync } = useSectionUpdateProvider();
  const { data: section, isLoading, isFetched } = useSectionInfoProvider();
  const {
    sectionAccess: {
      readerAccess: { data: readerList },
      responsibleAccess: { data: responsibleList },
    },
  } = useSectionAccessProvider();
  const {
    updateAccess: { mutateAsync: updateAccess },
  } = useSectionAccessUpdateProvider();

  const canRemove = permissions.includes(BusinessUnitSectionShortViewPermissionsEnum.DELETE_BUSINESS_UNIT_SECTION);
  const canEditTitles = permissions.includes(BusinessUnitSectionShortViewPermissionsEnum.UPDATE_BUSINESS_UNIT_SECTION);
  const canUpdateAccess = permissions.includes(
    BusinessUnitSectionShortViewPermissionsEnum.UPDATE_BUSINESS_UNIT_SECTION_ACCESS
  );
  const canUpdateResponsibleAccess = permissions.includes(
    BusinessUnitSectionShortViewPermissionsEnum.UPDATE_BUSINESS_UNIT_SECTION_RESPONSIBLE_ACCESS
  );

  const onSubmit = useCallback(
    async (formValues: SectionFormValues) => {
      const { reader: readerFields, responsible: responsibleFields, ...updateValues } = formValues;
      const tasks = [
        () =>
          mutateAsync({
            ...updateValues,
            sectionRestrictedAccess: readerFields.restrictedAccess,
            sectionRestrictedResponsibleAccess: responsibleFields.restrictedAccess,
            sectionPreviewEnFile: has(updateValues.sectionPreviewEnFile, 'downloadUrl')
              ? (omit(updateValues.sectionPreviewEnFile, 'downloadUrl') as typeof updateValues.sectionPreviewEnFile)
              : updateValues.sectionPreviewEnFile,
            sectionPreviewRuFile: has(updateValues.sectionPreviewRuFile, 'downloadUrl')
              ? (omit(updateValues.sectionPreviewRuFile, 'downloadUrl') as typeof updateValues.sectionPreviewEnFile)
              : updateValues.sectionPreviewRuFile,
          }),
        () =>
          updateAccess({
            readerAccess: getAccessInfoForRequest(readerFields, readerList || []),
            responsibleAccess: getAccessInfoForRequest(responsibleFields, responsibleList || []),
            sectionId,
            businessUnitId,
          }),
      ];
      setDisabledCloseButton(true);
      await tasks
        .reduce((p, fn) => p.then(() => fn()), Promise.resolve({}))
        .then(() => {
          onClose();
        });
      setDisabledCloseButton(false);
    },
    [setDisabledCloseButton, mutateAsync, updateAccess, readerList, sectionId, businessUnitId, responsibleList, onClose]
  );

  if (isLoading) {
    return (
      <Box height={296}>
        <Spinner />
      </Box>
    );
  }
  const initialValues = {
    ...omit(section, [
      'sectionPreviewEnFileMetaView',
      'sectionPreviewRuFileMetaView',
      // we use it in responsibleInfo below
      'sectionRestrictedResponsibleAccess',
      // we use it in readerInfo below
      'sectionRestrictedAccess',
    ]),
    sectionPreviewEnFile: section?.sectionPreviewEnFileMetaView
      ? {
          ...section.sectionPreviewEnFileMetaView,
          downloadUrl: getSectionPreviewFileURL(
            businessUnitId,
            section.sectionId,
            section.sectionPreviewEnFileMetaView.id
          ),
        }
      : undefined,
    sectionPreviewRuFile: section?.sectionPreviewRuFileMetaView
      ? {
          ...section.sectionPreviewRuFileMetaView,
          downloadUrl: getSectionPreviewFileURL(
            businessUnitId,
            section.sectionId,
            section.sectionPreviewRuFileMetaView.id
          ),
        }
      : undefined,
  } as SectionUpdateRequest;

  return section && isFetched ? (
    <SectionForm
      type="EDIT"
      onSubmit={onSubmit}
      editProps={{
        businessUnitId,
        onClose,
        initialValues,
        canRemove,
        canEditTitles,
        canUpdateAccess,
        canUpdateResponsibleAccess,
      }}
      readerInfo={{ list: readerList, restrictedAccess: section.sectionRestrictedAccess }}
      responsibleInfo={{ list: responsibleList, restrictedAccess: section.sectionRestrictedResponsibleAccess ?? true }}
    />
  ) : null;
}

const useStyles = makeStyles(theme => ({
  label: {
    color: '#ffffff',
    fontSize: '14px',
  },
}));

interface EditSectionDialogContextType {
  disabledCloseButton: boolean;
  setDisabledCloseButton: (flag: boolean) => void;
}

const EditSectionDialogContext = React.createContext<EditSectionDialogContextType>({
  disabledCloseButton: false,
  setDisabledCloseButton: () => {},
});
