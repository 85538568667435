import React from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'react-final-form';
import { Checkboxes, CheckboxData } from 'mui-rff';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { AccountViewAllowableRolesEnum } from 'api/generated';

import { required } from 'components/fields/validators';

export function Roles() {
  const { t } = useTranslation();

  const rolesData: CheckboxData[] = [
    {
      label: t(`accounts:roles.${AccountViewAllowableRolesEnum.ADMIN}` as const),
      value: AccountViewAllowableRolesEnum.ADMIN,
    },
    {
      label: t(`accounts:roles.${AccountViewAllowableRolesEnum.READER}` as const),
      value: AccountViewAllowableRolesEnum.READER,
    },
    {
      label: t(`accounts:roles.${AccountViewAllowableRolesEnum.RESPONSIBLE}` as const),
      value: AccountViewAllowableRolesEnum.RESPONSIBLE,
    },
  ];

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">{t('accounts:create.rolesSection')}</Typography>
      </Box>
      <Box display="flex">
        <Checkboxes
          name="allowableRoles"
          data={rolesData}
          color="primary"
          formGroupProps={{ row: true }}
          required
          fieldProps={{ validate: required }}
        />
      </Box>
    </Box>
  );
}

interface CheckRoleConditionProps {
  when: string;
  is: AccountViewAllowableRolesEnum;
}
export const CheckRoleCondition: React.FC<React.PropsWithChildren<CheckRoleConditionProps>> = ({
  when,
  is,
  children,
}) => (
  <Field<AccountViewAllowableRolesEnum[]> name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value.includes(is) ? children : null)}
  </Field>
);
