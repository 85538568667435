import React, { useCallback, useEffect, useState } from 'react';

import { Field, FieldInputProps } from 'react-final-form';
import { useDropzone } from 'react-dropzone';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import first from 'lodash/first';

interface IDropImageFieldProps {
  name: string;
  buttonLabel: string;
  DefaultIcon: React.ElementType;
  getUrl: () => string;
}

interface IDropzoneAvatarProps extends FieldInputProps<File> {
  buttonLabel?: string;
  getUrl: () => string;
  DefaultIcon: React.ElementType;
}

export function DropImageField({ name, ...rest }: IDropImageFieldProps) {
  return <Field name={name}>{({ input }) => <DropzoneAvatar {...input} {...rest} />}</Field>;
}

function DropzoneAvatar({ onChange, buttonLabel, getUrl, DefaultIcon }: IDropzoneAvatarProps) {
  const [filePreview, setFilePreview] = useState<string>();

  useEffect(() => {
    const url = getUrl();
    if (url) {
      setFilePreview(url);
    }
    // not to set new filePreview if form values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      filePreview && URL.revokeObjectURL(filePreview);
    };
  }, [filePreview]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = first(acceptedFiles);
      if (!file) {
        return;
      }
      setFilePreview(URL.createObjectURL(file));
      onChange(file);
    },
    [onChange]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
    noDragEventsBubbling: true,
  });

  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <DefaultIcon src={filePreview} alt="avatar" />
      </Box>
      {!!buttonLabel && (
        <Box ml={3}>
          <Button variant="outlined" onClick={open}>
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  dropzone: {
    outline: 'none',
    cursor: 'pointer',
  },
}));
