/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeRoleRequest } from '../views';
// @ts-ignore
import { UserSessionStateView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * MeControllerApi - axios parameter creator
 * @export
 */
export const MeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Changing role
     * @param {ChangeRoleRequest} changeRoleRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeRole: async (
      changeRoleRequest: ChangeRoleRequest,
      applicationPlatform?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'changeRoleRequest' is not null or undefined
      assertParamExists('changeRole', 'changeRoleRequest', changeRoleRequest);
      const localVarPath = `/me/changeRole`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (applicationPlatform !== undefined && applicationPlatform !== null) {
        localVarHeaderParameter['Application-Platform'] = String(applicationPlatform);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changeRoleRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns UserView of current user or origin user if impersonating has been made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns UserView of current user or origin user if impersonating has been made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe1: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MeControllerApi - functional programming interface
 * @export
 */
export const MeControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Changing role
     * @param {ChangeRoleRequest} changeRoleRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeRole(
      changeRoleRequest: ChangeRoleRequest,
      applicationPlatform?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionStateView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeRole(
        changeRoleRequest,
        applicationPlatform,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns UserView of current user or origin user if impersonating has been made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMe(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionStateView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns UserView of current user or origin user if impersonating has been made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMe1(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionStateView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMe1(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MeControllerApi - factory interface
 * @export
 */
export const MeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MeControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Changing role
     * @param {ChangeRoleRequest} changeRoleRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeRole(
      changeRoleRequest: ChangeRoleRequest,
      applicationPlatform?: string,
      options?: any
    ): AxiosPromise<UserSessionStateView> {
      return localVarFp
        .changeRole(changeRoleRequest, applicationPlatform, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns UserView of current user or origin user if impersonating has been made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe(options?: any): AxiosPromise<UserSessionStateView> {
      return localVarFp.getMe(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns UserView of current user or origin user if impersonating has been made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe1(options?: any): AxiosPromise<UserSessionStateView> {
      return localVarFp.getMe1(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MeControllerApi - interface
 * @export
 * @interface MeControllerApi
 */
export interface MeControllerApiInterface {
  /**
   *
   * @summary Changing role
   * @param {ChangeRoleRequest} changeRoleRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApiInterface
   */
  changeRole(
    changeRoleRequest: ChangeRoleRequest,
    applicationPlatform?: string,
    options?: any
  ): AxiosPromise<UserSessionStateView>;

  /**
   *
   * @summary Returns UserView of current user or origin user if impersonating has been made
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApiInterface
   */
  getMe(options?: any): AxiosPromise<UserSessionStateView>;

  /**
   *
   * @summary Returns UserView of current user or origin user if impersonating has been made
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApiInterface
   */
  getMe1(options?: any): AxiosPromise<UserSessionStateView>;
}

/**
 * MeControllerApi - object-oriented interface
 * @export
 * @class MeControllerApi
 * @extends {BaseAPI}
 */
export class MeControllerApi extends BaseAPI implements MeControllerApiInterface {
  /**
   *
   * @summary Changing role
   * @param {ChangeRoleRequest} changeRoleRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */
  public changeRole(changeRoleRequest: ChangeRoleRequest, applicationPlatform?: string, options?: any) {
    return MeControllerApiFp(this.configuration)
      .changeRole(changeRoleRequest, applicationPlatform, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns UserView of current user or origin user if impersonating has been made
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */
  public getMe(options?: any) {
    return MeControllerApiFp(this.configuration)
      .getMe(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns UserView of current user or origin user if impersonating has been made
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */
  public getMe1(options?: any) {
    return MeControllerApiFp(this.configuration)
      .getMe1(options)
      .then(request => request(this.axios, this.basePath));
  }
}
