import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitSectionControllerApiFactory, BusinessUnitSectionShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const SectionsApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

type SectionsProviderType = QueryObserverResult<BusinessUnitSectionShortView[], unknown>;

const SectionsContext = createContext<SectionsProviderType | null>(null);
SectionsContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useSectionsProvider(): SectionsProviderType {
  const contextState = useContext(SectionsContext);
  if (isNil(contextState)) {
    throw new Error(`${useSectionsProvider.name} must be used within a ${SectionsContext.displayName} context`);
  }
  return contextState;
}

interface SectionsProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function SectionsProvider(props: React.PropsWithChildren<SectionsProviderProps>) {
  const value = useQuery<BusinessUnitSectionShortView[], AxiosError<unknown>, BusinessUnitSectionShortView[]>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId }],
    () => SectionsApi.getBusinessUnitSections2(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <SectionsContext.Provider value={value}>{props.children}</SectionsContext.Provider>;
}
