import React, { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useAuthProvider } from 'api/AuthProviders';
import { useDocumentsListProvider } from 'api/DocumentProviders/DocumentsListProvider';
import { useFolderShowProvider } from 'api/DocumentProviders/Folders';
import { useSectionShowProvider } from 'api/SectionsProviders';
import { ContentItemView, UserSessionStateViewRoleEnum } from 'api/generated';

import { PaperIcon } from 'components/icons';
import { ListWrapper } from 'pages/Layouts/ListWrapper';
import { makeParentUrl } from 'utils';

import { AddEntityButton } from '../ActionButtons/AddEntityButton';
import { DocumentsList } from '../DocumentsList';
import { useColumnStyles } from './styles';

import find from 'lodash/find';
import flatten from 'lodash/flatten';
import last from 'lodash/last';

export function FolderDocumentsColumn({
  businessUnitId,
  sectionId,
  parentFolderId,
}: {
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
}) {
  const classes = useColumnStyles();
  const { t } = useTranslation();

  const match = useRouteMatch();
  const history = useHistory();

  const { data: folder } = useFolderShowProvider();
  const { data: section } = useSectionShowProvider();
  const { data: docListData } = useDocumentsListProvider();

  const { role } = useAuthProvider();

  const canAddFolders =
    role === UserSessionStateViewRoleEnum.ADMIN ||
    role === UserSessionStateViewRoleEnum.ROOT ||
    role === UserSessionStateViewRoleEnum.RESPONSIBLE;

  // NOTE: if root level contains group then go into this group
  useEffect(() => {
    const documents = flatten(docListData?.pages);
    const group = find<ContentItemView[]>(documents, { dataType: 'GROUP' }) as ContentItemView | undefined;
    if (group) {
      history.replace(`${match.url}/parentFolderId/${group.id}`);
    }
  }, [docListData, history, match.url]);

  const goBack = useCallback(() => {
    const folderParentId = last(folder?.path);
    // если есть родительская папка, переход вверх с заменой parentFolderId в урле,
    // если нет, переход в раздел c вырезкой папки в урле
    if (folderParentId) {
      history.push(`${makeParentUrl(match.url)}/${folderParentId}`);
    } else if (parentFolderId && !folder) {
      history.push(makeParentUrl(match.url, 4));
    } else {
      history.push(makeParentUrl(match.url, 2));
    }
  }, [folder, history, match.url, parentFolderId]);

  const showPreviewHandler = useCallback(() => {
    if (section?.sectionPreviewFileMetaView) {
      history.push(`${match.url}/section_preview`, {
        businessUnitId: section.businessUnitId,
        sectionId: section.sectionId,
        previewFileId: section.sectionPreviewFileMetaView.id,
        hideGoToList: true,
      });
    }
  }, [history, match.url, section]);

  return (
    <ListWrapper>
      <Box className={classes.backToolbar}>
        <Button className={classes.backBtn} startIcon={<ArrowBackIosIcon />} size="small" onClick={() => goBack()}>
          {`${t('documents:back')}`}
        </Button>
      </Box>
      <Box className={classes.header}>
        <Box flexGrow="1" display="flex" alignItems="center" justifyContent="space-between">
          <Box className={classes.title} component="span">
            {folder?.title || section?.sectionTitle || ''}
          </Box>
          <Box display="flex" alignItems="center">
            {section?.sectionPreviewFileMetaView && (
              <Box mr={3} display="flex" alignItems="center">
                <ButtonBase onClick={showPreviewHandler} centerRipple>
                  <PaperIcon fontSize="large" />
                </ButtonBase>
              </Box>
            )}
            {canAddFolders && <AddEntityButton />}
          </Box>
        </Box>
      </Box>
      <Box className={classes.list}>
        <DocumentsList businessUnitId={businessUnitId} sectionId={sectionId} parentFolderId={parentFolderId} />
      </Box>
    </ListWrapper>
  );
}
