import { createBrowserHistory as createHistory, History, Action, Location, LocationDescriptorObject } from 'history';

import get from 'lodash/fp/get';
import compact from 'lodash/fp/compact';
import dropRight from 'lodash/fp/dropRight';
import split from 'lodash/fp/split';
import last from 'lodash/fp/last';

export const history = createHistory();

let pastLocations: Location[] = [];
function updatePastLocations(location: Location, action: Action) {
  switch (action) {
    case 'PUSH':
      // first location when app loads and when pushing onto history
      pastLocations.push(location);
      break;
    case 'REPLACE':
      // only when using history.replace
      if (pastLocations.length === 0) {
        pastLocations.push(location);
      }
      pastLocations[pastLocations.length - 1] = location;
      break;
    case 'POP': {
      // happens when using the back button, or forward button
      pastLocations.pop();
      // location according to pastLocations
      const appLocation = pastLocations[pastLocations.length - 1];
      if (!(appLocation && appLocation.key === location.key)) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations = [location];
      }
      break;
    }
    default:
  }
}
history.listen(updatePastLocations);

function isPreviousLocationWithinApp(): boolean {
  return pastLocations.length >= 1;
}

export function goBackOrReplace(
  location: History.Path | LocationDescriptorObject,
  state?: History.LocationState
): void {
  if (isPreviousLocationWithinApp()) {
    history.goBack();
  } else {
    history.replace(location as any, state);
  }
}

export const getUrlFilter = (path: string): string => {
  // returns a filter part of the given path
  // if path is '/path?filter=first&filter=second' will return 'filter=first&filter=second'
  return get('[1]', path.split('?')) || '';
};

export const makeParentUrl = (url: string, cutNumber = 1): string => {
  const paths = compact(split('/', url));
  const lastPath = last(paths) || '';
  const filter = getUrlFilter(lastPath);
  const parentPath = dropRight(cutNumber, paths).join('/');
  return `/${parentPath}${filter ? `?${filter}` : ''}`;
};
