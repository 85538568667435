import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';

import { CompaniesPage } from './CompaniesPage';
import { AddCompanyDialog } from './CompaniesList/CompanyCard/AddCompanyDialog';
import { EditCompanyDialog } from './CompaniesList/CompanyCard/EditCompanyDialog';

import { BusinessUnitsCommonRoutes } from 'pages/BusinessUnitsCommonRoutes/BusinessUnitsCommonRoutes';

export const basePath = 'companies';

export function CompaniesRouter() {
  const { path } = useRouteMatch();

  return (
    <Route
      path={[
        `${path}/:businessUnitId?/section/:sectionId?/parentFolderId/:parentFolderId?`,
        `${path}/:businessUnitId?/section/:sectionId?/group/:groupFolderId?`,
        `${path}/:businessUnitId?/section/:sectionId?`,
        `${path}/:businessUnitId?`,
      ]}
    >
      <CompaniesPage />
      <Route
        path={[`${path}/new`, `${path}/*/new`]}
        render={routeProps => <AddCompanyDialog routeProps={routeProps} />}
      />
      <Route
        path={`${path}/:businessUnitId/edit`}
        render={routeProps => <EditCompanyDialog routeProps={routeProps} />}
      />
      <BusinessUnitsCommonRoutes path={path} structType="COMPANY" />
    </Route>
  );
}
