import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { SecretaryUpdateRequest, UserControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const UserApi = UserControllerApiFactory(ApiConfiguration);

type SecretaryUpdateProviderType = UseMutationResult<
  SecretaryUpdateRequest,
  AxiosError<unknown>,
  SecretaryUpdateRequest,
  unknown
>;

const SecretaryUpdateContext = createContext<SecretaryUpdateProviderType | null>(null);
SecretaryUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}Update`;

export function useSecretaryUpdateProvider(): SecretaryUpdateProviderType {
  const contextState = useContext(SecretaryUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSecretaryUpdateProvider.name} must be used within a ${SecretaryUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface SecretaryUpdateProviderProps {
  queryOptions?: UseMutationOptions<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryUpdateRequest, unknown>;
}
export function SecretaryUpdateProvider(props: React.PropsWithChildren<SecretaryUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const updateDefaultOptions: SecretaryUpdateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const update = useMutation<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryUpdateRequest>(
    async (data: SecretaryUpdateRequest) => UserApi.updateUser(data).then(resp => resp.data),
    {
      ...updateDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <SecretaryUpdateContext.Provider value={update}>{props.children}</SecretaryUpdateContext.Provider>;
}
