import React, { useEffect, useState } from 'react';

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Div100vh from 'react-div-100vh';
import { isMobile, isAndroid } from 'react-device-detect';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { LogoIcon } from 'components/icons';

import { getMobileAppDownloadURL } from 'api/http';
import { makeParentUrl } from 'utils';

import screenshot1_ru from './assets/ru/screenshot1.png';
import screenshot1_2x_ru from './assets/ru/screenshot1@2x.png';
import screenshot2_ru from './assets/ru/screenshot2.png';
import screenshot2_2x_ru from './assets/ru/screenshot2@2x.png';
import screenshot3_ru from './assets/ru/screenshot3.png';
import screenshot3_2x_ru from './assets/ru/screenshot4@2x.png';
import screenshot4_ru from './assets/ru/screenshot4.png';
import screenshot4_2x_ru from './assets/ru/screenshot4@2x.png';
import screenshot5_ru from './assets/ru/screenshot5.png';
import screenshot5_2x_ru from './assets/ru/screenshot5@2x.png';

import screenshot1_en from './assets/en/screenshot1En.png';
import screenshot1_2x_en from './assets/en/screenshot1En@2x.png';
import screenshot2_en from './assets/en/screenshot2En.png';
import screenshot2_2x_en from './assets/en/screenshot2En@2x.png';
import screenshot3_en from './assets/en/screenshot3En.png';
import screenshot3_2x_en from './assets/en/screenshot4En@2x.png';
import screenshot4_en from './assets/en/screenshot4En.png';
import screenshot4_2x_en from './assets/en/screenshot4En@2x.png';
import screenshot5_en from './assets/en/screenshot5En.png';
import screenshot5_2x_en from './assets/en/screenshot5En@2x.png';

import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';

export function MobileAppsDownloadPage() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const {
    t,
    i18n: { language: i18nLang, languages },
  } = useTranslation();

  const language = first(i18nLang.split('-')) || first(languages) || 'en';

  const screenMap: any = {
    en: {
      screenshot1: screenshot1_en,
      screenshot2: screenshot2_en,
      screenshot3: screenshot3_en,
      screenshot4: screenshot4_en,
      screenshot5: screenshot5_en,
      screenshot1_2x: screenshot1_2x_en,
      screenshot2_2x: screenshot2_2x_en,
      screenshot3_2x: screenshot3_2x_en,
      screenshot4_2x: screenshot4_2x_en,
      screenshot5_2x: screenshot5_2x_en,
    },
    ru: {
      screenshot1: screenshot1_ru,
      screenshot2: screenshot2_ru,
      screenshot3: screenshot3_ru,
      screenshot4: screenshot4_ru,
      screenshot5: screenshot5_ru,
      screenshot1_2x: screenshot1_2x_ru,
      screenshot2_2x: screenshot2_2x_ru,
      screenshot3_2x: screenshot3_2x_ru,
      screenshot4_2x: screenshot4_2x_ru,
      screenshot5_2x: screenshot5_2x_ru,
    },
  };

  const [downloadAppLink, setDownloadAppLink] = useState<string>('');

  useEffect(() => {
    if (!isMobile) {
      history.push(`${makeParentUrl(match.url)}/error`);
    }

    if (isAndroid) {
      history.push(`${makeParentUrl(match.url)}/error`, { errorMsg: t('mobileApps:notAvailableForAndroid') });
    }

    const query = new URLSearchParams(location.search);
    const accessKey = query.get('access_key');
    if (accessKey) {
      setDownloadAppLink(`itms-services://?action=download-manifest&url=${getMobileAppDownloadURL(accessKey)}`);
    }
  }, [history, location.search, match.url, t]);

  const classes = useStyles();
  return (
    <Div100vh>
      <Box className={classes.root}>
        <Box maxWidth={375} px={1.5}>
          <Box display="flex" flexDirection="column" alignItems="center" mt={7}>
            <Box>
              <LogoIcon style={{ width: 78, height: 78 }} />
            </Box>
            <Box mt={3} component="span" fontSize="h5.fontSize" fontWeight="bold">
              USM Universe
            </Box>
          </Box>
          <Box mt={9} mb={6} px={1.5} alignSelf="center">
            <Button
              color="primary"
              variant="contained"
              href={downloadAppLink}
              disabled={isEmpty(downloadAppLink)}
              disableElevation
              fullWidth
            >
              {t('login:downloadApp')}
            </Button>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" mb={3}>
              <Box fontSize="body1.fontSize" fontWeight="bold">
                {t('mobileApps:downloadInstruction.subtitle')}
              </Box>
              <Box mt={1} textAlign="center">
                <img
                  src={screenMap[language].screenshot1}
                  srcSet={`${screenMap[language].screenshot1}, ${screenMap[language].screenshot1_2x} 2x`}
                  alt="screenshot"
                  style={{ objectFit: 'contain', maxWidth: '100%' }}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb={3}>
              <Box fontSize="body1.fontSize">{t('mobileApps:downloadInstruction.step1')}</Box>
              <Box mt={1}>
                <img
                  src={screenMap[language].screenshot2}
                  srcSet={`${screenMap[language].screenshot2}, ${screenMap[language].screenshot2_2x} 2x`}
                  alt="screenshot"
                  style={{ objectFit: 'contain', maxWidth: '100%' }}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb={3}>
              <Box fontSize="body1.fontSize">{t('mobileApps:downloadInstruction.step2')}</Box>
              <Box mt={1}>
                <img
                  src={screenMap[language].screenshot3}
                  srcSet={`${screenMap[language].screenshot3}, ${screenMap[language].screenshot3_2x} 2x`}
                  alt="screenshot"
                  style={{ objectFit: 'contain', maxWidth: '100%' }}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb={3}>
              <Box fontSize="body1.fontSize">{t('mobileApps:downloadInstruction.step3')}</Box>
              <Box mt={1}>
                <img
                  src={screenMap[language].screenshot4}
                  srcSet={`${screenMap[language].screenshot4}, ${screenMap[language].screenshot4_2x} 2x`}
                  alt="screenshot"
                  style={{ objectFit: 'contain', maxWidth: '100%' }}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb={3}>
              <Box fontSize="body1.fontSize">{t('mobileApps:downloadInstruction.step4')}</Box>
              <Box mt={1}>
                <img
                  src={screenMap[language].screenshot5}
                  srcSet={`${screenMap[language].screenshot5}, ${screenMap[language].screenshot5_2x} 2x`}
                  alt="screenshot"
                  style={{ objectFit: 'contain', maxWidth: '100%' }}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb={3}>
              <Box fontSize="body1.fontSize">{t('mobileApps:downloadInstruction.step5')}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Div100vh>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F8F8F8',
  },
}));
