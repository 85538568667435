import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useAuthProvider } from 'api/AuthProviders';
import { ChangeRoleRequestRoleEnum } from 'api/generated';
import { getAccountPhotoURL } from 'api/http';

import { AccountRoles } from 'pages/Accounts/components/AccountRoles';
import { CustomInput } from 'components/fields/CustomInput';
import { AccountCircleIcon, SettingsIcon, SupportIcon } from 'components/icons';
import { Spinner } from 'components/material';
import { history, makeParentUrl } from 'utils';

import map from 'lodash/map';
import { SEARCH_PAGE_PATH } from '../../../Router';
import {
  MessagingChangeLanguageProvider,
  useMessagingChangeLanguageProvider,
} from '../../../../api/MessagingProvider/MessagingChangeLanguageProvider';
import { ChangeLanguageRequestLanguageEnum } from '../../../../api/generated/views/change-language-request';
import { ContactInfo } from 'components/common/ContactInfo';

export function ProfileDetailsDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const match = useRouteMatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeHandler = () => {
    if (makeParentUrl(match.url) === SEARCH_PAGE_PATH) {
      history.goBack();
    } else {
      history.replace(makeParentUrl(match.url));
    }
  };

  return (
    <Dialog open={!!match} maxWidth="sm" fullWidth={true} fullScreen={fullScreen} data-test={ProfileDetailsDialog.name}>
      <Box position="relative">
        <Box position="absolute" top={8} right={8} display="flex" alignItems="center">
          <Box ml="auto">
            <IconButton aria-label="close" size="medium" onClick={closeHandler} data-test="closeButton">
              <CloseIcon htmlColor="#ffffff" fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        <MessagingChangeLanguageProvider>
          <ProfileDetails />
        </MessagingChangeLanguageProvider>
      </Box>
    </Dialog>
  );
}

function ProfileDetails() {
  const { t, i18n } = useTranslation();
  const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'support@usmuniverse.ru';
  const {
    controller: { mutateAsync: changeLanguage },
  } = useMessagingChangeLanguageProvider();

  const {
    sessionUser,
    status,
    role: currentRole,
    controllers: {
      logout: { mutateAsync: logout },
      changeRole: { mutateAsync: changeRole },
    },
  } = useAuthProvider();

  const account = sessionUser?.account;

  const handleLogout = useCallback(() => {
    logout({ devices: ['CURRENT'] });
  }, [logout]);

  const handleRoleChange = useCallback(
    (event: React.ChangeEvent<{ value: ChangeRoleRequestRoleEnum | unknown }>) => {
      const role: ChangeRoleRequestRoleEnum = event.target.value as ChangeRoleRequestRoleEnum;
      changeRole({ role });
    },
    [changeRole]
  );

  const handleLangChange = useCallback(
    (event: React.ChangeEvent<{ value: 'ru' | 'en' | unknown }>) => {
      const usedLanguage = event.target.value === 'ru' ? 'RUSSIAN' : 'ENGLISH';

      i18n.changeLanguage(event.target.value as string);
      changeLanguage({ language: ChangeLanguageRequestLanguageEnum[usedLanguage] });
    },
    [changeLanguage, i18n]
  );

  if (status === 'loading') {
    return <Spinner />;
  }

  if (status === 'error' || status === 'loggedOut' || !account) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" bgcolor="#31323A">
      <Box display="flex" justifyContent="center" p={3}>
        <PersonAvatar src={getAccountPhotoURL(account.id)} alt="avatar" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="background.paper"
        boxShadow={1}
        style={{ borderTopLeftRadius: 16, borderTopRightRadius: 16 }}
        py={3}
        px={6}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box fontSize="subtitle1.fontSize">
            <Box textAlign="center" fontSize="h4.fontSize" fontWeight={600}>
              {account.lastName}
            </Box>
            <Box textAlign="center" fontSize="h5.fontSize">
              {`${account.firstName} ${account.middleName || ''}`}
            </Box>
          </Box>
        </Box>
        <ContactInfo person={account} i18nNamespace="persons"></ContactInfo>
        <Box display="flex" mt={4} alignItems="center">
          <Box color="grey.500" mr={2.5} alignSelf="center" height={24}>
            <AccountCircleIcon />
          </Box>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Box>
              <Typography variant="subtitle1" color="primary">
                {t('persons:role')}
              </Typography>
            </Box>
            <Box ml="auto">
              {account.allowableRoles.length > 1 ? (
                <Select
                  name="currentRole"
                  value={currentRole}
                  onChange={handleRoleChange}
                  input={<CustomInput />}
                  margin="dense"
                >
                  {map(account.allowableRoles, role => (
                    <MenuItem key={role} value={role} dense={false}>
                      {t(`accounts:roles.${role}` as const)}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <AccountRoles roles={account.allowableRoles} />
              )}
            </Box>
          </Box>
        </Box>

        <Box display="flex" mt={3} alignItems="center">
          <Box color="grey.500" mr={2.5} alignSelf="center" height={24}>
            <SettingsIcon />
          </Box>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Box>
              <Typography variant="subtitle1" color="primary">
                {t('persons:language')}
              </Typography>
            </Box>
            <Box ml="auto">
              <Select
                name="language"
                value={formatLanguge(i18n.language, i18n.languages)}
                onChange={handleLangChange}
                input={<CustomInput />}
                margin="dense"
              >
                <MenuItem key="ru" value="ru" dense={false}>
                  Русский
                </MenuItem>
                <MenuItem key="en" value="en" dense={false}>
                  English
                </MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" mt={3}>
          <Box display="flex">
            <Box color="grey.500" alignSelf="flex-end" mr={2.5}>
              <SupportIcon fontSize="small" />
            </Box>
            <Box>
              <Link href={`mailto:${SUPPORT_EMAIL}`}>
                <Typography variant="subtitle1">{t('persons:form.fields.supportLabel')}</Typography>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box mt={5}>
          <LogoutButton variant="contained" color="secondary" onClick={handleLogout} fullWidth>
            {t('persons:logout')}
          </LogoutButton>
        </Box>
      </Box>
    </Box>
  );
}

const PersonAvatar = withStyles(theme => ({
  root: {
    height: 156,
    width: 156,
  },
}))(Avatar);

const LogoutButton = withStyles(theme => ({
  root: {
    color: theme.palette.error.main,
  },
}))(Button);

const formatLanguge = (language: string, languages: any) => {
  return languages.find((lang: string) => language.startsWith(lang));
};
