import { PersonFolderRequest, PersonUpdateRequest } from 'api/generated';
import { PersonFormValuesType } from './PersonForm/PersonForm';
import omit from 'lodash/omit';
import differenceBy from 'lodash/differenceBy';
import compact from 'lodash/compact';
import { PersonWithPhotoUpdateRequest, usePersonMutatorsProvider } from 'api/PersonsProvider';
import { usePersonFoldersProvider } from 'api/PersonsProvider/PersonFoldersProvider';
import { UseMutateAsyncFunction } from 'react-query';
import { AxiosError } from 'axios';
import { updateErrorTextFromServerError } from 'utils/errorUtils';
import i18next from 'i18next';

export function usePersonMutation() {
  const {
    update: { mutateAsync: updatePerson, isLoading: isPersonUpdating, isSuccess: isPersonUpdatingSuccess },
    create: { mutateAsync: createPerson, isLoading: isPersonCreating, isSuccess: isPersonCreatingSuccess, data },
  } = usePersonMutatorsProvider();
  const {
    controllers: {
      updatePersonFolders: {
        mutateAsync: updatePersonFolders,
        isSuccess: foldersRequestIsSuccess,
        isLoading: areFoldersUpdating,
      },
    },
  } = usePersonFoldersProvider();

  return {
    submitUpdate: (formValues: PersonFormValuesType, personFolders: Array<PersonFolderRequest>) =>
      submitMutation(formValues, personFolders, updatePerson, updatePersonFolders),
    submitCreate: (formValues: PersonFormValuesType) => {
      if (isPersonCreatingSuccess && !isPersonUpdatingSuccess && data) {
        // in case when server returned error on creating positions but person was created successfully
        // we use function update for person
        return submitMutation(Object.assign({}, data, formValues), [], updatePerson, updatePersonFolders);
      } else {
        return submitMutation(formValues, [], createPerson, updatePersonFolders);
      }
    },
    isLoading: isPersonCreating || isPersonUpdating || (areFoldersUpdating && areFoldersUpdating),
    // currently implementation suppose that only one  submitCreate or submitCreate will bi called at the same time
    isPersonRequestSuccess: isPersonCreatingSuccess || isPersonUpdatingSuccess,
    foldersRequestIsSuccess,
  };
}

function submitMutation(
  formValues: PersonFormValuesType,
  personFolders: Array<PersonFolderRequest>,
  mutatePerson: UseMutateAsyncFunction<PersonUpdateRequest, AxiosError<unknown>, PersonWithPhotoUpdateRequest, unknown>,
  updatePersonFolders: ReturnType<typeof usePersonFoldersProvider>['controllers']['updatePersonFolders']['mutateAsync']
) {
  const deletePersonFolderIds = compact(
    differenceBy(personFolders, formValues.groups, 'personFolderId').map(el => el.personFolderId)
  );
  const createPersonFolders = differenceBy(formValues.groups, personFolders || [], 'personFolderId');
  const foldersToUpdate = differenceBy(
    formValues.groups.filter(el => el.personFolderId),
    deletePersonFolderIds,
    'personFolderId'
  );
  const personData = omit(formValues, 'groups');
  return mutatePerson(personData).then(({ personId }) => {
    return updatePersonFolders({
      personId,
      updatePersonFolders: foldersToUpdate,
      createPersonFolders: createPersonFolders,
      deletePersonFolderIds,
      earlyErrorHandler: e => {
        e = updateErrorTextFromServerError(e, msg => i18next.t('persons:errors.partialUserCreating') + msg);
        throw e;
      },
    });
  });
}
