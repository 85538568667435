import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import {
  NewBusinessUnitAccessProvider,
  BusinessUnitUpdateAccessByPersonsProvider,
  useNewBusinessUnitAccessProvider,
  useBusinessUnitUpdateAccessByPersonsProvider,
} from 'api/BusinessUnitProviders';
import { ProjectCreateProvider, useProjectCreateProvider } from 'api/ProjectsProviders';
import { useErrorCatcher } from 'api/notifications';
import { BusinessUnitUpdateRequest } from 'api/generated';

import { makeParentUrl } from 'utils';
import { Modal } from 'components/material';
import { ProjectFormFieldsEnum, ProjectForm } from './ProjectForm';
import { basePath } from '../../ProjectsRouter';

import map from 'lodash/map';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

export function AddProjectDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const match = routeProps.match;
  const catchError = useErrorCatcher();

  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url));

  return (
    <Modal
      title={t('projects:dialog.newProject')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={AddProjectDialog.name}
    >
      <ProjectCreateProvider queryOptions={{ onError: catchError }}>
        <NewBusinessUnitAccessProvider type="PROJECT">
          <BusinessUnitUpdateAccessByPersonsProvider>
            <ProjectCreate />
          </BusinessUnitUpdateAccessByPersonsProvider>
        </NewBusinessUnitAccessProvider>
      </ProjectCreateProvider>
    </Modal>
  );
}

function ProjectCreate() {
  const history = useHistory();
  const { mutateAsync: createProject } = useProjectCreateProvider();
  const { mutateAsync: updateAccess } = useBusinessUnitUpdateAccessByPersonsProvider();
  const {
    readers: { data: readers, isFetched: isReadersFetched },
    responsible: { data: responsible, isFetched: isResponsibleFetched },
  } = useNewBusinessUnitAccessProvider();
  const initialAllowIds = map(filter(readers, ['access', true]), 'accountDisplayView.id');
  const initialResponsibleIds = map(filter(responsible, ['access', true]), 'accountDisplayView.id');

  const onSubmit = useCallback(
    values => {
      return createProject(values)
        .then(({ businessUnitId }: BusinessUnitUpdateRequest) => {
          if (
            !isEqual(initialAllowIds, values[ProjectFormFieldsEnum.accountAllowIds]) ||
            !isEqual(initialResponsibleIds, values[ProjectFormFieldsEnum.responsibleIds])
          ) {
            const updateRequest = {
              businessUnitId,
              readerAccess: map(readers, item => ({
                accountId: item.accountDisplayView.id,
                access: values[ProjectFormFieldsEnum.accountAllowIds]?.includes(item.accountDisplayView.id) || false,
              })),
              responsibleAccess: map(responsible, item => ({
                accountId: item.accountDisplayView.id,
                access: values[ProjectFormFieldsEnum.responsibleIds]?.includes(item.accountDisplayView.id) || false,
              })),
            };
            return updateAccess(updateRequest).then(() => businessUnitId);
          }
          return businessUnitId;
        })
        .then(businessUnitId => {
          history.push(`/${basePath}/${businessUnitId}`);
        });
    },
    [createProject, initialAllowIds, initialResponsibleIds, readers, responsible, updateAccess, history]
  );

  if (!isReadersFetched || !readers || !responsible || !isResponsibleFetched) {
    return null;
  }

  return (
    <ProjectForm
      onSubmit={onSubmit}
      type="CREATE"
      personsList={readers}
      initialAllowIds={initialAllowIds}
      responsibleIds={initialResponsibleIds}
      responsibleList={responsible}
    />
  );
}
