import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { ProjectArchiveProvider, useProjectArchiveProvider } from 'api/ProjectsProviders/ProjectArchiveProvider';
import { ProjectUpdateProvider, useProjectUpdateProvider } from 'api/ProjectsProviders/ProjectUpdateProvider';
import { ProjectInfoProvider, useProjectInfoProvider } from 'api/ProjectsProviders/ProjectInfoProvider';
import {
  BusinessUnitAccessPersonsListProvider,
  BusinessUnitUpdateAccessByPersonsProvider,
  useBusinessUnitAccessPersonsListProvider,
  useBusinessUnitUpdateAccessByPersonsProvider,
} from 'api/BusinessUnitProviders';
import { useErrorCatcher } from 'api/notifications';

import { makeParentUrl } from 'utils';
import { Modal } from 'components/material';
import { ProjectForm, ProjectFormFieldsEnum, ProjectWithAccessFormRequestType } from './ProjectForm';
import { ProjectsPageRouterParams } from '../../interfaces';

import omit from 'lodash/omit';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

export function EditProjectDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const match = useRouteMatch<ProjectsPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const catchError = useErrorCatcher();

  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url));

  return (
    <Modal
      title={t('projects:dialog.title')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={EditProjectDialog.name}
    >
      <ProjectInfoProvider businessUnitId={businessUnitId}>
        <ProjectUpdateProvider queryOptions={{ onError: catchError }} businessUnitId={businessUnitId}>
          <ProjectArchiveProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
            <BusinessUnitAccessPersonsListProvider businessUnitId={businessUnitId}>
              <BusinessUnitUpdateAccessByPersonsProvider>
                <ProjectUpdate />
              </BusinessUnitUpdateAccessByPersonsProvider>
            </BusinessUnitAccessPersonsListProvider>
          </ProjectArchiveProvider>
        </ProjectUpdateProvider>
      </ProjectInfoProvider>
    </Modal>
  );
}

function ProjectUpdate() {
  const match = useRouteMatch<ProjectsPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const history = useHistory();
  const {
    controllers: {
      archive: { mutateAsync: archive },
      unarchive: { mutateAsync: unarchive },
    },
  } = useProjectArchiveProvider();
  const { mutateAsync: updateProject } = useProjectUpdateProvider();
  const { mutateAsync: updateAccess } = useBusinessUnitUpdateAccessByPersonsProvider();
  const { data: project } = useProjectInfoProvider();
  const {
    readers: { data: readers, isFetched: isReadersFetched },
    responsible: { data: responsible, isFetched: isResponsibleFetched },
  } = useBusinessUnitAccessPersonsListProvider();

  const initialAllowIds = map(filter(readers, ['access', true]), 'accountDisplayView.id');
  const initialResponsibleIds = map(filter(responsible, ['access', true]), 'accountDisplayView.id');

  const onArchive = useCallback(() => {
    if (project) {
      archive(project.version).then(() => history.push(makeParentUrl(match.url)));
    }
  }, [archive, history, match.url, project]);

  const onUnarchive = useCallback(() => {
    if (project) {
      unarchive(project.version).then(() => history.push(makeParentUrl(match.url)));
    }
  }, [history, match.url, project, unarchive]);

  const onSubmit = useCallback(
    (values: ProjectWithAccessFormRequestType) => {
      const companyValues = omit(values, ProjectFormFieldsEnum.accountAllowIds);
      return updateProject(companyValues)
        .then(() => {
          if (
            !isEqual(initialAllowIds, values[ProjectFormFieldsEnum.accountAllowIds]) ||
            !isEqual(initialResponsibleIds, values[ProjectFormFieldsEnum.responsibleIds])
          ) {
            const updateRequest = {
              businessUnitId,
              readerAccess: map(readers, item => ({
                accountId: item.accountDisplayView.id,
                access: values[ProjectFormFieldsEnum.accountAllowIds]?.includes(item.accountDisplayView.id) || false,
              })),
              responsibleAccess: map(responsible, item => ({
                accountId: item.accountDisplayView.id,
                access: values[ProjectFormFieldsEnum.responsibleIds]?.includes(item.accountDisplayView.id) || false,
              })),
            };
            return updateAccess(updateRequest);
          }
        })
        .then(() => history.push(makeParentUrl(match.url)));
    },
    [
      updateProject,
      initialAllowIds,
      initialResponsibleIds,
      businessUnitId,
      readers,
      responsible,
      updateAccess,
      history,
      match.url,
    ]
  );

  if (!isReadersFetched || !readers || !responsible || !isResponsibleFetched) {
    return null;
  }

  return (
    <ProjectForm
      onSubmit={onSubmit}
      onArchive={onArchive}
      onUnarchive={onUnarchive}
      initialValues={project}
      type="EDIT"
      personsList={readers}
      initialAllowIds={initialAllowIds}
      responsibleList={responsible}
      responsibleIds={initialResponsibleIds}
    />
  );
}
