import React, { useCallback } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useMobileAppsListProvider } from 'api/MobileAppsProviders';
import { i18nDateFnsLocaleMap } from 'i18n';

import { Spinner } from 'components/material/Spinner';
import { MobilePhoneIcon } from 'components/icons';

import format from 'date-fns/format';

import isEmpty from 'lodash/isEmpty';

interface MobileAppsListProps {}
export function MobileAppsList(props: MobileAppsListProps) {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data: apps, isFetched, isLoading } = useMobileAppsListProvider();

  const goToDetailsPage = useCallback(
    appId => {
      history.push(`${url}/${appId}`);
    },
    [history, url]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(apps) && isFetched) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Box display="flex" alignItems="center" justifyContent="center" fontSize="8rem" color="grey.300" mb={5}>
          <MobilePhoneIcon fontSize="inherit" />
        </Box>
        <Box color="grey.500" fontSize="h6.fontSize" fontWeight={400}>
          {t('mobileApps:list.empty')}
        </Box>
      </Box>
    );
  }

  const formatOptions = {
    locale: i18nDateFnsLocaleMap[language],
    weekStartsOn: 1 as any,
    addSuffix: true,
  };

  return (
    <TableContainer component={Box} position="relative">
      <Table aria-label="mobile apps table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('mobileApps:list.platformHeader')}</TableCell>
            <TableCell align="left">{t('mobileApps:list.appVersion')}</TableCell>
            <TableCell align="left">{t('mobileApps:list.publicationDate')}</TableCell>
            <TableCell align="left">{t('mobileApps:list.description')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(apps || []).map(app => (
            <TableRow key={app.id} className={classes.tableRow} hover onClick={() => goToDetailsPage(app.id)}>
              <TableCell align="left">
                <Typography variant="subtitle1">{app.platformHeader}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle1">{app.appVersion}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle1">
                  {format(new Date(app.publicationDate!), 'd MMM p', formatOptions)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle1">{app.description}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
  },
}));
