import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { BusinessUnitControllerApiFactory, FileControllerApiFactory, BusinessUnitUpdateRequest } from 'api/generated';
import { ApiConfiguration, FileApiConfiguration, invalidateFallbackImageCache } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

const CompanyApi = BusinessUnitControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

export type CompanyUpdateRequest = {
  logo?: File;
} & BusinessUnitUpdateRequest;

type CompanyUpdateProviderType = UseMutationResult<
  CompanyUpdateRequest,
  AxiosError<unknown>,
  CompanyUpdateRequest,
  unknown
>;

const CompanyUpdateContext = createContext<CompanyUpdateProviderType | null>(null);
CompanyUpdateContext.displayName = 'CompanyUpdate';

export function useCompanyUpdateProvider(): CompanyUpdateProviderType {
  const contextState = useContext(CompanyUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useCompanyUpdateProvider.name} must be used within a ${CompanyUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface CompanyUpdateProviderProps {
  businessUnitId: number;
  queryOptions?: UseMutationOptions<BusinessUnitUpdateRequest, AxiosError<unknown>, CompanyUpdateRequest, unknown>;
}

export function CompanyUpdateProvider(props: React.PropsWithChildren<CompanyUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: CompanyUpdateProviderProps['queryOptions'] = {
    onSuccess: (company, variables) => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries(['businessUnit', { businessUnitId: props.businessUnitId }]);
      if (variables.logo) {
        invalidateFallbackImageCache();
      }
    },
  };
  const create = useMutation<BusinessUnitUpdateRequest, AxiosError<unknown>, CompanyUpdateRequest>(
    async data => {
      if (data.logo) {
        const logo = data.logo;
        const file = await FileApi.upload(logo, logo.name).then(resp => resp.data);
        data.iconId = file.id;
      }
      const params = omit(data, ['logo']);
      return CompanyApi.updateBusinessUnit('COMPANY', params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <CompanyUpdateContext.Provider value={create}>{props.children}</CompanyUpdateContext.Provider>;
}
