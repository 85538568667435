import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { PersonControllerApiFactory, PersonView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const PersonDetailsApi = PersonControllerApiFactory(ApiConfiguration);

type PersonDetailsProviderType = QueryObserverResult<PersonView, unknown>;

const PersonContext = createContext<PersonDetailsProviderType | null>(null);
PersonContext.displayName = `${upperFirst(RESOURCE_NAME)}Details`;

export function usePersonDetailsProvider(): PersonDetailsProviderType {
  const contextState = useContext(PersonContext);
  if (isNil(contextState)) {
    throw new Error(`${usePersonDetailsProvider.name} must be used within a ${PersonContext.displayName} context`);
  }
  return contextState;
}

interface PersonProviderProps {
  businessUnitId: number;
  personId: number;
  options?: any;
}

export function PersonDetailsProvider(props: React.PropsWithChildren<PersonProviderProps>) {
  const value = useQuery<PersonView>([RESOURCE_NAME, { id: props.personId }], () =>
    PersonDetailsApi.getBusinessUnitPersonById2(props.businessUnitId, props.personId).then(resp => resp.data)
  );
  return <PersonContext.Provider value={value}>{props.children}</PersonContext.Provider>;
}
