import React from 'react';

import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface Props {
  label: 'Ру' | 'En';
}
export function DocumentLabel(props: Props) {
  const { label } = props;
  const classes = useStyles();
  return (
    <Chip classes={{ colorPrimary: classes.primary }} component="span" size="small" color="primary" label={label} />
  );
}

const useStyles = makeStyles(theme => ({
  primary: {
    background: '#DAD8DB',
    color: theme.palette.text.primary,
    textTransform: 'initial',
    borderRadius: theme.spacing(0.5),
    marginLeft: '4px',
  },
}));
