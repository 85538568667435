import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { SearchProvider } from 'api/SearchProviders';

import SearchResultsColumn from './SearchResultsColumn';
import { SEARCH_PARAM_NAME, PERSON_FOLDER_PATH } from './constants';
import { ResultsPageSearchArea } from './ResultsPageSearchArea';
import EmptyPage from './SearchResults/EmptyPage';

export function SearchResultsPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchText = params.get(SEARCH_PARAM_NAME) || '';
  const isDialogPath = location.pathname.indexOf(PERSON_FOLDER_PATH);

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <ResultsPageSearchArea searchText={searchText} />
      {(searchText || isDialogPath > 0) && searchText.length >= 3 ? (
        <SearchProvider searchText={searchText}>
          <SearchResultsColumn searchText={searchText} />
        </SearchProvider>
      ) : (
        <>
          {searchText.length < 3 && searchText.length > 0 ? (
            <EmptyPage label={t('common:searchResults.emptyQuerySymbols')} />
          ) : (
            <EmptyPage label={t('common:searchResults.emptyQuery')} />
          )}
        </>
      )}
    </Box>
  );
}
