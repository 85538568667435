import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import {
  GroupDeleteProvider,
  GroupDetailsWithUpdateProvider,
  GroupUpdateProvider,
  useGroupDetailsWithUpdateProvider,
  useGroupUpdateProvider,
} from 'api/GroupsProvider';
import { GroupFolderUpdateRequest } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { GroupRequiredRouterParams } from '../interfaces';

import { DeleteGroupConfirmationDialog } from './DeleteGroupConfirmationDialog';
import { GroupForm } from './GroupForm';

import isNaN from 'lodash/isNaN';

export function UpdateGroupDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<GroupRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  let groupFolderId = parseInt(match.params.groupFolderId || '', 10);
  if (isNaN(groupFolderId)) {
    const query = new URLSearchParams(location.search);
    groupFolderId = parseInt(query.get('groupFolderId') || '', 10);
  }

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url));

  return (
    <Modal
      title={t('groups:dialog.updateTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={UpdateGroupDialog.name}
    >
      <GroupDetailsWithUpdateProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        groupFolderId={groupFolderId}
      >
        <GroupUpdateProvider
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          queryOptions={{ onError: catchError }}
        >
          <GroupDeleteProvider
            businessUnitId={businessUnitId}
            sectionId={sectionId}
            groupFolderId={groupFolderId}
            queryOptions={{ onError: catchError }}
          >
            <GroupUpdate />
          </GroupDeleteProvider>
        </GroupUpdateProvider>
      </GroupDetailsWithUpdateProvider>
    </Modal>
  );
}

function GroupUpdate() {
  const history = useHistory();
  const match = useRouteMatch<GroupRequiredRouterParams>();
  const { t } = useTranslation();

  const {
    controller: { mutateAsync },
  } = useGroupUpdateProvider();

  const { data: group } = useGroupDetailsWithUpdateProvider();

  const [openConfirm, setOpenConfirm] = useState(false);

  const showConfirmDialog = useCallback(event => {
    setOpenConfirm(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const onSubmit = useCallback(
    (values: GroupFolderUpdateRequest) => {
      return mutateAsync(values).then(() => goBackOrReplace(makeParentUrl(match.url)));
    },
    [match.url, mutateAsync]
  );

  const onCancel = useCallback(() => {
    goBackOrReplace(makeParentUrl(match.url));
  }, [match.url]);

  const onDelete = useCallback(() => {
    closeConfirmDialog();
    if (match.params.groupFolderId) {
      history.push(makeParentUrl(`${match.url}${history.location.search}`, 3));
    } else {
      goBackOrReplace(makeParentUrl(match.url));
    }
  }, [closeConfirmDialog, history, match]);

  return (
    <>
      <GroupForm
        initialValues={group}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onRemove={showConfirmDialog}
        submitLabel={t('groups:dialog.save')}
        cancelLabel={t('groups:dialog.cancel')}
        removeLabel={t('groups:dialog.remove')}
      />
      <DeleteGroupConfirmationDialog open={openConfirm} onDelete={onDelete} onClose={closeConfirmDialog} />
    </>
  );
}
