import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  BusinessUnitSectionControllerApiFactory,
  BusinessUnitSectionShortView,
  BusinessUnitSectionShortViewSectionDataTypeEnum,
  UserSessionStateViewRoleEnum,
} from 'api/generated';
import { ApiConfiguration, getSectionPreviewFileURL } from 'api/http';

import { useAuthProvider } from 'api/AuthProviders/AuthProvider';
import { DocumentsListProvider, useDocumentsListProvider } from 'api/DocumentProviders/DocumentsListProvider';
import { SectionContentProvider, useSectionContentProvider } from 'api/SectionsProviders/SectionContentProvider';

import { PDFViewer } from 'components/common/PDFViewer';
import { Spinner } from 'components/material/Spinner';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { BusinessUnitPageRouterParams } from '../interfaces';

import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Link from '@material-ui/core/Link';

const SectionsApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

export function SectionPreviewDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const history = useHistory();
  const match = useRouteMatch<BusinessUnitPageRouterParams>();

  const businessUnitId = get(history, 'location.state.businessUnitId', '');
  const sectionId = get(history, 'location.state.sectionId', '');
  const previewFileId = get(history, 'location.state.previewFileId', '');
  const previewFileUrl = getSectionPreviewFileURL(businessUnitId, sectionId, previewFileId);

  const hideGoToList = get(history, 'location.state.hideGoToList', false);

  const [section, setSection] = useState<BusinessUnitSectionShortView>();

  const [scale, setScale] = useState(1);

  const zoomIn = useCallback(() => {
    setScale(scale => Math.round((scale + 0.1) * 100) / 100);
  }, []);

  const zoomOut = useCallback(() => {
    setScale(scale => (scale > 0.1 ? Math.round((scale - 0.1) * 100) / 100 : 0.1));
  }, []);

  const closeHandler = useCallback(() => goBackOrReplace(makeParentUrl(match.url)), [match.url]);

  useEffect(() => {
    SectionsApi.getBusinessUnitSection2(businessUnitId, sectionId).then(resp => setSection(resp.data));
  }, [businessUnitId, sectionId]);

  const stopPropagation = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  return (
    <Dialog open={!!match} fullScreen={true} data-test={SectionPreviewDialog.name}>
      <CustomDialogTitle>
        <Box display="flex" alignItems="center" width="100%">
          <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" mr={3}>
            {section?.sectionPreviewFileMetaView?.fileName || ''}
          </Box>
          <Box ml="auto" display="flex" alignItems="center" justifyContent="flex-end">
            <Box display="flex" alignItems="center" mr={6}>
              <Box mr={1}>
                <IconButton aria-label="zoom out" size="medium" onClick={zoomOut} data-test="zoomOutButton">
                  <RemoveIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <IconButton aria-label="zoom in" size="medium" onClick={zoomIn} data-test="zoomInButton">
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            <Box mr={3}>
              <IconButton
                component={Link}
                href={previewFileUrl}
                download={section?.sectionPreviewFileMetaView?.fileName || true}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="download"
                size="medium"
                onClick={stopPropagation}
                data-test="downloadButton"
              >
                <GetAppIcon fontSize="inherit" />
              </IconButton>
            </Box>
            {!hideGoToList && (
              <Box>
                {section?.sectionDataType === BusinessUnitSectionShortViewSectionDataTypeEnum.DOCUMENT && (
                  <DocumentsListProvider filter={undefined} businessUnitId={businessUnitId} sectionId={sectionId}>
                    <DocumentsSectionGoToListButton section={section} />
                  </DocumentsListProvider>
                )}
                {section?.sectionDataType === BusinessUnitSectionShortViewSectionDataTypeEnum.PERSON && (
                  <SectionContentProvider
                    filter={undefined}
                    businessUnitId={businessUnitId}
                    sectionId={sectionId}
                    allHierarchyLevels={false}
                  >
                    <PersonsSectionGoToListButton section={section} />
                  </SectionContentProvider>
                )}
              </Box>
            )}
            <Box ml={6}>
              <IconButton aria-label="close" size="medium" onClick={closeHandler} data-test="closeButton">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CustomDialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <PDFViewer fileUrl={previewFileUrl} scale={scale} />
      </DialogContent>
    </Dialog>
  );
}

export const CustomDialogTitle = withStyles(theme => ({
  root: {
    paddingBottom: 13,
    paddingTop: 13,
  },
}))(DialogTitle);

function DocumentsSectionGoToListButton({ section }: { section: BusinessUnitSectionShortView }) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<BusinessUnitPageRouterParams>();

  const { role } = useAuthProvider();
  const isReader = role === UserSessionStateViewRoleEnum.READER;

  const { data: documents, isFetched, isLoading } = useDocumentsListProvider();

  const goToListHandler = useCallback(() => {
    history.push(`${makeParentUrl(match.url)}/section/${section.sectionId}`);
  }, [history, match.url, section]);

  if (isLoading) {
    return <Spinner color="secondary" />;
  }

  if (isReader && isEmpty(flatten(documents?.pages || [])) && isFetched) {
    return null;
  }

  return (
    <Button variant="contained" color="secondary" size="small" onClick={goToListHandler}>
      {t('businessUnit:sectionPreviewDialog.goToList')}
    </Button>
  );
}

function PersonsSectionGoToListButton({ section }: { section: BusinessUnitSectionShortView }) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<BusinessUnitPageRouterParams>();

  const { role } = useAuthProvider();
  const isReader = role === UserSessionStateViewRoleEnum.READER;

  const { data: persons, isFetched, isLoading } = useSectionContentProvider();

  const goToListHandler = useCallback(() => {
    history.push(`${makeParentUrl(match.url)}/section/${section.sectionId}`);
  }, [history, match.url, section]);

  if (isLoading) {
    return <Spinner color="secondary" />;
  }

  if (isReader && isEmpty(flatten(persons?.pages || [])) && isFetched) {
    return null;
  }

  return (
    <Button variant="contained" color="secondary" size="small" onClick={goToListHandler}>
      {t('businessUnit:sectionPreviewDialog.goToList')}
    </Button>
  );
}
