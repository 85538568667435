import React from 'react';

import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Variant } from '@material-ui/core/styles/createTypography';

import { AccountViewAllowableRolesEnum, UserSessionStateViewRoleEnum } from 'api/generated';

import map from 'lodash/map';

interface AccountRolesProps {
  roles: AccountViewAllowableRolesEnum[] | UserSessionStateViewRoleEnum[];
  variant?: Variant | 'inherit';
}
export function AccountRoles({ roles, variant }: AccountRolesProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Typography variant={variant} className={classes.roles}>
      {map<AccountViewAllowableRolesEnum | UserSessionStateViewRoleEnum>(
        roles,
        (role: AccountViewAllowableRolesEnum | UserSessionStateViewRoleEnum) => t(`accounts:roles.${role}` as const)
      ).join(', ')}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  roles: {
    color: theme.palette.grey[600],
  },
}));
