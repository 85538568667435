import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import withStyles from '@material-ui/core/styles/withStyles';

export const CustomInput = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiSelect-select.MuiSelect-select p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  input: {
    position: 'relative',
  },
  notchedOutline: {
    borderWidth: 0,
  },
}))(OutlinedInput);

export const CustomLabel = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '1.125rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}))(InputLabel);
