import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function LockIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="12" height="16" viewBox="0 0 12 16" fill="none" {...props}>
      <path
        d="M1.97067 16H10.0293C11.349 16 12 15.3881 12 14.0581V8.43651C12 7.27792 11.4985 6.66599 10.478 6.53544V4.56094C10.478 1.48496 8.30499 0 6 0C3.69501 0 1.52199 1.48496 1.52199 4.56094V6.53544C0.501466 6.66599 0 7.27792 0 8.43651V14.0581C0 15.3881 0.651026 16 1.97067 16ZM2.93842 4.3896C2.93842 2.34166 4.35484 1.2565 6 1.2565C7.64516 1.2565 9.06158 2.34166 9.06158 4.3896V6.51096H2.93842V4.3896ZM2.02346 14.7761C1.61877 14.7761 1.41642 14.6048 1.41642 14.1479V8.35492C1.41642 7.89801 1.61877 7.73483 2.02346 7.73483H9.98534C10.39 7.73483 10.5836 7.89801 10.5836 8.35492V14.1479C10.5836 14.6048 10.39 14.7761 9.98534 14.7761H2.02346Z"
        fill="black"
      />
    </SvgIcon>
  );
}
