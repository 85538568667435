import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { DisablePushTokenRequest, EnablePushChannelRequest, NotificationControllerApiFactory } from '../generated';
import { ApiConfiguration } from '../http';
import React, { createContext, useContext } from 'react';
import upperFirst from 'lodash/upperFirst';
import isNil from 'lodash/isNil';
import { RESOURCE_NAME } from './constants';

const NotificationApi = NotificationControllerApiFactory(ApiConfiguration);

type MessagingEnableProviderType = {
  enableNotifications: UseMutationResult<unknown, AxiosError<unknown>, EnablePushChannelRequest>;
  disableNotifications: UseMutationResult<unknown, AxiosError<unknown>, DisablePushTokenRequest>;
};

const MessagingEnableContext = createContext<MessagingEnableProviderType | null>(null);
MessagingEnableContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useMessagingEnableProvider(): MessagingEnableProviderType {
  const contextState = useContext(MessagingEnableContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMessagingEnableProvider.name} must be used within a ${MessagingEnableContext.displayName} context`
    );
  }
  return contextState;
}

interface MessagingEnableProviderProps {}
//TODO: rename to NotificationsProvider
export function MessagingEnableProvider(props: React.PropsWithChildren<MessagingEnableProviderProps>) {
  const enableNotifications = useMutation<unknown, AxiosError<unknown>, EnablePushChannelRequest>(
    async ({ deliveryType, language, pushToken }: EnablePushChannelRequest) =>
      NotificationApi.enablePushChannel({ deliveryType, language, pushToken }).then(resp => resp.data)
  );
  const disableNotifications = useMutation<unknown, AxiosError<unknown>, DisablePushTokenRequest>(
    async ({ pushToken }: DisablePushTokenRequest) =>
      NotificationApi.disablePushChannel({ pushToken }).then(resp => resp.data)
  );
  return (
    <MessagingEnableContext.Provider
      value={{
        enableNotifications,
        disableNotifications,
      }}
    >
      {props.children}
    </MessagingEnableContext.Provider>
  );
}
