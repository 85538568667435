import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { MobileAppVersionControllerApiFactory, MobileAppVersionShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const MobileAppVersionApi = MobileAppVersionControllerApiFactory(ApiConfiguration);

type MobileAppsListProviderType = QueryObserverResult<MobileAppVersionShortView[]>;

const MobileAppsListContext = createContext<MobileAppsListProviderType | null>(null);
MobileAppsListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useMobileAppsListProvider(): MobileAppsListProviderType {
  const contextState = useContext(MobileAppsListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMobileAppsListProvider.name} must be used within a ${MobileAppsListContext.displayName} context`
    );
  }
  return contextState;
}

interface MobileAppsListProviderProps {
  queryOptions?: UseQueryOptions<MobileAppVersionShortView[]>;
}
export function MobileAppsListProvider(props: React.PropsWithChildren<MobileAppsListProviderProps>) {
  const value = useQuery<MobileAppVersionShortView[]>(
    RESOURCE_NAME,
    () => MobileAppVersionApi.getMobileAppVersions().then(resp => resp.data),
    props.queryOptions
  );
  return <MobileAppsListContext.Provider value={value}>{props.children}</MobileAppsListContext.Provider>;
}
