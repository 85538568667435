/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileMetaView } from '../views';
// @ts-ignore
import { MobileAppUpdateStatusView } from '../views';
// @ts-ignore
import { MobileAppVersionCreateRequest } from '../views';
// @ts-ignore
import { MobileAppVersionShortView } from '../views';
// @ts-ignore
import { MobileAppVersionUpdateRequest } from '../views';
// @ts-ignore
import { UrlView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * MobileAppVersionControllerApi - axios parameter creator
 * @export
 */
export const MobileAppVersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create new mobile app version
     * @param {MobileAppVersionCreateRequest} mobileAppVersionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create2: async (
      mobileAppVersionCreateRequest: MobileAppVersionCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'mobileAppVersionCreateRequest' is not null or undefined
      assertParamExists('create2', 'mobileAppVersionCreateRequest', mobileAppVersionCreateRequest);
      const localVarPath = `/mobile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mobileAppVersionCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download actual manifest.plist file
     * @param {string} accessKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadActualManifestFile: async (accessKey: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'accessKey' is not null or undefined
      assertParamExists('downloadActualManifestFile', 'accessKey', accessKey);
      const localVarPath = `/public/mobile-app/manifest/{accessKey}`.replace(
        `{${'accessKey'}}`,
        encodeURIComponent(String(accessKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download icon for the mobile app
     * @param {number} iconId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIcon: async (iconId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'iconId' is not null or undefined
      assertParamExists('downloadIcon', 'iconId', iconId);
      const localVarPath = `/public/mobile-app/logo/{iconId}`.replace(
        `{${'iconId'}}`,
        encodeURIComponent(String(iconId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download mobile app by id
     * @param {string} accessKey
     * @param {number} mobileAppId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadMobileAppById: async (accessKey: string, mobileAppId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'accessKey' is not null or undefined
      assertParamExists('downloadMobileAppById', 'accessKey', accessKey);
      // verify required parameter 'mobileAppId' is not null or undefined
      assertParamExists('downloadMobileAppById', 'mobileAppId', mobileAppId);
      const localVarPath = `/public/mobile-app/ipa-file/{mobileAppId}/{accessKey}/appfile.ipa`
        .replace(`{${'accessKey'}}`, encodeURIComponent(String(accessKey)))
        .replace(`{${'mobileAppId'}}`, encodeURIComponent(String(mobileAppId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mobile app versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMobileAppVersions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/mobile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mobile app update link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateLink: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/mobile/updateLink`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mobile app update info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppUpdateStatus: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/mobile/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload files for mobile app version
     * @param {any} [file]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMobileAppVersionFiles: async (file?: any, name?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/mobile/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (name !== undefined) {
        localVarFormParams.append('name', name as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MobileAppVersionControllerApi - functional programming interface
 * @export
 */
export const MobileAppVersionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MobileAppVersionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create new mobile app version
     * @param {MobileAppVersionCreateRequest} mobileAppVersionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create2(
      mobileAppVersionCreateRequest: MobileAppVersionCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileAppVersionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create2(mobileAppVersionCreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download actual manifest.plist file
     * @param {string} accessKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadActualManifestFile(
      accessKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadActualManifestFile(accessKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download icon for the mobile app
     * @param {number} iconId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadIcon(
      iconId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIcon(iconId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download mobile app by id
     * @param {string} accessKey
     * @param {number} mobileAppId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadMobileAppById(
      accessKey: string,
      mobileAppId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMobileAppById(accessKey, mobileAppId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get mobile app versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMobileAppVersions(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MobileAppVersionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMobileAppVersions(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get mobile app update link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUpdateLink(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUpdateLink(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get mobile app update info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppUpdateStatus(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileAppUpdateStatusView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppUpdateStatus(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Upload files for mobile app version
     * @param {any} [file]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadMobileAppVersionFiles(
      file?: any,
      name?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileMetaView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMobileAppVersionFiles(file, name, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MobileAppVersionControllerApi - factory interface
 * @export
 */
export const MobileAppVersionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MobileAppVersionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Create new mobile app version
     * @param {MobileAppVersionCreateRequest} mobileAppVersionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create2(
      mobileAppVersionCreateRequest: MobileAppVersionCreateRequest,
      options?: any
    ): AxiosPromise<MobileAppVersionUpdateRequest> {
      return localVarFp.create2(mobileAppVersionCreateRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download actual manifest.plist file
     * @param {string} accessKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadActualManifestFile(accessKey: string, options?: any): AxiosPromise<any> {
      return localVarFp.downloadActualManifestFile(accessKey, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download icon for the mobile app
     * @param {number} iconId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIcon(iconId: number, options?: any): AxiosPromise<any> {
      return localVarFp.downloadIcon(iconId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download mobile app by id
     * @param {string} accessKey
     * @param {number} mobileAppId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadMobileAppById(accessKey: string, mobileAppId: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .downloadMobileAppById(accessKey, mobileAppId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get mobile app versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMobileAppVersions(options?: any): AxiosPromise<Array<MobileAppVersionShortView>> {
      return localVarFp.getMobileAppVersions(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get mobile app update link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateLink(options?: any): AxiosPromise<UrlView> {
      return localVarFp.getUpdateLink(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get mobile app update info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppUpdateStatus(options?: any): AxiosPromise<MobileAppUpdateStatusView> {
      return localVarFp.mobileAppUpdateStatus(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload files for mobile app version
     * @param {any} [file]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMobileAppVersionFiles(file?: any, name?: string, options?: any): AxiosPromise<FileMetaView> {
      return localVarFp.uploadMobileAppVersionFiles(file, name, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MobileAppVersionControllerApi - interface
 * @export
 * @interface MobileAppVersionControllerApi
 */
export interface MobileAppVersionControllerApiInterface {
  /**
   *
   * @summary Create new mobile app version
   * @param {MobileAppVersionCreateRequest} mobileAppVersionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  create2(
    mobileAppVersionCreateRequest: MobileAppVersionCreateRequest,
    options?: any
  ): AxiosPromise<MobileAppVersionUpdateRequest>;

  /**
   *
   * @summary Download actual manifest.plist file
   * @param {string} accessKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  downloadActualManifestFile(accessKey: string, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Download icon for the mobile app
   * @param {number} iconId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  downloadIcon(iconId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Download mobile app by id
   * @param {string} accessKey
   * @param {number} mobileAppId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  downloadMobileAppById(accessKey: string, mobileAppId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Get mobile app versions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  getMobileAppVersions(options?: any): AxiosPromise<Array<MobileAppVersionShortView>>;

  /**
   *
   * @summary Get mobile app update link
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  getUpdateLink(options?: any): AxiosPromise<UrlView>;

  /**
   *
   * @summary Get mobile app update info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  mobileAppUpdateStatus(options?: any): AxiosPromise<MobileAppUpdateStatusView>;

  /**
   *
   * @summary Upload files for mobile app version
   * @param {any} [file]
   * @param {string} [name]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApiInterface
   */
  uploadMobileAppVersionFiles(file?: any, name?: string, options?: any): AxiosPromise<FileMetaView>;
}

/**
 * MobileAppVersionControllerApi - object-oriented interface
 * @export
 * @class MobileAppVersionControllerApi
 * @extends {BaseAPI}
 */
export class MobileAppVersionControllerApi extends BaseAPI implements MobileAppVersionControllerApiInterface {
  /**
   *
   * @summary Create new mobile app version
   * @param {MobileAppVersionCreateRequest} mobileAppVersionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public create2(mobileAppVersionCreateRequest: MobileAppVersionCreateRequest, options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .create2(mobileAppVersionCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download actual manifest.plist file
   * @param {string} accessKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public downloadActualManifestFile(accessKey: string, options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .downloadActualManifestFile(accessKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download icon for the mobile app
   * @param {number} iconId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public downloadIcon(iconId: number, options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .downloadIcon(iconId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download mobile app by id
   * @param {string} accessKey
   * @param {number} mobileAppId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public downloadMobileAppById(accessKey: string, mobileAppId: number, options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .downloadMobileAppById(accessKey, mobileAppId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get mobile app versions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public getMobileAppVersions(options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .getMobileAppVersions(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get mobile app update link
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public getUpdateLink(options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .getUpdateLink(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get mobile app update info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public mobileAppUpdateStatus(options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .mobileAppUpdateStatus(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload files for mobile app version
   * @param {any} [file]
   * @param {string} [name]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileAppVersionControllerApi
   */
  public uploadMobileAppVersionFiles(file?: any, name?: string, options?: any) {
    return MobileAppVersionControllerApiFp(this.configuration)
      .uploadMobileAppVersionFiles(file, name, options)
      .then(request => request(this.axios, this.basePath));
  }
}
