import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { GroupFolderControllerApiFactory, GroupFolderShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

type GroupsListProviderType = QueryObserverResult<GroupFolderShortView[]>;

const GroupsListContext = createContext<GroupsListProviderType | null>(null);
GroupsListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useGroupsListProvider(): GroupsListProviderType {
  const contextState = useContext(GroupsListContext);
  if (isNil(contextState)) {
    throw new Error(`${useGroupsListProvider.name} must be used within a ${GroupsListContext.displayName} context`);
  }
  return contextState;
}

interface GroupsListProviderProps {
  businessUnitId: number;
  queryOptions?: UseQueryOptions<GroupFolderShortView[]>;
}
export function GroupsListProvider(props: React.PropsWithChildren<GroupsListProviderProps>) {
  const value = useQuery<GroupFolderShortView[]>(
    RESOURCE_NAME,
    () => GroupFolderApi.getAllGroups(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <GroupsListContext.Provider value={value}>{props.children}</GroupsListContext.Provider>;
}
