import React from 'react';

import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useErrorCatcher } from 'api/notifications';
import { SimplePersonsListProvider } from 'api/PersonsProvider';
import { UserSessionStateViewRoleEnum } from 'api/generated';
import { useAuthProvider } from 'api/AuthProviders';

import { makeParentUrl } from 'utils';
import { Modal, ModalToolbar } from 'components/material';
import { CommonPersonsList } from '../PersonsList/CommonPersonsList';
import { PersonRequiredRouterParams } from '../interfaces';

export function CommonPersonsListDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();
  const history = useHistory();

  const { role } = useAuthProvider();
  const match = useRouteMatch<PersonRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const closeHandler = () => history.push(makeParentUrl(match.url, 1));

  const canAddPerson = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;

  return (
    <Modal
      title={t('persons:dialog.commonListTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={CommonPersonsListDialog.name}
      toolbar={
        canAddPerson && (
          <ModalToolbar>
            <Button color="primary" variant="contained" size="medium" component={Link} to={`${match.url}/add_person`}>
              {t('persons:createMember')}
            </Button>
          </ModalToolbar>
        )
      }
    >
      {!!businessUnitId && (
        <SimplePersonsListProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
          <Box mb={6}>
            <CommonPersonsList businessUnitId={businessUnitId} />
          </Box>
        </SimplePersonsListProvider>
      )}
    </Modal>
  );
}
