import React, { createContext, useContext } from 'react';

import { useQuery, UseQueryOptions } from 'react-query';

import { ContentItemView, SearchControllerApiFactory, SearchView } from 'api/generated';
import { RESOURCE_NAME, RESOURCE_DOCUMENT_MORE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { ApiConfiguration } from '../http';

const SearchApi = SearchControllerApiFactory(ApiConfiguration);

type SearchProviderType = {
  data: any;
  isFetched: boolean;
  isLoading: boolean;
};

const SearchContext = createContext<SearchProviderType | null>(null);
SearchContext.displayName = `${upperFirst(RESOURCE_NAME)}Results`;

export function useSearchPersonProvider(): SearchProviderType {
  const contextState = useContext(SearchContext);
  if (isNil(contextState)) {
    throw new Error(`${useSearchPersonProvider.name} must be used within a ${SearchContext.displayName} context`);
  }
  return contextState;
}

interface SearchProviderProps {
  searchText: string;
  options?: UseQueryOptions<ContentItemView[]>;
}

// TODO: Добавить типы и вынести page в константы
export function SearchPersonProvider({ searchText, options, children }: React.PropsWithChildren<SearchProviderProps>) {
  // @ts-ignore
  const value = useQuery<SearchView>([RESOURCE_DOCUMENT_MORE_NAME], () =>
    SearchApi.searchPersonByFullName({ search: searchText, page: { limit: 100, skip: 4 } }).then(resp => resp.data)
  );
  // @ts-ignore
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}
