import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DownloadIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.09204 19.2422C4.55884 19.2422 4.19995 19.6216 4.19995 20.1548C4.19995 20.688 4.55884 21.0776 5.09204 21.0776H18.9143C19.4578 21.0776 19.8269 20.688 19.8269 20.1548C19.8269 19.6216 19.4578 19.2422 18.9143 19.2422H12.2288C12.4031 19.1909 12.5569 19.0986 12.7004 18.9448L19.4885 12.1567C19.6936 11.9414 19.7859 11.7158 19.7859 11.4697C19.7859 10.957 19.4167 10.5674 18.8938 10.5674C18.6477 10.5674 18.4119 10.6597 18.2375 10.8237L15.9407 13.0898L12.8542 16.4634L12.9363 14.269V3.9126C12.9363 3.37939 12.5466 3 12.0134 3C11.4802 3 11.1008 3.37939 11.1008 3.9126V14.269L11.1726 16.4736L8.08618 13.0898L5.78931 10.8237C5.62524 10.6597 5.37915 10.5674 5.13306 10.5674C4.62036 10.5674 4.24097 10.957 4.24097 11.4697C4.24097 11.7158 4.34351 11.9414 4.54858 12.1567L11.3264 18.9448C11.47 19.0986 11.634 19.1909 11.8083 19.2422H5.09204Z"
        fill="#31323A"
      />
    </SvgIcon>
  );
}

export default DownloadIcon;
