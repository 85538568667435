/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * View for group folder content change log
 * @export
 * @interface GroupFolderContentChangeLogView
 */
export interface GroupFolderContentChangeLogView {
  /**
   *
   * @type {string}
   * @memberof GroupFolderContentChangeLogView
   */
  date: string;
  /**
   * Description of changes
   * @type {string}
   * @memberof GroupFolderContentChangeLogView
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof GroupFolderContentChangeLogView
   */
  displayName: string;
  /**
   * Position of person
   * @type {string}
   * @memberof GroupFolderContentChangeLogView
   */
  position?: string;
  /**
   *
   * @type {string}
   * @memberof GroupFolderContentChangeLogView
   */
  type: GroupFolderContentChangeLogViewTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GroupFolderContentChangeLogViewTypeEnum {
  START_DATE = 'PERSON_POSITION_ADDED_START_DATE',
  END_DATE = 'PERSON_POSITION_ADDED_END_DATE',
}
