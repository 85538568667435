import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoDialog } from './InfoDialog';

export function EnableNotificationsDialog() {
  const { t } = useTranslation();

  return (
    <InfoDialog
      open={Notification.permission !== 'granted'}
      title={t('dialogs:enableNotifications.title')}
      text={t('dialogs:enableNotifications.content')}
      neverShow={{ key: 'newerShowEnableNotificationsDialog' }}
    />
  );
}
