import React, { useCallback } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useSecretariesListProvider } from 'api/SecretariesProvider';

import { Spinner } from 'components/material/Spinner';
import { formatPhone } from 'utils/phone';

import { AccountState } from '../components/chips/AccountState';

import isEmpty from 'lodash/isEmpty';

interface SecretariesListProps {}
export function SecretariesList(props: SecretariesListProps) {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const { data: secretaries, isLoading, isFetched } = useSecretariesListProvider();

  const goToDetailsPage = useCallback(
    accountId => {
      history.push(`${url}/secretary/${accountId}`);
    },
    [history, url]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TableContainer component={Box} position="relative">
      <Table aria-label="accounts table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('accounts:list.name')}</TableCell>
            <TableCell align="left">{t('accounts:list.loginPhone')}</TableCell>
            <TableCell align="left">{t('accounts:list.smsPhone')}</TableCell>
            <TableCell align="right">{t('accounts:list.state')}</TableCell>
          </TableRow>
        </TableHead>
        {isEmpty(secretaries) && isFetched ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Box color="grey.500">{t('accounts:list.emptySecretaries')}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {(secretaries || []).map(secretary => (
              <TableRow
                key={secretary.id}
                className={classes.tableRow}
                hover
                onClick={() => goToDetailsPage(secretary.id)}
              >
                <TableCell>
                  <Box color="grey.700">{secretary.displayName}</Box>
                </TableCell>
                <TableCell align="left">
                  <Box color="grey.700">{secretary.phone ? formatPhone(secretary.phone) : ''}</Box>
                </TableCell>
                <TableCell align="left">
                  <Box color="grey.700">{secretary.smsPhone ? formatPhone(secretary.smsPhone) : ''}</Box>
                </TableCell>
                <TableCell align="right">
                  <AccountState state={secretary.state} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
  },
}));
