import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

export function NotFoudPage() {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <Box fontSize="h5.fontSize" borderRight={1} py={1} pr={2.5}>
        404
      </Box>
      <Box fontSize="body2.fontSize" mx={2.5}>
        {t('common:pageNotFound')}
      </Box>
    </Box>
  );
}
