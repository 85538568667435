import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { EmailIcon, PhoneIcon } from 'components/icons';
import { formatPhone } from 'utils';

export interface ContactablePerson {
  /**
   * Main phone
   * @type {string}
   * @memberof PersonView
   */
  phone?: string;

  /**
   * Reception phone
   * @type {string}
   * @memberof PersonView
   */
  receptionPhone?: string;

  /**
   * Reception extension number
   * @type {string}
   * @memberof PersonView
   */
  receptionExtensionNumber?: string;

  /**
   * The main phone.
   * @type {string}
   * @memberof PersonView
   */
  additionalPhone?: string;

  /**
   * Internal phone.
   * @type {string}
   * @memberof PersonView
   */
  extensionNumber?: string;

  /**
   * Email of the person
   * @type {string}
   * @memberof PersonView
   */
  email?: string;
}

interface ContactsInfoProps {
  person: ContactablePerson | undefined;
  i18nNamespace: 'persons' | 'accounts';
}

export function ContactInfo({ i18nNamespace, person }: ContactsInfoProps) {
  const { t } = useTranslation();
  const hasContacts = person?.phone || person?.additionalPhone || person?.receptionPhone || person?.email;

  return (
    <Box>
      {hasContacts && (
        <Box ml={5} mt={4} fontSize="subtitle1.fontSize" fontWeight={600}>
          <Box>{t(`${i18nNamespace}:contacts.contactsSection`)}</Box>
        </Box>
      )}
      {person?.phone && (
        <Box display="flex" flexDirection="column" mt={3}>
          <Box display="flex">
            <Box color="grey.500" alignSelf="flex-end" mr={2.5}>
              <PhoneIcon fontSize="small" />
            </Box>
            <Box>
              <Typography variant="caption">{t(`${i18nNamespace}:contacts.fields.phone`)}</Typography>
              <Link href={`tel:+${person.phone}`}>
                <Typography variant="subtitle1">{formatPhone(person.phone)}</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
      {person?.receptionPhone && (
        <Box display="flex" flexDirection="column" mt={3}>
          <Box display="flex">
            <Box color="grey.500" alignSelf="flex-end" mr={2.5}>
              <PhoneIcon fontSize="small" />
            </Box>
            <Box mr={2}>
              <Typography variant="caption">{t(`${i18nNamespace}:contacts.fields.receptionPhone`)}</Typography>
              <Link href={`tel:+${person.receptionPhone}`}>
                <Typography variant="subtitle1">{formatPhone(person.receptionPhone)}</Typography>
              </Link>
            </Box>
            {person.receptionExtensionNumber && (
              <Box display="flex" alignItems="center" alignSelf="flex-end">
                <Box mr={1}>
                  <Typography variant="subtitle1">
                    {t(`${i18nNamespace}:contacts.fields.receptionExtensionNumber`)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="primary">
                    {person.receptionExtensionNumber}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {person?.additionalPhone && (
        <Box display="flex" flexDirection="column" mt={3}>
          <Box display="flex">
            <Box color="grey.500" alignSelf="flex-end" mr={2.5}>
              <PhoneIcon fontSize="small" />
            </Box>
            <Box mr={2}>
              <Typography variant="caption">{t(`${i18nNamespace}:contacts.fields.additionalPhone`)}</Typography>
              <Link href={`tel:+${person.additionalPhone}`}>
                <Typography variant="subtitle1">{formatPhone(person.additionalPhone)}</Typography>
              </Link>
            </Box>
            {person.extensionNumber && (
              <Box display="flex" alignItems="center" alignSelf="flex-end">
                <Box mr={1}>
                  <Typography variant="subtitle1">{t(`${i18nNamespace}:contacts.fields.extensionNumber`)}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="primary">
                    {person.extensionNumber}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {person?.email && (
        <Box display="flex" flexDirection="column" mt={3}>
          <Box display="flex">
            <Box color="grey.500" alignSelf="flex-end" mr={2.5}>
              <EmailIcon fontSize="small" />
            </Box>
            <Box>
              <Typography variant="caption">{t(`${i18nNamespace}:contacts.fields.email`)}</Typography>
              <Link href={`mailto:${person.email}`}>
                <Typography variant="subtitle1">{person.email}</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
