import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getMessaging } from './Firebase';
import CloseIcon from '@material-ui/icons/Close';
import { useMessagingEnableProvider } from '../../../api/MessagingProvider/MessagingEnableProvider';
import { EnablePushChannelRequestDeliveryTypeEnum, EnablePushChannelRequestLanguageEnum } from '../../../api/generated';
import { getToken, onMessage } from 'firebase/messaging';
import i18n from 'i18n/config';

const VAPID_TOKEN = 'BLfbiunMk3ka92FyXHJ9wENOifdqGI1OaqGxIlrnNvlytkwXqS4dipeDeLh3YWRSyu8qIgvrOdzWo77tWbTrDMw';

const areNotificationsAllowed = async () => {
  try {
    if ((await Notification.requestPermission()) === 'granted') {
      return true;
    }
  } catch (e) {
    return false;
  }
};
/**
 * Show Native notification.
 *
 * @param title
 * @param options
 */
const handleNotification = (title: string, options?: any) => {
  if (Notification.permission === 'granted') {
    new Notification(title, options);
  } else {
    Notification.requestPermission()
      .then(() => {
        handleNotification(title, options);
      })
      .catch(err => {
        console.log('Permission denied - Error occurred');
        throw err;
      });
  }
};

export function Messaging({ language }: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const usedLanguage = language === 'ru' ? 'RUSSIAN' : 'ENGLISH';
  const {
    enableNotifications: { mutateAsync: enableNotifications },
    disableNotifications: { mutateAsync: disableNotifications },
  } = useMessagingEnableProvider();

  // subscribe on push-notifications
  useEffect(() => {
    let tokenToUnsubscribe: string;
    areNotificationsAllowed().then(
      async allowed =>
        allowed &&
        // get getToken wok only with notification permissions
        getToken(getMessaging(), {
          vapidKey: VAPID_TOKEN,
        })
          .then((currentToken: any) => {
            if (currentToken) {
              enableNotifications({
                language: EnablePushChannelRequestLanguageEnum[usedLanguage],
                pushToken: currentToken,
                deliveryType: EnablePushChannelRequestDeliveryTypeEnum['FCM'],
              }).then(() => {
                tokenToUnsubscribe = currentToken;
              });
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err: any) => {
            console.log('An error occurred while retrieving token. ', err);
          })
    );

    return () => {
      if (tokenToUnsubscribe) {
        // should be call on logout and on language change
        disableNotifications({ pushToken: tokenToUnsubscribe }).catch(e =>
          console.error('Failed to unsubscribe notifications.', e)
        );
      }
    };
  }, [disableNotifications, enableNotifications, usedLanguage]);

  useEffect(() => {
    const cancel = onMessage(getMessaging(), (payload: any) => {
      const defaultTitle = 'USM Universe';
      const defaultBody = i18n.t('notification:newNotification');
      const defaultIcon = '/favicon.ico';
      const title = (payload.notification && payload.notification.body) || defaultTitle;

      const options = {
        icon: defaultIcon,
        body: (payload.data && payload.data.body) || defaultBody,
        data: payload.data,
        requireInteraction: true,
      };

      enqueueSnackbar(title, {
        variant: 'default',
        preventDuplicate: true,
        action: key => (
          <div onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </div>
        ),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      // Also show native notification. (by default chrome doesn't show notifications if site is opened)
      // But line bellow have nothing in common with notifications that are shown when site is closed
      // so if you remove this line push ups will still work when browser is closed
      handleNotification(title, options);
    });
    return () => cancel();
  }, [closeSnackbar, enqueueSnackbar]);

  return null;
}
