import React from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import { CSS } from '@dnd-kit/utilities';
import classnames from 'classnames';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import makeStyles from '@material-ui/core/styles/makeStyles';
interface SortableListProps {
  children: React.ReactNode;
  data: any;
  disabled: boolean;
}
export function SortableList({ children, data, disabled }: SortableListProps) {
  return (
    <Droppable id={data?.id.toString()} disabled={disabled}>
      <Draggable id={data?.id.toString()} disabled={disabled}>
        {children}
      </Draggable>
    </Droppable>
  );
}

interface DroppableProps {
  id: string;
  disabled: boolean;
}
function Droppable({ id, children, disabled }: React.PropsWithChildren<DroppableProps>) {
  const { setNodeRef, isOver, active } = useDroppable({ id, disabled });

  const theme = useTheme();
  return (
    <Box
      border={2}
      borderColor={isOver && active?.id !== id ? theme.palette.success.main : 'transparent'}
      borderRadius={4}
      ref={setNodeRef}
    >
      {children}
    </Box>
  );
}

interface DraggableProps {
  id: string;
  disabled: boolean;
}
function Draggable({ id, children, disabled }: React.PropsWithChildren<DraggableProps>) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    disabled,
  });

  const theme = useTheme();

  const style = {
    transform: transform ? CSS.Translate.toString(transform) : undefined,
  };

  const classes = useDraggableStyles();
  return (
    <Box
      display="flex"
      position="relative"
      style={style}
      borderRadius={8}
      bgcolor={isDragging ? 'background.paper' : 'background.default'}
      zIndex={isDragging ? theme.zIndex.tooltip : 'auto'}
    >
      {!disabled && (
        <Box
          mt={2.6}
          className={classnames(classes.icon, { dragging: isDragging })}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
        >
          <DragIndicatorIcon />
        </Box>
      )}
      <Box flexGrow={1}>{children}</Box>
    </Box>
  );
}

const useDraggableStyles = makeStyles(() => ({
  icon: {
    cursor: 'grab',
    '&.dragging': {
      cursor: 'grabbing',
    },
  },
}));
