import React, { useCallback } from 'react';

import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeParentUrl } from 'utils/history';

import { ACCESS_FORM_VALUES, AccountCreateFormValuesType, AccountForm } from './components/AccountForm/AccountForm';
import { AccountCreateProvider, useAccountCreateProvider } from 'api/AccountsProviders';
import { BusinessUnitUpdateAccessProvider, useBusinessUnitUpdateAccessProvider } from 'api/BusinessUnitProviders';
import { useErrorCatcher } from 'api/notifications';

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import some from 'lodash/some';
import keys from 'lodash/keys';

export function AccountCreatePage() {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  return (
    <Container maxWidth="lg">
      <Box alignSelf="center" display="flex" flexDirection="column" width="100%" mt={2}>
        <Box display="flex" flexDirection="column" mb={4}>
          <Box>
            <Button startIcon={<ArrowBackIosIcon />} size="small" component={Link} to={makeParentUrl(match.url)}>
              {`${t('accounts:back')}`}
            </Button>
          </Box>
          <Box>
            <Typography variant="h5">{t('accounts:create.account')}</Typography>
          </Box>
        </Box>
        <Box flexGrow={1} bgcolor="background.paper" borderRadius={8}>
          <AccountCreateProvider queryOptions={{ onError: catchError }}>
            <BusinessUnitUpdateAccessProvider queryOptions={{ onError: catchError }}>
              <AccountCreate />
            </BusinessUnitUpdateAccessProvider>
          </AccountCreateProvider>
        </Box>
      </Box>
    </Container>
  );
}

function AccountCreate() {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { mutateAsync: createAccount } = useAccountCreateProvider();
  const { mutateAsync: updateAccess } = useBusinessUnitUpdateAccessProvider();

  const onSubmit = useCallback(
    (values: AccountCreateFormValuesType) => {
      const accountValues = omit(values, [
        'allProjectsForReader',
        'allCompaniesForReader',
        'readerCompaniesIds',
        'readerProjectsIds',
        'responsibleCompaniesIds',
        'responsibleProjectIds',
      ]);
      return createAccount(accountValues)
        .then(account => {
          if (some(ACCESS_FORM_VALUES, item => keys(values).includes(item))) {
            const accessValues = {
              ...pick(values, [
                'allowableRoles',
                'allProjectsForReader',
                'allCompaniesForReader',
                'allowByDefaultCompanies',
                'allowByDefaultProjects',
                'readerCompaniesIds',
                'readerProjectsIds',
                'responsibleCompaniesIds',
                'responsibleProjectIds',
              ]),
              accountId: account.accountId,
            };
            updateAccess(accessValues);
          }
        })
        .then(() => history.push(makeParentUrl(match.url)));
    },
    [history, match.url, createAccount, updateAccess]
  );

  const onCancel = useCallback(() => {
    history.push(makeParentUrl(match.url));
  }, [history, match.url]);

  return (
    <AccountForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel={t('accounts:create.createAccount')}
      cancelLabel={t('accounts:create.cancel')}
    />
  );
}
