import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { FolderControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const FolderApi = FolderControllerApiFactory(ApiConfiguration);

const RESOURCE_NAME = 'Folder';

export interface FolderDeleteRequest {
  businessUnitId?: number;
  sectionId?: number;
  folderId?: number;
}

type FolderDeleteProviderType = UseMutationResult<object, AxiosError<unknown>, FolderDeleteRequest, unknown>;

const FolderDeleteContext = createContext<FolderDeleteProviderType | null>(null);
FolderDeleteContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useFolderDeleteProvider(): FolderDeleteProviderType {
  const contextState = useContext(FolderDeleteContext);
  if (isNil(contextState)) {
    throw new Error(`${useFolderDeleteProvider.name} must be used within a ${FolderDeleteContext.displayName} context`);
  }
  return contextState;
}

interface FolderDeleteProviderProps {
  businessUnitId: number;
  sectionId: number;
  folderId: number;
  parentFolderId?: number; // для очистки кэша запроса
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, FolderDeleteRequest, unknown>;
}

export function FolderDeleteProvider(props: React.PropsWithChildren<FolderDeleteProviderProps>) {
  const queryClient = useQueryClient();
  const defaultOptions: FolderDeleteProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents', props.businessUnitId, props.sectionId, props.parentFolderId]);
    },
  };
  const value = useMutation<object, AxiosError<unknown>, FolderDeleteRequest>(
    async () => {
      return FolderApi.deleteFolder2(props.businessUnitId, props.sectionId, props.folderId).then(resp => resp.data);
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <FolderDeleteContext.Provider value={value}>{props.children}</FolderDeleteContext.Provider>;
}
