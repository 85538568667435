import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CountryData, default as ReactPhoneInput, PhoneInputProps } from 'react-phone-input-2';

import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ru } from 'i18n/phone_codes';

import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';

import { countries } from 'utils/countries';
import { getPhoneCode } from 'utils';

const DEFAULT_COUNTRY = 'ru';
const DEFAULT_COUNTRY_INFO = countries.find(c => c.name === 'Russia');

// omit onChange because this component works with string in the first parameter in onchange callback
interface PhoneCustomInputProps extends Omit<OutlinedInputProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
}

export function PhoneCustomInput(props: PhoneCustomInputProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const inputProps: PhoneInputProps['inputProps'] = pick(props, [
    'aria-describedby',
    'aria-invalid',
    'autoComplete',
    'autoFocus',
    'checked',
    'disabled',
    'id',
    'name',
    'readOnly',
    'required',
    'rows',
    'type',
  ]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(DEFAULT_COUNTRY_INFO?.code);

  // set initial value for selected country
  useEffect(() => {
    if (props.value) {
      const code = getPhoneCode(props.value);
      if (code) {
        setSelectedCountryCode(code);
      }
    }
  }, [props.value]);

  const eventsListeners = {
    ...pick(props, ['onAnimationStart', 'onBlur', 'onFocus', 'onKeyDown', 'onKeyUp']),
    onChange: (
      value: string,
      data: CountryData,
      event: React.ChangeEvent<HTMLInputElement>,
      formattedValue: string
    ) => {
      setSelectedCountryCode(data.dialCode);
      return isFunction(props.onChange) && props.onChange(value === data.dialCode ? '' : value);
    },
  };

  const refProps = {
    ref: (ref: any) => {
      isFunction(props.inputRef) && props.inputRef(ref ? ref.inputElement : null);
    },
  };

  const { supportedCountries } = useMemo(() => {
    const masks: { [key: string]: string } = [] as any;
    const supportedCountries: string[] = [];
    countries.forEach(item => {
      const countryIso = item.iso.toLowerCase();
      supportedCountries.push(countryIso);
      masks[countryIso] = Array.isArray(item.mask) ? item.mask[0] : item.mask;
    });
    return { masks, supportedCountries };
  }, []);
  const classes = usePhoneCustomStyles();

  const value = (props.value ? props.value : selectedCountryCode) || '';
  return (
    <ReactPhoneInput
      {...refProps}
      inputProps={inputProps}
      {...eventsListeners}
      placeholder={props.placeholder}
      value={value}
      onlyCountries={supportedCountries}
      country={DEFAULT_COUNTRY}
      enableAreaCodes={false}
      countryCodeEditable={false}
      localization={language === 'ru' ? ru : undefined}
      inputClass={classnames(props.className, classes.input)}
      // remove input label
      specialLabel=""
      // IMPORTANT: keep jumpCursorToEnd property set to 'false' otherwise you will get an infinite onFocus <-> onBlur loop
      jumpCursorToEnd={false}
    />
  );
}

const usePhoneCustomStyles = makeStyles(theme => ({
  input: {
    '&.form-control': {
      borderRadius: '8px',
      borderWidth: '1px',
      width: 'calc(100% - 72px)',
      backgroundColor: 'inherit',
      border: 'inherit',
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    '&.form-control:focus': {
      boxShadow: 'inherit',
    },
  },
}));
