import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function LogoIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" {...props}>
      <path
        d="M0 250C0 111.929 111.929 0 250 0H774C912.071 0 1024 111.929 1024 250V774C1024 912.071 912.071 1024 774 1024H250C111.929 1024 0 912.071 0 774V250Z"
        fill="white"
      />
      <path
        d="M261.298 200H438.063L437.765 254.848L261.596 254.55L261.298 200ZM584.721 209.241L591.279 204.769L760.294 205.068L763.275 209.539L584.721 209.241ZM585.914 260.81L757.91 261.108L758.208 267.964H585.616L585.914 260.81ZM261.298 265.877H438.063L437.765 318.34H261V265.877H261.298ZM585.914 317.148H761.487L762.083 326.388H585.318L585.914 317.148ZM261 331.754L438.063 331.456L437.467 382.727L261.298 382.131L261 331.754ZM585.616 373.486H761.487L761.785 385.111H585.616V373.486ZM261.298 397.631H438.063V445.921H261.596L261.298 397.631ZM585.318 429.228L748.967 428.93L761.785 431.911L761.487 441.748L749.265 443.238H585.616L585.318 429.228ZM261.298 462.912H438.063L438.361 509.413L317.338 510.308L261.298 509.711V462.912ZM584.721 485.566H761.785V502.259H585.02L584.721 485.566ZM261.298 529.087H437.765L439.255 569.925L450.285 604.503L466.381 631.927L488.44 644.446L395.735 795.874L365.032 776.797L466.381 631.927L324.194 734.766L320.021 736.257L298.857 704.66L450.285 604.503L274.414 657.264L263.683 616.426L438.957 569.925L261.298 573.8V529.087ZM585.02 541.905H761.785L760.891 560.088L728.399 560.386H585.318L585.02 541.905ZM585.616 579.166L757.611 619.109L752.544 640.869L580.25 600.628L585.616 579.166ZM541.499 641.466L564.75 628.946L578.76 605.993L721.841 709.727L707.831 729.997L564.154 628.35L658.051 776.797L634.502 792.595L541.499 641.466ZM489.036 644.446L515.565 648.322L540.605 642.658L575.779 816.442L545.672 822.106L515.565 648.62L484.863 823L452.371 817.932L489.036 644.446Z"
        fill="#CF112B"
      />
    </SvgIcon>
  );
}

export default LogoIcon;
