import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { PersonFolderControllerApiFactory, PersonFolderView } from 'api/generated';

import { RESOURCE_HISTORY_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const PersonFolderApi = PersonFolderControllerApiFactory(ApiConfiguration);

type PersonHistoryProviderType = QueryObserverResult<any, unknown>;

const PersonHistoryContext = createContext<PersonHistoryProviderType | null>(null);
PersonHistoryContext.displayName = `${upperFirst(RESOURCE_HISTORY_NAME)}Details`;

export function usePersonHistoryProvider(): PersonHistoryProviderType {
  const contextState = useContext(PersonHistoryContext);
  if (isNil(contextState)) {
    throw new Error(
      `${usePersonHistoryProvider.name} must be used within a ${PersonHistoryContext.displayName} context`
    );
  }
  return contextState;
}

interface PersonHistoryProviderProps {
  businessUnitId: number;
  personId: number;
}

export function PersonHistoryProvider(props: React.PropsWithChildren<PersonHistoryProviderProps>) {
  // @ts-ignore
  const value = useQuery<PersonFolderView>(
    [RESOURCE_HISTORY_NAME, { personId: props.personId, businessUnitId: props.businessUnitId }],
    () =>
      PersonFolderApi.getAllPersonFolders(props.businessUnitId, props.personId, props.businessUnitId).then(
        resp => resp.data
      )
  );
  // @ts-ignore
  return <PersonHistoryContext.Provider value={value}>{props.children}</PersonHistoryContext.Provider>;
}
