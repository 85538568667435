import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { GroupFolderControllerApiFactory, GroupFolderCreateRequest, GroupFolderUpdateRequest } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from 'api/SectionsProviders/constants';
import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

type GroupCreateProviderType = {
  businessUnitId: number;
  sectionId: number;
  controller: UseMutationResult<GroupFolderUpdateRequest, AxiosError<unknown>, GroupFolderCreateRequest>;
};

const GroupCreateContext = createContext<GroupCreateProviderType | null>(null);
GroupCreateContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useGroupCreateProvider(): GroupCreateProviderType {
  const contextState = useContext(GroupCreateContext);
  if (isNil(contextState)) {
    throw new Error(`${useGroupCreateProvider.name} must be used within a ${GroupCreateContext.displayName} context`);
  }
  return contextState;
}

interface GroupCreateProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: UseMutationOptions<GroupFolderUpdateRequest, AxiosError<unknown>, GroupFolderCreateRequest>;
}
export function GroupCreateProvider(props: React.PropsWithChildren<GroupCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: GroupCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries(SECTION_RESOURCE_NAME);
    },
  };
  const create = useMutation<GroupFolderUpdateRequest, AxiosError<unknown>, GroupFolderCreateRequest>(
    async (data: GroupFolderCreateRequest) =>
      GroupFolderApi.createGroupFolder(props.businessUnitId, props.sectionId, data).then(resp => resp.data),
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <GroupCreateContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        sectionId: props.sectionId,
        controller: create,
      }}
    >
      {props.children}
    </GroupCreateContext.Provider>
  );
}
