import React from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import { BusinessUnitShowProvider } from 'api/BusinessUnitProviders';
import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { PersonRequiredRouterParams } from '../interfaces';
import { BusinessUnitShowConsumer } from 'api/BusinessUnitProviders/BusinessUnitShowProvider';
import { AccountList } from 'components/lists/AccountList';

export function ResponsibleListDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<PersonRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url, 1));

  return (
    <Modal
      title={t('persons:dialog.responsibleTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={ResponsibleListDialog.name}
    >
      {!!businessUnitId && (
        <BusinessUnitShowProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
          <Box mb={6}>
            <BusinessUnitShowConsumer>
              {value => (
                <AccountList
                  businessUnitId={businessUnitId}
                  isLoading={!value?.isFetched}
                  members={value?.data?.responsible}
                />
              )}
            </BusinessUnitShowConsumer>
          </Box>
        </BusinessUnitShowProvider>
      )}
    </Modal>
  );
}

export const PersonAvatar = withStyles(theme => ({
  root: {
    height: 96,
    width: 96,
  },
}))(Avatar);
