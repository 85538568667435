import axios from 'axios';

import i18n from 'i18n/config';
import { Configuration } from 'api/generated';

export const API_REQUEST_TIMEOUT = 15 * 1000;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const APPLICATION_PLATFORM = process.env.REACT_APP_APPLICATION_PLATFORM;

export const ApiConfiguration = new Configuration({
  basePath: API_URL,
  baseOptions: {
    timeout: API_REQUEST_TIMEOUT,
    headers: { 'API-Version': API_VERSION, 'Application-Platform': APPLICATION_PLATFORM },
  },
});

export const FileApiConfiguration = new Configuration({
  basePath: API_URL,
  baseOptions: {
    timeout: 0,
    headers: { 'API-Version': API_VERSION, 'Application-Platform': APPLICATION_PLATFORM },
  },
});

axios.interceptors.request.use(config => {
  config.headers['Accept-Language'] = i18n.language;
  return config;
});

// Helpers
export const getUrl = () => window.location.origin;
export const getApiUrl = () => `${getUrl()}${API_URL}`;

// key is used as a fallback for 'nocache' value in urls to images if key is not provideds
let fallbackCacheKey = Date.now();
const shownWarnings = new Set<string>();
export function ensureNoCacheKey(key: string | number | undefined | null) {
  // if key is null - it is legal value for default picture
  if (key !== undefined) {
    return key;
  }
  if (process.env.NODE_ENV === 'development') {
    const warn = new Error("Specify unique value for image url, so that browser's cache worked properly.");
    if (!shownWarnings.has(warn.stack!)) {
      shownWarnings.add(warn.stack!);
      // enable logging to find bad behavior.
      // console.warn(warn.message);
    }
  }
  return fallbackCacheKey;
}

export function invalidateFallbackImageCache() {
  fallbackCacheKey = Date.now();
}

export const getAccountPhotoURL = (accountId: number | string | undefined, photoId?: number | string | null) =>
  `${getApiUrl()}/usermanager/account/${accountId}/photo?nocache=${ensureNoCacheKey(photoId)}`;

export const getPersonPhotoURL = (businessUnitId: number, personId: number, photoId?: number | string | null) => {
  return `${getApiUrl()}/struct/ALL/${businessUnitId}/person/${personId}/photo?nocache=${ensureNoCacheKey(photoId)}`;
};

export const getBusinessUnitIconURL = (
  businessUnitId: number | string | undefined,
  photoId?: number | string | null
) => {
  return `${getApiUrl()}/struct/ALL/${businessUnitId}/icon?nocache=${ensureNoCacheKey(photoId)}`;
};

export const getDocumentIconURL = (
  businessUnitId: number | string | undefined,
  sectionId: number | string | undefined,
  documentId: number | string | undefined
) => `${getApiUrl()}/struct/ALL/${businessUnitId}/section/DOCUMENT/${sectionId}/document/${documentId}/icon`;

export const getFolderIconURL = (
  businessUnitId: number | string | undefined,
  sectionId: number | string | undefined,
  folderId: number | string | undefined
) => `${getApiUrl()}/struct/ALL/${businessUnitId}/section/DOCUMENT/${sectionId}/folder/${folderId}/icon`;

export const getSectionPreviewFileURL = (businessUnitId: number, sectionId: number, fileMetaId: number) =>
  `${getApiUrl()}/struct/ALL/${businessUnitId}/section/${sectionId}/file/${fileMetaId}`;

export const getSectionIconURL = (
  businessUnitId: number | string | undefined,
  sectionId: number | string | undefined
) => `${getApiUrl()}/struct/ALL/${businessUnitId}/section/${sectionId}/icon`;

export const getFileOfDocumentURL = (
  businessUnitId: number,
  sectionId: number,
  documentId: number,
  fileMetaId: number
) =>
  `${getApiUrl()}/struct/ALL/${businessUnitId}/section/DOCUMENT/${sectionId}/document/${documentId}/file/${fileMetaId}`;

export const getMobileAppDownloadURL = (accessKey: string) => `${getApiUrl()}/public/mobile-app/manifest/${accessKey}`;
