import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function BagIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="38" height="38" viewBox="0 0 38 38" fill="none" {...props}>
      <path
        opacity="0.24"
        d="M3.16699 17.541C3.24616 21.2428 3.46783 27.5746 3.49949 28.2728C3.61191 29.7659 4.18349 31.2748 5.07333 32.3388C6.31149 33.8319 7.83624 34.4985 9.96266 34.4985C12.9013 34.5143 16.1408 34.5143 19.2869 34.5143C22.4457 34.5143 25.511 34.5143 28.0997 34.4985C30.1961 34.4985 31.7683 33.8145 32.9907 32.3388C33.8805 31.2748 34.4521 29.7501 34.5328 28.2728C34.5645 27.6854 34.7228 20.8122 34.8178 17.541H3.16699Z"
        fill="#B9BCC6"
      />
      <path
        d="M17.8052 24.3584V26.4072C17.8052 27.0627 18.3372 27.5947 18.9927 27.5947C19.6482 27.5947 20.1802 27.0627 20.1802 26.4072V24.3584C20.1802 23.7029 19.6482 23.1709 18.9927 23.1709C18.3372 23.1709 17.8052 23.7029 17.8052 24.3584Z"
        fill="#C2BECA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1677 23.0477C16.0094 23.6224 15.4568 23.9898 14.8583 23.9106C10.8192 23.3469 6.95908 21.914 3.70058 19.7623C3.36649 19.5438 3.16699 19.1701 3.16699 18.7711V13.2833C3.16699 9.95827 5.87766 7.25394 9.21058 7.25394H12.325C12.6227 4.95494 14.5702 3.16736 16.9483 3.16736H21.0365C23.4131 3.16736 25.3622 4.95494 25.6598 7.25394H28.7901C32.1135 7.25394 34.8178 9.95827 34.8178 13.2833V18.7711C34.8178 19.1701 34.6167 19.5422 34.2858 19.7623C31.021 21.9235 27.145 23.3628 23.079 23.9249C23.0236 23.9328 22.9697 23.9359 22.9159 23.9359C22.3792 23.9359 21.8994 23.5734 21.7648 23.0414C21.445 21.781 20.3002 20.8991 18.9845 20.8991C17.6513 20.8991 16.5192 21.762 16.1677 23.0477ZM21.0365 5.54236H16.9483C15.8828 5.54236 14.9929 6.27069 14.7269 7.25394H23.2563C22.9903 6.27069 22.1005 5.54236 21.0365 5.54236Z"
        fill="#C2BECA"
      />
    </SvgIcon>
  );
}

export default BagIcon;
