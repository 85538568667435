import React, { useCallback, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';
import ClearIcon from '@material-ui/icons/Clear';
import { ClassNameMap } from '@material-ui/styles';

export const SEARCH_TEXT_MAX_LENGTH = 255;

export function SearchTextField({
  originClasses,
  submitHandler,
  initialValue = '',
}: {
  submitHandler: React.EventHandler<any>;
  originClasses?: Partial<ClassNameMap<'root'>>;
  initialValue?: string;
}) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(event => {
    setValue(event.target.value);
  }, []);

  const onClear = useCallback(() => {
    setValue('');
  }, []);

  const onKeyDown = useCallback(
    event => {
      if (event.keyCode === 13 || event.key === 'Enter') {
        submitHandler(value);
      }
    },
    [submitHandler, value]
  );

  return (
    <Box className={classnames(classes.root, originClasses?.root)}>
      <IconButton className={classes.iconButton}>
        <SearchIcon style={{ fontSize: 16 }} />
      </IconButton>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        spellCheck={false}
        autoComplete="off"
        placeholder={t('common:search')}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{ 'data-test': 'search', maxLength: SEARCH_TEXT_MAX_LENGTH }}
        value={value}
        endAdornment={
          value ? (
            <InputAdornment position="end">
              <ClearIcon onClick={onClear} style={{ fontSize: 20, color: '#83899B', cursor: 'pointer' }} />
            </InputAdornment>
          ) : undefined
        }
      />
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 40,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    background: '#ECEEF3',
    borderRadius: 10,
  },
  inputRoot: {
    flexGrow: 1,
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.subtitle2.fontSize,
  },
  input: {
    fontSize: theme.typography.body1.fontSize,
  },
  iconButton: {
    paddingRight: theme.spacing(1),
  },
}));
