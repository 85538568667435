import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, UseQueryOptions, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccountControllerApiFactory, AccountView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);

type AccountsListProviderType = QueryObserverResult<AccountView[], AxiosError<unknown>>;

const AccountsListContext = createContext<AccountsListProviderType | null>(null);
AccountsListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useAccountsListProvider(): AccountsListProviderType {
  const contextState = useContext(AccountsListContext);
  if (isNil(contextState)) {
    throw new Error(`${useAccountsListProvider.name} must be used within a ${AccountsListContext.displayName} context`);
  }
  return contextState;
}

interface AccountsListProviderProps {
  queryOptions?: UseQueryOptions<AccountView[], AxiosError<unknown>>;
}
export function AccountsListProvider(props: React.PropsWithChildren<AccountsListProviderProps>) {
  const value = useQuery<AccountView[], AxiosError<unknown>>(
    RESOURCE_NAME,
    () => AccountApi.getAccountsDetails().then(resp => resp.data),
    props.queryOptions
  );
  return <AccountsListContext.Provider value={value}>{props.children}</AccountsListContext.Provider>;
}
