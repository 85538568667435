import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { PersonFolderControllerApiFactory, PersonFolderUpdateRequest } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from '../../SectionsProviders/constants';

import isNil from 'lodash/isNil';

const PersonFolderApi = PersonFolderControllerApiFactory(ApiConfiguration);

type MemberPositionUpdateProviderType = UseMutationResult<
  PersonFolderUpdateRequest,
  AxiosError<unknown>,
  PersonFolderUpdateRequest
>;

const MemberPositionUpdateContext = createContext<MemberPositionUpdateProviderType | null>(null);
MemberPositionUpdateContext.displayName = 'MemberPositionUpdate';

export function useMemberPositionUpdateProvider(): MemberPositionUpdateProviderType {
  const contextState = useContext(MemberPositionUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMemberPositionUpdateProvider.name} must be used within a ${MemberPositionUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface MemberPositionUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  queryOptions?: UseMutationOptions<PersonFolderUpdateRequest, AxiosError<unknown>, PersonFolderUpdateRequest>;
}

export function MemberPositionUpdateProvider(props: React.PropsWithChildren<MemberPositionUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: MemberPositionUpdateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([
        SECTION_RESOURCE_NAME,
        props.businessUnitId,
        props.sectionId,
        props.groupFolderId,
      ]);
    },
  };
  const update = useMutation<PersonFolderUpdateRequest, AxiosError<unknown>, PersonFolderUpdateRequest>(
    async (data: PersonFolderUpdateRequest) => {
      return PersonFolderApi.updatePersonFolders(props.businessUnitId, props.sectionId, props.groupFolderId, data).then(
        resp => resp.data
      );
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <MemberPositionUpdateContext.Provider value={update}>{props.children}</MemberPositionUpdateContext.Provider>;
}
