import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core';

export function AddProjectButton() {
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  return (
    <ListItem component={Link} to={`${location.pathname}/new`}>
      <IconButton classes={{ root: classes.icon }}>
        <AddIcon />
      </IconButton>
      <ListItemText classes={{ primary: classes.label }}>{t('projects:addProject')}</ListItemText>
    </ListItem>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: '#83899B',
    backgroundColor: '#ECEEF3',
    marginRight: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));
