import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  DocumentControllerApiFactory,
  DocumentCreateRequest,
  DocumentMetaUpdateRequest,
  DocumentUpdateRequest,
  FileControllerApiFactory,
  FileMetaView,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import { isFileMetaView } from './type-helpers';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

type DocumentMetaUpdateWithFilesRequest = {
  pdfFile?: File | FileMetaView;
  originalFile?: File | FileMetaView;
} & DocumentMetaUpdateRequest;
export interface DocumentWithFilesCreateRequest {
  documentMetas: DocumentMetaUpdateWithFilesRequest[];
}

type DocumentCreateProviderType = UseMutationResult<
  DocumentUpdateRequest,
  AxiosError<unknown>,
  DocumentWithFilesCreateRequest,
  unknown
>;

const DocumentCreateContext = createContext<DocumentCreateProviderType | null>(null);
DocumentCreateContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useDocumentCreateProvider(): DocumentCreateProviderType {
  const contextState = useContext(DocumentCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentCreateProvider.name} must be used within a ${DocumentCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentCreateProviderProps {
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
  queryOptions?: UseMutationOptions<
    DocumentUpdateRequest,
    AxiosError<unknown>,
    DocumentWithFilesCreateRequest,
    unknown
  >;
}
export function DocumentCreateProvider(props: React.PropsWithChildren<DocumentCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: DocumentCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const create = useMutation<DocumentUpdateRequest, AxiosError<unknown>, DocumentWithFilesCreateRequest>(
    async (data: DocumentWithFilesCreateRequest) => {
      const promises = data.documentMetas.map(async dm => {
        if (dm.pdfFile && !isFileMetaView(dm.pdfFile)) {
          dm.pdfFileMetaView = await FileApi.upload(dm.pdfFile, dm.pdfFile.name).then(resp => resp.data);
        }
        if (dm.originalFile && !isFileMetaView(dm.originalFile)) {
          dm.originalFileMetaView = await FileApi.upload(dm.originalFile, dm.originalFile.name).then(resp => resp.data);
        }
        return omit(dm, ['pdfFile', 'originalFile']) as DocumentMetaUpdateRequest;
      });
      const documentMetas: DocumentMetaUpdateRequest[] = await Promise.all(promises);
      const params: DocumentCreateRequest = {
        documentMetas,
        parentFolderId: props.parentFolderId,
      };
      return DocumentApi.createDocument2(props.businessUnitId, props.sectionId, params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <DocumentCreateContext.Provider value={create}>{props.children}</DocumentCreateContext.Provider>;
}
