import React from 'react';

import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { AccountAccessView } from 'api/generated';

import { AccountAccessSwitchGroup, Switch } from 'components/fields';
import { SectionFormValues } from './SectionForm';

export enum AccessFieldTypeEnum {
  READER = 'reader',
  RESPONSIBLE = 'responsible',
}

export function AccessFields({ personsList, type }: { personsList?: AccountAccessView[]; type: AccessFieldTypeEnum }) {
  const { t } = useTranslation();

  return (
    <>
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="h6">{t(`businessUnit:dialog.${type}.accessTitle`)}</Typography>
        </Box>
        <Switch
          name={`${type}.restrictedAccess`}
          data={{
            label: (
              <Box>
                <Box fontSize="body1.fontSize">{t(`businessUnit:dialog.${type}.nonPublic`)}</Box>
                <FormSpy subscription={{ values: true }}>
                  {({ values }: FormSpyRenderProps<SectionFormValues>) => (
                    <Box fontSize="caption.fontSize">
                      {values[type].restrictedAccess
                        ? t(`businessUnit:dialog.${type}.nonPublicEnabledPlaceholder`)
                        : t(`businessUnit:dialog.${type}.nonPublicDisabledPlaceholder`)}
                    </Box>
                  )}
                </FormSpy>
              </Box>
            ),
            value: undefined,
          }}
        />
      </Box>
      <FormSpy subscription={{ values: true }}>
        {({ values }: FormSpyRenderProps<SectionFormValues>) => {
          return values[type].restrictedAccess ? (
            <Box mb={4}>
              <AccountAccessSwitchGroup
                name={`${type}.allowIds`}
                label={t(`businessUnit:dialog.${type}.allowIds`)}
                data={personsList || []}
              />
            </Box>
          ) : null;
        }}
      </FormSpy>
    </>
  );
}
