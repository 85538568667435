import React from 'react';

import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { GroupFolderCreateRequest, GroupFolderUpdateRequest } from 'api/generated';
import { TextField } from 'components/fields';
import { composeValidators, required, maxLength } from 'components/fields/validators';
import trim from 'utils/trim';

import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';

interface GroupFormProps {
  initialValues?: Partial<GroupFolderCreateRequest | GroupFolderUpdateRequest>;
  onSubmit: any;
  onCancel: any;
  onRemove?: any;
  submitLabel: string;
  cancelLabel: string;
  removeLabel?: string;
}
export function GroupForm({
  initialValues,
  onSubmit,
  onCancel,
  onRemove,
  submitLabel,
  cancelLabel,
  removeLabel,
}: GroupFormProps) {
  const { t } = useTranslation();

  const emptyInitialValues: Partial<GroupFolderCreateRequest | GroupFolderUpdateRequest> = {};

  const canRemove = isFunction(onRemove) && !isEmpty(removeLabel);

  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues || emptyInitialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box display="flex" flexDirection="column">
            <Box mb={4}>
              <Box fontSize="body1.fontSize" fontWeight="bold">
                {t('groups:form.sectionInRu')}
              </Box>
              <TextField
                label={t('groups:form.fields.titleRu')}
                placeholder={t('groups:form.fields.titleRuPlaceholder')}
                name="titleRu"
                margin="dense"
                required
                fieldProps={{
                  validate: titleValidator,
                  format: trim,
                  formatOnBlur: true,
                }}
              />
              <TextField
                label={t('groups:form.fields.descriptionRu')}
                placeholder={t('groups:form.fields.descriptionRuPlaceholder')}
                name="descriptionRu"
                margin="dense"
                rows={5}
                multiline
                fieldProps={{
                  validate: descriptionValidator,
                  format: trim,
                  formatOnBlur: true,
                }}
              />
            </Box>
            <Box mb={4}>
              <Box fontSize="body1.fontSize" fontWeight="bold">
                {t('groups:form.sectionInEn')}
              </Box>
              <TextField
                label={t('groups:form.fields.titleEn')}
                placeholder={t('groups:form.fields.titleEnPlaceholder')}
                name="titleEn"
                margin="dense"
                required
                fieldProps={{
                  validate: titleValidator,
                  format: trim,
                  formatOnBlur: true,
                }}
              />
              <TextField
                label={t('groups:form.fields.descriptionEn')}
                placeholder={t('groups:form.fields.descriptionEnPlaceholder')}
                name="descriptionEn"
                margin="dense"
                rows={5}
                multiline
                fieldProps={{
                  validate: descriptionValidator,
                  format: trim,
                  formatOnBlur: true,
                }}
              />
            </Box>
            <Toolbar disableGutters>
              <Box display="flex" my={3} width="100%">
                <Box>
                  <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                    {submitLabel}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button variant="contained" color="secondary" onClick={onCancel}>
                    {cancelLabel}
                  </Button>
                </Box>
                {canRemove && (
                  <Box ml="auto">
                    <Button variant="text" color="secondary" onClick={onRemove}>
                      {removeLabel}
                    </Button>
                  </Box>
                )}
              </Box>
            </Toolbar>
          </Box>
        </form>
      )}
    />
  );
}

const titleValidator = composeValidators(required, maxLength(120));
const descriptionValidator = maxLength(4000);

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
  },
}));
