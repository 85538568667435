import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitView } from 'api/generated';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const RESOURCE_NAME = 'company';

const CompanyShowApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type CompanyShowProviderType = QueryObserverResult<BusinessUnitView, unknown>;

const CompanyShowContext = createContext<CompanyShowProviderType | null>(null);
CompanyShowContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useCompanyShowProvider(): CompanyShowProviderType {
  const contextState = useContext(CompanyShowContext);
  if (isNil(contextState)) {
    throw new Error(`${useCompanyShowProvider.name} must be used within a ${CompanyShowContext.displayName} context`);
  }
  return contextState;
}

interface CompanyShowProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function CompanyShowProvider(props: React.PropsWithChildren<CompanyShowProviderProps>) {
  const value = useQuery<BusinessUnitView | null, AxiosError<unknown>, BusinessUnitView>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId }],
    () => CompanyShowApi.getBusinessUnitById(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <CompanyShowContext.Provider value={value}>{props.children}</CompanyShowContext.Provider>;
}
