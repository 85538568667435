import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useSectionRemoveProvider } from 'api/SectionsProviders';
import { DeleteConfirmDialog } from '../../ConfirmDialogs';

interface DeleteDocumentConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}
export function DeleteSectionConfirmationDialog({ open, onClose, onDelete }: DeleteDocumentConfirmationDialogProps) {
  const { t } = useTranslation();
  const { mutateAsync: removeSection, isLoading } = useSectionRemoveProvider();

  const handleRemove = useCallback(
    async event => {
      await removeSection({}).then(() => {
        onClose();
        onDelete();
      });
    },
    [removeSection, onClose, onDelete]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={handleRemove}
      title={t('businessUnit:deleteSectionConfirmDialog.title')}
      confirmText={t('businessUnit:deleteSectionConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}
