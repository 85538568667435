/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentCardView } from '../views';
// @ts-ignore
import { DocumentCreateRequest } from '../views';
// @ts-ignore
import { DocumentUpdateRequest } from '../views';
// @ts-ignore
import { DocumentView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * DocumentControllerApi - axios parameter creator
 * @export
 */
export const DocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Archive document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveDocument: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      body: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('archiveDocument', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('archiveDocument', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('archiveDocument', 'documentId', documentId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('archiveDocument', 'body', body);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/archive`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocument: async (
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createDocument', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createDocument', 'sectionId', sectionId);
      // verify required parameter 'documentCreateRequest' is not null or undefined
      assertParamExists('createDocument', 'documentCreateRequest', documentCreateRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/document`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocument1: async (
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createDocument1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createDocument1', 'sectionId', sectionId);
      // verify required parameter 'documentCreateRequest' is not null or undefined
      assertParamExists('createDocument1', 'documentCreateRequest', documentCreateRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/document`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocument2: async (
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createDocument2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createDocument2', 'sectionId', sectionId);
      // verify required parameter 'documentCreateRequest' is not null or undefined
      assertParamExists('createDocument2', 'documentCreateRequest', documentCreateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocumentById: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteDocumentById', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteDocumentById', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('deleteDocumentById', 'documentId', documentId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocumentById1: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteDocumentById1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteDocumentById1', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('deleteDocumentById1', 'documentId', documentId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocumentById2: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteDocumentById2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteDocumentById2', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('deleteDocumentById2', 'documentId', documentId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download file of the document by business unit id, section id, document id and fileMeta id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} fileMetaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFileOfDocument: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      fileMetaId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadFileOfDocument', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('downloadFileOfDocument', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('downloadFileOfDocument', 'documentId', documentId);
      // verify required parameter 'fileMetaId' is not null or undefined
      assertParamExists('downloadFileOfDocument', 'fileMetaId', fileMetaId);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/file/{fileMetaId}`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)))
          .replace(`{${'fileMetaId'}}`, encodeURIComponent(String(fileMetaId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download icon of the document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconOfDocument: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadIconOfDocument', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('downloadIconOfDocument', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('downloadIconOfDocument', 'documentId', documentId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/icon`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentById: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentById', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentById', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentById', 'documentId', documentId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentById1: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentById1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentById1', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentById1', 'documentId', documentId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentById2: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentById2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentById2', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentById2', 'documentId', documentId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document card by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentCard: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentCard', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentCard', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentCard', 'documentId', documentId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/card`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentForEdit: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentForEdit', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentForEdit', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentForEdit', 'documentId', documentId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentForEdit1: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentForEdit1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentForEdit1', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentForEdit1', 'documentId', documentId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentForEdit2: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getDocumentForEdit2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getDocumentForEdit2', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('getDocumentForEdit2', 'documentId', documentId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unarchive document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unarchiveDocument: async (
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      body: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('unarchiveDocument', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('unarchiveDocument', 'sectionId', sectionId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('unarchiveDocument', 'documentId', documentId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('unarchiveDocument', 'body', body);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document/{documentId}/unarchive`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'documentId'}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument: async (
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateDocument', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateDocument', 'sectionId', sectionId);
      // verify required parameter 'documentUpdateRequest' is not null or undefined
      assertParamExists('updateDocument', 'documentUpdateRequest', documentUpdateRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/document`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument1: async (
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateDocument1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateDocument1', 'sectionId', sectionId);
      // verify required parameter 'documentUpdateRequest' is not null or undefined
      assertParamExists('updateDocument1', 'documentUpdateRequest', documentUpdateRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/document`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument2: async (
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateDocument2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateDocument2', 'sectionId', sectionId);
      // verify required parameter 'documentUpdateRequest' is not null or undefined
      assertParamExists('updateDocument2', 'documentUpdateRequest', documentUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/document`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Archive document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      body: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveDocument(
        businessUnitId,
        sectionId,
        documentId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDocument(
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(
        businessUnitId,
        sectionId,
        documentCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDocument1(
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument1(
        businessUnitId,
        sectionId,
        documentCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDocument2(
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument2(
        businessUnitId,
        sectionId,
        documentCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDocumentById(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentById(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDocumentById1(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentById1(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDocumentById2(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentById2(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download file of the document by business unit id, section id, document id and fileMeta id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} fileMetaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadFileOfDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      fileMetaId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFileOfDocument(
        businessUnitId,
        sectionId,
        documentId,
        fileMetaId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download icon of the document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadIconOfDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIconOfDocument(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentById(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentById(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentById1(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentById1(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentById2(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentById2(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document card by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentCard(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCardView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentCard(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentForEdit(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentForEdit(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentForEdit1(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentForEdit1(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentForEdit2(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentForEdit2(
        businessUnitId,
        sectionId,
        documentId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Unarchive document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unarchiveDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      body: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveDocument(
        businessUnitId,
        sectionId,
        documentId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDocument(
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(
        businessUnitId,
        sectionId,
        documentUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDocument1(
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument1(
        businessUnitId,
        sectionId,
        documentUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDocument2(
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument2(
        businessUnitId,
        sectionId,
        documentUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DocumentControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Archive document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      body: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .archiveDocument(businessUnitId, sectionId, documentId, body, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocument(
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .createDocument(businessUnitId, sectionId, documentCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocument1(
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .createDocument1(businessUnitId, sectionId, documentCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentCreateRequest} documentCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDocument2(
      businessUnitId: number,
      sectionId: number,
      documentCreateRequest: DocumentCreateRequest,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .createDocument2(businessUnitId, sectionId, documentCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocumentById(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .deleteDocumentById(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocumentById1(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .deleteDocumentById1(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDocumentById2(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .deleteDocumentById2(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download file of the document by business unit id, section id, document id and fileMeta id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} fileMetaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFileOfDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      fileMetaId: number,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .downloadFileOfDocument(businessUnitId, sectionId, documentId, fileMetaId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download icon of the document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconOfDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .downloadIconOfDocument(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentById(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentView> {
      return localVarFp
        .getDocumentById(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentById1(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentView> {
      return localVarFp
        .getDocumentById1(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentById2(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentView> {
      return localVarFp
        .getDocumentById2(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document card by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentCard(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentCardView> {
      return localVarFp
        .getDocumentCard(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentForEdit(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .getDocumentForEdit(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentForEdit1(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .getDocumentForEdit1(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get document for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentForEdit2(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .getDocumentForEdit2(businessUnitId, sectionId, documentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unarchive document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} documentId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unarchiveDocument(
      businessUnitId: number,
      sectionId: number,
      documentId: number,
      body: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .unarchiveDocument(businessUnitId, sectionId, documentId, body, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument(
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .updateDocument(businessUnitId, sectionId, documentUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument1(
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .updateDocument1(businessUnitId, sectionId, documentUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update document
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {DocumentUpdateRequest} documentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDocument2(
      businessUnitId: number,
      sectionId: number,
      documentUpdateRequest: DocumentUpdateRequest,
      options?: any
    ): AxiosPromise<DocumentUpdateRequest> {
      return localVarFp
        .updateDocument2(businessUnitId, sectionId, documentUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DocumentControllerApi - interface
 * @export
 * @interface DocumentControllerApi
 */
export interface DocumentControllerApiInterface {
  /**
   *
   * @summary Archive document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  archiveDocument(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    body: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Create document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentCreateRequest} documentCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  createDocument(
    businessUnitId: number,
    sectionId: number,
    documentCreateRequest: DocumentCreateRequest,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Create document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentCreateRequest} documentCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  createDocument1(
    businessUnitId: number,
    sectionId: number,
    documentCreateRequest: DocumentCreateRequest,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Create document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentCreateRequest} documentCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  createDocument2(
    businessUnitId: number,
    sectionId: number,
    documentCreateRequest: DocumentCreateRequest,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Delete document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  deleteDocumentById(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Delete document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  deleteDocumentById1(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Delete document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  deleteDocumentById2(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Download file of the document by business unit id, section id, document id and fileMeta id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {number} fileMetaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  downloadFileOfDocument(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    fileMetaId: number,
    options?: any
  ): AxiosPromise<any>;

  /**
   *
   * @summary Download icon of the document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  downloadIconOfDocument(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<any>;

  /**
   *
   * @summary Get document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentById(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentView>;

  /**
   *
   * @summary Get document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentById1(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentView>;

  /**
   *
   * @summary Get document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentById2(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentView>;

  /**
   *
   * @summary Get document card by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentCard(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentCardView>;

  /**
   *
   * @summary Get document for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentForEdit(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Get document for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentForEdit1(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Get document for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  getDocumentForEdit2(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Unarchive document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  unarchiveDocument(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    body: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Update document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentUpdateRequest} documentUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  updateDocument(
    businessUnitId: number,
    sectionId: number,
    documentUpdateRequest: DocumentUpdateRequest,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Update document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentUpdateRequest} documentUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  updateDocument1(
    businessUnitId: number,
    sectionId: number,
    documentUpdateRequest: DocumentUpdateRequest,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;

  /**
   *
   * @summary Update document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentUpdateRequest} documentUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApiInterface
   */
  updateDocument2(
    businessUnitId: number,
    sectionId: number,
    documentUpdateRequest: DocumentUpdateRequest,
    options?: any
  ): AxiosPromise<DocumentUpdateRequest>;
}

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI implements DocumentControllerApiInterface {
  /**
   *
   * @summary Archive document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public archiveDocument(businessUnitId: number, sectionId: number, documentId: number, body: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .archiveDocument(businessUnitId, sectionId, documentId, body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentCreateRequest} documentCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public createDocument(
    businessUnitId: number,
    sectionId: number,
    documentCreateRequest: DocumentCreateRequest,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .createDocument(businessUnitId, sectionId, documentCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentCreateRequest} documentCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public createDocument1(
    businessUnitId: number,
    sectionId: number,
    documentCreateRequest: DocumentCreateRequest,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .createDocument1(businessUnitId, sectionId, documentCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentCreateRequest} documentCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public createDocument2(
    businessUnitId: number,
    sectionId: number,
    documentCreateRequest: DocumentCreateRequest,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .createDocument2(businessUnitId, sectionId, documentCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public deleteDocumentById(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .deleteDocumentById(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public deleteDocumentById1(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .deleteDocumentById1(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public deleteDocumentById2(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .deleteDocumentById2(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download file of the document by business unit id, section id, document id and fileMeta id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {number} fileMetaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public downloadFileOfDocument(
    businessUnitId: number,
    sectionId: number,
    documentId: number,
    fileMetaId: number,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .downloadFileOfDocument(businessUnitId, sectionId, documentId, fileMetaId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download icon of the document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public downloadIconOfDocument(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .downloadIconOfDocument(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentById(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentById(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentById1(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentById1(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentById2(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentById2(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document card by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentCard(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentCard(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentForEdit(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentForEdit(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentForEdit1(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentForEdit1(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get document for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public getDocumentForEdit2(businessUnitId: number, sectionId: number, documentId: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .getDocumentForEdit2(businessUnitId, sectionId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unarchive document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} documentId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public unarchiveDocument(businessUnitId: number, sectionId: number, documentId: number, body: number, options?: any) {
    return DocumentControllerApiFp(this.configuration)
      .unarchiveDocument(businessUnitId, sectionId, documentId, body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentUpdateRequest} documentUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public updateDocument(
    businessUnitId: number,
    sectionId: number,
    documentUpdateRequest: DocumentUpdateRequest,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .updateDocument(businessUnitId, sectionId, documentUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentUpdateRequest} documentUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public updateDocument1(
    businessUnitId: number,
    sectionId: number,
    documentUpdateRequest: DocumentUpdateRequest,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .updateDocument1(businessUnitId, sectionId, documentUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update document
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {DocumentUpdateRequest} documentUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public updateDocument2(
    businessUnitId: number,
    sectionId: number,
    documentUpdateRequest: DocumentUpdateRequest,
    options?: any
  ) {
    return DocumentControllerApiFp(this.configuration)
      .updateDocument2(businessUnitId, sectionId, documentUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
