import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { SecretaryUpdateRequest, SecretaryUpdateRequestStateEnum } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';
import { AccountExpirePinProvider, AccountPinStatusProvider, useAccountPinStatusProvider } from 'api/AccountsProviders';
import {
  SecretaryDetailsWithUpdateProvider,
  SecretaryLockProvider,
  SecretaryRemoveProvider,
  SecretaryUpdateProvider,
  useSecretaryDetailsWithUpdateProvider,
  useSecretaryUpdateProvider,
} from 'api/SecretariesProvider';

import { goBackOrReplace, makeParentUrl } from 'utils';

import { AccountsRequiredRouterParams } from './interfaces';
import { AccountPinStatus } from './components/chips/AccountPinStatus';
import { AccountState } from './components/chips/AccountState';
import { SecretaryForm } from './components/SecretaryForm/SecretaryForm';
import { SecretaryRunMenuButton } from './components/SecretaryRunMenuButton';

export function UpdateSecretaryDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<AccountsRequiredRouterParams>();
  const secretaryId = parseInt(match.params.secretaryId || '', 10);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url, 2));

  return (
    <SecretaryDetailsWithUpdateProvider secretaryId={secretaryId}>
      <Dialog
        open={!!match}
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen}
        data-test={UpdateSecretaryDialog.name}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" pt={2}>
            <Box fontSize="1.75rem" fontWeight="bold">
              {t('accounts:update.secretary')}
            </Box>
            <Box ml={4} mr={1} display="flex" justifyContent="space-between" flexGrow={1}>
              <AccountPinStatusProvider primaryUserId={secretaryId}>
                <StatusesBar />
              </AccountPinStatusProvider>
              <RunMenuButton />
            </Box>
            <Box ml="auto">
              <IconButton aria-label="close" size="medium" onClick={closeHandler} data-test="closeButton">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <SecretaryUpdateProvider queryOptions={{ onError: catchError }}>
            <SecretaryUpdate />
          </SecretaryUpdateProvider>
        </DialogContent>
      </Dialog>
    </SecretaryDetailsWithUpdateProvider>
  );
}

function RunMenuButton() {
  const catchError = useErrorCatcher();
  const { data: secretary } = useSecretaryDetailsWithUpdateProvider();

  return !secretary || secretary?.state === SecretaryUpdateRequestStateEnum.REMOVED ? null : (
    <AccountExpirePinProvider primaryUserId={secretary.id} queryOptions={{ onError: catchError }}>
      <SecretaryLockProvider secretaryId={secretary.id} queryOptions={{ onError: catchError }}>
        <SecretaryRemoveProvider queryOptions={{ onError: catchError }}>
          <SecretaryRunMenuButton />
        </SecretaryRemoveProvider>
      </SecretaryLockProvider>
    </AccountExpirePinProvider>
  );
}

function SecretaryUpdate() {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useSecretaryUpdateProvider();

  const { data: secretary } = useSecretaryDetailsWithUpdateProvider();

  const onSubmit = useCallback(
    (values: SecretaryUpdateRequest) => {
      if (!isLoading) {
        mutateAsync(values).then(() => goBackOrReplace(makeParentUrl(match.url, 2)));
      }
    },
    [isLoading, match.url, mutateAsync]
  );

  const onCancel = useCallback(() => goBackOrReplace(makeParentUrl(match.url, 2)), [match.url]);

  return (
    <SecretaryForm
      initialValues={secretary}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel={t('accounts:update.updateSecretary')}
      cancelLabel={t('accounts:update.cancel')}
      readOnly={secretary?.state === SecretaryUpdateRequestStateEnum.REMOVED}
    />
  );
}

function StatusesBar() {
  const { data: secretary } = useSecretaryDetailsWithUpdateProvider();
  const { data: pin } = useAccountPinStatusProvider();
  return (
    <Box display="flex" alignItems="center">
      {secretary?.state && (
        <Box>
          <AccountState state={secretary!.state} />
        </Box>
      )}
      {pin?.pinStatus && (
        <Box ml={2}>
          <AccountPinStatus status={pin.pinStatus} />
        </Box>
      )}
    </Box>
  );
}
