/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BusinessUnitCreateRequest } from '../views';
// @ts-ignore
import { BusinessUnitShortView } from '../views';
// @ts-ignore
import { BusinessUnitUpdateRequest } from '../views';
// @ts-ignore
import { BusinessUnitView } from '../views';
// @ts-ignore
import { UpdateOrderNumRequest } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * BusinessUnitControllerApi - axios parameter creator
 * @export
 */
export const BusinessUnitControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Archive business unit
     * @param {number} businessUnitId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveBusinessUnit: async (businessUnitId: number, body: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('archiveBusinessUnit', 'businessUnitId', businessUnitId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('archiveBusinessUnit', 'body', body);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/archive`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create business unit
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {BusinessUnitCreateRequest} businessUnitCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnit: async (
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      businessUnitCreateRequest: BusinessUnitCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('createBusinessUnit', 'type', type);
      // verify required parameter 'businessUnitCreateRequest' is not null or undefined
      assertParamExists('createBusinessUnit', 'businessUnitCreateRequest', businessUnitCreateRequest);
      const localVarPath = `/struct/{type}`.replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnit: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteBusinessUnit', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnit1: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteBusinessUnit1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnit2: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteBusinessUnit2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download icon by business unit id
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconByBusinessUnitId: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadIconByBusinessUnitId', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{BusinessUnitId}/icon`.replace(
        `{${'BusinessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitById: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitById', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitById1: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitById1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitById2: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitById2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitForEdit: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitForEdit', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/edit`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitForEdit1: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitForEdit1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/edit`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitForEdit2: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitForEdit2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/edit`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanies: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/struct/COMPANY`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/struct/PROJECT`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unarchive business unit
     * @param {number} businessUnitId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unarchiveBusinessUnit: async (businessUnitId: number, body: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('unarchiveBusinessUnit', 'businessUnitId', businessUnitId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('unarchiveBusinessUnit', 'body', body);
      const localVarPath = `/struct/ALL/{businessUnitId}/unarchive`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update business unit
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {BusinessUnitUpdateRequest} businessUnitUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnit: async (
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      businessUnitUpdateRequest: BusinessUnitUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('updateBusinessUnit', 'type', type);
      // verify required parameter 'businessUnitUpdateRequest' is not null or undefined
      assertParamExists('updateBusinessUnit', 'businessUnitUpdateRequest', businessUnitUpdateRequest);
      const localVarPath = `/struct/{type}`.replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit order
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitOrder: async (
      updateOrderNumRequest: UpdateOrderNumRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateOrderNumRequest' is not null or undefined
      assertParamExists('updateBusinessUnitOrder', 'updateOrderNumRequest', updateOrderNumRequest);
      const localVarPath = `/struct/ALL/order`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateOrderNumRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update business unit responsible
     * @param {number} accountId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitResponsible: async (
      accountId: number,
      requestBody: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('updateBusinessUnitResponsible', 'accountId', accountId);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('updateBusinessUnitResponsible', 'requestBody', requestBody);
      const localVarPath = `/struct/ALL/{accountId}/responsible`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BusinessUnitControllerApi - functional programming interface
 * @export
 */
export const BusinessUnitControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BusinessUnitControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Archive business unit
     * @param {number} businessUnitId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveBusinessUnit(
      businessUnitId: number,
      body: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveBusinessUnit(businessUnitId, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create business unit
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {BusinessUnitCreateRequest} businessUnitCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBusinessUnit(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      businessUnitCreateRequest: BusinessUnitCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBusinessUnit(
        type,
        businessUnitCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessUnit(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessUnit(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessUnit1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessUnit1(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessUnit2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessUnit2(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download icon by business unit id
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadIconByBusinessUnitId(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIconByBusinessUnitId(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitById(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitById(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitById1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitById1(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitById2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitById2(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitForEdit(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitForEdit(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitForEdit1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitForEdit1(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitForEdit2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitForEdit2(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get available companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanies(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get available projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProjects(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Unarchive business unit
     * @param {number} businessUnitId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unarchiveBusinessUnit(
      businessUnitId: number,
      body: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveBusinessUnit(businessUnitId, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update business unit
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {BusinessUnitUpdateRequest} businessUnitUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnit(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      businessUnitUpdateRequest: BusinessUnitUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnit(
        type,
        businessUnitUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit order
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitOrder(
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitOrder(updateOrderNumRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update business unit responsible
     * @param {number} accountId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitResponsible(
      accountId: number,
      requestBody: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitResponsible(
        accountId,
        requestBody,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BusinessUnitControllerApi - factory interface
 * @export
 */
export const BusinessUnitControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BusinessUnitControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Archive business unit
     * @param {number} businessUnitId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveBusinessUnit(businessUnitId: number, body: number, options?: any): AxiosPromise<object> {
      return localVarFp.archiveBusinessUnit(businessUnitId, body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create business unit
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {BusinessUnitCreateRequest} businessUnitCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnit(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      businessUnitCreateRequest: BusinessUnitCreateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitUpdateRequest> {
      return localVarFp
        .createBusinessUnit(type, businessUnitCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnit(businessUnitId: number, options?: any): AxiosPromise<object> {
      return localVarFp.deleteBusinessUnit(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnit1(businessUnitId: number, options?: any): AxiosPromise<object> {
      return localVarFp.deleteBusinessUnit1(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnit2(businessUnitId: number, options?: any): AxiosPromise<object> {
      return localVarFp.deleteBusinessUnit2(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download icon by business unit id
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconByBusinessUnitId(businessUnitId: number, options?: any): AxiosPromise<any> {
      return localVarFp.downloadIconByBusinessUnitId(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitById(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitView> {
      return localVarFp.getBusinessUnitById(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitById1(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitView> {
      return localVarFp.getBusinessUnitById1(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitById2(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitView> {
      return localVarFp.getBusinessUnitById2(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitForEdit(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitUpdateRequest> {
      return localVarFp.getBusinessUnitForEdit(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitForEdit1(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitUpdateRequest> {
      return localVarFp.getBusinessUnitForEdit1(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit request model for update
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitForEdit2(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitUpdateRequest> {
      return localVarFp.getBusinessUnitForEdit2(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanies(options?: any): AxiosPromise<Array<BusinessUnitShortView>> {
      return localVarFp.getCompanies(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects(options?: any): AxiosPromise<Array<BusinessUnitShortView>> {
      return localVarFp.getProjects(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unarchive business unit
     * @param {number} businessUnitId
     * @param {number} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unarchiveBusinessUnit(businessUnitId: number, body: number, options?: any): AxiosPromise<object> {
      return localVarFp.unarchiveBusinessUnit(businessUnitId, body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update business unit
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {BusinessUnitUpdateRequest} businessUnitUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnit(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      businessUnitUpdateRequest: BusinessUnitUpdateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitUpdateRequest> {
      return localVarFp
        .updateBusinessUnit(type, businessUnitUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit order
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitOrder(
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): AxiosPromise<Array<BusinessUnitShortView>> {
      return localVarFp
        .updateBusinessUnitOrder(updateOrderNumRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update business unit responsible
     * @param {number} accountId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitResponsible(accountId: number, requestBody: Array<number>, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateBusinessUnitResponsible(accountId, requestBody, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * BusinessUnitControllerApi - interface
 * @export
 * @interface BusinessUnitControllerApi
 */
export interface BusinessUnitControllerApiInterface {
  /**
   *
   * @summary Archive business unit
   * @param {number} businessUnitId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  archiveBusinessUnit(businessUnitId: number, body: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Create business unit
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {BusinessUnitCreateRequest} businessUnitCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  createBusinessUnit(
    type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
    businessUnitCreateRequest: BusinessUnitCreateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitUpdateRequest>;

  /**
   *
   * @summary Delete business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  deleteBusinessUnit(businessUnitId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Delete business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  deleteBusinessUnit1(businessUnitId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Delete business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  deleteBusinessUnit2(businessUnitId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Download icon by business unit id
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  downloadIconByBusinessUnitId(businessUnitId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Get business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getBusinessUnitById(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitView>;

  /**
   *
   * @summary Get business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getBusinessUnitById1(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitView>;

  /**
   *
   * @summary Get business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getBusinessUnitById2(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitView>;

  /**
   *
   * @summary Get business unit request model for update
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getBusinessUnitForEdit(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitUpdateRequest>;

  /**
   *
   * @summary Get business unit request model for update
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getBusinessUnitForEdit1(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitUpdateRequest>;

  /**
   *
   * @summary Get business unit request model for update
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getBusinessUnitForEdit2(businessUnitId: number, options?: any): AxiosPromise<BusinessUnitUpdateRequest>;

  /**
   *
   * @summary Get available companies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getCompanies(options?: any): AxiosPromise<Array<BusinessUnitShortView>>;

  /**
   *
   * @summary Get available projects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  getProjects(options?: any): AxiosPromise<Array<BusinessUnitShortView>>;

  /**
   *
   * @summary Unarchive business unit
   * @param {number} businessUnitId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  unarchiveBusinessUnit(businessUnitId: number, body: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Update business unit
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {BusinessUnitUpdateRequest} businessUnitUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  updateBusinessUnit(
    type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
    businessUnitUpdateRequest: BusinessUnitUpdateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitUpdateRequest>;

  /**
   *
   * @summary Update the business unit order
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  updateBusinessUnitOrder(
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ): AxiosPromise<Array<BusinessUnitShortView>>;

  /**
   *
   * @summary Update business unit responsible
   * @param {number} accountId
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApiInterface
   */
  updateBusinessUnitResponsible(accountId: number, requestBody: Array<number>, options?: any): AxiosPromise<void>;
}

/**
 * BusinessUnitControllerApi - object-oriented interface
 * @export
 * @class BusinessUnitControllerApi
 * @extends {BaseAPI}
 */
export class BusinessUnitControllerApi extends BaseAPI implements BusinessUnitControllerApiInterface {
  /**
   *
   * @summary Archive business unit
   * @param {number} businessUnitId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public archiveBusinessUnit(businessUnitId: number, body: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .archiveBusinessUnit(businessUnitId, body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create business unit
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {BusinessUnitCreateRequest} businessUnitCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public createBusinessUnit(
    type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
    businessUnitCreateRequest: BusinessUnitCreateRequest,
    options?: any
  ) {
    return BusinessUnitControllerApiFp(this.configuration)
      .createBusinessUnit(type, businessUnitCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public deleteBusinessUnit(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .deleteBusinessUnit(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public deleteBusinessUnit1(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .deleteBusinessUnit1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public deleteBusinessUnit2(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .deleteBusinessUnit2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download icon by business unit id
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public downloadIconByBusinessUnitId(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .downloadIconByBusinessUnitId(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getBusinessUnitById(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getBusinessUnitById(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getBusinessUnitById1(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getBusinessUnitById1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getBusinessUnitById2(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getBusinessUnitById2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit request model for update
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getBusinessUnitForEdit(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getBusinessUnitForEdit(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit request model for update
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getBusinessUnitForEdit1(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getBusinessUnitForEdit1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit request model for update
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getBusinessUnitForEdit2(businessUnitId: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getBusinessUnitForEdit2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available companies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getCompanies(options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getCompanies(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available projects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public getProjects(options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .getProjects(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unarchive business unit
   * @param {number} businessUnitId
   * @param {number} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public unarchiveBusinessUnit(businessUnitId: number, body: number, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .unarchiveBusinessUnit(businessUnitId, body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update business unit
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {BusinessUnitUpdateRequest} businessUnitUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public updateBusinessUnit(
    type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
    businessUnitUpdateRequest: BusinessUnitUpdateRequest,
    options?: any
  ) {
    return BusinessUnitControllerApiFp(this.configuration)
      .updateBusinessUnit(type, businessUnitUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit order
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public updateBusinessUnitOrder(updateOrderNumRequest: UpdateOrderNumRequest, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .updateBusinessUnitOrder(updateOrderNumRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update business unit responsible
   * @param {number} accountId
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitControllerApi
   */
  public updateBusinessUnitResponsible(accountId: number, requestBody: Array<number>, options?: any) {
    return BusinessUnitControllerApiFp(this.configuration)
      .updateBusinessUnitResponsible(accountId, requestBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}
