import React from 'react';

import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export function Spinner(props: CircularProgressProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <CircularProgress {...props} />
    </Box>
  );
}

type SpinnerButtonProps = CircularProgressProps & { label: string };

export function SpinnerButton({ label, ...props }: SpinnerButtonProps) {
  return (
    <Box component="span">
      <CircularProgress {...props} />
      <Box component="span" ml={1}>
        {label}
      </Box>
    </Box>
  );
}
