import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitUpdateRequest } from 'api/generated';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const RESOURCE_NAME = 'project';

const ProjectInfoApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type ProjectInfoProviderType = QueryObserverResult<BusinessUnitUpdateRequest, unknown>;

const ProjectInfoContext = createContext<ProjectInfoProviderType | null>(null);
ProjectInfoContext.displayName = `${upperFirst(RESOURCE_NAME)}Info`;

export function useProjectInfoProvider(): ProjectInfoProviderType {
  const contextState = useContext(ProjectInfoContext);
  if (isNil(contextState)) {
    throw new Error(`${useProjectInfoProvider.name} must be used within a ${ProjectInfoContext.displayName} context`);
  }
  return contextState;
}

interface ProjectInfoProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function ProjectInfoProvider(props: React.PropsWithChildren<ProjectInfoProviderProps>) {
  const value = useQuery<BusinessUnitUpdateRequest | null, AxiosError<unknown>, BusinessUnitUpdateRequest>(
    [RESOURCE_NAME, 'info', { businessUnitId: props.businessUnitId }],
    () => ProjectInfoApi.getBusinessUnitForEdit1(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <ProjectInfoContext.Provider value={value}>{props.children}</ProjectInfoContext.Provider>;
}
