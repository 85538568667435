import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAuthProvider } from 'api/AuthProviders';
import { getPersonPhotoURL } from 'api/http';
import { PersonSimpleView, UserSessionStateViewRoleEnum } from 'api/generated';

import { Spinner } from 'components/material/Spinner';

import { useRouteMatch } from 'react-router-dom';
import { MemberList } from 'components/lists/MemberList';
import { PersonsActionsButton } from 'pages/All/PersonsAndGroups/PersonsList/PersonsActionsButton';

export interface PersonListProps {
  businessUnitId: number;
  persons: PersonSimpleView[] | undefined;
  isLoading: boolean;
}

/**
 * Component renders list of specified persons.
 * @param param0
 * @returns
 */
export function PersonList({ businessUnitId, persons, isLoading }: PersonListProps) {
  const { t } = useTranslation();
  const { role } = useAuthProvider();
  const { url } = useRouteMatch();
  const canEditPerson = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <MemberList
      members={persons}
      getMemberLinkUrl={person => `${url}/person/${person.id}/show`}
      getActionButtons={person => (canEditPerson ? <PersonsActionsButton person={person} /> : undefined)}
      getMemberPhotoUrl={person => getPersonPhotoURL(businessUnitId, person.id)}
      emptyText={t('persons:empty')}
    />
  );
}
