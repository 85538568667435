import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function TrashIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="16" height="18" viewBox="0 0 16 18" color="inherit" fill="none" {...props}>
      <path
        d="M13.8264 16.0925L14.3967 4.34037H15.3884C15.7273 4.34037 16 4.05748 16 3.72609C16 3.3947 15.7273 3.11989 15.3884 3.11989H11.6116V1.9075C11.6116 0.743601 10.8099 0 9.53719 0H6.44628C5.17355 0 4.38017 0.743601 4.38017 1.9075V3.11989H0.61157C0.280992 3.11989 0 3.40278 0 3.72609C0 4.05748 0.280992 4.34037 0.61157 4.34037H1.61157L2.18182 16.1006C2.23967 17.2564 3.01653 18 4.21488 18H11.7934C12.9752 18 13.7686 17.2483 13.8264 16.0925ZM5.70248 1.98833C5.70248 1.52762 6.04132 1.20431 6.54545 1.20431H9.44628C9.95041 1.20431 10.2975 1.52762 10.2975 1.98833V3.11989H5.70248V1.98833ZM4.33058 16.7795C3.8595 16.7795 3.50413 16.432 3.47934 15.9551L2.90083 4.34037H13.0744L12.5289 15.9551C12.5041 16.4401 12.157 16.7795 11.6612 16.7795H4.33058ZM10.4545 15.3651C10.7273 15.3651 10.9256 15.1468 10.9339 14.8397L11.1818 6.36102C11.1901 6.06197 10.9835 5.83565 10.7025 5.83565C10.4463 5.83565 10.2314 6.07005 10.2231 6.36102L9.97521 14.8316C9.96694 15.1307 10.1736 15.3651 10.4545 15.3651ZM5.55372 15.3651C5.83471 15.3651 6.04132 15.1307 6.03306 14.8316L5.77686 6.36102C5.76859 6.07005 5.55372 5.83565 5.29752 5.83565C5.01653 5.83565 4.80992 6.06197 4.81818 6.36102L5.06612 14.8397C5.07438 15.1468 5.27273 15.3651 5.55372 15.3651ZM8 15.3651C8.27273 15.3651 8.50413 15.1307 8.50413 14.8397V6.36102C8.50413 6.07005 8.27273 5.83565 8 5.83565C7.73554 5.83565 7.50413 6.07005 7.50413 6.36102V14.8397C7.50413 15.1307 7.73554 15.3651 8 15.3651Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default TrashIcon;
