import React from 'react';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n/config';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { TextField, PhoneField } from 'components/fields';
import { composeValidators, email, phoneLength, maxLength, numbers } from 'components/fields/validators';
import { numbersFilter, maxLengthFilter, composeFilters } from './valueFilters';
import { useFormState } from 'react-final-form';
export interface ContactsProps {
  i18nNamespace: 'persons' | 'accounts';
  disabled?: boolean;
}
interface ContactsFormFields {
  phone?: string;
  accountPhone?: string;
  additionalPhone?: string;
  extensionNumber?: string;
  receptionPhone?: string;
  receptionExtensionNumber?: string;
  email?: string;
}
export function ContactFields({ i18nNamespace, disabled }: ContactsProps) {
  const { t } = useTranslation();
  const { values } = useFormState<ContactsFormFields>();

  const isReceptionExtNumDisabled = !values.receptionPhone && !values.receptionExtensionNumber;
  const isExtNumDisabled = !values.additionalPhone && !values.extensionNumber;
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">{t(`${i18nNamespace}:contacts.contactsSection`)}</Typography>
      </Box>
      <Grid container direction="column">
        <Grid item xs={6}>
          <PhoneField
            label={t(`${i18nNamespace}:contacts.fields.phone`)}
            placeholder={t(`${i18nNamespace}:contacts.fields.phonePlaceholder`)}
            name={i18nNamespace === 'accounts' ? 'accountPhone' : 'phone'}
            margin="dense"
            fieldProps={{ validate: phoneLength }}
            disabled={disabled}
          />
        </Grid>
        <Grid item container direction="row" xs={12}>
          <Grid item xs={6}>
            <PhoneField
              label={t(`${i18nNamespace}:contacts.fields.receptionPhone`)}
              placeholder={t(`${i18nNamespace}:contacts.fields.receptionPhonePlaceholder`)}
              name="receptionPhone"
              margin="dense"
              showError={({ meta }) => meta.error}
              fieldProps={{
                validate: getPhoneValidator(!values.receptionExtensionNumber),
                // according to final-form doc we should update the key when we update validator
                // https://final-form.org/docs/react-final-form/types/FieldProps#validate
                key: Number(!values.receptionExtensionNumber),
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Box ml={1.5} width={170}>
              <TextField
                label={t(`${i18nNamespace}:contacts.fields.receptionExtensionNumber`)}
                placeholder={
                  isReceptionExtNumDisabled
                    ? ''
                    : t(`${i18nNamespace}:contacts.fields.receptionExtensionNumberPlaceholder`)
                }
                name="receptionExtensionNumber"
                margin="dense"
                disabled={isReceptionExtNumDisabled || disabled}
                valueFilter={extensionNumberValueFilter}
                fieldProps={{ validate: extensionNumberValidator }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container direction="row" xs={12}>
          <Grid item xs={6}>
            <PhoneField
              label={t(`${i18nNamespace}:contacts.fields.additionalPhone`)}
              placeholder={t(`${i18nNamespace}:contacts.fields.additionalPhonePlaceholder`)}
              name="additionalPhone"
              margin="dense"
              showError={({ meta }) => meta.error}
              fieldProps={{
                validate: getPhoneValidator(!values.extensionNumber),
                // according to final-form doc we should update the key when we update validator
                // https://final-form.org/docs/react-final-form/types/FieldProps#validate
                key: Number(!values.extensionNumber),
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Box ml={1.5} width={170}>
              <TextField
                label={t(`${i18nNamespace}:contacts.fields.extensionNumber`)}
                placeholder={isExtNumDisabled ? '' : t(`${i18nNamespace}:contacts.fields.extensionNumberPlaceholder`)}
                name="extensionNumber"
                margin="dense"
                disabled={isExtNumDisabled || disabled}
                valueFilter={extensionNumberValueFilter}
                fieldProps={{ validate: extensionNumberValidator }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t(`${i18nNamespace}:contacts.fields.email`)}
            placeholder={t(`${i18nNamespace}:contacts.fields.emailPlaceholder`)}
            type="email"
            name="email"
            margin="dense"
            fieldProps={{ validate: emailValidator }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const getPhoneValidator = (allowEmpty: boolean) => {
  // use async validator because sync one doesn't work properly when
  // we change extensionNumber fields
  const validator = allowEmpty
    ? phoneLength
    : composeValidators<string | undefined>(
        phoneLength,
        // forbid when main field is empty and ext num exists
        // case when we filled ext number and then cleaned the main field
        (val: string | undefined) => (allowEmpty || val?.length ? undefined : i18n.t('form:errors.mainFieldIsEmpty'))
      );
  return async (v: any) => validator(v);
};
const extensionNumberValidator = composeValidators(maxLength(6), numbers());
const extensionNumberValueFilter = composeFilters(numbersFilter(), maxLengthFilter(6));
const emailValidator = composeValidators(email, maxLength(255));
