import React, { useEffect } from 'react';

import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { MOBILE_APPS_PAGE_PATH } from '../Router';

import { Header } from './Header';
import { headerHeight } from '../../styles/constants';

interface MainLayoutProps {}
export function MainLayout(props: React.PropsWithChildren<MainLayoutProps>) {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (isMobile) {
      history.push(`${MOBILE_APPS_PAGE_PATH}/download`);
    }
  }, [history]);

  return (
    <Box
      overflow="auto"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      height="100vh"
      width="100%"
      minWidth="fit-content"
    >
      <Box overflow="auto" display="flex" flexDirection="column" flexGrow={1} height="100vh">
        <Header />
        <Box className={classes.main}>{props.children}</Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    width: '100vw',
    overflow: 'auto',
    marginTop: `${headerHeight}`,
    height: `calc(100vh - ${headerHeight})`,
  },
}));
