import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { CompaniesSelect } from './CompaniesSelect';
import { ProjectsSelect } from './ProjectsSelect';
import { ContactFields } from 'components/fields/ContactFields';

export function ResponsibleFields() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6">{t('accounts:roles.ROLE_RESPONSIBLE')}</Typography>
      <Grid container direction="column">
        <Grid container item direction="row">
          <Grid item xs={6}>
            <Box pr={1.5}>
              <CompaniesSelect
                fieldName="responsibleCompaniesIds"
                label={t('accounts:create.responsible.companiesSection')}
                placeholder={t('accounts:create.fields.companiesPlaceholder')}
                secondaryListItemText={t('accounts:create.responsible.responsible')}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <ProjectsSelect
              fieldName="responsibleProjectIds"
              label={t('accounts:create.responsible.projectsSection')}
              placeholder={t('accounts:create.fields.projectsPlaceholder')}
              secondaryListItemText={t('accounts:create.responsible.responsible')}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box mt={2.5}>
            <ContactFields i18nNamespace="accounts" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
