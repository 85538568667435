import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { PersonFolderControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from '../../SectionsProviders/constants';

import isNil from 'lodash/isNil';

const PersonFolderApi = PersonFolderControllerApiFactory(ApiConfiguration);

type MemberPositionDeleteProviderType = UseMutationResult<object, AxiosError<unknown>, void>;

const MemberPositionDeleteContext = createContext<MemberPositionDeleteProviderType | null>(null);
MemberPositionDeleteContext.displayName = 'MemberPositionDelete';

export function useMemberPositionDeleteProvider(): MemberPositionDeleteProviderType {
  const contextState = useContext(MemberPositionDeleteContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMemberPositionDeleteProvider.name} must be used within a ${MemberPositionDeleteContext.displayName} context`
    );
  }
  return contextState;
}

interface MemberPositionDeleteProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  personFolderId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, void>;
}

export function MemberPositionDeleteProvider(props: React.PropsWithChildren<MemberPositionDeleteProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: MemberPositionDeleteProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([
        SECTION_RESOURCE_NAME,
        props.businessUnitId,
        props.sectionId,
        props.groupFolderId,
      ]);
    },
  };
  const remove = useMutation<object, AxiosError<unknown>, void>(
    async () => {
      return PersonFolderApi.deletePersonFolders(
        props.businessUnitId,
        props.sectionId,
        props.groupFolderId,
        props.personFolderId
      ).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <MemberPositionDeleteContext.Provider value={remove}>{props.children}</MemberPositionDeleteContext.Provider>;
}
