import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function MobilePhoneIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="66" height="108" viewBox="0 0 66 108" color="inherit" fill="none" {...props}>
      <path
        d="M14.1211 107.402H51.9316C60.2109 107.402 65.6953 102.076 65.6953 94.1133V13.7461C65.6953 5.7832 60.2109 0.509766 51.9316 0.509766H14.1211C5.8418 0.509766 0.304688 5.7832 0.304688 13.7461V94.166C0.304688 102.076 5.8418 107.402 14.1211 107.402ZM15.3867 98.6484C11.2734 98.6484 8.95312 96.4336 8.95312 92.4785V15.4336C8.95312 11.4785 11.2734 9.26367 15.3867 9.26367H17.4434V11.6367C17.4434 13.7461 18.8145 15.1699 20.9238 15.1699H45.1289C47.2383 15.1699 48.6094 13.7461 48.6094 11.6367V9.26367H50.6133C54.7266 9.26367 57.0469 11.4785 57.0469 15.4336V92.4785C57.0469 96.4336 54.7266 98.6484 50.6133 98.6484H15.3867ZM19.8164 94.8516H46.2363C47.6074 94.8516 48.6094 93.9551 48.6094 92.4785C48.6094 91.0547 47.6074 90.1055 46.2363 90.1055H19.8164C18.4453 90.1055 17.4434 91.0547 17.4434 92.4785C17.4434 93.9551 18.4453 94.8516 19.8164 94.8516Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default MobilePhoneIcon;
