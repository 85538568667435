import i18n from 'i18n/config';
import isNil from 'lodash/isNil';
import isToday from 'date-fns/isToday';
import { FieldValidator } from 'final-form';
import { useUtils } from '@material-ui/pickers';
import { ParsableDate } from '../DatePicker';

export const notToday: FieldValidator<ParsableDate> = value => {
  return value && isToday(new Date(value as any)) ? i18n.t('form:errors.notToday') : undefined;
};

export const validDate: FieldValidator<ParsableDate> = value => {
  return isNil(value) || !isNaN(new Date(value as any).getTime()) ? undefined : i18n.t('form:errors.invalidDate');
};

export const dateShouldBeBefore: (
  limit: Date,
  dateUtils: ReturnType<typeof useUtils>
) => FieldValidator<ParsableDate> = (limit, dateUtils) => {
  return value => {
    const dateValue = isNil(value) ? null : dateUtils.date(value);
    // skip null and nan and show error only when the last condition is false
    return isNil(dateValue) || isNaN(dateValue.getTime()) || dateValue < limit
      ? undefined
      : i18n.t('form:errors.dateShouldBeBefore', { limit: dateUtils.getDatePickerHeaderText(limit) });
  };
};

export const dateShouldBeAfter: (
  limit: Date,
  dateUtils: ReturnType<typeof useUtils>
) => FieldValidator<ParsableDate> = (limit, dateUtils) => {
  return value => {
    const dateValue = isNil(value) ? null : dateUtils.date(value);

    // skip null and nan and show error only when the last condition is false
    return isNil(dateValue) || isNaN(dateValue.getTime()) || dateValue > limit
      ? undefined
      : i18n.t('form:errors.dateShouldBeAfter', { limit: dateUtils.getDatePickerHeaderText(limit) });
  };
};
