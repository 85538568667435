import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function AccountCircleIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" color="inherit" fill="none" {...props}>
      <path
        d="M10.6792 19.9327C16.1138 19.9327 20.6152 15.4313 20.6152 9.9967C20.6152 4.56213 16.1035 0.0606689 10.6689 0.0606689C5.23438 0.0606689 0.73291 4.56213 0.73291 9.9967C0.73291 15.4313 5.24463 19.9327 10.6792 19.9327ZM10.6792 13.319C7.81836 13.319 5.61377 14.3444 4.61914 15.4928C3.30664 14.047 2.51709 12.1193 2.51709 9.9967C2.51709 5.46448 6.13672 1.82434 10.6689 1.82434C15.2012 1.82434 18.8413 5.46448 18.8516 9.9967C18.8516 12.1193 18.0518 14.047 16.7393 15.4928C15.7446 14.3444 13.54 13.319 10.6792 13.319ZM10.6792 11.6886C12.5454 11.7091 14.0117 10.1095 14.0117 8.02795C14.0117 6.06946 12.5352 4.43909 10.6792 4.43909C8.81299 4.43909 7.33643 6.06946 7.34668 8.02795C7.35693 10.1095 8.80273 11.6783 10.6792 11.6886Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default AccountCircleIcon;
