import React from 'react';
import classnames from 'classnames';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Link, LinkProps } from 'react-router-dom';
import { MemberListEmptyContent } from './MemberListEmptyContent';
import { Avatar } from '@material-ui/core';

export interface Member {
  id: number;
  displayName: string;
}

export interface MemberListProps<T extends Member> {
  members: T[] | undefined;
  getMemberLinkUrl: (member: T) => LinkProps['to'];
  getMemberPhotoUrl: (member: T) => string;
  getActionButtons: (member: T) => React.ReactElement | undefined;
  /**
   * Text to display when list is empty.
   */
  emptyText: string;
}
/**
 * Component that renders list of members with avatar and action button.
 * @param param0
 * @returns
 */
export function MemberList<T extends Member = Member>({
  members,
  getMemberLinkUrl,
  getMemberPhotoUrl,
  getActionButtons,
  emptyText,
}: MemberListProps<T>) {
  const classes = useStyles();

  if (!members || isEmpty(members)) {
    return (
      <Box className={classes.root}>
        <MemberListEmptyContent text={emptyText} />
      </Box>
    );
  }

  return (
    <List classes={{ root: classes.root }}>
      {map(members, member => {
        const actionButtons = getActionButtons(member);
        return (
          <ListItem
            component={Link}
            to={getMemberLinkUrl(member)}
            key={member.id}
            button
            divider
            disableGutters
            alignItems="center"
          >
            <ListItemAvatar>
              <Avatar
                alt={member.displayName}
                src={getMemberPhotoUrl(member)}
                className={classnames(classes.itemIcon)}
              />
            </ListItemAvatar>
            <ListItemText primary={member.displayName} />
            {actionButtons && <ListItemSecondaryAction>{actionButtons}</ListItemSecondaryAction>}
          </ListItem>
        );
      })}
    </List>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 400,
  },
  itemIcon: {
    width: 48,
    height: 48,
  },
}));
