import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitSectionControllerApiFactory, BusinessUnitSectionShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const SectionShowApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

type SectionShowProviderType = QueryObserverResult<BusinessUnitSectionShortView, unknown>;

const SectionShowContext = createContext<SectionShowProviderType | null>(null);
SectionShowContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useSectionShowProvider(): SectionShowProviderType {
  const contextState = useContext(SectionShowContext);
  if (isNil(contextState)) {
    throw new Error(`${useSectionShowProvider.name} must be used within a ${SectionShowContext.displayName} context`);
  }
  return contextState;
}

interface SectionShowProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: any;
}

export function SectionShowProvider(props: React.PropsWithChildren<SectionShowProviderProps>) {
  const value = useQuery<BusinessUnitSectionShortView | null, AxiosError<unknown>, BusinessUnitSectionShortView>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId, sectionId: props.sectionId }],
    () => SectionShowApi.getBusinessUnitSection2(props.businessUnitId, props.sectionId).then(resp => resp.data),
    props.queryOptions
  );
  return <SectionShowContext.Provider value={value}>{props.children}</SectionShowContext.Provider>;
}
