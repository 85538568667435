import React, { useRef, useCallback, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';

import { FolderDeleteProvider } from 'api/DocumentProviders/Folders';
import { DeleteFolderConfirmDialog } from '../../FoldersDialogs';

export function FolderActionsButton({
  businessUnitId,
  sectionId,
  folderId,
  parentFolderId,
}: {
  businessUnitId: number;
  sectionId: number;
  folderId: number;
  parentFolderId?: number;
}) {
  const { t } = useTranslation();
  const location = useLocation();

  const [isMenuOpened, setMenuOpen] = useState(false);
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);

  const handleToggleActionMenu = useCallback(event => {
    event.preventDefault();
    setMenuOpen(prevOpen => !prevOpen);
  }, []);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const handleCloseActionMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };

  const showConfirmRemoveDialog = useCallback(event => {
    event.preventDefault();
    handleCloseActionMenu(event);
    setOpenConfirmRemove(true);
  }, []);

  const closeConfirmDialog = useCallback(event => {
    setOpenConfirmRemove(false);
  }, []);

  const classes = useStyles();

  return (
    <Box>
      <IconButton ref={anchorButtonRef} onClick={handleToggleActionMenu} className={classes.actionsButton}>
        <MoreVertIcon style={{ color: '#C2C6D2' }} />
      </IconButton>
      <Popper
        open={isMenuOpened}
        anchorEl={anchorButtonRef.current}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            offset: '0, +8px',
          },
        }}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseActionMenu}>
                <MenuList autoFocusItem={isMenuOpened} onKeyDown={handleListKeyDown} classes={{ root: classes.list }}>
                  <MenuItem
                    className={classes.menuItem}
                    component={Link}
                    to={`${location.pathname}/folder/${folderId}/edit_folder`}
                    onClick={handleCloseActionMenu}
                    data-test="EditFolderButton"
                  >
                    {t('documents:actionsMenu.edit')}
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={classNames(classes.menuItem, classes.menuItemWarn)}
                    onClick={showConfirmRemoveDialog}
                    data-test="RemoveFolderButton"
                  >
                    {t('documents:actionsMenu.remove')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <FolderDeleteProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        folderId={folderId}
        parentFolderId={parentFolderId}
      >
        <DeleteFolderConfirmDialog
          open={openConfirmRemove}
          onClose={closeConfirmDialog}
          onDelete={closeConfirmDialog}
        />
      </FolderDeleteProvider>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  actionsButton: {
    marginRight: theme.spacing(-2),
  },
  list: {
    padding: 0,
    borderRadius: 8,
  },
  menuItem: {
    padding: theme.spacing(1.5, 2),
    minWidth: 230,
  },
  menuItemWarn: {
    color: theme.palette.error.main,
  },
}));
