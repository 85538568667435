import React from 'react';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';

import { getPersonPhotoURL, getBusinessUnitIconURL } from 'api/http';
import Box from '@material-ui/core/Box';

import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Avatar } from '@material-ui/core';

function MemberSearchResultItem({ person }: { person: any }) {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  return (
    <ListItem
      component={Link}
      to={{ pathname: `${url}/${person.businessUnitId}/person/${person.personId}/show`, search }}
      button
      disableGutters
      divider
      alignItems="center"
    >
      {person.businessUnitId && person.personId && (
        <Box>
          <ListItemAvatar style={{ position: 'absolute', zIndex: 3 }}>
            <Avatar
              alt={person.fullName}
              src={getBusinessUnitIconURL(person.businessUnitId)}
              style={{ width: 20, height: 20, top: 30, left: 30 }}
            />
          </ListItemAvatar>
          <ListItemAvatar>
            <Avatar alt={person.fullName} src={getPersonPhotoURL(person.businessUnitId, person.personId)} />
          </ListItemAvatar>
        </Box>
      )}
      <Box>
        <Box style={{ fontSize: 18, fontWeight: 'bold' }}>{person.fullName}</Box>
        <Box style={{ fontSize: 14, color: '#83899B' }}>{person.businessUnitTitle}</Box>
      </Box>
    </ListItem>
  );
}

export default MemberSearchResultItem;
