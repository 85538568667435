import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PhoneIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" color="inherit" fill="none" {...props}>
      <path
        d="M13.1836 17.2764C14.7217 17.2764 15.7324 16.8633 16.6201 15.8613C16.6904 15.791 16.7607 15.7119 16.8223 15.6416C17.3496 15.0527 17.5957 14.4727 17.5957 13.9189C17.5957 13.2861 17.2266 12.6973 16.4443 12.1523L13.8867 10.377C13.0957 9.83203 12.1729 9.77051 11.4346 10.5L10.7578 11.1768C10.5557 11.3789 10.3799 11.3877 10.1865 11.2646C9.71191 10.9658 8.75391 10.1309 8.10352 9.48047C7.41797 8.80371 6.75879 8.04785 6.40723 7.49414C6.29297 7.29199 6.30176 7.125 6.50391 6.92285L7.17188 6.24609C7.91016 5.50781 7.84863 4.57617 7.30371 3.79395L5.52832 1.23633C4.9834 0.454102 4.39453 0.09375 3.76172 0.0849609C3.20801 0.0761719 2.62793 0.331055 2.03906 0.858398C1.95996 0.928711 1.88965 0.990234 1.81934 1.05176C0.817383 1.94824 0.404297 2.95898 0.404297 4.47949C0.404297 6.99316 1.95117 10.0518 4.79004 12.8906C7.61133 15.7119 10.6787 17.2764 13.1836 17.2764ZM13.1924 15.9229C10.9512 15.9668 8.07715 14.2441 5.80078 11.9766C3.50684 9.69141 1.70508 6.7207 1.74902 4.47949C1.7666 3.5127 2.10938 2.67773 2.79492 2.08008C2.85645 2.02734 2.90039 1.9834 2.96191 1.93945C3.22559 1.71094 3.50684 1.58789 3.76172 1.58789C4.0166 1.58789 4.24512 1.68457 4.41211 1.94824L6.11719 4.50586C6.30176 4.77832 6.31934 5.08594 6.04688 5.3584L5.27344 6.13184C4.66699 6.73828 4.71094 7.47656 5.15039 8.06543C5.65137 8.74219 6.52148 9.72656 7.19824 10.3945C7.86621 11.0713 8.92969 12.0205 9.61523 12.5303C10.2041 12.9697 10.9424 13.0137 11.5488 12.4072L12.3223 11.6338C12.5947 11.3613 12.8936 11.3789 13.1748 11.5547L15.7324 13.2598C15.9961 13.4355 16.0928 13.6553 16.0928 13.9189C16.0928 14.1738 15.9697 14.4551 15.7412 14.7188C15.6885 14.7803 15.6533 14.8242 15.6006 14.8857C15.0029 15.5713 14.168 15.9053 13.1924 15.9229Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default PhoneIcon;
