import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { ApiConfiguration } from '../http';
import React, { createContext, useContext } from 'react';
import upperFirst from 'lodash/upperFirst';
import isNil from 'lodash/isNil';
import { RESOURCE_NAME } from './constants';
import { ChangeLanguageRequest } from '../generated/views/change-language-request';
import { NotificationControllerApiFactory } from '../generated';

const NotificationApi = NotificationControllerApiFactory(ApiConfiguration);

type MessagingChangeLanguageProviderType = {
  controller: UseMutationResult<unknown, AxiosError<unknown>, ChangeLanguageRequest>;
};

const MessagingChangeLanguageContext = createContext<MessagingChangeLanguageProviderType | null>(null);
MessagingChangeLanguageContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useMessagingChangeLanguageProvider(): MessagingChangeLanguageProviderType {
  const contextState = useContext(MessagingChangeLanguageContext);
  if (isNil(contextState)) {
    throw new Error(
      `${MessagingChangeLanguageProvider.name} must be used within a ${MessagingChangeLanguageContext.displayName} context`
    );
  }
  return contextState;
}

interface MessagingChangeLanguageProviderProps {}

export function MessagingChangeLanguageProvider(props: React.PropsWithChildren<MessagingChangeLanguageProviderProps>) {
  const changeLanguage = useMutation<unknown, AxiosError<unknown>, ChangeLanguageRequest>(
    async ({ language }: ChangeLanguageRequest) => NotificationApi.changeLanguage({ language }).then(resp => resp.data)
  );

  return (
    <MessagingChangeLanguageContext.Provider
      value={{
        controller: changeLanguage,
      }}
    >
      {props.children}
    </MessagingChangeLanguageContext.Provider>
  );
}
