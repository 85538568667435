import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Field, FieldInputProps, useFormState } from 'react-final-form';
import { useDropzone } from 'react-dropzone';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { getPersonPhotoURL } from 'api/http';

import { TextField } from 'components/fields';
import { composeValidators, required, maxLength } from 'components/fields/validators';
import { ProfileIcon } from 'components/icons';

import first from 'lodash/first';
import trim from 'utils/trim';
import { PersonLastNameField } from './PersonLastNameField';
import { StructType } from 'api/PersonsProvider';
import { PersonFormValuesType } from '../MemberPositionDialogs/MemberPositionForm';

interface PersonalInfoProps {
  businessUnitId: number;
  structType: StructType;
  disabled?: boolean;
}
export function PersonalInfo({ businessUnitId, structType, disabled }: PersonalInfoProps) {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">{t('persons:form.personalInfoSection')}</Typography>
      </Box>
      <Box>
        <Field name="photo">{({ input }) => <DropzoneAvatar businessUnitId={businessUnitId} {...input} />}</Field>
      </Box>
      <Box display="flex">
        <Box flexGrow={1} flexBasis="50%" mr={1.5}>
          <PersonLastNameField
            textFieldProps={{ disabled }}
            businessUnitId={businessUnitId}
            language="ru"
            structType={structType}
          />
          <TextField
            disabled={disabled}
            label={t('persons:form.fields.firstNameRu')}
            placeholder={t('persons:form.fields.firstNameRuPlaceholder')}
            name="firstNameRu"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            disabled={disabled}
            label={t('persons:form.fields.middleNameRu')}
            placeholder={t('persons:form.fields.middleNameRuPlaceholder')}
            name="middleNameRu"
            margin="dense"
            fieldProps={{
              validate: middleNameValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            disabled={disabled}
            label={t('persons:form.fields.descriptionRu')}
            placeholder={t('persons:form.fields.descriptionRuPlaceholder')}
            name="descriptionRu"
            margin="dense"
            rows={5}
            multiline
            fieldProps={{
              validate: descriptionValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
        </Box>
        <Box flexGrow={1} flexBasis="50%" ml={1.5}>
          <PersonLastNameField
            textFieldProps={{ disabled }}
            businessUnitId={businessUnitId}
            language="en"
            structType={structType}
          />
          <TextField
            disabled={disabled}
            label={t('persons:form.fields.firstNameEn')}
            placeholder={t('persons:form.fields.firstNameEnPlaceholder')}
            name="firstNameEn"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            disabled={disabled}
            label={t('persons:form.fields.middleNameEn')}
            placeholder={t('persons:form.fields.middleNameEnPlaceholder')}
            name="middleNameEn"
            margin="dense"
            fieldProps={{
              validate: middleNameValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            disabled={disabled}
            label={t('persons:form.fields.descriptionEn')}
            placeholder={t('persons:form.fields.descriptionEnPlaceholder')}
            name="descriptionEn"
            margin="dense"
            rows={5}
            multiline
            fieldProps={{
              validate: descriptionValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

const firstLastNamesValidator = composeValidators(required, maxLength(255));
const middleNameValidator = maxLength(255);
const descriptionValidator = maxLength(4000);

function DropzoneAvatar({ onChange, value, businessUnitId }: FieldInputProps<File> & { businessUnitId: number }) {
  const { t } = useTranslation();

  const [filePreview, setFilePreview] = useState<string>();
  const { values: account } = useFormState<PersonFormValuesType>();

  useEffect(() => {
    if (account?.personId) {
      setFilePreview(getPersonPhotoURL(businessUnitId, account?.personId, account?.photoId));
    }
  }, [account?.personId, account?.photoId, businessUnitId]);

  useEffect(() => {
    if (account?.photo && account.photo?.isCopied) {
      fetch(getPersonPhotoURL(account.photo.businessUnitId, account.photo.personId /**, account.photo.photoId */))
        .then(res => {
          return res.blob();
        })
        .then(blob => {
          const file = new File([blob], 'test.png');
          setFilePreview(URL.createObjectURL(blob));
          onChange(file);
        });
    }
  }, [account.photo, onChange]);

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      filePreview && URL.revokeObjectURL(filePreview);
    };
  }, [filePreview]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = first(acceptedFiles);
      if (!file) {
        return;
      }
      setFilePreview(URL.createObjectURL(file));
      onChange(file);
    },
    [onChange]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
    noDragEventsBubbling: true,
  });

  const classes = useDropzoneAvatarStyles();
  return (
    <Box display="flex" alignItems="center" my={4}>
      <Box {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <CustomAvatar src={filePreview} alt="avatar">
          <Box display="flex" alignItems="center" justifyContent="center" fontSize="5rem">
            <ProfileIcon fontSize="inherit" />
          </Box>
        </CustomAvatar>
      </Box>
      <Box ml={3}>
        <Button variant="outlined" onClick={open}>
          {t('persons:form.uploadPhoto')}
        </Button>
      </Box>
    </Box>
  );
}

const useDropzoneAvatarStyles = makeStyles(theme => ({
  dropzone: {
    outline: 'none',
    cursor: 'pointer',
  },
}));

export const CustomAvatar = withStyles(theme => ({
  root: {
    height: 76,
    width: 76,
  },
}))(Avatar);
