import React, { Fragment, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useGroupsListProvider } from 'api/GroupsProvider';

import { DatePicker, TextField } from 'components/fields';
import { TrashIcon } from 'components/icons';
import { Select } from 'components/fields/Select';
import { notToday, required } from 'components/fields/validators';

import { ConfirmDialog, DeleteConfirmDialog } from 'pages/All/ConfirmDialogs';

import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import noop from 'lodash/noop';
import { PersonFolderFormValuesType } from './PersonForm';
import { memberPositionValidator } from '../MemberPositionDialogs/MemberPositionForm';

interface ISelectItem {
  id: number;
  title: string;
  isSection: boolean;
}

export function Groups({ mutators }: { mutators: Record<string, (...args: any[]) => any> }) {
  const { t } = useTranslation();

  const [removeGroupIndex, setRemoveGroupIndex] = useState<number | undefined>(undefined);
  const [impossibleToRemoveGroupIndex, setImpossibleToRemoveGroupIndex] = useState<number | undefined>(undefined);

  const { data: groups } = useGroupsListProvider();

  const getSelectItems = useCallback(() => {
    const sortedGroups = map(groupBy(groups, 'sectionId'), section => ({
      sectionId: section[0].sectionId,
      sectionTitle: section[0].sectionTitle,
      sectionGroups: section,
    }));
    const selectItems: ISelectItem[] = [];
    sortedGroups.forEach(section => {
      selectItems.push({
        id: section.sectionId,
        title: section.sectionTitle,
        isSection: true,
      });
      section.sectionGroups.forEach(({ id, title }) => {
        selectItems.push({
          id,
          title,
          isSection: false,
        });
      });
    });
    return selectItems;
  }, [groups]);

  const classes = useStyles();
  // TODO: move out fields related to position and use one component with group.ts
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">{t('persons:form.groupsSection')}</Typography>
      </Box>
      <FieldArray name="groups" formatOnBlur={false}>
        {({ fields }: FieldArrayRenderProps<PersonFolderFormValuesType, HTMLElement>) =>
          fields.map((name, index) => (
            <Fragment key={name}>
              <Box display="flex" width="50%">
                <Box flexGrow={1}>
                  <Select
                    name={`${name}.groupFolderId`}
                    label={t('persons:form.fields.group')}
                    placeholder={t('persons:form.fields.groupPlaceholder')}
                    formControlProps={{ margin: 'dense' }}
                    required={true}
                    fieldProps={{
                      formatOnBlur: false,
                      validate: required,
                    }}
                    disabled={
                      Boolean(fields.value[index]?.personFolderId) || fields.value[index]?.uiOptions?.disableGroupSelect
                    }
                  >
                    {map(getSelectItems(), ({ id, isSection, title }, index) => (
                      <MenuItem
                        key={index.toString()}
                        disabled={isSection}
                        value={id}
                        dense={false}
                        classes={{ gutters: classes.selectItem }}
                      >
                        <ListItemText id={index.toString()} primary={title} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box display="flex">
                <Box flexGrow={1} flexBasis="50%">
                  <TextField
                    label={t('persons:form.fields.positionRu')}
                    placeholder={t('persons:form.fields.positionRuPlaceholder')}
                    name={`${name}.positionRu`}
                    margin="dense"
                    fieldProps={{
                      validate: memberPositionValidator,
                      formatOnBlur: false,
                    }}
                  />
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <Box ml={1.5}>
                    <TextField
                      label={t('persons:form.fields.positionEn')}
                      placeholder={t('persons:form.fields.positionEnPlaceholder')}
                      name={`${name}.positionEn`}
                      margin="dense"
                      fieldProps={{
                        validate: memberPositionValidator,
                        formatOnBlur: false,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1} flexBasis="50%">
                  <Box display="flex" flexDirection="row" flexGrow={1}>
                    <Box flexGrow={1} flexBasis="50%">
                      <DatePicker
                        label={t('persons:form.fields.positionStartDate')}
                        placeholder={t('persons:form.fields.positionStartDatePlaceholder')}
                        name={`${name}.positionStartDate`}
                        clearable={true}
                        maxDate={fields.value[index]?.positionEndDate || undefined}
                        disableFuture={true}
                        disableToolbar={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="dense"
                      />
                    </Box>
                    <Box flexGrow={1} flexBasis="50%" ml={1.5}>
                      <DatePicker
                        label={t('persons:form.fields.positionEndDate')}
                        placeholder={t('persons:form.fields.positionEndDatePlaceholder')}
                        name={`${name}.positionEndDate`}
                        clearable={true}
                        minDate={fields.value[index]?.positionStartDate || undefined}
                        disableFuture={true}
                        disableToolbar={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="dense"
                        fieldProps={{
                          validate: notToday,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <Box display="flex" justifyContent="flex-end" mt={3} height="100%">
                    <IconButton
                      className={[classes.icon, fields.value[index]?.uiOptions?.hideDelete ? classes.hidden : ''].join(
                        ' '
                      )}
                      onClick={() => {
                        if (fields.value[index]?.hasChildren) {
                          setImpossibleToRemoveGroupIndex(index);
                        } else if (fields.value[index]?.groupFolderId) {
                          setRemoveGroupIndex(index);
                        } else {
                          fields.remove(index);
                        }
                      }}
                    >
                      <TrashIcon style={{ fontSize: 18, color: '#83899B' }} />
                    </IconButton>
                    <DeleteConfirmDialog
                      open={removeGroupIndex === index}
                      onClose={() => setRemoveGroupIndex(undefined)}
                      handleRemove={() => {
                        fields.remove(index);
                        return Promise.resolve().then(() => {
                          setRemoveGroupIndex(undefined);
                        });
                      }}
                      title={t('persons:deletePersonFromGroupConfirmDialog.title')}
                      confirmText={t('persons:deletePersonFromGroupConfirmDialog.text')}
                      primaryButtonDisabled={false}
                    />
                    <ConfirmDialog
                      open={impossibleToRemoveGroupIndex === index}
                      title={t('persons:deletePersonFromGroupWithChildrenConfirmDialog.title')}
                      confirmText={t('persons:deletePersonFromGroupWithChildrenConfirmDialog.text')}
                      handleSubmit={noop}
                      onClose={() => setImpossibleToRemoveGroupIndex(undefined)}
                      primaryButtonDisabled={false}
                    />
                  </Box>
                </Box>
              </Box>
            </Fragment>
          ))
        }
      </FieldArray>
      <Box mt={2} mb={3}>
        <Button size="small" variant="outlined" onClick={() => mutators.push('groups', undefined)}>
          {t('persons:form.addGroupsSection')}
        </Button>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  hidden: { visibility: 'hidden' },
  icon: {
    width: 48,
    height: 48,
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    marginLeft: theme.spacing(2),
  },
  selectItem: { paddingLeft: theme.spacing(2) },
}));
