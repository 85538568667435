import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { UserControllerApiFactory, UserShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const UserApi = UserControllerApiFactory(ApiConfiguration);

type SecretariesListProviderType = QueryObserverResult<UserShortView[]>;

const SecretariesListContext = createContext<SecretariesListProviderType | null>(null);
SecretariesListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useSecretariesListProvider(): SecretariesListProviderType {
  const contextState = useContext(SecretariesListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSecretariesListProvider.name} must be used within a ${SecretariesListContext.displayName} context`
    );
  }
  return contextState;
}

interface SecretariesListProviderProps {
  accountId: number;
  queryOptions?: UseQueryOptions<UserShortView[]>;
}
export function SecretariesListProvider(props: React.PropsWithChildren<SecretariesListProviderProps>) {
  const value = useQuery<UserShortView[]>(
    RESOURCE_NAME,
    () => UserApi.getSecretaries(props.accountId).then(resp => resp.data),
    props.queryOptions
  );
  return <SecretariesListContext.Provider value={value}>{props.children}</SecretariesListContext.Provider>;
}
