import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar } from '@material-ui/core';

import { useMembersByDateListProvider } from 'api/PersonsProvider';

import { makeParentUrl } from 'utils';
import { Spinner } from 'components/material/Spinner';

import { EmptyMembersByDateList } from './EmptyMembersByDateList';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

export function MembersByDateList() {
  const data = useMembersByDateListProvider();
  const { data: persons, isLoading } = data;
  const { url } = useRouteMatch();

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(persons)) {
    return <EmptyMembersByDateList />;
  }

  return (
    <List>
      {map(persons, person => (
        <ListItem
          key={person.id}
          component={Link}
          to={`${makeParentUrl(url)}/person/${person.dataId}/show`}
          button
          disableGutters
          divider
          alignItems="center"
        >
          {person.iconPath && (
            <ListItemAvatar>
              <Avatar alt={person.title} src={person.iconPath} />
            </ListItemAvatar>
          )}
          <ListItemText primary={person.title} secondary={person.description} />
        </ListItem>
      ))}
    </List>
  );
}
