/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Account details (with nested objects).
 * @export
 * @interface AccountView
 */
export interface AccountView {
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  additionalPhone?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountView
   */
  allowableRoles: Array<AccountViewAllowableRolesEnum>;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  extensionNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof AccountView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  loginPhone: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  phone?: string;
  /**
   *
   * @type {number}
   * @memberof AccountView
   */
  primaryUserId?: number;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  receptionExtensionNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  receptionPhone?: string;
  /**
   *
   * @type {number}
   * @memberof AccountView
   */
  secretariesCount: number;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  smsPhone?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  state: AccountViewStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountViewAllowableRolesEnum {
  ROOT = 'ROLE_ROOT',
  ADMIN = 'ROLE_ADMIN',
  RESPONSIBLE = 'ROLE_RESPONSIBLE',
  READER = 'ROLE_READER',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountViewStateEnum {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED',
}
