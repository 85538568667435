/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Section dictionary item creation request
 * @export
 * @interface SectionDictionaryItemCreateRequest
 */
export interface SectionDictionaryItemCreateRequest {
  /**
   * Type of data in Section, it can be \'DOCUMENT\' or \'PERSON\'
   * @type {string}
   * @memberof SectionDictionaryItemCreateRequest
   */
  dataType: SectionDictionaryItemCreateRequestDataTypeEnum;
  /**
   * Icon id
   * @type {number}
   * @memberof SectionDictionaryItemCreateRequest
   */
  iconId: number | null;
  /**
   * Section order number
   * @type {number}
   * @memberof SectionDictionaryItemCreateRequest
   */
  orderNum: number;
  /**
   * English title
   * @type {string}
   * @memberof SectionDictionaryItemCreateRequest
   */
  titleEn: string;
  /**
   * Russian title
   * @type {string}
   * @memberof SectionDictionaryItemCreateRequest
   */
  titleRu: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SectionDictionaryItemCreateRequestDataTypeEnum {
  DOCUMENT = 'DOCUMENT',
  PERSON = 'PERSON',
}
