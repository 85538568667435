import React from 'react';

import Box from '@material-ui/core/Box';

export function ListWrapper(props: { children: React.ReactNode | React.ReactNodeArray }) {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box alignSelf="center" display="flex" flexDirection="column" width="100%" height="100%">
        {props.children}
      </Box>
    </Box>
  );
}
