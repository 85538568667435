import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccessControllerApiFactory, AccountAccessView } from 'api/generated';

import { BusinessUnitType, RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

type BusinessUnitNewAccessProviderType = {
  readers: QueryObserverResult<AccountAccessView[], unknown>;
  responsible: QueryObserverResult<AccountAccessView[], unknown>;
};

const BusinessUnitNewAccessContext = createContext<BusinessUnitNewAccessProviderType | null>(null);
BusinessUnitNewAccessContext.displayName = `${upperFirst(RESOURCE_NAME)}NewAccess`;

export function useNewBusinessUnitAccessProvider(): BusinessUnitNewAccessProviderType {
  const contextState = useContext(BusinessUnitNewAccessContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useNewBusinessUnitAccessProvider.name} must be used within a ${BusinessUnitNewAccessContext.displayName} context`
    );
  }
  return contextState;
}

interface NewBusinessUnitAccessProviderProps {
  type: BusinessUnitType;
  queryOptions?: any;
}

export function NewBusinessUnitAccessProvider(props: React.PropsWithChildren<NewBusinessUnitAccessProviderProps>) {
  const value = {
    readers: useQuery<AccountAccessView[], AxiosError<unknown>, AccountAccessView[]>(
      [RESOURCE_NAME, props.type, 'access', 'reader'],
      () => AccessApi.getBusinessUnitReaderAccess3(props.type).then(resp => resp.data)
    ),
    responsible: useQuery<AccountAccessView[], AxiosError<unknown>, AccountAccessView[]>(
      [RESOURCE_NAME, props.type, 'access', 'responsible'],
      () => AccessApi.getBusinessUnitResponsibleAccess3(props.type).then(resp => resp.data)
    ),
  };

  return <BusinessUnitNewAccessContext.Provider value={value}>{props.children}</BusinessUnitNewAccessContext.Provider>;
}
