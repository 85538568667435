import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function RunMenuIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" color="inherit" fill="none" {...props}>
      <path
        d="M4.72631 6.02751V9.98095H3.02382C1.35521 9.98095 0 11.2931 0 12.9693C0 14.6455 1.35521 16 3.02382 16C4.69243 16 6.04764 14.6455 6.04764 12.9693V11.2762H9.95236V12.9693C9.95236 14.6455 11.3076 16 12.9762 16C14.6448 16 16 14.6455 16 12.9693C16 11.2931 14.6448 9.98095 12.9762 9.98095H11.2737V6.02751H12.9762C14.6448 6.02751 16 4.71534 16 3.03915C16 1.36296 14.6448 0 12.9762 0C11.3076 0 9.95236 1.36296 9.95236 3.03915V4.73228H6.04764V3.03915C6.04764 1.36296 4.69243 0 3.02382 0C1.35521 0 0 1.36296 0 3.03915C0 4.71534 1.35521 6.02751 3.02382 6.02751H4.72631ZM3.02382 4.74921C2.09211 4.74921 1.32133 3.97884 1.32133 3.03915C1.32133 2.09947 2.09211 1.32063 3.02382 1.32063C3.95553 1.32063 4.72631 2.09947 4.72631 3.03915V4.74921H3.02382ZM12.9762 4.74921H11.2737V3.03915C11.2737 2.09947 12.0445 1.32063 12.9762 1.32063C13.9079 1.32063 14.6787 2.09947 14.6787 3.03915C14.6787 3.97884 13.9079 4.74921 12.9762 4.74921ZM6.04764 9.99788V6.01058H9.95236V9.99788H6.04764ZM3.02382 11.2508H4.72631V12.9608C4.72631 13.9005 3.95553 14.6709 3.02382 14.6709C2.09211 14.6709 1.32133 13.9005 1.32133 12.9608C1.32133 12.0212 2.09211 11.2508 3.02382 11.2508ZM12.9762 11.2508C13.9079 11.2508 14.6787 12.0212 14.6787 12.9608C14.6787 13.9005 13.9079 14.6709 12.9762 14.6709C12.0445 14.6709 11.2737 13.9005 11.2737 12.9608V11.2508H12.9762Z"
        fill="color"
      />
    </SvgIcon>
  );
}
