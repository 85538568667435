import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGroupDeleteProvider } from 'api/GroupsProvider';

import { DeleteConfirmDialog } from '../../ConfirmDialogs';

interface DeleteGroupConfirmationDialogProps {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
}
export function DeleteGroupConfirmationDialog({ open, onDelete, onClose }: DeleteGroupConfirmationDialogProps) {
  const { t } = useTranslation();

  const { mutateAsync: deleteGroup, isLoading } = useGroupDeleteProvider();

  const handleRemove = useCallback(
    async event => {
      await deleteGroup({}).then(() => {
        onDelete();
      });
    },
    [deleteGroup, onDelete]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={handleRemove}
      title={t('groups:dialog.deleteConfirmDialog.title')}
      confirmText={t('groups:dialog.deleteConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}
