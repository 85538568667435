import React, { useCallback } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';

import { BusinessUnitShowProvider, BusinessUnitOrderUpdateProvider } from 'api/BusinessUnitProviders';
import { CompaniesListProvider } from 'api/CompaniesProviders';
import { RESOURCE_NAME } from 'api/CompaniesProviders/constants';
import { GroupDetailsProvider } from 'api/GroupsProvider';
import { SectionShowProvider } from 'api/SectionsProviders';
import { useErrorCatcher } from 'api/notifications';

import { COMPANIES_PAGE_PATH } from 'pages/Router';

import { CompaniesColumn } from './CompaniesList/CompaniesColumn';
import { SectionsColumn, EmptySectionsColumn } from '../All/SectionsList';
import { CompaniesPageRouterParams } from './interfaces';
import { DocumentsQueryWrapper } from '../All/DocumentsList/DocumentsQueryWrapper';
import { GroupMembersColumn } from '../All/PersonsAndGroups/GroupMembersColumn';
import { leftColumnWidth } from '../../styles/constants';

import get from 'lodash/get';
import isNaN from 'lodash/isNaN';

export function CompaniesPage() {
  const history = useHistory();
  const match = useRouteMatch<CompaniesPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const parentFolderId = parseInt(match.params.parentFolderId || '', 10);
  const groupFolderId = parseInt(match.params.groupFolderId || '', 10);

  const catchError = useErrorCatcher();

  const onErrorHandler = useCallback(
    (error: unknown) => {
      catchError(error);
      const errStatus = get(error, 'response.status');
      if (errStatus === 403) {
        history.push(COMPANIES_PAGE_PATH);
      }
    },
    [catchError, history]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Box alignSelf="center" justifyContent="stretch" display="flex" width="100%" height="100%">
        <Box width={leftColumnWidth} minWidth={leftColumnWidth} height="100%">
          <CompaniesListProvider queryOptions={{ onError: catchError }}>
            <BusinessUnitOrderUpdateProvider businessUnitId={businessUnitId} resourceName={RESOURCE_NAME}>
              <CompaniesColumn />
            </BusinessUnitOrderUpdateProvider>
          </CompaniesListProvider>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box height="100%" flexGrow="1" overflow="auto" width={`calc(100% - ${leftColumnWidth})`}>
          {isNaN(businessUnitId) && <EmptySectionsColumn />}
          {!!businessUnitId && isNaN(sectionId) && isNaN(parentFolderId) && (
            <BusinessUnitShowProvider businessUnitId={businessUnitId} queryOptions={{ onError: onErrorHandler }}>
              <SectionsColumn />
            </BusinessUnitShowProvider>
          )}
          {!!businessUnitId && !!sectionId && isNaN(groupFolderId) && (
            <SectionShowProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              queryOptions={{ onError: onErrorHandler }}
            >
              <DocumentsQueryWrapper
                businessUnitId={businessUnitId}
                sectionId={sectionId}
                parentFolderId={parentFolderId || undefined}
              />
            </SectionShowProvider>
          )}
          {!!businessUnitId && !!sectionId && !!groupFolderId && (
            <GroupDetailsProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              groupFolderId={groupFolderId}
              queryOptions={{ onError: onErrorHandler }}
            >
              <GroupMembersColumn />
            </GroupDetailsProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
}
