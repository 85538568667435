import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PhoneNumberIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M31.826 25.5652H1.56522C0.701214 25.5652 0 24.864 0 24C0 23.136 0.701214 22.4348 1.56522 22.4348H31.826C32.69 22.4348 33.3912 23.136 33.3912 24C33.3912 24.864 32.69 25.5652 31.826 25.5652Z"
          fill="black"
        />
        <path
          d="M23.4784 33.9129C23.0776 33.9129 22.6771 33.7608 22.3721 33.454C21.7607 32.8426 21.7607 31.8513 22.3721 31.2395L29.6139 23.9981L22.3721 16.7563C21.7607 16.1449 21.7607 15.1532 22.3721 14.5418C22.9839 13.9304 23.9752 13.9304 24.5866 14.5418L32.9343 22.8899C33.5457 23.5013 33.5457 24.4926 32.9343 25.104L24.5866 33.4517C24.2797 33.7608 23.8793 33.9129 23.4784 33.9129Z"
          fill="black"
        />
        <path
          d="M25.0435 46.9566C15.5455 46.9566 7.1604 41.2426 3.67725 32.3977C3.36008 31.5964 3.75635 30.6866 4.55998 30.3694C5.36131 30.0564 6.27346 30.4466 6.59064 31.2544C9.59802 38.8906 16.8417 43.8262 25.0435 43.8262C35.9748 43.8262 44.8697 34.9313 44.8697 24C44.8697 13.0687 35.9748 4.17377 25.0435 4.17377C16.8417 4.17377 9.59802 9.1094 6.59064 16.7456C6.27117 17.5534 5.36131 17.9436 4.55998 17.6306C3.75635 17.3134 3.36008 16.4036 3.67725 15.6022C7.1604 6.75737 15.5455 1.04333 25.0435 1.04333C37.7009 1.04333 48.0001 11.3426 48.0001 24C48.0001 36.6574 37.7009 46.9566 25.0435 46.9566Z"
          fill="#EDEFF6"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PhoneNumberIcon;
