import React from 'react';

import { makeStyles, ToolbarProps } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

interface IModalToolbarProps extends ToolbarProps {}

export function ModalToolbar({ ...props }: IModalToolbarProps) {
  const classes = useStyles();
  return (
    <Toolbar
      classes={{
        root: classes.root,
      }}
    >
      {props.children}
    </Toolbar>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 4),
    boxShadow: '0px -2px 4px rgba(194, 198, 210, 0.19)',
  },
}));
