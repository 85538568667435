import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useErrorCatcher } from 'api/notifications';
import { FolderCreateProvider, useFolderCreateProvider } from 'api/DocumentProviders/Folders';

import { makeParentUrl } from 'utils';
import { Modal } from 'components/material';

import { FolderForm } from './FolderForm';
import { FolderRouterParams } from './interfaces';

export function AddFolderDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();
  const match = useRouteMatch<FolderRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const parentFolderId = parseInt(match.params.parentFolderId || '', 10) || undefined;

  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url));

  return (
    <Modal
      title={t('documents:folder.dialog.createTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={AddFolderDialog.name}
    >
      <FolderCreateProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        queryOptions={{ onError: catchError }}
      >
        <FolderCreate parentFolderId={parentFolderId} />
      </FolderCreateProvider>
    </Modal>
  );
}

function FolderCreate({ parentFolderId }: { parentFolderId?: number }) {
  const match = useRouteMatch();
  const history = useHistory();
  const { mutateAsync } = useFolderCreateProvider();

  const onSubmit = useCallback(
    values => {
      if (parentFolderId) {
        values.parentFolderId = parentFolderId;
      }
      return mutateAsync(values).then(() => history.push(makeParentUrl(match.url)));
    },
    [history, match.url, mutateAsync, parentFolderId]
  );

  return <FolderForm onSubmit={onSubmit} type="CREATE" />;
}
