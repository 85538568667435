import { AccountAccessView } from 'api/generated';
import { AccessInfo } from './SectionForm';

/**
 * Helper to transform form data to request data for access info.
 * @param readerFields
 * @param readerList
 * @returns
 */
export function getAccessInfoForRequest(readerFields: AccessInfo, readerList: AccountAccessView[]) {
  if (readerFields.restrictedAccess) {
    return readerList.map(({ accountDisplayView: { id: accountId } }) => ({
      access: readerFields.allowIds.includes(accountId),
      accountId,
    }));
  }

  return readerList.map(({ accountDisplayView: { id: accountId } }) => ({ access: true, accountId }));
}
