import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { UserControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const UserApi = UserControllerApiFactory(ApiConfiguration);

export interface SecretaryRemoveRequest {
  secretaryId: number;
}

type SecretaryRemoveProviderType = UseMutationResult<number, AxiosError<unknown>, SecretaryRemoveRequest>;

const SecretaryRemoveContext = createContext<SecretaryRemoveProviderType | null>(null);
SecretaryRemoveContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useSecretaryRemoveProvider(): SecretaryRemoveProviderType {
  const contextState = useContext(SecretaryRemoveContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSecretaryRemoveProvider.name} must be used within a ${SecretaryRemoveContext.displayName} context`
    );
  }
  return contextState;
}

interface SecretaryRemoveProviderProps {
  queryOptions?: UseMutationOptions<number, AxiosError<unknown>, SecretaryRemoveRequest>;
}

export function SecretaryRemoveProvider(props: React.PropsWithChildren<SecretaryRemoveProviderProps>) {
  const queryClient = useQueryClient();
  const removeDefaultOptions: SecretaryRemoveProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const remove = useMutation<number, AxiosError<unknown>, SecretaryRemoveRequest>(
    async ({ secretaryId }: SecretaryRemoveRequest) => {
      return UserApi.deleteUser(secretaryId).then(resp => resp.data);
    },
    {
      ...removeDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <SecretaryRemoveContext.Provider value={remove}>{props.children}</SecretaryRemoveContext.Provider>;
}
