import React, { useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { useAuthProvider } from 'api/AuthProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';
import { useCompaniesListProvider } from 'api/CompaniesProviders';

import { ListWrapper } from '../../Layouts/ListWrapper';
import { CompaniesList } from './CompaniesList';
import { AddCompanyButton } from './CompanyCard/AddCompanyButton';
import { CompaniesPageRouterParams } from '../interfaces';

export function CompaniesColumn() {
  const match = useRouteMatch<CompaniesPageRouterParams>();
  const { role } = useAuthProvider();
  const history = useHistory();

  const { data: companiesList } = useCompaniesListProvider();
  const [currentCompanyId, setCurrentCompanyId] = useState<number | null>(null);

  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;

  useEffect(() => {
    const companyId = match.params?.businessUnitId;
    let newCompanyId;
    if (typeof companyId === 'string') {
      newCompanyId = parseInt(companyId, 10);
    }
    if (newCompanyId) {
      setCurrentCompanyId(newCompanyId);
    } else {
      setCurrentCompanyId(null);
    }
  }, [match.params?.businessUnitId]);

  useEffect(() => {
    if (companiesList?.length && !companiesList.find(item => item.id === currentCompanyId)) {
      history.push(`/companies/${companiesList[0].id}`);
      setCurrentCompanyId(companiesList[0].id);
    }
  }, [companiesList, history, currentCompanyId]);

  return (
    <ListWrapper>
      <Box flexGrow="1" overflow="auto">
        <CompaniesList currentCompanyId={currentCompanyId} />
      </Box>
      <Divider />
      {isAdmin && <AddCompanyButton />}
    </ListWrapper>
  );
}
