/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Request for change role
 * @export
 * @interface ChangeRoleRequest
 */
export interface ChangeRoleRequest {
  /**
   * Account role
   * @type {string}
   * @memberof ChangeRoleRequest
   */
  role: ChangeRoleRequestRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ChangeRoleRequestRoleEnum {
  ROOT = 'ROLE_ROOT',
  ADMIN = 'ROLE_ADMIN',
  RESPONSIBLE = 'ROLE_RESPONSIBLE',
  READER = 'ROLE_READER',
}
