/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Represents a structure that describes a company or company outer
 * @export
 * @interface CompanyView
 */
export interface CompanyView {
  /**
   *
   * @type {string}
   * @memberof CompanyView
   */
  iconPath: string;
  /**
   *
   * @type {number}
   * @memberof CompanyView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CompanyView
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CompanyView
   */
  type: CompanyViewTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyViewTypeEnum {
  PROJECT = 'PROJECT',
  COMPANY = 'COMPANY',
  COMPANY_OUTER = 'COMPANY_OUTER',
}
