import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SmsCodeIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="65" height="56" viewBox="0 0 65 56" fill="none" {...props}>
      <rect y="11.0638" width="26.6809" height="44.9362" rx="2" fill="black" />
      <rect x="2.80859" y="13.8723" width="21.0638" height="39.3191" rx="1" fill="white" />
      <rect x="5.61694" y="11.0638" width="15.4468" height="4.21277" rx="2" fill="black" />
      <path
        d="M11.2341 4.63831C11.2341 3.53374 12.1296 2.63831 13.2341 2.63831H55.5746C56.6791 2.63831 57.5746 3.53374 57.5746 4.63831V31.6797C57.5746 32.7843 56.6791 33.6797 55.5746 33.6797H25.915C25.2915 33.6797 24.7036 33.9705 24.3253 34.4662L18.6065 41.9575V33.6797H13.2341C12.1296 33.6797 11.2341 32.7843 11.2341 31.6797V4.63831Z"
        fill="#EDEFF6"
      />
      <path
        d="M19.5674 16.703C19.5674 17.9608 20.3878 18.7733 22.0049 19.1171L23.4346 19.4139C24.2549 19.6014 24.544 19.8514 24.544 20.2968C24.544 20.8436 24.0128 21.1952 23.1534 21.1952C22.2471 21.1952 21.7237 20.8046 21.5831 20.0702H19.3565C19.5049 21.7186 20.8409 22.7968 23.1534 22.7968C25.3096 22.7968 26.8174 21.7108 26.8174 20.0624C26.8174 18.8358 26.0987 18.1483 24.3487 17.7811L22.919 17.4764C22.0753 17.2968 21.7628 17.0077 21.7628 16.578C21.7628 16.0233 22.2862 15.6639 23.0753 15.6639C23.919 15.6639 24.4268 16.1249 24.4971 16.8046H26.5987C26.5518 15.1639 25.2237 14.0624 23.0753 14.0624C20.9424 14.0624 19.5674 15.1093 19.5674 16.703ZM27.9424 22.6171H30.2237V17.5624C30.2237 16.6249 30.8096 15.9686 31.6768 15.9686C32.5206 15.9686 33.0284 16.4999 33.0284 17.3983V22.6171H35.2237V17.5311C35.2237 16.6014 35.794 15.9686 36.6612 15.9686C37.544 15.9686 38.0284 16.4999 38.0284 17.4686V22.6171H40.3096V16.8983C40.3096 15.2108 39.2081 14.0858 37.5674 14.0858C36.3409 14.0858 35.3409 14.7499 34.9659 15.8046H34.919C34.6378 14.7108 33.794 14.0858 32.5831 14.0858C31.4424 14.0858 30.544 14.7499 30.1924 15.7577H30.1456V14.2421H27.9424V22.6171ZM41.6143 16.703C41.6143 17.9608 42.4346 18.7733 44.0518 19.1171L45.4815 19.4139C46.3018 19.6014 46.5909 19.8514 46.5909 20.2968C46.5909 20.8436 46.0596 21.1952 45.2003 21.1952C44.294 21.1952 43.7706 20.8046 43.6299 20.0702H41.4034C41.5518 21.7186 42.8878 22.7968 45.2003 22.7968C47.3565 22.7968 48.8643 21.7108 48.8643 20.0624C48.8643 18.8358 48.1456 18.1483 46.3956 17.7811L44.9659 17.4764C44.1221 17.2968 43.8096 17.0077 43.8096 16.578C43.8096 16.0233 44.3331 15.6639 45.1221 15.6639C45.9659 15.6639 46.4737 16.1249 46.544 16.8046H48.6456C48.5987 15.1639 47.2706 14.0624 45.1221 14.0624C42.9893 14.0624 41.6143 15.1093 41.6143 16.703Z"
        fill="black"
      />
    </SvgIcon>
  );
}

export default SmsCodeIcon;
