/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PinStatusResponse } from '../views';
// @ts-ignore
import { UserView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Expire pin for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireMyPin: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/credentials/expire`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Expire pin for given user. For superusers only.
     * @param {number} userId UserView.id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expirePin: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('expirePin', 'userId', userId);
      const localVarPath = `/credentials/{userId}/expire`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get pin status for the specified user. For superusers only.
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPinStatus: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getPinStatus', 'userId', userId);
      const localVarPath = `/credentials/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Expire pin for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expireMyPin(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expireMyPin(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Expire pin for given user. For superusers only.
     * @param {number} userId UserView.id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expirePin(
      userId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expirePin(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get pin status for the specified user. For superusers only.
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPinStatus(
      userId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PinStatusResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPinStatus(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @summary Expire pin for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireMyPin(options?: any): AxiosPromise<UserView> {
      return localVarFp.expireMyPin(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Expire pin for given user. For superusers only.
     * @param {number} userId UserView.id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expirePin(userId: number, options?: any): AxiosPromise<UserView> {
      return localVarFp.expirePin(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get pin status for the specified user. For superusers only.
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPinStatus(userId: number, options?: any): AxiosPromise<PinStatusResponse> {
      return localVarFp.getPinStatus(userId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
  /**
   *
   * @summary Expire pin for current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  expireMyPin(options?: any): AxiosPromise<UserView>;

  /**
   *
   * @summary Expire pin for given user. For superusers only.
   * @param {number} userId UserView.id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  expirePin(userId: number, options?: any): AxiosPromise<UserView>;

  /**
   *
   * @summary Get pin status for the specified user. For superusers only.
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  getPinStatus(userId: number, options?: any): AxiosPromise<PinStatusResponse>;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
  /**
   *
   * @summary Expire pin for current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public expireMyPin(options?: any) {
    return AuthApiFp(this.configuration)
      .expireMyPin(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Expire pin for given user. For superusers only.
   * @param {number} userId UserView.id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public expirePin(userId: number, options?: any) {
    return AuthApiFp(this.configuration)
      .expirePin(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get pin status for the specified user. For superusers only.
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public getPinStatus(userId: number, options?: any) {
    return AuthApiFp(this.configuration)
      .getPinStatus(userId, options)
      .then(request => request(this.axios, this.basePath));
  }
}
