import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';
import { MobileAppCreateDialog } from './MobileAppsDialogs/MobileAppCreateDialog';

import { MobileAppsListPage } from './MobileAppsList/MobileAppsListPage';

export function MobileAppsRouter() {
  const { path } = useRouteMatch();
  return (
    <Route path={path}>
      <MobileAppsListPage />
      <Route path={`${path}/new`} render={routeProps => <MobileAppCreateDialog routeProps={routeProps} />} />
    </Route>
  );
}
