import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import AutoSizer from 'react-virtualized-auto-sizer';
import { Virtuoso, Components as IVirtuosoComponents } from 'react-virtuoso';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useErrorCatcher } from 'api/notifications';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export const PDFViewer = React.memo(({ fileUrl, scale = 1.0 }: { fileUrl: string; scale?: number }) => {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const [totalNumPages, setTotalNumPages] = useState(0);
  const [loaded, setLoaded] = useState(0);

  const calcLoadProgress = useCallback(({ loaded, total }) => setLoaded(Math.floor((loaded * 100) / total)), []);

  const classes = usePDFViewerStyles();
  return (
    <AutoSizer>
      {({ height, width }) => (
        <Box style={{ height, width }} className={classes.container}>
          <Document
            file={fileUrl}
            onLoadSuccess={pdf => {
              setTotalNumPages(pdf.numPages);
            }}
            options={{
              cMapUrl: '/cmaps/',
              cMapPacked: true,
              disableRange: true,
            }}
            renderMode="canvas"
            externalLinkTarget="_blank"
            onLoadProgress={calcLoadProgress}
            onLoadError={catchError}
            onSourceError={catchError}
            loading={<LinearProgress variant="determinate" value={loaded} />}
            noData={<Error msg={t('common:noDataProvided')} />}
            error={<Error msg={t('common:filedToLoad')} />}
          >
            <Virtuoso
              style={{ height, width }}
              components={VirtuosoComponents}
              totalCount={totalNumPages}
              itemContent={index => <MemoizedPage pageNumber={index + 1} height={height} scale={scale} />}
              defaultItemHeight={height}
              // NOTE: (paddingTop = 8) + (paddingBottom = 8) === 16
              overscan={{ main: (height + 16) * 6, reverse: (height + 16) * 6 }}
            />
          </Document>
        </Box>
      )}
    </AutoSizer>
  );
});

const VirtuosoComponents: IVirtuosoComponents = {
  List: React.forwardRef(({ style, children }, listRef) => {
    return (
      <Box style={style} ref={listRef}>
        {children}
      </Box>
    );
  }),
  Item: React.forwardRef(({ children, ...props }, itemRef) => {
    const classes = usePDFViewerStyles();
    return (
      <Box {...props} className={classes.item} ref={itemRef}>
        {children}
      </Box>
    );
  }),
};

const MemoizedPage = React.memo(
  ({ pageNumber, width, height, scale }: { pageNumber: number; width?: number; height?: number; scale?: number }) => {
    const { t } = useTranslation();
    return (
      <Page
        pageNumber={pageNumber}
        width={width}
        height={height}
        scale={scale}
        renderTextLayer={true}
        loading={''}
        noData={<Error msg={t('common:noDataProvided')} />}
        error={<Error msg={t('common:filedToLoad')} />}
      />
    );
  }
);

const Error: React.FC<{ msg: string }> = ({ msg }) => {
  const classes = usePDFViewerStyles();
  return <Box className={classes.error}>{msg}</Box>;
};

const usePDFViewerStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.default,
  },
  error: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    '& .react-pdf__Page': {
      margin: '0 auto',
      display: 'inline-block',
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
