import { isDate } from 'lodash';

export class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`);
  }
}

export function LogUnreachableCase(value: never) {
  console.error(`Unreachable case: ${value}`);
}

export function discardTime(date: Date | string | null | undefined) {
  return date
    ? isDate(date)
      ? // discard minutes and seconds to be consistent with parsed dates
        new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
      : new Date(date)
    : null;
}
