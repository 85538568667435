import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useLocation, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { useAuthProvider } from 'api/AuthProviders';
import { usePersonListOrderUpdateProvider } from 'api/PersonsProvider';
import { useSectionContentProvider } from 'api/SectionsProviders';
import { ContentItemView, UserSessionStateViewRoleEnum } from 'api/generated';

import { LoadMore } from 'components/common';
import { SortableTree, SortableTreeData } from 'components/common/SortableTree';
import { Spinner } from 'components/material/Spinner';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { PersonAndGroupsHeader } from '../PersonAndGroupsHeader';

import { MemberPositionActionsButton } from './MemberPositionActionsButton';

interface SortablePersonsTreeProps {
  businessUnitId: number;
  sectionId: number;
  parentFolderId: number;
  getEmptyListContent: () => ReactElement;
}
/**
 * Draggable persons list. This is a page for sections with members.
 * @param param0
 * @returns
 */
export function SortablePersonsTree({
  businessUnitId,
  sectionId,
  parentFolderId,
  getEmptyListContent,
}: SortablePersonsTreeProps) {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  const { data, isFetched, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useSectionContentProvider();

  const [persons, setPersons] = useState<ContentItemView[]>([]);

  const {
    controller: { mutateAsync: updateOrder, isLoading: isUpdatingOrder },
  } = usePersonListOrderUpdateProvider();

  const { role } = useAuthProvider();
  const canMakeUpdates = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;

  useEffect(() => {
    setPersons(flatten(data?.pages));
  }, [data]);

  const onDragEnd = useCallback(
    event => {
      const { active, over } = event;
      if (over && active.id !== over.id && !isUpdatingOrder) {
        const activeId = parseInt(active.id, 10);
        const overId = parseInt(over.id, 10);
        const toIndex = over.data?.current?.index;
        updateOrder({
          from: { id: activeId },
          to: { id: overId, index: toIndex },
        });
      }
    },
    [isUpdatingOrder, updateOrder]
  );

  if (isLoading) {
    return <Spinner />;
  }

  function addLink(data: SortableTreeData[]): SortableTreeData[] {
    const query = new URLSearchParams(search);
    query.set('groupFolderId', parentFolderId.toString());
    return map(data, d => ({
      ...d,
      link: `${url}/person/${d.dataId}/show?${query.toString()}`,
      children: addLink(d.children),
    }));
  }

  function addActions(data: SortableTreeData[], groupFolderId: number): SortableTreeData[] {
    return map(data, d => ({
      ...d,
      actions: (
        <MemberPositionActionsButton
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          groupFolderId={groupFolderId}
          member={d}
        />
      ),
      children: addActions(d.children, groupFolderId),
    }));
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" alignItems="center">
        <PersonAndGroupsHeader parentFolderId={parentFolderId} />
      </Box>
      {isEmpty(persons) && isFetched ? (
        getEmptyListContent()
      ) : (
        <>
          <SortableTree
            data={canMakeUpdates ? addActions(addLink(persons), parentFolderId) : addLink(persons)}
            parentFolderId={parentFolderId}
            onDragEnd={onDragEnd}
            disabled={!canMakeUpdates}
          />
          {hasNextPage && (
            <Box my={3.5} mx={8}>
              <LoadMore onLoadMore={fetchNextPage} isLoading={Boolean(isFetchingNextPage)} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
