import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  DocumentControllerApiFactory,
  DocumentMetaUpdateRequest,
  DocumentUpdateRequest,
  FileControllerApiFactory,
  FileMetaView,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import { isFileMetaView } from './type-helpers';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

type DocumentMetaUpdateWithFilesRequest = {
  pdfFile?: File | FileMetaView;
  originalFile?: File | FileMetaView;
  pdfFileEn?: File | FileMetaView;
  originalFileEn?: File | FileMetaView;
} & DocumentMetaUpdateRequest;
export interface DocumentWithFilesUpdateRequest {
  documentMetas: DocumentMetaUpdateWithFilesRequest[];
  version: DocumentUpdateRequest['version'];
}

type DocumentUpdateProviderType = UseMutationResult<
  DocumentUpdateRequest,
  AxiosError<unknown>,
  DocumentWithFilesUpdateRequest,
  unknown
>;

const DocumentUpdateContext = createContext<DocumentUpdateProviderType | null>(null);
DocumentUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}Update`;

export function useDocumentUpdateProvider(): DocumentUpdateProviderType {
  const contextState = useContext(DocumentUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentUpdateProvider.name} must be used within a ${DocumentUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
  queryOptions?: UseMutationOptions<
    DocumentUpdateRequest,
    AxiosError<unknown>,
    DocumentWithFilesUpdateRequest,
    unknown
  >;
}
export function DocumentUpdateProvider(props: React.PropsWithChildren<DocumentUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const updateDefaultOptions: DocumentUpdateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const update = useMutation<DocumentUpdateRequest, AxiosError<unknown>, DocumentWithFilesUpdateRequest>(
    async (data: DocumentWithFilesUpdateRequest) => {
      const promises = data.documentMetas.map(async dm => {
        if (dm.pdfFile && !isFileMetaView(dm.pdfFile)) {
          dm.pdfFileMetaView = await FileApi.upload(dm.pdfFile, dm.pdfFile.name).then(resp => resp.data);
        }
        if (dm.originalFile && !isFileMetaView(dm.originalFile)) {
          dm.originalFileMetaView = await FileApi.upload(dm.originalFile, dm.originalFile.name).then(resp => resp.data);
        }
        return omit(dm, ['pdfFile', 'originalFile']) as DocumentMetaUpdateRequest;
      });
      const documentMetas: DocumentMetaUpdateRequest[] = await Promise.all(promises);
      const params: DocumentUpdateRequest = {
        documentMetas,
        id: props.documentId,
        version: data.version,
      };
      return DocumentApi.updateDocument2(props.businessUnitId, props.sectionId, params).then(resp => resp.data);
    },
    {
      ...updateDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <DocumentUpdateContext.Provider value={update}>{props.children}</DocumentUpdateContext.Provider>;
}
