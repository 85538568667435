import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import {
  DndContext,
  rectIntersection,
  useDraggable,
  useDroppable,
  LayoutMeasuringStrategy,
  DragStartEvent,
  DragMoveEvent,
  DragOverEvent,
  DragEndEvent,
  CollisionDetection,
  closestCorners,
  useDndContext,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { allNodesId, findNodeById } from 'api/PersonsProvider';
import { ContentItemView } from 'api/generated';

import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import without from 'lodash/without';
import { Avatar } from '@material-ui/core';

interface SortableTreeContextType {
  disabled: boolean;
  collision: CollisionStatus | undefined;
  expandNode: (nodeId: string) => void;
  collapseNode: (nodeId: string) => void;
}

interface CollisionStatus {
  over: string;
  index: number;
}

const SortableTreeContext = createContext<SortableTreeContextType | undefined>(undefined);
SortableTreeContext.displayName = 'SortableTreeContext';

export interface SortableTreeData extends ContentItemView {
  link?: string;
  actions?: JSX.Element;
}

interface SortableTreeProps {
  data: SortableTreeData[];
  parentFolderId: number;
  onDragStart?(event: DragStartEvent): void;
  onDragMove?(event: DragMoveEvent): void;
  onDragOver?(event: DragOverEvent): void;
  onDragEnd?(event: DragEndEvent): void;
  disabled?: boolean;
}
export function SortableTree({
  data,
  parentFolderId,
  onDragStart,
  onDragMove,
  onDragOver,
  onDragEnd,
  disabled = false,
}: SortableTreeProps) {
  const theme = useTheme();

  const [expandedNodes, setExpandedNodes] = useState<string[]>([
    parentFolderId.toString(),
    ...map(allNodesId(data), id => id.toString()),
  ]);

  const collisionStatus = useRef<CollisionStatus | undefined>();

  const customCollisionDetectionStrategy: CollisionDetection = useCallback(
    (rects, rect) => {
      const parentDetection = rectIntersection(rects, rect);

      if (parentDetection) {
        let siblings = [];
        if (parseInt(parentDetection, 10) === parentFolderId) {
          siblings = data;
        } else {
          const parent = findNodeById(parseInt(parentDetection || '', 10), data);
          siblings = parent?.children || [];
        }

        const siblingDetection = closestCorners(rects, rect);
        if (siblingDetection) {
          if (siblingDetection === parentDetection) {
            collisionStatus.current = { over: parentDetection, index: siblings.length };
          } else {
            const siblingIndex = findIndex(siblings, { id: parseInt(siblingDetection || '', 10) });
            collisionStatus.current = { over: siblingDetection, index: siblingIndex };
          }
        }
      } else {
        collisionStatus.current = undefined;
      }

      return parentDetection;
    },
    [data, parentFolderId]
  );

  const expandNode = useCallback(
    (nodeId: string) => {
      if (!expandedNodes.includes(nodeId)) {
        setExpandedNodes([...expandedNodes, nodeId]);
      }
    },
    [expandedNodes]
  );

  const collapseNode = useCallback(
    (nodeId: string) => {
      if (expandedNodes.includes(nodeId)) {
        setExpandedNodes(without(expandedNodes, nodeId));
      }
    },
    [expandedNodes]
  );

  const handleToggleNodes = useCallback((event, nodeIds) => {
    setExpandedNodes(nodeIds);
  }, []);

  return (
    <DndContext
      collisionDetection={customCollisionDetectionStrategy}
      layoutMeasuring={{ strategy: LayoutMeasuringStrategy.Always }}
      onDragStart={onDragStart}
      onDragOver={props => {
        isFunction(onDragStart) && onDragStart(props);
      }}
      onDragMove={props => {
        isFunction(onDragMove) && onDragMove(props);
      }}
      onDragEnd={props => {
        isFunction(onDragEnd) &&
          onDragEnd({ ...props, over: props.over ? { ...props.over, data: { ...collisionStatus } } : null });
        collisionStatus.current = undefined;
      }}
      onDragCancel={() => {
        collisionStatus.current = undefined;
      }}
    >
      <Droppable id={parentFolderId.toString()}>
        <SortableTreeContext.Provider
          value={{ collision: collisionStatus.current, disabled, expandNode, collapseNode }}
        >
          <TreeView
            defaultCollapseIcon={<ExpandLessIcon htmlColor={theme.palette.grey[600]} />}
            expanded={expandedNodes}
            onNodeToggle={handleToggleNodes}
            defaultExpandIcon={<ExpandMoreIcon htmlColor={theme.palette.grey[600]} />}
            disableSelection={true}
            selected={[]}
          >
            {map(data, d => (
              <SortableTreeNode key={d.id} value={d} divider />
            ))}
          </TreeView>
        </SortableTreeContext.Provider>
      </Droppable>
    </DndContext>
  );
}

interface SortableTreeNodeProps {
  value: SortableTreeData;
  divider?: boolean;
}
function SortableTreeNode(props: SortableTreeNodeProps) {
  const { value } = props;
  const classes = useSortableTreeNodeStyles(props);
  return (
    <Draggable id={value.id.toString()}>
      <Droppable id={value.id.toString()}>
        <TreeItem
          classes={{ group: classes.group }}
          key={value.id}
          nodeId={value.id.toString()}
          label={<TreeItemLabel id={value.id.toString()} value={value} />}
        >
          {isEmpty(value.children) ? null : map(value.children, c => <SortableTreeNode key={c.id} value={c} />)}
        </TreeItem>
      </Droppable>
    </Draggable>
  );
}

const useSortableTreeNodeStyles = makeStyles<Theme, SortableTreeNodeProps>(theme => ({
  group: props => ({
    marginLeft: theme.spacing(4.5),
    border: 0,
    borderBottom: props.divider ? 1 : 0,
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
  }),
}));

interface TreeItemLabelProps {
  id: string;
  value: SortableTreeData;
}
function TreeItemLabel({ id, value }: TreeItemLabelProps) {
  const { active } = useDndContext();
  return (
    <ListItem
      ContainerComponent="div"
      component={Link}
      to={value.link || '#'}
      button
      disableGutters
      alignItems="center"
    >
      {value.iconPath && (
        <ListItemAvatar>
          <Avatar alt={value.title} src={value.iconPath} />
        </ListItemAvatar>
      )}
      <ListItemText primary={value.title} secondary={value.description} />
      {isNil(active) && !isNil(value.actions) && <ListItemSecondaryAction>{value.actions}</ListItemSecondaryAction>}
    </ListItem>
  );
}

interface DroppableProps {
  id: string;
}

function Droppable({ id, children }: React.PropsWithChildren<DroppableProps>) {
  const treeContext = useContext(SortableTreeContext);
  const { setNodeRef, isOver, active } = useDroppable({ id, disabled: Boolean(treeContext?.disabled) });

  const theme = useTheme();
  return (
    <Box
      border={2}
      borderColor={isOver && active?.id !== id ? theme.palette.success.main : 'transparent'}
      borderRadius={4}
      ref={setNodeRef}
    >
      {children}
    </Box>
  );
}

interface DraggableProps {
  id: string;
}
function Draggable({ id, children }: React.PropsWithChildren<DraggableProps>) {
  const treeContext = useContext(SortableTreeContext);
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    disabled: Boolean(treeContext?.disabled),
  });

  useEffect(() => {
    if (isDragging) {
      treeContext?.collapseNode(id);
    }
  }, [isDragging, id, treeContext]);

  const theme = useTheme();

  const style = {
    transform: transform ? CSS.Translate.toString(transform) : undefined,
  };

  const classes = useDraggableStyles();
  return (
    <Box
      display="flex"
      position="relative"
      style={style}
      borderRadius={8}
      bgcolor={isDragging ? 'background.paper' : 'background.default'}
      zIndex={isDragging ? theme.zIndex.tooltip : 'auto'}
    >
      {!Boolean(treeContext?.disabled) && (
        <Box
          mt={2.6}
          className={classnames(classes.icon, { dragging: isDragging })}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
        >
          <DragIndicatorIcon />
        </Box>
      )}
      <Box flexGrow={1}>{children}</Box>
    </Box>
  );
}

const useDraggableStyles = makeStyles(theme => ({
  icon: {
    cursor: 'grab',
    '&.dragging': {
      cursor: 'grabbing',
    },
  },
}));
