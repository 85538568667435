import React, { useCallback, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthProvider } from 'api/AuthProviders';
import { useGroupDetailsProvider } from 'api/GroupsProvider';
import { PersonListOrderUpdateProvider } from 'api/PersonsProvider';
import { ITEMS_PER_PAGE } from 'api/SectionsProviders/SectionContentProvider';
import { SectionContentProvider } from 'api/SectionsProviders';
import { ContentItemView, HierarchyPlaceDataTypeEnum, UserSessionStateViewRoleEnum } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { PencilIcon } from 'components/icons';
import { Modal } from 'components/material';
import { ListWrapper } from 'pages/Layouts/ListWrapper';
import { backToolbarHeight } from 'styles/constants';
import { makeParentUrl } from 'utils';

import { GroupRequiredRouterParams } from './interfaces';
import { SortablePersonsTree } from './PersonsList/SortablePersonsTree';
import { EmptyGroupMembersList } from './GroupMembersList/EmptyGroupMembersList';

export function GroupMembersColumn() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<GroupRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const groupFolderId = parseInt(match.params.groupFolderId || '', 10);

  const { data: group } = useGroupDetailsProvider();

  const { role } = useAuthProvider();
  const canMakeChanges = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;

  const [showDescription, setShowDescription] = useState(false);

  const goBack = useCallback(() => {
    const { location } = history;
    history.push(makeParentUrl(`${location.pathname}${location.search}`, 2));
  }, [history]);

  const optionsRef = useRef({
    getNextPageParam: (lastPage: ContentItemView[], pages: ContentItemView[][]) => {
      if (lastPage.length >= ITEMS_PER_PAGE) {
        return { skip: pages.length * ITEMS_PER_PAGE, limit: ITEMS_PER_PAGE };
      }
      return undefined;
    },
    getPreviousPageParam: (_firstPage: ContentItemView[], _pages: ContentItemView[][]) => {
      // there is no previous page available
      return undefined;
    },
  });

  const classes = useStyles();
  return (
    <ListWrapper>
      <Box className={classes.backToolbar}>
        <Button className={classes.backBtn} startIcon={<ArrowBackIosIcon />} size="small" onClick={() => goBack()}>
          {`${t('persons:back')}`}
        </Button>
      </Box>
      <Box className={classes.header}>
        <Box flexGrow="1" display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box className={classes.sectionTitle} component="span">
              {group?.title}
            </Box>
            {canMakeChanges && (
              <Link to={`${match.url}/edit${location.search}`}>
                <PencilIcon style={{ fontSize: 26 }} className={classes.editIcon} />
              </Link>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {group?.description && (
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => setShowDescription(true)} centerRipple size="small">
                  <InfoIcon color="inherit" htmlColor="#83899B" style={{ width: 26, height: 26 }} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.listContainer}>
        <Box className={classes.list}>
          <SectionContentProvider
            filter={undefined}
            businessUnitId={businessUnitId}
            sectionId={sectionId}
            parentFolderId={groupFolderId}
            allHierarchyLevels={true}
            queryOptions={{ onError: catchError, ...optionsRef.current }}
          >
            <PersonListOrderUpdateProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              parentFolderId={groupFolderId}
              dataType={HierarchyPlaceDataTypeEnum.GROUP}
            >
              <SortablePersonsTree
                businessUnitId={businessUnitId}
                sectionId={sectionId}
                parentFolderId={groupFolderId}
                getEmptyListContent={() => <EmptyGroupMembersList />}
              />
            </PersonListOrderUpdateProvider>
          </SectionContentProvider>
        </Box>
      </Box>
      <Modal
        title={t('groups:dialog.detailsTitle')}
        open={showDescription}
        fullWidth={true}
        onCloseModal={() => setShowDescription(false)}
      >
        <Box pb={8}>
          <Typography>{group?.description}</Typography>
        </Box>
      </Modal>
    </ListWrapper>
  );
}

export const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(0, 9, 0, 9),
  },
  backToolbar: {
    minHeight: backToolbarHeight,
    height: backToolbarHeight,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 9, 0.5, 9),
  },
  backBtn: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(-2),
  },
  sectionTitle: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginRight: theme.spacing(2.5),
    lineHeight: '32px',
  },
  editIcon: {
    marginRight: theme.spacing(1.5),
    lineHeight: '32px',
    cursor: 'pointer',
    width: 19,
    height: 19,
    marginTop: theme.spacing(0.75),
    color: theme.palette.grey[600],
  },
  listContainer: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 9, 0, 9),
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    overflow: 'auto',
    flexGrow: 1,
  },
}));
