/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Element to update the place
 * @export
 * @interface HierarchyPlace
 */
export interface HierarchyPlace {
  /**
   * Data type of the object
   * @type {string}
   * @memberof HierarchyPlace
   */
  dataType: HierarchyPlaceDataTypeEnum;
  /**
   * The id of the element in the hierarchy i.e. for example ContentItemView.id
   * @type {number}
   * @memberof HierarchyPlace
   */
  id: number;
  /**
   * Order number for sorting
   * @type {number}
   * @memberof HierarchyPlace
   */
  orderNum: number;
}

/**
 * @export
 * @enum {string}
 */
export enum HierarchyPlaceDataTypeEnum {
  DOCUMENT = 'DOCUMENT',
  FOLDER = 'FOLDER',
  GROUP = 'GROUP',
  PERSON = 'PERSON',
  PARENT_FOR_GROUP = 'PARENT_FOR_GROUP',
}
