import React from 'react';

import { FormSpy } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getBusinessUnitIconURL } from 'api/http';

import { Placeholder, Select, SelectProps } from 'components/fields/Select';

import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
interface Item {
  id: string | number;
}

interface ExtendedSelectProps<TItem extends Item> extends SelectProps {
  multiple: boolean;
  name: string;
  disabled?: boolean;
  placeholder: string;
  items: TItem[];
  getItemTitle: (item: TItem) => string | undefined;
  getItemSecondaryTitle?: (item: TItem) => string | undefined;
}

export function ExtendedSelect<TItem extends Item>({
  multiple,
  name,
  disabled,
  placeholder,
  getItemTitle,
  getItemSecondaryTitle,
  items,
  ...rest
}: ExtendedSelectProps<TItem>) {
  const classes = useSelectStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Select
          name={name}
          multiple={multiple}
          formControlProps={{ margin: 'dense' }}
          disabled={disabled}
          displayEmpty
          renderValue={valueIds => {
            const selCompanies = filter(items, item => (valueIds as TItem['id'][]).includes(item.id));
            return !isEmpty(selCompanies) && !disabled ? (
              <Box pl={1}>
                <Typography variant="body1">{selCompanies.map(getItemTitle).join(', ')}</Typography>
              </Box>
            ) : (
              <Placeholder disabled={disabled}>{placeholder}</Placeholder>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: classes.menuPaper, list: classes.menuList },
          }}
          {...rest}
        >
          {map(items, item => (
            <MenuItem key={item.id} value={item.id} dense={false} classes={{ gutters: classes.menuItem }}>
              {multiple ? (
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <Checkbox name={item.id.toString()} color="primary" checked={values[name]?.includes(item.id)} />
                  )}
                </FormSpy>
              ) : undefined}
              <label htmlFor={item.id.toString()} className={classes.itemLabel}>
                <>
                  <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                    <Avatar
                      src={getBusinessUnitIconURL(item.id)}
                      alt={getItemTitle(item)}
                      classes={{ root: classes.avatar }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={`${item.id}`}
                    primary={getItemTitle(item)}
                    secondary={getItemSecondaryTitle && getItemSecondaryTitle(item)}
                  />
                </>
              </label>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}

export const useSelectStyles = makeStyles(theme => ({
  menuItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiListItemAvatar-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  menuList: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  listItemAvatar: {
    width: 40,
    minWidth: 40,
  },
  avatar: {
    width: 32,
    minWidth: 32,
    height: 32,
    minHeight: 32,
  },
  menuPaper: {
    maxHeight: 400,
  },
  itemLabel: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    cursor: 'pointer',
    '& .MuiListItemText-multiline': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));
