/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserView } from './user-view';

/**
 * State of user session
 * @export
 * @interface UserSessionStateView
 */
export interface UserSessionStateView {
  /**
   *
   * @type {UserView}
   * @memberof UserSessionStateView
   */
  realUser: UserView;
  /**
   * Current user role.
   * @type {string}
   * @memberof UserSessionStateView
   */
  role: UserSessionStateViewRoleEnum;
  /**
   * Current session id. Can be empty.
   * @type {string}
   * @memberof UserSessionStateView
   */
  sessionId: string;
  /**
   *
   * @type {UserView}
   * @memberof UserSessionStateView
   */
  sessionUser: UserView;
}

/**
 * @export
 * @enum {string}
 */
export enum UserSessionStateViewRoleEnum {
  ROOT = 'ROLE_ROOT',
  ADMIN = 'ROLE_ADMIN',
  RESPONSIBLE = 'ROLE_RESPONSIBLE',
  READER = 'ROLE_READER',
}
