import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { EmptyGroupMembersListIcon } from 'components/icons';
import { ListWrapper } from 'pages/Layouts/ListWrapper';

export function EmptyMembersByDateList() {
  const { t } = useTranslation();

  return (
    <ListWrapper>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={6}
      >
        <Box color="grey.400" mb={3}>
          <EmptyGroupMembersListIcon style={{ width: 164, height: 81 }} />
        </Box>
        <Typography align="center" color="textSecondary">
          {t('persons:emptyMembersByDate')}
        </Typography>
      </Box>
    </ListWrapper>
  );
}
