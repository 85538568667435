import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  MobileAppCreateProvider,
  MobileAppWithFilesCreateRequest,
  useMobileAppCreateProvider,
} from 'api/MobileAppsProviders';

import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { ConfirmDialog } from 'pages/All/ConfirmDialogs/ConfirmDialog';

import { goBackOrReplace, makeParentUrl } from 'utils';

import { MobileAppForm } from './MobileAppForm';
import { MobileAppsRequiredRouterParams } from '../interfaces';

export function MobileAppCreateDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();
  const match = useRouteMatch<MobileAppsRequiredRouterParams>();

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url));

  return (
    <Modal
      title={t('mobileApps:dialog.createTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={MobileAppCreateDialog.name}
    >
      <MobileAppCreateProvider queryOptions={{ onError: catchError }}>
        <MobileAppCreate />
      </MobileAppCreateProvider>
    </Modal>
  );
}

function MobileAppCreate() {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [values, setValues] = useState<MobileAppWithFilesCreateRequest>();

  const { mutateAsync, isLoading } = useMobileAppCreateProvider();

  const onCancel = useCallback(() => {
    history.push(makeParentUrl(match.url));
  }, [history, match.url]);

  const [openConfirm, setOpenConfirm] = useState(false);

  const showConfirmDialog = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const onSubmitForm = useCallback(
    async (values: MobileAppWithFilesCreateRequest) => {
      setValues(values);
      showConfirmDialog();
    },
    [showConfirmDialog]
  );

  const onSubmit = useCallback(() => {
    if (values) {
      return mutateAsync(values).then(() => {
        closeConfirmDialog();
        history.push(makeParentUrl(match.url));
      });
    }
  }, [closeConfirmDialog, history, match.url, mutateAsync, values]);

  return (
    <>
      <MobileAppForm
        onSubmit={onSubmitForm}
        onCancel={onCancel}
        submitLabel={t('mobileApps:dialog.create')}
        cancelLabel={t('mobileApps:dialog.cancel')}
      />
      <ConfirmDialog
        open={openConfirm}
        title={t('mobileApps:dialog.publishTitle')}
        confirmText={t('mobileApps:dialog.publishNote')}
        submitLabel={t('mobileApps:dialog.publish')}
        onClose={closeConfirmDialog}
        handleSubmit={onSubmit}
        primaryButtonDisabled={isLoading}
      />
    </>
  );
}
