import React from 'react';

import { useTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ContentItemViewStatusEnum } from 'api/generated';

interface DocumentStatusProps {
  status: ContentItemViewStatusEnum;
}
export function DocumentStatus({ status }: DocumentStatusProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Chip
      classes={{ colorPrimary: classes.primary }}
      component="span"
      size="small"
      color="primary"
      label={t(`documents:status.${status}` as const)}
    />
  );
}

const useStyles = makeStyles(theme => ({
  primary: {
    background: '#DAD8DB',
    color: theme.palette.text.primary,
    textTransform: 'initial',
    borderRadius: theme.spacing(0.5),
    marginLeft: '4px',
  },
}));
