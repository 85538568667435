import React, { useCallback } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';

import { BusinessUnitShowProvider, BusinessUnitOrderUpdateProvider } from 'api/BusinessUnitProviders';
import { GroupDetailsProvider } from 'api/GroupsProvider';
import { ProjectsListProvider } from 'api/ProjectsProviders';
import { RESOURCE_NAME } from 'api/ProjectsProviders/constants';
import { SectionShowProvider } from 'api/SectionsProviders/SectionShowProvider';
import { useErrorCatcher } from 'api/notifications';

import { PROJECTS_PAGE_PATH } from 'pages/Router';

import { ProjectsColumn } from './ProjectsList/ProjectsColumn';
import { SectionsColumn, EmptySectionsColumn } from '../All/SectionsList';
import { DocumentsQueryWrapper } from '../All/DocumentsList/DocumentsQueryWrapper';
import { BusinessUnitPageRouterParams } from '../All/SectionsList/interfaces';
import { GroupMembersColumn } from '../All/PersonsAndGroups/GroupMembersColumn';

import get from 'lodash/get';
import isNaN from 'lodash/isNaN';

export function ProjectsPage() {
  const history = useHistory();
  const match = useRouteMatch<BusinessUnitPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const parentFolderId = parseInt(match.params.parentFolderId || '', 10);
  const groupFolderId = parseInt(match.params.groupFolderId || '', 10);

  const catchError = useErrorCatcher();

  const onErrorHandler = useCallback(
    (error: unknown) => {
      catchError(error);
      const errStatus = get(error, 'response.status');
      if (errStatus === 403) {
        history.push(PROJECTS_PAGE_PATH);
      }
    },
    [catchError, history]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Box alignSelf="center" justifyContent="stretch" display="flex" width="100%" height="100%">
        <Box width={320} minWidth={320} height="100%">
          <ProjectsListProvider queryOptions={{ onError: catchError }}>
            <BusinessUnitOrderUpdateProvider businessUnitId={businessUnitId} resourceName={RESOURCE_NAME}>
              <ProjectsColumn />
            </BusinessUnitOrderUpdateProvider>
          </ProjectsListProvider>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box height="100%" flexGrow="1" overflow="auto">
          {isNaN(businessUnitId) && <EmptySectionsColumn />}
          {!!businessUnitId && isNaN(sectionId) && isNaN(parentFolderId) && (
            <BusinessUnitShowProvider businessUnitId={businessUnitId} queryOptions={{ onError: onErrorHandler }}>
              <SectionsColumn />
            </BusinessUnitShowProvider>
          )}
          {!!businessUnitId && !!sectionId && isNaN(groupFolderId) && (
            <SectionShowProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              queryOptions={{ onError: onErrorHandler }}
            >
              <DocumentsQueryWrapper
                businessUnitId={businessUnitId}
                sectionId={sectionId}
                parentFolderId={parentFolderId || undefined}
              />
            </SectionShowProvider>
          )}
          {!!businessUnitId && !!sectionId && !!groupFolderId && (
            <GroupDetailsProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              groupFolderId={groupFolderId}
              queryOptions={{ onError: onErrorHandler }}
            >
              <GroupMembersColumn />
            </GroupDetailsProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
}
