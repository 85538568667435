import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { EntityPlace, BusinessUnitControllerApiFactory, BusinessUnitShortView } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { reorder } from 'utils';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import map from 'lodash/map';
import pick from 'lodash/pick';
import upperFirst from 'lodash/upperFirst';

const BusinessUnitApi = BusinessUnitControllerApiFactory(ApiConfiguration);

interface BusinessUnitOrderUpdateRequest {
  from: number;
  to: number;
}

type MutationContext = { prevState: BusinessUnitShortView[] | undefined };

type BusinessUnitOrderUpdateProviderType = {
  businessUnitId: number;
  controller: UseMutationResult<
    BusinessUnitShortView[],
    AxiosError<unknown>,
    BusinessUnitOrderUpdateRequest,
    MutationContext
  >;
};

const BusinessUnitOrderUpdateContext = createContext<BusinessUnitOrderUpdateProviderType | null>(null);
BusinessUnitOrderUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}OrderUpdate`;

export function useBusinessUnitOrderUpdateProvider(): BusinessUnitOrderUpdateProviderType {
  const contextState = useContext(BusinessUnitOrderUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitOrderUpdateProvider.name} must be used within a ${BusinessUnitOrderUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface BusinessUnitOrderUpdateProviderProps {
  businessUnitId: number;
  resourceName: 'companies' | 'projects';
  queryOptions?: UseMutationOptions<
    BusinessUnitShortView[],
    AxiosError<unknown>,
    BusinessUnitOrderUpdateRequest,
    MutationContext
  >;
}
export function BusinessUnitOrderUpdateProvider(props: React.PropsWithChildren<BusinessUnitOrderUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const updateDefaultOptions: BusinessUnitOrderUpdateProviderProps['queryOptions'] = {
    onMutate: params => {
      const prevState: BusinessUnitShortView[] | undefined = queryClient.getQueryData(props.resourceName);
      // Optimistically update order
      queryClient.setQueryData<BusinessUnitShortView[] | undefined>(props.resourceName, xs => {
        return isNil(xs) ? xs : reorder(xs, params.from, params.to);
      });
      return { prevState };
    },
    onError: (err, params, context) => {
      queryClient.setQueryData(props.resourceName, context?.prevState);
    },
    onSettled: data => {
      queryClient.invalidateQueries(props.resourceName);
    },
  };
  const update = useMutation<
    BusinessUnitShortView[],
    AxiosError<unknown>,
    BusinessUnitOrderUpdateRequest,
    MutationContext
  >(
    async (params: BusinessUnitOrderUpdateRequest) => {
      // NOTE: businessUnits are already reordered in onMutate function
      const businessUnits: BusinessUnitShortView[] = queryClient.getQueryData(props.resourceName) || [];
      const elements: EntityPlace[] = map(businessUnits, (item: BusinessUnitShortView, index: number) => ({
        ...pick(item, ['id', 'version']),
        orderNum: index,
      }));
      return BusinessUnitApi.updateBusinessUnitOrder({ elements }).then(resp => resp.data);
    },
    {
      ...updateDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <BusinessUnitOrderUpdateContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        controller: update,
      }}
    >
      {props.children}
    </BusinessUnitOrderUpdateContext.Provider>
  );
}
