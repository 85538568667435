import React, { useEffect, useRef } from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

import { isInViewport } from 'utils/dom';

import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query/types/core/types';

import isFunction from 'lodash/fp/isFunction';

const AUTO_LOAD_MORE_TIMEOUT = 1000; // in milliseconds

export function LoadMore({
  onLoadMore,
  isLoading = false,
}: {
  onLoadMore: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult>;
  isLoading?: boolean;
}) {
  const elRef = useRef(null);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (elRef.current && isInViewport(elRef.current!)) {
        !isLoading && isFunction(onLoadMore) && onLoadMore();
      }
    }, AUTO_LOAD_MORE_TIMEOUT);
    return () => clearInterval(timeout);
  }, [onLoadMore, isLoading]);

  return (
    <Box display="flex" justifyContent="center" width="100%" data-test={LoadMore.name}>
      <LinearProgress
        variant="indeterminate"
        color="primary"
        style={{ width: '100%' }}
        onClick={() => onLoadMore()}
        ref={elRef}
        data-test="progress"
      />
    </Box>
  );
}
