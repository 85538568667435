import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  BusinessUnitSectionControllerApiFactory,
  BusinessUnitSectionShortView,
  BusinessUnitSectionUpdateRequest,
  BusinessUnitView,
  FileControllerApiFactory,
  FileMetaView,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';
import { isFileMetaView } from 'api/DocumentProviders/Documents/type-helpers';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import omit from 'lodash/omit';

const FileApi = FileControllerApiFactory(FileApiConfiguration);
const SectionApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

export type SectionUpdateRequest = {
  sectionPreviewEnFile?: File | FileMetaView;
  sectionPreviewRuFile?: File | FileMetaView;
} & BusinessUnitSectionUpdateRequest;

type SectionUpdateProviderType = UseMutationResult<
  BusinessUnitSectionUpdateRequest,
  AxiosError<unknown>,
  SectionUpdateRequest,
  unknown
>;

const SectionUpdateContext = createContext<SectionUpdateProviderType | null>(null);
SectionUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}Update`;

export function useSectionUpdateProvider(): SectionUpdateProviderType {
  const contextState = useContext(SectionUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSectionUpdateProvider.name} must be used within a ${SectionUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface SectionUpdateProviderProps {
  businessUnitId: BusinessUnitView['id'];
  sectionId: BusinessUnitSectionShortView['sectionId'];
  queryOptions?: UseMutationOptions<
    BusinessUnitSectionUpdateRequest,
    AxiosError<unknown>,
    BusinessUnitSectionUpdateRequest,
    unknown
  >;
}

export function SectionUpdateProvider(props: React.PropsWithChildren<SectionUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: SectionUpdateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([RESOURCE_NAME, { businessUnitId: props.businessUnitId }]);
    },
  };
  const update = useMutation<BusinessUnitSectionUpdateRequest, AxiosError<unknown>, SectionUpdateRequest>(
    async (data: SectionUpdateRequest) => {
      if (data.sectionPreviewEnFile) {
        if (isFileMetaView(data.sectionPreviewEnFile)) {
          data.sectionPreviewEnFileMetaView = data.sectionPreviewEnFile;
        } else {
          data.sectionPreviewEnFileMetaView = await FileApi.upload(
            data.sectionPreviewEnFile,
            data.sectionPreviewEnFile.name
          ).then(resp => resp.data);
        }
      }
      if (data.sectionPreviewRuFile) {
        if (isFileMetaView(data.sectionPreviewRuFile)) {
          data.sectionPreviewRuFileMetaView = data.sectionPreviewRuFile;
        } else {
          data.sectionPreviewRuFileMetaView = await FileApi.upload(
            data.sectionPreviewRuFile,
            data.sectionPreviewRuFile.name
          ).then(resp => resp.data);
        }
      }
      const params: BusinessUnitSectionUpdateRequest = omit(data, ['sectionPreviewEnFile', 'sectionPreviewRuFile']);
      return SectionApi.updateBusinessUnitSection2(props.businessUnitId, params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <SectionUpdateContext.Provider value={update}>{props.children}</SectionUpdateContext.Provider>;
}
