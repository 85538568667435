import React, { useCallback } from 'react';

import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useErrorCatcher } from 'api/notifications';

import {
  AccountDetailsWithUpdateProvider,
  AccountExpirePinProvider,
  AccountShowProvider,
  AccountUpdateProvider,
  useAccountDetailsWithUpdateProvider,
  useAccountShowProvider,
  useAccountUpdateProvider,
  AccountPinStatusProvider,
  useAccountPinStatusProvider,
  AccountLockProvider,
} from 'api/AccountsProviders';
import {
  BusinessUnitAccessDetailsProvider,
  useBusinessUnitAccessDetailsProvider,
  BusinessUnitUpdateAccessProvider,
  useBusinessUnitUpdateAccessProvider,
} from 'api/BusinessUnitProviders';

import { AccountViewStateEnum, AccountWithUserUpdateRequest, UserSessionStateViewRoleEnum } from 'api/generated';

import { makeParentUrl } from 'utils/history';
import { useAuthProvider } from 'api/AuthProviders';

import { ACCESS_FORM_VALUES, AccountForm, AccountInitialFormValuesType } from './components/AccountForm/AccountForm';
import { Spinner } from 'components/material/Spinner';
import { RunMenuButton } from './components/RunMenuButton';
import { AccountState } from './components/chips/AccountState';
import { AccountPinStatus } from './components/chips/AccountPinStatus';

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import some from 'lodash/some';

interface UserDetailsPageRouterParams {
  accountId?: string | undefined;
}

export function AccountDetailsPage() {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<UserDetailsPageRouterParams>();
  const accountId = parseInt(match.params.accountId || '', 10);
  const { role } = useAuthProvider();
  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;

  return (
    <Container maxWidth="lg">
      <Box alignSelf="center" display="flex" flexDirection="column" width="100%" mt={2}>
        <Box display="flex" flexDirection="column" mb={4}>
          <Box ml={-1.5} color="text.secondary">
            <Button
              color="inherit"
              startIcon={<ArrowBackIosIcon />}
              size="small"
              component={Link}
              to={makeParentUrl(match.url)}
            >
              {`${t('accounts:back')}`}
            </Button>
          </Box>
          {!isNaN(accountId) && isAdmin && (
            <AccountShowProvider id={accountId}>
              <Box display="flex">
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography variant="h5">{t('accounts:details.title')}</Typography>
                  <AccountStateInDetailsPage />
                  <AccountPinStatusWrapper />
                </Box>
                <Box>
                  <RunMenuButtonWrapper />
                </Box>
              </Box>
            </AccountShowProvider>
          )}
        </Box>
        {!isNaN(accountId) && (
          <AccountShowProvider id={accountId}>
            <AccountDetailsWithUpdateProvider id={accountId}>
              <BusinessUnitAccessDetailsProvider accountId={accountId}>
                <AccountUpdateProvider queryOptions={{ onError: catchError }}>
                  <BusinessUnitUpdateAccessProvider queryOptions={{ onError: catchError }}>
                    <Box flexGrow={1} bgcolor="background.paper" borderRadius={8}>
                      <AccountDetails />
                    </Box>
                  </BusinessUnitUpdateAccessProvider>
                </AccountUpdateProvider>
              </BusinessUnitAccessDetailsProvider>
            </AccountDetailsWithUpdateProvider>
          </AccountShowProvider>
        )}
      </Box>
    </Container>
  );
}

function RunMenuButtonWrapper() {
  const { data, isFetched } = useAccountShowProvider();

  return isFetched && data?.primaryUserId && data.state !== AccountViewStateEnum.REMOVED ? (
    <AccountExpirePinProvider primaryUserId={data?.primaryUserId}>
      <AccountLockProvider accountId={data?.id}>
        <RunMenuButton />
      </AccountLockProvider>
    </AccountExpirePinProvider>
  ) : null;
}

function AccountStateInDetailsPage() {
  const { data: account, isFetched } = useAccountShowProvider();
  return isFetched && account ? (
    <Box ml={2}>
      <AccountState state={account.state} />
    </Box>
  ) : null;
}

function AccountPinStatusWrapper() {
  const { data, isFetched } = useAccountShowProvider();
  return isFetched && data?.primaryUserId ? (
    <AccountPinStatusProvider primaryUserId={data.primaryUserId}>
      <AccountPinStatusInDetailsPage />
    </AccountPinStatusProvider>
  ) : null;
}

function AccountPinStatusInDetailsPage() {
  const { data, isFetched } = useAccountPinStatusProvider();
  return isFetched && data ? (
    <Box ml={2}>
      <AccountPinStatus status={data?.pinStatus} />
    </Box>
  ) : null;
}

function AccountDetails() {
  const match = useRouteMatch();

  const history = useHistory();
  const { t } = useTranslation();

  const { data: accountShow } = useAccountShowProvider();
  const { data: account, isLoading, isFetched } = useAccountDetailsWithUpdateProvider();
  const { data: accessList, isFetched: isFetchedAccessList } = useBusinessUnitAccessDetailsProvider();

  const initialValues =
    account && accessList
      ? {
          allProjectsForReader: accessList?.readerAccess?.projectAccess?.allowAll ?? true,
          allCompaniesForReader: accessList?.readerAccess?.companyAccess?.allowAll ?? true,
          readerCompaniesIds: accessList?.readerAccess?.companyAccess?.allowIds ?? [],
          readerProjectsIds: accessList?.readerAccess?.projectAccess?.allowIds ?? [],
          allowByDefaultCompanies: accessList?.readerAccess?.companyAccess?.allowByDefault ?? true,
          allowByDefaultProjects: accessList?.readerAccess?.projectAccess?.allowByDefault ?? true,
          responsibleCompaniesIds: accessList?.responsibleAccess?.companyAllowIds ?? [],
          responsibleProjectIds: accessList?.responsibleAccess?.projectAllowIds ?? [],
          ...account,
        }
      : undefined;

  const { mutateAsync: updateAccount } = useAccountUpdateProvider();
  const { mutateAsync: updateAccess } = useBusinessUnitUpdateAccessProvider();

  const onSubmit = useCallback(
    (values: AccountInitialFormValuesType, form: any) => {
      const dirtyFields = keys(form.getState().dirtyFields);
      const accountValues: AccountWithUserUpdateRequest = omit(values, [
        'allProjectsForReader',
        'allCompaniesForReader',
        'readerCompaniesIds',
        'readerProjectsIds',
        'responsibleCompaniesIds',
        'responsibleProjectIds',
      ]);
      return updateAccount(accountValues)
        .then(account => {
          if (some(ACCESS_FORM_VALUES, item => dirtyFields.includes(item))) {
            const accessValues = {
              ...pick(values, [
                'allowableRoles',
                'allProjectsForReader',
                'allCompaniesForReader',
                'allowByDefaultCompanies',
                'allowByDefaultProjects',
                'readerCompaniesIds',
                'readerProjectsIds',
                'responsibleCompaniesIds',
                'responsibleProjectIds',
              ]),
              accountId: account.accountId,
            };
            updateAccess(accessValues);
          }
        })
        .then(() => history.push(makeParentUrl(match.url)));
    },
    [history, match.url, updateAccount, updateAccess]
  );

  const onCancel = useCallback(() => {
    history.push(makeParentUrl(match.url));
  }, [history, match.url]);

  return isLoading ? (
    <Spinner />
  ) : isFetched && isFetchedAccessList ? (
    <AccountForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel={t('accounts:details.update')}
      cancelLabel={t('accounts:details.cancel')}
      readOnly={accountShow?.state === AccountViewStateEnum.REMOVED}
    />
  ) : null;
}
