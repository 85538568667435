import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { TextField, PhoneField } from 'components/fields';
import {
  composeValidators,
  email,
  maxLength,
  phoneLength,
  required,
  requiredPhone,
} from 'components/fields/validators';

import trim from 'utils/trim';

export function PersonalInfo() {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Box flexGrow={1} flexBasis="50%" mr={1.5}>
          <TextField
            label={t('accounts:create.fields.lastName')}
            placeholder={t('accounts:create.fields.lastNamePlaceholder')}
            name="lastName"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.firstName')}
            placeholder={t('accounts:create.fields.firstNamePlaceholder')}
            name="firstName"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.middleName')}
            placeholder={t('accounts:create.fields.middleNamePlaceholder')}
            name="middleName"
            margin="dense"
            fieldProps={{
              validate: middleNameValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <PhoneField
            label={t('accounts:create.fields.userPhone')}
            placeholder={t('accounts:create.fields.userPhonePlaceholder')}
            name="phone"
            margin="dense"
            required
            fieldProps={{ validate: userPhoneValidator }}
          />
          <PhoneField
            label={t('accounts:create.fields.smsPhone')}
            placeholder={t('accounts:create.fields.smsPhonePlaceholder')}
            name="smsPhone"
            margin="dense"
            fieldProps={{ validate: phoneLength }}
          />
          <TextField
            label={t('accounts:create.fields.email')}
            placeholder={t('accounts:create.fields.emailPlaceholder')}
            type="email"
            name="email"
            margin="dense"
            fieldProps={{ validate: emailValidator }}
          />
        </Box>
        <Box flexGrow={1} flexBasis="50%" ml={1.5}>
          <TextField
            label={t('accounts:create.fields.lastNameEn')}
            placeholder={t('accounts:create.fields.lastNameEnPlaceholder')}
            name="lastNameEn"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.firstNameEn')}
            placeholder={t('accounts:create.fields.firstNameEnPlaceholder')}
            name="firstNameEn"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.middleNameEn')}
            placeholder={t('accounts:create.fields.middleNameEnPlaceholder')}
            name="middleNameEn"
            margin="dense"
            fieldProps={{
              validate: middleNameValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

const firstLastNamesValidator = composeValidators(required, maxLength(255));
const middleNameValidator = maxLength(255);
const userPhoneValidator = composeValidators(requiredPhone, phoneLength);
const emailValidator = composeValidators(email, maxLength(255));
