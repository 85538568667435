import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';

import { Modal } from 'components/material/Modal';
import { ModalToolbar } from 'components/material/ModalToolbar';

interface ConfirmDialogProps extends DialogProps {
  open: boolean;
  title: string;
  confirmText: string;
  submitLabel?: string;
  cancelLabel?: string;
  onClose: (event?: any) => void;
  handleSubmit: (event: any) => void;
  primaryButtonDisabled: boolean;
}

export function ConfirmDialog({
  open,
  title,
  confirmText,
  submitLabel,
  cancelLabel,
  onClose,
  handleSubmit,
  primaryButtonDisabled,
}: ConfirmDialogProps) {
  return (
    <Modal
      open={open}
      onCloseModal={onClose}
      maxWidth="sm"
      fullWidth={true}
      title={title}
      onClick={event => event.stopPropagation()}
      toolbar={
        submitLabel || cancelLabel ? (
          <ModalToolbar>
            {submitLabel && (
              <Button onClick={handleSubmit} color="primary" variant="contained" disabled={primaryButtonDisabled}>
                {submitLabel}
              </Button>
            )}
            <Box width={16} />
            {cancelLabel && (
              <Button onClick={onClose} variant="contained" color="secondary">
                {cancelLabel}
              </Button>
            )}
          </ModalToolbar>
        ) : null
      }
    >
      <Box mb={6} minHeight={150}>
        <Typography variant="subtitle2">{confirmText}</Typography>
      </Box>
    </Modal>
  );
}
