import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { GroupFolderControllerApiFactory, GroupFolderContentChangeLogView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

type GroupsChangelogProviderType = QueryObserverResult<GroupFolderContentChangeLogView[], unknown>;

const GroupsChangelogContext = createContext<GroupsChangelogProviderType | null>(null);
GroupsChangelogContext.displayName = `${upperFirst(RESOURCE_NAME)}Log`;

export function useGroupsChangelogProvider(): GroupsChangelogProviderType {
  const contextState = useContext(GroupsChangelogContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useGroupsChangelogProvider.name} must be used within a ${GroupsChangelogContext.displayName} context`
    );
  }
  return contextState;
}

interface GroupsChangelogProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
}
export function GroupsChangelogProvider(props: React.PropsWithChildren<GroupsChangelogProviderProps>) {
  const value = useQuery<GroupFolderContentChangeLogView[]>(
    [RESOURCE_NAME, { id: props.groupFolderId }, 'changelog'],
    () =>
      GroupFolderApi.getGroupFolderContentChangeLog(props.businessUnitId, props.sectionId, props.groupFolderId).then(
        resp => resp.data
      )
  );
  return <GroupsChangelogContext.Provider value={value}>{props.children}</GroupsChangelogContext.Provider>;
}
