import React from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import { getAccountPhotoURL } from 'api/http';
import { useErrorCatcher } from 'api/notifications';

import { Modal, Spinner } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';
import { AccountShowProvider, useAccountShowProvider } from 'api/AccountsProviders';
import { ContactInfo } from 'components/common/ContactInfo';

interface ResponsibleDetailsDialogProps {
  accountId: string;
  businessUnitId: string;
}
export function ResponsibleDetailsDialog({
  routeProps,
}: {
  routeProps: RouteComponentProps<ResponsibleDetailsDialogProps>;
}) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const businessUnitId = parseInt(routeProps.match.params.businessUnitId || '', 10);
  const accountId = parseInt(routeProps.match.params.accountId || '', 10);
  const closeHandler = () => goBackOrReplace(makeParentUrl(routeProps.match.url, 1));

  return (
    <Modal
      title={t('businessUnit:responsible.profile')}
      open={!!routeProps.match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={ResponsibleDetailsDialog.name}
    >
      {!!businessUnitId && (
        <AccountShowProvider id={accountId} queryOptions={{ onError: catchError }}>
          <Box mb={6}>
            <ResponsibleDetails />
          </Box>
        </AccountShowProvider>
      )}
    </Modal>
  );
}

function ResponsibleDetails() {
  const { data: responsible, isLoading } = useAccountShowProvider();

  if (isLoading) {
    return <Spinner />;
  }

  if (!responsible) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <PersonAvatar src={getAccountPhotoURL(responsible?.id)} alt="avatar" />
        </Box>
        <Box fontSize="subtitle1.fontSize" fontWeight={600}>
          <Box>{responsible?.lastName}</Box>
          <Box>{`${responsible?.firstName} ${responsible?.middleName || ''}`}</Box>
        </Box>
      </Box>
      <ContactInfo person={responsible} i18nNamespace="persons"></ContactInfo>
    </Box>
  );
}

export const PersonAvatar = withStyles(theme => ({
  root: {
    height: 96,
    width: 96,
  },
}))(Avatar);
