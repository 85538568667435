import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { DocumentControllerApiFactory, DocumentView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

type DocumentDetailsProviderType = QueryObserverResult<DocumentView, unknown>;

const DocumentDetailsContext = createContext<DocumentDetailsProviderType | null>(null);
DocumentDetailsContext.displayName = `${upperFirst(RESOURCE_NAME)}DetailsWithUpdate`;

export function useDocumentDetailsProvider(): DocumentDetailsProviderType {
  const contextState = useContext(DocumentDetailsContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentDetailsProvider.name} must be used within a ${DocumentDetailsContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentDetailsProviderProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
  queryOptions?: UseQueryOptions<DocumentView, unknown, DocumentView>;
}
export function DocumentDetailsProvider(props: React.PropsWithChildren<DocumentDetailsProviderProps>) {
  const value = useQuery<DocumentView>(
    [RESOURCE_NAME, { id: props.documentId }],
    () => DocumentApi.getDocumentById2(props.businessUnitId, props.sectionId, props.documentId).then(resp => resp.data),
    props.queryOptions
  );
  return <DocumentDetailsContext.Provider value={value}>{props.children}</DocumentDetailsContext.Provider>;
}
