/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessUpdateRequest } from '../views';
// @ts-ignore
import { AccountAccessView } from '../views';
// @ts-ignore
import { BusinessUnitAccessUpdateRequest } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * AccessControllerApi - axios parameter creator
 * @export
 */
export const AccessControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get business unit access list
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitAccessList: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('getBusinessUnitAccessList', 'accountId', accountId);
      const localVarPath = `/struct/ALL/access/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitReaderAccess', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/access/readers`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess1: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitReaderAccess1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/access/readers`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess2: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitReaderAccess2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/access/readers`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access by business unit type for all readers
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess3: async (
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getBusinessUnitReaderAccess3', 'type', type);
      const localVarPath = `/struct/{type}/access/readers`.replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitResponsibleAccess', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/access/responsible`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess1: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitResponsibleAccess1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/access/responsible`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess2: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitResponsibleAccess2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/access/responsible`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access by business unit type for all responsible
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess3: async (
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getBusinessUnitResponsibleAccess3', 'type', type);
      const localVarPath = `/struct/{type}/access/responsible`.replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess', 'sectionId', sectionId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/{sectionId}/access/readers`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess1: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess1', 'sectionId', sectionId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/{sectionId}/access/readers`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess2: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess2', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/access/readers`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess3: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess3', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/access/readers`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess4: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess4', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/access/readers`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess5: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionReaderAccess5', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/access/readers`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess: async (
      businessUnitId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionResponsibleAccess', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/access/responsible`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess1: async (
      businessUnitId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionResponsibleAccess1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/access/responsible`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess2: async (
      businessUnitId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionResponsibleAccess2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/access/responsible`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get accesses to business unit section for all responsible
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess3: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionResponsibleAccess3', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionResponsibleAccess3', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/access/responsible`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update access to BusinessUnit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {AccessUpdateRequest} accessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccessToBusinessUnitSections: async (
      businessUnitId: number,
      sectionId: number,
      accessUpdateRequest: AccessUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateAccessToBusinessUnitSections', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateAccessToBusinessUnitSections', 'sectionId', sectionId);
      // verify required parameter 'accessUpdateRequest' is not null or undefined
      assertParamExists('updateAccessToBusinessUnitSections', 'accessUpdateRequest', accessUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/access`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(accessUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update access to BusinessUnit for readers
     * @param {number} businessUnitId
     * @param {AccessUpdateRequest} accessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccessToBusinessUnitsForReaders: async (
      businessUnitId: number,
      accessUpdateRequest: AccessUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateAccessToBusinessUnitsForReaders', 'businessUnitId', businessUnitId);
      // verify required parameter 'accessUpdateRequest' is not null or undefined
      assertParamExists('updateAccessToBusinessUnitsForReaders', 'accessUpdateRequest', accessUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/access`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(accessUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update business unit access
     * @param {BusinessUnitAccessUpdateRequest} businessUnitAccessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitAccess: async (
      businessUnitAccessUpdateRequest: BusinessUnitAccessUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitAccessUpdateRequest' is not null or undefined
      assertParamExists('updateBusinessUnitAccess', 'businessUnitAccessUpdateRequest', businessUnitAccessUpdateRequest);
      const localVarPath = `/struct/ALL/access`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitAccessUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccessControllerApi - functional programming interface
 * @export
 */
export const AccessControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccessControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get business unit access list
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitAccessList(
      accountId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitAccessUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitAccessList(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitReaderAccess(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitReaderAccess(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitReaderAccess1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitReaderAccess1(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitReaderAccess2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitReaderAccess2(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access by business unit type for all readers
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitReaderAccess3(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitReaderAccess3(type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitResponsibleAccess(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitResponsibleAccess(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitResponsibleAccess1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitResponsibleAccess1(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitResponsibleAccess2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitResponsibleAccess2(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access by business unit type for all responsible
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitResponsibleAccess3(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitResponsibleAccess3(type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionReaderAccess(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionReaderAccess(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionReaderAccess1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionReaderAccess1(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionReaderAccess2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionReaderAccess2(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionReaderAccess3(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionReaderAccess3(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionReaderAccess4(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionReaderAccess4(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionReaderAccess5(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionReaderAccess5(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionResponsibleAccess(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionResponsibleAccess(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionResponsibleAccess1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionResponsibleAccess1(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionResponsibleAccess2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionResponsibleAccess2(
        businessUnitId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get accesses to business unit section for all responsible
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionResponsibleAccess3(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionResponsibleAccess3(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update access to BusinessUnit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {AccessUpdateRequest} accessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccessToBusinessUnitSections(
      businessUnitId: number,
      sectionId: number,
      accessUpdateRequest: AccessUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessToBusinessUnitSections(
        businessUnitId,
        sectionId,
        accessUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update access to BusinessUnit for readers
     * @param {number} businessUnitId
     * @param {AccessUpdateRequest} accessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccessToBusinessUnitsForReaders(
      businessUnitId: number,
      accessUpdateRequest: AccessUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessToBusinessUnitsForReaders(
        businessUnitId,
        accessUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update business unit access
     * @param {BusinessUnitAccessUpdateRequest} businessUnitAccessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitAccess(
      businessUnitAccessUpdateRequest: BusinessUnitAccessUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitAccess(
        businessUnitAccessUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccessControllerApi - factory interface
 * @export
 */
export const AccessControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccessControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Get business unit access list
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitAccessList(accountId: number, options?: any): AxiosPromise<BusinessUnitAccessUpdateRequest> {
      return localVarFp.getBusinessUnitAccessList(accountId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp.getBusinessUnitReaderAccess(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess1(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp.getBusinessUnitReaderAccess1(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess2(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp.getBusinessUnitReaderAccess2(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access by business unit type for all readers
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitReaderAccess3(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp.getBusinessUnitReaderAccess3(type, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitResponsibleAccess(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess1(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitResponsibleAccess1(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess2(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitResponsibleAccess2(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access by business unit type for all responsible
     * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitResponsibleAccess3(
      type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp.getBusinessUnitResponsibleAccess3(type, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionReaderAccess(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionReaderAccess1(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit section for all readers
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionReaderAccess2(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess3(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionReaderAccess3(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess4(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionReaderAccess4(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access to new business unit section for all readers
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionReaderAccess5(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionReaderAccess5(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess(
      businessUnitId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionResponsibleAccess(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess1(
      businessUnitId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionResponsibleAccess1(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get access to new business unit section for all responsible
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess2(
      businessUnitId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionResponsibleAccess2(businessUnitId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get accesses to business unit section for all responsible
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionResponsibleAccess3(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<Array<AccountAccessView>> {
      return localVarFp
        .getBusinessUnitSectionResponsibleAccess3(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update access to BusinessUnit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {AccessUpdateRequest} accessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccessToBusinessUnitSections(
      businessUnitId: number,
      sectionId: number,
      accessUpdateRequest: AccessUpdateRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateAccessToBusinessUnitSections(businessUnitId, sectionId, accessUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update access to BusinessUnit for readers
     * @param {number} businessUnitId
     * @param {AccessUpdateRequest} accessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccessToBusinessUnitsForReaders(
      businessUnitId: number,
      accessUpdateRequest: AccessUpdateRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateAccessToBusinessUnitsForReaders(businessUnitId, accessUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update business unit access
     * @param {BusinessUnitAccessUpdateRequest} businessUnitAccessUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitAccess(
      businessUnitAccessUpdateRequest: BusinessUnitAccessUpdateRequest,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateBusinessUnitAccess(businessUnitAccessUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AccessControllerApi - interface
 * @export
 * @interface AccessControllerApi
 */
export interface AccessControllerApiInterface {
  /**
   *
   * @summary Get business unit access list
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitAccessList(accountId: number, options?: any): AxiosPromise<BusinessUnitAccessUpdateRequest>;

  /**
   *
   * @summary Get accesses to business unit for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitReaderAccess(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitReaderAccess1(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitReaderAccess2(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access by business unit type for all readers
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitReaderAccess3(
    type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitResponsibleAccess(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitResponsibleAccess1(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitResponsibleAccess2(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access by business unit type for all responsible
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitResponsibleAccess3(
    type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER',
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit section for all readers
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionReaderAccess(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit section for all readers
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionReaderAccess1(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit section for all readers
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionReaderAccess2(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access to new business unit section for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionReaderAccess3(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access to new business unit section for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionReaderAccess4(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access to new business unit section for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionReaderAccess5(businessUnitId: number, options?: any): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access to new business unit section for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionResponsibleAccess(
    businessUnitId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access to new business unit section for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionResponsibleAccess1(
    businessUnitId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get access to new business unit section for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionResponsibleAccess2(
    businessUnitId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Get accesses to business unit section for all responsible
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  getBusinessUnitSectionResponsibleAccess3(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<Array<AccountAccessView>>;

  /**
   *
   * @summary Update access to BusinessUnit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {AccessUpdateRequest} accessUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  updateAccessToBusinessUnitSections(
    businessUnitId: number,
    sectionId: number,
    accessUpdateRequest: AccessUpdateRequest,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Update access to BusinessUnit for readers
   * @param {number} businessUnitId
   * @param {AccessUpdateRequest} accessUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  updateAccessToBusinessUnitsForReaders(
    businessUnitId: number,
    accessUpdateRequest: AccessUpdateRequest,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Update business unit access
   * @param {BusinessUnitAccessUpdateRequest} businessUnitAccessUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApiInterface
   */
  updateBusinessUnitAccess(
    businessUnitAccessUpdateRequest: BusinessUnitAccessUpdateRequest,
    options?: any
  ): AxiosPromise<object>;
}

/**
 * AccessControllerApi - object-oriented interface
 * @export
 * @class AccessControllerApi
 * @extends {BaseAPI}
 */
export class AccessControllerApi extends BaseAPI implements AccessControllerApiInterface {
  /**
   *
   * @summary Get business unit access list
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitAccessList(accountId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitAccessList(accountId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitReaderAccess(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitReaderAccess(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitReaderAccess1(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitReaderAccess1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitReaderAccess2(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitReaderAccess2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access by business unit type for all readers
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitReaderAccess3(type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER', options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitReaderAccess3(type, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitResponsibleAccess(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitResponsibleAccess(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitResponsibleAccess1(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitResponsibleAccess1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitResponsibleAccess2(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitResponsibleAccess2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access by business unit type for all responsible
   * @param {'PROJECT' | 'COMPANY' | 'COMPANY_OUTER'} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitResponsibleAccess3(type: 'PROJECT' | 'COMPANY' | 'COMPANY_OUTER', options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitResponsibleAccess3(type, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit section for all readers
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionReaderAccess(businessUnitId: number, sectionId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionReaderAccess(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit section for all readers
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionReaderAccess1(businessUnitId: number, sectionId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionReaderAccess1(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit section for all readers
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionReaderAccess2(businessUnitId: number, sectionId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionReaderAccess2(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access to new business unit section for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionReaderAccess3(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionReaderAccess3(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access to new business unit section for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionReaderAccess4(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionReaderAccess4(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access to new business unit section for all readers
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionReaderAccess5(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionReaderAccess5(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access to new business unit section for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionResponsibleAccess(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionResponsibleAccess(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access to new business unit section for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionResponsibleAccess1(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionResponsibleAccess1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get access to new business unit section for all responsible
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionResponsibleAccess2(businessUnitId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionResponsibleAccess2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get accesses to business unit section for all responsible
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public getBusinessUnitSectionResponsibleAccess3(businessUnitId: number, sectionId: number, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .getBusinessUnitSectionResponsibleAccess3(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update access to BusinessUnit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {AccessUpdateRequest} accessUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public updateAccessToBusinessUnitSections(
    businessUnitId: number,
    sectionId: number,
    accessUpdateRequest: AccessUpdateRequest,
    options?: any
  ) {
    return AccessControllerApiFp(this.configuration)
      .updateAccessToBusinessUnitSections(businessUnitId, sectionId, accessUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update access to BusinessUnit for readers
   * @param {number} businessUnitId
   * @param {AccessUpdateRequest} accessUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public updateAccessToBusinessUnitsForReaders(
    businessUnitId: number,
    accessUpdateRequest: AccessUpdateRequest,
    options?: any
  ) {
    return AccessControllerApiFp(this.configuration)
      .updateAccessToBusinessUnitsForReaders(businessUnitId, accessUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update business unit access
   * @param {BusinessUnitAccessUpdateRequest} businessUnitAccessUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControllerApi
   */
  public updateBusinessUnitAccess(businessUnitAccessUpdateRequest: BusinessUnitAccessUpdateRequest, options?: any) {
    return AccessControllerApiFp(this.configuration)
      .updateBusinessUnitAccess(businessUnitAccessUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
