import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AuthApiFactory, PinStatusResponse } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AuthApiFactory(ApiConfiguration);

type AccountPinStatusProviderType = QueryObserverResult<PinStatusResponse, unknown>;

const AccountPinStatusContext = createContext<AccountPinStatusProviderType | null>(null);
AccountPinStatusContext.displayName = `${upperFirst(RESOURCE_NAME)}PinStatusShow`;

export function useAccountPinStatusProvider(): AccountPinStatusProviderType {
  const contextState = useContext(AccountPinStatusContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useAccountPinStatusProvider.name} must be used within a ${AccountPinStatusContext.displayName} context`
    );
  }
  return contextState;
}

interface AccountPinStatusProviderProps {
  primaryUserId: number;
}
export function AccountPinStatusProvider(props: React.PropsWithChildren<AccountPinStatusProviderProps>) {
  const value = useQuery<PinStatusResponse, AxiosError<unknown>, PinStatusResponse>(
    [RESOURCE_NAME, 'PinStatusShow', { id: props.primaryUserId }],
    () => AccountApi.getPinStatus(props.primaryUserId).then(resp => resp.data)
  );
  return <AccountPinStatusContext.Provider value={value}>{props.children}</AccountPinStatusContext.Provider>;
}
