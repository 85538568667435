import React, { useRef, useState, createRef, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import { Field, Form, FormProps } from 'react-final-form';

import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import isObject from 'lodash/isObject';
import times from 'lodash/times';

import { number } from 'utils';
import { composeValidators, minLength, required } from '../validators';
import { useFormStyles } from '../styles';

export const PIN_CODE_LENGTH = 4;

interface IEnterPinCodeFormProps {
  onSubmit: FormProps['onSubmit'];
  name?: string;
  firstStep?: boolean;
  createPin?: boolean;
  switchStep?: () => void;
}

export function PinCodeFieldsForm({
  onSubmit,
  name = 'pin',
  firstStep = false,
  createPin = false,
  switchStep = () => {},
}: IEnterPinCodeFormProps) {
  const { t } = useTranslation();
  const [pin, setPin] = useState<string>('');
  const [firstPin, setFirstPin] = useState<string>('');
  const [hasError, setError] = useState<boolean>(false);

  const refs: any = [];
  times(PIN_CODE_LENGTH, i => {
    const ref = createRef();
    refs.push(ref);
  });
  const itemRefs = useRef(refs);

  const onChangeHandlerItem = useCallback(
    fieldProps => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const index = event.target.dataset.index || '0';
      const { value } = event.target;
      const newItem = value.replace(/\D/g, '');
      const newPin = pin + newItem;
      const nextIndexToGo = newPin.length;

      if (newItem) {
        itemRefs.current[index].current?.blur();
        if (newPin.length < PIN_CODE_LENGTH) {
          itemRefs.current[nextIndexToGo]?.current?.focus();
        }
        setPin(newPin);
        fieldProps.input.onChange(newPin);
      }
    },
    [pin]
  );

  const translateError = useCallback(
    error => {
      let errorText = '';
      if (isObject(error) as any) {
        errorText = t(error.message, error.args);
      } else {
        errorText = t(error);
      }
      return errorText;
    },
    [t]
  );

  const onKeyPressHandlerItem = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.keyCode === 8 && event.key === 'Backspace') {
        const newPin = pin.slice(0, pin.length - 1);
        setPin(newPin);
        if (pin.length) {
          const index = event.currentTarget.dataset.index || 0;
          if (index) {
            itemRefs.current[index]?.current?.blur();
            itemRefs.current[+index - 1]?.current?.focus();
          }
        }
        if (newPin.length === 0) {
          itemRefs.current[0]?.current?.focus();
        }
      }
    },
    [pin]
  );

  const onChangePinCodeField = useCallback(
    (pinCode, formProps: any) => {
      if (!pinCode || pinCode.length !== PIN_CODE_LENGTH) {
        return;
      }
      if (!createPin) {
        formProps.form.submit();
        setPin('');
        itemRefs.current[0].current?.focus();
        return;
      }
      if (firstStep) {
        setFirstPin(pinCode);
        formProps.form.submit();
        hasError && setError(false);
      } else {
        if (pinCode !== firstPin) {
          setError(true);
          setFirstPin('');
          switchStep();
        } else {
          formProps.form.submit();
        }
      }
      setPin('');
      itemRefs.current[0]?.current?.focus();
      formProps.form.reset();
    },
    [createPin, firstPin, firstStep, hasError, switchStep]
  );

  const helperText = hasError ? translateError('login:validation:pinNotMatch') : '';
  const classes = useFormStyles();
  return (
    <Form
      onSubmit={onSubmit}
      subscription={formSubscription}
      keepDirtyOnReinitialize={true}
      render={formProps => (
        <form name={name} onSubmit={formProps.handleSubmit} className={classes.form}>
          <Box mt={3} display="flex" alignItems="center" justifyContent="center">
            <Field name={name} validate={pinCodeFieldValidation}>
              {fieldProps => {
                return (
                  <React.Fragment>
                    {times(PIN_CODE_LENGTH, i => {
                      return (
                        <React.Fragment key={i}>
                          <Box key={i} className={classes.numberItemBox}>
                            <InputBase
                              id={`${name}-${name}${i}-component`}
                              autoFocus={i === 0}
                              inputRef={itemRefs.current[i]}
                              inputProps={{
                                name: `${name}${i}`,
                                maxLength: 1,
                                autoComplete: 'off',
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                'data-index': i,
                                'data-test': `loginPage_${name}${i}`,
                                type: 'password',
                              }}
                              onKeyUp={onKeyPressHandlerItem}
                              onChange={onChangeHandlerItem(fieldProps)}
                              value={pin[i] || ''}
                              className={classnames(classes.numberItem)}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    })}
                    <Box width={0} height={0}>
                      <FormControl>
                        <InputBase
                          id={`${name}-${fieldProps.input.name}-component`}
                          autoFocus={false}
                          inputProps={{
                            name: fieldProps.input.name,
                            maxLength: 1,
                            autoComplete: 'off',
                            inputMode: 'numeric',
                            'data-test': `${name}`,
                            pattern: '[0-9]*',
                          }}
                          value={pin || ''}
                        />
                      </FormControl>
                    </Box>
                  </React.Fragment>
                );
              }}
            </Field>
            <OnChange name={name}>
              {(pinCode: string) => {
                onChangePinCodeField(pinCode, formProps);
              }}
            </OnChange>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <FormHelperText id="pin-component-text" error color="error.main">
              {helperText}
            </FormHelperText>
          </Box>
        </form>
      )}
    />
  );
}

// Form Helpers
const pinCodeFieldValidation = composeValidators(required(), minLength(4), number());

// don't redraw entire form each time one field changes
const formSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};
