import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { useSearchProvider } from 'api/SearchProviders';

import { MembersSearchResults } from './SearchResults/MembersSearchResults';
import { DocumentsSearchResults } from './SearchResults/DocumentsSearchResults';
import { Spinner } from 'components/material';
import EmptyPage from './SearchResults/EmptyPage';

export function SearchResultsColumn({ searchText }: { searchText: string }) {
  const { t } = useTranslation();

  const { data, isFetched, isLoading } = useSearchProvider();

  if (isLoading) {
    return <Spinner />;
  }

  if (isFetched && !data) {
    return <EmptyPage label={t('common:searchResults.emptyQuery')} />;
  }

  if (isFetched && data.persons.length === 0 && data.documents.length === 0) {
    return <EmptyPage label={t('common:searchResults.emptyResults')} />;
  }

  if (data && isFetched) {
    return (
      <Box maxWidth={1064} width="100%">
        <Box mt={10}>
          <MembersSearchResults data={data} searchText={searchText} />
        </Box>
        <Box mt={10}>
          <DocumentsSearchResults data={data} searchText={searchText} />
        </Box>
      </Box>
    );
  }

  return null;
}

export default SearchResultsColumn;
