import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { FolderControllerApiFactory, FolderUpdateRequest } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from '../constants';

import isNil from 'lodash/isNil';

const FolderApi = FolderControllerApiFactory(ApiConfiguration);

type FolderUpdateProviderType = UseMutationResult<
  FolderUpdateRequest,
  AxiosError<unknown>,
  FolderUpdateRequest,
  unknown
>;

const FolderUpdateContext = createContext<FolderUpdateProviderType | null>(null);
FolderUpdateContext.displayName = 'CompanyUpdate';

export function useFolderUpdateProvider(): FolderUpdateProviderType {
  const contextState = useContext(FolderUpdateContext);
  if (isNil(contextState)) {
    throw new Error(`${useFolderUpdateProvider.name} must be used within a ${FolderUpdateContext.displayName} context`);
  }
  return contextState;
}

interface FolderUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: UseMutationOptions<FolderUpdateRequest, AxiosError<unknown>, FolderUpdateRequest, unknown>;
}

export function FolderUpdateProvider(props: React.PropsWithChildren<FolderUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: FolderUpdateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const create = useMutation<FolderUpdateRequest, AxiosError<unknown>, FolderUpdateRequest>(
    async (data: FolderUpdateRequest) => {
      return FolderApi.updateFolder2(props.businessUnitId, props.sectionId, data).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <FolderUpdateContext.Provider value={create}>{props.children}</FolderUpdateContext.Provider>;
}
