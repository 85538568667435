/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountShortView } from '../views';
// @ts-ignore
import { AccountView } from '../views';
// @ts-ignore
import { AccountWithUserCreateRequest } from '../views';
// @ts-ignore
import { AccountWithUserUpdateRequest } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Mark all users of the account as deleted. Warning! The operation is irreversible. For superusers only.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id);
      const localVarPath = `/usermanager/account/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create the account and primary user
     * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      accountWithUserCreateRequest: AccountWithUserCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountWithUserCreateRequest' is not null or undefined
      assertParamExists('create', 'accountWithUserCreateRequest', accountWithUserCreateRequest);
      const localVarPath = `/usermanager/account/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountWithUserCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create the account and primary user
     * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1: async (
      accountWithUserCreateRequest: AccountWithUserCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountWithUserCreateRequest' is not null or undefined
      assertParamExists('create1', 'accountWithUserCreateRequest', accountWithUserCreateRequest);
      const localVarPath = `/usermanager/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountWithUserCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download a photo by the account ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPhotoByAccountId: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('downloadPhotoByAccountId', 'id', id);
      const localVarPath = `/usermanager/account/{id}/photo`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the account for the specified ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountById: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccountById', 'id', id);
      const localVarPath = `/usermanager/account/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for the specified account ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountUpdateRequestById: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccountUpdateRequestById', 'id', id);
      const localVarPath = `/usermanager/account/{id}/edit`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all accounts. Returning list is depended on the current user role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountsDetails: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/usermanager/account/extended`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of responsible.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResponsibleList: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/usermanager/account/responsible/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lock the primary user of the account
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockPrimaryUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('lockPrimaryUser', 'id', id);
      const localVarPath = `/usermanager/account/{id}/lock`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlock the primary user of the account
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockPrimaryUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('unlockPrimaryUser', 'id', id);
      const localVarPath = `/usermanager/account/{id}/unlock`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the account and primary user
     * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountWithUserUpdateRequest' is not null or undefined
      assertParamExists('update', 'accountWithUserUpdateRequest', accountWithUserUpdateRequest);
      const localVarPath = `/usermanager/account/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountWithUserUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the account and primary user
     * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1: async (
      accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountWithUserUpdateRequest' is not null or undefined
      assertParamExists('update1', 'accountWithUserUpdateRequest', accountWithUserUpdateRequest);
      const localVarPath = `/usermanager/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountWithUserUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Mark all users of the account as deleted. Warning! The operation is irreversible. For superusers only.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create the account and primary user
     * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      accountWithUserCreateRequest: AccountWithUserCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(accountWithUserCreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create the account and primary user
     * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create1(
      accountWithUserCreateRequest: AccountWithUserCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create1(accountWithUserCreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download a photo by the account ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadPhotoByAccountId(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPhotoByAccountId(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the account for the specified ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountById(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for the specified account ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountUpdateRequestById(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountUpdateRequestById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get all accounts. Returning list is depended on the current user role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountsDetails(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsDetails(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get list of responsible.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResponsibleList(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResponsibleList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Lock the primary user of the account
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockPrimaryUser(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lockPrimaryUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Unlock the primary user of the account
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlockPrimaryUser(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlockPrimaryUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the account and primary user
     * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(accountWithUserUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the account and primary user
     * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update1(
      accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountWithUserUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update1(accountWithUserUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Mark all users of the account as deleted. Warning! The operation is irreversible. For superusers only.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<AccountView> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create the account and primary user
     * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      accountWithUserCreateRequest: AccountWithUserCreateRequest,
      options?: any
    ): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.create(accountWithUserCreateRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create the account and primary user
     * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1(
      accountWithUserCreateRequest: AccountWithUserCreateRequest,
      options?: any
    ): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.create1(accountWithUserCreateRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download a photo by the account ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPhotoByAccountId(id: number, options?: any): AxiosPromise<any> {
      return localVarFp.downloadPhotoByAccountId(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the account for the specified ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountById(id: number, options?: any): AxiosPromise<AccountView> {
      return localVarFp.getAccountById(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for the specified account ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountUpdateRequestById(id: number, options?: any): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.getAccountUpdateRequestById(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all accounts. Returning list is depended on the current user role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountsDetails(options?: any): AxiosPromise<Array<AccountView>> {
      return localVarFp.getAccountsDetails(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of responsible.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResponsibleList(options?: any): AxiosPromise<Array<AccountShortView>> {
      return localVarFp.getResponsibleList(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lock the primary user of the account
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockPrimaryUser(id: number, options?: any): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.lockPrimaryUser(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unlock the primary user of the account
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockPrimaryUser(id: number, options?: any): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.unlockPrimaryUser(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the account and primary user
     * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
      options?: any
    ): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.update(accountWithUserUpdateRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the account and primary user
     * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1(
      accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
      options?: any
    ): AxiosPromise<AccountWithUserUpdateRequest> {
      return localVarFp.update1(accountWithUserUpdateRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AccountControllerApi - interface
 * @export
 * @interface AccountControllerApi
 */
export interface AccountControllerApiInterface {
  /**
   *
   * @summary Mark all users of the account as deleted. Warning! The operation is irreversible. For superusers only.
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  _delete(id: number, options?: any): AxiosPromise<AccountView>;

  /**
   *
   * @summary Create the account and primary user
   * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  create(
    accountWithUserCreateRequest: AccountWithUserCreateRequest,
    options?: any
  ): AxiosPromise<AccountWithUserUpdateRequest>;

  /**
   *
   * @summary Create the account and primary user
   * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  create1(
    accountWithUserCreateRequest: AccountWithUserCreateRequest,
    options?: any
  ): AxiosPromise<AccountWithUserUpdateRequest>;

  /**
   *
   * @summary Download a photo by the account ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  downloadPhotoByAccountId(id: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Get the account for the specified ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  getAccountById(id: number, options?: any): AxiosPromise<AccountView>;

  /**
   *
   * @summary Get the \'UpdateRequest\' for the specified account ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  getAccountUpdateRequestById(id: number, options?: any): AxiosPromise<AccountWithUserUpdateRequest>;

  /**
   *
   * @summary Get all accounts. Returning list is depended on the current user role.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  getAccountsDetails(options?: any): AxiosPromise<Array<AccountView>>;

  /**
   *
   * @summary Get list of responsible.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  getResponsibleList(options?: any): AxiosPromise<Array<AccountShortView>>;

  /**
   *
   * @summary Lock the primary user of the account
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  lockPrimaryUser(id: number, options?: any): AxiosPromise<AccountWithUserUpdateRequest>;

  /**
   *
   * @summary Unlock the primary user of the account
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  unlockPrimaryUser(id: number, options?: any): AxiosPromise<AccountWithUserUpdateRequest>;

  /**
   *
   * @summary Update the account and primary user
   * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  update(
    accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
    options?: any
  ): AxiosPromise<AccountWithUserUpdateRequest>;

  /**
   *
   * @summary Update the account and primary user
   * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApiInterface
   */
  update1(
    accountWithUserUpdateRequest: AccountWithUserUpdateRequest,
    options?: any
  ): AxiosPromise<AccountWithUserUpdateRequest>;
}

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI implements AccountControllerApiInterface {
  /**
   *
   * @summary Mark all users of the account as deleted. Warning! The operation is irreversible. For superusers only.
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public _delete(id: number, options?: any) {
    return AccountControllerApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create the account and primary user
   * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public create(accountWithUserCreateRequest: AccountWithUserCreateRequest, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .create(accountWithUserCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create the account and primary user
   * @param {AccountWithUserCreateRequest} accountWithUserCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public create1(accountWithUserCreateRequest: AccountWithUserCreateRequest, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .create1(accountWithUserCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download a photo by the account ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public downloadPhotoByAccountId(id: number, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .downloadPhotoByAccountId(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the account for the specified ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public getAccountById(id: number, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .getAccountById(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the \'UpdateRequest\' for the specified account ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public getAccountUpdateRequestById(id: number, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .getAccountUpdateRequestById(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all accounts. Returning list is depended on the current user role.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public getAccountsDetails(options?: any) {
    return AccountControllerApiFp(this.configuration)
      .getAccountsDetails(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list of responsible.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public getResponsibleList(options?: any) {
    return AccountControllerApiFp(this.configuration)
      .getResponsibleList(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lock the primary user of the account
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public lockPrimaryUser(id: number, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .lockPrimaryUser(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlock the primary user of the account
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public unlockPrimaryUser(id: number, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .unlockPrimaryUser(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the account and primary user
   * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public update(accountWithUserUpdateRequest: AccountWithUserUpdateRequest, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .update(accountWithUserUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the account and primary user
   * @param {AccountWithUserUpdateRequest} accountWithUserUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountControllerApi
   */
  public update1(accountWithUserUpdateRequest: AccountWithUserUpdateRequest, options?: any) {
    return AccountControllerApiFp(this.configuration)
      .update1(accountWithUserUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
