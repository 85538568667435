import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Field, FieldInputProps, useFormState } from 'react-final-form';
import { useDropzone } from 'react-dropzone';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { getAccountPhotoURL } from 'api/http';

import { TextField, PhoneField } from 'components/fields';
import { composeValidators, required, phoneLength, maxLength, requiredPhone } from 'components/fields/validators';
import { ProfileIcon } from 'components/icons';

import trim from 'utils/trim';

import first from 'lodash/first';
import { AccountInitialFormValuesType } from './AccountForm';

export function PersonalInfo({ readOnly = false }: { readOnly?: boolean }) {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">{t('accounts:create.personalInfoSection')}</Typography>
      </Box>
      <Box my={4}>
        <Field name="photo">{({ input }) => <DropzoneAvatar {...input} readOnly={readOnly} />}</Field>
      </Box>
      <Box display="flex">
        <Box flexGrow={1} flexBasis="50%" mr={1.5}>
          <TextField
            label={t('accounts:create.fields.lastName')}
            placeholder={t('accounts:create.fields.lastNamePlaceholder')}
            name="lastName"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.firstName')}
            placeholder={t('accounts:create.fields.firstNamePlaceholder')}
            name="firstName"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.middleName')}
            placeholder={t('accounts:create.fields.middleNamePlaceholder')}
            name="middleName"
            margin="dense"
            fieldProps={{
              validate: middleNameValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <PhoneField
            label={t('accounts:create.fields.userPhone')}
            placeholder={t('accounts:create.fields.userPhonePlaceholder')}
            name="userPhone"
            margin="dense"
            required
            fieldProps={{ validate: userPhoneValidator }}
          />
          <PhoneField
            label={t('accounts:create.fields.smsPhone')}
            placeholder={t('accounts:create.fields.smsPhonePlaceholder')}
            name="smsPhone"
            margin="dense"
            fieldProps={{ validate: phoneLength }}
          />
        </Box>
        <Box flexGrow={1} flexBasis="50%" ml={1.5}>
          <TextField
            label={t('accounts:create.fields.lastNameEn')}
            placeholder={t('accounts:create.fields.lastNameEnPlaceholder')}
            name="lastNameEn"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.firstNameEn')}
            placeholder={t('accounts:create.fields.firstNameEnPlaceholder')}
            name="firstNameEn"
            margin="dense"
            required
            fieldProps={{
              validate: firstLastNamesValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
          <TextField
            label={t('accounts:create.fields.middleNameEn')}
            placeholder={t('accounts:create.fields.middleNameEnPlaceholder')}
            name="middleNameEn"
            margin="dense"
            fieldProps={{
              validate: middleNameValidator,
              format: trim,
              formatOnBlur: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

const firstLastNamesValidator = composeValidators(required, maxLength(255));
const middleNameValidator = maxLength(255);
const userPhoneValidator = composeValidators(requiredPhone, phoneLength);

function DropzoneAvatar({ onChange, value, readOnly = false }: FieldInputProps<File> & { readOnly?: boolean }) {
  const { t } = useTranslation();

  const [filePreview, setFilePreview] = useState<string>();
  const { values: account } = useFormState<AccountInitialFormValuesType>();

  useEffect(() => {
    if (account?.accountId) {
      setFilePreview(getAccountPhotoURL(account.accountId, account.photoId));
    }
  }, [account.accountId, account.photoId]);

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      filePreview && URL.revokeObjectURL(filePreview);
    };
  }, [filePreview]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = first(acceptedFiles);
      if (!file) {
        return;
      }
      setFilePreview(URL.createObjectURL(file));
      onChange(file);
    },
    [onChange]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
    noDragEventsBubbling: true,
  });

  const classes = useDropzoneAvatarStyles();
  return (
    <Box display="flex" alignItems="center" my={4}>
      <Box {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <CustomAvatar src={filePreview} alt="avatar">
          <Box display="flex" alignItems="center" justifyContent="center" fontSize="5rem">
            <ProfileIcon fontSize="inherit" />
          </Box>
        </CustomAvatar>
      </Box>
      <Box ml={3}>
        <Button variant="outlined" onClick={open} disabled={readOnly}>
          {t('accounts:create.uploadPhoto')}
        </Button>
      </Box>
    </Box>
  );
}

const useDropzoneAvatarStyles = makeStyles(theme => ({
  dropzone: {
    outline: 'none',
    cursor: 'pointer',
  },
}));

export const CustomAvatar = withStyles(theme => ({
  root: {
    height: 76,
    width: 76,
  },
}))(Avatar);
