import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps, useLocation } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { getBusinessUnitIconURL, getPersonPhotoURL } from 'api/http';
import { PersonDetailsProvider, usePersonDetailsProvider } from 'api/PersonsProvider';

import { PencilIcon } from 'components/icons';
import { Modal, ModalToolbar, Spinner } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { GroupRequiredRouterParams } from '../interfaces';

import map from 'lodash/map';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import format from 'date-fns/format';
import { ContactInfo } from 'components/common/ContactInfo';
import { useAuthProvider } from 'api/AuthProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';

export function PersonDetailsDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();

  const match = useRouteMatch<GroupRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const personId = parseInt(match.params.personId || '', 10);

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url, 3));

  return (
    <Modal
      title={t('persons:dialog.detailsTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
    >
      <PersonDetailsProvider businessUnitId={businessUnitId} personId={personId}>
        <Box>
          <PersonDetails businessUnitId={businessUnitId} personId={personId} />
        </Box>
      </PersonDetailsProvider>
    </Modal>
  );
}

interface PersonPositionHistoryProps {
  data: any[];
}
function PersonHistory({ data }: PersonPositionHistoryProps) {
  const [showHistory, setShowHistory] = useState(false);
  const { t } = useTranslation();

  if (data.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" mt={6} mb={3}>
        <Box display="flex">
          <Box color="grey.500" alignSelf="flex-start" mr={2.5} mt={0.5}>
            {showHistory ? (
              <ExpandLess style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => setShowHistory(false)} />
            ) : (
              <ExpandMore style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => setShowHistory(true)} />
            )}
          </Box>
          <Box>
            <Box fontSize="subtitle1.fontSize" flexGrow={1} maxHeight={300} overflow="auto" fontWeight="bold">
              {t('persons:dialog.positionHistory')}
            </Box>
            <Box mt={2}>
              {showHistory &&
                data.map((history: any, i: number) => (
                  <Box key={i} mt={2}>
                    <Box display="inline-block" width="25%">
                      <Box>
                        {t('persons:dialog.positionHistoryFrom')}
                        {history.positionStartDate ? format(new Date(history.positionStartDate), 'dd.MM.yyyy') : '-'}
                      </Box>
                      <Box>
                        {history.positionEndDate ? (
                          <span>
                            {t('persons:dialog.positionHistoryTo')}
                            {format(new Date(history.positionEndDate), 'dd.MM.yyyy')}
                          </span>
                        ) : (
                          <span style={{ color: '#A2A6B4' }}>{t('persons:dialog.positionHistoryCurrentTime')}</span>
                        )}
                      </Box>
                    </Box>
                    <Box display="inline-block" ml={4}>
                      <Box style={{ textAlign: 'left' }}>{history.positionTitle}</Box>
                      <Box style={{ textAlign: 'left' }}>{history.groupTitle}</Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

interface PersonDetailsProps {
  businessUnitId: number;
  personId: number;
}
function PersonDetails({ businessUnitId, personId }: PersonDetailsProps) {
  const { t } = useTranslation();
  const { role } = useAuthProvider();
  const history = useHistory();
  const { search, pathname: url } = useLocation();

  const { data: person, isFetched, isLoading } = usePersonDetailsProvider();

  const personHistory: any[] =
    person?.activePositions && person?.archivePositions ? [...person.activePositions, ...person.archivePositions] : [];

  if (isLoading) {
    return <Spinner />;
  }

  if (!person && isFetched) {
    return null;
  }

  const goToEditMembersPositionHandler = (event: any) => {
    history.push({ pathname: `${makeParentUrl(url)}/edit`, search });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <PersonAvatar src={getPersonPhotoURL(businessUnitId, person!.personId)} alt="avatar" />
        </Box>
        <Box fontSize="subtitle1.fontSize" fontWeight={600}>
          <Box>{person?.lastName}</Box>
          <Box>{`${person?.firstName} ${person?.middleName || ''}`}</Box>
        </Box>
      </Box>
      <ContactInfo person={person} i18nNamespace="persons"></ContactInfo>
      {person?.company && (
        <Box display="flex" flexDirection="column" mt={5}>
          <Box display="flex" alignItems="center">
            {person.company && (
              <Box mr={2.5}>
                <CompanyAvatar src={getBusinessUnitIconURL(person.company.id)} alt="company" />
              </Box>
            )}
            <Box>
              {person.company && (
                <Typography variant="subtitle1" style={{ lineHeight: 1.4 }}>
                  {person.company.title}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {person?.description && (
        <Box display="flex" flexDirection="column" mt={6}>
          <Box display="flex">
            <Box color="grey.500" alignSelf="flex-start" mr={2.5} mt={0.5}>
              <PencilIcon style={{ fontSize: 18 }} />
            </Box>
            <Box fontSize="subtitle1.fontSize" flexGrow={1} maxHeight={300} overflow="auto">
              {map(person?.description.split('\n'), (item, idx) => (
                <Box key={idx.toString()}>{item}</Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {person?.company && <PersonHistory data={personHistory} />}

      {role === UserSessionStateViewRoleEnum.READER ? (
        <Box mb={4} />
      ) : (
        <Box mx={-4}>
          <ModalToolbar>
            <Box width="100%">
              <Box mr={1.5} display="inline-block">
                <Button variant="contained" color="secondary" onClick={goToEditMembersPositionHandler}>
                  {t('persons:actionsMenu.editPosition')}
                </Button>
              </Box>
              {/*<Box display="inline-block" style={{ float: 'right' }}>
              <Button variant="contained" color="secondary" style={{ color: '#EB6060' }}>
                {t('persons:actionsMenu.removePosition')}
              </Button>
            </Box>*/}
            </Box>
          </ModalToolbar>
        </Box>
      )}
    </Box>
  );
}

export const PersonAvatar = withStyles(theme => ({
  root: {
    height: 96,
    width: 96,
  },
}))(Avatar);

export const CompanyAvatar = withStyles(theme => ({
  root: {
    height: 48,
    width: 48,
  },
}))(Avatar);
