import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { FileControllerApiFactory, PersonControllerApiFactory, PersonUpdateRequest } from 'api/generated';
import { ApiConfiguration, FileApiConfiguration, invalidateFallbackImageCache } from 'api/http';

import { RESOURCE_NAME } from './constants';
import { StructType } from './type-helpers';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

const PersonApi = PersonControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

export type PersonWithPhotoUpdateRequest = {
  photo?: File;
} & PersonUpdateRequest;

type PersonMutatorsProviderType = {
  update: UseMutationResult<PersonUpdateRequest, AxiosError<unknown>, PersonWithPhotoUpdateRequest, unknown>;
  create: UseMutationResult<PersonUpdateRequest, AxiosError<unknown>, PersonWithPhotoUpdateRequest, unknown>;
  businessUnitId: number;
  structType: StructType;
};

const PersonMutatorsContext = createContext<PersonMutatorsProviderType | null>(null);
PersonMutatorsContext.displayName = `${upperFirst(RESOURCE_NAME)}Mutators`;

export function usePersonMutatorsProvider(): PersonMutatorsProviderType {
  const contextState = useContext(PersonMutatorsContext);
  if (isNil(contextState)) {
    throw new Error(
      `${usePersonMutatorsProvider.name} must be used within a ${PersonMutatorsContext.displayName} context`
    );
  }
  return contextState;
}

interface PersonMutatorsProviderProps {
  queryOptions?: UseMutationOptions<PersonUpdateRequest, AxiosError<unknown>, PersonWithPhotoUpdateRequest, unknown>;
  businessUnitId: number;
  structType: StructType;
}
export function PersonMutatorsProvider(props: React.PropsWithChildren<PersonMutatorsProviderProps>) {
  const create = usePersonMutation('create', props);
  const update = usePersonMutation('update', props);
  return (
    <PersonMutatorsContext.Provider
      value={{
        create,
        update,
        businessUnitId: props.businessUnitId,
        structType: props.structType,
      }}
    >
      {props.children}
    </PersonMutatorsContext.Provider>
  );
}

function usePersonMutation(type: 'create' | 'update', props: React.PropsWithChildren<PersonMutatorsProviderProps>) {
  const queryClient = useQueryClient();
  const invalidateQueryOptions: PersonMutatorsProviderProps['queryOptions'] = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      if (variables.photo) {
        invalidateFallbackImageCache();
      }
      if (props.queryOptions?.onSuccess) {
        props.queryOptions?.onSuccess(data, variables, context);
      }
    },
  };

  return useMutation<PersonUpdateRequest, AxiosError<unknown>, PersonWithPhotoUpdateRequest>(
    async (data: PersonWithPhotoUpdateRequest) => {
      if (data.photo) {
        const photo = data.photo;
        const file = await FileApi.upload(photo, photo.name).then(resp => resp.data);
        data.photoId = file.id;
      }
      const params = omit(data, ['photo']);
      if (type === 'update') {
        return PersonApi.updateBusinessUnitPerson2(props.businessUnitId, params).then(resp => resp.data);
      } else {
        // TODO: replace with union function for company and project
        switch (props.structType) {
          case 'COMPANY': {
            return PersonApi.createPersonForCompany(props.businessUnitId, params).then(resp => resp.data);
          }
          case 'PROJECT': {
            return PersonApi.createPersonForProject(props.businessUnitId, params).then(resp => resp.data);
          }
          default: {
            return Promise.reject(`Unknown structType '${props.structType}'`);
          }
        }
      }
    },
    {
      ...(props.queryOptions || {}),
      ...invalidateQueryOptions,
    }
  );
}
