import React from 'react';

import { Switches, SwitchProps } from 'mui-rff';
import makeStyles from '@material-ui/core/styles/makeStyles';

import isArray from 'lodash/isArray';
import first from 'lodash/first';

export function Switch({ data: dataProp, formControlLabelProps, formControlProps, ...rest }: SwitchProps) {
  const data = isArray(dataProp) ? first(dataProp)! : dataProp;

  const classes = useStyles();
  return (
    <Switches
      data={data}
      formControlLabelProps={{
        classes: { root: classes.switchArea },
        labelPlacement: 'start',
        ...formControlLabelProps,
      }}
      color="primary"
      formControlProps={{ fullWidth: true, ...formControlProps }}
      {...rest}
    />
  );
}

const useStyles = makeStyles(theme => ({
  switchArea: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.1rem',
    marginLeft: 0,
  },
}));
