import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { AuthApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AuthApi = AuthApiFactory(ApiConfiguration);

export interface AccountExpirePinRequest {
  primaryUserId?: number;
}

type AccountExpirePinProviderType = UseMutationResult<object, AxiosError<unknown>, AccountExpirePinRequest, unknown>;

const AccountExpirePinContext = createContext<AccountExpirePinProviderType | null>(null);
AccountExpirePinContext.displayName = `${upperFirst(RESOURCE_NAME)}Expire`;

export function useAccountExpirePinProvider(): AccountExpirePinProviderType {
  const contextState = useContext(AccountExpirePinContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useAccountExpirePinProvider.name} must be used within a ${AccountExpirePinContext.displayName} context`
    );
  }
  return contextState;
}

interface AccountExpirePinProviderProps {
  primaryUserId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, AccountExpirePinRequest, unknown>;
}

export function AccountExpirePinProvider(props: React.PropsWithChildren<AccountExpirePinProviderProps>) {
  const queryClient = useQueryClient();
  const defaultOptions: AccountExpirePinProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries([RESOURCE_NAME, 'PinStatusShow', { id: props.primaryUserId }]);
    },
  };
  const value = useMutation<object, AxiosError<unknown>, AccountExpirePinRequest>(
    async ({ primaryUserId }: AccountExpirePinRequest) => {
      return AuthApi.expirePin(props.primaryUserId).then(resp => resp.data);
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <AccountExpirePinContext.Provider value={value}>{props.children}</AccountExpirePinContext.Provider>;
}
