/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DocumentCardMetaView } from './document-card-meta-view';

/**
 * Document card
 * @export
 * @interface DocumentCardView
 */
export interface DocumentCardView {
  /**
   * Description of file fields for each languages
   * @type {Array<DocumentCardMetaView>}
   * @memberof DocumentCardView
   */
  documentMetas: Array<DocumentCardMetaView>;
  /**
   * Document id
   * @type {number}
   * @memberof DocumentCardView
   */
  id: number;
  /**
   * Document status
   * @type {string}
   * @memberof DocumentCardView
   */
  status?: DocumentCardViewStatusEnum;
  /**
   * Version of the document
   * @type {number}
   * @memberof DocumentCardView
   */
  version: number;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentCardViewStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
