import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { SearchResult } from './SearchResult';
import Box from '@material-ui/core/Box';
import MemberSearchResultItem from './MemberSearchResultItem';
import { SearchPersonProvider } from '../../../api/SearchProviders';
import { PersonsSearchMoreResults } from './PersonsSearchMoreResults';

export function MembersSearchResults({ data, searchText }: any) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const more = () => {
    setShowMore(true);
  };

  const hide = () => {
    setShowMore(false);
  };

  if (data.persons.length === 0) {
    return null;
  }

  return (
    <>
      <SearchResult
        title={t('common:searchResults.members')}
        hasMore={data.hasMorePersons}
        onShowMore={more}
        onHideMore={hide}
        showMore={showMore}
      />
      {data.persons &&
        data.persons.map((person: any) => (
          <Box key={person.personFolderId}>
            <MemberSearchResultItem person={person} />
          </Box>
        ))}
      {showMore && (
        <Box>
          <SearchPersonProvider searchText={searchText}>
            <PersonsSearchMoreResults />
          </SearchPersonProvider>
        </Box>
      )}
    </>
  );
}
