import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { AccountView } from 'api/generated';
import { getAccountPhotoURL } from 'api/http';

import { formatUserName } from 'utils/formatName';

interface AccountNameProps {
  account: AccountView;
}
export function AccountName({ account }: AccountNameProps) {
  const name = formatUserName(account);
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box>
        <Avatar src={getAccountPhotoURL(account.id)} alt={name} className={classes.medium} />
      </Box>
      <Box ml={2}>
        <Typography variant="subtitle1">{name}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  large: {
    height: 64,
    width: 64,
  },
  medium: {
    height: 48,
    width: 48,
  },
  small: {
    height: 24,
    width: 24,
  },
}));
