/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PersonFolderChangeRequest } from '../views';
// @ts-ignore
import { PersonFolderCreateRequest } from '../views';
// @ts-ignore
import { PersonFolderMultiUpdateRequest } from '../views';
// @ts-ignore
import { PersonFolderUpdateRequest } from '../views';
// @ts-ignore
import { PersonFolderView } from '../views';
// @ts-ignore
import { PersonWithFoldersView } from '../views';
// @ts-ignore
import { ProcessPersonFoldersRequest } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * PersonFolderControllerApi - axios parameter creator
 * @export
 */
export const PersonFolderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Change person folder with save history
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderChangeRequest} personFolderChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePersonFolder: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderChangeRequest: PersonFolderChangeRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('changePersonFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('changePersonFolder', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('changePersonFolder', 'groupFolderId', groupFolderId);
      // verify required parameter 'personFolderChangeRequest' is not null or undefined
      assertParamExists('changePersonFolder', 'personFolderChangeRequest', personFolderChangeRequest);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/personFolder/change`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personFolderChangeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderCreateRequest} personFolderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPersonFolders: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderCreateRequest: PersonFolderCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createPersonFolders', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createPersonFolders', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('createPersonFolders', 'groupFolderId', groupFolderId);
      // verify required parameter 'personFolderCreateRequest' is not null or undefined
      assertParamExists('createPersonFolders', 'personFolderCreateRequest', personFolderCreateRequest);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/personFolder`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personFolderCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePersonFolders: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deletePersonFolders', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deletePersonFolders', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('deletePersonFolders', 'groupFolderId', groupFolderId);
      // verify required parameter 'personFolderId' is not null or undefined
      assertParamExists('deletePersonFolders', 'personFolderId', personFolderId);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/personFolder/{personFolderId}`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)))
          .replace(`{${'personFolderId'}}`, encodeURIComponent(String(personFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all person folders of the specified person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPersonFolders: async (businessUnitId: number, personId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getAllPersonFolders', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getAllPersonFolders', 'personId', personId);
      const localVarPath = `/struct/ALL/{businessUnitId}/personFolder/edit`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (personId !== undefined) {
        localVarQueryParameter['personId'] = personId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonFolderById: async (
      businessUnitId: number,
      sectionId: number,
      personFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getPersonFolderById', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getPersonFolderById', 'sectionId', sectionId);
      // verify required parameter 'personFolderId' is not null or undefined
      assertParamExists('getPersonFolderById', 'personFolderId', personFolderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/personFolder/{personFolderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'personFolderId'}}`, encodeURIComponent(String(personFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person folder for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonFolderByIdForEdit: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getPersonFolderByIdForEdit', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getPersonFolderByIdForEdit', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('getPersonFolderByIdForEdit', 'groupFolderId', groupFolderId);
      // verify required parameter 'personFolderId' is not null or undefined
      assertParamExists('getPersonFolderByIdForEdit', 'personFolderId', personFolderId);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/personFolder/{personFolderId}/edit`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)))
          .replace(`{${'personFolderId'}}`, encodeURIComponent(String(personFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Multi update (create, update, delete) person folders
     * @param {number} businessUnitId
     * @param {PersonFolderMultiUpdateRequest} personFolderMultiUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    multiUpdatePersonFolders: async (
      businessUnitId: number,
      personFolderMultiUpdateRequest: PersonFolderMultiUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('multiUpdatePersonFolders', 'businessUnitId', businessUnitId);
      // verify required parameter 'personFolderMultiUpdateRequest' is not null or undefined
      assertParamExists('multiUpdatePersonFolders', 'personFolderMultiUpdateRequest', personFolderMultiUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/personFolder/multiUpdate`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personFolderMultiUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderUpdateRequest} personFolderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonFolders: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderUpdateRequest: PersonFolderUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updatePersonFolders', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updatePersonFolders', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('updatePersonFolders', 'groupFolderId', groupFolderId);
      // verify required parameter 'personFolderUpdateRequest' is not null or undefined
      assertParamExists('updatePersonFolders', 'personFolderUpdateRequest', personFolderUpdateRequest);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/personFolder`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personFolderUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update person folders
     * @param {number} businessUnitId
     * @param {ProcessPersonFoldersRequest} processPersonFoldersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonFolders1: async (
      businessUnitId: number,
      processPersonFoldersRequest: ProcessPersonFoldersRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updatePersonFolders1', 'businessUnitId', businessUnitId);
      // verify required parameter 'processPersonFoldersRequest' is not null or undefined
      assertParamExists('updatePersonFolders1', 'processPersonFoldersRequest', processPersonFoldersRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/personFolder`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        processPersonFoldersRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PersonFolderControllerApi - functional programming interface
 * @export
 */
export const PersonFolderControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PersonFolderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Change person folder with save history
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderChangeRequest} personFolderChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePersonFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderChangeRequest: PersonFolderChangeRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonFolderChangeRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePersonFolder(
        businessUnitId,
        sectionId,
        groupFolderId,
        personFolderChangeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderCreateRequest} personFolderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPersonFolders(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderCreateRequest: PersonFolderCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonFolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonFolders(
        businessUnitId,
        sectionId,
        groupFolderId,
        personFolderCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePersonFolders(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePersonFolders(
        businessUnitId,
        sectionId,
        groupFolderId,
        personFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get all person folders of the specified person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPersonFolders(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonWithFoldersView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPersonFolders(businessUnitId, personId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPersonFolderById(
      businessUnitId: number,
      sectionId: number,
      personFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonFolderView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonFolderById(
        businessUnitId,
        sectionId,
        personFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person folder for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPersonFolderByIdForEdit(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonFolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonFolderByIdForEdit(
        businessUnitId,
        sectionId,
        groupFolderId,
        personFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Multi update (create, update, delete) person folders
     * @param {number} businessUnitId
     * @param {PersonFolderMultiUpdateRequest} personFolderMultiUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async multiUpdatePersonFolders(
      businessUnitId: number,
      personFolderMultiUpdateRequest: PersonFolderMultiUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonFolderMultiUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.multiUpdatePersonFolders(
        businessUnitId,
        personFolderMultiUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderUpdateRequest} personFolderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePersonFolders(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderUpdateRequest: PersonFolderUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonFolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonFolders(
        businessUnitId,
        sectionId,
        groupFolderId,
        personFolderUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update person folders
     * @param {number} businessUnitId
     * @param {ProcessPersonFoldersRequest} processPersonFoldersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePersonFolders1(
      businessUnitId: number,
      processPersonFoldersRequest: ProcessPersonFoldersRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonWithFoldersView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonFolders1(
        businessUnitId,
        processPersonFoldersRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PersonFolderControllerApi - factory interface
 * @export
 */
export const PersonFolderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PersonFolderControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Change person folder with save history
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderChangeRequest} personFolderChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePersonFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderChangeRequest: PersonFolderChangeRequest,
      options?: any
    ): AxiosPromise<PersonFolderChangeRequest> {
      return localVarFp
        .changePersonFolder(businessUnitId, sectionId, groupFolderId, personFolderChangeRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderCreateRequest} personFolderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPersonFolders(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderCreateRequest: PersonFolderCreateRequest,
      options?: any
    ): AxiosPromise<PersonFolderUpdateRequest> {
      return localVarFp
        .createPersonFolders(businessUnitId, sectionId, groupFolderId, personFolderCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePersonFolders(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .deletePersonFolders(businessUnitId, sectionId, groupFolderId, personFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all person folders of the specified person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPersonFolders(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonWithFoldersView> {
      return localVarFp
        .getAllPersonFolders(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonFolderById(
      businessUnitId: number,
      sectionId: number,
      personFolderId: number,
      options?: any
    ): AxiosPromise<PersonFolderView> {
      return localVarFp
        .getPersonFolderById(businessUnitId, sectionId, personFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person folder for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {number} personFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonFolderByIdForEdit(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderId: number,
      options?: any
    ): AxiosPromise<PersonFolderUpdateRequest> {
      return localVarFp
        .getPersonFolderByIdForEdit(businessUnitId, sectionId, groupFolderId, personFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Multi update (create, update, delete) person folders
     * @param {number} businessUnitId
     * @param {PersonFolderMultiUpdateRequest} personFolderMultiUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    multiUpdatePersonFolders(
      businessUnitId: number,
      personFolderMultiUpdateRequest: PersonFolderMultiUpdateRequest,
      options?: any
    ): AxiosPromise<PersonFolderMultiUpdateRequest> {
      return localVarFp
        .multiUpdatePersonFolders(businessUnitId, personFolderMultiUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update person folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {PersonFolderUpdateRequest} personFolderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonFolders(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      personFolderUpdateRequest: PersonFolderUpdateRequest,
      options?: any
    ): AxiosPromise<PersonFolderUpdateRequest> {
      return localVarFp
        .updatePersonFolders(businessUnitId, sectionId, groupFolderId, personFolderUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update person folders
     * @param {number} businessUnitId
     * @param {ProcessPersonFoldersRequest} processPersonFoldersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonFolders1(
      businessUnitId: number,
      processPersonFoldersRequest: ProcessPersonFoldersRequest,
      options?: any
    ): AxiosPromise<PersonWithFoldersView> {
      return localVarFp
        .updatePersonFolders1(businessUnitId, processPersonFoldersRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PersonFolderControllerApi - interface
 * @export
 * @interface PersonFolderControllerApi
 */
export interface PersonFolderControllerApiInterface {
  /**
   *
   * @summary Change person folder with save history
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {PersonFolderChangeRequest} personFolderChangeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  changePersonFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderChangeRequest: PersonFolderChangeRequest,
    options?: any
  ): AxiosPromise<PersonFolderChangeRequest>;

  /**
   *
   * @summary Create person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {PersonFolderCreateRequest} personFolderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  createPersonFolders(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderCreateRequest: PersonFolderCreateRequest,
    options?: any
  ): AxiosPromise<PersonFolderUpdateRequest>;

  /**
   *
   * @summary Delete person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {number} personFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  deletePersonFolders(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Get all person folders of the specified person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  getAllPersonFolders(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonWithFoldersView>;

  /**
   *
   * @summary Get person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} personFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  getPersonFolderById(
    businessUnitId: number,
    sectionId: number,
    personFolderId: number,
    options?: any
  ): AxiosPromise<PersonFolderView>;

  /**
   *
   * @summary Get person folder for edit
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {number} personFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  getPersonFolderByIdForEdit(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderId: number,
    options?: any
  ): AxiosPromise<PersonFolderUpdateRequest>;

  /**
   *
   * @summary Multi update (create, update, delete) person folders
   * @param {number} businessUnitId
   * @param {PersonFolderMultiUpdateRequest} personFolderMultiUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  multiUpdatePersonFolders(
    businessUnitId: number,
    personFolderMultiUpdateRequest: PersonFolderMultiUpdateRequest,
    options?: any
  ): AxiosPromise<PersonFolderMultiUpdateRequest>;

  /**
   *
   * @summary Update person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {PersonFolderUpdateRequest} personFolderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  updatePersonFolders(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderUpdateRequest: PersonFolderUpdateRequest,
    options?: any
  ): AxiosPromise<PersonFolderUpdateRequest>;

  /**
   *
   * @summary Update person folders
   * @param {number} businessUnitId
   * @param {ProcessPersonFoldersRequest} processPersonFoldersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApiInterface
   */
  updatePersonFolders1(
    businessUnitId: number,
    processPersonFoldersRequest: ProcessPersonFoldersRequest,
    options?: any
  ): AxiosPromise<PersonWithFoldersView>;
}

/**
 * PersonFolderControllerApi - object-oriented interface
 * @export
 * @class PersonFolderControllerApi
 * @extends {BaseAPI}
 */
export class PersonFolderControllerApi extends BaseAPI implements PersonFolderControllerApiInterface {
  /**
   *
   * @summary Change person folder with save history
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {PersonFolderChangeRequest} personFolderChangeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public changePersonFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderChangeRequest: PersonFolderChangeRequest,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .changePersonFolder(businessUnitId, sectionId, groupFolderId, personFolderChangeRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {PersonFolderCreateRequest} personFolderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public createPersonFolders(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderCreateRequest: PersonFolderCreateRequest,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .createPersonFolders(businessUnitId, sectionId, groupFolderId, personFolderCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {number} personFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public deletePersonFolders(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderId: number,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .deletePersonFolders(businessUnitId, sectionId, groupFolderId, personFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all person folders of the specified person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public getAllPersonFolders(businessUnitId: number, personId: number, options?: any) {
    return PersonFolderControllerApiFp(this.configuration)
      .getAllPersonFolders(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} personFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public getPersonFolderById(businessUnitId: number, sectionId: number, personFolderId: number, options?: any) {
    return PersonFolderControllerApiFp(this.configuration)
      .getPersonFolderById(businessUnitId, sectionId, personFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person folder for edit
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {number} personFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public getPersonFolderByIdForEdit(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderId: number,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .getPersonFolderByIdForEdit(businessUnitId, sectionId, groupFolderId, personFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Multi update (create, update, delete) person folders
   * @param {number} businessUnitId
   * @param {PersonFolderMultiUpdateRequest} personFolderMultiUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public multiUpdatePersonFolders(
    businessUnitId: number,
    personFolderMultiUpdateRequest: PersonFolderMultiUpdateRequest,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .multiUpdatePersonFolders(businessUnitId, personFolderMultiUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update person folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {PersonFolderUpdateRequest} personFolderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public updatePersonFolders(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    personFolderUpdateRequest: PersonFolderUpdateRequest,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .updatePersonFolders(businessUnitId, sectionId, groupFolderId, personFolderUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update person folders
   * @param {number} businessUnitId
   * @param {ProcessPersonFoldersRequest} processPersonFoldersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonFolderControllerApi
   */
  public updatePersonFolders1(
    businessUnitId: number,
    processPersonFoldersRequest: ProcessPersonFoldersRequest,
    options?: any
  ) {
    return PersonFolderControllerApiFp(this.configuration)
      .updatePersonFolders1(businessUnitId, processPersonFoldersRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
