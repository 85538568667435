import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PinAbsentIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
      <path
        d="M1.17087 12.0371H6.81553C7.60194 12.0371 7.98641 11.6373 7.98641 10.7529V6.21581C7.98641 5.44044 7.6835 5.03459 7.07184 4.94978V3.33847C7.07184 1.92706 7.93398 1.1517 8.9767 1.1517C10.0136 1.1517 10.8757 1.92706 10.8757 3.33847V4.61661C10.8757 5.0467 11.1204 5.27083 11.4408 5.27083C11.7553 5.27083 12 5.05882 12 4.61661V3.44751C12 1.14564 10.5204 0.0371094 8.9767 0.0371094C7.42718 0.0371094 5.94757 1.14564 5.94757 3.44751V4.93161L1.26408 4.94372C0.483495 4.94372 0 5.34352 0 6.21581V10.7529C0 11.6373 0.384466 12.0371 1.17087 12.0371Z"
        fill="white"
      />
    </SvgIcon>
  );
}
