import React from 'react';

import { Form } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  AccountViewAllowableRolesEnum,
  AccountWithUserCreateRequest,
  AccountWithUserUpdateRequest,
} from 'api/generated';
import { BusinessUnitUpdateAccessFormRequest } from 'api/BusinessUnitProviders/BusinessUnitUpdateAccessProvider';
import { ProjectsListProvider } from 'api/ProjectsProviders';
import { CompaniesListProvider } from 'api/CompaniesProviders';

import { PersonalInfo } from './PersonalInfo';
import { CheckRoleCondition, Roles } from './Roles';
import { ReaderFields } from './ReaderFields';
import { ResponsibleFields } from './ResponsibleFields';
import { Secretaries } from './Secretaries';

export const ACCESS_FORM_VALUES = [
  'allowableRoles',
  'allProjectsForReader',
  'allCompaniesForReader',
  'allowByDefaultCompanies',
  'allowByDefaultProject',
  'readerCompaniesIds',
  'readerProjectsIds',
  'responsibleCompaniesIds',
  'responsibleProjectIds',
];

export type AccountInitialFormValuesType = AccountWithUserUpdateRequest &
  Omit<BusinessUnitUpdateAccessFormRequest, 'accountId'>;
export type AccountCreateFormValuesType = AccountWithUserCreateRequest &
  Omit<BusinessUnitUpdateAccessFormRequest, 'accountId'>;

interface AccountFormProps {
  initialValues?: AccountInitialFormValuesType;
  onSubmit: any;
  onCancel: any;
  submitLabel: string;
  cancelLabel: string;
  readOnly?: boolean;
}

export function AccountForm({
  initialValues,
  onSubmit,
  onCancel,
  submitLabel,
  cancelLabel,
  readOnly = false,
}: AccountFormProps) {
  const emptyInitialValues = {
    allowableRoles: [],
    allCompaniesForReader: true,
    allProjectsForReader: true,
    allowByDefaultCompanies: true,
    allowByDefaultProjects: true,
    readerCompaniesIds: [],
    readerProjectIds: [],
    responsibleCompaniesIds: [],
    responsibleProjectIds: [],
  };

  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues || emptyInitialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box px={5} py={2.5} height="100%">
              <Box my={2.5}>
                <PersonalInfo readOnly={readOnly} />
              </Box>
              <Box my={2.5}>
                <Roles />
              </Box>
              <ProjectsListProvider>
                <CompaniesListProvider>
                  <CheckRoleCondition when="allowableRoles" is={AccountViewAllowableRolesEnum.READER}>
                    <Box mt={6}>
                      <ReaderFields />
                    </Box>
                  </CheckRoleCondition>
                  <CheckRoleCondition when="allowableRoles" is={AccountViewAllowableRolesEnum.RESPONSIBLE}>
                    <Box mt={6}>
                      <ResponsibleFields />
                    </Box>
                  </CheckRoleCondition>
                </CompaniesListProvider>
              </ProjectsListProvider>
              {initialValues?.accountId && (
                <Box my={2.5}>
                  <Secretaries readOnly={readOnly} />
                </Box>
              )}
            </Box>
            {!readOnly && (
              <Box mt="auto">
                <Toolbar>
                  <Box display="flex" my={3} mx={2}>
                    <Box>
                      <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                        {submitLabel}
                      </Button>
                    </Box>
                    <Box ml={2}>
                      <Button variant="contained" color="secondary" onClick={onCancel}>
                        {cancelLabel}
                      </Button>
                    </Box>
                  </Box>
                </Toolbar>
              </Box>
            )}
          </Box>
        </form>
      )}
    />
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
  },
}));
