import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MuiLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ContentItemViewStatusEnum, DocumentView } from 'api/generated';

import {
  DocumentDeleteProvider,
  useDocumentArchiveProvider,
  useDocumentDetailsProvider,
} from 'api/DocumentProviders/Documents';
import { ContentItemView, UserSessionStateViewRoleEnum } from 'api/generated';
import { getFileOfDocumentURL } from 'api/http';
import { useAuthProvider } from 'api/AuthProviders';

import { DeleteDocumentConfirmDialog } from 'pages/All/DocumentsDialogs';
import { DocumentRequiredRouterParams } from 'pages/All/DocumentsDialogs/interfaces';

interface DocumentsActionsButtonProps {
  document: ContentItemView;
}

export function DocumentsActionsButton({ document }: DocumentsActionsButtonProps) {
  const match = useRouteMatch<DocumentRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);

  const [isMenuOpened, setOpen] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const { data: fullDocument, isFetching } = useDocumentDetailsProvider();
  const {
    controllers: {
      archive: { mutateAsync: archive },
      unarchive: { mutateAsync: unarchive },
    },
  } = useDocumentArchiveProvider();

  const onArchive = useCallback(() => {
    if (document && document.dataVersion) {
      if (document.status === ContentItemViewStatusEnum.ARCHIVED) {
        unarchive(document.dataVersion).then(() => setOpen(false));
      } else {
        archive(document.dataVersion).then(() => setOpen(false));
      }
    }
  }, [document, unarchive, archive]);

  const handleToggleActionMenu = useCallback(
    event => {
      event.preventDefault();
      if (!isFetching) {
        setOpen(prevOpen => !prevOpen);
      }
    },
    [isFetching]
  );

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleCloseActionMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const showConfirmDialog = useCallback(event => {
    setOpen(false);
    setOpenConfirm(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const classes = useStyles();

  return (
    <Box>
      <IconButton ref={anchorButtonRef} onClick={handleToggleActionMenu} className={classes.actionsButton}>
        <MoreVertIcon style={{ color: '#C2C6D2' }} />
      </IconButton>
      <Popper
        open={isMenuOpened}
        anchorEl={anchorButtonRef.current}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            offset: '0, +8px',
          },
        }}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <DocumentActionsMenu
                open={isMenuOpened}
                onClose={handleCloseActionMenu}
                onDelete={showConfirmDialog}
                onKeyDown={handleListKeyDown}
                // when document doesn't have files for current locale
                document={fullDocument ?? document}
                documentId={document.id}
                onArchive={onArchive}
                status={document.status}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
      <DocumentDeleteProvider businessUnitId={businessUnitId} sectionId={sectionId} documentId={document.id}>
        <DeleteDocumentConfirmDialog open={openConfirm} onDelete={closeConfirmDialog} onClose={closeConfirmDialog} />
      </DocumentDeleteProvider>
    </Box>
  );
}

interface DocumentActionsMenuProps {
  open: boolean;
  onClose: (event: React.MouseEvent<EventTarget>) => void;
  onArchive: (event: React.MouseEvent<EventTarget>) => void;
  onDelete: (event: React.MouseEvent<EventTarget>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLUListElement> | undefined;
  documentId: string | number;
  document: ContentItemView | DocumentView | undefined;
  status: string;
}
function DocumentActionsMenu({
  open,
  onClose,
  onArchive,
  onDelete,
  onKeyDown,
  document,
  documentId,
  status,
}: DocumentActionsMenuProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const match = useRouteMatch<DocumentRequiredRouterParams>();
  const { role } = useAuthProvider();
  const classes = useStyles();

  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);

  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;
  const isResponsible = role === UserSessionStateViewRoleEnum.RESPONSIBLE;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MenuList autoFocusItem={open} onKeyDown={onKeyDown} classes={{ root: classes.list }}>
        {(isAdmin || isResponsible) && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={`${location.pathname}/document/${documentId}/edit`}
            onClick={onClose}
            data-test="EditDocumentButton"
          >
            {t('documents:actionsMenu.edit')}
          </MenuItem>
        )}
        <Divider />
        {document && 'originalFileMeta' in document && document.originalFileMeta && (
          <MenuItem
            className={classes.menuItem}
            component={MuiLink}
            href={getFileOfDocumentURL(businessUnitId, sectionId, document.id, document.originalFileMeta.id)}
            download={document.title}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}
            data-test="DownloadOriginalDocumentButton"
          >
            {t('documents:actionsMenu.downloadOriginal')}
          </MenuItem>
        )}
        {document && 'originalFileMeta' in document && document.originalFileMeta && <Divider />}
        {document && 'pdfFileMeta' in document && document.pdfFileMeta && (
          <MenuItem
            className={classes.menuItem}
            component={MuiLink}
            href={getFileOfDocumentURL(businessUnitId, sectionId, document.id, document.pdfFileMeta.id)}
            download={document.title}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}
            data-test="DownloadPdfDocumentButton"
          >
            {t('documents:actionsMenu.downloadPdf')}
          </MenuItem>
        )}
        {(isAdmin || isResponsible) && (
          <MenuItem
            className={classes.menuItem}
            component={MuiLink}
            download={document?.title}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onArchive}
          >
            {status === ContentItemViewStatusEnum.ARCHIVED
              ? t('documents:actionsMenu.unArchive')
              : t('documents:actionsMenu.addToArchive')}
          </MenuItem>
        )}
        <Divider />
        {(isAdmin || isResponsible) && (
          <MenuItem
            className={classNames(classes.menuItem, classes.menuItemWarn)}
            onClick={onDelete}
            data-test="DeleteDocumentButton"
          >
            {t('documents:actionsMenu.remove')}
          </MenuItem>
        )}
      </MenuList>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles(theme => ({
  actionsButton: {
    marginRight: theme.spacing(-2),
  },
  list: {
    padding: 0,
    borderRadius: 8,
  },
  menuItem: {
    padding: theme.spacing(1.5, 2),
    minWidth: 230,
  },
  menuItemWarn: {
    color: theme.palette.error.main,
  },
}));
