import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  BusinessUnitCreateRequest,
  BusinessUnitControllerApiFactory,
  FileControllerApiFactory,
  BusinessUnitUpdateRequest,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

const ProjectApi = BusinessUnitControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

type ProjectCreateRequest = {
  logo?: File;
} & BusinessUnitCreateRequest;

type ProjectCreateProviderType = UseMutationResult<
  BusinessUnitUpdateRequest,
  AxiosError<unknown>,
  ProjectCreateRequest,
  unknown
>;

const ProjectCreateContext = createContext<ProjectCreateProviderType | null>(null);
ProjectCreateContext.displayName = 'ProjectCreate';

export function useProjectCreateProvider(): ProjectCreateProviderType {
  const contextState = useContext(ProjectCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useProjectCreateProvider.name} must be used within a ${ProjectCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface ProjectCreateProviderProps {
  queryOptions?: UseMutationOptions<BusinessUnitUpdateRequest, AxiosError<unknown>, BusinessUnitCreateRequest, unknown>;
}

export function ProjectCreateProvider(props: React.PropsWithChildren<ProjectCreateProviderProps>) {
  const queryClient = useQueryClient();

  const create = useMutation<BusinessUnitUpdateRequest, AxiosError<unknown>, BusinessUnitCreateRequest>(
    async (data: ProjectCreateRequest) => {
      if (data.logo) {
        const logo = data.logo;
        const file = await FileApi.upload(logo, logo.name).then(resp => resp.data);
        data.iconId = file.id;
      }
      const params = omit(data, ['logo']);
      return ProjectApi.createBusinessUnit('PROJECT', params).then(async resp => {
        await queryClient.invalidateQueries(RESOURCE_NAME);
        return resp.data;
      });
    },
    {
      ...(props.queryOptions || {}),
    }
  );
  return <ProjectCreateContext.Provider value={create}>{props.children}</ProjectCreateContext.Provider>;
}
