import { Route } from 'react-router-dom';

import { AddDocumentDialog, EditDocumentDialog } from 'pages/All/DocumentsDialogs';

import { AddSectionDialog, SectionPreviewDialog } from '../All/SectionsList';
import { AddFolderDialog, EditFolderDialog } from '../All/FoldersDialogs';
import { CreatePersonDialog } from '../All/PersonsAndGroups/PersonsDialogs/CreatePersonDialog';
import { PersonDetailsDialog } from '../All/PersonsAndGroups/PersonsDialogs/PersonDetailsDialog';
import { UpdatePersonDialog } from '../All/PersonsAndGroups/PersonsDialogs/UpdatePersonDialog';
import { ResponsibleListDialog } from '../All/PersonsAndGroups/PersonsDialogs/ResponsibleListDialog';
import { CreateGroupDialog } from '../All/PersonsAndGroups/GroupsDialogs/CreateGroupDialog';
import { GroupsChangelogDialog } from '../All/PersonsAndGroups/GroupsDialogs/GroupsChangelogDialog';
import { UpdateGroupDialog } from '../All/PersonsAndGroups/GroupsDialogs/UpdateGroupDialog';
import { CommonPersonsListDialog } from '../All/PersonsAndGroups/PersonsDialogs/CommonPersonsListDialog';
import { MembersByDateDialog } from '../All/PersonsAndGroups/PersonsDialogs/MembersByDateDialog';
import { AddMemberPositionDialog } from '../All/PersonsAndGroups/PersonsDialogs/MemberPositionDialogs/AddMemberPositionDialog';
import { EditMemberPositionDialog } from '../All/PersonsAndGroups/PersonsDialogs/MemberPositionDialogs/EditMemberPositionDialog';
import { DocumentCardDialog } from '../All/DocumentsDialogs/DocumentCardDialog';
import { ResponsibleDetailsDialog } from 'pages/All/PersonsAndGroups/PersonsDialogs/ResponsibleDetailsDialog';
import { BusinessUnitType } from 'api/BusinessUnitProviders/constants';

export function BusinessUnitsCommonRoutes({ path, structType }: { path: string; structType: BusinessUnitType }) {
  return (
    <>
      <Route
        path={`${path}/:businessUnitId/add_section`}
        render={routeProps => <AddSectionDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section_preview`,
          `${path}/:businessUnitId/section/:sectionId/section_preview`,
          `${path}/:businessUnitId/section/:sectionId/parentFolderId/:parentFolderId/section_preview`,
        ]}
        render={routeProps => <SectionPreviewDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/add_document`,
          `${path}/:businessUnitId/section/:sectionId/parentFolderId/:parentFolderId/add_document`,
        ]}
        render={routeProps => <AddDocumentDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/document/:documentId/edit`,
          `${path}/:businessUnitId/section/:sectionId/parentFolderId/:parentFolderId/document/:documentId/edit`,
        ]}
        render={routeProps => <EditDocumentDialog routeProps={routeProps} />}
      />
      <Route
        exact
        path={[
          `${path}/:businessUnitId/section/:sectionId/document/:documentId/view`,
          `${path}/:businessUnitId/section/:sectionId/parentFolderId/:parentFolderId/document/:documentId/view`,
        ]}
        render={routeProps => <DocumentCardDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/add_folder`,
          `${path}/:businessUnitId/section/:sectionId/parentFolderId/:parentFolderId/add_folder`,
        ]}
        render={routeProps => <AddFolderDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/folder/:folderId/edit_folder`,
          `${path}/:businessUnitId/section/:sectionId/parentFolderId/:parentFolderId/folder/:folderId/edit_folder`,
        ]}
        render={routeProps => <EditFolderDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/members/add_person`,
          `${path}/:businessUnitId/section/:sectionId/add_person`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/add_person`,
        ]}
        render={routeProps => <CreatePersonDialog routeProps={routeProps} structType={structType} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/members/person/:personId/show`,
          `${path}/:businessUnitId/section/:sectionId/person/:personId/show`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/person/:personId/show`,
        ]}
        render={routeProps => <PersonDetailsDialog routeProps={routeProps} />}
      />
      <Route
        path={`${path}/:businessUnitId/responsible`}
        render={routeProps => <ResponsibleListDialog routeProps={routeProps} />}
      />
      <Route
        path={`${path}/:businessUnitId/responsible/account/:accountId/show`}
        render={routeProps => <ResponsibleDetailsDialog routeProps={routeProps} />}
      />
      <Route
        path={`${path}/:businessUnitId/members`}
        render={routeProps => <CommonPersonsListDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/members/person/:personId/edit`,
          `${path}/:businessUnitId/section/:sectionId/person/:personId/edit`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/person/:personId/edit`,
        ]}
        render={routeProps => <UpdatePersonDialog routeProps={routeProps} />}
      />
      <Route
        path={`${path}/:businessUnitId/section/:sectionId/add_group`}
        render={routeProps => <CreateGroupDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/edit_group`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/edit`,
        ]}
        render={routeProps => <UpdateGroupDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/changelog`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/changelog`,
        ]}
        render={routeProps => <GroupsChangelogDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/membersByDate`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/membersByDate`,
        ]}
        render={routeProps => <MembersByDateDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/add_member_position`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/add_member_position`,
        ]}
        render={routeProps => <AddMemberPositionDialog routeProps={routeProps} />}
      />
      <Route
        path={[
          `${path}/:businessUnitId/section/:sectionId/edit_member_position`,
          `${path}/:businessUnitId/section/:sectionId/group/:groupFolderId/edit_member_position`,
        ]}
        render={routeProps => <EditMemberPositionDialog routeProps={routeProps} />}
      />
    </>
  );
}
