import React from 'react';

import { Form, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { PersonFolderRequest, PersonUpdateRequest } from 'api/generated';
import { CompaniesListProvider } from 'api/CompaniesProviders';
import { StructType } from 'api/PersonsProvider';

import { Company } from './Company';
import { ContactFields } from '../../../../../components/fields/ContactFields';
import { PersonalInfo } from './PersonalInfo';
import { Groups } from './Groups';

import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

interface UIPersonFolderOptions {
  hideDelete?: boolean;
  disableGroupSelect?: boolean;
}

export type PersonFormValuesType = PersonUpdateRequest & { groups: PersonFolderFormValuesType[] };
export type PersonFolderFormValuesType = PersonFolderRequest & {
  uiOptions?: UIPersonFolderOptions;
  positionStartDate?: PersonFolderRequest['positionStartDate'] | Date;
  positionEndDate?: PersonFolderRequest['positionEndDate'] | Date;
};

interface PersonFormProps {
  businessUnitId: number;
  structType: StructType;
  initialValues?: PersonUpdateRequest;
  foldersInitialValues?: PersonFolderFormValuesType[];
  onSubmit: any;
  onCancel: any;
  onRemove?: any;
  submitLabel: string;
  cancelLabel: string;
  removeLabel?: string;
  disablePersonalInfo?: boolean;
  disableCompany?: boolean;
  disableContacts?: boolean;
}

export function PersonForm(props: PersonFormProps) {
  const {
    businessUnitId,
    structType,
    initialValues,
    foldersInitialValues,
    onSubmit,
    onCancel,
    onRemove,
    submitLabel,
    cancelLabel,
    removeLabel,
  } = props;
  const emptyInitialValues = {
    ...initialValues,
    groups: isNil(foldersInitialValues) ? [] : foldersInitialValues,
  };

  if (structType === 'COMPANY') {
    emptyInitialValues.companyId = businessUnitId;
  }

  const canRemove = isFunction(onRemove) && !isEmpty(removeLabel);

  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={emptyInitialValues}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, submitting, form: { mutators } }: FormRenderProps<PersonFormValuesType>) => {
        return (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <Box display="flex" flexDirection="column">
              <Box>
                <PersonalInfo
                  disabled={props.disablePersonalInfo}
                  businessUnitId={businessUnitId}
                  structType={structType}
                />
              </Box>
              <CompaniesListProvider>
                <Box my={2.5}>
                  <Company disabled={structType === 'COMPANY' || props.disableCompany} />
                </Box>
              </CompaniesListProvider>
              <Box my={2.5}>
                <ContactFields disabled={props.disableContacts} i18nNamespace="persons" />
              </Box>
              <Box my={2.5}>
                <Groups mutators={mutators} />
              </Box>
              <Toolbar disableGutters>
                <Box display="flex" my={3} width="100%">
                  <Box>
                    <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                      {submitLabel}
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                      {cancelLabel}
                    </Button>
                  </Box>
                  {canRemove && (
                    <Box ml="auto">
                      <Button variant="text" color="secondary" onClick={onRemove}>
                        {removeLabel}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Toolbar>
            </Box>
          </form>
        );
      }}
    />
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
  },
}));
