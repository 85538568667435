import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { SecretaryUpdateRequest, UserControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const UserApi = UserControllerApiFactory(ApiConfiguration);

export interface SecretaryLockRequest {
  type: 'lock' | 'unlock';
}

type SecretaryLockProviderType = UseMutationResult<
  SecretaryUpdateRequest,
  AxiosError<unknown>,
  SecretaryLockRequest,
  unknown
>;

const SecretaryLockContext = createContext<SecretaryLockProviderType | null>(null);
SecretaryLockContext.displayName = `${upperFirst(RESOURCE_NAME)}Lock`;

export function useSecretaryLockProvider(): SecretaryLockProviderType {
  const contextState = useContext(SecretaryLockContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSecretaryLockProvider.name} must be used within a ${SecretaryLockContext.displayName} context`
    );
  }
  return contextState;
}

interface SecretaryLockProviderProps {
  secretaryId: number;
  queryOptions?: UseMutationOptions<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryLockRequest, unknown>;
}

export function SecretaryLockProvider(props: React.PropsWithChildren<SecretaryLockProviderProps>) {
  const queryClient = useQueryClient();
  const defaultOptions: SecretaryLockProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([RESOURCE_NAME, { id: props.secretaryId }]);
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const value = useMutation<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryLockRequest>(
    async ({ type }: SecretaryLockRequest) => {
      if (type === 'lock') {
        return UserApi.lockUser(props.secretaryId).then(resp => resp.data);
      } else {
        return UserApi.unlockUser(props.secretaryId).then(resp => resp.data);
      }
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <SecretaryLockContext.Provider value={value}>{props.children}</SecretaryLockContext.Provider>;
}
