import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  AccessControllerApiFactory,
  AccountWithUserUpdateRequest,
  AccountWithUserUpdateRequestAllowableRolesEnum,
  BusinessUnitAccessUpdateRequest,
  BusinessUnitReaderAccessRequest,
  BusinessUnitResponsibleAccessRequest,
  BusinessUnitShortView,
} from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

export interface BusinessUnitUpdateAccessFormRequest {
  accountId: AccountWithUserUpdateRequest['accountId'];
  allProjectsForReader: boolean;
  allCompaniesForReader: boolean;
  allowByDefaultCompanies: boolean;
  allowByDefaultProjects: boolean;
  readerCompaniesIds?: BusinessUnitShortView['id'][];
  readerProjectsIds?: BusinessUnitShortView['id'][];
  responsibleCompaniesIds?: BusinessUnitShortView['id'][];
  responsibleProjectIds?: BusinessUnitShortView['id'][];
  allowableRoles: AccountWithUserUpdateRequestAllowableRolesEnum[];
}

type BusinessUnitUpdateAccessProviderType = UseMutationResult<
  object,
  AxiosError<unknown>,
  BusinessUnitUpdateAccessFormRequest,
  unknown
>;

const BusinessUnitUpdateAccessContext = createContext<BusinessUnitUpdateAccessProviderType | null>(null);
BusinessUnitUpdateAccessContext.displayName = `${upperFirst(RESOURCE_NAME)}UpdateAccess`;

export function useBusinessUnitUpdateAccessProvider(): BusinessUnitUpdateAccessProviderType {
  const contextState = useContext(BusinessUnitUpdateAccessContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitUpdateAccessProvider.name} must be used within a ${BusinessUnitUpdateAccessContext.displayName} context`
    );
  }
  return contextState;
}

interface BusinessUnitUpdateAccessProviderProps {
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, BusinessUnitUpdateAccessFormRequest, unknown>;
}

export function BusinessUnitUpdateAccessProvider(
  props: React.PropsWithChildren<BusinessUnitUpdateAccessProviderProps>
) {
  const queryClient = useQueryClient();
  const defaultOptions: BusinessUnitUpdateAccessProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const update = useMutation<object, AxiosError<unknown>, BusinessUnitUpdateAccessFormRequest>(
    async (data: BusinessUnitUpdateAccessFormRequest) => {
      const isReader = data.allowableRoles.includes(AccountWithUserUpdateRequestAllowableRolesEnum.READER);
      const isResponsible = data.allowableRoles.includes(AccountWithUserUpdateRequestAllowableRolesEnum.RESPONSIBLE);

      const {
        readerCompaniesIds,
        readerProjectsIds,
        responsibleCompaniesIds,
        responsibleProjectIds,
        allProjectsForReader,
        allCompaniesForReader,
        allowByDefaultCompanies,
        allowByDefaultProjects,
        accountId,
      } = data;

      const updateRequest: BusinessUnitAccessUpdateRequest = {
        accountId,
      };

      if (isReader) {
        const readerAccess: BusinessUnitReaderAccessRequest = {
          companyAccess: {
            allowAll: allCompaniesForReader,
            allowByDefault: allowByDefaultCompanies,
          },
          projectAccess: {
            allowAll: allProjectsForReader,
            allowByDefault: allowByDefaultProjects,
          },
        };
        readerAccess.companyAccess.allowIds = allCompaniesForReader ? undefined : readerCompaniesIds;
        readerAccess.projectAccess.allowIds = allProjectsForReader ? undefined : readerProjectsIds;
        updateRequest.readerAccess = readerAccess;
      }
      if (isResponsible) {
        const responsibleAccess: BusinessUnitResponsibleAccessRequest = {
          companyAllowIds: responsibleCompaniesIds,
          projectAllowIds: responsibleProjectIds,
        };
        updateRequest.responsibleAccess = responsibleAccess;
      }
      return AccessApi.updateBusinessUnitAccess(updateRequest).then(resp => resp.data);
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <BusinessUnitUpdateAccessContext.Provider value={update}>{props.children}</BusinessUnitUpdateAccessContext.Provider>
  );
}
