import React, { useCallback, useState } from 'react';

import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { SectionRemoveProvider } from 'api/SectionsProviders';
import { SectionUpdateRequest } from 'api/SectionsProviders/SectionUpdateProvider';
import { AccountAccessView } from 'api/generated';

import { DropFileField, TextField } from 'components/fields';
import { ModalToolbar, SpinnerButton } from 'components/material';
import { composeValidators, required, maxLength } from 'components/fields/validators';
import { DeleteSectionConfirmationDialog } from './DeleteSectionConfirmationDialog';
import { AccessFieldTypeEnum, AccessFields } from './AccessFields';

import trim from 'utils/trim';

export type AccessInfo = { allowIds: number[]; restrictedAccess: boolean };

type AccessValues = {
  reader: AccessInfo;
  responsible: AccessInfo;
};

export type SectionFormValues = Omit<
  SectionUpdateRequest & AccessValues,
  'sectionRestrictedResponsibleAccess' | 'sectionRestrictedAccess'
>;

type EditFormAdditionalProps = {
  initialValues: SectionUpdateRequest;
  onClose: () => void;
  businessUnitId: number;
  canRemove: boolean;
  canEditTitles: boolean;
  canUpdateAccess: boolean;
  canUpdateResponsibleAccess: boolean;
};

type CreateFormAdditionalProps = {
  canUpdateResponsibleAccess: boolean;
};

interface SectionFormProps {
  type: 'CREATE' | 'EDIT';
  onSubmit: (values: any) => void;
  editProps?: EditFormAdditionalProps;
  createProps?: CreateFormAdditionalProps;
  readerInfo: { restrictedAccess?: boolean; list?: AccountAccessView[] };
  responsibleInfo: { restrictedAccess?: boolean; list?: AccountAccessView[] };
}

export function SectionForm({ type, onSubmit, editProps, createProps, readerInfo, responsibleInfo }: SectionFormProps) {
  const { t } = useTranslation();

  const initialValues: SectionFormValues = Object.assign({}, editProps?.initialValues, {
    reader: {
      allowIds: readerInfo.list?.filter(r => r.access).map(r => r.accountDisplayView.id) || [],
      restrictedAccess: readerInfo?.restrictedAccess ?? false,
    },
    responsible: {
      allowIds: responsibleInfo.list?.filter(r => r.access).map(r => r.accountDisplayView.id) || [],
      restrictedAccess: responsibleInfo?.restrictedAccess ?? true,
    },
  });

  const classes = useStyles();

  const submitLabel = type === 'CREATE' ? t('businessUnit:dialog.create') : t('businessUnit:dialog.save');

  const removeButton = type === 'EDIT' && editProps && editProps.canRemove && <RemoveButton editProps={editProps} />;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box className={classnames(classes.overlay, { [classes.disabled]: submitting })}></Box>
          <Box>
            <Box mb={4}>
              <Box fontSize="body1.fontSize" fontWeight="bold">
                {t('businessUnit:dialog.fields.sectionInRu')}
              </Box>
              <TextField
                label={t('businessUnit:dialog.fields.sectionTitleRu')}
                placeholder={t('businessUnit:dialog.fields.sectionTitleRuPlaceholder')}
                name="sectionTitleRu"
                margin="dense"
                required
                fieldProps={{
                  validate: titleValidator,
                  format: trim,
                  formatOnBlur: true,
                }}
              />
              <DropFileField
                label={t('businessUnit:dialog.fields.sectionPreviewRuFile')}
                placeholder={t('businessUnit:dialog.fields.sectionPreviewRuFilePlaceholder')}
                tooltip={t('businessUnit:dialog.fields.sectionPreviewRuFileTooltip')}
                name="sectionPreviewRuFile"
                margin="dense"
                accept=".pdf"
                fullWidth
              />
            </Box>
            <Box mb={4}>
              <Box fontSize="body1.fontSize" fontWeight="bold">
                {t('businessUnit:dialog.fields.sectionInEn')}
              </Box>
              <TextField
                label={t('businessUnit:dialog.fields.sectionTitleEn')}
                placeholder={t('businessUnit:dialog.fields.sectionTitleEnPlaceholder')}
                name="sectionTitleEn"
                margin="dense"
                required
                fieldProps={{
                  validate: titleValidator,
                  format: trim,
                  formatOnBlur: true,
                }}
              />
              <DropFileField
                label={t('businessUnit:dialog.fields.sectionPreviewEnFile')}
                placeholder={t('businessUnit:dialog.fields.sectionPreviewEnFilePlaceholder')}
                tooltip={t('businessUnit:dialog.fields.sectionPreviewEnFileTooltip')}
                name="sectionPreviewEnFile"
                margin="dense"
                accept=".pdf"
                fullWidth
              />
            </Box>
            {(createProps?.canUpdateResponsibleAccess || editProps?.canUpdateResponsibleAccess) && (
              <AccessFields type={AccessFieldTypeEnum.RESPONSIBLE} personsList={responsibleInfo.list} />
            )}
            {(type === 'CREATE' || editProps?.canUpdateAccess) && (
              <AccessFields type={AccessFieldTypeEnum.READER} personsList={readerInfo.list} />
            )}
          </Box>
          <Box mx={-4}>
            <ModalToolbar>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Button type="submit" variant="contained" color="primary">
                  {submitting ? <SpinnerButton size={16} label={submitLabel} color="inherit" /> : submitLabel}
                </Button>
                {removeButton}
              </Box>
            </ModalToolbar>
          </Box>
        </form>
      )}
    />
  );
}

const titleValidator = composeValidators(required, maxLength(120));

function RemoveButton({ editProps }: { editProps: EditFormAdditionalProps }) {
  const { t } = useTranslation();

  const [openConfirm, setOpenConfirm] = useState(false);

  const showConfirmDialog = useCallback(event => {
    setOpenConfirm(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  return (
    <>
      <Button variant="text" color="secondary" onClick={showConfirmDialog}>
        {t('businessUnit:dialog.delete')}
      </Button>
      <SectionRemoveProvider businessUnitId={editProps.businessUnitId} sectionId={editProps.initialValues?.sectionId}>
        <DeleteSectionConfirmationDialog open={openConfirm} onClose={closeConfirmDialog} onDelete={editProps.onClose} />
      </SectionRemoveProvider>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(0, 4),
    margin: theme.spacing(0, -4),
    '& .MuiFormControl-marginDense': {
      marginBottom: theme.spacing(1),
    },
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  disabled: {
    zIndex: 50000,
    opacity: 0.5,
    background: '#fff',
  },
}));
