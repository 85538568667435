import React from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuthProvider } from 'api/AuthProviders/AuthProvider';
import { UserSessionStateViewRoleEnum } from 'api/generated';

import { AUTH_PAGE_PATH, ROOT_PAGE_PATH } from './Router';

import isEmpty from 'lodash/isEmpty';

type PrivateRouteProps = {
  roles?: UserSessionStateViewRoleEnum[];
} & RouteProps;

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles, ...props }) => {
  const { status, role } = useAuthProvider();

  switch (status) {
    case 'idle':
    case 'loading':
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <CircularProgress />
        </Box>
      );

    case 'loggedIn': {
      const hasRolesRestriction = !isEmpty(roles);
      if (!hasRolesRestriction || (hasRolesRestriction && role && roles?.includes(role))) {
        return <Route {...props} />;
      }
      return <Redirect to={{ pathname: ROOT_PAGE_PATH }} />;
    }

    case 'loggedOut':
    case 'error':
    default:
      return (
        <Redirect
          to={{
            pathname: AUTH_PAGE_PATH,
            state: { from: props.location },
          }}
        />
      );
  }
};
