import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { BusinessUnitControllerApiFactory, FileControllerApiFactory, BusinessUnitUpdateRequest } from 'api/generated';
import { ApiConfiguration, FileApiConfiguration, invalidateFallbackImageCache } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

const ProjectApi = BusinessUnitControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

export type ProjectUpdateRequest = {
  logo?: File;
} & BusinessUnitUpdateRequest;

type ProjectUpdateProviderType = UseMutationResult<
  ProjectUpdateRequest,
  AxiosError<unknown>,
  ProjectUpdateRequest,
  unknown
>;

const ProjectUpdateContext = createContext<ProjectUpdateProviderType | null>(null);
ProjectUpdateContext.displayName = 'ProjectUpdate';

export function useProjectUpdateProvider(): ProjectUpdateProviderType {
  const contextState = useContext(ProjectUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useProjectUpdateProvider.name} must be used within a ${ProjectUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface ProjectUpdateProviderProps {
  businessUnitId: number;
  queryOptions?: UseMutationOptions<BusinessUnitUpdateRequest, AxiosError<unknown>, ProjectUpdateRequest, unknown>;
}

export function ProjectUpdateProvider(props: React.PropsWithChildren<ProjectUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: ProjectUpdateProviderProps['queryOptions'] = {
    onSuccess: (project, variables) => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries(['businessUnit', { businessUnitId: props.businessUnitId }]);
      if (variables.logo) {
        invalidateFallbackImageCache();
      }
    },
  };
  const create = useMutation<BusinessUnitUpdateRequest, AxiosError<unknown>, ProjectUpdateRequest>(
    async data => {
      if (data.logo) {
        const logo = data.logo;
        const file = await FileApi.upload(logo, logo.name).then(resp => resp.data);
        data.iconId = file.id;
      }
      const params = omit(data, ['logo']);
      return ProjectApi.updateBusinessUnit('PROJECT', params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <ProjectUpdateContext.Provider value={create}>{props.children}</ProjectUpdateContext.Provider>;
}
