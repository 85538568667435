import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useMobileAppsDownloadProvider } from 'api/MobileAppsProviders';
import { isVendorErrorResponse } from 'api/AuthProviders';
import { useErrorCatcher } from 'api/notifications';

import { EnterPhoneForm } from './EnterPhoneForm';
import { EnterSMSCodeForm } from './EnterSMSCodeForm';

import map from 'lodash/map';

type AuthSteps = 'ENTER_PHONE' | 'ENTER_SMS_CODE';
const SMS_CODE_LENGTH = 4;

export function MobileAppsDownloadForm() {
  const history = useHistory();
  const catchError = useErrorCatcher();

  const [phone, setPhone] = useState<string>('');
  const [smsCodeLength, setSMSCodeLength] = useState<number>(SMS_CODE_LENGTH);
  const [nonce, setNonce] = useState<string>('');
  const [step, setStep] = useState<AuthSteps>('ENTER_PHONE');
  const [loading, setLoading] = useState<boolean>(false);

  const {
    controllers: {
      loginPhone: { mutateAsync: sendPhone },
      downloadLink: { mutateAsync: getDownloadLink },
    },
  } = useMobileAppsDownloadProvider();

  const submitPhone = useCallback(
    async formValues => {
      setPhone(formValues.phone || '');
      setLoading(true);
      await sendPhone({ phone: formValues.phone })
        .then(resp => {
          if (isVendorErrorResponse(resp)) {
            catchError(new Error(map(resp.errors, 'message').join('\n')));
          } else {
            setSMSCodeLength(resp.codeSize);
            setNonce(resp.nonce);
            setStep('ENTER_SMS_CODE');
          }
          setLoading(false);
        })
        .catch(e => {
          catchError(e);
          setLoading(false);
        });
    },
    [catchError, sendPhone]
  );

  const submitSMSCode = useCallback(
    async formValues => {
      setLoading(true);
      const otp = formValues.otp || '';
      await getDownloadLink({ phone: phone, otp, nonce })
        .then(resp => {
          if (isVendorErrorResponse(resp)) {
            catchError(new Error(map(resp.errors, 'message').join('\n')));
          } else {
            const url = new URL(resp.url);
            history.push(url.pathname + url.search);
          }
          setLoading(false);
        })
        .catch(e => {
          catchError(e);
          setLoading(false);
        });
    },
    [getDownloadLink, phone, nonce, history, catchError]
  );

  switch (step) {
    case 'ENTER_PHONE':
      return <EnterPhoneForm onSubmit={submitPhone} loading={loading} />;
    case 'ENTER_SMS_CODE':
      return (
        <EnterSMSCodeForm phone={phone} smsCodeLength={smsCodeLength} onSubmit={submitSMSCode} loading={loading} />
      );
    default:
      return null;
  }
}
