/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContentItemView } from '../views';
// @ts-ignore
import { GroupFolderContentChangeLogView } from '../views';
// @ts-ignore
import { GroupFolderCreateRequest } from '../views';
// @ts-ignore
import { GroupFolderShortView } from '../views';
// @ts-ignore
import { GroupFolderUpdateRequest } from '../views';
// @ts-ignore
import { GroupFolderView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * GroupFolderControllerApi - axios parameter creator
 * @export
 */
export const GroupFolderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create group folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {GroupFolderCreateRequest} groupFolderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGroupFolder: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderCreateRequest: GroupFolderCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createGroupFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createGroupFolder', 'sectionId', sectionId);
      // verify required parameter 'groupFolderCreateRequest' is not null or undefined
      assertParamExists('createGroupFolder', 'groupFolderCreateRequest', groupFolderCreateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        groupFolderCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete group folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupFolder: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteGroupFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteGroupFolder', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('deleteGroupFolder', 'groupFolderId', groupFolderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all group created for specified business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGroups: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getAllGroups', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/groupFolders`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get group folder content for specified date
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentForDate: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      date: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getContentForDate', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getContentForDate', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('getContentForDate', 'groupFolderId', groupFolderId);
      // verify required parameter 'date' is not null or undefined
      assertParamExists('getContentForDate', 'date', date);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/content`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (date !== undefined) {
        localVarQueryParameter['date'] =
          (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get group folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupFolderById: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getGroupFolderById', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getGroupFolderById', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('getGroupFolderById', 'groupFolderId', groupFolderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the log of changes in the group folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupFolderContentChangeLog: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getGroupFolderContentChangeLog', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getGroupFolderContentChangeLog', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('getGroupFolderContentChangeLog', 'groupFolderId', groupFolderId);
      const localVarPath =
        `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/content/log`
          .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
          .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
          .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get group folder by folderId for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupFolderForEdit: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getGroupFolderForEdit', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getGroupFolderForEdit', 'sectionId', sectionId);
      // verify required parameter 'groupFolderId' is not null or undefined
      assertParamExists('getGroupFolderForEdit', 'groupFolderId', groupFolderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder/{groupFolderId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'groupFolderId'}}`, encodeURIComponent(String(groupFolderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update group folder for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {GroupFolderUpdateRequest} groupFolderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGroupFolder: async (
      businessUnitId: number,
      sectionId: number,
      groupFolderUpdateRequest: GroupFolderUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateGroupFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateGroupFolder', 'sectionId', sectionId);
      // verify required parameter 'groupFolderUpdateRequest' is not null or undefined
      assertParamExists('updateGroupFolder', 'groupFolderUpdateRequest', groupFolderUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/PERSON/{sectionId}/groupFolder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        groupFolderUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GroupFolderControllerApi - functional programming interface
 * @export
 */
export const GroupFolderControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GroupFolderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create group folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {GroupFolderCreateRequest} groupFolderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGroupFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderCreateRequest: GroupFolderCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupFolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupFolder(
        businessUnitId,
        sectionId,
        groupFolderCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete group folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGroupFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupFolder(
        businessUnitId,
        sectionId,
        groupFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get all group created for specified business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllGroups(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupFolderShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGroups(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get group folder content for specified date
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContentForDate(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      date: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentItemView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContentForDate(
        businessUnitId,
        sectionId,
        groupFolderId,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get group folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupFolderById(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupFolderView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupFolderById(
        businessUnitId,
        sectionId,
        groupFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the log of changes in the group folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupFolderContentChangeLog(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupFolderContentChangeLogView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupFolderContentChangeLog(
        businessUnitId,
        sectionId,
        groupFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get group folder by folderId for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupFolderForEdit(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupFolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupFolderForEdit(
        businessUnitId,
        sectionId,
        groupFolderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update group folder for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {GroupFolderUpdateRequest} groupFolderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGroupFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderUpdateRequest: GroupFolderUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupFolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupFolder(
        businessUnitId,
        sectionId,
        groupFolderUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GroupFolderControllerApi - factory interface
 * @export
 */
export const GroupFolderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GroupFolderControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Create group folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {GroupFolderCreateRequest} groupFolderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGroupFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderCreateRequest: GroupFolderCreateRequest,
      options?: any
    ): AxiosPromise<GroupFolderUpdateRequest> {
      return localVarFp
        .createGroupFolder(businessUnitId, sectionId, groupFolderCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete group folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .deleteGroupFolder(businessUnitId, sectionId, groupFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all group created for specified business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGroups(businessUnitId: number, options?: any): AxiosPromise<Array<GroupFolderShortView>> {
      return localVarFp.getAllGroups(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get group folder content for specified date
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentForDate(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      date: string,
      options?: any
    ): AxiosPromise<Array<ContentItemView>> {
      return localVarFp
        .getContentForDate(businessUnitId, sectionId, groupFolderId, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get group folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupFolderById(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): AxiosPromise<GroupFolderView> {
      return localVarFp
        .getGroupFolderById(businessUnitId, sectionId, groupFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the log of changes in the group folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupFolderContentChangeLog(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): AxiosPromise<Array<GroupFolderContentChangeLogView>> {
      return localVarFp
        .getGroupFolderContentChangeLog(businessUnitId, sectionId, groupFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get group folder by folderId for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} groupFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupFolderForEdit(
      businessUnitId: number,
      sectionId: number,
      groupFolderId: number,
      options?: any
    ): AxiosPromise<GroupFolderUpdateRequest> {
      return localVarFp
        .getGroupFolderForEdit(businessUnitId, sectionId, groupFolderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update group folder for edit
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {GroupFolderUpdateRequest} groupFolderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGroupFolder(
      businessUnitId: number,
      sectionId: number,
      groupFolderUpdateRequest: GroupFolderUpdateRequest,
      options?: any
    ): AxiosPromise<GroupFolderUpdateRequest> {
      return localVarFp
        .updateGroupFolder(businessUnitId, sectionId, groupFolderUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * GroupFolderControllerApi - interface
 * @export
 * @interface GroupFolderControllerApi
 */
export interface GroupFolderControllerApiInterface {
  /**
   *
   * @summary Create group folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {GroupFolderCreateRequest} groupFolderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  createGroupFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderCreateRequest: GroupFolderCreateRequest,
    options?: any
  ): AxiosPromise<GroupFolderUpdateRequest>;

  /**
   *
   * @summary Delete group folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  deleteGroupFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Get all group created for specified business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  getAllGroups(businessUnitId: number, options?: any): AxiosPromise<Array<GroupFolderShortView>>;

  /**
   *
   * @summary Get group folder content for specified date
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {string} date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  getContentForDate(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    date: string,
    options?: any
  ): AxiosPromise<Array<ContentItemView>>;

  /**
   *
   * @summary Get group folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  getGroupFolderById(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    options?: any
  ): AxiosPromise<GroupFolderView>;

  /**
   *
   * @summary Get the log of changes in the group folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  getGroupFolderContentChangeLog(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    options?: any
  ): AxiosPromise<Array<GroupFolderContentChangeLogView>>;

  /**
   *
   * @summary Get group folder by folderId for edit
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  getGroupFolderForEdit(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    options?: any
  ): AxiosPromise<GroupFolderUpdateRequest>;

  /**
   *
   * @summary Update group folder for edit
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {GroupFolderUpdateRequest} groupFolderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApiInterface
   */
  updateGroupFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderUpdateRequest: GroupFolderUpdateRequest,
    options?: any
  ): AxiosPromise<GroupFolderUpdateRequest>;
}

/**
 * GroupFolderControllerApi - object-oriented interface
 * @export
 * @class GroupFolderControllerApi
 * @extends {BaseAPI}
 */
export class GroupFolderControllerApi extends BaseAPI implements GroupFolderControllerApiInterface {
  /**
   *
   * @summary Create group folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {GroupFolderCreateRequest} groupFolderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public createGroupFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderCreateRequest: GroupFolderCreateRequest,
    options?: any
  ) {
    return GroupFolderControllerApiFp(this.configuration)
      .createGroupFolder(businessUnitId, sectionId, groupFolderCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete group folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public deleteGroupFolder(businessUnitId: number, sectionId: number, groupFolderId: number, options?: any) {
    return GroupFolderControllerApiFp(this.configuration)
      .deleteGroupFolder(businessUnitId, sectionId, groupFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all group created for specified business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public getAllGroups(businessUnitId: number, options?: any) {
    return GroupFolderControllerApiFp(this.configuration)
      .getAllGroups(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get group folder content for specified date
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {string} date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public getContentForDate(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    date: string,
    options?: any
  ) {
    return GroupFolderControllerApiFp(this.configuration)
      .getContentForDate(businessUnitId, sectionId, groupFolderId, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get group folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public getGroupFolderById(businessUnitId: number, sectionId: number, groupFolderId: number, options?: any) {
    return GroupFolderControllerApiFp(this.configuration)
      .getGroupFolderById(businessUnitId, sectionId, groupFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the log of changes in the group folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public getGroupFolderContentChangeLog(
    businessUnitId: number,
    sectionId: number,
    groupFolderId: number,
    options?: any
  ) {
    return GroupFolderControllerApiFp(this.configuration)
      .getGroupFolderContentChangeLog(businessUnitId, sectionId, groupFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get group folder by folderId for edit
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} groupFolderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public getGroupFolderForEdit(businessUnitId: number, sectionId: number, groupFolderId: number, options?: any) {
    return GroupFolderControllerApiFp(this.configuration)
      .getGroupFolderForEdit(businessUnitId, sectionId, groupFolderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update group folder for edit
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {GroupFolderUpdateRequest} groupFolderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupFolderControllerApi
   */
  public updateGroupFolder(
    businessUnitId: number,
    sectionId: number,
    groupFolderUpdateRequest: GroupFolderUpdateRequest,
    options?: any
  ) {
    return GroupFolderControllerApiFp(this.configuration)
      .updateGroupFolder(businessUnitId, sectionId, groupFolderUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
