import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SupportIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M1.20947 15.4005H1.84521C1.99902 15.4005 2.09131 15.3082 2.09131 15.1544V9.62756C2.09131 5.04407 5.55713 2.06018 10.5918 2.06018C15.6265 2.06018 19.1025 5.04407 19.1025 9.62756V15.1544C19.1025 15.3082 19.1948 15.4005 19.3486 15.4005H19.9844C20.4048 15.4005 20.7227 15.1237 20.7227 14.7238V9.23792C20.7227 4.02893 16.6006 0.440063 10.9302 0.440063H10.2637C4.59326 0.440063 0.471191 4.02893 0.471191 9.23792V14.7238C0.471191 15.1237 0.778809 15.4005 1.20947 15.4005ZM4.12158 19.2047H5.11621C6.29541 19.2047 6.97217 18.569 6.97217 17.4615V13.1857C6.97217 12.0782 6.29541 11.4425 5.11621 11.4425H4.12158C3.47559 11.4425 3.10645 11.7911 3.10645 12.4371V18.2203C3.10645 18.8561 3.47559 19.2047 4.12158 19.2047ZM16.0879 19.2047H17.0825C17.7285 19.2047 18.1079 18.8561 18.1079 18.2203V12.4371C18.1079 11.7911 17.7285 11.4425 17.0825 11.4425H16.0879C14.9087 11.4425 14.2319 12.0782 14.2319 13.1857V17.4615C14.2319 18.569 14.9087 19.2047 16.0879 19.2047Z"
        fill="#A69FAD"
      />
    </SvgIcon>
  );
}

export default SupportIcon;
