/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Company or project update request
 * @export
 * @interface BusinessUnitUpdateRequest
 */
export interface BusinessUnitUpdateRequest {
  /**
   * Business Unit id for update
   * @type {number}
   * @memberof BusinessUnitUpdateRequest
   */
  businessUnitId: number;
  /**
   * Icon id
   * @type {number}
   * @memberof BusinessUnitUpdateRequest
   */
  iconId: number;
  /**
   * Ids responsible for this business unit
   * @type {Array<number>}
   * @memberof BusinessUnitUpdateRequest
   */
  responsibleAccountIds: Array<number> | null;
  /**
   * Business unit status
   * @type {string}
   * @memberof BusinessUnitUpdateRequest
   */
  status?: BusinessUnitUpdateRequestStatusEnum;
  /**
   * English title
   * @type {string}
   * @memberof BusinessUnitUpdateRequest
   */
  titleEn: string;
  /**
   * Russian title
   * @type {string}
   * @memberof BusinessUnitUpdateRequest
   */
  titleRu: string;
  /**
   * version of the business unit
   * @type {number}
   * @memberof BusinessUnitUpdateRequest
   */
  version: number;
}

/**
 * @export
 * @enum {string}
 */
export enum BusinessUnitUpdateRequestStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
