import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccountControllerApiFactory, AccountView, AccountWithUserUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);

type AccountDetailsWithUpdateProviderType = QueryObserverResult<AccountWithUserUpdateRequest, unknown>;

const AccountDetailsWithUpdateContext = createContext<AccountDetailsWithUpdateProviderType | null>(null);
AccountDetailsWithUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useAccountDetailsWithUpdateProvider(): AccountDetailsWithUpdateProviderType {
  const contextState = useContext(AccountDetailsWithUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useAccountDetailsWithUpdateProvider.name} must be used within a ${AccountDetailsWithUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface AccountDetailsWithUpdateProviderProps {
  id: AccountView['id'];
}
export function AccountDetailsWithUpdateProvider(
  props: React.PropsWithChildren<AccountDetailsWithUpdateProviderProps>
) {
  const value = useQuery<AccountWithUserUpdateRequest, AxiosError<unknown>, AccountWithUserUpdateRequest>(
    [RESOURCE_NAME, { id: props.id }],
    () => AccountApi.getAccountUpdateRequestById(props.id).then(resp => resp.data)
  );
  return (
    <AccountDetailsWithUpdateContext.Provider value={value}>{props.children}</AccountDetailsWithUpdateContext.Provider>
  );
}
