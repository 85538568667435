import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useCompaniesListProvider } from 'api/CompaniesProviders';
import { ExtendedSelect } from 'components/fields/ExtendedSelect';

interface CompaniesSelectProps {
  fieldName: string;
  disabled?: boolean;
  label?: string;
  placeholder: string;
  subtitle?: boolean;
  secondaryListItemText: string;
}

export function CompaniesSelect({
  fieldName,
  disabled,
  label,
  placeholder,
  subtitle,
  secondaryListItemText,
}: CompaniesSelectProps) {
  const { data: companies } = useCompaniesListProvider();
  return (
    <Box display="flex" flexDirection="column">
      {!!subtitle && (
        <Box>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Box>
      )}
      <Box>
        <ExtendedSelect
          items={companies || []}
          name={fieldName}
          multiple
          label={label}
          formControlProps={{ margin: 'dense' }}
          disabled={disabled}
          displayEmpty
          getItemTitle={item => item.title}
          placeholder={placeholder}
          getItemSecondaryTitle={company =>
            company.responsible
              ? `${secondaryListItemText}: ${company.responsible.map((r: any) => r.displayName).join(', ')}`
              : undefined
          }
        />
      </Box>
    </Box>
  );
}
