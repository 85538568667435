import React, { useCallback } from 'react';

import classnames from 'classnames';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff';

import { useTranslation } from 'react-i18next';

import { DropzoneOptions, useDropzone } from 'react-dropzone';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseTextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';

import {
  FileMetaViewWithUrl,
  isFileMetaView,
  isFileMetaViewWithUrl,
} from 'api/DocumentProviders/Documents/type-helpers';
import { FileIcon, TrashIcon } from 'components/icons';
import { CustomLabel } from './CustomInput';

import { formatFileSize } from 'utils/formatFileSize';
import { shallowEqualObjects } from 'utils/shallowEqual';

import differenceWith from 'lodash/differenceWith';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

export type IDropFileFieldProps = Partial<Omit<MuiTextFieldProps, 'type' | 'onChange'>> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  showError?: ShowErrorFunc;
  accept?: DropzoneOptions['accept'];
  multiple?: boolean;
  tooltip?: string;
};
export function DropFileField(props: IDropFileFieldProps) {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      type="hidden"
      render={({ input, meta }) => <Dropzone input={input} meta={meta} {...rest} />}
      {...fieldProps}
    />
  );
}

type IDropzoneProps = FieldRenderProps<File | FileMetaViewWithUrl | (File | FileMetaViewWithUrl)[], HTMLElement> & {
  accept?: DropzoneOptions['accept'];
};
function Dropzone(props: IDropzoneProps) {
  const {
    input: { value, onChange },
    meta,
    required,
    fullWidth = true,
    helperText,
    showError = showErrorOnChange,
    label,
    margin,
    variant,
    placeholder,
    accept,
    multiple = false,
    tooltip,
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (multiple) {
        const values = value ? (isArray(value) ? value : [value]) : [];
        onChange([...values, ...differenceWith(acceptedFiles, values, shallowEqualObjects)]);
      } else {
        onChange(first(acceptedFiles));
      }
    },
    [value, multiple, onChange]
  );

  const onRemove = useCallback(
    (file: File | FileMetaViewWithUrl) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (isArray(value)) {
        const filteredFiles = value.filter(stateFile => !shallowEqualObjects(stateFile, file));
        onChange(filteredFiles);
      } else {
        onChange(null);
      }
    },
    [onChange, value]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    multiple,
    noDragEventsBubbling: true,
  });

  const classes = useStyles();
  return (
    <FormControl error={isError} margin={margin} variant={variant} fullWidth={fullWidth} required={required}>
      <CustomLabel shrink variant="standard">
        {tooltip ? (
          <Box display="flex" component="span">
            <Box component="span">{label}</Box>
            <Box
              color="grey.500"
              ml={1}
              mt={-0.2}
              style={{ cursor: 'pointer' }}
              component="span"
              fontSize="h5.fontSize"
            >
              <Tooltip title={tooltip}>
                <InfoIcon color="inherit" fontSize="inherit" />
              </Tooltip>
            </Box>
          </Box>
        ) : (
          label
        )}
      </CustomLabel>
      <Box {...getRootProps({ className: classnames(classes.dropzone, isError ? 'error' : '') })}>
        <input {...getInputProps()} />
        <Box padding={2}>
          <PreviewValue value={value} placeholder={placeholder} onRemove={onRemove} />
        </Box>
      </Box>
      <FormHelperText>{isError ? error || submitError : helperText}</FormHelperText>
    </FormControl>
  );
}

function PreviewValue({
  value,
  placeholder,
  onRemove,
}: {
  value: File | FileMetaViewWithUrl | (File | FileMetaViewWithUrl)[];
  placeholder: string;
  onRemove: (file: File | FileMetaViewWithUrl) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const stopPropagation = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  if (isEmpty(value)) {
    return (
      <Box textAlign="center">
        <Typography variant="caption" color="textSecondary">
          {placeholder}
        </Typography>
      </Box>
    );
  }

  const values: (File | FileMetaViewWithUrl)[] = isArray(value) ? value : [value];
  return (
    <Box>
      {map(values, value => {
        const [size, unit] = formatFileSize(value.size || 0);

        const formatter = new Intl.NumberFormat(i18n.language, {
          style: 'unit',
          unit,
          unitDisplay: 'short',
        } as any);

        let downloadUrl = '#';
        if (value) {
          if (isFileMetaViewWithUrl(value)) {
            downloadUrl = value.downloadUrl || downloadUrl;
          } else {
            downloadUrl = URL.createObjectURL(value);
          }
        }

        return (
          <Box display="flex" key={isFileMetaView(value) ? value.id : value.name} className={classes.fileItemPreview}>
            <Box marginRight={1.5} padding="3px">
              <Link href={downloadUrl} onClick={stopPropagation} target="_blank" rel="noopener noreferrer">
                <FileIcon fontSize="small" />
              </Link>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1} marginRight={1.5}>
              <Typography variant="caption" color="textPrimary" className={classes.fileName}>
                <Link href={downloadUrl} onClick={stopPropagation} target="_blank" rel="noopener noreferrer">
                  {isFileMetaView(value) ? value.fileName : value.name}
                </Link>
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatter.format(size || 0)}
              </Typography>
            </Box>
            <Box marginLeft="auto" color="grey.600">
              <IconButton aria-label="clear" size="small" onClick={onRemove(value)}>
                <TrashIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  dropzone: {
    outline: 'none',
    cursor: 'pointer',
    borderColor: theme.palette.grey['500'],
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 6,
    marginTop: theme.spacing(3),
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
  fileItemPreview: {
    '& + &': {
      marginTop: theme.spacing(0.5),
    },
  },
  fileName: {
    wordBreak: 'break-all',
  },
}));
