import React, { useCallback, useState } from 'react';

import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';

import { BusinessUnitDeleteProvider } from 'api/BusinessUnitProviders';
import { CompanyUpdateRequest } from 'api/CompaniesProviders/CompanyUpdateProvider';

import { makeParentUrl } from 'utils';
import trim from 'utils/trim';

import { AccountAccessSwitchGroup, DropImageField, TextField } from 'components/fields';
import { ModalToolbar } from 'components/material';
import { BagIcon } from 'components/icons';
import { composeValidators, required, maxLength } from 'components/fields/validators';

import { DeleteCompanyConfirmDialog } from './DeleteCompanyConfirmDialog';
import { CompaniesPageRouterParams } from '../../interfaces';
import { Typography } from '@material-ui/core';
import { ExtendedSelect } from 'components/fields/ExtendedSelect';
import { useAuthProvider } from 'api/AuthProviders';
import { getBusinessUnitIconURL } from 'api/http';
import { AccountAccessView, BusinessUnitUpdateRequest, UserSessionStateViewRoleEnum } from 'api/generated';

export enum CompanyFormFieldsEnum {
  accountAllowIds = 'accountAllowIds',
  responsibleIds = 'responsibleIds',
}

interface FormValues extends BusinessUnitUpdateRequest {
  [CompanyFormFieldsEnum.accountAllowIds]: number[];
  [CompanyFormFieldsEnum.responsibleIds]: number[];
}

interface AddCompanyFormProps {
  initialValues?: BusinessUnitUpdateRequest;
  personsList: AccountAccessView[];
  initialAllowIds: number[];
  responsibleList: AccountAccessView[];
  responsibleIds: number[];
  type: 'CREATE' | 'EDIT';
  onSubmit: any;
}

export type CompanyWithAccessFormRequestType = CompanyUpdateRequest & {
  [CompanyFormFieldsEnum.accountAllowIds]?: number[];
  [CompanyFormFieldsEnum.responsibleIds]?: number[];
} & Omit<any, any>;

export function CompanyForm({
  initialValues,
  onSubmit,
  type,
  personsList,
  initialAllowIds,
  responsibleList,
  responsibleIds,
}: AddCompanyFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const formValues = {
    ...initialValues,
    [CompanyFormFieldsEnum.accountAllowIds]: initialAllowIds,
    [CompanyFormFieldsEnum.responsibleIds]: responsibleIds,
  } as FormValues;

  const { role } = useAuthProvider();
  const showResponsibleFields = role === UserSessionStateViewRoleEnum.ADMIN;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box pb={2}>
            <Box mb={1}>
              <DropImageField
                name="logo"
                getUrl={() => getBusinessUnitIconURL(formValues.businessUnitId, formValues.iconId)}
                buttonLabel={t('companies:dialog.fields.upload')}
                DefaultIcon={UploadLogoIcon}
              />
            </Box>
            <TextField
              label={t('companies:dialog.fields.titleRu')}
              placeholder={t('companies:dialog.fields.titleRuPlaceholder')}
              name="titleRu"
              margin="dense"
              required
              fieldProps={{
                validate: titleValidator,
                format: trim,
                formatOnBlur: true,
              }}
            />
            <TextField
              label={t('companies:dialog.fields.titleEn')}
              placeholder={t('companies:dialog.fields.titleEnPlaceholder')}
              name="titleEn"
              margin="dense"
              required
              fieldProps={{
                validate: titleValidator,
                format: trim,
                formatOnBlur: true,
              }}
            />
          </Box>
          {showResponsibleFields ? (
            <Box pr={1.5} pb={2}>
              <Typography variant="h6">{t('companies:dialog.fields.responsible')}</Typography>
              <ExtendedSelect
                items={responsibleList.map(i => i.accountDisplayView) || []}
                name={CompanyFormFieldsEnum.responsibleIds}
                multiple
                formControlProps={{ margin: 'dense' }}
                displayEmpty
                getItemTitle={item => item.displayName}
                placeholder={t('companies:dialog.fields.responsiblePlaceholder')}
              />
            </Box>
          ) : undefined}
          <Box mr={-4} maxHeight={300} overflow="auto">
            <Typography variant="h6">{t('companies:dialog.fields.userAccess')}</Typography>
            <Box pt={1} mr={5.5}>
              <AccountAccessSwitchGroup name={CompanyFormFieldsEnum.accountAllowIds} data={personsList} />
            </Box>
          </Box>
          <Box mx={-4}>
            <ModalToolbar>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                  {type === 'CREATE' ? t('companies:dialog.create') : t('companies:dialog.save')}
                </Button>
                {type === 'EDIT' && <CompanyDeleteButton />}
              </Box>
            </ModalToolbar>
          </Box>
        </form>
      )}
    />
  );
}

const titleValidator = composeValidators(required, maxLength(120));

function CompanyDeleteButton() {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<CompaniesPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false);

  const showConfirmRemoveDialog = useCallback(event => {
    setOpenConfirmRemove(true);
  }, []);

  const closeConfirmDialog = useCallback(event => {
    setOpenConfirmRemove(false);
  }, []);

  const onDelete = useCallback(
    event => {
      setOpenConfirmRemove(false);
      history.push(makeParentUrl(match.url));
    },
    [history, match.url]
  );

  return (
    <>
      <Button variant="text" color="secondary" onClick={showConfirmRemoveDialog}>
        {t('companies:dialog.delete')}
      </Button>
      <BusinessUnitDeleteProvider businessUnitId={businessUnitId}>
        <DeleteCompanyConfirmDialog open={openConfirmRemove} onClose={closeConfirmDialog} onDelete={onDelete} />
      </BusinessUnitDeleteProvider>
    </>
  );
}

function UploadLogoIcon(props: any) {
  return (
    <CustomAvatar {...props}>
      <Box display="flex" alignItems="center" justifyContent="center" fontSize="5rem">
        <BagIcon style={{ fontSize: 38 }} />
      </Box>
    </CustomAvatar>
  );
}

export const CustomAvatar = withStyles(theme => ({
  root: {
    height: 76,
    width: 76,
    backgroundColor: theme.palette.secondary.main,
  },
}))(Avatar);

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
    '& .MuiFormControl-marginDense': {
      marginBottom: theme.spacing(1),
    },
  },
}));
