import { initializeApp } from 'firebase/app';
import { getMessaging as _getMessaging, Messaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAj9XSLSAr-4-8w1ygw1IJjRpbRrDtRkXI',
  authDomain: 'universe-55cbd.firebaseapp.com',
  projectId: 'universe-55cbd',
  storageBucket: 'universe-55cbd.appspot.com',
  messagingSenderId: '469093920586',
  appId: '1:469093920586:web:bbed6e0a08c9c02dd89bb3',
};

let messaging: Messaging;
export const getMessaging = () => {
  if (!messaging) {
    const app = initializeApp(firebaseConfig);
    messaging = _getMessaging(app);
  }
  return messaging;
};
