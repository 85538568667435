import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  DocumentCreateProvider,
  DocumentWithFilesCreateRequest,
  useDocumentCreateProvider,
} from 'api/DocumentProviders/Documents/DocumentCreateProvider';
import { DocumentMetaUpdateRequestLanguageEnum } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';
import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { DocumentForm, DocumentFormValues } from './DocumentForm';
import { DocumentRequiredRouterParams } from './interfaces';

export function AddDocumentDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<DocumentRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const parentFolderId = parseInt(match.params.parentFolderId || '', 10);

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url));

  return (
    <Modal
      title={t('documents:dialog.createTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={AddDocumentDialog.name}
    >
      <DocumentCreateProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        parentFolderId={parentFolderId}
        queryOptions={{ onError: catchError }}
      >
        <DocumentCreate />
      </DocumentCreateProvider>
    </Modal>
  );
}

function DocumentCreate() {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { mutateAsync } = useDocumentCreateProvider();

  const onSubmit = useCallback(
    async (values: DocumentFormValues) => {
      const documentMetas: DocumentWithFilesCreateRequest['documentMetas'] = [];
      if (values.pdfFile || values.originalFile) {
        documentMetas.push({
          title: values.name,
          language: DocumentMetaUpdateRequestLanguageEnum.RUSSIAN,
          pdfFile: values.pdfFile,
          originalFile: values.originalFile,
        });
      }
      if (values.pdfFileEn || values.originalFileEn) {
        documentMetas.push({
          title: values.nameEn,
          language: DocumentMetaUpdateRequestLanguageEnum.ENGLISH,
          pdfFile: values.pdfFileEn,
          originalFile: values.originalFileEn,
        });
      }
      return mutateAsync({ documentMetas }).then(() => history.push(makeParentUrl(match.url)));
    },
    [history, match.url, mutateAsync]
  );

  return <DocumentForm onSubmit={onSubmit} submitLabel={t('documents:form.create')} />;
}
