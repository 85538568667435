import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthProvider } from 'api/AuthProviders';
import { PersonListOrderUpdateProvider } from 'api/PersonsProvider';
import { SectionContentProvider, useSectionContentProvider, useSectionShowProvider } from 'api/SectionsProviders';
import { ITEMS_PER_PAGE } from 'api/SectionsProviders/SectionContentProvider';
import { ContentItemView, HierarchyPlaceDataTypeEnum, UserSessionStateViewRoleEnum } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { PaperIcon } from 'components/icons';
import { ListWrapper } from 'pages/Layouts/ListWrapper';
import { backToolbarHeight } from 'styles/constants';
import { personsAndGroupsHeaderHeight } from 'styles/constants';
import { makeParentUrl } from 'utils';

import { GroupsList } from './GroupsList/GroupsList';
import { PARENT_FOLDER_SEARCH_PARAM_NAME, TAB_SEARCH_PARAM_NAME } from './constants';

import flatten from 'lodash/flatten';
import find from 'lodash/find';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import { GroupOrderUpdateProvider } from '../../../api/GroupsProvider/GroupOrderUpdateProvider';
import { MemberListEmptyContent } from 'components/lists/MemberListEmptyContent';
import { SortablePersonsTree } from './PersonsList/SortablePersonsTree';

interface PersonsAndGroupsColumnProps {
  businessUnitId: number;
  sectionId: number;
}
export function PersonsAndGroupsColumn({ businessUnitId, sectionId }: PersonsAndGroupsColumnProps) {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const [selectedTab, setSelectedTab] = useState<PersonsAndGroupsTab | undefined>(undefined);

  const { data } = useSectionContentProvider();
  const content = flatten(data?.pages);

  const personTab = find(content, { dataType: 'GROUP' }) as ContentItemView | undefined;
  const groupTab = find(content, { dataType: 'PARENT_FOR_GROUP' }) as ContentItemView | undefined;

  const { data: section } = useSectionShowProvider();

  const { role } = useAuthProvider();
  const canAddPerson = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;
  const canAddGroup = canAddPerson;

  useEffect(() => {
    const tabs = [personTab, groupTab];
    if (selectedTab || every(tabs, isNil)) {
      return;
    }
    if (isNil(personTab)) {
      setSelectedTab('GROUPS');
    } else if (isNil(groupTab)) {
      setSelectedTab('PERSONS');
    } else {
      const query = new URLSearchParams(location.search);
      let tab = query.get(TAB_SEARCH_PARAM_NAME);
      tab = tab && tab.toUpperCase();
      if (isPersonsAndGroupsTab(tab)) {
        setSelectedTab(tab);
      } else {
        setSelectedTab('PERSONS');
      }
    }
  }, [personTab, groupTab, selectedTab, location.search]);

  const goBack = useCallback(() => {
    history.push(makeParentUrl(match.url, 2));
  }, [history, match.url]);

  const showPreviewHandler = useCallback(() => {
    if (section?.sectionPreviewFileMetaView) {
      history.push(`${match.url}/section_preview`, {
        businessUnitId: section.businessUnitId,
        sectionId: section.sectionId,
        previewFileId: section.sectionPreviewFileMetaView.id,
        hideGoToList: true,
      });
    }
  }, [history, match.url, section]);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: PersonsAndGroupsTab) => {
      setSelectedTab(newValue);
      const query = new URLSearchParams(location.search);
      query.set(TAB_SEARCH_PARAM_NAME, newValue);
      history.replace({
        ...location,
        search: query.toString(),
      });
    },
    [history, location]
  );

  const getAddGroupUrl = useCallback(() => {
    const query = new URLSearchParams(location.search);
    query.set(TAB_SEARCH_PARAM_NAME, selectedTab || '');
    query.set(PARENT_FOLDER_SEARCH_PARAM_NAME, `${groupTab?.id}`);
    return `${match.url}/add_group?${query.toString()}`;
  }, [groupTab, location.search, match.url, selectedTab]);

  const optionsRef = useRef({
    getNextPageParam: (lastPage: ContentItemView[], pages: ContentItemView[][]) => {
      if (lastPage.length >= ITEMS_PER_PAGE) {
        return { skip: pages.length * ITEMS_PER_PAGE, limit: ITEMS_PER_PAGE };
      }
      return undefined;
    },
    getPreviousPageParam: (_firstPage: ContentItemView[], _pages: ContentItemView[][]) => {
      // there is no previous page available
      return undefined;
    },
  });

  const classes = useStyles();
  return (
    <ListWrapper>
      <Box className={classes.backToolbar}>
        <Button className={classes.backBtn} startIcon={<ArrowBackIosIcon />} size="small" onClick={() => goBack()}>
          {`${t('persons:back')}`}
        </Button>
      </Box>
      <Box className={classes.header}>
        <Box flexGrow="1" display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box className={classes.sectionTitle} component="span">
            {section?.sectionTitle}
          </Box>
          <Box display="flex" alignItems="center">
            {section?.sectionPreviewFileMetaView && (
              <Box mr={3} display="flex" alignItems="center">
                <ButtonBase onClick={showPreviewHandler} centerRipple>
                  <PaperIcon fontSize="large" />
                </ButtonBase>
              </Box>
            )}
            {selectedTab === 'GROUPS' && canAddGroup && (
              <Button color="primary" variant="contained" size="small" component={Link} to={getAddGroupUrl()}>
                {`+ ${t('groups:add')}`}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.tabsContainer}>
        {selectedTab && (
          <Tabs
            classes={{ indicator: classes.tabsIndicator }}
            value={selectedTab}
            onChange={handleTabChange}
            textColor="primary"
          >
            {personTab && <Tab key="PERSONS" label={personTab.title} value="PERSONS" />}
            {groupTab && <Tab key="GROUPS" label={groupTab.title} value="GROUPS" />}
          </Tabs>
        )}
        <Divider />
        <Box className={classes.list}>
          {personTab && selectedTab === 'PERSONS' && (
            <SectionContentProvider
              filter={undefined}
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              parentFolderId={personTab.id}
              allHierarchyLevels={true}
              queryOptions={{ onError: catchError, ...optionsRef.current }}
            >
              <PersonListOrderUpdateProvider
                businessUnitId={businessUnitId}
                sectionId={sectionId}
                parentFolderId={personTab.id}
                dataType={HierarchyPlaceDataTypeEnum.PERSON}
              >
                <SortablePersonsTree
                  businessUnitId={businessUnitId}
                  parentFolderId={personTab.id}
                  sectionId={sectionId}
                  getEmptyListContent={() => (
                    <Box height={`calc(100% - ${personsAndGroupsHeaderHeight})`}>
                      <MemberListEmptyContent text={t('persons:empty')} />
                    </Box>
                  )}
                />
              </PersonListOrderUpdateProvider>
            </SectionContentProvider>
          )}
          {groupTab && selectedTab === 'GROUPS' && (
            <SectionContentProvider
              filter={undefined}
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              parentFolderId={groupTab.id}
              allHierarchyLevels={false}
              queryOptions={{ onError: catchError, ...optionsRef.current }}
            >
              <GroupOrderUpdateProvider
                businessUnitId={businessUnitId}
                sectionId={sectionId}
                parentFolderId={groupTab.id}
                dataType={HierarchyPlaceDataTypeEnum.PARENT_FOR_GROUP}
              >
                <GroupsList />
              </GroupOrderUpdateProvider>
            </SectionContentProvider>
          )}
        </Box>
      </Box>
    </ListWrapper>
  );
}

type PersonsAndGroupsTab = 'PERSONS' | 'GROUPS';

function isPersonsAndGroupsTab(tab: any): tab is PersonsAndGroupsTab {
  return typeof tab === 'string' && (tab === 'PERSONS' || tab === 'GROUPS');
}

export const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(0, 9, 0, 9),
    minHeight: 36,
  },
  backToolbar: {
    minHeight: backToolbarHeight,
    height: backToolbarHeight,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 9, 0.5, 9),
  },
  backBtn: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(-2),
  },
  sectionTitle: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginRight: theme.spacing(1.5),
    lineHeight: '32px',
  },
  tabsContainer: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 9, 0, 9),
    display: 'flex',
    flexDirection: 'column',
  },
  tabsIndicator: {
    backgroundColor: '#EB6060',
  },
  list: {
    overflow: 'auto',
    flexGrow: 1,
  },
}));
