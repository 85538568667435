import React from 'react';

import { Form } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { SecretaryUpdateRequest } from 'api/generated';

import { PersonalInfo } from './PersonalInfo';

interface SecretaryFormProps {
  initialValues?: SecretaryUpdateRequest;
  onSubmit: any;
  onCancel: any;
  submitLabel: string;
  cancelLabel: string;
  readOnly?: boolean;
}
export function SecretaryForm({
  initialValues,
  onSubmit,
  onCancel,
  submitLabel,
  cancelLabel,
  readOnly = false,
}: SecretaryFormProps) {
  const emptyInitialValues: Partial<SecretaryUpdateRequest> = {};

  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues || emptyInitialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box display="flex" flexDirection="column">
            <Box mb={2.5}>
              <PersonalInfo />
            </Box>
            {!readOnly && (
              <Toolbar disableGutters>
                <Box display="flex" my={3}>
                  <Box>
                    <Button type="submit" variant="contained" color="primary">
                      {submitLabel}
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                      {cancelLabel}
                    </Button>
                  </Box>
                </Box>
              </Toolbar>
            )}
          </Box>
        </form>
      )}
    />
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
  },
}));
