import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PinLockedIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="12" height="14" viewBox="0 0 12 14" fill="none" {...props}>
      <path
        d="M5.55029 0.579346C4.20264 0.579346 2.94287 1.35864 2.521 2.92896C2.51514 2.95239 2.51514 2.95825 2.50928 2.98169L3.47607 3.94849C3.47607 3.91333 3.48193 3.87231 3.48193 3.83716C3.5874 2.46021 4.46631 1.69263 5.55029 1.69263C6.69873 1.69263 7.63623 2.53052 7.63623 4.12427V5.64185L5.17529 5.64771L9.72217 10.1946V6.91333C9.72217 6.15161 9.41748 5.74731 8.79639 5.66528V4.2356C8.79639 1.7688 7.23193 0.579346 5.55029 0.579346ZM10.5015 13.3586C10.6831 13.5403 10.9819 13.5403 11.1577 13.3586C11.3394 13.1711 11.3452 12.8782 11.1577 12.6965L0.79834 2.34302C0.616699 2.16138 0.317871 2.15552 0.13623 2.34302C-0.0454102 2.5188 -0.0454102 2.82349 0.13623 2.99927L10.5015 13.3586ZM2.58545 12.7434H8.58545C8.68506 12.7434 8.77295 12.7375 8.84912 12.7258L1.92334 5.79419C1.53662 5.99341 1.38428 6.36255 1.38428 6.96021V11.4661C1.38428 12.345 1.78271 12.7434 2.58545 12.7434Z"
        fill="white"
      />
    </SvgIcon>
  );
}
