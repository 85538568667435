import React, { useCallback } from 'react';

import { Form, Field, FormProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { formatPhone } from 'utils/phone';
import { SmsCodeIcon } from 'components/icons/SmsCodeIcon';

import isObject from 'lodash/isObject';
import { useTranslation } from 'react-i18next';
import { required } from '../validators';
import { useFormStyles } from '../styles';

interface IEnterSMSCodeFormProps {
  onSubmit: FormProps['onSubmit'];
  phone: string;
  smsCodeLength: number;
  loading: boolean;
}

export function EnterSMSCodeForm({ onSubmit, phone, smsCodeLength, loading }: IEnterSMSCodeFormProps) {
  const { t } = useTranslation();

  const translateError = useCallback(
    error => {
      let errorText = '';
      if (isObject(error) as any) {
        errorText = t(error.message, error.args);
      } else {
        errorText = t(error);
      }
      return errorText;
    },
    [t]
  );

  const classes = useFormStyles();
  return (
    <Form
      onSubmit={onSubmit}
      subscription={formSubscription}
      keepDirtyOnReinitialize={true}
      render={formProps => (
        <form onSubmit={formProps.handleSubmit} className={classes.form}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <SmsCodeIcon style={{ fontSize: 43, marginLeft: 30 }} />
            <Box mt={3} fontSize="h5.fontSize" fontWeight="bold">
              {t('login:smsCodeVerification')}
            </Box>
            <Box fontSize="subtitle1.fontSize" mt={3} color="text.secondary" textAlign="center">
              <span>{t('login:enterSMSCode')} </span>
              <span>{formatPhone(phone)}</span>
            </Box>
            {loading ? (
              <Box pt={5}>
                <CircularProgress />
              </Box>
            ) : (
              <Box mt={4} width={160}>
                <Field name="otp" validate={smsCodeFieldValidation}>
                  {fieldProps => {
                    const hasError = fieldProps.meta.invalid && fieldProps.meta.submitFailed;
                    const errorText = hasError ? translateError(fieldProps.meta.error) : '';
                    return (
                      <TextField
                        className={classes.smsCodeField}
                        variant="outlined"
                        inputProps={{
                          name: fieldProps.input.name,
                          autoFocus: true,
                          maxLength: smsCodeLength,
                          autoComplete: 'off',
                          'data-test': 'sms_code',
                        }}
                        onChange={fieldProps.input.onChange}
                        onFocus={fieldProps.input.onFocus}
                        onBlur={fieldProps.input.onBlur}
                        error={hasError}
                        helperText={errorText}
                      />
                    );
                  }}
                </Field>
                <OnChange name="otp">
                  {(otp: string) => {
                    if (otp && otp.length === smsCodeLength) {
                      formProps.form.submit();
                    }
                  }}
                </OnChange>
              </Box>
            )}
          </Box>
        </form>
      )}
    />
  );
}

// Form Helpers

// don't redraw entire form each time one field changes
const formSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const smsCodeFieldValidation = required();
