import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { InfiniteData, useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  BusinessUnitSectionControllerApiFactory,
  ContentItemView,
  HierarchyPlaceDataTypeEnum,
  UpdateContentOrderRequest,
} from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';
import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from '../SectionsProviders/constants';
import { ITEMS_PER_PAGE } from '../SectionsProviders/SectionContentProvider';

import chunk from 'lodash/chunk';
import compact from 'lodash/compact';
import find from 'lodash/find';
import first from 'lodash/first';
import flatten from 'lodash/flatten';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';

const SectionsApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

interface GroupOrderUpdateRequest {
  from: {
    id: number;
    index: number;
  };
  to: {
    id: number;
    index: number;
  };
}

type MutationContext = { prevState: InfiniteData<ContentItemView[]> | undefined };

type GroupOrderUpdateProviderType = {
  businessUnitId: number;
  sectionId: number;
  parentFolderId: number;
  controller: UseMutationResult<ContentItemView[], AxiosError<unknown>, GroupOrderUpdateRequest, MutationContext>;
};

const GroupOrderUpdateContext = createContext<GroupOrderUpdateProviderType | null>(null);
GroupOrderUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}OrderUpdate`;

export function useGroupOrderUpdateProvider(): GroupOrderUpdateProviderType {
  const contextState = useContext(GroupOrderUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useGroupOrderUpdateProvider.name} must be used within a ${GroupOrderUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface GroupOrderUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  parentFolderId: number;
  dataType: HierarchyPlaceDataTypeEnum;
  queryOptions?: UseMutationOptions<ContentItemView[], AxiosError<unknown>, GroupOrderUpdateRequest, MutationContext>;
}
export function GroupOrderUpdateProvider(props: React.PropsWithChildren<GroupOrderUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const updateDefaultOptions: GroupOrderUpdateProviderProps['queryOptions'] = {
    onMutate: params => {
      const prevState: InfiniteData<ContentItemView[]> | undefined = queryClient.getQueryData([
        SECTION_RESOURCE_NAME,
        props.businessUnitId,
        props.sectionId,
        props.parentFolderId,
      ]);
      // Optimistically update order
      queryClient.setQueryData<InfiniteData<ContentItemView[]> | undefined>(
        [SECTION_RESOURCE_NAME, props.businessUnitId, props.sectionId, props.parentFolderId],
        state => {
          const groups = flatten(state?.pages);
          return isNil(state)
            ? state
            : {
                ...state,
                pages: chunk(moveNodeById(params.from.id, params.to.id, params.to.index, groups), ITEMS_PER_PAGE),
              };
        }
      );
      return { prevState };
    },
    onError: (err, params, context) => {
      queryClient.setQueryData(
        [SECTION_RESOURCE_NAME, props.businessUnitId, props.sectionId, props.parentFolderId],
        context?.prevState
      );
    },
    onSettled: data => {
      queryClient.invalidateQueries([
        SECTION_RESOURCE_NAME,
        props.businessUnitId,
        props.sectionId,
        props.parentFolderId,
      ]);
    },
  };
  const update = useMutation<ContentItemView[], AxiosError<unknown>, GroupOrderUpdateRequest, MutationContext>(
    async (params: GroupOrderUpdateRequest) => {
      const indexTo = params.to.index;
      const indexFrom = params.from.index;
      const result: UpdateContentOrderRequest['childrenEntities'] = params
        ? [
            {
              id: params.from.id,
              orderNum: indexTo,
              dataType: props.dataType,
            },
            {
              id: params.to.id,
              orderNum: indexFrom,
              dataType: props.dataType,
            },
          ]
        : [];
      if (result.length) {
        const childrenEntities: UpdateContentOrderRequest['childrenEntities'] = result;
        return SectionsApi.updateContentOrderAndPlace(props.businessUnitId, props.sectionId, {
          childrenEntities,
          parentFolderId: props.parentFolderId,
        }).then(resp => resp.data);
      }
      return Promise.reject(`${upperFirst(RESOURCE_NAME)}OrderUpdate can't find node with id: ${params.to.id}`);
    },
    {
      ...updateDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <GroupOrderUpdateContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        sectionId: props.sectionId,
        parentFolderId: props.parentFolderId,
        controller: update,
      }}
    >
      {props.children}
    </GroupOrderUpdateContext.Provider>
  );
}

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

export function allNodesId(data: ContentItemView[]): ContentItemView['id'][] {
  return flatten(map(data, d => [d.id, ...allNodesId(d.children)]));
}

export function findNodeById(id: number, data: ContentItemView[]): ContentItemView | undefined {
  const finded = find(data, { id });
  if (finded) {
    return finded;
  }
  return first(compact(map(data, d => findNodeById(id, d.children))));
}

export function insertNodeToTree(
  node: ContentItemView,
  parent: ContentItemView | undefined,
  toIndex: number | undefined,
  tree: ContentItemView[]
): ContentItemView[] {
  if (!parent) {
    if (isNil(toIndex)) {
      return [...tree, node];
    }
    tree.splice(toIndex, 0, node);
    return tree;
  }
  return map(tree, n => {
    if (n.id === parent.id) {
      if (isNil(toIndex)) {
        return { ...n, children: [...n.children, node] };
      }
      const children = [...n.children];
      children.splice(toIndex, 0, node);
      return { ...n, children };
    }
    return { ...n, children: [...insertNodeToTree(node, parent, toIndex, n.children)] };
  });
}

export function removeNodeFromTree(node: ContentItemView, tree: ContentItemView[]): ContentItemView[] {
  return compact(
    map(tree, n => {
      if (n.id === node.id) {
        return undefined;
      }
      return { ...n, children: [...removeNodeFromTree(node, n.children)] };
    })
  );
}

export function moveNodeById(
  id: number,
  parentId: number,
  toIndex: number | undefined,
  tree: ContentItemView[]
): ContentItemView[] {
  const finded = findNodeById(id, tree);
  if (!finded) {
    return tree;
  }

  let t = [...tree];

  t = removeNodeFromTree(finded, t);

  const parent = findNodeById(parentId, tree);
  t = insertNodeToTree(finded, parent, toIndex, t);

  return t;
}
