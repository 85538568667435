import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useDocumentDeleteProvider } from 'api/DocumentProviders/Documents';
import { DeleteConfirmDialog } from '../ConfirmDialogs';

interface DeleteDocumentConfirmDialogProps {
  open: boolean;
  onClose: (event?: any) => void;
  onDelete: (event?: any) => void;
}
export function DeleteDocumentConfirmDialog({ open, onDelete, onClose }: DeleteDocumentConfirmDialogProps) {
  const { t } = useTranslation();

  const { mutateAsync: deleteDocument, isLoading } = useDocumentDeleteProvider();

  const handleRemove = useCallback(
    async event => {
      await deleteDocument({}).then(() => {
        onDelete();
      });
    },
    [deleteDocument, onDelete]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={handleRemove}
      title={t('businessUnit:deleteDocumentConfirmDialog.title')}
      confirmText={t('businessUnit:deleteDocumentConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}
