import React from 'react';

import { useTranslation } from 'react-i18next';

import { ValidationErrors } from 'final-form';
import { Form } from 'react-final-form';
import classnames from 'classnames';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { MobileAppWithFilesCreateRequest } from 'api/MobileAppsProviders';

import { DropFileField, TextField } from 'components/fields';
import { maxLength, required, semVer } from 'components/fields/validators';
import { SpinnerButton } from 'components/material';

interface MobileAppFormProps {
  initialValues?: Partial<MobileAppWithFilesCreateRequest>;
  onSubmit: any;
  onCancel: any;
  submitLabel: string;
  cancelLabel: string;
}
export function MobileAppForm({ initialValues, onSubmit, onCancel, submitLabel, cancelLabel }: MobileAppFormProps) {
  const { t } = useTranslation();
  const emptyInitialValues = {};

  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues || emptyInitialValues}
      keepDirtyOnReinitialize
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box className={classnames(classes.overlay, { [classes.disabled]: submitting })}></Box>
          <Box display="flex" flexDirection="column">
            <TextField
              label={t('mobileApps:form.fields.appVersion')}
              placeholder={t('mobileApps:form.fields.appVersionPlaceholder')}
              name="appVersion"
              margin="dense"
            />

            <TextField
              label={t('mobileApps:form.fields.descriptionRu')}
              placeholder={t('mobileApps:form.fields.descriptionRuPlaceholder')}
              name="descriptionRu"
              margin="dense"
              rows={4}
              multiline
            />

            <TextField
              label={t('mobileApps:form.fields.descriptionEn')}
              placeholder={t('mobileApps:form.fields.descriptionEnPlaceholder')}
              name="descriptionEn"
              margin="dense"
              rows={4}
              multiline
            />

            <DropFileField
              label={t('mobileApps:form.fields.appFile')}
              placeholder={t('mobileApps:form.fields.appFilePlaceholder')}
              name="appFile"
              margin="dense"
              accept=".ipa"
            />

            <Box display="flex" mb={3}>
              <Box flexGrow={1} flexBasis="50%" mr={1}>
                <DropFileField
                  label={t('mobileApps:form.fields.logoFile')}
                  placeholder={t('mobileApps:form.fields.logoFilePlaceholder')}
                  name="logoFile"
                  margin="dense"
                />
              </Box>
              <Box flexGrow={1} flexBasis="50%" ml={1}>
                <DropFileField
                  label={t('mobileApps:form.fields.bigLogoFile')}
                  placeholder={t('mobileApps:form.fields.bigLogoFilePlaceholder')}
                  name="bigLogoFile"
                  margin="dense"
                />
              </Box>
            </Box>

            <Toolbar disableGutters>
              <Box display="flex" my={3}>
                <Box>
                  <Button type="submit" variant="contained" color="primary">
                    {submitting ? <SpinnerButton size={16} label={submitLabel} color="inherit" /> : submitLabel}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button variant="contained" color="secondary" onClick={onCancel}>
                    {cancelLabel}
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </Box>
        </form>
      )}
    />
  );
}

const descriptionValidator = maxLength(4000);

const validateForm = (values: MobileAppWithFilesCreateRequest) => {
  const errors: ValidationErrors = {};

  errors.appVersion = required(values.appVersion);
  if (!errors.appVersion) {
    // TODO: remove any and resolve type error
    errors.appVersion = semVer(values.appVersion as any);
  }

  errors.appFile = required(values.appFile);

  errors.descriptionEn = required(values.descriptionEn);
  if (!errors.descriptionEn) {
    errors.descriptionEn = descriptionValidator(values.descriptionEn);
  }

  errors.descriptionRu = required(values.descriptionRu);
  if (!errors.descriptionRu) {
    errors.descriptionRu = descriptionValidator(values.descriptionRu);
  }

  return errors;
};

const useStyles = makeStyles(theme => ({
  form: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(0, 4),
    margin: theme.spacing(0, -4),
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  disabled: {
    zIndex: 50000,
    opacity: 0.5,
    background: '#fff',
  },
}));
