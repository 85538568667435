import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  AddSectionProvider,
  useAddSectionProvider,
  useNewSectionAccessProvider,
  useSectionAccessUpdateProvider,
  SectionAccessUpdateProvider,
  NewSectionAccessProvider,
} from 'api/SectionsProviders';
import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { makeParentUrl } from 'utils';
import { useAuthProvider } from 'api/AuthProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';

import { SectionForm, SectionFormValues } from './SectionForm';
import { BusinessUnitPageRouterParams } from '../interfaces';

import { getAccessInfoForRequest } from './helpers';

export function AddSectionDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();
  const match = useRouteMatch<BusinessUnitPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);

  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url));

  return (
    <Modal
      title={t('businessUnit:dialog.newSection')}
      open={!!match}
      onCloseModal={closeHandler}
      disableBackdropClick
      maxWidth="sm"
      fullWidth={true}
      data-test={AddSectionDialog.name}
    >
      <SectionAccessUpdateProvider queryOptions={{ onError: catchError }}>
        <NewSectionAccessProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
          <AddSectionProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
            <SectionCreate businessUnitId={businessUnitId} />
          </AddSectionProvider>
        </NewSectionAccessProvider>
      </SectionAccessUpdateProvider>
    </Modal>
  );
}

function SectionCreate({ businessUnitId }: { businessUnitId: number }) {
  const match = useRouteMatch();
  const history = useHistory();
  const { mutateAsync: addSection } = useAddSectionProvider();
  const {
    newSectionAccess: {
      readerAccess: { data: readerList },
      responsibleAccess: { data: responsibleList },
    },
  } = useNewSectionAccessProvider();
  const {
    updateAccess: { mutateAsync: updateAccess },
  } = useSectionAccessUpdateProvider();
  const { role } = useAuthProvider();

  const canUpdateResponsibleAccess =
    role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;

  const onSubmit = useCallback(
    async (values: SectionFormValues) => {
      const { responsible, reader, ...createValues } = values;
      await addSection({
        ...createValues,
        sectionRestrictedAccess: reader.restrictedAccess,
        sectionRestrictedResponsibleAccess: responsible.restrictedAccess,
      }).then(section => {
        updateAccess({
          readerAccess: getAccessInfoForRequest(reader, readerList || []),
          responsibleAccess: getAccessInfoForRequest(responsible, responsibleList || []),
          sectionId: section.sectionId,
          businessUnitId,
        }).then(() => {
          history.push(makeParentUrl(match.url));
        });
      });
    },
    [addSection, updateAccess, readerList, responsibleList, businessUnitId, history, match.url]
  );

  return (
    <SectionForm
      onSubmit={onSubmit}
      type="CREATE"
      readerInfo={{ list: readerList }}
      responsibleInfo={{ list: responsibleList }}
      createProps={{
        canUpdateResponsibleAccess,
      }}
    />
  );
}
