import React, { useCallback, useMemo } from 'react';

import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import { TextFieldProps } from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { useErrorCatcher } from 'api/notifications';
import { MembersByDateListProvider } from 'api/PersonsProvider';

import { makeParentUrl } from 'utils';
import { Modal } from 'components/material';
import { CustomInput } from 'components/fields';
import { CalendarIcon } from 'components/icons';
import { GroupRequiredRouterParams } from '../interfaces';
import { MembersByDateList } from '../PersonsList/MembersByDateList';
import { DATE_SEARCH_PARAM_NAME, PARENT_FOLDER_SEARCH_PARAM_NAME } from '../constants';

export function MembersByDateDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const history = useHistory();
  const match = useRouteMatch<GroupRequiredRouterParams>();
  const query = new URLSearchParams(history.location.search);

  const date = query.get(DATE_SEARCH_PARAM_NAME) || '';
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  let groupFolderId = parseInt(match.params.groupFolderId || '', 10);
  if (isNaN(groupFolderId)) {
    groupFolderId = parseInt(query.get(PARENT_FOLDER_SEARCH_PARAM_NAME) || '', 10);
  }

  const closeHandler = () => history.push(makeParentUrl(match.url, 1));

  return (
    <Modal
      title={t('persons:dialog.membershipByDateTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={MembersByDateDialog.name}
    >
      {!!businessUnitId && !!sectionId && !!groupFolderId && !!date && (
        <MembersByDateListProvider
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          date={date}
          groupFolderId={groupFolderId}
          queryOptions={{ onError: catchError }}
        >
          <PersonsListForm />
        </MembersByDateListProvider>
      )}
    </Modal>
  );
}

function PersonsListForm() {
  const history = useHistory();

  const match = useRouteMatch<GroupRequiredRouterParams>();
  const query = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const date = query.get(DATE_SEARCH_PARAM_NAME) || '';

  const onChange = useCallback(
    value => {
      const currentDate = value.toISOString().split('T')[0];
      query.set(DATE_SEARCH_PARAM_NAME, currentDate);
      history.push(`${makeParentUrl(match.url)}/membersByDate?${query.toString()}`);
    },
    [query, history, match.url]
  );

  return (
    <>
      <MuiDatePicker
        autoOk={true}
        onChange={onChange}
        name="date"
        variant="inline"
        value={date}
        disableFuture={true}
        disableToolbar={false}
        format="dd.MM.yyyy"
        margin="dense"
        TextFieldComponent={CustomInputForDatePicker as React.ComponentType<TextFieldProps>}
      />
      <Box mb={6} overflow="auto" height={400}>
        <MembersByDateList />
      </Box>
    </>
  );
}

const CustomInputForDatePicker = React.forwardRef((props, ref) => {
  const { helperText, InputProps, inputProps, ...restProps } = props as any;
  return (
    <CustomInput
      ref={ref}
      {...inputProps}
      {...InputProps}
      {...restProps}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="calendar" size="small">
            <CalendarIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      }
    />
  );
});
