import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { useErrorCatcher } from 'api/notifications';
import {
  PersonDeleteProvider,
  PersonDetailsWithUpdateProvider,
  PersonMutatorsProvider,
  usePersonDetailsForUpdateProvider,
} from 'api/PersonsProvider';
import { GroupsListProvider } from 'api/GroupsProvider';
import { PersonFoldersProvider, usePersonFoldersProvider } from 'api/PersonsProvider/PersonFoldersProvider';

import { Modal, Spinner } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { PersonRequiredRouterParams } from '../interfaces';
import { PersonForm, PersonFormValuesType } from './PersonForm/PersonForm';
import { DeletePersonConfirmationDialog } from './DeletePersonConfirmationDialog';

import { BusinessUnitShowProvider } from 'api/BusinessUnitProviders';
import { BusinessUnitShowConsumer } from 'api/BusinessUnitProviders/BusinessUnitShowProvider';
import { usePersonMutation } from './PersonDialogsUtils';
import { BusinessUnitType } from 'api/BusinessUnitProviders/constants';

interface UpdatePersonDialogProps {
  routeProps: RouteComponentProps;
  redirectOnDelete?: () => void;
}

export function UpdatePersonDialog({ routeProps, redirectOnDelete }: UpdatePersonDialogProps) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<PersonRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const personId = parseInt(match.params.personId || '', 10);

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url, 3));

  return (
    <Modal
      title={t('persons:dialog.updateTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="md"
      fullWidth={true}
      data-test={UpdatePersonDialog.name}
    >
      <BusinessUnitShowProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
        <BusinessUnitShowConsumer>
          {res =>
            res?.data?.type ? (
              <PersonFoldersProvider
                businessUnitId={businessUnitId}
                personId={personId}
                queryOptions={{ onError: catchError }}
              >
                <PersonDetailsWithUpdateProvider businessUnitId={businessUnitId} personId={personId}>
                  <PersonMutatorsProvider
                    businessUnitId={businessUnitId}
                    structType={res.data.type}
                    queryOptions={{ onError: catchError }}
                  >
                    <PersonDeleteProvider
                      businessUnitId={businessUnitId}
                      personId={personId}
                      queryOptions={{ onError: catchError }}
                    >
                      <GroupsListProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
                        <PersonUpdate
                          redirectOnDelete={redirectOnDelete}
                          structType={res.data.type}
                          businessUnitId={businessUnitId}
                        />
                      </GroupsListProvider>
                    </PersonDeleteProvider>
                  </PersonMutatorsProvider>
                </PersonDetailsWithUpdateProvider>
              </PersonFoldersProvider>
            ) : (
              <Spinner></Spinner>
            )
          }
        </BusinessUnitShowConsumer>
      </BusinessUnitShowProvider>
    </Modal>
  );
}

function PersonUpdate({
  redirectOnDelete,
  structType,
  businessUnitId,
}: {
  redirectOnDelete?: () => void;
  structType: BusinessUnitType;
  businessUnitId: number;
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    controllers: {
      folders: { data: folders, isFetched: areFoldersFetched, isLoading: areFoldersLoading },
    },
  } = usePersonFoldersProvider();

  const { submitUpdate } = usePersonMutation();

  const { data: person, isFetched, isLoading } = usePersonDetailsForUpdateProvider();

  const [openConfirm, setOpenConfirm] = useState(false);

  const showConfirmDialog = useCallback(event => {
    setOpenConfirm(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const onSubmit = useCallback(
    (formValues: PersonFormValuesType) => {
      if (folders) {
        submitUpdate(formValues, folders.personFolders).then(() => goBackOrReplace(makeParentUrl(match.url, 3)));
      }
    },
    [folders, submitUpdate, match.url]
  );

  const onCancel = useCallback(() => {
    goBackOrReplace(makeParentUrl(match.url, 3));
  }, [match.url]);

  const onDelete = useCallback(() => {
    closeConfirmDialog();
    if (redirectOnDelete) {
      redirectOnDelete();
    } else {
      history.push({ pathname: makeParentUrl(match.url, 3), search: history.location.search });
    }
  }, [closeConfirmDialog, redirectOnDelete, history, match.url]);

  if (isLoading || areFoldersLoading) {
    return <Spinner />;
  }

  if (!isFetched || !areFoldersFetched) {
    return null;
  }
  return (
    <>
      <PersonForm
        businessUnitId={businessUnitId}
        structType={structType}
        initialValues={person}
        foldersInitialValues={folders?.personFolders}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onRemove={showConfirmDialog}
        submitLabel={t('persons:dialog.save')}
        cancelLabel={t('persons:dialog.cancel')}
        removeLabel={t('persons:dialog.remove')}
      />
      <DeletePersonConfirmationDialog open={openConfirm} onDelete={onDelete} onClose={closeConfirmDialog} />
    </>
  );
}
