import React, { useState, useRef, useCallback } from 'react';

import { Link, useLocation } from 'react-router-dom';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

export function AddEntityButton() {
  const { t } = useTranslation();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);

  const handleToggleActionMenu = useCallback(event => {
    event.preventDefault();
    setOpen(prevOpen => !prevOpen);
  }, []);

  const handleCloseActionMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const classes = useStyles();

  return (
    <>
      <Button variant="contained" color="primary" size="small" ref={anchorButtonRef} onClick={handleToggleActionMenu}>
        {`+ ${t('documents:add')}`}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorButtonRef.current}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            offset: '0, +8px',
          },
        }}
        placement="bottom-end"
        disablePortal={true}
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseActionMenu}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown} classes={{ root: classes.list }}>
                  <MenuItem
                    className={classes.menuItem}
                    component={Link}
                    to={`${location.pathname}/add_folder`}
                    onClick={handleCloseActionMenu}
                    data-test="AddFolderLink"
                  >
                    {t('documents:contextMenu.folder')}
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.menuItem}
                    component={Link}
                    to={`${location.pathname}/add_document`}
                    onClick={handleCloseActionMenu}
                    data-test="AddDocumentLink"
                  >
                    {t('documents:contextMenu.document')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  actionsButton: {
    marginRight: theme.spacing(-2),
  },
  popper: {
    zIndex: 500,
  },
  list: {
    padding: 0,
    borderRadius: 8,
  },
  menuItem: {
    padding: theme.spacing(1.5, 2),
    minWidth: 125,
  },
}));
