import React, { useCallback, useRef, useState } from 'react';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { MemberPositionDeleteProvider, useMemberPositionDeleteProvider } from 'api/PersonsProvider';
import { ContentItemView } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { PencilIcon, TrashIcon } from 'components/icons';
import { ConfirmDialog, DeleteConfirmDialog } from 'pages/All/ConfirmDialogs';

import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

interface MemberPositionActionsButtonProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  member: ContentItemView;
}

export function MemberPositionActionsButton({
  businessUnitId,
  sectionId,
  groupFolderId,
  member,
}: MemberPositionActionsButtonProps) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const [open, setOpen] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);

  const [openConfirmRemove, setOpenConfirmRemove] = useState(false);
  const [openImpossibleToRemove, setOpenImpossibleToRemove] = useState(false);

  const handleToggleActionMenu = useCallback(event => {
    event.preventDefault();
    setOpen(prevOpen => !prevOpen);
  }, []);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleCloseActionMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const showConfirmDialog = useCallback(
    event => {
      event.stopPropagation();
      setOpen(false);
      if (isEmpty(member.children)) {
        setOpenConfirmRemove(true);
      } else {
        setOpenImpossibleToRemove(true);
      }
    },
    [member]
  );

  return (
    <Box>
      <IconButton edge="end" ref={anchorButtonRef} onClick={handleToggleActionMenu}>
        <MoreVertIcon style={{ color: '#C2C6D2' }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorButtonRef.current}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            offset: '0, +8px',
          },
        }}
        placement="bottom-end"
        style={{ zIndex: 1300 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <MemberPositionActionsMenu
                groupFolderId={groupFolderId}
                member={member}
                open={open}
                onClose={handleCloseActionMenu}
                onDelete={showConfirmDialog}
                onKeyDown={handleListKeyDown}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
      <ConfirmDialog
        open={openImpossibleToRemove}
        title={t('persons:deletePersonFromGroupWithChildrenConfirmDialog.title')}
        confirmText={t('persons:deletePersonFromGroupWithChildrenConfirmDialog.text')}
        handleSubmit={noop}
        onClose={() => setOpenImpossibleToRemove(false)}
        primaryButtonDisabled={false}
      />
      <MemberPositionDeleteProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        groupFolderId={groupFolderId}
        personFolderId={member.id}
        queryOptions={{ onError: catchError }}
      >
        <MemberPositionDeleteDialog open={openConfirmRemove} onClose={() => setOpenConfirmRemove(false)} />
      </MemberPositionDeleteProvider>
    </Box>
  );
}

interface MemberPositionActionsMenuProps {
  groupFolderId: number;
  member: ContentItemView;
  open: boolean;
  onClose: (event: React.MouseEvent<EventTarget>) => void;
  onDelete: (event: React.MouseEvent<EventTarget>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLUListElement> | undefined;
}

function MemberPositionActionsMenu({
  groupFolderId,
  member,
  open,
  onClose,
  onDelete,
  onKeyDown,
}: MemberPositionActionsMenuProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const classes = useStyles();

  const goToEditMembersPositionHandler = useCallback(
    event => {
      const query = new URLSearchParams(history.location.search);
      query.set('groupFolderId', groupFolderId.toString());
      query.set('personFolderId', member.id.toString());
      onClose(event);
      history.push(`${url}/edit_member_position?${query.toString()}`);
    },
    [history, groupFolderId, url, member, onClose]
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MenuList autoFocusItem={open} onKeyDown={onKeyDown} classes={{ root: classes.list }}>
        <MenuItem
          className={classes.menuItem}
          onClick={goToEditMembersPositionHandler}
          data-test="EditMemberPositionButton"
        >
          <ListItemIcon>
            <PencilIcon fontSize="small" />
          </ListItemIcon>
          {t('persons:actionsMenu.editPosition')}
        </MenuItem>
        <MenuItem
          className={classNames(classes.menuItem, classes.menuItemWarn)}
          onClick={onDelete}
          data-test="DeleteMemberPositionButton"
        >
          <ListItemIcon>
            <TrashIcon fontSize="small" color="error" />
          </ListItemIcon>
          {t('persons:actionsMenu.removePosition')}
        </MenuItem>
      </MenuList>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
    borderRadius: 8,
  },
  menuItem: {
    padding: theme.spacing(1.5, 2),
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    minWidth: 230,
  },
  menuItemWarn: {
    color: theme.palette.error.main,
  },
}));

function MemberPositionDeleteDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const { mutateAsync: deleteMemberPosition } = useMemberPositionDeleteProvider();
  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={() => deleteMemberPosition()}
      title={t('persons:deletePersonFromGroupConfirmDialog.title')}
      confirmText={t('persons:deletePersonFromGroupConfirmDialog.text')}
      primaryButtonDisabled={false}
    />
  );
}
