import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import keys from 'lodash/keys';

import accountsEn from './locales/en/accounts.json';
import businessUnitEn from './locales/en/businessUnit.json';
import commonEn from './locales/en/common.json';
import companiesEn from './locales/en/companies.json';
import copyrightEn from './locales/en/copyright.json';
import dialogsEn from './locales/en/dialogs.json';
import documentsEn from './locales/en/documents.json';
import formEn from './locales/en/form.json';
import groupsEn from './locales/en/groups.json';
import loginEn from './locales/en/login.json';
import mobileAppsEn from './locales/en/mobileApps.json';
import notificationEn from './locales/en/notification.json';
import personsEn from './locales/en/persons.json';
import projectsEn from './locales/en/projects.json';

import accountsRu from './locales/ru/accounts.json';
import businessUnitRu from './locales/ru/businessUnit.json';
import commonRu from './locales/ru/common.json';
import companiesRu from './locales/ru/companies.json';
import copyrightRu from './locales/ru/copyright.json';
import dialogsRu from './locales/ru/dialogs.json';
import documentsRu from './locales/ru/documents.json';
import formRu from './locales/ru/form.json';
import groupsRu from './locales/ru/groups.json';
import loginRu from './locales/ru/login.json';
import mobileAppsRu from './locales/ru/mobileApps.json';
import notificationRu from './locales/ru/notification.json';
import personsRu from './locales/ru/persons.json';
import projectsRu from './locales/ru/projects.json';

export const resources = {
  en: {
    accounts: accountsEn,
    businessUnit: businessUnitEn,
    common: commonEn,
    companies: companiesEn,
    copyright: copyrightEn,
    dialogs: dialogsEn,
    documents: documentsEn,
    form: formEn,
    groups: groupsEn,
    login: loginEn,
    mobileApps: mobileAppsEn,
    notification: notificationEn,
    persons: personsEn,
    projects: projectsEn,
  },
  ru: {
    accounts: accountsRu,
    businessUnit: businessUnitRu,
    common: commonRu,
    companies: companiesRu,
    copyright: copyrightRu,
    dialogs: dialogsRu,
    documents: documentsRu,
    form: formRu,
    groups: groupsRu,
    login: loginRu,
    mobileApps: mobileAppsRu,
    notification: notificationRu,
    persons: personsRu,
    projects: projectsRu,
  },
} as const;

const options = {
  order: ['localStorage', 'querystring', 'navigator'],
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    supportedLngs: ['ru', 'en'],
    detection: options,
    interpolation: {
      escapeValue: false,
    },
    resources,
    ns: keys(resources.en),
  });

export default i18n;
