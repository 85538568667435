import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

export function SearchResult({ onHideMore, onShowMore, title, hasMore, showMore }: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        {hasMore && (
          <Box>
            {!showMore && (
              <Typography
                onClick={onShowMore}
                variant="caption"
                color="textSecondary"
                className={classes.showMoreButton}
              >
                {t('common:searchResults.showMore')}
              </Typography>
            )}
            {showMore && (
              <Typography
                onClick={onHideMore}
                variant="caption"
                color="textSecondary"
                className={classes.showMoreButton}
              >
                {t('common:searchResults.hide')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  showMoreButton: {
    cursor: 'pointer',
  },
}));
