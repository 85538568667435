/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CodeLoginRequest } from '../views';
// @ts-ignore
import { CodeLoginResponse } from '../views';
// @ts-ignore
import { CompleteLoginRequest } from '../views';
// @ts-ignore
import { LogoutRequest } from '../views';
// @ts-ignore
import { PhoneLoginRequest } from '../views';
// @ts-ignore
import { PhoneOnlyLoginResponse } from '../views';
// @ts-ignore
import { PinLoginRequest } from '../views';
// @ts-ignore
import { UrlView } from '../views';
// @ts-ignore
import { UserSessionStateView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Getting mobile app download link: expected `phone`, `otp`, `nonce`
     * @param {CodeLoginRequest} codeLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadLink: async (codeLoginRequest: CodeLoginRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'codeLoginRequest' is not null or undefined
      assertParamExists('getDownloadLink', 'codeLoginRequest', codeLoginRequest);
      const localVarPath = `/public/login/downloadLink`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(codeLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logouts a user from sessions and stops notifications for associated push-channels
     * @param {LogoutRequest} logoutRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (
      logoutRequest: LogoutRequest,
      applicationPlatform?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoutRequest' is not null or undefined
      assertParamExists('logout', 'logoutRequest', logoutRequest);
      const localVarPath = `/logout/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (applicationPlatform !== undefined && applicationPlatform !== null) {
        localVarHeaderParameter['Application-Platform'] = String(applicationPlatform);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(logoutRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logouts a user from sessions and stops notifications for associated push-channels
     * @param {LogoutRequest} logoutRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout1: async (
      logoutRequest: LogoutRequest,
      applicationPlatform?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoutRequest' is not null or undefined
      assertParamExists('logout1', 'logoutRequest', logoutRequest);
      const localVarPath = `/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (applicationPlatform !== undefined && applicationPlatform !== null) {
        localVarHeaderParameter['Application-Platform'] = String(applicationPlatform);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(logoutRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login CODE stage: expected `phone`, `otp`, `nonce`
     * @param {CodeLoginRequest} codeLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCode: async (codeLoginRequest: CodeLoginRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'codeLoginRequest' is not null or undefined
      assertParamExists('processCode', 'codeLoginRequest', codeLoginRequest);
      const localVarPath = `/public/login/code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(codeLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login COMPLETE stage: expected `phone`, `pin`, `otp`, `nonce`
     * @param {CompleteLoginRequest} completeLoginRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processComplete: async (
      completeLoginRequest: CompleteLoginRequest,
      applicationPlatform?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'completeLoginRequest' is not null or undefined
      assertParamExists('processComplete', 'completeLoginRequest', completeLoginRequest);
      const localVarPath = `/public/login/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (applicationPlatform !== undefined && applicationPlatform !== null) {
        localVarHeaderParameter['Application-Platform'] = String(applicationPlatform);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(completeLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login PHONE stage. Expected fields: `phone`
     * @param {PhoneLoginRequest} phoneLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processPhone: async (phoneLoginRequest: PhoneLoginRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'phoneLoginRequest' is not null or undefined
      assertParamExists('processPhone', 'phoneLoginRequest', phoneLoginRequest);
      const localVarPath = `/public/login/phone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(phoneLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login PIN stage. Expected `pin` filed and valid session id
     * @param {PinLoginRequest} pinLoginRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processPin: async (
      pinLoginRequest: PinLoginRequest,
      applicationPlatform?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'pinLoginRequest' is not null or undefined
      assertParamExists('processPin', 'pinLoginRequest', pinLoginRequest);
      const localVarPath = `/public/login/pin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (applicationPlatform !== undefined && applicationPlatform !== null) {
        localVarHeaderParameter['Application-Platform'] = String(applicationPlatform);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pinLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Getting mobile app download link: expected `phone`, `otp`, `nonce`
     * @param {CodeLoginRequest} codeLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadLink(
      codeLoginRequest: CodeLoginRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadLink(codeLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Logouts a user from sessions and stops notifications for associated push-channels
     * @param {LogoutRequest} logoutRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      logoutRequest: LogoutRequest,
      applicationPlatform?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(logoutRequest, applicationPlatform, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Logouts a user from sessions and stops notifications for associated push-channels
     * @param {LogoutRequest} logoutRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout1(
      logoutRequest: LogoutRequest,
      applicationPlatform?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout1(logoutRequest, applicationPlatform, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Login CODE stage: expected `phone`, `otp`, `nonce`
     * @param {CodeLoginRequest} codeLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async processCode(
      codeLoginRequest: CodeLoginRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeLoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.processCode(codeLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Login COMPLETE stage: expected `phone`, `pin`, `otp`, `nonce`
     * @param {CompleteLoginRequest} completeLoginRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async processComplete(
      completeLoginRequest: CompleteLoginRequest,
      applicationPlatform?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionStateView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.processComplete(
        completeLoginRequest,
        applicationPlatform,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Login PHONE stage. Expected fields: `phone`
     * @param {PhoneLoginRequest} phoneLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async processPhone(
      phoneLoginRequest: PhoneLoginRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneOnlyLoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.processPhone(phoneLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Login PIN stage. Expected `pin` filed and valid session id
     * @param {PinLoginRequest} pinLoginRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async processPin(
      pinLoginRequest: PinLoginRequest,
      applicationPlatform?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionStateView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.processPin(
        pinLoginRequest,
        applicationPlatform,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LoginApiFp(configuration);
  return {
    /**
     *
     * @summary Getting mobile app download link: expected `phone`, `otp`, `nonce`
     * @param {CodeLoginRequest} codeLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadLink(codeLoginRequest: CodeLoginRequest, options?: any): AxiosPromise<UrlView> {
      return localVarFp.getDownloadLink(codeLoginRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Logouts a user from sessions and stops notifications for associated push-channels
     * @param {LogoutRequest} logoutRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(logoutRequest: LogoutRequest, applicationPlatform?: string, options?: any): AxiosPromise<object> {
      return localVarFp.logout(logoutRequest, applicationPlatform, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Logouts a user from sessions and stops notifications for associated push-channels
     * @param {LogoutRequest} logoutRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout1(logoutRequest: LogoutRequest, applicationPlatform?: string, options?: any): AxiosPromise<object> {
      return localVarFp.logout1(logoutRequest, applicationPlatform, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Login CODE stage: expected `phone`, `otp`, `nonce`
     * @param {CodeLoginRequest} codeLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCode(codeLoginRequest: CodeLoginRequest, options?: any): AxiosPromise<CodeLoginResponse> {
      return localVarFp.processCode(codeLoginRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Login COMPLETE stage: expected `phone`, `pin`, `otp`, `nonce`
     * @param {CompleteLoginRequest} completeLoginRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processComplete(
      completeLoginRequest: CompleteLoginRequest,
      applicationPlatform?: string,
      options?: any
    ): AxiosPromise<UserSessionStateView> {
      return localVarFp
        .processComplete(completeLoginRequest, applicationPlatform, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Login PHONE stage. Expected fields: `phone`
     * @param {PhoneLoginRequest} phoneLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processPhone(phoneLoginRequest: PhoneLoginRequest, options?: any): AxiosPromise<PhoneOnlyLoginResponse> {
      return localVarFp.processPhone(phoneLoginRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Login PIN stage. Expected `pin` filed and valid session id
     * @param {PinLoginRequest} pinLoginRequest
     * @param {string} [applicationPlatform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processPin(
      pinLoginRequest: PinLoginRequest,
      applicationPlatform?: string,
      options?: any
    ): AxiosPromise<UserSessionStateView> {
      return localVarFp
        .processPin(pinLoginRequest, applicationPlatform, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * LoginApi - interface
 * @export
 * @interface LoginApi
 */
export interface LoginApiInterface {
  /**
   *
   * @summary Getting mobile app download link: expected `phone`, `otp`, `nonce`
   * @param {CodeLoginRequest} codeLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  getDownloadLink(codeLoginRequest: CodeLoginRequest, options?: any): AxiosPromise<UrlView>;

  /**
   *
   * @summary Logouts a user from sessions and stops notifications for associated push-channels
   * @param {LogoutRequest} logoutRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  logout(logoutRequest: LogoutRequest, applicationPlatform?: string, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Logouts a user from sessions and stops notifications for associated push-channels
   * @param {LogoutRequest} logoutRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  logout1(logoutRequest: LogoutRequest, applicationPlatform?: string, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Login CODE stage: expected `phone`, `otp`, `nonce`
   * @param {CodeLoginRequest} codeLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  processCode(codeLoginRequest: CodeLoginRequest, options?: any): AxiosPromise<CodeLoginResponse>;

  /**
   *
   * @summary Login COMPLETE stage: expected `phone`, `pin`, `otp`, `nonce`
   * @param {CompleteLoginRequest} completeLoginRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  processComplete(
    completeLoginRequest: CompleteLoginRequest,
    applicationPlatform?: string,
    options?: any
  ): AxiosPromise<UserSessionStateView>;

  /**
   *
   * @summary Login PHONE stage. Expected fields: `phone`
   * @param {PhoneLoginRequest} phoneLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  processPhone(phoneLoginRequest: PhoneLoginRequest, options?: any): AxiosPromise<PhoneOnlyLoginResponse>;

  /**
   *
   * @summary Login PIN stage. Expected `pin` filed and valid session id
   * @param {PinLoginRequest} pinLoginRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApiInterface
   */
  processPin(
    pinLoginRequest: PinLoginRequest,
    applicationPlatform?: string,
    options?: any
  ): AxiosPromise<UserSessionStateView>;
}

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI implements LoginApiInterface {
  /**
   *
   * @summary Getting mobile app download link: expected `phone`, `otp`, `nonce`
   * @param {CodeLoginRequest} codeLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public getDownloadLink(codeLoginRequest: CodeLoginRequest, options?: any) {
    return LoginApiFp(this.configuration)
      .getDownloadLink(codeLoginRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logouts a user from sessions and stops notifications for associated push-channels
   * @param {LogoutRequest} logoutRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public logout(logoutRequest: LogoutRequest, applicationPlatform?: string, options?: any) {
    return LoginApiFp(this.configuration)
      .logout(logoutRequest, applicationPlatform, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logouts a user from sessions and stops notifications for associated push-channels
   * @param {LogoutRequest} logoutRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public logout1(logoutRequest: LogoutRequest, applicationPlatform?: string, options?: any) {
    return LoginApiFp(this.configuration)
      .logout1(logoutRequest, applicationPlatform, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login CODE stage: expected `phone`, `otp`, `nonce`
   * @param {CodeLoginRequest} codeLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public processCode(codeLoginRequest: CodeLoginRequest, options?: any) {
    return LoginApiFp(this.configuration)
      .processCode(codeLoginRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login COMPLETE stage: expected `phone`, `pin`, `otp`, `nonce`
   * @param {CompleteLoginRequest} completeLoginRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public processComplete(completeLoginRequest: CompleteLoginRequest, applicationPlatform?: string, options?: any) {
    return LoginApiFp(this.configuration)
      .processComplete(completeLoginRequest, applicationPlatform, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login PHONE stage. Expected fields: `phone`
   * @param {PhoneLoginRequest} phoneLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public processPhone(phoneLoginRequest: PhoneLoginRequest, options?: any) {
    return LoginApiFp(this.configuration)
      .processPhone(phoneLoginRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login PIN stage. Expected `pin` filed and valid session id
   * @param {PinLoginRequest} pinLoginRequest
   * @param {string} [applicationPlatform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginApi
   */
  public processPin(pinLoginRequest: PinLoginRequest, applicationPlatform?: string, options?: any) {
    return LoginApiFp(this.configuration)
      .processPin(pinLoginRequest, applicationPlatform, options)
      .then(request => request(this.axios, this.basePath));
  }
}
