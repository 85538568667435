import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { PaperIcon } from 'components/icons';
import { makeStyles } from '@material-ui/core';
import { DocumentActions } from './DocumentActions';
import { PREVIEW_FILE_URL_SEARCH_PARAM_NAME } from '../../All/DocumentsDialogs';
import { ApiConfiguration, getFileOfDocumentURL } from '../../../api/http';
import { PDF_VIEWER_PAGE_PATH } from '../../Router';
import { useErrorCatcher } from '../../../api/notifications';
import { DocumentControllerApiFactory } from '../../../api/generated';
import { useHistory, useRouteMatch } from 'react-router-dom';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

export function DocumentSearchResultItem({ document }: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const catchError = useErrorCatcher();
  const history = useHistory();
  const match = useRouteMatch();
  const businessUnitId = document.businessUnitId;
  const sectionId = document.sectionId;

  const openDocument = async () => {
    await DocumentApi.getDocumentById(businessUnitId, sectionId, document.id).then(
      ({ data: document }) => {
        if (document.pdfFileMeta) {
          const query = new URLSearchParams(history.location.search);
          query.set(
            PREVIEW_FILE_URL_SEARCH_PARAM_NAME,
            getFileOfDocumentURL(businessUnitId, sectionId, document.id, document.pdfFileMeta.id)
          );
          history.push(`${match.url}${PDF_VIEWER_PAGE_PATH}?${query.toString()}`, {
            title: `${document.title} ( ${document.pdfFileMeta.fileName} ) `,
          });
        } else if (document.originalFileMeta) {
          window.open(
            getFileOfDocumentURL(businessUnitId, sectionId, document.id, document.originalFileMeta.id),
            '_blank',
            'noopener,noreferrer'
          );
        }
        return document;
      },
      e => catchError(e)
    );
  };

  return (
    <>
      <Divider />
      <Box pt={2} pb={2}>
        <Box display="inline-block" className={classes.document}>
          <Box display="inline-block" className={classes.paper}>
            <PaperIcon fontSize="large" />
          </Box>
          <Box display="inline-block" style={{ opacity: document.isArchived ? 0.5 : 1 }}>
            <Box className={classes.documentName} onClick={openDocument}>
              {document.documentTitle}
            </Box>
            {document.originalFile && (
              <Box style={{ fontSize: 14 }}>
                <span>{document.originalFile.name}</span>
                <span className={classes.file}>
                  {(document.originalFile.size / 1000000).toFixed(2)} {t('common:searchResults.fileSize')}
                </span>
              </Box>
            )}
            {document.pdfFile && (
              <Box style={{ fontSize: 14 }}>
                <span>{document.pdfFile.name}</span>
                <span className={classes.file}>
                  {(document.pdfFile.size / 1000000).toFixed(2)} {t('common:searchResults.fileSize')}
                </span>
              </Box>
            )}
            <Box style={{ fontSize: 14, color: '#83899B' }}>
              <span>{document.businessUnitTitle}</span>
              {document.pathInfo.map((path: any) => (
                <span key={path.id}> → {path.title}</span>
              ))}
            </Box>
          </Box>
          <Box display="inline-block" className={classes.archived}>
            {document.isArchived && (
              <span className={classes.archivedFile}>{t('common:searchResults.archiveFile')}</span>
            )}
            {(document.pdfFile || document.originalFile) && (
              <span style={{ marginRight: 20 }}>
                <DocumentActions document={document} />
              </span>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  document: {
    width: '100%',
    verticalAlign: 'top',
    marginLeft: 10,
  },
  paper: {
    verticalAlign: 'top',
    marginTop: 25,
    marginRight: 10,
  },
  file: {
    fontSize: 12,
    color: '#83899B',
    marginLeft: 5,
  },
  archived: {
    verticalAlign: 'top',
    float: 'right',
    marginTop: 25,
  },
  archivedFile: {
    marginRight: 20,
    color: '#31323A',
    width: 65,
    height: 24,
    background: '#DAD8DB',
    textAlign: 'center',
    fontSize: 12,
    padding: 5,
    borderRadius: 4,
  },
  documentName: {
    fontSize: 18,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));
