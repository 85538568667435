import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import Box from '@material-ui/core/Box';

import {
  useFolderUpdateProvider,
  FolderUpdateProvider,
  useFolderInfoProvider,
  FolderInfoProvider,
} from 'api/DocumentProviders/Folders';
import { useErrorCatcher } from 'api/notifications';

import { Modal, Spinner } from 'components/material';
import { makeParentUrl } from 'utils';
import { FolderForm } from './FolderForm';
import { FolderRouterParams } from './interfaces';

export function EditFolderDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const match = useRouteMatch<FolderRouterParams>();
  const catchError = useErrorCatcher();

  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const folderId = parseInt(match.params.folderId || '', 10);

  // cutNumber = 3, т.к. переходим из companies/100/section/100/folder/103/edit_folder
  // к companies/100/section/100/
  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url, 3));

  return (
    <Modal
      title={t('documents:folder.dialog.folderCard')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={EditFolderDialog.name}
    >
      <FolderInfoProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        folderId={folderId}
        queryOptions={{ onError: catchError }}
      >
        <FolderUpdateProvider
          sectionId={sectionId}
          businessUnitId={businessUnitId}
          queryOptions={{ onError: catchError }}
        >
          <FolderUpdate />
        </FolderUpdateProvider>
      </FolderInfoProvider>
    </Modal>
  );
}

function FolderUpdate() {
  const match = useRouteMatch();
  const { mutateAsync } = useFolderUpdateProvider();
  const { data: section, isLoading, isFetched } = useFolderInfoProvider();
  const history = useHistory();
  const onSubmit = useCallback(
    values => {
      // cutNumber = 3, т.к. переходим из companies/100/section/100/folder/103/edit_folder
      // к companies/100/section/100/
      return mutateAsync(values).then(() => history.push(makeParentUrl(match.url, 3)));
    },
    [mutateAsync, history, match.url]
  );

  if (isLoading) {
    return (
      <Box height={296}>
        <Spinner />
      </Box>
    );
  }

  return section && isFetched ? <FolderForm onSubmit={onSubmit} initialValues={section} type="EDIT" /> : null;
}
