import { FileMetaView } from 'api/generated/views/file-meta-view';

import has from 'lodash/has';

export type FileMetaViewWithUrl = FileMetaView & { downloadUrl?: string };

export function isFileMetaView(object: object): object is FileMetaView {
  return has(object, 'id') && has(object, 'size') && has(object, 'createdDate');
}

export function isFileMetaViewWithUrl(object: object): object is FileMetaView {
  return isFileMetaView(object) && has(object, 'downloadUrl');
}
