import React, { useState } from 'react';
import { FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PinCodeFieldsForm } from './PinCodeFieldsForm';
import PinCodeIcon from 'components/icons/PinCodeIcon';

type CreatePinCodeSteps = 'ENTER_PIN_CODE' | 'CONFIRM_PIN_CODE';

interface ICreatePinCodeFormProps {
  onSubmit: FormProps['onSubmit'];
  loading: boolean;
}
export function CreatePinCodeForm({ onSubmit, loading }: ICreatePinCodeFormProps) {
  const { t } = useTranslation();

  const [step, setStep] = useState<CreatePinCodeSteps>('ENTER_PIN_CODE');

  const switchStep = () => {
    setStep(step === 'CONFIRM_PIN_CODE' ? 'ENTER_PIN_CODE' : 'CONFIRM_PIN_CODE');
  };

  // TODO исправить any
  const submit = (data: any, formProps: any) => {
    onSubmit(data, formProps);
    switchStep();
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <PinCodeIcon style={{ fontSize: 43, fill: 'none' }} />
      <Box mt={2.5} fontSize="h5.fontSize" fontWeight="bold">
        {t('login:pinCodeVerification')}
      </Box>
      <Box fontSize="body1.fontSize" mt={3} color="text.secondary" textAlign="center">
        <span>{t('login:createPinCode.itWillBeUsedFor')}</span>
      </Box>
      {loading ? (
        <Box pt={5}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {step === 'ENTER_PIN_CODE' ? (
            <PinCodeFieldsForm onSubmit={switchStep} name="firstPin" firstStep createPin />
          ) : (
            <PinCodeFieldsForm onSubmit={submit} switchStep={switchStep} firstStep={false} createPin />
          )}
          <Box fontSize="body1.fontSize" mt={2} color="text.secondary" textAlign="center">
            {step === 'ENTER_PIN_CODE' && <span>{t('login:createPinCode.useDigits')}</span>}
            {step === 'CONFIRM_PIN_CODE' && <span>{t('login:createPinCode.enterAgain')}</span>}
          </Box>
        </>
      )}
    </Box>
  );
}
