import React, { useCallback } from 'react';
import classnames from 'classnames';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useSortable, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { useSectionOrderUpdateProvider, useSectionsProvider } from 'api/SectionsProviders';
import { BusinessUnitSectionShortView } from 'api/generated';

import { Spinner } from 'components/material';

import { SectionItem } from './SectionItem';

import findIndex from 'lodash/findIndex';
import map from 'lodash/map';

export function SectionsList({ canEdit, canReOrder }: { canEdit: boolean; canReOrder: boolean }) {
  const { data: sections, isLoading, isFetching, isFetched } = useSectionsProvider();

  const {
    controller: { mutateAsync: updateOrder, isLoading: isUpdatingOrder },
  } = useSectionOrderUpdateProvider();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = useCallback(
    event => {
      const { active, over } = event;
      if (active.id !== over.id && !isUpdatingOrder) {
        const fromIndex = findIndex(sections, { sectionId: parseInt(active.id, 10) });
        const toIndex = findIndex(sections, { sectionId: parseInt(over.id, 10) });
        updateOrder({ from: fromIndex, to: toIndex });
      }
    },
    [isUpdatingOrder, sections, updateOrder]
  );

  const classes = useStyles();

  if (isLoading) {
    return <Spinner />;
  }

  if (sections?.length && isFetched) {
    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <Box className={classes.list}>
          <SortableContext
            id={SectionsList.name}
            items={map(sections, s => s.sectionId.toString())}
            strategy={rectSortingStrategy}
          >
            {sections.map(section => (
              <SortableSectionItem
                key={section.sectionId}
                section={section}
                canEdit={canEdit}
                hasDnd={canReOrder}
                disabledDnd={isUpdatingOrder || isFetching}
              />
            ))}
          </SortableContext>
        </Box>
      </DndContext>
    );
  }

  return null;
}

function SortableSectionItem({
  section,
  canEdit,
  hasDnd,
  disabledDnd,
}: {
  section: BusinessUnitSectionShortView;
  canEdit: boolean;
  hasDnd: boolean;
  disabledDnd: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: section.sectionId.toString(),
    disabled: disabledDnd || !hasDnd,
  });

  const classes = useStyles();
  const style = { transform: CSS.Transform.toString(transform), transition } as React.CSSProperties;
  return (
    <Box ref={setNodeRef} className={classnames(classes.item, { dragging: isDragging })} style={style} {...attributes}>
      {hasDnd && (
        <Box className={classnames(classes.dragIndicator, { dragging: isDragging })} {...listeners}>
          <DragIndicatorIcon />
        </Box>
      )}
      <SectionItem section={section} canEdit={canEdit} />
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'stretch',
    overflow: 'auto',
    padding: theme.spacing(0, 7),
  },
  item: {
    position: 'relative',
    margin: theme.spacing(1.5, 2),
    width: 'calc(50% - 32px)',
    '&.dragging': {
      zIndex: theme.zIndex.tooltip,
    },
  },
  dragIndicator: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 0,
    color: '#DAD8DB',
    cursor: 'grab',
    '&.dragging': {
      cursor: 'grabbing',
    },
  },
}));
