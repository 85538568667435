import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccessControllerApiFactory, AccessUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from '../constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

type CustomAccessUpdateRequest = AccessUpdateRequest & { sectionId: number; businessUnitId: number };

type SectionAccessProviderType = {
  updateAccess: UseMutationResult<object, AxiosError<unknown>, CustomAccessUpdateRequest>;
};

const SectionAccessUpdateContext = createContext<SectionAccessProviderType | null>(null);
SectionAccessUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}AccessUpdate`;

export function useSectionAccessUpdateProvider(): SectionAccessProviderType {
  const contextState = useContext(SectionAccessUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSectionAccessUpdateProvider.name} must be used within a ${SectionAccessUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface SectionAccessUpdateProviderProps {
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, CustomAccessUpdateRequest>;
}

/**
 * Provides mutator to update section access
 * @param props
 * @returns
 */
export function SectionAccessUpdateProvider(props: React.PropsWithChildren<SectionAccessUpdateProviderProps>) {
  const queryClient = useQueryClient();

  // Update access to a SPECIFIC business unit section for readers
  const updateAccess = useMutation<object, AxiosError<unknown>, CustomAccessUpdateRequest>(
    async (data: CustomAccessUpdateRequest) => {
      const sectionId = data.sectionId;
      if (isNil(sectionId)) {
        throw new Error(`${SectionAccessUpdateProvider.name} is missed 'sectionId' property`);
      }
      return AccessApi.updateAccessToBusinessUnitSections(
        data.businessUnitId,
        sectionId,
        omit(data, ['sectionId', 'businessUnitId'])
      ).then(resp => resp.data);
    },
    {
      onSuccess: (o, data) => {
        queryClient.invalidateQueries([
          RESOURCE_NAME,
          { businessUnitId: data.businessUnitId, sectionId: data.sectionId },
          'sectionAccess',
        ]);
        setTimeout(() => {
          queryClient.invalidateQueries([RESOURCE_NAME, { businessUnitId: data.businessUnitId }]);
        });
      },
      ...(props.queryOptions || {}),
    }
  );

  return (
    <SectionAccessUpdateContext.Provider
      value={{
        updateAccess,
      }}
    >
      {props.children}
    </SectionAccessUpdateContext.Provider>
  );
}
