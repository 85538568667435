/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Basic user information
 * @export
 * @interface UserShortView
 */
export interface UserShortView {
  /**
   * example: \'Иванов И. И.\' on rus or \'I.I. Ivanov\' on eng
   * @type {string}
   * @memberof UserShortView
   */
  displayName: string;
  /**
   *
   * @type {number}
   * @memberof UserShortView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserShortView
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof UserShortView
   */
  smsPhone?: string;
  /**
   *
   * @type {string}
   * @memberof UserShortView
   */
  state: UserShortViewStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserShortViewStateEnum {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED',
}
