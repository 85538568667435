import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitAccessUpdateRequest, AccountView, AccessControllerApiFactory } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

type BusinessUnitAccessDetailsProviderType = QueryObserverResult<BusinessUnitAccessUpdateRequest, unknown>;

const BusinessUnitDetailsWithUpdateContext = createContext<BusinessUnitAccessDetailsProviderType | null>(null);
BusinessUnitDetailsWithUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useBusinessUnitAccessDetailsProvider(): BusinessUnitAccessDetailsProviderType {
  const contextState = useContext(BusinessUnitDetailsWithUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitAccessDetailsProvider.name} must be used within a ${BusinessUnitDetailsWithUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface BusinessUnitAccessDetailsProviderProps {
  accountId: AccountView['id'];
}
export function BusinessUnitAccessDetailsProvider(
  props: React.PropsWithChildren<BusinessUnitAccessDetailsProviderProps>
) {
  const value = useQuery<BusinessUnitAccessUpdateRequest, AxiosError<unknown>, BusinessUnitAccessUpdateRequest>(
    [RESOURCE_NAME, { accountId: props.accountId }],
    () => AccessApi.getBusinessUnitAccessList(props.accountId).then(resp => resp.data)
  );
  return (
    <BusinessUnitDetailsWithUpdateContext.Provider value={value}>
      {props.children}
    </BusinessUnitDetailsWithUpdateContext.Provider>
  );
}
