/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Request for search persons
 * @export
 * @interface SearchPersonRequest
 */
export interface SearchPersonRequest {
  /**
   * language for searching by Russian or English
   * @type {string}
   * @memberof SearchPersonRequest
   */
  language: SearchPersonRequestLanguageEnum;
  /**
   * String containing part of the last name for searching
   * @type {string}
   * @memberof SearchPersonRequest
   */
  search: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchPersonRequestLanguageEnum {
  ENGLISH = 'ENGLISH',
  RUSSIAN = 'RUSSIAN',
}
