import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AccountsPage } from './AccountsPage';
import { AccountCreatePage } from './AccountCreatePage';
import { AccountDetailsPage } from './AccountDetailsPage';
import { CreateSecretaryDialog } from './CreateSecretaryDialog';
import { UpdateSecretaryDialog } from './UpdateSecretaryDialog';

export function AccountsRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`}>
        <AccountCreatePage />
      </Route>
      <Route path={`${path}/:accountId(\\d+)`}>
        <AccountDetailsPage />
        <Route
          path={`${path}/:accountId(\\d+)/add_secretary`}
          render={routeProps => <CreateSecretaryDialog routeProps={routeProps} />}
        />
        <Route
          path={`${path}/:accountId(\\d+)/secretary/:secretaryId(\\d+)`}
          render={routeProps => <UpdateSecretaryDialog routeProps={routeProps} />}
        />
      </Route>
      <Route path={[path, `${path}/list`]}>
        <AccountsPage />
      </Route>
    </Switch>
  );
}
