import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { GroupCreateProvider, useGroupCreateProvider } from 'api/GroupsProvider';
import { GroupFolderCreateRequest } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { GroupForm } from './GroupForm';
import { GroupRequiredRouterParams } from '../interfaces';
import { PARENT_FOLDER_SEARCH_PARAM_NAME } from '../constants';

export function CreateGroupDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<GroupRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url, 3));

  return (
    <Modal
      title={t('groups:dialog.createTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={CreateGroupDialog.name}
    >
      <GroupCreateProvider businessUnitId={businessUnitId} sectionId={sectionId} queryOptions={{ onError: catchError }}>
        <GroupCreate />
      </GroupCreateProvider>
    </Modal>
  );
}

function GroupCreate() {
  const location = useLocation();
  const match = useRouteMatch<GroupRequiredRouterParams>();
  const { t } = useTranslation();

  const query = new URLSearchParams(location.search);
  const groupFolderId = parseInt(query.get(PARENT_FOLDER_SEARCH_PARAM_NAME) || '', 10);

  const {
    controller: { mutateAsync, isLoading },
  } = useGroupCreateProvider();

  const onSubmit = useCallback(
    (values: GroupFolderCreateRequest) => {
      if (!isLoading) {
        return mutateAsync({ ...values, parentId: groupFolderId }).then(() => {
          goBackOrReplace(makeParentUrl(match.url));
        });
      }
    },
    [isLoading, mutateAsync, groupFolderId, match.url]
  );

  const onCancel = useCallback(() => {
    goBackOrReplace(makeParentUrl(match.url));
  }, [match.url]);

  return (
    <GroupForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel={t('groups:dialog.create')}
      cancelLabel={t('groups:dialog.cancel')}
    />
  );
}
