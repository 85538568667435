import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccessControllerApiFactory, AccountAccessView } from 'api/generated';

import { RESOURCE_NAME } from '../constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { SectionAccess } from './types';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

type SectionAccessProviderType = {
  businessUnitId: number;
  sectionId: number;
  sectionAccess: SectionAccess;
};

const SectionAccessContext = createContext<SectionAccessProviderType | null>(null);
SectionAccessContext.displayName = `${upperFirst(RESOURCE_NAME)}Access`;

export function useSectionAccessProvider(): SectionAccessProviderType {
  const contextState = useContext(SectionAccessContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSectionAccessProvider.name} must be used within a ${SectionAccessContext.displayName} context`
    );
  }
  return contextState;
}

interface SectionAccessProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: any;
}
/**
 * Provides users with access to the specified section.
 * @param props
 * @returns
 */
export function SectionAccessProvider(props: React.PropsWithChildren<SectionAccessProviderProps>) {
  // Get accesses to a SPECIFIC business unit section for all readers
  const sectionAccess: SectionAccess = {
    readerAccess: useQuery<AccountAccessView[], AxiosError<unknown>>(
      [RESOURCE_NAME, 'access', 'reader', { businessUnitId: props.businessUnitId, sectionId: props.sectionId }],
      () => {
        if (isNil(props.sectionId)) {
          throw new Error(`${SectionAccessProvider.name} is missed 'sectionId' property`);
        }
        return AccessApi.getBusinessUnitSectionReaderAccess2(props.businessUnitId, props.sectionId).then(
          resp => resp.data
        );
      },
      { enabled: !isNil(props.sectionId), ...(props.queryOptions || {}) }
    ),
    responsibleAccess: useQuery<AccountAccessView[], AxiosError<unknown>>(
      [RESOURCE_NAME, 'access', 'responsible', { businessUnitId: props.businessUnitId, sectionId: props.sectionId }],
      () => {
        if (isNil(props.sectionId)) {
          throw new Error(`${SectionAccessProvider.name} is missed 'sectionId' property`);
        }
        return AccessApi.getBusinessUnitSectionResponsibleAccess3(props.businessUnitId, props.sectionId).then(
          resp => resp.data
        );
      },
      { enabled: !isNil(props.sectionId), ...(props.queryOptions || {}) }
    ),
  };

  return (
    <SectionAccessContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        sectionId: props.sectionId,
        sectionAccess,
      }}
    >
      {props.children}
    </SectionAccessContext.Provider>
  );
}
