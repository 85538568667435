/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BusinessUnitSectionCreateRequest } from '../views';
// @ts-ignore
import { BusinessUnitSectionShortView } from '../views';
// @ts-ignore
import { BusinessUnitSectionUpdateRequest } from '../views';
// @ts-ignore
import { ContentItemView } from '../views';
// @ts-ignore
import { UpdateContentOrderRequest } from '../views';
// @ts-ignore
import { UpdateOrderNumRequest } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * BusinessUnitSectionControllerApi - axios parameter creator
 * @export
 */
export const BusinessUnitSectionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnitSection: async (
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createBusinessUnitSection', 'businessUnitId', businessUnitId);
      // verify required parameter 'businessUnitSectionCreateRequest' is not null or undefined
      assertParamExists(
        'createBusinessUnitSection',
        'businessUnitSectionCreateRequest',
        businessUnitSectionCreateRequest
      );
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitSectionCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnitSection1: async (
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createBusinessUnitSection1', 'businessUnitId', businessUnitId);
      // verify required parameter 'businessUnitSectionCreateRequest' is not null or undefined
      assertParamExists(
        'createBusinessUnitSection1',
        'businessUnitSectionCreateRequest',
        businessUnitSectionCreateRequest
      );
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitSectionCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnitSection2: async (
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createBusinessUnitSection2', 'businessUnitId', businessUnitId);
      // verify required parameter 'businessUnitSectionCreateRequest' is not null or undefined
      assertParamExists(
        'createBusinessUnitSection2',
        'businessUnitSectionCreateRequest',
        businessUnitSectionCreateRequest
      );
      const localVarPath = `/struct/ALL/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitSectionCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnitSection: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteBusinessUnitSection', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteBusinessUnitSection', 'sectionId', sectionId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnitSection1: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteBusinessUnitSection1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteBusinessUnitSection1', 'sectionId', sectionId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnitSection2: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteBusinessUnitSection2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteBusinessUnitSection2', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download icon for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconOfSection: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadIconOfSection', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('downloadIconOfSection', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/icon`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download preview file of the BusinessUnitSection by business unit id, section id, and fileMeta id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} fileMetaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPreviewFile: async (
      businessUnitId: number,
      sectionId: number,
      fileMetaId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadPreviewFile', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('downloadPreviewFile', 'sectionId', sectionId);
      // verify required parameter 'fileMetaId' is not null or undefined
      assertParamExists('downloadPreviewFile', 'fileMetaId', fileMetaId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/file/{fileMetaId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'fileMetaId'}}`, encodeURIComponent(String(fileMetaId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSection: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSection', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSection', 'sectionId', sectionId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSection1: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSection1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSection1', 'sectionId', sectionId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSection2: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSection2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSection2', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionUpdateRequest: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionUpdateRequest', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionUpdateRequest', 'sectionId', sectionId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/{sectionId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionUpdateRequest1: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionUpdateRequest1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionUpdateRequest1', 'sectionId', sectionId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/{sectionId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionUpdateRequest2: async (
      businessUnitId: number,
      sectionId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSectionUpdateRequest2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getBusinessUnitSectionUpdateRequest2', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSections: async (
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSections', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSections1: async (
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSections1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSections2: async (
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitSections2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get section/folder content with a possibility to get all hierarchy levels
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {boolean} [allHierarchyLevels]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContent: async (
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      allHierarchyLevels?: boolean,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getContent', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getContent', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/content`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (parentFolderId !== undefined) {
        localVarQueryParameter['parentFolderId'] = parentFolderId;
      }

      if (allHierarchyLevels !== undefined) {
        localVarQueryParameter['allHierarchyLevels'] = allHierarchyLevels;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentOld: async (
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getContentOld', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getContentOld', 'sectionId', sectionId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (parentFolderId !== undefined) {
        localVarQueryParameter['parentFolderId'] = parentFolderId;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentOld1: async (
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getContentOld1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getContentOld1', 'sectionId', sectionId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (parentFolderId !== undefined) {
        localVarQueryParameter['parentFolderId'] = parentFolderId;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentOld2: async (
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getContentOld2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getContentOld2', 'sectionId', sectionId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (parentFolderId !== undefined) {
        localVarQueryParameter['parentFolderId'] = parentFolderId;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSection: async (
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitSection', 'businessUnitId', businessUnitId);
      // verify required parameter 'businessUnitSectionUpdateRequest' is not null or undefined
      assertParamExists(
        'updateBusinessUnitSection',
        'businessUnitSectionUpdateRequest',
        businessUnitSectionUpdateRequest
      );
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitSectionUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSection1: async (
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitSection1', 'businessUnitId', businessUnitId);
      // verify required parameter 'businessUnitSectionUpdateRequest' is not null or undefined
      assertParamExists(
        'updateBusinessUnitSection1',
        'businessUnitSectionUpdateRequest',
        businessUnitSectionUpdateRequest
      );
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitSectionUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSection2: async (
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitSection2', 'businessUnitId', businessUnitId);
      // verify required parameter 'businessUnitSectionUpdateRequest' is not null or undefined
      assertParamExists(
        'updateBusinessUnitSection2',
        'businessUnitSectionUpdateRequest',
        businessUnitSectionUpdateRequest
      );
      const localVarPath = `/struct/ALL/{businessUnitId}/section`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessUnitSectionUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSectionOrder: async (
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitSectionOrder', 'businessUnitId', businessUnitId);
      // verify required parameter 'updateOrderNumRequest' is not null or undefined
      assertParamExists('updateBusinessUnitSectionOrder', 'updateOrderNumRequest', updateOrderNumRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/order`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateOrderNumRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSectionOrder1: async (
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitSectionOrder1', 'businessUnitId', businessUnitId);
      // verify required parameter 'updateOrderNumRequest' is not null or undefined
      assertParamExists('updateBusinessUnitSectionOrder1', 'updateOrderNumRequest', updateOrderNumRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/order`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateOrderNumRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSectionOrder2: async (
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitSectionOrder2', 'businessUnitId', businessUnitId);
      // verify required parameter 'updateOrderNumRequest' is not null or undefined
      assertParamExists('updateBusinessUnitSectionOrder2', 'updateOrderNumRequest', updateOrderNumRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/order`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateOrderNumRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update content order and place
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {UpdateContentOrderRequest} updateContentOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContentOrderAndPlace: async (
      businessUnitId: number,
      sectionId: number,
      updateContentOrderRequest: UpdateContentOrderRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateContentOrderAndPlace', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateContentOrderAndPlace', 'sectionId', sectionId);
      // verify required parameter 'updateContentOrderRequest' is not null or undefined
      assertParamExists('updateContentOrderAndPlace', 'updateContentOrderRequest', updateContentOrderRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/{sectionId}/content/order`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateContentOrderRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BusinessUnitSectionControllerApi - functional programming interface
 * @export
 */
export const BusinessUnitSectionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BusinessUnitSectionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBusinessUnitSection(
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBusinessUnitSection(
        businessUnitId,
        businessUnitSectionCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBusinessUnitSection1(
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBusinessUnitSection1(
        businessUnitId,
        businessUnitSectionCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBusinessUnitSection2(
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBusinessUnitSection2(
        businessUnitId,
        businessUnitSectionCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessUnitSection(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessUnitSection(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessUnitSection1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessUnitSection1(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessUnitSection2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessUnitSection2(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download icon for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadIconOfSection(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIconOfSection(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download preview file of the BusinessUnitSection by business unit id, section id, and fileMeta id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} fileMetaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadPreviewFile(
      businessUnitId: number,
      sectionId: number,
      fileMetaId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPreviewFile(
        businessUnitId,
        sectionId,
        fileMetaId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSection(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionShortView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSection(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSection1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionShortView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSection1(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSection2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionShortView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSection2(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionUpdateRequest(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionUpdateRequest(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionUpdateRequest1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionUpdateRequest1(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSectionUpdateRequest2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSectionUpdateRequest2(
        businessUnitId,
        sectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSections(
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitSectionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSections(
        businessUnitId,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSections1(
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitSectionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSections1(
        businessUnitId,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitSections2(
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitSectionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitSections2(
        businessUnitId,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get section/folder content with a possibility to get all hierarchy levels
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {boolean} [allHierarchyLevels]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContent(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      allHierarchyLevels?: boolean,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentItemView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContent(
        businessUnitId,
        sectionId,
        parentFolderId,
        allHierarchyLevels,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContentOld(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentItemView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContentOld(
        businessUnitId,
        sectionId,
        parentFolderId,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContentOld1(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentItemView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContentOld1(
        businessUnitId,
        sectionId,
        parentFolderId,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContentOld2(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentItemView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContentOld2(
        businessUnitId,
        sectionId,
        parentFolderId,
        skip,
        limit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitSection(
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitSection(
        businessUnitId,
        businessUnitSectionUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitSection1(
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitSection1(
        businessUnitId,
        businessUnitSectionUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitSection2(
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitSectionUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitSection2(
        businessUnitId,
        businessUnitSectionUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitSectionOrder(
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitSectionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitSectionOrder(
        businessUnitId,
        updateOrderNumRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitSectionOrder1(
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitSectionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitSectionOrder1(
        businessUnitId,
        updateOrderNumRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitSectionOrder2(
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessUnitSectionShortView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitSectionOrder2(
        businessUnitId,
        updateOrderNumRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update content order and place
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {UpdateContentOrderRequest} updateContentOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContentOrderAndPlace(
      businessUnitId: number,
      sectionId: number,
      updateContentOrderRequest: UpdateContentOrderRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentItemView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContentOrderAndPlace(
        businessUnitId,
        sectionId,
        updateContentOrderRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BusinessUnitSectionControllerApi - factory interface
 * @export
 */
export const BusinessUnitSectionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BusinessUnitSectionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnitSection(
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .createBusinessUnitSection(businessUnitId, businessUnitSectionCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnitSection1(
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .createBusinessUnitSection1(businessUnitId, businessUnitSectionCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessUnitSection2(
      businessUnitId: number,
      businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .createBusinessUnitSection2(businessUnitId, businessUnitSectionCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnitSection(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteBusinessUnitSection(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnitSection1(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteBusinessUnitSection1(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete business unit section
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessUnitSection2(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteBusinessUnitSection2(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download icon for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconOfSection(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .downloadIconOfSection(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download preview file of the BusinessUnitSection by business unit id, section id, and fileMeta id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} fileMetaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPreviewFile(
      businessUnitId: number,
      sectionId: number,
      fileMetaId: number,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .downloadPreviewFile(businessUnitId, sectionId, fileMetaId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSection(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<BusinessUnitSectionShortView> {
      return localVarFp
        .getBusinessUnitSection(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSection1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<BusinessUnitSectionShortView> {
      return localVarFp
        .getBusinessUnitSection1(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit section by business unit id and section id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSection2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<BusinessUnitSectionShortView> {
      return localVarFp
        .getBusinessUnitSection2(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionUpdateRequest(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .getBusinessUnitSectionUpdateRequest(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionUpdateRequest1(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .getBusinessUnitSectionUpdateRequest1(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the \'UpdateRequest\' for section by section id and business unit id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSectionUpdateRequest2(
      businessUnitId: number,
      sectionId: number,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .getBusinessUnitSectionUpdateRequest2(businessUnitId, sectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSections(
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<BusinessUnitSectionShortView>> {
      return localVarFp
        .getBusinessUnitSections(businessUnitId, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSections1(
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<BusinessUnitSectionShortView>> {
      return localVarFp
        .getBusinessUnitSections1(businessUnitId, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get business unit sections
     * @param {number} businessUnitId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitSections2(
      businessUnitId: number,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<BusinessUnitSectionShortView>> {
      return localVarFp
        .getBusinessUnitSections2(businessUnitId, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get section/folder content with a possibility to get all hierarchy levels
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {boolean} [allHierarchyLevels]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContent(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      allHierarchyLevels?: boolean,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<ContentItemView>> {
      return localVarFp
        .getContent(businessUnitId, sectionId, parentFolderId, allHierarchyLevels, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentOld(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<ContentItemView>> {
      return localVarFp
        .getContentOld(businessUnitId, sectionId, parentFolderId, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentOld1(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<ContentItemView>> {
      return localVarFp
        .getContentOld1(businessUnitId, sectionId, parentFolderId, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get section/folder content
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} [parentFolderId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentOld2(
      businessUnitId: number,
      sectionId: number,
      parentFolderId?: number,
      skip?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<ContentItemView>> {
      return localVarFp
        .getContentOld2(businessUnitId, sectionId, parentFolderId, skip, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSection(
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .updateBusinessUnitSection(businessUnitId, businessUnitSectionUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSection1(
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .updateBusinessUnitSection1(businessUnitId, businessUnitSectionUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit section
     * @param {number} businessUnitId
     * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSection2(
      businessUnitId: number,
      businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
      options?: any
    ): AxiosPromise<BusinessUnitSectionUpdateRequest> {
      return localVarFp
        .updateBusinessUnitSection2(businessUnitId, businessUnitSectionUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSectionOrder(
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): AxiosPromise<Array<BusinessUnitSectionShortView>> {
      return localVarFp
        .updateBusinessUnitSectionOrder(businessUnitId, updateOrderNumRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSectionOrder1(
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): AxiosPromise<Array<BusinessUnitSectionShortView>> {
      return localVarFp
        .updateBusinessUnitSectionOrder1(businessUnitId, updateOrderNumRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update the business unit section order
     * @param {number} businessUnitId
     * @param {UpdateOrderNumRequest} updateOrderNumRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitSectionOrder2(
      businessUnitId: number,
      updateOrderNumRequest: UpdateOrderNumRequest,
      options?: any
    ): AxiosPromise<Array<BusinessUnitSectionShortView>> {
      return localVarFp
        .updateBusinessUnitSectionOrder2(businessUnitId, updateOrderNumRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update content order and place
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {UpdateContentOrderRequest} updateContentOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContentOrderAndPlace(
      businessUnitId: number,
      sectionId: number,
      updateContentOrderRequest: UpdateContentOrderRequest,
      options?: any
    ): AxiosPromise<Array<ContentItemView>> {
      return localVarFp
        .updateContentOrderAndPlace(businessUnitId, sectionId, updateContentOrderRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * BusinessUnitSectionControllerApi - interface
 * @export
 * @interface BusinessUnitSectionControllerApi
 */
export interface BusinessUnitSectionControllerApiInterface {
  /**
   *
   * @summary Create business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  createBusinessUnitSection(
    businessUnitId: number,
    businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Create business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  createBusinessUnitSection1(
    businessUnitId: number,
    businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Create business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  createBusinessUnitSection2(
    businessUnitId: number,
    businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Delete business unit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  deleteBusinessUnitSection(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Delete business unit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  deleteBusinessUnitSection1(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Delete business unit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  deleteBusinessUnitSection2(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Download icon for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  downloadIconOfSection(businessUnitId: number, sectionId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Download preview file of the BusinessUnitSection by business unit id, section id, and fileMeta id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} fileMetaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  downloadPreviewFile(businessUnitId: number, sectionId: number, fileMetaId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Get business unit section by business unit id and section id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSection(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<BusinessUnitSectionShortView>;

  /**
   *
   * @summary Get business unit section by business unit id and section id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSection1(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<BusinessUnitSectionShortView>;

  /**
   *
   * @summary Get business unit section by business unit id and section id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSection2(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<BusinessUnitSectionShortView>;

  /**
   *
   * @summary Get the \'UpdateRequest\' for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSectionUpdateRequest(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Get the \'UpdateRequest\' for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSectionUpdateRequest1(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Get the \'UpdateRequest\' for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSectionUpdateRequest2(
    businessUnitId: number,
    sectionId: number,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Get business unit sections
   * @param {number} businessUnitId
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSections(
    businessUnitId: number,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<BusinessUnitSectionShortView>>;

  /**
   *
   * @summary Get business unit sections
   * @param {number} businessUnitId
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSections1(
    businessUnitId: number,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<BusinessUnitSectionShortView>>;

  /**
   *
   * @summary Get business unit sections
   * @param {number} businessUnitId
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getBusinessUnitSections2(
    businessUnitId: number,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<BusinessUnitSectionShortView>>;

  /**
   *
   * @summary Get section/folder content with a possibility to get all hierarchy levels
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {boolean} [allHierarchyLevels]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getContent(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    allHierarchyLevels?: boolean,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<ContentItemView>>;

  /**
   *
   * @summary Get section/folder content
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getContentOld(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<ContentItemView>>;

  /**
   *
   * @summary Get section/folder content
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getContentOld1(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<ContentItemView>>;

  /**
   *
   * @summary Get section/folder content
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  getContentOld2(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    skip?: number,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<ContentItemView>>;

  /**
   *
   * @summary Update the business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateBusinessUnitSection(
    businessUnitId: number,
    businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Update the business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateBusinessUnitSection1(
    businessUnitId: number,
    businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Update the business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateBusinessUnitSection2(
    businessUnitId: number,
    businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
    options?: any
  ): AxiosPromise<BusinessUnitSectionUpdateRequest>;

  /**
   *
   * @summary Update the business unit section order
   * @param {number} businessUnitId
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateBusinessUnitSectionOrder(
    businessUnitId: number,
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ): AxiosPromise<Array<BusinessUnitSectionShortView>>;

  /**
   *
   * @summary Update the business unit section order
   * @param {number} businessUnitId
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateBusinessUnitSectionOrder1(
    businessUnitId: number,
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ): AxiosPromise<Array<BusinessUnitSectionShortView>>;

  /**
   *
   * @summary Update the business unit section order
   * @param {number} businessUnitId
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateBusinessUnitSectionOrder2(
    businessUnitId: number,
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ): AxiosPromise<Array<BusinessUnitSectionShortView>>;

  /**
   *
   * @summary Update content order and place
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {UpdateContentOrderRequest} updateContentOrderRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApiInterface
   */
  updateContentOrderAndPlace(
    businessUnitId: number,
    sectionId: number,
    updateContentOrderRequest: UpdateContentOrderRequest,
    options?: any
  ): AxiosPromise<Array<ContentItemView>>;
}

/**
 * BusinessUnitSectionControllerApi - object-oriented interface
 * @export
 * @class BusinessUnitSectionControllerApi
 * @extends {BaseAPI}
 */
export class BusinessUnitSectionControllerApi extends BaseAPI implements BusinessUnitSectionControllerApiInterface {
  /**
   *
   * @summary Create business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public createBusinessUnitSection(
    businessUnitId: number,
    businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .createBusinessUnitSection(businessUnitId, businessUnitSectionCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public createBusinessUnitSection1(
    businessUnitId: number,
    businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .createBusinessUnitSection1(businessUnitId, businessUnitSectionCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionCreateRequest} businessUnitSectionCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public createBusinessUnitSection2(
    businessUnitId: number,
    businessUnitSectionCreateRequest: BusinessUnitSectionCreateRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .createBusinessUnitSection2(businessUnitId, businessUnitSectionCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete business unit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public deleteBusinessUnitSection(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .deleteBusinessUnitSection(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete business unit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public deleteBusinessUnitSection1(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .deleteBusinessUnitSection1(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete business unit section
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public deleteBusinessUnitSection2(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .deleteBusinessUnitSection2(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download icon for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public downloadIconOfSection(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .downloadIconOfSection(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download preview file of the BusinessUnitSection by business unit id, section id, and fileMeta id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} fileMetaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public downloadPreviewFile(businessUnitId: number, sectionId: number, fileMetaId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .downloadPreviewFile(businessUnitId, sectionId, fileMetaId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit section by business unit id and section id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSection(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSection(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit section by business unit id and section id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSection1(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSection1(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit section by business unit id and section id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSection2(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSection2(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the \'UpdateRequest\' for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSectionUpdateRequest(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSectionUpdateRequest(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the \'UpdateRequest\' for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSectionUpdateRequest1(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSectionUpdateRequest1(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the \'UpdateRequest\' for section by section id and business unit id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSectionUpdateRequest2(businessUnitId: number, sectionId: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSectionUpdateRequest2(businessUnitId, sectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit sections
   * @param {number} businessUnitId
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSections(businessUnitId: number, skip?: number, limit?: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSections(businessUnitId, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit sections
   * @param {number} businessUnitId
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSections1(businessUnitId: number, skip?: number, limit?: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSections1(businessUnitId, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get business unit sections
   * @param {number} businessUnitId
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getBusinessUnitSections2(businessUnitId: number, skip?: number, limit?: number, options?: any) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getBusinessUnitSections2(businessUnitId, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get section/folder content with a possibility to get all hierarchy levels
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {boolean} [allHierarchyLevels]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getContent(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    allHierarchyLevels?: boolean,
    skip?: number,
    limit?: number,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getContent(businessUnitId, sectionId, parentFolderId, allHierarchyLevels, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get section/folder content
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getContentOld(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    skip?: number,
    limit?: number,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getContentOld(businessUnitId, sectionId, parentFolderId, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get section/folder content
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getContentOld1(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    skip?: number,
    limit?: number,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getContentOld1(businessUnitId, sectionId, parentFolderId, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get section/folder content
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} [parentFolderId]
   * @param {number} [skip]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public getContentOld2(
    businessUnitId: number,
    sectionId: number,
    parentFolderId?: number,
    skip?: number,
    limit?: number,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .getContentOld2(businessUnitId, sectionId, parentFolderId, skip, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateBusinessUnitSection(
    businessUnitId: number,
    businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateBusinessUnitSection(businessUnitId, businessUnitSectionUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateBusinessUnitSection1(
    businessUnitId: number,
    businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateBusinessUnitSection1(businessUnitId, businessUnitSectionUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit section
   * @param {number} businessUnitId
   * @param {BusinessUnitSectionUpdateRequest} businessUnitSectionUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateBusinessUnitSection2(
    businessUnitId: number,
    businessUnitSectionUpdateRequest: BusinessUnitSectionUpdateRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateBusinessUnitSection2(businessUnitId, businessUnitSectionUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit section order
   * @param {number} businessUnitId
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateBusinessUnitSectionOrder(
    businessUnitId: number,
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateBusinessUnitSectionOrder(businessUnitId, updateOrderNumRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit section order
   * @param {number} businessUnitId
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateBusinessUnitSectionOrder1(
    businessUnitId: number,
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateBusinessUnitSectionOrder1(businessUnitId, updateOrderNumRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the business unit section order
   * @param {number} businessUnitId
   * @param {UpdateOrderNumRequest} updateOrderNumRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateBusinessUnitSectionOrder2(
    businessUnitId: number,
    updateOrderNumRequest: UpdateOrderNumRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateBusinessUnitSectionOrder2(businessUnitId, updateOrderNumRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update content order and place
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {UpdateContentOrderRequest} updateContentOrderRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessUnitSectionControllerApi
   */
  public updateContentOrderAndPlace(
    businessUnitId: number,
    sectionId: number,
    updateContentOrderRequest: UpdateContentOrderRequest,
    options?: any
  ) {
    return BusinessUnitSectionControllerApiFp(this.configuration)
      .updateContentOrderAndPlace(businessUnitId, sectionId, updateContentOrderRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
