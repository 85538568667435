import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as ExclamationMarkIcon } from 'assets/icons/exclamation_mark.svg';

export function NotificationProvider(props: any) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      variant="error"
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantError: classes.error,
        variantInfo: classes.info,
      }}
      iconVariant={{
        info: <ExclamationMarkIcon style={{ marginRight: '12px' }} />,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
}

const useStyles = makeStyles(() => ({
  error: {
    borderRadius: '8px',
    backgroundColor: '#DC5050 !important',
  },
  info: {
    backgroundColor: '#474D61',
    borderRadius: '4px',
  },
}));
