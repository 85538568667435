import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const ProjectApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type ProjectsListProviderType = QueryObserverResult<BusinessUnitShortView[], unknown>;

const ProjectsListContext = createContext<ProjectsListProviderType | null>(null);
ProjectsListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useProjectsListProvider(): ProjectsListProviderType {
  const contextState = useContext(ProjectsListContext);
  if (isNil(contextState)) {
    throw new Error(`${useProjectsListProvider.name} must be used within a ${ProjectsListContext.displayName} context`);
  }
  return contextState;
}

interface ProjectsListProviderProps {
  queryOptions?: UseQueryOptions<BusinessUnitShortView[], AxiosError<unknown>, BusinessUnitShortView[]>;
}
export function ProjectsListProvider(props: React.PropsWithChildren<ProjectsListProviderProps>) {
  const value = useQuery<BusinessUnitShortView[], AxiosError<unknown>, BusinessUnitShortView[]>(
    RESOURCE_NAME,
    () => ProjectApi.getProjects().then(resp => resp.data),
    props.queryOptions
  );
  return <ProjectsListContext.Provider value={value}>{props.children}</ProjectsListContext.Provider>;
}
