import React, { useMemo, useState } from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { ShowErrorFunc, showErrorOnChange } from 'mui-rff';

import { KeyboardDatePicker, KeyboardDatePickerProps, useUtils } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { CustomInput, CustomLabel } from './CustomInput';
import { composeValidators, dateShouldBeAfter, dateShouldBeBefore, validDate } from './validators';
import { FieldValidator } from 'final-form';
import { isDate, isNil } from 'lodash/fp';
import { TextFieldProps } from '@material-ui/core';

export type ParsableDate = KeyboardDatePickerProps['value'];

export interface DatePickerProps extends Partial<KeyboardDatePickerProps> {
  name: string;
  fieldProps?: Partial<FieldProps<ParsableDate, any>>;
  showError?: ShowErrorFunc;
}

export function DatePicker(props: DatePickerProps) {
  const dateUtils = useUtils();
  const { name, fieldProps, showError, ...rest } = props;

  let minDate = props.minDate ? dateUtils.date(props.minDate) : null;
  // don't add validation if date is invalid
  if (isNil(minDate) || isNaN(minDate?.getTime())) {
    minDate = null;
  }

  let maxDate = props.maxDate ? dateUtils.date(props.maxDate) : null;
  // don't add validation if date is invalid
  if (isNil(maxDate) || isNaN(maxDate?.getTime())) {
    maxDate = null;
  }

  const { validate, key } = useMemo(() => {
    const validators: FieldValidator<ParsableDate>[] = [];
    validators.push(validDate);
    if (props.disableFuture) {
      validators.push(dateShouldBeBefore(new Date(), dateUtils));
    }

    if (minDate) {
      validators.push(dateShouldBeAfter(minDate, dateUtils));
    }

    if (maxDate) {
      validators.push(dateShouldBeBefore(maxDate, dateUtils));
    }

    if (fieldProps?.validate) {
      validators.push(fieldProps?.validate);
    }
    return {
      validate: composeValidators(...validators),
      // according to final-form doc we should update the key when we update validator
      // https://final-form.org/docs/react-final-form/types/FieldProps#validate
      key: [maxDate, minDate, props.disableFuture].map(String).join(','),
    };
  }, [props.disableFuture, minDate, maxDate, fieldProps?.validate, dateUtils]);

  // control value of textField to avoid cleaning of field when validation is changed
  const [inputValue, setInputValue] = useState<string | undefined | null>(undefined);

  return (
    <Field
      name={name}
      render={({ input, meta }: FieldRenderProps<ParsableDate, HTMLElement>) => {
        const onChange: KeyboardDatePickerProps['onChange'] = (date, inputValue) => {
          // set value for textbox
          setInputValue(inputValue);

          // use current time instead of 00:00 so that keyboard input was consistent with manual
          if (isDate(date) && date.getHours() === 0) {
            const now = new Date();
            date.setHours(now.getHours());
            date.setMinutes(now.getMinutes());
          }
          return input.onChange(date);
        };
        return (
          <DatePickerWrapper
            fieldRenderProps={{ input: { ...input, onChange }, meta }}
            datePickerProps={{ inputValue: inputValue || undefined, ...rest }}
            dateUtils={dateUtils}
            showError={showError}
          />
        );
      }}
      {...fieldProps}
      validate={validate}
      key={key}
    />
  );
}
interface DatePickerWrapperProps {
  fieldRenderProps: FieldRenderProps<ParsableDate, HTMLElement>;
  datePickerProps: Partial<KeyboardDatePickerProps>;
  dateUtils: ReturnType<typeof useUtils>;
  showError?: ShowErrorFunc;
}

function DatePickerWrapper(props: DatePickerWrapperProps) {
  const {
    fieldRenderProps: {
      meta,
      input: { name, onChange, onBlur, onFocus, value, ...restInput },
    },
    datePickerProps: { required, fullWidth = true, helperText, label, margin, inputVariant, ...rest },
    showError = obj => showErrorOnChange(obj) || (obj.meta.visited && !!obj.meta.error),
  } = props;
  const { error, submitError } = meta;
  const isError = showError({ meta });

  return (
    <FormControl error={isError} margin={margin} variant={inputVariant} fullWidth={fullWidth} required={required}>
      <CustomLabel shrink variant="standard">
        {label}
      </CustomLabel>
      <KeyboardDatePicker
        fullWidth={true}
        autoOk={true}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        inputProps={{ onChange, ...restInput }}
        TextFieldComponent={CustomInputForDatePicker as React.ComponentType<TextFieldProps>}
        InputAdornmentProps={{ position: 'start' }}
        {...rest}
        value={value === '' ? null : value}
        onChange={onChange}
      />
      <FormHelperText>{isError ? error || submitError : helperText}</FormHelperText>
    </FormControl>
  );
}
export const CustomInputForDatePicker = (props: any) => {
  const { value, helperText, clearable, InputProps, inputProps, ...restProps } = props;
  return (
    <CustomInput
      {...inputProps}
      {...InputProps}
      {...restProps}
      value={value || ''}
      endAdornment={
        clearable && value ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              onClick={evt => {
                evt.preventDefault();
                evt.stopPropagation();
                inputProps.onChange(null, null);
              }}
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null
      }
    />
  );
};
