import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import { GroupsChangelogProvider, useGroupsChangelogProvider } from 'api/GroupsProvider/GroupsChangelogProvider';
import { GroupFolderContentChangeLogView, GroupFolderContentChangeLogViewTypeEnum } from 'api/generated';
import { EmptyGroupMembersListIcon } from 'components/icons';
import { Spinner } from 'components/material/Spinner';
import { i18nDateFnsLocaleMap } from 'i18n/i18nDateFnsLocaleMap';
import { ListWrapper } from 'pages/Layouts/ListWrapper';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { GroupRequiredRouterParams } from '../interfaces';
import { DATE_SEARCH_PARAM_NAME } from '../constants';

import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';

import format from 'date-fns/format';

export function GroupsChangelogDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();

  const history = useHistory();
  const match = useRouteMatch<GroupRequiredRouterParams>();

  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  let groupFolderId = parseInt(match.params.groupFolderId || '', 10);
  if (isNaN(groupFolderId)) {
    const query = new URLSearchParams(history.location.search);
    groupFolderId = parseInt(query.get('groupFolderId') || '', 10);
  }

  const closeHandler = useCallback(() => {
    goBackOrReplace(makeParentUrl(match.url));
  }, [match.url]);

  const goToMembersByDateHandler = useCallback(() => {
    const query = new URLSearchParams(history.location.search);
    const currentDate = new Date().toISOString().split('T')[0];
    query.set(DATE_SEARCH_PARAM_NAME, currentDate);
    history.push(`${makeParentUrl(match.url)}/membersByDate?${query.toString()}`);
  }, [history, match.url]);

  return (
    <Dialog open={!!match} maxWidth="md" fullWidth={true} data-test={GroupsChangelogDialog.name}>
      <CustomDialogTitle>
        <Box display="flex" alignItems="center" width="100%">
          <Box
            fontSize="1.75rem"
            fontWeight="bold"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            mr={3}
          >
            {t('persons:changelog')}
          </Box>
          <Box ml="auto" display="flex" alignItems="center" justifyContent="flex-end">
            <Box>
              <Button variant="contained" color="secondary" size="small" onClick={goToMembersByDateHandler}>
                {t('persons:viewMembersAtDate')}
              </Button>
            </Box>
            <Box ml={4}>
              <IconButton aria-label="close" size="medium" onClick={closeHandler} data-test="closeButton">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CustomDialogTitle>
      <DialogContent>
        <GroupsChangelogProvider businessUnitId={businessUnitId} sectionId={sectionId} groupFolderId={groupFolderId}>
          <Box minHeight={200}>
            <Changelog />
          </Box>
        </GroupsChangelogProvider>
      </DialogContent>
    </Dialog>
  );
}

export const CustomDialogTitle = withStyles(theme => ({
  root: {
    paddingBottom: 13,
    paddingTop: 13,
  },
}))(DialogTitle);

function Changelog() {
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data, isFetched, isLoading } = useGroupsChangelogProvider();

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(data) && isFetched) {
    return (
      <ListWrapper>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={6}
        >
          <Box color="grey.400" mb={3}>
            <EmptyGroupMembersListIcon style={{ width: 164, height: 81 }} />
          </Box>
          <Typography align="center" color="textSecondary">
            {t('persons:changelogEmpty')}
          </Typography>
        </Box>
      </ListWrapper>
    );
  }

  const groupedByDate = groupBy(data, 'date');

  const formatOptions = {
    locale: i18nDateFnsLocaleMap[language],
    weekStartsOn: 1 as any,
    firstWeekContainsDate: 1 as any,
    addSuffix: true,
  };

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <TreeView
        defaultCollapseIcon={<ExpandLessIcon htmlColor={theme.palette.grey[600]} />}
        defaultExpandIcon={<ExpandMoreIcon htmlColor={theme.palette.grey[600]} />}
        defaultExpanded={keys(groupedByDate)}
        disableSelection
      >
        {map(groupedByDate, (items, dateKey) => {
          return (
            <CustomTreeItem
              key={dateKey}
              nodeId={dateKey}
              label={
                <Box my={1} color={theme.palette.text.secondary}>
                  {format(new Date(dateKey), `d MMMM y${language === 'ru' ? " 'г.'" : ''}`, formatOptions)}
                </Box>
              }
            >
              {map(items, (item, itemKey) => {
                const key = `${dateKey}.${itemKey}`;
                return <CustomTreeItem key={key} nodeId={key} label={<TreeItemLabel value={item} />} />;
              })}
            </CustomTreeItem>
          );
        })}
      </TreeView>
    </Box>
  );
}

export const CustomTreeItem = withStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'initial',
    },
    '&:focus': {
      '& > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'initial',
      },
    },
  },
  label: {
    '&:hover': {
      backgroundColor: 'initial',
    },
  },
}))(TreeItem);

function TreeItemLabel({ value }: { value: GroupFolderContentChangeLogView }) {
  const theme = useTheme();
  const descrColor = useCallback(
    (type: GroupFolderContentChangeLogViewTypeEnum) => {
      switch (type) {
        case GroupFolderContentChangeLogViewTypeEnum.START_DATE:
          return theme.palette.success.main;
        case GroupFolderContentChangeLogViewTypeEnum.END_DATE:
          return theme.palette.error.main;
        default:
          return theme.palette.text.primary;
      }
    },
    [theme]
  );
  return (
    <Box display="flex" flexDirection="row" alignItems="center" my={1}>
      <Box flex={1}>{value.displayName}</Box>
      <Box flex={1} color={descrColor(value.type)}>
        {value.description}
      </Box>
      <Box flex={1} color={theme.palette.grey[700]}>
        {value.position}
      </Box>
    </Box>
  );
}
