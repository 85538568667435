import React, { useRef } from 'react';

import { DocumentsListProvider, ITEMS_PER_PAGE } from 'api/DocumentProviders/DocumentsListProvider';
import { FolderShowProvider } from 'api/DocumentProviders/Folders';
import { SectionContentProvider, useSectionShowProvider } from 'api/SectionsProviders';
import { BusinessUnitSectionShortViewSectionDataTypeEnum, ContentItemView } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { FolderDocumentsColumn } from './ColumnList/FolderDocumentsColumn';
import { PersonsAndGroupsColumn } from '../PersonsAndGroups/PersonsAndGroupsColumn';
import { DocumentOrderUpdateProvider } from '../../../api/DocumentProviders/Documents/DocumentOrderUpdateProvider';

export function DocumentsQueryWrapper({
  businessUnitId,
  sectionId,
  parentFolderId,
}: {
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
}) {
  const catchError = useErrorCatcher();

  const { data: section } = useSectionShowProvider();

  const optionsRef = useRef({
    getNextPageParam: (lastPage: ContentItemView[], pages: ContentItemView[][]) => {
      if (lastPage.length >= ITEMS_PER_PAGE) {
        return { skip: pages.length * ITEMS_PER_PAGE, limit: ITEMS_PER_PAGE };
      }
      return undefined;
    },
    getPreviousPageParam: (_firstPage: ContentItemView[], _pages: ContentItemView[][]) => {
      // there is no previous page available
      return undefined;
    },
  });

  switch (section?.sectionDataType) {
    case BusinessUnitSectionShortViewSectionDataTypeEnum.DOCUMENT: {
      return (
        <DocumentsListProvider
          filter={undefined}
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          parentFolderId={parentFolderId}
          queryOptions={{ onError: catchError, ...optionsRef.current }}
        >
          <FolderShowProvider
            businessUnitId={businessUnitId}
            sectionId={sectionId}
            folderId={parentFolderId}
            queryOptions={{ onError: catchError }}
          >
            <DocumentOrderUpdateProvider
              businessUnitId={businessUnitId}
              sectionId={sectionId}
              parentFolderId={parentFolderId}
            >
              <FolderDocumentsColumn
                businessUnitId={businessUnitId}
                sectionId={sectionId}
                parentFolderId={parentFolderId}
              />
            </DocumentOrderUpdateProvider>
          </FolderShowProvider>
        </DocumentsListProvider>
      );
    }
    case BusinessUnitSectionShortViewSectionDataTypeEnum.PERSON: {
      return (
        <SectionContentProvider
          filter={undefined}
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          parentFolderId={parentFolderId}
          allHierarchyLevels={false}
          queryOptions={{ onError: catchError, ...optionsRef.current }}
        >
          <PersonsAndGroupsColumn businessUnitId={businessUnitId} sectionId={sectionId} />
        </SectionContentProvider>
      );
    }
    default: {
      return null;
    }
  }
}
