import React, { useCallback } from 'react';
import { Form, Field, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PhoneNumberInput } from './PhoneNumberInput';
import { PhoneNumberIcon } from 'components/icons/PhoneNumberIcon';

import { composeValidators, required, phone } from '../validators';

import isObject from 'lodash/isObject';

import { useFormStyles } from '../styles';

interface IEnterPhoneFormProps {
  onSubmit: FormProps['onSubmit'];
  loading: boolean;
}

export function EnterPhoneForm({ onSubmit, loading }: IEnterPhoneFormProps) {
  const { t } = useTranslation();

  const translateError = useCallback(
    error => {
      let errorText;
      if (isObject(error) as any) {
        errorText = t(error.message, error.args);
      } else {
        errorText = t(error);
      }
      return errorText;
    },
    [t]
  );

  const classes = useFormStyles();
  return (
    <Form
      onSubmit={onSubmit}
      subscription={formSubscription}
      keepDirtyOnReinitialize={true}
      render={formProps => (
        <form onSubmit={formProps.handleSubmit} className={classes.form}>
          <Box className={classes.root}>
            <PhoneNumberIcon style={{ fontSize: 45 }} />
            <Box mt={3.5} fontSize="h5.fontSize" fontWeight="bold">
              {t('login:personalAccount')}
            </Box>
            <Box fontSize="subtitle1.fontSize" mt={3} color="#83899B">
              {t('login:enterPhoneNumber')}
            </Box>
            {loading ? (
              <Box pt={5}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box mt={3} px={3} width="100%" display="flex" justifyContent="center">
                  <Field name="phone" width="100%" validate={phoneFieldValidation}>
                    {fieldProps => {
                      const hasError = fieldProps.meta.invalid && fieldProps.meta.submitFailed;
                      const errorText = hasError ? translateError(fieldProps.meta.error) : '';
                      return (
                        <PhoneNumberInput
                          inputProps={{
                            name: fieldProps.input.name,
                            autoFocus: true,
                            'data-test': 'phone_input',
                          }}
                          onChange={fieldProps.input.onChange}
                          onFocus={fieldProps.input.onFocus}
                          onBlur={fieldProps.input.onBlur}
                          onKeyDown={(event: any) => {
                            if (event.key === 'Enter') {
                              formProps.handleSubmit();
                              event.preventDefault();
                            }
                          }}
                          error={fieldProps.meta.invalid && fieldProps.meta.submitFailed}
                          helperText={errorText}
                        />
                      );
                    }}
                  </Field>
                </Box>
                <Box mt={3} width={300}>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={formProps.submitting}
                    onClick={formProps.form.submit}
                    data-test="phone_submit"
                    fullWidth
                    className={classes.phoneButton}
                  >
                    {t('login:login')}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </form>
      )}
    />
  );
}

// Form Helpers

// don't redraw entire form each time one field changes
const formSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const phoneFieldValidation = composeValidators(required(), phone());
