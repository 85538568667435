import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import { SearchTextField } from 'components/fields';
import { searchSubHeaderHeight } from 'styles/constants';
import { SEARCH_PAGE_PATH } from '../Router';

function ResultsPageSearchField({ searchText }: { searchText: string }) {
  const inputClasses = useInputStyles();

  const onSubmit = (value: any) => {
    window.location.href = `${SEARCH_PAGE_PATH}?query=${value}`;
  };

  return <SearchTextField submitHandler={onSubmit} initialValue={searchText} originClasses={inputClasses} />;
}

export function ResultsPageSearchArea({ searchText }: { searchText: string }) {
  const classes = useStyles();
  return (
    <Box className={classes.subHeader}>
      <Box className={classes.searchBox}>
        <ResultsPageSearchField searchText={searchText} />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  subHeader: {
    width: '100%',
    height: searchSubHeaderHeight,
    background: '#EDEEF3',
    position: 'relative',
  },
  searchBox: {
    bottom: '-50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    width: 842,
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.04)',
    marginTop: 40,
  },
  inputRoot: {
    height: 58,
  },
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    height: 58,
    background: '#fff',
    width: '100%',
  },
}));
