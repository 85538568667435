import React, { useCallback, useState } from 'react';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import RemoveIcon from '@material-ui/icons/Remove';

import { PDFViewer } from 'components/common/PDFViewer';
import { goBackOrReplace, makeParentUrl } from 'utils';

import get from 'lodash/get';

export const PREVIEW_FILE_URL_SEARCH_PARAM_NAME = 'previewFileUrl';

export function DocumentPreviewDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const history = useHistory();
  const match = useRouteMatch();

  const fileName = get(history, 'location.state.title', '');

  const query = new URLSearchParams(history.location.search);
  const previewFileUrl = query.get(PREVIEW_FILE_URL_SEARCH_PARAM_NAME) || '';

  const [scale, setScale] = useState(1);
  const zoomIn = useCallback(() => {
    setScale(scale => Math.round((scale + 0.1) * 100) / 100);
  }, []);
  const zoomOut = useCallback(() => {
    setScale(scale => (scale > 0.1 ? Math.round((scale - 0.1) * 100) / 100 : 0.1));
  }, []);

  const closeHandler = useCallback(() => {
    const query = new URLSearchParams(history.location.search);
    query.delete(PREVIEW_FILE_URL_SEARCH_PARAM_NAME);
    goBackOrReplace(makeParentUrl(`${match.url}?${query.toString()}`));
  }, [history.location.search, match.url]);

  const stopPropagation = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  return (
    <Dialog open={!!match} fullScreen={true} data-test={DocumentPreviewDialog.name}>
      <CustomDialogTitle>
        <Box display="flex" alignItems="center" width="100%">
          <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" mr={3}>
            {fileName || ''}
          </Box>
          <Box ml="auto" display="flex" alignItems="center" justifyContent="flex-end">
            <Box display="flex" alignItems="center" mr={6}>
              <Box mr={1}>
                <IconButton aria-label="zoom out" size="medium" onClick={zoomOut} data-test="zoomOutButton">
                  <RemoveIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <IconButton aria-label="zoom in" size="medium" onClick={zoomIn} data-test="zoomInButton">
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <IconButton
                component={Link}
                href={previewFileUrl}
                download={fileName || true}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="download"
                size="medium"
                onClick={stopPropagation}
                data-test="downloadButton"
              >
                <GetAppIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box ml={6}>
              <IconButton aria-label="close" size="medium" onClick={closeHandler} data-test="closeButton">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CustomDialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <PDFViewer fileUrl={previewFileUrl} scale={scale} />
      </DialogContent>
    </Dialog>
  );
}

export const CustomDialogTitle = withStyles(theme => ({
  root: {
    paddingBottom: 13,
    paddingTop: 13,
  },
}))(DialogTitle);
