import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { EnterPhoneForm } from './components/EnterPhoneForm';
import { EnterSMSCodeForm } from './components/EnterSMSCodeForm';
import { EnterPinCodeForm } from './components/EnterPinCodeForm';
import { CreatePinCodeForm } from './components/CreatePinCodeForm';
import { SupportBlock } from './components/SupportBlock';

import { isVendorErrorResponse, useAuthProvider } from 'api/AuthProviders';
import { useErrorCatcher } from 'api/notifications';
import { MOBILE_APPS_PAGE_PATH, ROOT_PAGE_PATH } from '../Router';

import get from 'lodash/get';
import map from 'lodash/map';

import { LogoIcon } from 'components/icons';

type AuthSteps = 'ENTER_PHONE' | 'ENTER_SMS_CODE' | 'ENTER_PIN_CODE' | 'CREATE_PIN_CODE';
const SMS_CODE_LENGTH = 4;

export function AuthPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const catchError = useErrorCatcher();

  const {
    isAuthenticated,
    controllers: {
      loginPhone: { mutateAsync: sendPhone },
      loginCode: { mutateAsync: sendCode },
      loginComplete: { mutateAsync: sendComplete },
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status,
  } = useAuthProvider();

  useEffect(() => {
    if (isMobile) {
      history.push(`${MOBILE_APPS_PAGE_PATH}/download`);
    }

    if (isAuthenticated) {
      const from = get(history, 'location.state.from', ROOT_PAGE_PATH);
      history.push(from);
    }
  }, [history, isAuthenticated]);

  const [phone, setPhone] = useState<string>('');
  const [smsCode, setSMSCode] = useState<string>('');
  const [smsCodeLength, setSMSCodeLength] = useState<number>(SMS_CODE_LENGTH);
  const [nonce, setNonce] = useState<string>('');
  const [step, setStep] = useState<AuthSteps>('ENTER_PHONE');
  const [fieldError, setFieldError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);

  const toggleSupportVisibility = useCallback(() => {
    setShowSupport(showSupport => !showSupport);
  }, []);

  useEffect(() => {
    setFieldError('');
  }, [step]);

  const submitPhone = useCallback(
    async formValues => {
      setPhone(formValues.phone || '');
      setLoading(true);

      const phone = cleanPhone(formValues.phone || '');
      await sendPhone({ phone })
        .then(resp => {
          if (isVendorErrorResponse(resp)) {
            setFieldError(map(resp.errors, 'message').join('\n'));
          } else {
            setSMSCodeLength(resp.codeSize);
            setNonce(resp.nonce);
            setStep('ENTER_SMS_CODE');
          }
          setLoading(false);
        })
        .catch(e => {
          catchError(e);
          setLoading(false);
        });
    },
    [sendPhone, catchError]
  );

  const submitSMSCode = useCallback(
    async formValues => {
      setLoading(true);
      const otp = formValues.otp || '';
      setSMSCode(otp);

      const cleanedPhone = cleanPhone(phone || '');

      await sendCode({ phone: cleanedPhone, otp, nonce })
        .then(resp => {
          if (isVendorErrorResponse(resp)) {
            setFieldError(map(resp.errors, 'message').join('\n'));
          } else {
            // @ts-ignore
            if (resp.data?.newPin) {
              setStep('CREATE_PIN_CODE');
            } else {
              setStep('ENTER_PIN_CODE');
            }
          }
          setLoading(false);
        })
        .catch(e => {
          catchError(e);
          setLoading(false);
        });
    },
    [catchError, phone, nonce, sendCode]
  );

  const submitPinCode = useCallback(
    async formValues => {
      setLoading(true);
      const pin = formValues.pin || '';
      const cleanedPhone = cleanPhone(phone);
      await sendComplete({ phone: cleanedPhone, otp: smsCode, pin, nonce })
        .then(resp => {
          if (isVendorErrorResponse(resp)) {
            setFieldError(map(resp.errors, 'message').join('\n'));
          }
          setLoading(false);
        })
        .catch(e => {
          catchError(e);
          setLoading(false);
        });
    },
    [catchError, phone, smsCode, nonce, sendComplete]
  );

  const classes = useStyles();

  const getCurrentForm = useCallback(() => {
    switch (step) {
      case 'ENTER_PHONE':
        return <EnterPhoneForm onSubmit={submitPhone} loading={loading} />;
      case 'ENTER_SMS_CODE':
        return (
          <EnterSMSCodeForm onSubmit={submitSMSCode} phone={phone} smsCodeLength={smsCodeLength} loading={loading} />
        );
      case 'CREATE_PIN_CODE':
        return <CreatePinCodeForm onSubmit={submitPinCode} loading={loading} />;
      case 'ENTER_PIN_CODE':
        return <EnterPinCodeForm onSubmit={submitPinCode} loading={loading} />;
      default:
        return null;
    }
  }, [step, loading, phone, submitPhone, submitPinCode, submitSMSCode, smsCodeLength]);

  const currentForm = getCurrentForm();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      bgcolor="#31323A"
      width="100vw"
      height="100vh"
    >
      <Box className={classes.logo}>
        <Box fontSize="h4.fontSize" color="#F2F2F2">
          <Box component="span">
            <LogoIcon className={classes.logoIcon} />
          </Box>
          <Box component="span" className={classes.logoLabel}>
            USM Universe
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          width={600}
          minHeight={512}
          m={2}
          px={16}
          pb={2.5}
          borderRadius={19}
          bgcolor="background.paper"
          height="calc(100% - 55px)"
          justifyContent="center"
        >
          {currentForm}
          {fieldError && (
            <Box textAlign="center" mt={2} color="error.main">
              {fieldError}
            </Box>
          )}
          <Box position="absolute" bottom={20} className={classes.helpButton} onClick={toggleSupportVisibility}>
            {t('login:needHelp')}
          </Box>
        </Box>
        <Box height={72}>
          <SupportBlock show={showSupport} />
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  logo: {
    fontSize: theme.typography.pxToRem(53),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(6.5),
  },
  helpButton: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontSize: theme.typography.subtitle1.fontSize,
  },
  logoLabel: {
    marginLeft: 80,
  },
  logoIcon: {
    width: 56,
    height: 56,
    position: 'absolute',
  },
}));

// Helpers

function cleanPhone(phone: string) {
  return (phone || '').replace(/[^0-9]+/g, '');
}
