/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AccountShortView } from './account-short-view';

/**
 * Represents a structure that describes a company or project
 * @export
 * @interface BusinessUnitView
 */
export interface BusinessUnitView {
  /**
   *
   * @type {number}
   * @memberof BusinessUnitView
   */
  id: number;
  /**
   * Accounts of responsible for this business unit
   * @type {Array<AccountShortView>}
   * @memberof BusinessUnitView
   */
  responsible: Array<AccountShortView>;
  /**
   * Accounts ids of responsible for this business unit
   * @type {Array<number>}
   * @memberof BusinessUnitView
   * @deprecated
   */
  responsibleIds: Array<number>;
  /**
   *
   * @type {string}
   * @memberof BusinessUnitView
   */
  status: BusinessUnitViewStatusEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessUnitView
   */
  title: string;
  /**
   * Now can be \'COMPANY\' or \'PROJECT\'
   * @type {string}
   * @memberof BusinessUnitView
   */
  type: BusinessUnitViewTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum BusinessUnitViewStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
/**
 * @export
 * @enum {string}
 */
export enum BusinessUnitViewTypeEnum {
  PROJECT = 'PROJECT',
  COMPANY = 'COMPANY',
  COMPANY_OUTER = 'COMPANY_OUTER',
}
