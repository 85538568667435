import find from 'lodash/find';
import isFunction from 'lodash/isFunction';
import { country_data } from 'utils/phone';

export const composeValidators =
  (...validators: any[]) =>
  (value: any, values: any, meta: any) => {
    const allValidators = (Array.isArray(validators[0]) ? validators[0] : validators).filter(isFunction);

    for (const validator of allValidators) {
      const error = validator(value, values, meta);

      if (error) {
        return error;
      }
    }
  };

export const phone =
  (message = 'login:validation:phone') =>
  (phone: string) => {
    if (!phone) {
      return;
    }
    const countryData = find(country_data, item => {
      const doesStartWith = phone.startsWith(item[3] as string);
      const isSameLength = phone.length === ((item[4] as string).match(/\./g) || []).length;
      return doesStartWith && isSameLength;
    });
    return countryData ? undefined : message;
  };

export const required =
  (message = 'login:validation:required') =>
  (phone: string) => {
    if (!phone) {
      return message;
    }
  };

export const minLength =
  (min: number, message = 'login:validation:wrongLength') =>
  (pin: string) => {
    if (!pin) {
      return;
    }
    if (pin.length < min) {
      return message;
    }
  };
