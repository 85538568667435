import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { BusinessUnitControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const ProjectApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type ProjectArchiveProviderType = {
  controllers: {
    archive: UseMutationResult<object, AxiosError<unknown>, number>;
    unarchive: UseMutationResult<object, AxiosError<unknown>, number>;
  };
};

const ProjectArchiveContext = createContext<ProjectArchiveProviderType | null>(null);
ProjectArchiveContext.displayName = `${upperFirst(RESOURCE_NAME)}Archive`;

export function useProjectArchiveProvider(): ProjectArchiveProviderType {
  const contextState = useContext(ProjectArchiveContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useProjectArchiveProvider.name} must be used within a ${ProjectArchiveContext.displayName} context`
    );
  }
  return contextState;
}

interface ProjectArchiveProviderProps {
  businessUnitId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, number>;
}

export function ProjectArchiveProvider(props: React.PropsWithChildren<ProjectArchiveProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: ProjectArchiveProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries(['businessUnit', { businessUnitId: props.businessUnitId }]);
    },
  };
  const archive = useMutation<object, AxiosError<unknown>, number>(
    async (version: number) => {
      return ProjectApi.archiveBusinessUnit(props.businessUnitId, version).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );

  const unarchive = useMutation<object, AxiosError<unknown>, number>(
    async (version: number) => {
      return ProjectApi.unarchiveBusinessUnit(props.businessUnitId, version).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );

  return (
    <ProjectArchiveContext.Provider value={{ controllers: { archive, unarchive } }}>
      {props.children}
    </ProjectArchiveContext.Provider>
  );
}
