import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { GroupFolderControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from 'api/SectionsProviders/constants';
import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

export interface GroupDeleteRequest {
  groupFolderId?: number;
}

type GroupDeleteProviderType = UseMutationResult<object, AxiosError<unknown>, GroupDeleteRequest, unknown>;

const GroupDeleteContext = createContext<GroupDeleteProviderType | null>(null);
GroupDeleteContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useGroupDeleteProvider(): GroupDeleteProviderType {
  const contextState = useContext(GroupDeleteContext);
  if (isNil(contextState)) {
    throw new Error(`${useGroupDeleteProvider.name} must be used within a ${GroupDeleteContext.displayName} context`);
  }
  return contextState;
}

interface GroupDeleteProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, GroupDeleteRequest, unknown>;
}
export function GroupDeleteProvider(props: React.PropsWithChildren<GroupDeleteProviderProps>) {
  const queryClient = useQueryClient();
  const removeDefaultOptions: GroupDeleteProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries(SECTION_RESOURCE_NAME);
    },
  };
  const remove = useMutation<object, AxiosError<unknown>, GroupDeleteRequest>(
    async ({ groupFolderId }: GroupDeleteRequest) => {
      return GroupFolderApi.deleteGroupFolder(
        props.businessUnitId,
        props.sectionId,
        groupFolderId || props.groupFolderId
      ).then(resp => resp.data);
    },
    {
      ...removeDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <GroupDeleteContext.Provider value={remove}>{props.children}</GroupDeleteContext.Provider>;
}
