import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  BusinessUnitSectionControllerApiFactory,
  BusinessUnitSectionCreateRequest,
  BusinessUnitSectionUpdateRequest,
  BusinessUnitView,
  FileControllerApiFactory,
  FileMetaView,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';
import { isFileMetaView } from 'api/DocumentProviders/Documents/type-helpers';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

const FileApi = FileControllerApiFactory(FileApiConfiguration);
const SectionApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

export type AddSectionRequest = {
  sectionPreviewEnFile?: File | FileMetaView;
  sectionPreviewRuFile?: File | FileMetaView;
} & BusinessUnitSectionCreateRequest;

type AddSectionProviderType = UseMutationResult<
  BusinessUnitSectionUpdateRequest,
  AxiosError<unknown>,
  BusinessUnitSectionCreateRequest,
  unknown
>;

const AddSectionContext = createContext<AddSectionProviderType | null>(null);
AddSectionContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useAddSectionProvider(): AddSectionProviderType {
  const contextState = useContext(AddSectionContext);
  if (isNil(contextState)) {
    throw new Error(`${useAddSectionProvider.name} must be used within a ${AddSectionContext.displayName} context`);
  }
  return contextState;
}

interface AddSectionProviderProps {
  businessUnitId: BusinessUnitView['id'];
  queryOptions?: UseMutationOptions<
    BusinessUnitSectionUpdateRequest,
    AxiosError<unknown>,
    BusinessUnitSectionCreateRequest,
    unknown
  >;
}

export function AddSectionProvider(props: React.PropsWithChildren<AddSectionProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: AddSectionProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([RESOURCE_NAME, { businessUnitId: props.businessUnitId }]);
    },
  };
  const create = useMutation<BusinessUnitSectionUpdateRequest, AxiosError<unknown>, AddSectionRequest>(
    async (data: AddSectionRequest) => {
      if (data.sectionPreviewEnFile && !isFileMetaView(data.sectionPreviewEnFile)) {
        data.sectionPreviewEnFileMetaView = await FileApi.upload(
          data.sectionPreviewEnFile,
          data.sectionPreviewEnFile.name
        ).then(resp => resp.data);
      }
      if (data.sectionPreviewRuFile && !isFileMetaView(data.sectionPreviewRuFile)) {
        data.sectionPreviewRuFileMetaView = await FileApi.upload(
          data.sectionPreviewRuFile,
          data.sectionPreviewRuFile.name
        ).then(resp => resp.data);
      }
      const params: BusinessUnitSectionCreateRequest = omit(data, ['sectionPreviewEnFile', 'sectionPreviewRuFile']);
      return SectionApi.createBusinessUnitSection2(props.businessUnitId, params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <AddSectionContext.Provider value={create}>{props.children}</AddSectionContext.Provider>;
}
