import React, { useMemo } from 'react';
import classnames from 'classnames';

import { default as ReactPhoneInput } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { ru } from 'i18n/phone_codes';
import { countries } from 'utils/countries';

const DEFAULT_COUNTRY = 'ru';

export function PhoneNumberInput(props: any) {
  const {
    i18n: { language },
  } = useTranslation();
  const classes = useStyles();

  const { supportedCountries } = useMemo(() => {
    const masks: { [key: string]: string } = [] as any;
    const supportedCountries: string[] = [];
    countries.forEach(item => {
      const countryIso = item.iso.toLowerCase();
      supportedCountries.push(countryIso);
      masks[countryIso] = Array.isArray(item.mask) ? item.mask[0] : item.mask;
    });
    return { masks, supportedCountries };
  }, []);

  return (
    <FormControl error={props.error} variant="outlined" fullWidth={true} size="medium">
      <Box display="flex" alignItems="center">
        <ReactPhoneInput
          onlyCountries={supportedCountries}
          country={DEFAULT_COUNTRY}
          disableAreaCodes={true}
          countryCodeEditable={false}
          localization={language === 'ru' ? ru : undefined}
          inputClass={classnames(classes.input, props.error ? 'error' : '')}
          // remove input label
          specialLabel={null}
          {...props}
        />
        {props?.resettable && (
          <IconButton
            className={classes.clearButton}
            size="small"
            onClick={() => props.onChange(undefined)}
            data-test="clearButton"
          >
            <ClearIcon className={classes.clearIcon} fontSize="small" />
          </IconButton>
        )}
      </Box>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}

const useStyles = makeStyles(theme => ({
  input: {
    '&.form-control': {
      borderRadius: '8px',
      borderWidth: '1px',
      height: 48,
    },
    '&.form-control:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      borderWidth: '1px',
    },
    '&.form-control&.error': {
      borderColor: theme.palette.error.main,
    },
    '&.error:hover, &.error:focus': {
      boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
    },
  },
  clearButton: {
    color: theme.palette.text.primary,
    marginLeft: -32,
  },
  clearIcon: {
    width: 16,
    height: 16,
  },
}));
