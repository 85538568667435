import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProps } from 'react-final-form';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PinCodeFieldsForm } from './PinCodeFieldsForm';
import { PinCodeIcon } from 'components/icons';
import { useFormStyles } from '../styles';

interface IEnterPinCodeFormProps {
  onSubmit: FormProps['onSubmit'];
  loading: boolean;
}

export function EnterPinCodeForm({ onSubmit, loading }: IEnterPinCodeFormProps) {
  const { t } = useTranslation();
  const classes = useFormStyles();
  return (
    <Box className={classes.root}>
      <PinCodeIcon style={{ fontSize: 43, fill: 'none' }} />
      <Box mt={2.5} fontSize="h5.fontSize" fontWeight="bold">
        {t('login:pinCodeVerification')}
      </Box>
      <Box fontSize="body1.fontSize" mt={3} color="text.secondary" textAlign="center">
        <span>{t('login:enterPinCode')}</span>
      </Box>
      {loading ? (
        <Box pt={5}>
          <CircularProgress />
        </Box>
      ) : (
        <PinCodeFieldsForm onSubmit={onSubmit} />
      )}
    </Box>
  );
}
