import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';

import { Modal } from 'components/material/Modal';
import { ModalToolbar } from 'components/material/ModalToolbar';

interface DeleteConfirmDialogProps extends DialogProps {
  open: boolean;
  onClose: (event?: any) => void;
  handleRemove: (event: any) => Promise<unknown>;
  title: string;
  confirmText: string;
  primaryButtonDisabled?: boolean;
}

export function DeleteConfirmDialog({
  open,
  onClose,
  handleRemove,
  title,
  confirmText,
  primaryButtonDisabled,
}: DeleteConfirmDialogProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onCloseModal={onClose}
      maxWidth="sm"
      fullWidth={true}
      title={title}
      onClick={event => event.stopPropagation()}
      toolbar={
        <ModalToolbar>
          <Button onClick={handleRemove} color="primary" variant="contained" disabled={primaryButtonDisabled}>
            {t('dialogs:deleteConfirmDialog.submit')}
          </Button>
          <Box width={16} />
          <Button onClick={onClose} variant="contained" color="secondary">
            {t('dialogs:deleteConfirmDialog.cancel')}
          </Button>
        </ModalToolbar>
      }
    >
      <Box mb={6} minHeight={150}>
        <Typography variant="subtitle2">{confirmText}</Typography>
      </Box>
    </Modal>
  );
}
