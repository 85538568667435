import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PinCodeIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="34" height="43" viewBox="0 0 34 43" fill="none" {...props}>
      <path
        d="M6.5 12C6.5 6.20101 11.201 1.5 17 1.5C22.799 1.5 27.5 6.20101 27.5 12V28.5H6.5V12Z"
        stroke="black"
        strokeWidth="3"
      />
      <rect y="18" width="34" height="25" rx="2" fill="black" />
      <circle cx="17" cy="30" r="3" fill="white" />
      <rect x="16" y="30" width="2" height="6" rx="1" fill="white" />
    </SvgIcon>
  );
}

export default PinCodeIcon;
