import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { AccountsListProvider } from 'api/AccountsProviders';

import { AccountsList } from './components/AccountsList';

export function AccountsPage() {
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Box alignSelf="center" display="flex" flexDirection="column" width="100%" mt={6}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="h5">{t('accounts:list.accounts')}</Typography>
          </Box>
          <Box>
            <Button color="primary" variant="contained" size="small" component={Link} to={`${url}/new`}>
              {`+ ${t('accounts:addAccount')}`}
            </Button>
          </Box>
        </Box>
        <Box flexGrow={1}>
          <AccountsListProvider>
            <AccountsList />
          </AccountsListProvider>
        </Box>
      </Box>
    </Container>
  );
}
