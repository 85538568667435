import React from 'react';

import { useTranslation } from 'react-i18next';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getPersonPhotoURL } from 'api/http';
import { PersonSimpleView } from 'api/generated';

import { Select, SelectProps } from 'components/fields/Select';

import map from 'lodash/map';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { makeParentUrl } from '../../../../../utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

interface SelectPersonProps extends Partial<SelectProps> {
  businessUnitId: number;
  groupFolderId: number;
  disabled?: boolean;
  persons: PersonSimpleView[];
}

export function SelectPerson({ disabled = false, businessUnitId, persons, groupFolderId, ...rest }: SelectPersonProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const { search } = useLocation();
  const classes = useStyles();

  const onCreatePerson = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.set('groupFolderId', String(groupFolderId));
    history.push({ pathname: `${makeParentUrl(match.url)}/add_person`, search: searchParams.toString() });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Box flexBasis="50%">
          <Select
            name="personId"
            label={t('persons:form.fields.member')}
            placeholder={t('persons:form.fields.memberPlaceholder')}
            formControlProps={{ margin: 'dense' }}
            disabled={disabled}
            {...rest}
          >
            <MenuItem dense={false} classes={{ gutters: classes.menuItem }} selected={true} onClick={onCreatePerson}>
              <Box display="flex">
                <IconButton classes={{ root: classes.icon }}>
                  <AddIcon />
                </IconButton>
                <ListItemText primary={t('persons:form.fields.createNewMember')} />
              </Box>
            </MenuItem>
            {map(persons, person => (
              <MenuItem key={person.id} value={person.id} dense={false} classes={{ gutters: classes.menuItem }}>
                <Box display="flex">
                  <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                    <Avatar
                      src={getPersonPhotoURL(businessUnitId, person.id)}
                      alt={person.displayName}
                      classes={{ root: classes.avatar }}
                    />
                  </ListItemAvatar>
                  <ListItemText id={`${person.id}`} primary={person.displayName} />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  menuItem: {
    paddingLeft: theme.spacing(2),
  },
  listItemAvatar: {
    width: 40,
    minWidth: 40,
  },
  avatar: {
    width: 32,
    minWidth: 32,
    height: 32,
    minHeight: 32,
  },
  icon: {
    color: '#83899B',
    backgroundColor: '#ECEEF3',
    marginRight: 5,
    padding: 5,
  },
}));
