/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AccountView } from './account-view';

/**
 * User details
 * @export
 * @interface UserView
 */
export interface UserView {
  /**
   *
   * @type {AccountView}
   * @memberof UserView
   */
  account: AccountView;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof UserView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  phone: string;
  /**
   *
   * @type {boolean}
   * @memberof UserView
   */
  primary: boolean;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  smsPhone?: string;
  /**
   *
   * @type {string}
   * @memberof UserView
   */
  state: UserViewStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserViewStateEnum {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED',
}
