import React from 'react';

import i18n from 'i18n/config';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useTranslation } from 'react-i18next';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { i18nDateFnsLocaleMap, DateFnsUtils } from 'i18n';

import { appThemeLight } from './AppTheme';
import { Router } from './pages/Router';
import { NotificationProvider } from './hocs/NotificationProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // uncomment if you want to avoid refetching on focus for debug
      // refetchOnWindowFocus: false,
    },
  },
});

i18n.on('languageChanged', _lang => {
  // Invalidate every query in the cache
  queryClient.invalidateQueries();
});

function App() {
  const {
    i18n: { language },
  } = useTranslation();
  const theme = React.useMemo(() => createMuiTheme(appThemeLight), []);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18nDateFnsLocaleMap[language]}>
          <NotificationProvider>
            <Router />
          </NotificationProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
