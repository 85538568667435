import { makeStyles } from '@material-ui/core';

import { backToolbarHeight } from 'styles/constants';

export const useColumnStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  header: {
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(0, 9, 0, 9),
  },
  backToolbar: {
    minHeight: backToolbarHeight,
    height: backToolbarHeight,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 9, 0.5, 9),
  },
  backBtn: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(-2),
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginRight: theme.spacing(1.5),
    lineHeight: '32px',
  },
  list: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 9, 0, 9),
    overflow: 'auto',
  },
}));
