/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * View for content item in section/folder
 * @export
 * @interface ContentItemView
 */
export interface ContentItemView {
  /**
   * Child elements of current item
   * @type {Array<ContentItemView>}
   * @memberof ContentItemView
   */
  children: Array<ContentItemView>;
  /**
   * Data (person,document,folder) id
   * @type {number}
   * @memberof ContentItemView
   */
  dataId?: number;
  /**
   * Now can be \'DOCUMENT\', \'FOLDER\', \'GROUP\', \'PERSON\', \'PARENT_FOR_GROUP\'
   * @type {string}
   * @memberof ContentItemView
   */
  dataType: ContentItemViewDataTypeEnum;
  /**
   * Data (person,document,folder) version
   * @type {number}
   * @memberof ContentItemView
   */
  dataVersion?: number;
  /**
   * Description of item
   * @type {string}
   * @memberof ContentItemView
   */
  description?: string;
  /**
   * Path URI to icon downloading
   * @type {string}
   * @memberof ContentItemView
   */
  iconPath: string;
  /**
   *
   * @type {number}
   * @memberof ContentItemView
   */
  id: number;
  /**
   * Supported languages
   * @type {Array<string>}
   * @memberof ContentItemView
   */
  languages?: Array<ContentItemViewLanguagesEnum>;
  /**
   *
   * @type {string}
   * @memberof ContentItemView
   */
  lastActivityDate: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ContentItemView
   */
  permissions: Array<ContentItemViewPermissionsEnum>;
  /**
   *
   * @type {string}
   * @memberof ContentItemView
   */
  status: ContentItemViewStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ContentItemView
   */
  title: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ContentItemViewDataTypeEnum {
  DOCUMENT = 'DOCUMENT',
  FOLDER = 'FOLDER',
  GROUP = 'GROUP',
  PERSON = 'PERSON',
  PARENT_FOR_GROUP = 'PARENT_FOR_GROUP',
}
/**
 * @export
 * @enum {string}
 */
export enum ContentItemViewLanguagesEnum {
  ENGLISH = 'ENGLISH',
  RUSSIAN = 'RUSSIAN',
}
/**
 * @export
 * @enum {string}
 */
export enum ContentItemViewPermissionsEnum {
  WRITE_GROUP_FOLDER = 'WRITE_GROUP_FOLDER',
  WRITE_PERSON = 'WRITE_PERSON',
  READ_DOCUMENT_LANGUAGES = 'READ_DOCUMENT_LANGUAGES',
}
/**
 * @export
 * @enum {string}
 */
export enum ContentItemViewStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
