import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PaperIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M31.3482 15.035C30.5957 15.035 29.5989 15.0184 28.3579 15.0184C25.3313 15.0184 22.8427 12.5134 22.8427 9.45837V4.09837C22.8427 3.67671 22.5061 3.33337 22.0886 3.33337H13.2729C9.15877 3.33337 5.8335 6.71004 5.8335 10.8484V28.8067C5.8335 33.1484 9.3172 36.6667 13.6161 36.6667H26.744C30.8432 36.6667 34.1668 33.3117 34.1668 29.17V15.785C34.1668 15.3617 33.8318 15.02 33.4127 15.0217C32.708 15.0267 31.8631 15.035 31.3482 15.035Z"
        fill="#DAD8DB"
      />
      <path
        d="M26.8068 4.27883C26.3085 3.76049 25.4385 4.11716 25.4385 4.83549V9.23049C25.4385 11.0738 26.9568 12.5905 28.8001 12.5905C29.9618 12.6038 31.5751 12.6072 32.9451 12.6038C33.6468 12.6022 34.0035 11.7638 33.5168 11.2572C31.7585 9.42883 28.6101 6.15216 26.8068 4.27883Z"
        fill="#DAD8DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9568 18.9793H20.5985C21.2835 18.9793 21.8401 18.4243 21.8401 17.7393C21.8401 17.0543 21.2835 16.4977 20.5985 16.4977H14.9568C14.2718 16.4977 13.7168 17.0543 13.7168 17.7393C13.7168 18.4243 14.2718 18.9793 14.9568 18.9793ZM14.957 27.303H24.0303C24.7153 27.303 25.272 26.748 25.272 26.063C25.272 25.378 24.7153 24.8213 24.0303 24.8213H14.957C14.272 24.8213 13.717 25.378 13.717 26.063C13.717 26.748 14.272 27.303 14.957 27.303Z"
        fill="#31323A"
      />
    </SvgIcon>
  );
}

export default PaperIcon;
