import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { FolderControllerApiFactory, FolderView } from 'api/generated';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const RESOURCE_NAME = 'folder';

const FolderApi = FolderControllerApiFactory(ApiConfiguration);

type FolderShowProviderType = QueryObserverResult<FolderView, unknown>;

const FolderShowContext = createContext<FolderShowProviderType | null>(null);
FolderShowContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useFolderShowProvider(): FolderShowProviderType {
  const contextState = useContext(FolderShowContext);
  if (isNil(contextState)) {
    throw new Error(`${useFolderShowProvider.name} must be used within a ${FolderShowContext.displayName} context`);
  }
  return contextState;
}

interface FolderShowProviderProps {
  businessUnitId: number;
  sectionId: number;
  folderId?: number;
  queryOptions?: any;
}

export function FolderShowProvider(props: React.PropsWithChildren<FolderShowProviderProps>) {
  const value = useQuery<FolderView | null, AxiosError<unknown>, FolderView>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId, sectionId: props.sectionId, folderId: props.folderId }],
    () => FolderApi.getFolderById2(props.businessUnitId, props.sectionId, props.folderId!).then(resp => resp.data),
    { enabled: !isNil(props.folderId), ...props.queryOptions }
  );
  return <FolderShowContext.Provider value={value}>{props.children}</FolderShowContext.Provider>;
}
