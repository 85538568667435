import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { GroupFolderControllerApiFactory, GroupFolderUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

type GroupDetailsWithUpdateProviderType = QueryObserverResult<GroupFolderUpdateRequest, unknown>;

const GroupDetailsWithUpdateContext = createContext<GroupDetailsWithUpdateProviderType | null>(null);
GroupDetailsWithUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}DetailsWithUpdate`;

export function useGroupDetailsWithUpdateProvider(): GroupDetailsWithUpdateProviderType {
  const contextState = useContext(GroupDetailsWithUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useGroupDetailsWithUpdateProvider.name} must be used within a ${GroupDetailsWithUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface GroupDetailsWithUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
}
export function GroupDetailsWithUpdateProvider(props: React.PropsWithChildren<GroupDetailsWithUpdateProviderProps>) {
  const value = useQuery<GroupFolderUpdateRequest>([RESOURCE_NAME, { id: props.groupFolderId, edit: true }], () =>
    GroupFolderApi.getGroupFolderForEdit(props.businessUnitId, props.sectionId, props.groupFolderId).then(
      resp => resp.data
    )
  );
  return (
    <GroupDetailsWithUpdateContext.Provider value={value}>{props.children}</GroupDetailsWithUpdateContext.Provider>
  );
}
