import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getFileOfDocumentURL } from 'api/http';
import { DocumentCardMetaViewLanguageEnum } from 'api/generated';
import {
  DocumentArchiveProvider,
  DocumentDeleteProvider,
  useDocumentArchiveProvider,
} from 'api/DocumentProviders/Documents';
import { useErrorCatcher } from 'api/notifications';

import { Modal } from 'components/material';
import { makeParentUrl } from 'utils';

import {
  DocumentCardProvider,
  useDocumentCardProvider,
} from '../../../api/DocumentProviders/Documents/DocumentCardProvider';
import { DocumentRequiredRouterParams } from './interfaces';
import find from 'lodash/find';
import { DeleteDocumentConfirmDialog } from './DeleteDocumentConfirmDialog';
import { DocumentCard, DocumentCardData } from './DocumentCard';
import { EnableNotificationsDialog } from '../InfoDIalogs/EnableNotificationsDialog';

export function DocumentCardDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<DocumentRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const documentId = parseInt(match.params.documentId || '', 10);

  const closeHandler = () => routeProps.history.replace(makeParentUrl(match.url, 3));

  return (
    <Modal
      title={t('documents:dialog.title')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={DocumentCardDialog.name}
    >
      <DocumentCardProvider documentId={documentId} businessUnitId={businessUnitId} sectionId={sectionId}>
        <DocumentArchiveProvider
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          documentId={documentId}
          queryOptions={{ onError: catchError }}
        >
          <DocumentUpdate businessUnitId={businessUnitId} sectionId={sectionId} documentId={documentId} />
        </DocumentArchiveProvider>
      </DocumentCardProvider>
      <EnableNotificationsDialog />
    </Modal>
  );
}

interface DocumentUpdateProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
}
function DocumentUpdate({ businessUnitId, sectionId, documentId }: DocumentUpdateProps) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    controllers: {
      archive: { mutateAsync: archive },
      unarchive: { mutateAsync: unarchive },
    },
  } = useDocumentArchiveProvider();

  const [openConfirm, setOpenConfirm] = useState(false);
  const { data: document } = useDocumentCardProvider();

  const ruDocumentMeta = find(document?.documentMetas, { language: DocumentCardMetaViewLanguageEnum.RUSSIAN });
  const enDocumentMeta = find(document?.documentMetas, { language: DocumentCardMetaViewLanguageEnum.ENGLISH });

  const cardData: DocumentCardData = {
    name: ruDocumentMeta?.title,
    nameEn: enDocumentMeta?.title,
    pdfFile: ruDocumentMeta?.pdfFileMetaView
      ? {
          ...ruDocumentMeta?.pdfFileMetaView,
          downloadUrl: getFileOfDocumentURL(businessUnitId, sectionId, documentId, ruDocumentMeta?.pdfFileMetaView.id),
        }
      : undefined,
    originalFile: ruDocumentMeta?.originalFileMetaView
      ? {
          ...ruDocumentMeta?.originalFileMetaView,
          downloadUrl: getFileOfDocumentURL(
            businessUnitId,
            sectionId,
            documentId,
            ruDocumentMeta?.originalFileMetaView.id
          ),
        }
      : undefined,
    pdfFileEn: enDocumentMeta?.pdfFileMetaView
      ? {
          ...enDocumentMeta?.pdfFileMetaView,
          downloadUrl: getFileOfDocumentURL(businessUnitId, sectionId, documentId, enDocumentMeta?.pdfFileMetaView.id),
        }
      : undefined,
    originalFileEn: enDocumentMeta?.originalFileMetaView
      ? {
          ...enDocumentMeta?.originalFileMetaView,
          downloadUrl: getFileOfDocumentURL(
            businessUnitId,
            sectionId,
            documentId,
            enDocumentMeta?.originalFileMetaView.id
          ),
        }
      : undefined,
    status: document?.status,
  };

  const onArchive = useCallback(() => {
    if (document) {
      archive(document.version).then(() => history.push(makeParentUrl(match.url, 3)));
    }
  }, [archive, history, match.url, document]);

  const onUnarchive = useCallback(() => {
    if (document) {
      unarchive(document.version).then(() => history.push(makeParentUrl(match.url, 3)));
    }
  }, [history, match.url, document, unarchive]);

  const handleRemove = useCallback(() => {
    if (document) {
      setOpenConfirm(true);
    }
  }, [document]);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const onDelete = useCallback(() => {
    setOpenConfirm(false);
    history.push(makeParentUrl(match.url, 3));
  }, [history, match.url]);

  return (
    <>
      <DocumentCard
        data={cardData}
        editLabel={t('documents:form.edit')}
        onArchive={onArchive}
        archiveLabel={t('documents:form.archive')}
        onUnarchive={onUnarchive}
        unarchiveLabel={t('documents:form.unarchive')}
        removeLabel={t('documents:actionsMenu.remove')}
        onRemove={handleRemove}
      />
      <DocumentDeleteProvider businessUnitId={businessUnitId} sectionId={sectionId} documentId={documentId}>
        <DeleteDocumentConfirmDialog open={openConfirm} onDelete={onDelete} onClose={closeConfirmDialog} />
      </DocumentDeleteProvider>
    </>
  );
}
