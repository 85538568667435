import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';

import { CustomInput, CustomLabel } from './CustomInput';

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_URL = 'url';
export const TYPE_TELEPHONE = 'tel';
export const TYPE_DATE = 'date';
export const TYPE_DATETIME_LOCAL = 'datetime-local';
export const TYPE_MONTH = 'month';
export const TYPE_TIME = 'time';
export const TYPE_WEEK = 'week';

// Restricts the type values to 'password', 'text', 'email', 'number', and 'url'.
export type TEXT_FIELD_TYPE =
  | typeof TYPE_PASSWORD
  | typeof TYPE_TEXT
  | typeof TYPE_EMAIL
  | typeof TYPE_NUMBER
  | typeof TYPE_URL
  | typeof TYPE_TELEPHONE
  | typeof TYPE_DATE
  | typeof TYPE_DATETIME_LOCAL
  | typeof TYPE_MONTH
  | typeof TYPE_TIME
  | typeof TYPE_WEEK;

export type TextFieldProps<FieldValue = any> = Partial<Omit<MuiTextFieldProps, 'type' | 'onChange'>> & {
  name: string;
  type?: TEXT_FIELD_TYPE;
  fieldProps?: Partial<FieldProps<FieldValue, any>>;
  showError?: ShowErrorFunc;
  endAdornment?: React.ReactNode;
  /**
   * Transform value right after input.
   */
  valueFilter?: (newValue: FieldValue, preValue: FieldValue) => FieldValue;
};

export function TextField(props: TextFieldProps) {
  const { name, type, fieldProps, valueFilter, ...rest } = props;

  return (
    <Field
      name={name}
      type={type}
      render={({ input, meta }) => (
        <TextFieldWrapper input={input} meta={meta} name={name} type={type} valueFilter={valueFilter} {...rest} />
      )}
      {...fieldProps}
    />
  );
}
type TextWrapperProps = FieldRenderProps<MuiTextFieldProps, HTMLElement> & Pick<TextFieldProps, 'valueFilter'>;
export function TextFieldWrapper(props: TextWrapperProps) {
  const {
    input: { name, value, type, onChange: originalOnChange, onBlur, onFocus, ...restInput },
    meta,
    required,
    fullWidth = true,
    helperText,
    showError = showErrorOnChange,
    label,
    margin,
    variant,
    valueFilter,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });
  const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = valueFilter
    ? e => {
        e.currentTarget.value = valueFilter(e.currentTarget.value, value);
        originalOnChange(e);
      }
    : originalOnChange;

  return (
    <FormControl error={isError} margin={margin} variant={variant} fullWidth={fullWidth} required={required}>
      <CustomLabel shrink variant="standard">
        {label}
      </CustomLabel>
      <CustomInput
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        value={value || ''}
        type={type}
        inputProps={{ required, ...restInput }}
        {...rest}
      />
      <FormHelperText>{isError ? error || submitError : helperText}</FormHelperText>
    </FormControl>
  );
}
