import React, { useCallback } from 'react';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import List from '@material-ui/core/List';

import { UserSessionStateViewRoleEnum } from 'api/generated';
import { useProjectsListProvider } from 'api/ProjectsProviders';
import { useBusinessUnitOrderUpdateProvider } from 'api/BusinessUnitProviders';
import { useAuthProvider } from 'api/AuthProviders';

import { Spinner } from 'components/material';
import { EmptyProjectsList } from './EmptyProjectsList';
import { SortableBusinessUnitListItem } from '../../All/BusinessUnits';
import { basePath } from '../ProjectsRouter';

import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';

interface ProjectsListProps {
  currentProjectId: number | null;
}
export function ProjectsList(props: ProjectsListProps) {
  const { role } = useAuthProvider();
  const isAdmin = role ? [UserSessionStateViewRoleEnum.ROOT, UserSessionStateViewRoleEnum.ADMIN].includes(role) : false;

  const { data: projectsList, isLoading, isFetching, isFetched } = useProjectsListProvider();

  const {
    controller: { mutateAsync: updateOrder, isLoading: isUpdatingOrder },
  } = useBusinessUnitOrderUpdateProvider();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = useCallback(
    event => {
      const { active, over } = event;
      if (active.id !== over.id && !isUpdatingOrder) {
        const fromIndex = findIndex(projectsList, { id: parseInt(active.id, 10) });
        const toIndex = findIndex(projectsList, { id: parseInt(over.id, 10) });
        updateOrder({ from: fromIndex, to: toIndex });
      }
    },
    [isUpdatingOrder, projectsList, updateOrder]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(projectsList) && isFetched) {
    return <EmptyProjectsList />;
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <List component="nav" aria-label="projects" data-test="projects_list">
        <SortableContext
          id={ProjectsList.name}
          items={map(projectsList, item => item.id.toString())}
          strategy={verticalListSortingStrategy}
        >
          {projectsList?.map(project => (
            <SortableBusinessUnitListItem
              key={project.id}
              basePath={basePath}
              currentBusinessUnitId={props.currentProjectId}
              businessUnit={project}
              hasDnd={isAdmin}
              disabledDnd={isUpdatingOrder || isFetching}
            />
          ))}
        </SortableContext>
      </List>
    </DndContext>
  );
}
