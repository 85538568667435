import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { CompanyCreateProvider, useCompanyCreateProvider } from 'api/CompaniesProviders';
import {
  NewBusinessUnitAccessProvider,
  BusinessUnitUpdateAccessByPersonsProvider,
  useNewBusinessUnitAccessProvider,
  useBusinessUnitUpdateAccessByPersonsProvider,
} from 'api/BusinessUnitProviders';
import { BusinessUnitUpdateRequest } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { makeParentUrl } from 'utils';
import { Modal } from 'components/material';
import { CompanyFormFieldsEnum, CompanyForm } from './CompanyForm';
import { basePath } from '../../CompaniesRouter';

import map from 'lodash/map';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

export function AddCompanyDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const match = routeProps.match;
  const catchError = useErrorCatcher();

  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url));

  return (
    <Modal
      title={t('companies:dialog.newCompany')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={AddCompanyDialog.name}
    >
      <CompanyCreateProvider queryOptions={{ onError: catchError }}>
        <NewBusinessUnitAccessProvider type="COMPANY">
          <BusinessUnitUpdateAccessByPersonsProvider>
            <CompanyCreate />
          </BusinessUnitUpdateAccessByPersonsProvider>
        </NewBusinessUnitAccessProvider>
      </CompanyCreateProvider>
    </Modal>
  );
}

function CompanyCreate() {
  const history = useHistory();
  const { mutateAsync: createCompany } = useCompanyCreateProvider();

  const { mutateAsync: updateAccess } = useBusinessUnitUpdateAccessByPersonsProvider();
  const {
    readers: { data: readers, isFetched: isReadersFetched },
    responsible: { data: responsible, isFetched: isResponsibleFetched },
  } = useNewBusinessUnitAccessProvider();
  const initialAllowIds = map(filter(readers, ['access', true]), 'accountDisplayView.id');
  const initialResponsibleIds = map(filter(responsible, ['access', true]), 'accountDisplayView.id');

  const onSubmit = useCallback(
    values => {
      return createCompany(values)
        .then(({ businessUnitId }: BusinessUnitUpdateRequest) => {
          if (
            !isEqual(initialAllowIds, values[CompanyFormFieldsEnum.accountAllowIds]) ||
            !isEqual(initialResponsibleIds, values[CompanyFormFieldsEnum.responsibleIds])
          ) {
            const updateRequest = {
              businessUnitId,
              readerAccess: map(readers, item => ({
                accountId: item.accountDisplayView.id,
                access: values[CompanyFormFieldsEnum.accountAllowIds]?.includes(item.accountDisplayView.id) || false,
              })),
              responsibleAccess: map(responsible, item => ({
                accountId: item.accountDisplayView.id,
                access: values[CompanyFormFieldsEnum.responsibleIds]?.includes(item.accountDisplayView.id) || false,
              })),
            };
            updateAccess(updateRequest);
          }
          return businessUnitId;
        })
        .then(businessUnitId => {
          history.push(`/${basePath}/${businessUnitId}`);
        });
    },
    [createCompany, initialAllowIds, initialResponsibleIds, readers, responsible, updateAccess, history]
  );
  if (!isReadersFetched || !isResponsibleFetched || !responsible || !readers) {
    return null;
  }
  return (
    <CompanyForm
      onSubmit={onSubmit}
      type="CREATE"
      personsList={readers}
      initialAllowIds={initialAllowIds}
      responsibleList={responsible}
      responsibleIds={initialResponsibleIds}
    />
  );
}
