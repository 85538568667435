import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { EmailIcon } from 'components/icons';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export function SupportBlock({ show }: { show?: boolean }) {
  const { t } = useTranslation();

  const classes = useStyles();

  return show ? (
    <Box className={classes.root}>
      <Box className={classes.emailRoot}>
        <Box>
          <EmailIcon className={classes.emailIcon} />
        </Box>
        <Box>{t('login:writeToEmail')}</Box>
        <Box className={classes.emailLink}>
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </Box>
      </Box>
    </Box>
  ) : null;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 420,
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: 19,
    background: theme.palette.background.paper,
  },
  emailRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,
  },
  emailIcon: {
    display: 'block',
    marginRight: theme.spacing(2),
  },
  emailLink: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },
}));
