/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BooleanResultView } from '../views';
// @ts-ignore
import { SearchDocumentView } from '../views';
// @ts-ignore
import { SearchPersonIsExistsRequest } from '../views';
// @ts-ignore
import { SearchPersonRequest } from '../views';
// @ts-ignore
import { SearchPersonSimpleView } from '../views';
// @ts-ignore
import { SearchPersonView } from '../views';
// @ts-ignore
import { SearchRequest } from '../views';
// @ts-ignore
import { SearchRequestWithPaging } from '../views';
// @ts-ignore
import { SearchView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search
     * @param {SearchRequest} searchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search: async (searchRequest: SearchRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'searchRequest' is not null or undefined
      assertParamExists('search', 'searchRequest', searchRequest);
      const localVarPath = `/search/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search document
     * @param {SearchRequestWithPaging} searchRequestWithPaging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDocument: async (
      searchRequestWithPaging: SearchRequestWithPaging,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchRequestWithPaging' is not null or undefined
      assertParamExists('searchDocument', 'searchRequestWithPaging', searchRequestWithPaging);
      const localVarPath = `/search/document`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchRequestWithPaging,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search person by full name
     * @param {SearchRequestWithPaging} searchRequestWithPaging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPersonByFullName: async (
      searchRequestWithPaging: SearchRequestWithPaging,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchRequestWithPaging' is not null or undefined
      assertParamExists('searchPersonByFullName', 'searchRequestWithPaging', searchRequestWithPaging);
      const localVarPath = `/search/person/by-full-name`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchRequestWithPaging,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search persons by second name
     * @param {SearchPersonRequest} searchPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPersonBySecondName: async (
      searchPersonRequest: SearchPersonRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchPersonRequest' is not null or undefined
      assertParamExists('searchPersonBySecondName', 'searchPersonRequest', searchPersonRequest);
      const localVarPath = `/search/persons/by-second-name`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchPersonRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search person is exists
     * @param {SearchPersonIsExistsRequest} searchPersonIsExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPersonIsExists: async (
      searchPersonIsExistsRequest: SearchPersonIsExistsRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchPersonIsExistsRequest' is not null or undefined
      assertParamExists('searchPersonIsExists', 'searchPersonIsExistsRequest', searchPersonIsExistsRequest);
      const localVarPath = `/search/personIsExists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchPersonIsExistsRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Search
     * @param {SearchRequest} searchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async search(
      searchRequest: SearchRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.search(searchRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search document
     * @param {SearchRequestWithPaging} searchRequestWithPaging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchDocument(
      searchRequestWithPaging: SearchRequestWithPaging,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchDocumentView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchDocument(searchRequestWithPaging, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search person by full name
     * @param {SearchRequestWithPaging} searchRequestWithPaging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPersonByFullName(
      searchRequestWithPaging: SearchRequestWithPaging,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchPersonView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersonByFullName(
        searchRequestWithPaging,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search persons by second name
     * @param {SearchPersonRequest} searchPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPersonBySecondName(
      searchPersonRequest: SearchPersonRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchPersonSimpleView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersonBySecondName(searchPersonRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Search person is exists
     * @param {SearchPersonIsExistsRequest} searchPersonIsExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPersonIsExists(
      searchPersonIsExistsRequest: SearchPersonIsExistsRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResultView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersonIsExists(
        searchPersonIsExistsRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SearchControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Search
     * @param {SearchRequest} searchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(searchRequest: SearchRequest, options?: any): AxiosPromise<SearchView> {
      return localVarFp.search(searchRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search document
     * @param {SearchRequestWithPaging} searchRequestWithPaging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDocument(
      searchRequestWithPaging: SearchRequestWithPaging,
      options?: any
    ): AxiosPromise<Array<SearchDocumentView>> {
      return localVarFp.searchDocument(searchRequestWithPaging, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search person by full name
     * @param {SearchRequestWithPaging} searchRequestWithPaging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPersonByFullName(
      searchRequestWithPaging: SearchRequestWithPaging,
      options?: any
    ): AxiosPromise<Array<SearchPersonView>> {
      return localVarFp
        .searchPersonByFullName(searchRequestWithPaging, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search persons by second name
     * @param {SearchPersonRequest} searchPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPersonBySecondName(
      searchPersonRequest: SearchPersonRequest,
      options?: any
    ): AxiosPromise<Array<SearchPersonSimpleView>> {
      return localVarFp
        .searchPersonBySecondName(searchPersonRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search person is exists
     * @param {SearchPersonIsExistsRequest} searchPersonIsExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPersonIsExists(
      searchPersonIsExistsRequest: SearchPersonIsExistsRequest,
      options?: any
    ): AxiosPromise<BooleanResultView> {
      return localVarFp
        .searchPersonIsExists(searchPersonIsExistsRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SearchControllerApi - interface
 * @export
 * @interface SearchControllerApi
 */
export interface SearchControllerApiInterface {
  /**
   *
   * @summary Search
   * @param {SearchRequest} searchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApiInterface
   */
  search(searchRequest: SearchRequest, options?: any): AxiosPromise<SearchView>;

  /**
   *
   * @summary Search document
   * @param {SearchRequestWithPaging} searchRequestWithPaging
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApiInterface
   */
  searchDocument(
    searchRequestWithPaging: SearchRequestWithPaging,
    options?: any
  ): AxiosPromise<Array<SearchDocumentView>>;

  /**
   *
   * @summary Search person by full name
   * @param {SearchRequestWithPaging} searchRequestWithPaging
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApiInterface
   */
  searchPersonByFullName(
    searchRequestWithPaging: SearchRequestWithPaging,
    options?: any
  ): AxiosPromise<Array<SearchPersonView>>;

  /**
   *
   * @summary Search persons by second name
   * @param {SearchPersonRequest} searchPersonRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApiInterface
   */
  searchPersonBySecondName(
    searchPersonRequest: SearchPersonRequest,
    options?: any
  ): AxiosPromise<Array<SearchPersonSimpleView>>;

  /**
   *
   * @summary Search person is exists
   * @param {SearchPersonIsExistsRequest} searchPersonIsExistsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApiInterface
   */
  searchPersonIsExists(
    searchPersonIsExistsRequest: SearchPersonIsExistsRequest,
    options?: any
  ): AxiosPromise<BooleanResultView>;
}

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI implements SearchControllerApiInterface {
  /**
   *
   * @summary Search
   * @param {SearchRequest} searchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public search(searchRequest: SearchRequest, options?: any) {
    return SearchControllerApiFp(this.configuration)
      .search(searchRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search document
   * @param {SearchRequestWithPaging} searchRequestWithPaging
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public searchDocument(searchRequestWithPaging: SearchRequestWithPaging, options?: any) {
    return SearchControllerApiFp(this.configuration)
      .searchDocument(searchRequestWithPaging, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search person by full name
   * @param {SearchRequestWithPaging} searchRequestWithPaging
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public searchPersonByFullName(searchRequestWithPaging: SearchRequestWithPaging, options?: any) {
    return SearchControllerApiFp(this.configuration)
      .searchPersonByFullName(searchRequestWithPaging, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search persons by second name
   * @param {SearchPersonRequest} searchPersonRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public searchPersonBySecondName(searchPersonRequest: SearchPersonRequest, options?: any) {
    return SearchControllerApiFp(this.configuration)
      .searchPersonBySecondName(searchPersonRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search person is exists
   * @param {SearchPersonIsExistsRequest} searchPersonIsExistsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public searchPersonIsExists(searchPersonIsExistsRequest: SearchPersonIsExistsRequest, options?: any) {
    return SearchControllerApiFp(this.configuration)
      .searchPersonIsExists(searchPersonIsExistsRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
