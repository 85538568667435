import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { PersonControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';
import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from '../SectionsProviders/constants';
import { RESOURCE_NAME as SEARCH_RESOURCE_NAME } from '../SearchProviders/constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const PersonApi = PersonControllerApiFactory(ApiConfiguration);

export interface PersonDeleteRequest {
  personId?: number;
}

type PersonDeleteProviderType = UseMutationResult<object, AxiosError<unknown>, PersonDeleteRequest, unknown>;

const PersonDeleteContext = createContext<PersonDeleteProviderType | null>(null);
PersonDeleteContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function usePersonDeleteProvider(): PersonDeleteProviderType {
  const contextState = useContext(PersonDeleteContext);
  if (isNil(contextState)) {
    throw new Error(`${usePersonDeleteProvider.name} must be used within a ${PersonDeleteContext.displayName} context`);
  }
  return contextState;
}

interface PersonDeleteProviderProps {
  businessUnitId: number;
  personId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, PersonDeleteRequest, unknown>;
}
export function PersonDeleteProvider(props: React.PropsWithChildren<PersonDeleteProviderProps>) {
  const queryClient = useQueryClient();
  const removeDefaultOptions: PersonDeleteProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries([SECTION_RESOURCE_NAME, props.businessUnitId]);
      queryClient.invalidateQueries([SEARCH_RESOURCE_NAME]);
    },
  };
  const remove = useMutation<object, AxiosError<unknown>, PersonDeleteRequest>(
    async ({ personId }: PersonDeleteRequest) => {
      return PersonApi.deletePersonByBusinessUnit(props.businessUnitId, personId || props.personId).then(
        resp => resp.data
      );
    },
    {
      ...removeDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <PersonDeleteContext.Provider value={remove}>{props.children}</PersonDeleteContext.Provider>;
}
