import React from 'react';

import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { FolderUpdateRequest } from 'api/generated';

import { TextField } from 'components/fields';
import { ModalToolbar } from 'components/material';
import { composeValidators, required, maxLength } from 'components/fields/validators';
import trim from 'utils/trim';

interface FolderFormProps {
  initialValues?: FolderUpdateRequest;
  onSubmit: any;
  type: 'CREATE' | 'EDIT';
}

export function FolderForm({ initialValues, onSubmit, type }: FolderFormProps) {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box pb={2}>
            <TextField
              label={t('companies:dialog.fields.titleRu')}
              placeholder={t('companies:dialog.fields.titleRuPlaceholder')}
              name="titleRu"
              margin="dense"
              required
              fieldProps={{
                validate: titleValidator,
                format: trim,
                formatOnBlur: true,
              }}
            />
            <TextField
              label={t('companies:dialog.fields.titleEn')}
              placeholder={t('companies:dialog.fields.titleEnPlaceholder')}
              name="titleEn"
              margin="dense"
              required
              fieldProps={{
                validate: titleValidator,
                format: trim,
                formatOnBlur: true,
              }}
            />
          </Box>
          <Box mx={-4}>
            <ModalToolbar>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                  {type === 'CREATE' ? t('documents:folder.dialog.create') : t('companies:dialog.save')}
                </Button>
                {type === 'EDIT' && (
                  <Button variant="text" color="secondary">
                    {t('documents:folder.dialog.delete')}
                  </Button>
                )}
              </Box>
            </ModalToolbar>
          </Box>
        </form>
      )}
    />
  );
}

const titleValidator = composeValidators(required, maxLength(120));

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
    '& .MuiFormControl-marginDense': {
      marginBottom: theme.spacing(1),
    },
  },
}));
