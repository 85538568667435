import React, { createContext, useContext, useRef } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

import {
  CodeLoginRequest,
  LoginApiFactory,
  PhoneLoginRequest,
  PhoneOnlyLoginResponse,
  UrlView,
  VendorErrorResponse,
} from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const LoginApi = LoginApiFactory(ApiConfiguration);

type MobileAppsDownloadProviderType = {
  controllers: {
    loginPhone: UseMutationResult<
      PhoneOnlyLoginResponse | VendorErrorResponse,
      AxiosError<unknown>,
      PhoneLoginRequest,
      unknown
    >;
    downloadLink: UseMutationResult<UrlView, AxiosError<unknown>, CodeLoginRequest, unknown>;
  };
};

const MobileAppsDownloadContext = createContext<MobileAppsDownloadProviderType | null>(null);
MobileAppsDownloadContext.displayName = `${upperFirst(RESOURCE_NAME)}Download`;

export function useMobileAppsDownloadProvider(): MobileAppsDownloadProviderType {
  const contextState = useContext(MobileAppsDownloadContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMobileAppsDownloadProvider.name} must be used within a ${MobileAppsDownloadContext.displayName} context`
    );
  }
  return contextState;
}

interface MobileAppsDownloadProviderProps {}
export function MobileAppsDownloadProvider(props: React.PropsWithChildren<MobileAppsDownloadProviderProps>) {
  const loginPhoneRef = useRef(
    useMutation<PhoneOnlyLoginResponse | VendorErrorResponse, AxiosError<unknown>, PhoneLoginRequest, unknown>(
      (params: PhoneLoginRequest) => LoginApi.processPhone(params).then(resp => (resp.data as any).data || resp.data)
    )
  );
  const downloadLinkRef = useRef(
    useMutation<UrlView, AxiosError<unknown>, CodeLoginRequest, unknown>((params: CodeLoginRequest) =>
      LoginApi.getDownloadLink(params).then(resp => (resp.data as any).data || resp.data)
    )
  );
  return (
    <MobileAppsDownloadContext.Provider
      value={{
        controllers: {
          loginPhone: loginPhoneRef.current,
          downloadLink: downloadLinkRef.current,
        },
      }}
    >
      {props.children}
    </MobileAppsDownloadContext.Provider>
  );
}
