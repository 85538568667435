import React from 'react';

import { useSimplePersonsListProvider } from 'api/PersonsProvider';
import { PersonList } from 'components/lists/PersonList';

export function CommonPersonsList({ businessUnitId }: { businessUnitId: number }) {
  const { data: persons, isLoading } = useSimplePersonsListProvider();

  return <PersonList businessUnitId={businessUnitId} persons={persons} isLoading={isLoading} />;
}
