import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { SecretariesListProvider } from 'api/SecretariesProvider';

import { SecretariesList } from '../SecretariesList';
import { AccountsRequiredRouterParams } from '../../interfaces';

export function Secretaries({ readOnly = false }: { readOnly?: boolean }) {
  const { t } = useTranslation();

  const match = useRouteMatch<AccountsRequiredRouterParams>();
  const accountId = parseInt(match.params.accountId || '', 10);

  return (
    <Box alignSelf="center" display="flex" flexDirection="column" width="100%" mt={6}>
      <Box display="flex" alignItems="center" mb={2}>
        <Box>
          <Typography variant="subtitle1">{t('accounts:list.secretaries')}</Typography>
        </Box>
        {!readOnly && (
          <Box ml={3}>
            <Button color="primary" variant="text" size="small" component={Link} to={`${match.url}/add_secretary`}>
              {`+ ${t('accounts:addSecretary')}`}
            </Button>
          </Box>
        )}
      </Box>
      <Box flexGrow={1}>
        <SecretariesListProvider accountId={accountId}>
          <SecretariesList />
        </SecretariesListProvider>
      </Box>
    </Box>
  );
}
