import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';

import { ProjectsPage } from './ProjectsPage';
import { AddProjectDialog } from './ProjectsList/ProjectCard/AddProjectDialog';
import { EditProjectDialog } from './ProjectsList/ProjectCard/EditProjectDialog';
import { BusinessUnitsCommonRoutes } from 'pages/BusinessUnitsCommonRoutes/BusinessUnitsCommonRoutes';

export const basePath = 'projects';

export function ProjectsRouter() {
  const { path } = useRouteMatch();

  return (
    <Route
      path={[
        `${path}/:businessUnitId?/section/:sectionId?/parentFolderId/:parentFolderId?`,
        `${path}/:businessUnitId?/section/:sectionId?/group/:groupFolderId?`,
        `${path}/:businessUnitId?/section/:sectionId?`,
        `${path}/:businessUnitId?`,
      ]}
    >
      <ProjectsPage />
      <Route
        path={[`${path}/new`, `${path}/*/new`]}
        render={routeProps => <AddProjectDialog routeProps={routeProps} />}
      />
      <Route
        path={`${path}/:businessUnitId/edit`}
        render={routeProps => <EditProjectDialog routeProps={routeProps} />}
      />
      <BusinessUnitsCommonRoutes path={path} structType="PROJECT" />
    </Route>
  );
}
