import React, { useCallback } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useAccountsListProvider } from 'api/AccountsProviders';

import { Spinner } from 'components/material/Spinner';
import EmptyAccountsListIcon from 'components/icons/EmptyAccountsListIcon';

import { formatPhone } from 'utils/phone';

import { AccountName } from './AccountName';
import { AccountRoles } from './AccountRoles';
import { AccountState } from './chips/AccountState';

import isEmpty from 'lodash/isEmpty';

interface AccountsListProps {}
export function AccountsList(props: AccountsListProps) {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const { data: accounts, isLoading, isFetched } = useAccountsListProvider();

  const goToDetailsPage = useCallback(
    accountId => {
      history.push(`${url}/${accountId}`);
    },
    [history, url]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(accounts) && isFetched) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Box display="flex" alignItems="center" justifyContent="center" fontSize="8rem" color="grey.300">
          <EmptyAccountsListIcon fontSize="inherit" />
        </Box>
        <Box color="grey.500">
          <Typography variant="h6">{t('accounts:list.emptyAccounts')}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <TableContainer component={Box} position="relative">
      <Table aria-label="accounts table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('accounts:list.name')}</TableCell>
            <TableCell align="left">{t('accounts:list.loginPhone')}</TableCell>
            <TableCell align="right">{t('accounts:list.role')}</TableCell>
            <TableCell align="right">{t('accounts:list.state')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(accounts || []).map(account => (
            <TableRow key={account.id} className={classes.tableRow} hover onClick={() => goToDetailsPage(account.id)}>
              <TableCell>
                <AccountName account={account} />
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle1">{account.loginPhone ? formatPhone(account.loginPhone) : ''}</Typography>
              </TableCell>
              <TableCell align="right">
                <AccountRoles roles={account.allowableRoles} variant="body1" />
              </TableCell>
              <TableCell align="right">
                <AccountState state={account.state} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
  },
}));
