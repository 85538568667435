import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function EmailIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="28" height="22" viewBox="0 0 28 22" color="inherit" fill="none" {...props}>
      <path
        d="M4.33967 21.5H24.0272C26.2283 21.5 27.5 20.2324 27.5 17.7217V4.26611C27.5 1.76756 26.216 0.5 23.6603 0.5H3.97283C1.77174 0.5 0.5 1.75537 0.5 4.26611V17.7217C0.5 20.2446 1.78397 21.5 4.33967 21.5ZM12.5448 11.6155L3.3125 2.5354C3.58152 2.42571 3.89946 2.36477 4.2663 2.36477H23.7337C24.1128 2.36477 24.4429 2.42571 24.712 2.55978L15.5041 11.6155C14.9783 12.1396 14.5014 12.3712 14.0245 12.3712C13.5353 12.3712 13.0584 12.1396 12.5448 11.6155ZM2.37092 17.7217V4.26611C2.37092 4.18079 2.37092 4.21735 2.37092 4.14423L9.40217 11.0183L2.38315 17.9289C2.37092 17.868 2.37092 17.7948 2.37092 17.7217ZM25.6291 4.27829V17.7339C25.6291 17.7826 25.6291 17.8436 25.6291 17.8923L18.6467 11.0183L25.6291 4.18079C25.6291 4.27829 25.6291 4.27829 25.6291 4.27829ZM4.2663 19.6352C3.92391 19.6352 3.61821 19.5865 3.37364 19.4768L10.6861 12.2737L11.481 13.0537C12.337 13.8947 13.1562 14.2481 14.0245 14.2481C14.8804 14.2481 15.712 13.8947 16.5679 13.0537L17.3628 12.2737L24.663 19.4646C24.394 19.5865 24.0883 19.6352 23.7337 19.6352H4.2663Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default EmailIcon;
