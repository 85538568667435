import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const ProjectShowApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type ProjectShowProviderType = QueryObserverResult<BusinessUnitView, unknown>;

const ProjectShowContext = createContext<ProjectShowProviderType | null>(null);
ProjectShowContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useProjectShowProvider(): ProjectShowProviderType {
  const contextState = useContext(ProjectShowContext);
  if (isNil(contextState)) {
    throw new Error(`${useProjectShowProvider.name} must be used within a ${ProjectShowContext.displayName} context`);
  }
  return contextState;
}

interface ProjectShowProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function ProjectShowProvider(props: React.PropsWithChildren<ProjectShowProviderProps>) {
  const value = useQuery<BusinessUnitView | null, AxiosError<unknown>, BusinessUnitView>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId }],
    () => ProjectShowApi.getBusinessUnitById1(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <ProjectShowContext.Provider value={value}>{props.children}</ProjectShowContext.Provider>;
}
