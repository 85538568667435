import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { CompanyUpdateProvider, useCompanyUpdateProvider } from 'api/CompaniesProviders';
import {
  BusinessUnitAccessPersonsListProvider,
  BusinessUnitUpdateAccessByPersonsProvider,
  useBusinessUnitAccessPersonsListProvider,
  useBusinessUnitUpdateAccessByPersonsProvider,
} from 'api/BusinessUnitProviders';
import { CompanyInfoProvider, useCompanyInfoProvider } from 'api/CompaniesProviders/CompanyInfoProvider';
import { useErrorCatcher } from 'api/notifications';

import { makeParentUrl } from 'utils';
import { Modal } from 'components/material';
import { CompanyForm, CompanyWithAccessFormRequestType, CompanyFormFieldsEnum } from './CompanyForm';
import { CompaniesPageRouterParams } from '../../interfaces';

import omit from 'lodash/omit';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

export function EditCompanyDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const match = useRouteMatch<CompaniesPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const catchError = useErrorCatcher();

  const closeHandler = () => routeProps.history.push(makeParentUrl(match.url));

  return (
    <Modal
      title={t('companies:dialog.companyCard')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="sm"
      fullWidth={true}
      data-test={EditCompanyDialog.name}
    >
      <CompanyInfoProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
        <CompanyUpdateProvider queryOptions={{ onError: catchError }} businessUnitId={businessUnitId}>
          <BusinessUnitAccessPersonsListProvider businessUnitId={businessUnitId}>
            <BusinessUnitUpdateAccessByPersonsProvider>
              <CompanyUpdate />
            </BusinessUnitUpdateAccessByPersonsProvider>
          </BusinessUnitAccessPersonsListProvider>
        </CompanyUpdateProvider>
      </CompanyInfoProvider>
    </Modal>
  );
}

function CompanyUpdate() {
  const match = useRouteMatch<CompaniesPageRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const history = useHistory();
  const { mutateAsync: updateCompany } = useCompanyUpdateProvider();
  const { mutateAsync: updateAccess } = useBusinessUnitUpdateAccessByPersonsProvider();
  const { data: company } = useCompanyInfoProvider();
  const {
    readers: { data: readers, isFetched: isReadersFetched },
    responsible: { data: responsible, isFetched: isResponsibleFetched },
  } = useBusinessUnitAccessPersonsListProvider();

  const initialAllowIds = map(filter(readers, ['access', true]), 'accountDisplayView.id');
  const initialResponsibleIds = map(filter(responsible, ['access', true]), 'accountDisplayView.id');

  const onSubmit = useCallback(
    (values: CompanyWithAccessFormRequestType) => {
      const companyValues = omit(values, CompanyFormFieldsEnum.accountAllowIds, CompanyFormFieldsEnum.responsibleIds);
      return updateCompany(companyValues)
        .then(() => {
          if (
            !isEqual(initialAllowIds, values[CompanyFormFieldsEnum.accountAllowIds]) ||
            !isEqual(initialResponsibleIds, values[CompanyFormFieldsEnum.responsibleIds])
          ) {
            const updateRequest = {
              businessUnitId,
              readerAccess: map(readers, item => ({
                accountId: item.accountDisplayView.id,
                access: values[CompanyFormFieldsEnum.accountAllowIds]?.includes(item.accountDisplayView.id) || false,
              })),
              responsibleAccess: map(responsible, item => ({
                accountId: item.accountDisplayView.id,
                access: values[CompanyFormFieldsEnum.responsibleIds]?.includes(item.accountDisplayView.id) || false,
              })),
            };
            return updateAccess(updateRequest);
          }
        })
        .then(() => history.push(makeParentUrl(match.url)));
    },
    [
      updateCompany,
      initialAllowIds,
      initialResponsibleIds,
      businessUnitId,
      readers,
      responsible,
      updateAccess,
      history,
      match.url,
    ]
  );

  if (!isReadersFetched || !readers || !responsible || !isResponsibleFetched) {
    return null;
  }

  return (
    <CompanyForm
      onSubmit={onSubmit}
      initialValues={company}
      type="EDIT"
      personsList={readers}
      initialAllowIds={initialAllowIds}
      responsibleList={responsible}
      responsibleIds={initialResponsibleIds}
    />
  );
}
