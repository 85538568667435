import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useFolderDeleteProvider } from 'api/DocumentProviders/Folders';
import { DeleteConfirmDialog } from '../ConfirmDialogs';

interface DeleteFolderConfirmationDialogProps {
  open: boolean;
  onClose: (event?: any) => void;
  onDelete: (event?: any) => void;
}
export function DeleteFolderConfirmDialog({ open, onClose, onDelete }: DeleteFolderConfirmationDialogProps) {
  const { t } = useTranslation();

  const { mutateAsync: deleteFolder, isLoading } = useFolderDeleteProvider();

  const handleRemove = useCallback(
    async event => {
      await deleteFolder({}).then(() => {
        onDelete();
      });
    },
    [deleteFolder, onDelete]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={handleRemove}
      title={t('businessUnit:deleteFolderConfirmDialog.title')}
      confirmText={t('businessUnit:deleteFolderConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}
