import find from 'lodash/find';
import head from 'lodash/head';
import reduce from 'lodash/reduce';
import tail from 'lodash/tail';

// from https://github.com/bl00mber/react-phone-input-2/blob/master/src/rawCountries.js
export const country_data = [
  ['Monaco', ['europe'], 'mc', '377', '+... ..-..-..-..'],
  ['Cyprus', ['europe', 'european-union'], 'cy', '357', '+... .. ......'],
  ['United Kingdom', ['europe', 'european-union'], 'gb', '44', '+.. .... ......'],
  ['France', ['europe', 'european-union'], 'fr', '33', '+.. . .. .. .. ..'],
  ['Russia', ['europe', 'asia', 'ex-ussr'], 'ru', '7', '+. (...) ...-..-..', 0],
  ['United States', ['america', 'north-america'], 'us', '1', '+. (...) ...-....', 0],
  ['Switzerland', ['europe'], 'ch', '41', '+.. .. ... .. ..'],
  ['Sweden', ['europe', 'eu-union', 'baltic'], 'se', '46', '+.. (...) ...-...'],
];

// NOTE: This is naive realization because we have not country code
// Determine phone country and format phone by founded pattern
export function formatPhone(phone: string): string {
  const countryData = find(country_data, item => {
    const isStartsWith = phone.startsWith(item[3] as string);
    const isSameLength = phone.length === ((item[4] as string).match(/\./g) || []).length;
    return isStartsWith && isSameLength;
  });
  const findedPattern = (countryData ? countryData[4] : '') as string;
  return formatNumber(phone, findedPattern);
}

function formatNumber(text: string, pattern: string) {
  if (!text || text.length === 0) {
    return '+';
  }

  // for all strings with length less than 3, just return it (1, 2 etc.)
  // also return the same text if the selected country has no fixed format
  if ((text && text.length < 2) || !pattern) {
    return `+${text}`;
  }

  let formattedObject = reduce(
    pattern,
    (acc, character) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }

      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText,
        };
      }

      return {
        formattedText: acc.formattedText + head(acc.remainingText),
        remainingText: tail(acc.remainingText),
      };
    },
    { formattedText: '', remainingText: text.split('') }
  );
  return formattedObject.formattedText + formattedObject.remainingText.join('');
}

export function getPhoneCountryData(phone: string): (string | number | string[])[] | undefined {
  const countryData = find(country_data, item => {
    const isStartsWith = phone.startsWith(item[3] as string);
    const isSameLength = phone.length <= ((item[4] as string).match(/\./g) || []).length;
    return isStartsWith && isSameLength;
  });
  return countryData;
}

export function getPhoneCode(phone: string): string {
  const countryData = getPhoneCountryData(phone);
  return (countryData ? countryData[3] : '') as string;
}

export function getPhoneRequiredLength(phone: string): number {
  const countryData = getPhoneCountryData(phone) || [];
  const countryFormat = countryData[4] || '';
  return ((countryFormat as string).match(/\./g) || []).length;
}
