/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CompanyView } from './company-view';
import { PositionsOfPersonView } from './positions-of-person-view';

/**
 * Person information in one language. If data of this language is empty - fields will be null
 * @export
 * @interface PersonView
 */
export interface PersonView {
  /**
   * Current positions of the persons (without \'position end date\')
   * @type {Array<PositionsOfPersonView>}
   * @memberof PersonView
   */
  activePositions: Array<PositionsOfPersonView>;
  /**
   * Additional number
   * @type {string}
   * @memberof PersonView
   */
  additionalPhone?: string;
  /**
   * Past positions of the persons (with \'position end date\')
   * @type {Array<PositionsOfPersonView>}
   * @memberof PersonView
   */
  archivePositions: Array<PositionsOfPersonView>;
  /**
   *
   * @type {CompanyView}
   * @memberof PersonView
   */
  company?: CompanyView;
  /**
   * Description
   * @type {string}
   * @memberof PersonView
   */
  description?: string;
  /**
   * Email of the person
   * @type {string}
   * @memberof PersonView
   */
  email?: string;
  /**
   * Extension number
   * @type {string}
   * @memberof PersonView
   */
  extensionNumber?: string;
  /**
   * First name of the person
   * @type {string}
   * @memberof PersonView
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PersonView
   */
  language: PersonViewLanguageEnum;
  /**
   * Last name of the person
   * @type {string}
   * @memberof PersonView
   */
  lastName: string;
  /**
   * Middle name of the person
   * @type {string}
   * @memberof PersonView
   */
  middleName?: string;
  /**
   * ID of the person
   * @type {number}
   * @memberof PersonView
   */
  personId: number;
  /**
   * Main phone
   * @type {string}
   * @memberof PersonView
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PersonView
   */
  photoPath: string;
  /**
   * Reception extension number
   * @type {string}
   * @memberof PersonView
   */
  receptionExtensionNumber?: string;
  /**
   * Reception phone
   * @type {string}
   * @memberof PersonView
   */
  receptionPhone?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonViewLanguageEnum {
  ENGLISH = 'ENGLISH',
  RUSSIAN = 'RUSSIAN',
}
