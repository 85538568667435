import React, { useCallback, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

import {
  BusinessUnitSectionShortView,
  BusinessUnitSectionShortViewPermissionsEnum,
  UserSessionStateViewRoleEnum,
} from 'api/generated';
import { getSectionIconURL } from 'api/http';
import { useAuthProvider } from 'api/AuthProviders';
import { PencilIcon, SectionLockedIcon } from 'components/icons';
import { EditSectionDialog } from './SectionCard/EditSectionDialog';
import Typography from '@material-ui/core/Typography';

interface SectionItemProps {
  section: BusinessUnitSectionShortView;
  canEdit: boolean;
}

export function SectionItem({ section, canEdit }: SectionItemProps) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { role } = useAuthProvider();

  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;

  const isResponsible = role === UserSessionStateViewRoleEnum.RESPONSIBLE;

  const showReaderAccess = (isAdmin || isResponsible) && section.restrictedAccess;
  const showResponsibleAccess = isAdmin && !section.restrictedResponsibleAccess;

  const showEdit =
    section.permissions.includes(BusinessUnitSectionShortViewPermissionsEnum.UPDATE_BUSINESS_UNIT_SECTION_PREVIEW) &&
    canEdit;

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onClickEditIcon = useCallback(
    event => {
      event.stopPropagation();
      setOpenDialog(true);
    },
    [setOpenDialog]
  );

  const openSection = useCallback(() => {
    if (section.sectionPreviewFileMetaView) {
      history.push(`${location.pathname}/section_preview`, {
        businessUnitId: section.businessUnitId,
        sectionId: section.sectionId,
        previewFileId: section.sectionPreviewFileMetaView.id,
      });
    } else {
      history.push(`${location.pathname}/section/${section.sectionId}`);
    }
  }, [history, location.pathname, section]);

  const onCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  return (
    <>
      <Card className={classes.root} onClick={openSection}>
        <Box display="flex" justifyContent="space-between">
          <img alt="" src={getSectionIconURL(section.businessUnitId, section.sectionId)} className={classes.icon} />
          <Box display="flex" alignItems="center">
            {showReaderAccess && <SectionLockedBox label={t('businessUnit:lockLabel.reader')} />}
            {showResponsibleAccess && <SectionLockedBox label={t('businessUnit:lockLabel.responsible')} />}
            {showEdit && (
              <Box className={classNames(classes.editIcon, 'editIcon')} onClick={onClickEditIcon}>
                <PencilIcon />{' '}
              </Box>
            )}
          </Box>
        </Box>
        <Box fontSize="h5.fontSize" fontWeight="500">
          {section.sectionTitle}
        </Box>
      </Card>
      {showEdit && (
        <EditSectionDialog
          open={openDialog}
          onClose={onCloseDialog}
          sectionId={section.sectionId}
          permissions={section.permissions}
        />
      )}
    </>
  );
}

function SectionLockedBox({ label }: { label: string }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="#C44A00"
      bgcolor="#FDF1EB"
      borderRadius={10}
      px={1}
      mx={0.5}
      height={32}
    >
      <SectionLockedIcon color="inherit" style={{ width: 14, height: 14, marginRight: 4 }} />
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.05)',
    borderRadius: 12,
    padding: theme.spacing(3.5),
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    height: 208,
    cursor: 'pointer',
    '&:hover .editIcon': {
      display: 'flex',
    },
  },
  icon: {
    width: 56,
    height: 56,
  },
  editIcon: {
    backgroundColor: '#222423',
    width: 32,
    height: 32,
    padding: '3px',
    marginLeft: theme.spacing(2.5),
    borderRadius: '50%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
      width: '18px',
      height: '18px',
      '& path': {
        fill: 'rgb(255, 255, 255)',
      },
    },
  },
  title: {},
}));
