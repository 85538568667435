import React, { useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { useAuthProvider } from 'api/AuthProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';
import { useProjectsListProvider } from 'api/ProjectsProviders';

import { ListWrapper } from '../ListWrapper';
import { ProjectsList } from './ProjectsList';
import { AddProjectButton } from './ProjectCard/AddProjectButton';

export interface ProjectsPageRouterParams {
  businessUnitId?: string | undefined;
}

export function ProjectsColumn() {
  const match = useRouteMatch<ProjectsPageRouterParams>();
  const { role } = useAuthProvider();
  const history = useHistory();

  const { data: projectsList } = useProjectsListProvider();
  const [currentProjectId, setCurrentProjectId] = useState<number | null>(null);

  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;

  useEffect(() => {
    const projectId = match.params?.businessUnitId;
    let newProjectId;
    if (typeof projectId === 'string') {
      newProjectId = parseInt(projectId, 10);
    }
    if (newProjectId) {
      setCurrentProjectId(newProjectId);
    } else {
      setCurrentProjectId(null);
    }
  }, [match.params?.businessUnitId]);

  useEffect(() => {
    if (projectsList?.length && !projectsList.find(item => item.id === currentProjectId)) {
      history.push(`/projects/${projectsList[0].id}`);
      setCurrentProjectId(projectsList[0].id);
    }
  }, [projectsList, history, currentProjectId]);

  return (
    <ListWrapper>
      <Box flexGrow="1" overflow="auto">
        <ProjectsList currentProjectId={currentProjectId} />
      </Box>
      <Divider />
      {isAdmin && <AddProjectButton />}
    </ListWrapper>
  );
}
