import React, { useEffect, useRef } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, TextFieldProps } from 'components/fields';
import { composeValidators, maxLength, required } from 'components/fields/validators';
import trim from 'utils/trim';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ApiConfiguration, getBusinessUnitIconURL, getPersonPhotoURL } from 'api/http';
import {
  PersonControllerApiFactory,
  SearchControllerApiFactory,
  SearchPersonRequestLanguageEnum,
  SearchPersonSimpleView,
} from 'api/generated';
import { useForm } from 'react-final-form';
import { StructType } from 'api/PersonsProvider';
import { Avatar } from '@material-ui/core';

const SearchApi = SearchControllerApiFactory(ApiConfiguration);
const PersonApi = PersonControllerApiFactory(ApiConfiguration);

interface Props {
  businessUnitId: number;
  language: 'ru' | 'en';
  structType: StructType;
  textFieldProps: Partial<TextFieldProps>;
}

export function PersonLastNameField(props: Props) {
  const { language, structType } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [options, setOptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingFields, setLoadingFields] = React.useState<boolean>(false);
  const form = useForm();

  const lastNameFieldKey = language === 'ru' ? 'lastNameRu' : 'lastNameEn';
  const lastNameLabel = language === 'ru' ? t('persons:form.fields.lastNameRu') : t('persons:form.fields.lastNameEn');
  const lastNamePlaceholder =
    language === 'ru' ? t('persons:form.fields.lastNameRuPlaceholder') : t('persons:form.fields.lastNameEnPlaceholder');
  const fieldKeysShouldExclude = structType === 'COMPANY' ? ['companyId'] : [];

  const handleInputChange = async (_: unknown, value: string) => {
    form.change(lastNameFieldKey, value);
    if (value.length === 3) {
      setLoading(true);
      const res = await SearchApi.searchPersonBySecondName({
        search: value,
        language: language === 'ru' ? SearchPersonRequestLanguageEnum.RUSSIAN : SearchPersonRequestLanguageEnum.ENGLISH,
      });
      if (res?.data.length > 0) {
        setOptions(res.data);
      }
      setLoading(false);
    }
  };

  const handleChange = async (_: unknown, _selectedValue: unknown) => {
    const selectedValue = _selectedValue as SearchPersonSimpleView;
    if (selectedValue) {
      setLoadingFields(true);
      const res = await PersonApi.getBusinessUnitPersonForEdit2(selectedValue.businessUnitId, selectedValue.id);
      if (res.data) {
        try {
          Object.keys(res.data).forEach(personPropKey => {
            if (personPropKey === 'photoId') {
              form.change('photo', {
                isCopied: true,
                personId: selectedValue.id,
                businessUnitId: selectedValue.businessUnitId,
                // also send photoId when implemented
              });
            } else if (!fieldKeysShouldExclude.includes(personPropKey)) {
              form.change(personPropKey, (res.data as any)[personPropKey]);
            }
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingFields(false);
        }
      }
    }
  };

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: options,
    getOptionLabel: option => option.displayName,
    freeSolo: true,
    onInputChange: handleInputChange,
    filterOptions: (options: SearchPersonSimpleView[], state: { inputValue: string }) => {
      return !state.inputValue ? [] : options;
    },
    onChange: handleChange,
  });

  // fix validation on onBlur - doesn't using TextField's onblur brakes
  // disappearing of autocomplete popup.
  const textInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const onFocus = () => form.focus(lastNameFieldKey);
    const onBlur = () => form.blur(lastNameFieldKey);
    const element = textInputRef.current;
    if (!element) {
      return;
    }
    element.addEventListener('blur', onBlur);
    element.addEventListener('focus', onFocus);

    return () => {
      element.removeEventListener('blur', onBlur);
      element.removeEventListener('focus', onFocus);
    };
  }, [form, lastNameFieldKey]);

  return (
    <div className={classes.wrapper}>
      <div {...getRootProps()} className={classes.fieldWrapper}>
        <TextField
          inputRef={textInputRef}
          label={lastNameLabel}
          placeholder={lastNamePlaceholder}
          name={lastNameFieldKey}
          margin="dense"
          value={form.getState().values[lastNameFieldKey] || ''}
          required
          fieldProps={{
            validate: composeValidators(required, maxLength(255)),
            format: trim,
            formatOnBlur: true,
            input: {
              ...getInputProps(),
            },
          }}
          endAdornment={<>{loading || loadingFields ? <CircularProgress color="inherit" size={20} /> : null}</>}
          {...props.textFieldProps}
        />
      </div>
      {groupedOptions.length > 0 || loading ? (
        <ul className={classes.dropdownWrapper} {...getListboxProps()}>
          {loading ? <div className={classes.loading}>{t('common:loading')}</div> : null}
          {!loading &&
            groupedOptions.map((option: SearchPersonSimpleView, index) => (
              <li {...getOptionProps({ option, index })} className={classes.dropdownItem} key={option.id}>
                <div className={classes.userSnippet}>
                  <div className={classes.userPhotoWrapper}>
                    <Avatar src={getPersonPhotoURL(option.businessUnitId, option.id)} className={classes.userAvatar} />
                    <Avatar src={getBusinessUnitIconURL(option.businessUnitId)} className={classes.businessUnitPhoto} />
                  </div>
                  <div className={classes.userInfo}>
                    <div className={classes.userName}>{option.displayName}</div>
                    <div className={classes.businessUnitName}>{option.businessUnitName}</div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  fieldWrapper: {
    width: '100%',
  },
  dropdownWrapper: {
    width: '100%',
    borderRadius: '8px',
    padding: '12px 0',
    border: '0',
    boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.3)',
    listStyle: 'none',
    maxHeight: '280px',
    margin: 0,
    position: 'absolute',
    zIndex: 2,
    background: '#fff',
    overflowY: 'auto',
  },
  dropdownItem: {
    marginBottom: '16px',
    padding: '0 16px',
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  loading: {
    padding: '0 16px',
  },
  userSnippet: {
    display: 'flex',
    alignItems: 'center',
  },
  userPhotoWrapper: {
    position: 'relative',
  },
  userAvatar: {
    width: '32px',
    height: '32px',
  },
  businessUnitPhoto: {
    position: 'absolute',
    zIndex: 1,
    width: '16px',
    height: '16px',
    right: 0,
    bottom: 0,
  },
  userInfo: {
    marginLeft: '8px',
  },
  userName: {
    color: theme.palette.common.black,
    fontSize: '16px',
    fontWeight: 500,
  },
  businessUnitName: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
}));
