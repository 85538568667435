import { ThemeOptions } from '@material-ui/core/styles';

export const appThemeLight: ThemeOptions = {
  palette: {
    type: 'light',
    background: {
      default: '#F5F6FA',
    },
    primary: {
      light: '#40414B',
      main: '#31323A',
      contrastText: '#FFFFFF',
      dark: '#000000',
    },
    secondary: {
      main: '#F5F6FA',
      contrastText: '#000000',
    },
    error: {
      light: 'rgba(251, 98, 4, 1)',
      main: 'rgba(236, 90, 64, 1)',
      dark: 'rgba(242, 69, 69, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000', // Typography color="textPrimary"
      secondary: '#83899B', // Typography color="textSecondary"
      hint: '#C2C6D2',
    },
    grey: {
      100: '#FFFFFF',
      200: '#F5F6FA',
      300: '#E3E4EB',
      400: '#C2C6D2',
      500: '#A2A6B4',
      600: '#83899B',
      700: '#474D61',
      800: '#1B1C1E',
      900: '#000000',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 16,
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.125rem', // for 18px
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.125rem', // for 18px
      fontWeight: 'normal',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        whiteSpace: 'nowrap',
        borderRadius: 8,
        fontSize: '1.25rem',
        height: 48,
        lineHeight: '48px',
        paddingLeft: '32px',
        paddingRight: '32px',
        boxShadow: 'none',
        fontWeight: 600,
      },
      sizeSmall: {
        fontSize: '1.125rem',
        height: 36,
        lineHeight: '36px',
        paddingLeft: '16px',
        paddingRight: '16px',
        boxShadow: 'none',
        fontWeight: 'normal',
      },
      text: {
        paddingLeft: '32px',
        paddingRight: '32px',
      },
      textSizeSmall: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      containedPrimary: {
        color: '#FFFFFF',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#40414B',
          boxShadow: 'none',
        },
        '&:disabled': {
          color: '#A2A6B4',
          backgroundColor: '#F5F6FA',
        },
      },
      containedSecondary: {
        color: '#000000',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#EAEBEE',
          boxShadow: 'none',
        },
      },
      textPrimary: {
        color: '#31323A',
        border: '1px solid #A2A6B4',
        backgroundColor: 'rgba(255,255,255,0)',
        '&:hover': {
          border: '1px solid #C8CBD4',
        },
      },
      textSecondary: {
        color: '#EB5757',
        backgroundColor: '#F5F6FA',
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
      label: {
        textTransform: 'initial',
        fontSize: '1.125rem',
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: 48,
        borderRadius: 8,
        '& input:-internal-autofill-selected': {
          transition: 'background-color 5000s ease-in-out 0s',
          backgroundColor: '#F5F6FA !important',
          '&:hover': {
            transition: 'background-color 5000s ease-in-out 0s',
            backgroundColor: '#F5F6FA !important',
          },
          '&:focus': {
            transition: 'background-color 5000s ease-in-out 0s',
            backgroundColor: '#F5F6FA !important',
          },
          '&:active': {
            transition: 'background-color 5000s ease-in-out 0s',
            backgroundColor: '#F5F6FA !important',
          },
        },
      },
    },
    MuiTableCell: {
      head: {
        textTransform: 'uppercase',
        color: '#83899B', // palette.grey.600
        fontSize: '0.625rem', // 10px
        padding: '8px',
      },
      body: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    MuiChip: {
      root: {
        color: '#FFFFFF',
        backgroundColor: '#474D61', // palette.grey.700
        borderRadius: '8px',
        fontSize: '0.75rem', // 12px
        textTransform: 'uppercase',
      },
      colorPrimary: {
        color: '#FFFFFF',
        backgroundColor: '#60BB86',
      },
      colorSecondary: {
        color: '#FFFFFF',
        backgroundColor: '#EB5757',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 24,
      },
      button: {
        '&:hover': {
          backgroundColor: '#ECEEF3',
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 48,
        height: 48,
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 64,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#E3E4EB',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&.MuiSelect-select': {
          paddingRight: 32,
        },
      },
      icon: {
        marginRight: 8,
      },
    },
    MuiTab: {
      root: {
        fontSize: '1.125rem', // 18px
        textTransform: 'unset',
      },
    },
    MuiSwitch: {
      switchBase: {
        '&:not(.Mui-checked)': {
          '& .MuiSwitch-thumb': {
            backgroundColor: '#FFF',
          },
        },
      },
    },
  },
};
