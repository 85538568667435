import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { BusinessUnitSectionControllerApiFactory, BusinessUnitSectionShortView, EntityPlace } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { reorder } from 'utils';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';

const SectionApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

interface SectionOrderUpdateRequest {
  from: number;
  to: number;
}

type MutationContext = { prevState: BusinessUnitSectionShortView[] | undefined };

type SectionOrderUpdateProviderType = {
  businessUnitId: number;
  controller: UseMutationResult<
    BusinessUnitSectionShortView[],
    AxiosError<unknown>,
    SectionOrderUpdateRequest,
    MutationContext
  >;
};

const SectionOrderUpdateContext = createContext<SectionOrderUpdateProviderType | null>(null);
SectionOrderUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}OrderUpdate`;

export function useSectionOrderUpdateProvider(): SectionOrderUpdateProviderType {
  const contextState = useContext(SectionOrderUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSectionOrderUpdateProvider.name} must be used within a ${SectionOrderUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface SectionOrderUpdateProviderProps {
  businessUnitId: number;
  queryOptions?: UseMutationOptions<
    BusinessUnitSectionShortView[],
    AxiosError<unknown>,
    SectionOrderUpdateRequest,
    MutationContext
  >;
}
export function SectionOrderUpdateProvider(props: React.PropsWithChildren<SectionOrderUpdateProviderProps>) {
  const queryClient = useQueryClient();
  const QUERY_CLIENT_DATA_KEY = [RESOURCE_NAME, { businessUnitId: props.businessUnitId }];
  const updateDefaultOptions: SectionOrderUpdateProviderProps['queryOptions'] = {
    onMutate: params => {
      const prevState: BusinessUnitSectionShortView[] | undefined = queryClient.getQueryData(QUERY_CLIENT_DATA_KEY);
      // Optimistically update order
      queryClient.setQueryData<BusinessUnitSectionShortView[] | undefined>(QUERY_CLIENT_DATA_KEY, xs => {
        return isNil(xs) ? xs : reorder(xs, params.from, params.to);
      });
      return { prevState };
    },
    onError: (err, params, context) => {
      queryClient.setQueryData(QUERY_CLIENT_DATA_KEY, context?.prevState);
    },
    onSettled: data => {
      queryClient.invalidateQueries(QUERY_CLIENT_DATA_KEY);
    },
  };
  const update = useMutation<
    BusinessUnitSectionShortView[],
    AxiosError<unknown>,
    SectionOrderUpdateRequest,
    MutationContext
  >(
    async (params: SectionOrderUpdateRequest) => {
      // NOTE: sections are already reordered in onMutate function
      const sections: BusinessUnitSectionShortView[] = queryClient.getQueryData(QUERY_CLIENT_DATA_KEY) || [];
      const elements: EntityPlace[] = map(sections, (section: BusinessUnitSectionShortView, index: number) => ({
        id: section.sectionId,
        version: section.sectionVersion,
        orderNum: index,
      }));
      return SectionApi.updateBusinessUnitSectionOrder2(props.businessUnitId, { elements }).then(resp => resp.data);
    },
    {
      ...updateDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <SectionOrderUpdateContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        controller: update,
      }}
    >
      {props.children}
    </SectionOrderUpdateContext.Provider>
  );
}
