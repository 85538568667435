import React from 'react';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { PersonFolderRequest, PersonFolderUpdateRequest, PersonSimpleView, PersonUpdateRequest } from 'api/generated';

import { DatePicker, TextField } from 'components/fields';
import { maxLength, notToday, required } from 'components/fields/validators';
import { SelectPerson } from './SelectPerson';
import { useHistory } from 'react-router-dom';

export type PersonFormValuesType = PersonUpdateRequest & {
  groups: PersonFolderRequest[];
  photo?: { isCopied: boolean; businessUnitId: number; personId: number };
};

interface MemberPositionFormProps {
  businessUnitId: number;
  onSubmit: any;
  onCancel: any;
  persons: PersonSimpleView[];
  initialValues?: PersonFolderUpdateRequest | {};
  groupFolderId: number;
}
export function MemberPositionForm({
  onSubmit,
  onCancel,
  businessUnitId,
  persons,
  initialValues,
  groupFolderId,
}: MemberPositionFormProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const isEditMode = !!initialValues;
  const query = new URLSearchParams(history.location.search);
  const emptyInitialValues: any = {};

  if (query.get('personId')) {
    emptyInitialValues.personId = query.get('personId');
  }

  const classes = useStyles();

  // TODO: move out fields related to position and use one component with MemberPositionForm.ts й
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues ? initialValues : emptyInitialValues}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, submitting, form }) => {
        return (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <Box display="flex" flexDirection="column">
              <SelectPerson
                businessUnitId={businessUnitId}
                groupFolderId={groupFolderId}
                disabled={isEditMode}
                persons={persons}
                required={true}
                fieldProps={{ required: true, validate: required }}
              />
              <Box display="flex">
                <Box flexGrow={1} flexBasis="50%">
                  <TextField
                    label={t('persons:form.fields.positionRu')}
                    placeholder={t('persons:form.fields.positionRuPlaceholder')}
                    name="positionRu"
                    margin="dense"
                    fieldProps={{
                      validate: memberPositionValidator,
                      formatOnBlur: false,
                    }}
                  />
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <Box ml={1.5}>
                    <TextField
                      label={t('persons:form.fields.positionEn')}
                      placeholder={t('persons:form.fields.positionEnPlaceholder')}
                      name="positionEn"
                      margin="dense"
                      fieldProps={{
                        validate: memberPositionValidator,
                        formatOnBlur: false,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexBasis="50%">
                  <Box display="flex" flexDirection="row">
                    <Box flexBasis="50%">
                      <DatePicker
                        label={t('persons:form.fields.positionStartDate')}
                        placeholder={t('persons:form.fields.positionStartDatePlaceholder')}
                        name="positionStartDate"
                        clearable={true}
                        maxDate={form.getState().values?.positionEndDate || undefined}
                        disableFuture={true}
                        disableToolbar={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="dense"
                      />
                    </Box>
                    <Box flexBasis="50%" ml={1.5}>
                      <DatePicker
                        label={t('persons:form.fields.positionEndDate')}
                        placeholder={t('persons:form.fields.positionEndDatePlaceholder')}
                        name="positionEndDate"
                        clearable={true}
                        minDate={form.getState().values?.positionStartDate || undefined}
                        disableFuture={true}
                        disableToolbar={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="dense"
                        fieldProps={{
                          validate: notToday,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Toolbar disableGutters>
              <Box display="flex" my={3} width="100%">
                <Box>
                  <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                    {isEditMode ? t('persons:dialog.save') : t('persons:dialog.add')}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button variant="contained" color="secondary" onClick={onCancel}>
                    {t('persons:dialog.cancel')}
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </form>
        );
      }}
    />
  );
}

export const memberPositionValidator = maxLength(250);

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
  },
}));
