import React from 'react';

import { Link, useLocation, matchPath } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useAuthProvider } from 'api/AuthProviders/AuthProvider';
import { UserSessionStateViewRoleEnum } from 'api/generated';
import { getAccountPhotoURL } from 'api/http';

import { AccountRoles } from 'pages/Accounts/components/AccountRoles';
import {
  ACCOUNTS_PAGE_PATH,
  COMPANIES_PAGE_PATH,
  MOBILE_APPS_PAGE_PATH,
  PROFILE_PAGE_PATH,
  PROJECTS_PAGE_PATH,
  ROOT_PAGE_PATH,
} from 'pages/Router';

import map from 'lodash/map';
import first from 'lodash/first';
import find from 'lodash/find';
import { headerHeight } from '../../styles/constants';
import { LogoIcon } from 'components/icons';

import { HeaderSearch } from '../Search/HeaderSearch';
import { SEARCH_PARAM_NAME } from '../Search/constants';
import { Messaging } from '../../components/common/Messaging/Messaging';
import { MessagingEnableProvider } from '../../api/MessagingProvider/MessagingEnableProvider';

export function Header() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const isSearching = params.has(SEARCH_PARAM_NAME);

  const { sessionUser, isAuthenticated, role } = useAuthProvider();
  const account = sessionUser?.account;

  const tabs = [
    {
      label: t('companies:title'),
      path: COMPANIES_PAGE_PATH,
    },
    {
      label: t('projects:title'),
      path: PROJECTS_PAGE_PATH,
    },
  ];

  if (role && [UserSessionStateViewRoleEnum.ADMIN, UserSessionStateViewRoleEnum.ROOT].includes(role)) {
    tabs.push({
      label: t('accounts:title'),
      path: ACCOUNTS_PAGE_PATH,
    });
    tabs.push({
      label: t('mobileApps:title'),
      path: MOBILE_APPS_PAGE_PATH,
    });
  }

  let selectedTab = find(tabs, tab => !!matchPath(location.pathname, { path: tab.path }));
  if (!selectedTab) {
    selectedTab = first(tabs);
  }

  const classes = useStyles();
  return (
    <AppBar elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" height="100%">
          <Box display="flex" alignItems="center" minWidth={296}>
            <Box mr={2}>
              <MuiLink component={Link} to={ROOT_PAGE_PATH} underline="none">
                <LogoIcon className={classes.logo} />
              </MuiLink>
            </Box>
            <Box>
              <MuiLink component={Link} to={ROOT_PAGE_PATH} underline="none">
                <Typography variant="h6">USM Universe</Typography>
              </MuiLink>
            </Box>
          </Box>

          <Box alignSelf="flex-end">
            <Tabs
              value={selectedTab?.path}
              textColor="primary"
              indicatorColor="primary"
              aria-label="navigation"
              centered
            >
              {map(tabs, tab => (
                <Tab
                  key={tab.path}
                  value={tab.path}
                  label={tab.label}
                  component={Link}
                  to={tab.path}
                  className={classes.tab}
                  disableRipple
                />
              ))}
            </Tabs>
          </Box>
          {!isSearching && (
            <Box flexGrow={1}>
              <HeaderSearch />
            </Box>
          )}
          {isAuthenticated && (
            <>
              <Link to={`${location.pathname}${PROFILE_PAGE_PATH}`}>
                <Box display="flex" alignItems="center" minWidth={296} justifyContent="flex-end" pr={5.25}>
                  <Box display="flex" flexDirection="column" alignItems="flex-end" mr={1}>
                    <Box>
                      <Typography variant="subtitle1" className={classes.username}>
                        {account?.firstName} {account?.lastName}
                      </Typography>
                    </Box>
                    <Box color="grey.600">
                      <AccountRoles roles={role ? [role] : []} variant="caption" />
                    </Box>
                  </Box>
                  <Box>
                    <Avatar
                      src={getAccountPhotoURL(account?.id)}
                      alt={`${account?.firstName} ${account?.lastName}`}
                      className={classes.avatar}
                    />
                  </Box>
                </Box>
              </Link>
              <MessagingEnableProvider>
                <Messaging language={language} />
              </MessagingEnableProvider>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
    height: `${headerHeight}`,
    minHeight: `${headerHeight}`,
  },
  toolbar: {
    height: `${headerHeight}`,
    minHeight: `${headerHeight}`,
  },
  tab: {
    paddingBottom: 21,
  },
  logo: {
    height: 34,
    width: 34,
  },
  username: {
    lineHeight: 1.3,
  },
  avatar: {
    height: 40,
    width: 40,
  },
}));
