import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { AccessControllerApiFactory, AccessUpdateRequest } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import omit from 'lodash/omit';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);
export type BusinessUnitUpdateAccessByPersonsFormRequest = AccessUpdateRequest & {
  businessUnitId: number;
};

type BusinessUnitUpdateAccessByPersonsProviderType = UseMutationResult<
  object,
  AxiosError<unknown>,
  BusinessUnitUpdateAccessByPersonsFormRequest,
  unknown
>;

const BusinessUnitUpdateAccessByPersonsContext = createContext<BusinessUnitUpdateAccessByPersonsProviderType | null>(
  null
);
BusinessUnitUpdateAccessByPersonsContext.displayName = `${upperFirst(RESOURCE_NAME)}UpdateAccessByPersons`;

export function useBusinessUnitUpdateAccessByPersonsProvider(): BusinessUnitUpdateAccessByPersonsProviderType {
  const contextState = useContext(BusinessUnitUpdateAccessByPersonsContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitUpdateAccessByPersonsProvider.name} must be used within a ${BusinessUnitUpdateAccessByPersonsContext.displayName} context`
    );
  }
  return contextState;
}

interface BusinessUnitUpdateAccessByPersonsProviderProps {
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, BusinessUnitUpdateAccessByPersonsFormRequest, unknown>;
}

export function BusinessUnitUpdateAccessByPersonsProvider(
  props: React.PropsWithChildren<BusinessUnitUpdateAccessByPersonsProviderProps>
) {
  const queryClient = useQueryClient();
  const defaultOptions: BusinessUnitUpdateAccessByPersonsProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([RESOURCE_NAME, 'access', 'personsList']);
    },
  };
  const update = useMutation<object, AxiosError<unknown>, BusinessUnitUpdateAccessByPersonsFormRequest>(
    async (data: BusinessUnitUpdateAccessByPersonsFormRequest) => {
      return AccessApi.updateAccessToBusinessUnitsForReaders(data.businessUnitId, omit(data, 'businessUnitId')).then(
        resp => resp.data
      );
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <BusinessUnitUpdateAccessByPersonsContext.Provider value={update}>
      {props.children}
    </BusinessUnitUpdateAccessByPersonsContext.Provider>
  );
}
