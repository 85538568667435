/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FileMetaView } from './file-meta-view';

/**
 * Presents document in one language, If data of the this language is empty - fields will be null
 * @export
 * @interface DocumentView
 */
export interface DocumentView {
  /**
   *
   * @type {number}
   * @memberof DocumentView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DocumentView
   */
  language: DocumentViewLanguageEnum;
  /**
   *
   * @type {string}
   * @memberof DocumentView
   */
  lastActivityDate: string;
  /**
   * Extension of the original file, if it exists
   * @type {string}
   * @memberof DocumentView
   */
  originalFileExtension?: string;
  /**
   *
   * @type {FileMetaView}
   * @memberof DocumentView
   */
  originalFileMeta?: FileMetaView | null;
  /**
   *
   * @type {Array<number>}
   * @memberof DocumentView
   */
  path: Array<number>;
  /**
   *
   * @type {FileMetaView}
   * @memberof DocumentView
   */
  pdfFileMeta?: FileMetaView | null;
  /**
   * Brief description of the document
   * @type {string}
   * @memberof DocumentView
   */
  title?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentViewLanguageEnum {
  ENGLISH = 'ENGLISH',
  RUSSIAN = 'RUSSIAN',
}
