import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { DocumentCardView, DocumentControllerApiFactory } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

type DocumentCardProviderType = QueryObserverResult<DocumentCardView, unknown>;

const DocumentCardContext = createContext<DocumentCardProviderType | null>(null);
DocumentCardContext.displayName = `${upperFirst(RESOURCE_NAME)}Card`;

export function useDocumentCardProvider(): DocumentCardProviderType {
  const contextState = useContext(DocumentCardContext);
  if (isNil(contextState)) {
    throw new Error(`${useDocumentCardProvider.name} must be used within a ${DocumentCardContext.displayName} context`);
  }
  return contextState;
}

interface DocumentCardProviderProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
}
export function DocumentCardProvider(props: React.PropsWithChildren<DocumentCardProviderProps>) {
  const value = useQuery<DocumentCardView>([RESOURCE_NAME, 'card', { id: props.documentId }], () =>
    DocumentApi.getDocumentCard(props.businessUnitId, props.sectionId, props.documentId).then(resp => resp.data)
  );
  return <DocumentCardContext.Provider value={value}>{props.children}</DocumentCardContext.Provider>;
}
