import React, { useCallback, useRef } from 'react';

import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import { useAuthProvider } from 'api/AuthProviders';
import { useSectionContentProvider } from 'api/SectionsProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';

import { Spinner } from 'components/material/Spinner';

import { EmptyGroupsList } from './EmptyGroupsList';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { closestCorners, CollisionDetection, DndContext, rectIntersection } from '@dnd-kit/core';
import findIndex from 'lodash/findIndex';
import { SortableList } from '../../../../components/common/SortableList';
import { useGroupOrderUpdateProvider } from '../../../../api/GroupsProvider/GroupOrderUpdateProvider';
import { Avatar } from '@material-ui/core';

interface CollisionStatus {
  over: string | null;
  index: number;
}
export function GroupsList() {
  const location = useLocation();
  const { url } = useRouteMatch();

  const { data, isLoading, isFetched, isFetching } = useSectionContentProvider();
  const groups = flatten(data?.pages);

  const { role } = useAuthProvider();
  const canEditGroup = role
    ? [
        UserSessionStateViewRoleEnum.ROOT,
        UserSessionStateViewRoleEnum.ADMIN,
        UserSessionStateViewRoleEnum.RESPONSIBLE,
      ].includes(role)
    : false;

  const {
    controller: { mutateAsync: updateOrder, isLoading: isUpdatingOrder },
  } = useGroupOrderUpdateProvider();

  const onDragEnd = useCallback(
    event => {
      const { active, over } = event;
      if (over && active.id !== over.id && !isUpdatingOrder) {
        const activeId = parseInt(active.id, 10);
        const overId = parseInt(over.id, 10);
        const fromIndex = findIndex(groups, { id: activeId });
        const toIndex = over.data?.current?.index;
        updateOrder({
          from: { id: activeId, index: fromIndex },
          to: { id: overId, index: toIndex },
        });
      }
    },
    [isUpdatingOrder, updateOrder, groups]
  );

  const collisionStatus = useRef<CollisionStatus | undefined>();

  const customCollisionDetectionStrategy: CollisionDetection = useCallback(
    (rects, rect) => {
      const detectionIntersect = rectIntersection(rects, rect);
      const detectionId = parseInt(detectionIntersect || '', 10);
      const currentId = closestCorners(rects, rect);
      const indexTo = findIndex(groups, { id: detectionId });
      collisionStatus.current = { over: currentId, index: indexTo };
      return detectionIntersect;
    },
    [groups]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(groups) && isFetched) {
    return <EmptyGroupsList />;
  }

  return (
    <DndContext
      onDragEnd={props =>
        onDragEnd({ ...props, over: props.over ? { ...props.over, data: { ...collisionStatus } } : null })
      }
      collisionDetection={customCollisionDetectionStrategy}
    >
      <List disablePadding>
        {map(groups, group => (
          <SortableList key={group.id} data={group} disabled={isUpdatingOrder || isFetching || !canEditGroup}>
            <ListItem
              component={Link}
              to={{ ...location, pathname: `${url}/group/${group.id}` }}
              button
              divider
              disableGutters
              alignItems="center"
            >
              <ListItemAvatar>
                <Avatar alt={group.title} src={group.iconPath} />
              </ListItemAvatar>
              <ListItemText primary={group.title} />
            </ListItem>
          </SortableList>
        ))}
      </List>
    </DndContext>
  );
}
