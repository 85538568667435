import React, { ReactNode } from 'react';

import { Field, FieldProps } from 'react-final-form';
import { ErrorMessage, showErrorOnChange, useFieldForErrors, ShowErrorFunc } from 'mui-rff';
import classNames from 'classnames';

import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormControl,
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
  MenuItem,
  MenuItemProps,
  makeStyles,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { CustomInput, CustomLabel } from './CustomInput';

export interface SelectData {
  label: string;
  value: string | number | string[] | undefined;
  disabled?: boolean;
}

export interface SelectProps extends Partial<Omit<MuiSelectProps, 'onChange'>> {
  name: string;
  label?: ReactNode;
  required?: boolean;
  multiple?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  MenuProps?: any;
  formControlProps?: Partial<FormControlProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
  menuItemProps?: Partial<MenuItemProps>;
  data?: SelectData[];
  children?: ReactNode;
}

// TODO: add type "checkbox" if appear one more select with checkbox
export function Select(props: SelectProps) {
  const {
    name,
    label,
    data,
    children,
    required,
    multiple,
    helperText,
    fieldProps,
    inputLabelProps,
    formControlProps,
    formHelperTextProps,
    menuItemProps,
    showError = showErrorOnChange,
    MenuProps,
    ...restSelectProps
  } = props;

  if (!data && !children) {
    throw new Error('Please specify either children or data as an attribute.');
  }
  const { variant } = restSelectProps;
  const field = useFieldForErrors(name);
  const isError = showError(field);
  return (
    <Field
      name={name}
      type="select"
      render={({ input: { name, value, onChange, ...restInput } }) => {
        // prevents an error that happens if you don't have initialValues defined in advance
        const finalValue = multiple && !value ? [] : value;
        const labelId = `select-input-${name}`;
        return (
          <FormControl required={required} error={isError} fullWidth={true} variant={variant} {...formControlProps}>
            {!!label && (
              <CustomLabel
                id={labelId}
                {...inputLabelProps}
                disabled={restSelectProps.disabled}
                shrink
                variant="standard"
              >
                {label}
              </CustomLabel>
            )}
            <MuiSelect
              name={name}
              value={finalValue}
              onChange={onChange}
              multiple={multiple}
              label={label}
              labelId={labelId}
              inputProps={{ required, ...restInput }}
              input={<CustomInput />}
              MenuProps={MenuProps}
              IconComponent={KeyboardArrowDownIcon}
              {...restSelectProps}
            >
              {data
                ? data.map(item => (
                    <MenuItem value={item.value} key={item.value} disabled={item.disabled} {...(menuItemProps as any)}>
                      {item.label}
                    </MenuItem>
                  ))
                : children}
            </MuiSelect>
            <ErrorMessage
              showError={isError}
              meta={field.meta}
              formHelperTextProps={formHelperTextProps}
              helperText={helperText}
            />
          </FormControl>
        );
      }}
      {...fieldProps}
    />
  );
}

export function Placeholder({
  disabled,
  children,
}: {
  disabled?: boolean;
  children: React.ReactChildren | React.ReactChild;
}) {
  const classes = usePlaceholderStyles();
  return <div className={classNames(classes.root, { [classes.disabled]: disabled })}>{children}</div>;
}

const usePlaceholderStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
  },
  disabled: {
    color: theme.palette.grey[300],
  },
}));
