import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  BusinessUnitCreateRequest,
  BusinessUnitUpdateRequest,
  BusinessUnitControllerApiFactory,
  FileControllerApiFactory,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

const CompanyApi = BusinessUnitControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

type CompanyCreateRequest = {
  logo?: File;
} & BusinessUnitCreateRequest;

type CompanyCreateProviderType = UseMutationResult<
  BusinessUnitUpdateRequest,
  AxiosError<unknown>,
  CompanyCreateRequest,
  unknown
>;

const CompanyCreateContext = createContext<CompanyCreateProviderType | null>(null);
CompanyCreateContext.displayName = 'CompanyCreate';

export function useCompanyCreateProvider(): CompanyCreateProviderType {
  const contextState = useContext(CompanyCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useCompanyCreateProvider.name} must be used within a ${CompanyCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface CompanyCreateProviderProps {
  queryOptions?: UseMutationOptions<BusinessUnitUpdateRequest, AxiosError<unknown>, BusinessUnitCreateRequest, unknown>;
}

export function CompanyCreateProvider(props: React.PropsWithChildren<CompanyCreateProviderProps>) {
  const queryClient = useQueryClient();

  const create = useMutation<BusinessUnitUpdateRequest, AxiosError<unknown>, BusinessUnitCreateRequest>(
    async (data: CompanyCreateRequest) => {
      if (data.logo) {
        const logo = data.logo;
        const file = await FileApi.upload(logo, logo.name).then(resp => resp.data);
        data.iconId = file.id;
      }
      const params = omit(data, ['logo']);
      return CompanyApi.createBusinessUnit('COMPANY', params).then(async resp => {
        await queryClient.invalidateQueries(RESOURCE_NAME);
        return resp.data;
      });
    },
    {
      ...(props.queryOptions || {}),
    }
  );
  return <CompanyCreateContext.Provider value={create}>{props.children}</CompanyCreateContext.Provider>;
}
