import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitSectionControllerApiFactory, BusinessUnitSectionUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const SectionInfoApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

type SectionInfoProviderType = QueryObserverResult<BusinessUnitSectionUpdateRequest, unknown>;

const SectionInfoContext = createContext<SectionInfoProviderType | null>(null);
SectionInfoContext.displayName = `${upperFirst(RESOURCE_NAME)}Info`;

export function useSectionInfoProvider(): SectionInfoProviderType {
  const contextState = useContext(SectionInfoContext);
  if (isNil(contextState)) {
    throw new Error(`${useSectionInfoProvider.name} must be used within a ${SectionInfoContext.displayName} context`);
  }
  return contextState;
}

interface SectionInfoProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: any;
}

export function SectionInfoProvider(props: React.PropsWithChildren<SectionInfoProviderProps>) {
  const value = useQuery<
    BusinessUnitSectionUpdateRequest | null,
    AxiosError<unknown>,
    BusinessUnitSectionUpdateRequest
  >(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId, sectionId: props.sectionId }],
    () =>
      SectionInfoApi.getBusinessUnitSectionUpdateRequest2(props.businessUnitId, props.sectionId).then(
        resp => resp.data
      ),
    props.queryOptions
  );
  return <SectionInfoContext.Provider value={value}>{props.children}</SectionInfoContext.Provider>;
}
