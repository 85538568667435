import React, { ReactNode } from 'react';

import classnames from 'classnames';

import { Field, FieldProps, useField } from 'react-final-form';
import { ErrorMessage, showErrorOnChange, useFieldForErrors, ShowErrorFunc } from 'mui-rff';

import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Box,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormGroupProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getAccountPhotoURL } from 'api/http';
import { AccountAccessView } from 'api/generated';

import isNil from 'lodash/isNil';

export interface SwitchGroupData {
  label: ReactNode;
  value: unknown;
  isWarning?: boolean;
  disabled?: boolean;
}

export interface SwitchesProps extends Partial<Omit<MuiSwitchProps, 'onChange'>> {
  name: string;
  data: AccountAccessView[];
  label?: ReactNode;
  required?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formGroupProps?: Partial<FormGroupProps>;
  formLabelProps?: Partial<FormLabelProps>;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

export function AccountAccessSwitchGroup(props: SwitchesProps) {
  const {
    name,
    data,
    label,
    required,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    ...restSwitches
  } = props;

  const classes = useAccountAccessSwitchGroupStyles();
  const { t } = useTranslation();

  const {
    input: { value: accountAllows },
  } = useField(name);

  const itemsData: SwitchGroupData[] = data.map(({ accountDisplayView: account }) => {
    const hasAllow = isNil(accountAllows) || accountAllows.includes(account.id);
    return {
      label: (
        <Box display="flex" alignItems="center">
          <Avatar src={getAccountPhotoURL(account.id)} alt={account.displayName} classes={{ root: classes.avatar }} />
          <Box flexGrow={1}>{account.displayName}</Box>
          <Box mr={1.5} color={hasAllow ? 'text.primary' : 'error.main'}>
            {hasAllow ? t('businessUnit:personsAllow.hasAllow') : t('businessUnit:personsAllow.withoutAllow')}
          </Box>
        </Box>
      ),
      value: account.id,
      isWarning: !hasAllow,
    };
  });

  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} classes={{ root: classes.switchGroup }} {...formControlProps}>
      {label ? (
        <FormLabel
          classes={{ root: classnames(classes.formLabel, formLabelProps?.classes?.root), ...formLabelProps?.classes }}
          {...formLabelProps}
        >
          {label}
        </FormLabel>
      ) : (
        <></>
      )}
      <FormGroup {...formGroupProps}>
        {itemsData.map((item: SwitchGroupData, idx: number) => (
          <FormControlLabel
            key={idx}
            name={name}
            label={item.label}
            value={item.value}
            disabled={item.disabled}
            labelPlacement="start"
            classes={{
              root: classnames(classes.switchArea, { [classes.switchAreaWarning]: item.isWarning }),
              label: classes.switchLabelBox,
            }}
            control={
              <Field
                type="checkbox"
                name={name}
                render={({ input: { name, value, onChange, checked, ...restInput } }) => (
                  <MuiSwitch
                    name={name}
                    value={value}
                    color="primary"
                    onChange={onChange}
                    checked={checked}
                    disabled={item.disabled}
                    required={required}
                    inputProps={{ required, ...restInput }}
                    {...restSwitches}
                  />
                )}
                {...fieldProps}
              />
            }
            {...formControlLabelProps}
          />
        ))}
      </FormGroup>
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
}

export const useAccountAccessSwitchGroupStyles = makeStyles(theme => ({
  avatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1.5),
  },
  switchArea: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.1rem',
    marginLeft: 0,
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5, 1.5, 0.5, 1),
    borderRadius: 6,
  },
  switchAreaWarning: {
    backgroundColor: '#FDF1EB',
  },
  switchGroup: {
    width: '100%',
  },
  switchLabelBox: {
    width: '100%',
  },
  formLabel: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
}));
