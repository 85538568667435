import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { getErrorTextFromServerError } from 'utils/errorUtils';

export function useErrorCatcher() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (err: any) => {
      enqueueSnackbar(`${t('notification:error')}: ${getErrorTextFromServerError(err)}`);
    },
    [enqueueSnackbar, t]
  );
}
