import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { usePersonDeleteProvider } from 'api/PersonsProvider';

import { DeleteConfirmDialog } from '../../ConfirmDialogs';

interface DeletePersonConfirmationDialogProps {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
}
export function DeletePersonConfirmationDialog({ open, onDelete, onClose }: DeletePersonConfirmationDialogProps) {
  const { t } = useTranslation();

  const { mutateAsync: deletePerson, isLoading } = usePersonDeleteProvider();

  const handleRemove = useCallback(
    async event => {
      await deletePerson({}).then(() => {
        onDelete();
      });
    },
    [deletePerson, onDelete]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={onClose}
      handleRemove={handleRemove}
      title={t('businessUnit:deletePersonConfirmDialog.title')}
      confirmText={t('businessUnit:deletePersonConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}
