import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { InfiniteData, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { ContentItemView, HierarchyPlaceDataTypeEnum } from 'api/generated';
import { RESOURCE_NAME } from './constants';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

interface PersonOrderUpdateRequest {
  from: {
    id: number;
    index?: number;
  };
  to: {
    id: number;
    index?: number;
  };
  dataType: HierarchyPlaceDataTypeEnum;
}

type MutationContext = { prevState: InfiniteData<ContentItemView[]> | undefined };

type PersonOrderUpdateProviderType = {
  businessUnitId: number;
  sectionId: number;
  parentFolderId: number;
  controller: UseMutationResult<ContentItemView[], AxiosError<unknown>, PersonOrderUpdateRequest, MutationContext>;
};

const PersonOrderUpdateContext = createContext<PersonOrderUpdateProviderType | null>(null);
PersonOrderUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}OrderUpdate`;

export function usePersonOrderUpdateProvider(): PersonOrderUpdateProviderType {
  const contextState = useContext(PersonOrderUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${usePersonOrderUpdateProvider.name} must be used within a ${PersonOrderUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface PersonOrderUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  parentFolderId: number;
  dataType: HierarchyPlaceDataTypeEnum;
  queryOptions?: UseMutationOptions<ContentItemView[], AxiosError<unknown>, PersonOrderUpdateRequest, MutationContext>;
}
export function PersonOrderUpdateProvider(props: React.PropsWithChildren<PersonOrderUpdateProviderProps>) {
  const update = useMutation<ContentItemView[], AxiosError<unknown>, PersonOrderUpdateRequest, MutationContext>({});
  return (
    <PersonOrderUpdateContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        sectionId: props.sectionId,
        parentFolderId: props.parentFolderId,
        controller: update,
      }}
    >
      {props.children}
    </PersonOrderUpdateContext.Provider>
  );
}
