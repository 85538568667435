import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { FolderControllerApiFactory, FolderUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from '../constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const FolderApi = FolderControllerApiFactory(ApiConfiguration);

type FolderInfoProviderType = QueryObserverResult<FolderUpdateRequest, unknown>;

const FolderInfoContext = createContext<FolderInfoProviderType | null>(null);
FolderInfoContext.displayName = `${upperFirst(RESOURCE_NAME)}Info`;

export function useFolderInfoProvider(): FolderInfoProviderType {
  const contextState = useContext(FolderInfoContext);
  if (isNil(contextState)) {
    throw new Error(`${useFolderInfoProvider.name} must be used within a ${FolderInfoContext.displayName} context`);
  }
  return contextState;
}

interface FolderInfoProviderProps {
  businessUnitId: number;
  sectionId: number;
  folderId: number;
  queryOptions?: any;
}

export function FolderInfoProvider(props: React.PropsWithChildren<FolderInfoProviderProps>) {
  const value = useQuery<FolderUpdateRequest | null, AxiosError<unknown>, FolderUpdateRequest>(
    [RESOURCE_NAME, 'info', { businessUnitId: props.businessUnitId }],
    () => FolderApi.getFolderForEdit2(props.businessUnitId, props.sectionId, props.folderId).then(resp => resp.data),
    props.queryOptions
  );
  return <FolderInfoContext.Provider value={value}>{props.children}</FolderInfoContext.Provider>;
}
