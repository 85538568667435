/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PersonForCompanyCreateRequest } from '../views';
// @ts-ignore
import { PersonForProjectCreateRequest } from '../views';
// @ts-ignore
import { PersonSimpleView } from '../views';
// @ts-ignore
import { PersonUpdateRequest } from '../views';
// @ts-ignore
import { PersonView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * PersonControllerApi - axios parameter creator
 * @export
 */
export const PersonControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create person for company
     * @param {number} businessUnitId
     * @param {PersonForCompanyCreateRequest} personForCompanyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPersonForCompany: async (
      businessUnitId: number,
      personForCompanyCreateRequest: PersonForCompanyCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createPersonForCompany', 'businessUnitId', businessUnitId);
      // verify required parameter 'personForCompanyCreateRequest' is not null or undefined
      assertParamExists('createPersonForCompany', 'personForCompanyCreateRequest', personForCompanyCreateRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/person`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personForCompanyCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create person for project section
     * @param {number} businessUnitId
     * @param {PersonForProjectCreateRequest} personForProjectCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPersonForProject: async (
      businessUnitId: number,
      personForProjectCreateRequest: PersonForProjectCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createPersonForProject', 'businessUnitId', businessUnitId);
      // verify required parameter 'personForProjectCreateRequest' is not null or undefined
      assertParamExists('createPersonForProject', 'personForProjectCreateRequest', personForProjectCreateRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/person`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personForProjectCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete person from company or project
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePersonByBusinessUnit: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deletePersonByBusinessUnit', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('deletePersonByBusinessUnit', 'personId', personId);
      const localVarPath = `/struct/ALL/{businessUnitId}/person/{personId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download a person photo by the person id
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconForPerson: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadIconForPerson', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('downloadIconForPerson', 'personId', personId);
      const localVarPath = `/struct/ALL/{businessUnitId}/person/{personId}/photo`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonById: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitPersonById', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getBusinessUnitPersonById', 'personId', personId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/person/{personId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonById1: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitPersonById1', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getBusinessUnitPersonById1', 'personId', personId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/person/{personId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonById2: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitPersonById2', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getBusinessUnitPersonById2', 'personId', personId);
      const localVarPath = `/struct/ALL/{businessUnitId}/person/{personId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonForEdit: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitPersonForEdit', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getBusinessUnitPersonForEdit', 'personId', personId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/person/{personId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonForEdit1: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitPersonForEdit1', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getBusinessUnitPersonForEdit1', 'personId', personId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/person/{personId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonForEdit2: async (
      businessUnitId: number,
      personId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getBusinessUnitPersonForEdit2', 'businessUnitId', businessUnitId);
      // verify required parameter 'personId' is not null or undefined
      assertParamExists('getBusinessUnitPersonForEdit2', 'personId', personId);
      const localVarPath = `/struct/ALL/{businessUnitId}/person/{personId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'personId'}}`, encodeURIComponent(String(personId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsByBusinessUnit: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getPersonsByBusinessUnit', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/persons`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsByBusinessUnit1: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getPersonsByBusinessUnit1', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/persons`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsByBusinessUnit2: async (businessUnitId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getPersonsByBusinessUnit2', 'businessUnitId', businessUnitId);
      const localVarPath = `/struct/ALL/{businessUnitId}/persons`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitPerson: async (
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitPerson', 'businessUnitId', businessUnitId);
      // verify required parameter 'personUpdateRequest' is not null or undefined
      assertParamExists('updateBusinessUnitPerson', 'personUpdateRequest', personUpdateRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/person`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(personUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitPerson1: async (
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitPerson1', 'businessUnitId', businessUnitId);
      // verify required parameter 'personUpdateRequest' is not null or undefined
      assertParamExists('updateBusinessUnitPerson1', 'personUpdateRequest', personUpdateRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/person`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(personUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitPerson2: async (
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateBusinessUnitPerson2', 'businessUnitId', businessUnitId);
      // verify required parameter 'personUpdateRequest' is not null or undefined
      assertParamExists('updateBusinessUnitPerson2', 'personUpdateRequest', personUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/person`.replace(
        `{${'businessUnitId'}}`,
        encodeURIComponent(String(businessUnitId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(personUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PersonControllerApi - functional programming interface
 * @export
 */
export const PersonControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PersonControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create person for company
     * @param {number} businessUnitId
     * @param {PersonForCompanyCreateRequest} personForCompanyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPersonForCompany(
      businessUnitId: number,
      personForCompanyCreateRequest: PersonForCompanyCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonForCompany(
        businessUnitId,
        personForCompanyCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create person for project section
     * @param {number} businessUnitId
     * @param {PersonForProjectCreateRequest} personForProjectCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPersonForProject(
      businessUnitId: number,
      personForProjectCreateRequest: PersonForProjectCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonForProject(
        businessUnitId,
        personForProjectCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete person from company or project
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePersonByBusinessUnit(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePersonByBusinessUnit(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download a person photo by the person id
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadIconForPerson(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIconForPerson(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitPersonById(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitPersonById(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitPersonById1(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitPersonById1(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitPersonById2(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitPersonById2(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitPersonForEdit(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitPersonForEdit(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitPersonForEdit1(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitPersonForEdit1(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessUnitPersonForEdit2(
      businessUnitId: number,
      personId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUnitPersonForEdit2(
        businessUnitId,
        personId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPersonsByBusinessUnit(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonSimpleView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonsByBusinessUnit(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPersonsByBusinessUnit1(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonSimpleView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonsByBusinessUnit1(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPersonsByBusinessUnit2(
      businessUnitId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonSimpleView>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonsByBusinessUnit2(businessUnitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitPerson(
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitPerson(
        businessUnitId,
        personUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitPerson1(
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitPerson1(
        businessUnitId,
        personUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBusinessUnitPerson2(
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessUnitPerson2(
        businessUnitId,
        personUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PersonControllerApi - factory interface
 * @export
 */
export const PersonControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PersonControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Create person for company
     * @param {number} businessUnitId
     * @param {PersonForCompanyCreateRequest} personForCompanyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPersonForCompany(
      businessUnitId: number,
      personForCompanyCreateRequest: PersonForCompanyCreateRequest,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .createPersonForCompany(businessUnitId, personForCompanyCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create person for project section
     * @param {number} businessUnitId
     * @param {PersonForProjectCreateRequest} personForProjectCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPersonForProject(
      businessUnitId: number,
      personForProjectCreateRequest: PersonForProjectCreateRequest,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .createPersonForProject(businessUnitId, personForProjectCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete person from company or project
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePersonByBusinessUnit(businessUnitId: number, personId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deletePersonByBusinessUnit(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download a person photo by the person id
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconForPerson(businessUnitId: number, personId: number, options?: any): AxiosPromise<any> {
      return localVarFp
        .downloadIconForPerson(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonById(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonView> {
      return localVarFp
        .getBusinessUnitPersonById(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonById1(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonView> {
      return localVarFp
        .getBusinessUnitPersonById1(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person view
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonById2(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonView> {
      return localVarFp
        .getBusinessUnitPersonById2(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonForEdit(
      businessUnitId: number,
      personId: number,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .getBusinessUnitPersonForEdit(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonForEdit1(
      businessUnitId: number,
      personId: number,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .getBusinessUnitPersonForEdit1(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get person for edit
     * @param {number} businessUnitId
     * @param {number} personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessUnitPersonForEdit2(
      businessUnitId: number,
      personId: number,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .getBusinessUnitPersonForEdit2(businessUnitId, personId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsByBusinessUnit(businessUnitId: number, options?: any): AxiosPromise<Array<PersonSimpleView>> {
      return localVarFp.getPersonsByBusinessUnit(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsByBusinessUnit1(businessUnitId: number, options?: any): AxiosPromise<Array<PersonSimpleView>> {
      return localVarFp.getPersonsByBusinessUnit1(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get persons by business unit
     * @param {number} businessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsByBusinessUnit2(businessUnitId: number, options?: any): AxiosPromise<Array<PersonSimpleView>> {
      return localVarFp.getPersonsByBusinessUnit2(businessUnitId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitPerson(
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .updateBusinessUnitPerson(businessUnitId, personUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitPerson1(
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .updateBusinessUnitPerson1(businessUnitId, personUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update person
     * @param {number} businessUnitId
     * @param {PersonUpdateRequest} personUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessUnitPerson2(
      businessUnitId: number,
      personUpdateRequest: PersonUpdateRequest,
      options?: any
    ): AxiosPromise<PersonUpdateRequest> {
      return localVarFp
        .updateBusinessUnitPerson2(businessUnitId, personUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PersonControllerApi - interface
 * @export
 * @interface PersonControllerApi
 */
export interface PersonControllerApiInterface {
  /**
   *
   * @summary Create person for company
   * @param {number} businessUnitId
   * @param {PersonForCompanyCreateRequest} personForCompanyCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  createPersonForCompany(
    businessUnitId: number,
    personForCompanyCreateRequest: PersonForCompanyCreateRequest,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Create person for project section
   * @param {number} businessUnitId
   * @param {PersonForProjectCreateRequest} personForProjectCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  createPersonForProject(
    businessUnitId: number,
    personForProjectCreateRequest: PersonForProjectCreateRequest,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Delete person from company or project
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  deletePersonByBusinessUnit(businessUnitId: number, personId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Download a person photo by the person id
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  downloadIconForPerson(businessUnitId: number, personId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Get person view
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getBusinessUnitPersonById(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonView>;

  /**
   *
   * @summary Get person view
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getBusinessUnitPersonById1(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonView>;

  /**
   *
   * @summary Get person view
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getBusinessUnitPersonById2(businessUnitId: number, personId: number, options?: any): AxiosPromise<PersonView>;

  /**
   *
   * @summary Get person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getBusinessUnitPersonForEdit(
    businessUnitId: number,
    personId: number,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Get person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getBusinessUnitPersonForEdit1(
    businessUnitId: number,
    personId: number,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Get person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getBusinessUnitPersonForEdit2(
    businessUnitId: number,
    personId: number,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Get persons by business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getPersonsByBusinessUnit(businessUnitId: number, options?: any): AxiosPromise<Array<PersonSimpleView>>;

  /**
   *
   * @summary Get persons by business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getPersonsByBusinessUnit1(businessUnitId: number, options?: any): AxiosPromise<Array<PersonSimpleView>>;

  /**
   *
   * @summary Get persons by business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  getPersonsByBusinessUnit2(businessUnitId: number, options?: any): AxiosPromise<Array<PersonSimpleView>>;

  /**
   *
   * @summary Update person
   * @param {number} businessUnitId
   * @param {PersonUpdateRequest} personUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  updateBusinessUnitPerson(
    businessUnitId: number,
    personUpdateRequest: PersonUpdateRequest,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Update person
   * @param {number} businessUnitId
   * @param {PersonUpdateRequest} personUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  updateBusinessUnitPerson1(
    businessUnitId: number,
    personUpdateRequest: PersonUpdateRequest,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;

  /**
   *
   * @summary Update person
   * @param {number} businessUnitId
   * @param {PersonUpdateRequest} personUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApiInterface
   */
  updateBusinessUnitPerson2(
    businessUnitId: number,
    personUpdateRequest: PersonUpdateRequest,
    options?: any
  ): AxiosPromise<PersonUpdateRequest>;
}

/**
 * PersonControllerApi - object-oriented interface
 * @export
 * @class PersonControllerApi
 * @extends {BaseAPI}
 */
export class PersonControllerApi extends BaseAPI implements PersonControllerApiInterface {
  /**
   *
   * @summary Create person for company
   * @param {number} businessUnitId
   * @param {PersonForCompanyCreateRequest} personForCompanyCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public createPersonForCompany(
    businessUnitId: number,
    personForCompanyCreateRequest: PersonForCompanyCreateRequest,
    options?: any
  ) {
    return PersonControllerApiFp(this.configuration)
      .createPersonForCompany(businessUnitId, personForCompanyCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create person for project section
   * @param {number} businessUnitId
   * @param {PersonForProjectCreateRequest} personForProjectCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public createPersonForProject(
    businessUnitId: number,
    personForProjectCreateRequest: PersonForProjectCreateRequest,
    options?: any
  ) {
    return PersonControllerApiFp(this.configuration)
      .createPersonForProject(businessUnitId, personForProjectCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete person from company or project
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public deletePersonByBusinessUnit(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .deletePersonByBusinessUnit(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download a person photo by the person id
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public downloadIconForPerson(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .downloadIconForPerson(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person view
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getBusinessUnitPersonById(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getBusinessUnitPersonById(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person view
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getBusinessUnitPersonById1(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getBusinessUnitPersonById1(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person view
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getBusinessUnitPersonById2(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getBusinessUnitPersonById2(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getBusinessUnitPersonForEdit(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getBusinessUnitPersonForEdit(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getBusinessUnitPersonForEdit1(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getBusinessUnitPersonForEdit1(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get person for edit
   * @param {number} businessUnitId
   * @param {number} personId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getBusinessUnitPersonForEdit2(businessUnitId: number, personId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getBusinessUnitPersonForEdit2(businessUnitId, personId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get persons by business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getPersonsByBusinessUnit(businessUnitId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getPersonsByBusinessUnit(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get persons by business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getPersonsByBusinessUnit1(businessUnitId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getPersonsByBusinessUnit1(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get persons by business unit
   * @param {number} businessUnitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public getPersonsByBusinessUnit2(businessUnitId: number, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .getPersonsByBusinessUnit2(businessUnitId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update person
   * @param {number} businessUnitId
   * @param {PersonUpdateRequest} personUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public updateBusinessUnitPerson(businessUnitId: number, personUpdateRequest: PersonUpdateRequest, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .updateBusinessUnitPerson(businessUnitId, personUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update person
   * @param {number} businessUnitId
   * @param {PersonUpdateRequest} personUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public updateBusinessUnitPerson1(businessUnitId: number, personUpdateRequest: PersonUpdateRequest, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .updateBusinessUnitPerson1(businessUnitId, personUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update person
   * @param {number} businessUnitId
   * @param {PersonUpdateRequest} personUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonControllerApi
   */
  public updateBusinessUnitPerson2(businessUnitId: number, personUpdateRequest: PersonUpdateRequest, options?: any) {
    return PersonControllerApiFp(this.configuration)
      .updateBusinessUnitPerson2(businessUnitId, personUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
