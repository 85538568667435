import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Modal } from 'components/material/Modal';
import { ModalToolbar } from 'components/material/ModalToolbar';
import { useAuthProvider } from 'api/AuthProviders';
import { Checkbox, DialogProps, FormControlLabel } from '@material-ui/core';

export interface InfoDialogProps {
  title: string;
  text: string;
  /** To use never show logic you need to add unique key for this dialog*/
  neverShow?: { key: string };
  /** By default it is opened whenever it is rendered (except if never show was checked before)*/
  open?: boolean;
  dialogProps?: DialogProps;
}

export function InfoDialog(props: InfoDialogProps) {
  const { t } = useTranslation();
  const { storedNeverShow, setNeverShowToLocalStorage } = useNeverShowController(props.neverShow?.key);
  const [neverShowCBValue, setNeverShowCBValue] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(props.open ?? true);

  /** If never show is true then don't show the modal. */
  if (storedNeverShow) {
    return null;
  }

  const onOk = () => {
    setNeverShowToLocalStorage(neverShowCBValue);
    setOpen(false);
  };

  return (
    <Modal
      title={props.title}
      open={open}
      onCloseModal={() => setOpen(false)}
      maxWidth="sm"
      fullWidth={true}
      toolbar={
        <ModalToolbar>
          <Button onClick={() => onOk()} color="primary" variant="contained">
            {t('common:ok')}
          </Button>
        </ModalToolbar>
      }
      {...props.dialogProps}
    >
      {' '}
      <Box mb={6} minHeight={150}>
        <Typography variant="subtitle2">{props.text}</Typography>
      </Box>
      <Box>
        {props.neverShow ? (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={neverShowCBValue}
                onChange={() => setNeverShowCBValue(!neverShowCBValue)}
              />
            }
            label={t('common:dontShowAnymore')}
          />
        ) : null}
      </Box>
    </Modal>
  );
}

/** Key is undefined when  this logic is disabled */
function useNeverShowController(key: string | undefined) {
  const user = useAuthProvider();
  const userId = user.realUser?.id;
  return useMemo(() => {
    const lsKey = key + String(userId);
    return {
      storedNeverShow: key ? Boolean(userId && localStorage.getItem(lsKey) === 'true') : false,
      setNeverShowToLocalStorage: (value: boolean) => key && userId && localStorage.setItem(lsKey, String(value)),
    };
  }, [userId, key]);
}
