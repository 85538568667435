import React from 'react';

import { useTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { AccountViewStateEnum, SecretaryUpdateRequestStateEnum, UserShortViewStateEnum } from 'api/generated';
import { LogUnreachableCase } from 'utils';

interface AccountStateProps {
  state: AccountViewStateEnum | UserShortViewStateEnum | SecretaryUpdateRequestStateEnum;
}
export function AccountState({ state }: AccountStateProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  switch (state) {
    case AccountViewStateEnum.ACTIVE:
    case SecretaryUpdateRequestStateEnum.ACTIVE:
    case UserShortViewStateEnum.ACTIVE:
      return <Chip size="small" color="primary" label={t(`accounts:states.${state}` as const)} />;
    case AccountViewStateEnum.LOCKED:
    case SecretaryUpdateRequestStateEnum.LOCKED:
    case UserShortViewStateEnum.LOCKED:
      return <Chip size="small" color="secondary" label={t(`accounts:states.${state}` as const)} />;
    case AccountViewStateEnum.REMOVED:
    case SecretaryUpdateRequestStateEnum.REMOVED:
    case UserShortViewStateEnum.REMOVED:
      return (
        <Chip
          size="small"
          color="secondary"
          label={t(`accounts:states.${state}` as const)}
          className={classes.removed}
        />
      );
    default:
      LogUnreachableCase(state);
      return null;
  }
}

const useStyles = makeStyles(theme => ({
  removed: {
    backgroundColor: '#F55D00',
  },
}));
