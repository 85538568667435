import Box from '@material-ui/core/Box';
import React from 'react';
import { useSearchPersonProvider } from 'api/SearchProviders';
import { Spinner } from '../../../components/material';
import MemberSearchResultItem from './MemberSearchResultItem';

export function PersonsSearchMoreResults() {
  const { data, isLoading } = useSearchPersonProvider();

  if (isLoading) {
    return <Spinner />;
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      {data.map((person: any, i: number) => (
        <Box key={i}>
          <MemberSearchResultItem person={person} />
        </Box>
      ))}
    </>
  );
}
