import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { AccountControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);

export interface AccountRemoveRequest {
  id?: number;
}

type AccountRemoveProviderType = UseMutationResult<object, AxiosError<unknown>, AccountRemoveRequest, unknown>;

const AccountRemoveContext = createContext<AccountRemoveProviderType | null>(null);
AccountRemoveContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useAccountRemoveProvider(): AccountRemoveProviderType {
  const contextState = useContext(AccountRemoveContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useAccountRemoveProvider.name} must be used within a ${AccountRemoveContext.displayName} context`
    );
  }
  return contextState;
}

interface AccountRemoveProviderProps {
  accountId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, AccountRemoveRequest, unknown>;
}

export function AccountRemoveProvider(props: React.PropsWithChildren<AccountRemoveProviderProps>) {
  const queryClient = useQueryClient();
  const removeDefaultOptions: AccountRemoveProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries([RESOURCE_NAME, { id: props.accountId }]);
    },
  };
  const remove = useMutation<object, AxiosError<unknown>, AccountRemoveRequest>(
    async ({ id }: AccountRemoveRequest) => {
      return AccountApi._delete(props.accountId).then(resp => resp.data);
    },
    {
      ...removeDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <AccountRemoveContext.Provider value={remove}>{props.children}</AccountRemoveContext.Provider>;
}
