import React from 'react';

import Box from '@material-ui/core/Box';

export function ListWrapper(props: { children: React.ReactNode | React.ReactNodeArray }) {
  return (
    <Box alignSelf="center" display="flex" flexDirection="column" width="100%" height="100%">
      {props.children}
    </Box>
  );
}
