import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core';

import {
  AccountRemoveProvider,
  useAccountRemoveProvider,
  useAccountShowProvider,
  useAccountExpirePinProvider,
  useAccountLockProvider,
} from 'api/AccountsProviders';

import { ResetPinIcon, RunMenuIcon, LockIcon } from 'components/icons';
import { useErrorCatcher } from 'api/notifications';
import { DeleteConfirmDialog } from '../../All/ConfirmDialogs';

export function RunMenuButton() {
  const { t } = useTranslation();
  const { data: account, isFetched } = useAccountShowProvider();
  const { mutateAsync: resetPin, isLoading: isResetPinLoading } = useAccountExpirePinProvider();
  const { mutateAsync: toggleLockAccount, isLoading: isLockAccountLoading } = useAccountLockProvider();
  const catchError = useErrorCatcher();
  const [openRemoveWarning, setOpenRemoveWarning] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLock = useCallback(async () => {
    await toggleLockAccount({ type: 'lock' }).then(() => {
      setAnchorEl(null);
    });
  }, [toggleLockAccount, setAnchorEl]);

  const handleUnlock = useCallback(async () => {
    await toggleLockAccount({ type: 'unlock' }).then(() => {
      setAnchorEl(null);
    });
  }, [toggleLockAccount, setAnchorEl]);

  const handleResetPin = useCallback(async () => {
    await resetPin({}).then(() => {
      setAnchorEl(null);
    });
  }, [resetPin, setAnchorEl]);

  const handleOpenRemoveWarning = useCallback(() => {
    handleClose();
    setOpenRemoveWarning(true);
  }, []);

  const handleCloseRemoveWarning = useCallback(() => {
    setOpenRemoveWarning(false);
  }, []);

  const classes = useStyles();

  return account && isFetched ? (
    <Box ml="auto">
      <Button
        disableRipple={true}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
        disableElevation={true}
        startIcon={<RunMenuIcon />}
        disabled={account.state === 'REMOVED'}
        data-test="run_menu_button"
        size="small"
        className={classes.button}
      >
        {t('accounts:actions.button')}
      </Button>
      <Menu
        id="simple-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ list: classes.menuList }}
      >
        <MenuItem onClick={handleResetPin} className={classes.menuItem} disabled={isResetPinLoading}>
          <ListItemIcon>
            <ResetPinIcon />
          </ListItemIcon>
          <ListItemText data-test="runmenu_reset_pin" primary={t('accounts:actions.resetPin')} />
        </MenuItem>
        {account.state !== 'LOCKED' && (
          <MenuItem onClick={handleLock} className={classes.menuItem} disabled={isLockAccountLoading}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText data-test="runmenu_lock" primary={t('accounts:actions.lock')} />
          </MenuItem>
        )}
        {account.state === 'LOCKED' && (
          <MenuItem onClick={handleUnlock} className={classes.menuItem} disabled={isLockAccountLoading}>
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText data-test="runmenu_unlock" primary={t('accounts:actions.unlock')} />
          </MenuItem>
        )}
        {account.state !== 'REMOVED' && (
          <MenuItem className={classNames(classes.menuItem, classes.remove)} onClick={handleOpenRemoveWarning}>
            <ListItemIcon className={classes.remove}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText data-test="runmenu_remove" primary={t('accounts:actions.removeAccount')} />
          </MenuItem>
        )}
        <AccountRemoveProvider accountId={account.id} queryOptions={{ onError: catchError }}>
          <RemoveConfirmDialog open={openRemoveWarning} closeHandler={handleCloseRemoveWarning} />
        </AccountRemoveProvider>
      </Menu>
    </Box>
  ) : null;
}

function RemoveConfirmDialog({ open, closeHandler }: { open: boolean; closeHandler: () => void }) {
  const { t } = useTranslation();
  const { mutateAsync: removeAccount, isLoading } = useAccountRemoveProvider();

  const handleRemove = useCallback(
    async event => {
      await removeAccount({}).then(() => {
        closeHandler();
      });
    },
    [removeAccount, closeHandler]
  );

  return (
    <DeleteConfirmDialog
      open={open}
      onClose={closeHandler}
      handleRemove={handleRemove}
      title={t('accounts:deleteAccountConfirmDialog.title')}
      confirmText={t('accounts:deleteAccountConfirmDialog.text')}
      primaryButtonDisabled={isLoading}
    />
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#474D61',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: theme.spacing(1, 2),
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
  },
  remove: {
    color: theme.palette.error.main,
  },
}));
