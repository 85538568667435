import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { BusinessUnitControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const BusinessUnitApi = BusinessUnitControllerApiFactory(ApiConfiguration);

export interface BusinessUnitDeleteRequest {
  businessUnitId?: number;
}

type BusinessUnitDeleteProviderType = UseMutationResult<
  object,
  AxiosError<unknown>,
  BusinessUnitDeleteRequest,
  unknown
>;

const BusinessUnitDeleteContext = createContext<BusinessUnitDeleteProviderType | null>(null);
BusinessUnitDeleteContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useBusinessUnitDeleteProvider(): BusinessUnitDeleteProviderType {
  const contextState = useContext(BusinessUnitDeleteContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitDeleteProvider.name} must be used within a ${BusinessUnitDeleteContext.displayName} context`
    );
  }
  return contextState;
}

interface BusinessUnitDeleteProviderProps {
  businessUnitId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, BusinessUnitDeleteRequest, unknown>;
}

export function BusinessUnitDeleteProvider(props: React.PropsWithChildren<BusinessUnitDeleteProviderProps>) {
  const queryClient = useQueryClient();
  const defaultOptions: BusinessUnitDeleteProviderProps['queryOptions'] = {
    onSuccess: () => {
      // TODO: исправить на 1 ключ, когда будут объединены апи проектов и компаний
      queryClient.invalidateQueries(['companies']);
      queryClient.invalidateQueries(['projects']);
    },
  };
  const value = useMutation<object, AxiosError<unknown>, BusinessUnitDeleteRequest>(
    async () => {
      return BusinessUnitApi.deleteBusinessUnit2(props.businessUnitId).then(resp => resp.data);
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <BusinessUnitDeleteContext.Provider value={value}>{props.children}</BusinessUnitDeleteContext.Provider>;
}
