import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { DocumentControllerApiFactory, DocumentUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

type DocumentDetailsWithUpdateProviderType = QueryObserverResult<DocumentUpdateRequest, unknown>;

const DocumentDetailsWithUpdateContext = createContext<DocumentDetailsWithUpdateProviderType | null>(null);
DocumentDetailsWithUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}DetailsWithUpdate`;

export function useDocumentDetailsWithUpdateProvider(): DocumentDetailsWithUpdateProviderType {
  const contextState = useContext(DocumentDetailsWithUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentDetailsWithUpdateProvider.name} must be used within a ${DocumentDetailsWithUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentDetailsWithUpdateProviderProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
}
export function DocumentDetailsWithUpdateProvider(
  props: React.PropsWithChildren<DocumentDetailsWithUpdateProviderProps>
) {
  const value = useQuery<DocumentUpdateRequest>([RESOURCE_NAME, 'edit', { id: props.documentId }], () =>
    DocumentApi.getDocumentForEdit2(props.businessUnitId, props.sectionId, props.documentId).then(resp => resp.data)
  );
  return (
    <DocumentDetailsWithUpdateContext.Provider value={value}>
      {props.children}
    </DocumentDetailsWithUpdateContext.Provider>
  );
}
