import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  AccountControllerApiFactory,
  AccountWithUserCreateRequest,
  AccountWithUserUpdateRequest,
  FileControllerApiFactory,
} from 'api/generated';
import { ApiConfiguration, FileApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);
const FileApi = FileControllerApiFactory(FileApiConfiguration);

export type AccountWithUserAndPhotoCreateRequest = {
  photo?: File;
} & AccountWithUserCreateRequest;

type AccountCreateProviderType = UseMutationResult<
  AccountWithUserUpdateRequest,
  AxiosError<unknown>,
  AccountWithUserAndPhotoCreateRequest,
  unknown
>;

const AccountCreateContext = createContext<AccountCreateProviderType | null>(null);
AccountCreateContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useAccountCreateProvider(): AccountCreateProviderType {
  const contextState = useContext(AccountCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useAccountCreateProvider.name} must be used within a ${AccountCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface AccountCreateProviderProps {
  queryOptions?: UseMutationOptions<
    AccountWithUserUpdateRequest,
    AxiosError<unknown>,
    AccountWithUserAndPhotoCreateRequest,
    unknown
  >;
}
export function AccountCreateProvider(props: React.PropsWithChildren<AccountCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: AccountCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const create = useMutation<AccountWithUserUpdateRequest, AxiosError<unknown>, AccountWithUserAndPhotoCreateRequest>(
    async (data: AccountWithUserAndPhotoCreateRequest) => {
      if (data.photo) {
        const photo = data.photo;
        const file = await FileApi.upload(photo, photo.name).then(resp => resp.data);
        data.photoId = file.id;
      }

      const params = omit(data, ['photo']);

      return AccountApi.create(params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <AccountCreateContext.Provider value={create}>{props.children}</AccountCreateContext.Provider>;
}
