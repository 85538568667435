import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { SecretaryUpdateRequest } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';
import { SecretaryCreateProvider, useSecretaryCreateProvider } from 'api/SecretariesProvider';

import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { AccountsRequiredRouterParams } from './interfaces';
import { SecretaryForm } from './components/SecretaryForm/SecretaryForm';

export function CreateSecretaryDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const match = useRouteMatch<AccountsRequiredRouterParams>();
  const accountId = parseInt(match.params.accountId || '', 10);

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url));

  return (
    <Modal
      title={t('accounts:create.secretary')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="md"
      fullWidth={true}
      data-test={CreateSecretaryDialog.name}
    >
      <SecretaryCreateProvider accountId={accountId} queryOptions={{ onError: catchError }}>
        <SecretaryCreate />
      </SecretaryCreateProvider>
    </Modal>
  );
}

function SecretaryCreate() {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const {
    accountId,
    controller: { mutateAsync, isLoading },
  } = useSecretaryCreateProvider();

  const onSubmit = useCallback(
    (values: SecretaryUpdateRequest) => {
      if (!isLoading) {
        mutateAsync({ ...values, accountId }).then(() => goBackOrReplace(makeParentUrl(match.url)));
      }
    },
    [accountId, isLoading, match.url, mutateAsync]
  );

  const onCancel = useCallback(() => goBackOrReplace(makeParentUrl(match.url)), [match.url]);

  return (
    <SecretaryForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitLabel={t('accounts:create.createSecretary')}
      cancelLabel={t('accounts:create.cancel')}
    />
  );
}
