/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Section dictionary item update request
 * @export
 * @interface SectionDictionaryItemUpdateRequest
 */
export interface SectionDictionaryItemUpdateRequest {
  /**
   * Type of data in Section, it can be \'DOCUMENT\' or \'PERSON\'
   * @type {string}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  dataType: SectionDictionaryItemUpdateRequestDataTypeEnum;
  /**
   * Icon id
   * @type {number}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  iconId: number | null;
  /**
   *
   * @type {number}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  id: number;
  /**
   * Section order number
   * @type {number}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  orderNum: number;
  /**
   *
   * @type {string}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  status: SectionDictionaryItemUpdateRequestStatusEnum;
  /**
   * English title
   * @type {string}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  titleEn: string;
  /**
   * Russian title
   * @type {string}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  titleRu: string;
  /**
   *
   * @type {string}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  type: SectionDictionaryItemUpdateRequestTypeEnum;
  /**
   * version of the dictionary item
   * @type {number}
   * @memberof SectionDictionaryItemUpdateRequest
   */
  version: number;
}

/**
 * @export
 * @enum {string}
 */
export enum SectionDictionaryItemUpdateRequestDataTypeEnum {
  DOCUMENT = 'DOCUMENT',
  PERSON = 'PERSON',
}
/**
 * @export
 * @enum {string}
 */
export enum SectionDictionaryItemUpdateRequestStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
/**
 * @export
 * @enum {string}
 */
export enum SectionDictionaryItemUpdateRequestTypeEnum {
  SYSTEM = 'SYSTEM',
  PRIVATE = 'PRIVATE',
}
