import { ContentItemViewLanguagesEnum } from 'api/generated';
import { useTranslation } from 'react-i18next';

export function useLanguage(): ContentItemViewLanguagesEnum {
  const {
    i18n: { language },
  } = useTranslation();

  switch (language) {
    case 'ru':
      return ContentItemViewLanguagesEnum.RUSSIAN;
    case 'en':
      return ContentItemViewLanguagesEnum.ENGLISH;
    default:
      throw new Error(`Unknown value of language ${language}`);
  }
}
