import React from 'react';

import { useTranslation } from 'react-i18next';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useCompaniesListProvider } from 'api/CompaniesProviders';
import { getBusinessUnitIconURL } from 'api/http';

import { Select, SelectProps } from 'components/fields/Select';

import map from 'lodash/map';

interface CompanyProps extends Partial<SelectProps> {}
export function Company({ disabled }: CompanyProps) {
  const { t } = useTranslation();

  const { data: companies } = useCompaniesListProvider();

  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Box flexBasis="50%">
          <Select
            name="companyId"
            label={t('persons:form.fields.company')}
            placeholder={t('persons:form.fields.companyPlaceholder')}
            formControlProps={{ margin: 'dense' }}
            disabled={disabled}
          >
            {map(companies, company => (
              <MenuItem key={company.id} value={company.id} dense={false} classes={{ gutters: classes.menuItem }}>
                <Box display="flex">
                  <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                    <Avatar
                      src={getBusinessUnitIconURL(company.id)}
                      alt={company.title}
                      classes={{ root: classes.avatar }}
                    />
                  </ListItemAvatar>
                  <ListItemText id={`${company.id}`} primary={company.title} />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  menuItem: {
    paddingLeft: theme.spacing(2),
  },
  listItemAvatar: {
    width: 40,
    minWidth: 40,
  },
  avatar: {
    width: 32,
    minWidth: 32,
    height: 32,
    minHeight: 32,
  },
}));
