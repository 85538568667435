import React from 'react';

import { Router as ReactRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthProvider } from 'api/AuthProviders/AuthProvider';
import { UserSessionStateViewRoleEnum } from 'api/generated';

import { AccountsRouter } from './Accounts/AccountsRouter';
import { AuthPage } from './AuthPage';
import { ProfileDetailsDialog } from './All/PersonsAndGroups/PersonsDialogs/ProfileDetailsDialog';
import { DocumentPreviewDialog } from './All/DocumentsDialogs';
import { CompaniesRouter } from './Companies/CompaniesRouter';
import { ProjectsRouter } from './Projects/ProjectsRouter';
import { MobileAppsRouter } from './MobileApps/MobileAppsRouter';
import {
  MobileAppsDownloadAuthPage,
  MobileAppsDownloadPage,
  MobileAppsErrorPage,
} from './MobileApps/MobileAppsDownload';
import { MainLayout } from './Layouts/MainLayout';
import { NotFoudPage } from './404Page';
import { PrivateRoute } from './PrivateRoute';
import { history } from 'utils/history';
import { SearchRouter } from './Search/SearchRouter';

export const ACCOUNTS_PAGE_PATH = '/accounts';
export const AUTH_PAGE_PATH = '/auth';
export const COMPANIES_PAGE_PATH = '/companies';
export const MOBILE_APPS_PAGE_PATH = '/mobile_apps';
export const PDF_VIEWER_PAGE_PATH = '/pdf_viewer';
export const PROFILE_PAGE_PATH = '/my_profile';
export const PROJECTS_PAGE_PATH = '/projects';
export const ROOT_PAGE_PATH = '/';
export const SEARCH_PAGE_PATH = '/search';

export function Router() {
  return (
    <AuthProvider>
      <ReactRouter history={history}>
        <Switch>
          {/* Authentication */}
          <Route path={AUTH_PAGE_PATH}>
            <AuthPage />
          </Route>

          <Route exact path={`${MOBILE_APPS_PAGE_PATH}/download/error`}>
            <MobileAppsErrorPage />
          </Route>
          <Route exact path={`${MOBILE_APPS_PAGE_PATH}/download`}>
            <MobileAppsDownloadAuthPage />
          </Route>
          <Route exact path={`${MOBILE_APPS_PAGE_PATH}/download/:version`}>
            <MobileAppsDownloadPage />
          </Route>

          {/* Public routes here */}

          {/* Private routes */}
          <PrivateRoute path={ROOT_PAGE_PATH}>
            <MainLayout>
              <PrivateRoute
                exact
                path={`*${PROFILE_PAGE_PATH}`}
                render={routeProps => <ProfileDetailsDialog routeProps={routeProps} />}
              />
              <PrivateRoute
                exact
                path={`*${PDF_VIEWER_PAGE_PATH}`}
                render={routeProps => <DocumentPreviewDialog routeProps={routeProps} />}
              />
              <Switch>
                <Redirect exact from={ROOT_PAGE_PATH} to={COMPANIES_PAGE_PATH} />

                <PrivateRoute
                  path={ACCOUNTS_PAGE_PATH}
                  roles={[UserSessionStateViewRoleEnum.ADMIN, UserSessionStateViewRoleEnum.ROOT]}
                >
                  <AccountsRouter />
                </PrivateRoute>

                <Route path={COMPANIES_PAGE_PATH}>
                  <CompaniesRouter />
                </Route>

                <Route path={PROJECTS_PAGE_PATH}>
                  <ProjectsRouter />
                </Route>

                <PrivateRoute
                  path={MOBILE_APPS_PAGE_PATH}
                  roles={[UserSessionStateViewRoleEnum.ADMIN, UserSessionStateViewRoleEnum.ROOT]}
                >
                  <MobileAppsRouter />
                </PrivateRoute>

                <PrivateRoute path={SEARCH_PAGE_PATH}>
                  <SearchRouter />
                </PrivateRoute>

                <Route>
                  <NotFoudPage />
                </Route>
              </Switch>
            </MainLayout>
          </PrivateRoute>
        </Switch>
      </ReactRouter>
    </AuthProvider>
  );
}
