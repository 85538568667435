import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { PersonFolderControllerApiFactory, PersonFolderUpdateRequest } from 'api/generated';

import isNil from 'lodash/isNil';

const PersonApi = PersonFolderControllerApiFactory(ApiConfiguration);

type MemberPositionDetailsProviderType = QueryObserverResult<PersonFolderUpdateRequest>;

const MemberPositionDetailsContext = createContext<MemberPositionDetailsProviderType | null>(null);
MemberPositionDetailsContext.displayName = 'MemberPositionDetails';

export function useMemberPositionDetailsProvider(): MemberPositionDetailsProviderType {
  const contextState = useContext(MemberPositionDetailsContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMemberPositionDetailsProvider.name} must be used within a ${MemberPositionDetailsContext.displayName} context`
    );
  }
  return contextState;
}

interface MemberPositionDetailsProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  personFolderId: number;
  queryOptions?: UseQueryOptions<PersonFolderUpdateRequest>;
}

export function MemberPositionDetailsProvider(props: React.PropsWithChildren<MemberPositionDetailsProviderProps>) {
  const value = useQuery<PersonFolderUpdateRequest>(
    ['MemberPositionDetails', props.businessUnitId, props.sectionId, props.groupFolderId, props.personFolderId],
    () =>
      PersonApi.getPersonFolderByIdForEdit(
        props.businessUnitId,
        props.sectionId,
        props.groupFolderId,
        props.personFolderId
      ).then(resp => resp.data)
  );
  return <MemberPositionDetailsContext.Provider value={value}>{props.children}</MemberPositionDetailsContext.Provider>;
}
