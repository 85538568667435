import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Div100vh from 'react-div-100vh';
import { isMobile, isAndroid } from 'react-device-detect';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthProvider } from 'api/AuthProviders';
import { MobileAppsDownloadProvider } from 'api/MobileAppsProviders';
import { MobileAppVersionControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { SupportBlock } from 'pages/AuthPage/components/SupportBlock';

import { MobileAppsDownloadForm } from './MobileAppsDownloadForm/MobileAppsDownloadForm';
import { LogoIcon } from 'components/icons';

const MobileAppVersionApi = MobileAppVersionControllerApiFactory(ApiConfiguration);

export function MobileAppsDownloadAuthPage() {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthProvider();

  const [showSupport, setShowSupport] = useState<boolean>(false);
  const toggleSupportVisibility = useCallback(() => {
    setShowSupport(showSupport => !showSupport);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      history.push(`${match.url}/error`);
    }

    if (isAndroid) {
      history.push(`${match.url}/error`, { errorMsg: t('mobileApps:notAvailableForAndroid') });
    }

    if (isAuthenticated) {
      MobileAppVersionApi.getUpdateLink().then(resp => {
        const url = new URL(resp.data.url);
        history.push(url.pathname + url.search);
      });
    }
  }, [history, isAuthenticated, match.url, t]);

  const classes = useStyles();
  return (
    <Div100vh className={classes.root}>
      <Box display="flex" alignItems="center">
        <Box mr={1.5}>
          <LogoIcon style={{ width: 28, height: 28 }} />
        </Box>
        <Box component="span" fontSize="subtitle1.fontSize" fontWeight="bold">
          USM Universe
        </Box>
      </Box>
      <Box mt={3}>
        <MobileAppsDownloadProvider>
          <MobileAppsDownloadForm />
        </MobileAppsDownloadProvider>
      </Box>
      <Box position="absolute" bottom={56} className={classes.helpButton} onClick={toggleSupportVisibility}>
        {t('login:needHelp')}
      </Box>
      <Box height={72}>
        <SupportBlock show={showSupport} />
      </Box>
    </Div100vh>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F8F8F8',
  },
  helpButton: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));
