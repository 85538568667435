/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeLanguageRequest } from '../views';
// @ts-ignore
import { DisablePushTokenRequest } from '../views';
// @ts-ignore
import { EnablePushChannelRequest } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ChangeLanguageRequest} changeLanguageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLanguage: async (changeLanguageRequest: ChangeLanguageRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'changeLanguageRequest' is not null or undefined
      assertParamExists('changeLanguage', 'changeLanguageRequest', changeLanguageRequest);
      const localVarPath = `/notification/language`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changeLanguageRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAllChannels: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/notification/disable/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DisablePushTokenRequest} disablePushTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disablePushChannel: async (
      disablePushTokenRequest: DisablePushTokenRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'disablePushTokenRequest' is not null or undefined
      assertParamExists('disablePushChannel', 'disablePushTokenRequest', disablePushTokenRequest);
      const localVarPath = `/notification/disable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        disablePushTokenRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EnablePushChannelRequest} enablePushChannelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enablePushChannel: async (
      enablePushChannelRequest: EnablePushChannelRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'enablePushChannelRequest' is not null or undefined
      assertParamExists('enablePushChannel', 'enablePushChannelRequest', enablePushChannelRequest);
      const localVarPath = `/notification/enable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        enablePushChannelRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ChangeLanguageRequest} changeLanguageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeLanguage(
      changeLanguageRequest: ChangeLanguageRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeLanguage(changeLanguageRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableAllChannels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableAllChannels(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DisablePushTokenRequest} disablePushTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disablePushChannel(
      disablePushTokenRequest: DisablePushTokenRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disablePushChannel(disablePushTokenRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EnablePushChannelRequest} enablePushChannelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enablePushChannel(
      enablePushChannelRequest: EnablePushChannelRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.enablePushChannel(enablePushChannelRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationControllerApiFp(configuration);
  return {
    /**
     *
     * @param {ChangeLanguageRequest} changeLanguageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLanguage(changeLanguageRequest: ChangeLanguageRequest, options?: any): AxiosPromise<void> {
      return localVarFp.changeLanguage(changeLanguageRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAllChannels(options?: any): AxiosPromise<void> {
      return localVarFp.disableAllChannels(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {DisablePushTokenRequest} disablePushTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disablePushChannel(disablePushTokenRequest: DisablePushTokenRequest, options?: any): AxiosPromise<void> {
      return localVarFp.disablePushChannel(disablePushTokenRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EnablePushChannelRequest} enablePushChannelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enablePushChannel(enablePushChannelRequest: EnablePushChannelRequest, options?: any): AxiosPromise<void> {
      return localVarFp.enablePushChannel(enablePushChannelRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * NotificationControllerApi - interface
 * @export
 * @interface NotificationControllerApi
 */
export interface NotificationControllerApiInterface {
  /**
   *
   * @param {ChangeLanguageRequest} changeLanguageRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApiInterface
   */
  changeLanguage(changeLanguageRequest: ChangeLanguageRequest, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApiInterface
   */
  disableAllChannels(options?: any): AxiosPromise<void>;

  /**
   *
   * @param {DisablePushTokenRequest} disablePushTokenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApiInterface
   */
  disablePushChannel(disablePushTokenRequest: DisablePushTokenRequest, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {EnablePushChannelRequest} enablePushChannelRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApiInterface
   */
  enablePushChannel(enablePushChannelRequest: EnablePushChannelRequest, options?: any): AxiosPromise<void>;
}

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI implements NotificationControllerApiInterface {
  /**
   *
   * @param {ChangeLanguageRequest} changeLanguageRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public changeLanguage(changeLanguageRequest: ChangeLanguageRequest, options?: any) {
    return NotificationControllerApiFp(this.configuration)
      .changeLanguage(changeLanguageRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public disableAllChannels(options?: any) {
    return NotificationControllerApiFp(this.configuration)
      .disableAllChannels(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DisablePushTokenRequest} disablePushTokenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public disablePushChannel(disablePushTokenRequest: DisablePushTokenRequest, options?: any) {
    return NotificationControllerApiFp(this.configuration)
      .disablePushChannel(disablePushTokenRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EnablePushChannelRequest} enablePushChannelRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationControllerApi
   */
  public enablePushChannel(enablePushChannelRequest: EnablePushChannelRequest, options?: any) {
    return NotificationControllerApiFp(this.configuration)
      .enablePushChannel(enablePushChannelRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
