import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { useErrorCatcher } from 'api/notifications';
import {
  MemberPositionUpdateProvider,
  useMemberPositionUpdateProvider,
  MemberPositionDetailsProvider,
  useMemberPositionDetailsProvider,
  SimplePersonsListProvider,
  useSimplePersonsListProvider,
} from 'api/PersonsProvider';
import { PersonFolderUpdateRequest } from 'api/generated';

import { Modal, Spinner } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { PARENT_FOLDER_SEARCH_PARAM_NAME, PERSON_FOLDER_SEARCH_PARAM_NAME } from '../../constants';

import { GroupRequiredRouterParams } from '../../interfaces';
import { MemberPositionForm } from './MemberPositionForm';
import { ConfirmDialog } from '../../../ConfirmDialogs';

import noop from 'lodash/noop';

export function EditMemberPositionDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const history = useHistory();
  const match = useRouteMatch<GroupRequiredRouterParams>();
  const query = new URLSearchParams(history.location.search);

  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const groupFolderId = parseInt(query.get(PARENT_FOLDER_SEARCH_PARAM_NAME) || '', 10);
  const personFolderId = parseInt(query.get(PERSON_FOLDER_SEARCH_PARAM_NAME) || '', 10);

  const closeHandler = () => goBackOrReplace(makeParentUrl(match.url));

  return (
    <Modal
      title={t('persons:dialog.updateMemberPositionTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="md"
      fullWidth={true}
      data-test={EditMemberPositionDialog.name}
    >
      <MemberPositionDetailsProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        groupFolderId={groupFolderId}
        personFolderId={personFolderId}
        queryOptions={{ onError: catchError }}
      >
        <MemberPositionUpdateProvider
          businessUnitId={businessUnitId}
          sectionId={sectionId}
          groupFolderId={groupFolderId}
          queryOptions={{ onError: catchError }}
        >
          <SimplePersonsListProvider businessUnitId={businessUnitId}>
            <EditMemberPosition businessUnitId={businessUnitId} groupFolderId={groupFolderId} />
          </SimplePersonsListProvider>
        </MemberPositionUpdateProvider>
      </MemberPositionDetailsProvider>
    </Modal>
  );
}

function EditMemberPosition({ businessUnitId, groupFolderId }: { businessUnitId: number; groupFolderId: number }) {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const { data: member, isFetched, isLoading } = useMemberPositionDetailsProvider();
  const { data: persons, isLoading: arePersonsLoading } = useSimplePersonsListProvider();
  const [openImpossibleToSave, setOpenImpossibleToSave] = useState(false);

  const { mutateAsync } = useMemberPositionUpdateProvider();

  const onSubmit = useCallback(
    (formValues: PersonFolderUpdateRequest) => {
      if (formValues.positionEndDate && formValues.hasChildren) {
        setOpenImpossibleToSave(true);
      } else {
        return mutateAsync(formValues).then(() =>
          history.push({ pathname: makeParentUrl(match.url), search: history.location.search })
        );
      }
    },
    [mutateAsync, history, match.url]
  );

  const onCancel = useCallback(() => {
    history.push({ pathname: makeParentUrl(match.url), search: history.location.search });
  }, [history, match.url]);

  if (isLoading || arePersonsLoading) {
    return (
      <Box height={265}>
        <Spinner />
      </Box>
    );
  }

  if (!persons || (!member && isFetched)) {
    return null;
  }

  return (
    <>
      <MemberPositionForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        businessUnitId={businessUnitId}
        initialValues={member}
        persons={persons}
        groupFolderId={groupFolderId}
      />
      <ConfirmDialog
        open={openImpossibleToSave}
        title={t('persons:editMemberPositionConfirmDialog.title')}
        confirmText={t('persons:editMemberPositionConfirmDialog.text')}
        handleSubmit={noop}
        onClose={() => setOpenImpossibleToSave(false)}
        primaryButtonDisabled={false}
      />
    </>
  );
}
