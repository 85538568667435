import React from 'react';

import { getAccountPhotoURL } from 'api/http';
import { AccountShortView } from 'api/generated';

import { Spinner } from 'components/material/Spinner';

import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MemberList } from 'components/lists/MemberList';

export interface PersonListProps {
  businessUnitId: number;
  members: AccountShortView[] | undefined;
  isLoading: boolean;
}

/**
 * Component renders list of specified users.
 * @param param0
 * @returns
 */
export function AccountList({ businessUnitId, members, isLoading }: PersonListProps) {
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <MemberList
      members={members}
      getMemberLinkUrl={user => `${url}/account/${user.id}/show`}
      getActionButtons={user => undefined}
      getMemberPhotoUrl={user => getAccountPhotoURL(user.id, user.photoId)}
      emptyText={t('businessUnit:responsible.empty')}
    />
  );
}
