import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { DocumentControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

type DocumentArchiveProviderType = {
  controllers: {
    archive: UseMutationResult<object, AxiosError<unknown>, number>;
    unarchive: UseMutationResult<object, AxiosError<unknown>, number>;
  };
};

const DocumentArchiveContext = createContext<DocumentArchiveProviderType | null>(null);
DocumentArchiveContext.displayName = `${upperFirst(RESOURCE_NAME)}Archive`;

export function useDocumentArchiveProvider(): DocumentArchiveProviderType {
  const contextState = useContext(DocumentArchiveContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentArchiveProvider.name} must be used within a ${DocumentArchiveContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentArchiveProviderProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, number>;
}

export function DocumentArchiveProvider(props: React.PropsWithChildren<DocumentArchiveProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: DocumentArchiveProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
      queryClient.invalidateQueries([RESOURCE_NAME, { id: props.documentId }]);
    },
  };
  const archive = useMutation<object, AxiosError<unknown>, number>(
    async (version: number) => {
      return DocumentApi.archiveDocument(props.businessUnitId, props.sectionId, props.documentId, version).then(
        resp => resp.data
      );
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );

  const unarchive = useMutation<object, AxiosError<unknown>, number>(
    async (version: number) => {
      return DocumentApi.unarchiveDocument(props.businessUnitId, props.sectionId, props.documentId, version).then(
        resp => resp.data
      );
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );

  return (
    <DocumentArchiveContext.Provider value={{ controllers: { archive, unarchive } }}>
      {props.children}
    </DocumentArchiveContext.Provider>
  );
}
