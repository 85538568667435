import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { SecretaryUpdateRequest, SecretaryCreateRequest, UserControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const UserApi = UserControllerApiFactory(ApiConfiguration);

type SecretaryCreateProviderType = {
  accountId: number;
  controller: UseMutationResult<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryCreateRequest, unknown>;
};

const SecretaryCreateContext = createContext<SecretaryCreateProviderType | null>(null);
SecretaryCreateContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useSecretaryCreateProvider(): SecretaryCreateProviderType {
  const contextState = useContext(SecretaryCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSecretaryCreateProvider.name} must be used within a ${SecretaryCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface SecretaryCreateProviderProps {
  accountId: number;
  queryOptions?: UseMutationOptions<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryCreateRequest, unknown>;
}
export function SecretaryCreateProvider(props: React.PropsWithChildren<SecretaryCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: SecretaryCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const create = useMutation<SecretaryUpdateRequest, AxiosError<unknown>, SecretaryCreateRequest>(
    async (data: SecretaryCreateRequest) => {
      return UserApi.createSecretary(data).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return (
    <SecretaryCreateContext.Provider
      value={{
        accountId: props.accountId,
        controller: create,
      }}
    >
      {props.children}
    </SecretaryCreateContext.Provider>
  );
}
