import Box from '@material-ui/core/Box';
import React from 'react';
import { DocumentSearchResultItem } from './DocumentSearchResultItem';
import { useSearchDocumentProvider } from 'api/SearchProviders';
import { Spinner } from '../../../components/material';

export function DocumentsSearchMoreResults() {
  const { data, isLoading } = useSearchDocumentProvider();

  if (isLoading) {
    return <Spinner />;
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      {data.map((document: any) => (
        <Box key={document.id}>
          <DocumentSearchResultItem document={document} />
        </Box>
      ))}
    </>
  );
}
