import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function EmptyAccountsListIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="133" height="108" viewBox="0 0 133 108" color="inherit" fill="none" {...props}>
      <path
        d="M79.709 105.291C108.344 105.291 132.074 81.5605 132.074 52.9258C132.074 24.3438 108.291 0.560547 79.6562 0.560547C53.2891 0.560547 30.9824 20.8633 27.8184 46.4395C44.2188 46.7031 57.9824 58.7266 60.8301 74.4414C66.4199 72.0684 72.9062 70.6973 79.709 70.6973C92.6816 70.6973 104.125 75.5488 111.086 83.0371C103.176 91.2109 92.0488 96.3789 79.6562 96.3789C72.3789 96.3789 65.5234 94.5332 59.5117 91.4219C58.5625 94.2168 57.2969 96.8535 55.5566 99.332C62.8867 103.129 71.0605 105.291 79.709 105.291ZM79.6562 61.8379C69.584 61.8379 61.9375 53.0312 61.9375 42.0625C61.9375 31.6738 69.7422 22.6562 79.6562 22.6562C89.623 22.6562 97.4805 31.6738 97.4277 42.0625C97.4277 53.0312 89.7812 61.8379 79.6562 61.8379ZM27.3438 107.295C41.8984 107.295 54.1328 95.166 54.1328 80.5059C54.1328 65.8457 42.0566 53.7168 27.3438 53.7168C12.6836 53.7168 0.554688 65.8457 0.554688 80.5059C0.554688 95.2715 12.6836 107.295 27.3438 107.295ZM27.3965 97.9082C25.5508 97.9082 23.916 96.6426 23.916 94.6387V83.7227H13.8438C12.0508 83.7227 10.5742 82.2461 10.5742 80.5059C10.5742 78.7129 12.0508 77.2363 13.8438 77.2363H23.916V66.3203C23.916 64.3691 25.5508 63.1035 27.3965 63.1035C29.1895 63.1035 30.8242 64.3691 30.8242 66.3203V77.2363H40.8965C42.6895 77.2363 44.1133 78.7129 44.1133 80.5059C44.1133 82.2461 42.6895 83.7227 40.8965 83.7227H30.8242V94.6387C30.8242 96.6426 29.1895 97.9082 27.3965 97.9082Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default EmptyAccountsListIcon;
