import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { FolderCreateRequest, FolderUpdateRequest, FolderControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from '../constants';

import isNil from 'lodash/isNil';

const FolderApi = FolderControllerApiFactory(ApiConfiguration);

type FolderCreateProviderType = UseMutationResult<
  FolderUpdateRequest,
  AxiosError<unknown>,
  FolderCreateRequest,
  unknown
>;

const FolderCreateContext = createContext<FolderCreateProviderType | null>(null);
FolderCreateContext.displayName = 'FolderCreate';

export function useFolderCreateProvider(): FolderCreateProviderType {
  const contextState = useContext(FolderCreateContext);
  if (isNil(contextState)) {
    throw new Error(`${useFolderCreateProvider.name} must be used within a ${FolderCreateContext.displayName} context`);
  }
  return contextState;
}

interface FolderCreateProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: UseMutationOptions<FolderUpdateRequest, AxiosError<unknown>, FolderCreateRequest, unknown>;
}

export function FolderCreateProvider(props: React.PropsWithChildren<FolderCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: FolderCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const create = useMutation<FolderUpdateRequest, AxiosError<unknown>, FolderCreateRequest>(
    async (data: FolderCreateRequest) => {
      return FolderApi.createFolder2(props.businessUnitId, props.sectionId, data).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <FolderCreateContext.Provider value={create}>{props.children}</FolderCreateContext.Provider>;
}
