import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccessControllerApiFactory, AccountAccessView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

type BusinessUnitAccessPersonsListProviderType = {
  readers: QueryObserverResult<AccountAccessView[], unknown>;
  responsible: QueryObserverResult<AccountAccessView[], unknown>;
};

const BusinessUnitAccessPersonsListContext = createContext<BusinessUnitAccessPersonsListProviderType | null>(null);
BusinessUnitAccessPersonsListContext.displayName = `${upperFirst(RESOURCE_NAME)}AccessPersonsList`;

export function useBusinessUnitAccessPersonsListProvider(): BusinessUnitAccessPersonsListProviderType {
  const contextState = useContext(BusinessUnitAccessPersonsListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitAccessPersonsListProvider.name} must be used within a ${BusinessUnitAccessPersonsListContext.displayName} context`
    );
  }
  return contextState;
}

interface BusinessUnitAccessPersonsListProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function BusinessUnitAccessPersonsListProvider(
  props: React.PropsWithChildren<BusinessUnitAccessPersonsListProviderProps>
) {
  const value = {
    readers: useQuery<AccountAccessView[], AxiosError<unknown>, AccountAccessView[]>(
      [RESOURCE_NAME, 'access', 'readers', { businessUnitId: props.businessUnitId }],
      () => AccessApi.getBusinessUnitReaderAccess2(props.businessUnitId).then(resp => resp.data)
    ),
    responsible: useQuery<AccountAccessView[], AxiosError<unknown>, AccountAccessView[]>(
      [RESOURCE_NAME, 'access', 'responsible', { businessUnitId: props.businessUnitId }],
      () => AccessApi.getBusinessUnitResponsibleAccess2(props.businessUnitId).then(resp => resp.data)
    ),
  };
  return (
    <BusinessUnitAccessPersonsListContext.Provider value={value}>
      {props.children}
    </BusinessUnitAccessPersonsListContext.Provider>
  );
}
