import React from 'react';

import { useTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';

import { PinStatusResponsePinStatusEnum } from 'api/generated';
import { LogUnreachableCase } from 'utils';
import { PinAbsentIcon, PinExpiredIcon, PinLockedIcon, PinActiveIcon } from 'components/icons';

interface AccountPinStatusProps {
  status: PinStatusResponsePinStatusEnum;
}
export function AccountPinStatus({ status }: AccountPinStatusProps) {
  const { t } = useTranslation();
  switch (status) {
    case PinStatusResponsePinStatusEnum.ABSENT:
      return (
        <Chip
          icon={<PinAbsentIcon style={{ height: 12 }} />}
          size="small"
          label={t(`accounts:pinStatuses.${status}` as const)}
        />
      );
    case PinStatusResponsePinStatusEnum.ACTIVE:
      return (
        <Chip
          icon={<PinActiveIcon style={{ height: 12 }} />}
          size="small"
          label={t(`accounts:pinStatuses.${status}` as const)}
        />
      );
    case PinStatusResponsePinStatusEnum.EXPIRED:
      return (
        <Chip
          icon={<PinExpiredIcon style={{ height: 12 }} />}
          size="small"
          label={t(`accounts:pinStatuses.${status}` as const)}
        />
      );
    case PinStatusResponsePinStatusEnum.LOCKED:
      return (
        <Chip
          icon={<PinLockedIcon style={{ height: 12 }} />}
          size="small"
          label={t(`accounts:pinStatuses.${status}` as const)}
        />
      );
    default:
      LogUnreachableCase(status);
      return null;
  }
}
