import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitView } from 'api/generated';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const RESOURCE_NAME = 'businessUnit';

const BusinessUnitShowApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type BusinessUnitShowProviderType = QueryObserverResult<BusinessUnitView, unknown>;

const BusinessUnitShowContext = createContext<BusinessUnitShowProviderType | null>(null);
BusinessUnitShowContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useBusinessUnitShowProvider(): BusinessUnitShowProviderType {
  const contextState = useContext(BusinessUnitShowContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useBusinessUnitShowProvider.name} must be used within a ${BusinessUnitShowContext.displayName} context`
    );
  }
  return contextState;
}

export const BusinessUnitShowConsumer = BusinessUnitShowContext.Consumer;

interface BusinessUnitShowProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function BusinessUnitShowProvider(props: React.PropsWithChildren<BusinessUnitShowProviderProps>) {
  const value = useQuery<BusinessUnitView | null, AxiosError<unknown>, BusinessUnitView>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId }],
    () => BusinessUnitShowApi.getBusinessUnitById2(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <BusinessUnitShowContext.Provider value={value}>{props.children}</BusinessUnitShowContext.Provider>;
}
