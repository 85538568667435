import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SearchFileIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="24" height="27" viewBox="0 0 24 27" color="inherit" fill="none" {...props}>
      <path
        opacity="0.15"
        d="M17.588 0.278969H6.41333C2.36 0.278969 0 2.6523 0 6.71897V20.4923C0 24.6256 2.36 26.9456 6.41333 26.9456H17.588C21.7067 26.9456 24 24.6256 24 20.4923V6.71897C24 2.6523 21.7067 0.278969 17.588 0.278969Z"
        fill="#200E32"
      />
    </SvgIcon>
  );
}

export default SearchFileIcon;
