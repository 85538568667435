import i18n from 'i18n/config';

import isEmpty from 'lodash/isEmpty';
import { FieldValidator } from 'final-form';

import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

import { getPhoneCode, getPhoneRequiredLength } from 'utils/phone';

let rEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
let rUrl =
  // eslint-disable-next-line
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

// https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
let rSemVer =
  // eslint-disable-next-line
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/gm;

export const phoneLength: FieldValidator<string | undefined> = value => {
  if (!value) {
    return undefined;
  }
  const countryCode = getPhoneCode(value);
  const length = getPhoneRequiredLength(value);

  if (length === 0 || value.toString() === countryCode) {
    return undefined;
  }

  return isString(value) && value.length === length ? undefined : i18n.t('form:errors.phoneLength', { count: length });
};

export const maxLength: (l: number) => FieldValidator<string | undefined> = (length: number) => value => {
  return isNil(value) || (isString(value) && value.length <= length)
    ? undefined
    : i18n.t('form:errors.maxLength', { count: length });
};

export const url: FieldValidator<string | undefined> = value => {
  return isString(value) && !isEmpty(value) && value.match(rUrl) ? undefined : i18n.t('form:errors.url');
};

export const email: FieldValidator<string | undefined> = value => {
  if (isEmpty(value)) {
    return undefined;
  }
  return isString(value) && value.match(rEmail) ? undefined : i18n.t('form:errors.email');
};

export const semVer: FieldValidator<string | undefined> = value => {
  return isString(value) && !isEmpty(value) && value.match(rSemVer) ? undefined : i18n.t('form:errors.semVer');
};

export const numbers: () => FieldValidator<string | undefined> = () => value => {
  return isNil(value) || /^\d*$/.test(value) ? undefined : i18n.t('form:errors.numbers');
};

export const requiredPhone = (value: any) => {
  const error = i18n.t('form:errors.required');
  if (!value) {
    return error;
  }
  const countryCode = getPhoneCode(value);
  return value === countryCode ? error : undefined;
};

export const required: FieldValidator<any> = value => {
  return !isEmpty(value) || typeof value === 'number' ? undefined : i18n.t('form:errors.required');
};

export const composeValidators = <TValue>(...validators: FieldValidator<TValue>[]): FieldValidator<TValue> => {
  return (...args) => validators.reduce((error, validator) => error || validator(...args), undefined);
};
