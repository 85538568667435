import React, { useCallback, useRef } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

import DocumentsListItemFolder from './ListItems/DocumentsListItemFolder';
import DocumentsListItemDocument from './ListItems/DocumentsListItemDocument';
import { EmptyDocumentsList } from './EmptyDocumentsList';

import { useDocumentsListProvider } from 'api/DocumentProviders/DocumentsListProvider';
import { Spinner } from 'components/material';
import { LoadMore } from 'components/common';

import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { closestCorners, CollisionDetection, DndContext, rectIntersection } from '@dnd-kit/core';
import findIndex from 'lodash/findIndex';
import { HierarchyPlaceDataTypeEnum, UserSessionStateViewRoleEnum } from '../../../api/generated/views';
import { useAuthProvider } from '../../../api/AuthProviders';
import { SortableList } from '../../../components/common/SortableList';
import { useDocumentOrderUpdateProvider } from '../../../api/DocumentProviders/Documents/DocumentOrderUpdateProvider';

interface CollisionStatus {
  over: string | null;
  index: number;
  item: any;
}
export function DocumentsList({
  businessUnitId,
  sectionId,
  parentFolderId,
}: {
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
}) {
  const { role } = useAuthProvider();
  const isAdmin = role ? [UserSessionStateViewRoleEnum.ROOT, UserSessionStateViewRoleEnum.ADMIN].includes(role) : false;
  const { data, isFetched, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    useDocumentsListProvider();

  const documents = flatten(data?.pages);

  const {
    controller: { mutateAsync: updateOrder, isLoading: isUpdatingOrder },
  } = useDocumentOrderUpdateProvider();

  const onDragEnd = useCallback(
    event => {
      const { active, over } = event;
      if (over && active.id !== over.id && !isUpdatingOrder) {
        const activeId = parseInt(active.id, 10);
        const overId = parseInt(over.id, 10);
        const fromIndex = findIndex(documents, { id: activeId });
        const toIndex = over.data?.current?.index;
        updateOrder({
          from: {
            id: activeId,
            index: fromIndex,
            dataType: HierarchyPlaceDataTypeEnum[documents[fromIndex].dataType],
          },
          to: {
            id: overId,
            index: toIndex,
            dataType: HierarchyPlaceDataTypeEnum[documents[toIndex].dataType],
          },
        });
      }
    },
    [isUpdatingOrder, updateOrder, documents]
  );

  const collisionStatus = useRef<CollisionStatus | undefined>();

  const customCollisionDetectionStrategy: CollisionDetection = useCallback(
    (rects, rect) => {
      const detectionIntersect = rectIntersection(rects, rect);
      const detectionId = parseInt(detectionIntersect || '', 10);
      const currentId = closestCorners(rects, rect);
      const indexTo = findIndex(documents, { id: detectionId });
      collisionStatus.current = {
        over: currentId,
        index: indexTo,
        item: documents[indexTo],
      };
      return detectionIntersect;
    },
    [documents]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(documents) && isFetched) {
    return <EmptyDocumentsList />;
  }

  return (
    <DndContext
      collisionDetection={customCollisionDetectionStrategy}
      onDragEnd={props =>
        onDragEnd({ ...props, over: props.over ? { ...props.over, data: { ...collisionStatus } } : null })
      }
    >
      <List aria-label="documents" data-test="documents_list">
        {documents.map(item => {
          switch (item.dataType) {
            case 'FOLDER':
              return (
                <SortableList
                  data={item}
                  disabled={!isAdmin || isUpdatingOrder || isFetching}
                  key={`${item.id}${item.dataType}`}
                >
                  <DocumentsListItemFolder
                    listItem={item}
                    businessUnitId={businessUnitId}
                    sectionId={sectionId}
                    parentFolderId={parentFolderId}
                  />
                </SortableList>
              );
            case 'DOCUMENT':
              return (
                <SortableList
                  data={item}
                  disabled={!isAdmin || isUpdatingOrder || isFetching}
                  key={`${item.id}${item.dataType}`}
                >
                  <DocumentsListItemDocument
                    listItem={item}
                    businessUnitId={businessUnitId}
                    sectionId={sectionId}
                    parentFolderId={parentFolderId}
                  />
                </SortableList>
              );
            default:
              return null;
          }
        })}
        {hasNextPage && (
          <ListItem>
            <ListItemText
              primary={
                <Box my={3.5} mx={8}>
                  <LoadMore onLoadMore={fetchNextPage} isLoading={Boolean(isFetchingNextPage)} />
                </Box>
              }
            />
          </ListItem>
        )}
      </List>
    </DndContext>
  );
}
