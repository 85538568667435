import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { useErrorCatcher } from 'api/notifications';
import {
  MemberPositionCreateProvider,
  useMemberPositionCreateProvider,
  SimplePersonsListProvider,
  useSimplePersonsListProvider,
} from 'api/PersonsProvider';

import { Modal, Spinner } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { GroupRequiredRouterParams } from '../../interfaces';
import { MemberPositionForm } from './MemberPositionForm';
import { PersonFormValuesType } from '../PersonForm/PersonForm';

import { PARENT_FOLDER_SEARCH_PARAM_NAME } from '../../constants';

export function AddMemberPositionDialog({ routeProps }: { routeProps: RouteComponentProps }) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();

  const history = useHistory();
  const match = useRouteMatch<GroupRequiredRouterParams>();
  const query = new URLSearchParams(history.location.search);

  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);

  let groupFolderId = parseInt(match.params.groupFolderId || '', 10);
  if (isNaN(groupFolderId)) {
    groupFolderId = parseInt(query.get(PARENT_FOLDER_SEARCH_PARAM_NAME) || '', 10);
  }

  const closeHandler = () => goBackOrReplace({ pathname: makeParentUrl(match.url), search: history.location.search });

  return (
    <Modal
      title={t('persons:dialog.addMemberPositionTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="md"
      fullWidth={true}
      data-test={AddMemberPositionDialog.name}
    >
      <MemberPositionCreateProvider
        businessUnitId={businessUnitId}
        sectionId={sectionId}
        groupFolderId={groupFolderId}
        queryOptions={{ onError: catchError }}
      >
        <SimplePersonsListProvider businessUnitId={businessUnitId}>
          <AddMemberPosition businessUnitId={businessUnitId} groupFolderId={groupFolderId} />
        </SimplePersonsListProvider>
      </MemberPositionCreateProvider>
    </Modal>
  );
}

function AddMemberPosition({ businessUnitId, groupFolderId }: { businessUnitId: number; groupFolderId: number }) {
  const match = useRouteMatch();
  const history = useHistory();

  const { data: persons, isLoading: arePersonsLoading } = useSimplePersonsListProvider();
  const { mutateAsync } = useMemberPositionCreateProvider();

  const onSubmit = useCallback(
    (formValues: PersonFormValuesType) => {
      return mutateAsync(formValues).then(() =>
        history.push({ pathname: makeParentUrl(match.url), search: history.location.search })
      );
    },
    [mutateAsync, history, match.url]
  );

  const onCancel = useCallback(() => {
    history.push({ pathname: makeParentUrl(match.url), search: history.location.search });
  }, [history, match.url]);

  if (arePersonsLoading) {
    return (
      <Box height={265}>
        <Spinner />
      </Box>
    );
  }

  if (!persons) {
    return null;
  }

  return (
    <MemberPositionForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      businessUnitId={businessUnitId}
      groupFolderId={groupFolderId}
      persons={persons}
    />
  );
}
