import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { BusinessUnitSectionControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const SectionApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

export interface SectionRemoveRequest {
  businessUnitId?: number;
  sectionId?: number;
}

type SectionRemoveProviderType = UseMutationResult<object, AxiosError<unknown>, SectionRemoveRequest, unknown>;

const SectionRemoveContext = createContext<SectionRemoveProviderType | null>(null);
SectionRemoveContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useSectionRemoveProvider(): SectionRemoveProviderType {
  const contextState = useContext(SectionRemoveContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSectionRemoveProvider.name} must be used within a ${SectionRemoveContext.displayName} context`
    );
  }
  return contextState;
}

interface SectionRemoveProviderProps {
  businessUnitId: number;
  sectionId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, SectionRemoveRequest, unknown>;
}

export function SectionRemoveProvider(props: React.PropsWithChildren<SectionRemoveProviderProps>) {
  const queryClient = useQueryClient();
  const defaultOptions: SectionRemoveProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([RESOURCE_NAME, { businessUnitId: props.businessUnitId }]);
    },
  };
  const value = useMutation<object, AxiosError<unknown>, SectionRemoveRequest>(
    async ({ sectionId, businessUnitId }: SectionRemoveRequest) => {
      return SectionApi.deleteBusinessUnitSection2(props.businessUnitId, props.sectionId).then(resp => resp.data);
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <SectionRemoveContext.Provider value={value}>{props.children}</SectionRemoveContext.Provider>;
}
