/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Secretary (not primary user) update form
 * @export
 * @interface SecretaryUpdateRequest
 */
export interface SecretaryUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  firstNameEn: string;
  /**
   *
   * @type {number}
   * @memberof SecretaryUpdateRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  lastNameEn: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  middleNameEn?: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  smsPhone?: string;
  /**
   * For information only. Ignored while updating
   * @type {string}
   * @memberof SecretaryUpdateRequest
   */
  state: SecretaryUpdateRequestStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SecretaryUpdateRequestStateEnum {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED',
}
