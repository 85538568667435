import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import { SectionOrderUpdateProvider, SectionsProvider } from 'api/SectionsProviders';
import { useBusinessUnitShowProvider } from 'api/BusinessUnitProviders/BusinessUnitShowProvider';
import { useErrorCatcher } from 'api/notifications';
import { useAuthProvider } from 'api/AuthProviders';
import { UserSessionStateViewRoleEnum } from 'api/generated';

import { PencilIcon, GroupIcon } from 'components/icons';
import { Spinner } from 'components/material';

import { ListWrapper } from '../../Layouts/ListWrapper';
import { SectionsList } from './SectionsList';
import { backToolbarHeight, sectionTitleHeight } from 'styles/constants';

export function SectionsColumn() {
  const classes = useStyles();
  const catchError = useErrorCatcher();
  const { t } = useTranslation();
  const location = useLocation();

  const { data: businessUnit, isLoading } = useBusinessUnitShowProvider();
  const { role } = useAuthProvider();

  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;
  const canAddSection = isAdmin;
  const canEditSection = isAdmin || role === UserSessionStateViewRoleEnum.RESPONSIBLE;
  const canEditUnit = isAdmin;
  const canReOrder = isAdmin;

  if (isLoading) {
    return <Spinner />;
  }

  if (!businessUnit?.id) {
    return null;
  }

  return (
    <ListWrapper>
      <Box height={backToolbarHeight} minHeight={backToolbarHeight} />
      <Box className={classes.header} height={sectionTitleHeight}>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Box className={classes.title} component="span">
            {businessUnit?.title}
          </Box>
          {canEditUnit && (
            <Link to={`${location.pathname}/edit`}>
              <PencilIcon style={{ fontSize: 26 }} className={classes.editIcon} />
            </Link>
          )}
          {!!businessUnit.responsible && (
            <Link to={`${location.pathname}/responsible`}>
              <Box className={classes.chip} component="span">
                &#9432;&#160;{t('businessUnit:responsible.responsible')}
              </Box>
            </Link>
          )}
          <Link to={`${location.pathname}/members`}>
            <Box className={classes.chip} ml={1}>
              <GroupIcon />
              <Box ml={0.5}>{t('businessUnit:members')}</Box>
            </Box>
          </Link>
        </Box>
        {canAddSection && (
          <Link to={`${location.pathname}/add_section`}>
            <Button variant="contained" size="small" color="primary">
              {`+ ${t('businessUnit:addSection')}`}
            </Button>
          </Link>
        )}
      </Box>
      <SectionsProvider businessUnitId={businessUnit.id} queryOptions={{ onError: catchError }}>
        <SectionOrderUpdateProvider businessUnitId={businessUnit.id}>
          <SectionsList canEdit={canEditSection} canReOrder={canReOrder} />
        </SectionOrderUpdateProvider>
      </SectionsProvider>
    </ListWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(0, 9, 0, 9),
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginRight: theme.spacing(1.5),
    lineHeight: '32px',
  },
  editIcon: {
    marginRight: theme.spacing(1.5),
    lineHeight: '32px',
    cursor: 'pointer',
    width: 19,
    height: 19,
    marginTop: theme.spacing(0.75),
    color: theme.palette.grey[600],
  },
  chip: {
    backgroundColor: '#ECEEF3',
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    lineHeight: '32px',
    color: '#200E32',
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    borderRadius: 10,
    cursor: 'pointer',
  },
}));
