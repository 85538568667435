import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import {
  InfiniteQueryObserverResult,
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitSectionControllerApiFactory, ContentItemView } from 'api/generated';
import { normalizeListData } from 'utils';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const DocumentsApi = BusinessUnitSectionControllerApiFactory(ApiConfiguration);

type DocumentsListProviderType = InfiniteQueryObserverResult<ContentItemView[], unknown>;

const DocumentsListContext = createContext<DocumentsListProviderType | null>(null);
DocumentsListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useDocumentsListProvider(): DocumentsListProviderType {
  const contextState = useContext(DocumentsListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentsListProvider.name} must be used within a ${DocumentsListContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentsListProviderProps {
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
  filter: any;
  queryOptions?: UseInfiniteQueryOptions<ContentItemView[], AxiosError<unknown>, ContentItemView[]>;
}

export const ITEMS_PER_PAGE = 20;

const initialPageParam = {
  skip: 0,
  limit: ITEMS_PER_PAGE,
};

export function DocumentsListProvider(props: React.PropsWithChildren<DocumentsListProviderProps>) {
  const value = useInfiniteQuery<ContentItemView[], AxiosError<unknown>, ContentItemView[]>(
    [RESOURCE_NAME, props.businessUnitId, props.sectionId, props.parentFolderId],
    ({ pageParam = initialPageParam, queryKey }: QueryFunctionContext) => {
      return DocumentsApi.getContent(
        props.businessUnitId,
        props.sectionId,
        props.parentFolderId,
        false, // allHierarchyLevels
        pageParam.skip,
        pageParam.limit,
        {
          ...props.filter,
          ...pageParam,
        }
      ).then(resp => {
        return normalizeListData(resp.data, pageParam.limit);
      });
    },
    props.queryOptions
  );
  return <DocumentsListContext.Provider value={value}>{props.children}</DocumentsListContext.Provider>;
}
