import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PencilIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="19" height="19" viewBox="0 0 19 19" fill="none" color="inherit" {...props}>
      <path
        d="M16.4072 4.16309L17.5781 3.00293C18.1582 2.42285 18.1797 1.78906 17.6533 1.25195L17.2344 0.822266C16.708 0.295898 16.0635 0.349609 15.4834 0.918945L14.3125 2.06836L16.4072 4.16309ZM3.24805 17.3008L15.376 5.17285L13.292 3.09961L1.16406 15.2061L0.12207 17.7412C-0.00683594 18.0635 0.326172 18.418 0.648438 18.2998L3.24805 17.3008Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default PencilIcon;
