import dropRight from 'lodash/dropRight';

export function normalizeListData(data: any, limit: number | undefined) {
  if (limit && data.length > limit) {
    return dropRight(data, data.length - limit);
  }
  return data;
}

export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from<T>(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}
