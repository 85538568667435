import React, { useCallback } from 'react';

import { useHistory } from 'react-router';
import { SearchTextField } from 'components/fields';
import { SEARCH_PAGE_PATH } from '../Router';

export function HeaderSearch() {
  const history = useHistory();

  const onSubmit = useCallback(
    value => {
      if (value) {
        history.push(`${SEARCH_PAGE_PATH}?query=${value}`);
      }
    },
    [history]
  );

  return <SearchTextField submitHandler={onSubmit} />;
}
