import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryKey, QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccountControllerApiFactory, AccountView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);

type AccountShowProviderType = QueryObserverResult<AccountView, unknown>;

const AccountShowContext = createContext<AccountShowProviderType | null>(null);
AccountShowContext.displayName = `${upperFirst(RESOURCE_NAME)}Show`;

export function useAccountShowProvider(): AccountShowProviderType {
  const contextState = useContext(AccountShowContext);
  if (isNil(contextState)) {
    throw new Error(`${useAccountShowProvider.name} must be used within a ${AccountShowContext.displayName} context`);
  }
  return contextState;
}

interface AccountShowProviderProps {
  id: AccountView['id'];
  queryOptions?: UseQueryOptions<AccountView, AxiosError<unknown>, AccountView, QueryKey>;
}
export function AccountShowProvider(props: React.PropsWithChildren<AccountShowProviderProps>) {
  const value = useQuery<AccountView, AxiosError<unknown>, AccountView>(
    [RESOURCE_NAME, 'show', { id: props.id }],
    () => AccountApi.getAccountById(props.id).then(resp => resp.data),
    props.queryOptions
  );
  return <AccountShowContext.Provider value={value}>{props.children}</AccountShowContext.Provider>;
}
