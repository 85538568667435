import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitUpdateRequest } from 'api/generated';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const RESOURCE_NAME = 'company';

const CompanyInfoApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type CompanyInfoProviderType = QueryObserverResult<BusinessUnitUpdateRequest, unknown>;

const CompanyInfoContext = createContext<CompanyInfoProviderType | null>(null);
CompanyInfoContext.displayName = `${upperFirst(RESOURCE_NAME)}Info`;

export function useCompanyInfoProvider(): CompanyInfoProviderType {
  const contextState = useContext(CompanyInfoContext);
  if (isNil(contextState)) {
    throw new Error(`${useCompanyInfoProvider.name} must be used within a ${CompanyInfoContext.displayName} context`);
  }
  return contextState;
}

interface CompanyInfoProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

export function CompanyInfoProvider(props: React.PropsWithChildren<CompanyInfoProviderProps>) {
  const value = useQuery<BusinessUnitUpdateRequest | null, AxiosError<unknown>, BusinessUnitUpdateRequest>(
    [RESOURCE_NAME, 'info', { businessUnitId: props.businessUnitId }],
    () => CompanyInfoApi.getBusinessUnitForEdit(props.businessUnitId).then(resp => resp.data),
    props.queryOptions
  );
  return <CompanyInfoContext.Provider value={value}>{props.children}</CompanyInfoContext.Provider>;
}
