import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { AccessControllerApiFactory, AccountAccessView } from 'api/generated';

import { RESOURCE_NAME } from '../constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { SectionAccess } from './types';

const AccessApi = AccessControllerApiFactory(ApiConfiguration);

type SectionAccessProviderType = {
  businessUnitId: number;
  sectionId?: number;
  newSectionAccess: SectionAccess;
};

const NewSectionAccessContext = createContext<SectionAccessProviderType | null>(null);
NewSectionAccessContext.displayName = `${upperFirst(RESOURCE_NAME)}Access`;

export function useNewSectionAccessProvider(): SectionAccessProviderType {
  const contextState = useContext(NewSectionAccessContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useNewSectionAccessProvider.name} must be used within a ${NewSectionAccessContext.displayName} context`
    );
  }
  return contextState;
}

interface NewSectionAccessProviderProps {
  businessUnitId: number;
  queryOptions?: any;
}

/**
 * Provides users with access to the business unit.
 * @param props
 * @returns
 */
export function NewSectionAccessProvider(props: React.PropsWithChildren<NewSectionAccessProviderProps>) {
  // Get access to a NEW business unit section for all readers
  const newSectionAccess: SectionAccess = {
    readerAccess: useQuery<AccountAccessView[], AxiosError<unknown>>(
      [RESOURCE_NAME, 'access', 'reader', { businessUnitId: props.businessUnitId }],
      () => AccessApi.getBusinessUnitSectionReaderAccess5(props.businessUnitId).then(resp => resp.data),
      props.queryOptions
    ),
    responsibleAccess: useQuery<AccountAccessView[], AxiosError<unknown>>(
      [RESOURCE_NAME, 'access', 'responsible', { businessUnitId: props.businessUnitId }],
      () => AccessApi.getBusinessUnitSectionResponsibleAccess2(props.businessUnitId).then(resp => resp.data),
      props.queryOptions
    ),
  };

  return (
    <NewSectionAccessContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        newSectionAccess,
      }}
    >
      {props.children}
    </NewSectionAccessContext.Provider>
  );
}
