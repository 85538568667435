import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PinExpiredIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M0 7.04004C0 10.3564 2.74219 13.0986 6.05859 13.0986C7.95117 13.0986 9.75 12.1553 10.8105 10.7373C11.0742 10.4209 11.0215 10.04 10.752 9.84082C10.4648 9.62988 10.1191 9.72363 9.87891 9.99902C8.99414 11.1475 7.62891 11.8799 6.05859 11.8857C3.36914 11.8916 1.21289 9.72363 1.21289 7.04004C1.21289 4.35059 3.36914 2.19434 6.05859 2.19434C8.33203 2.19434 10.2246 3.72949 10.7578 5.82715H9.9082C9.46289 5.82715 9.33984 6.16113 9.59766 6.52441L11.0039 8.50488C11.2148 8.80371 11.543 8.80371 11.7598 8.50488L13.166 6.53027C13.4297 6.16699 13.3066 5.82715 12.8555 5.82715H12C11.4316 3.0791 8.96484 0.975586 6.05859 0.975586C2.74219 0.975586 0 3.71777 0 7.04004ZM4.55859 9.87012H7.56445C7.98047 9.87012 8.18555 9.65918 8.18555 9.20801V6.90527C8.18555 6.51855 8.03906 6.31348 7.73438 6.26074V5.59277C7.73438 4.4502 7.04297 3.71191 6.05859 3.71191C5.08008 3.71191 4.38867 4.4502 4.38867 5.59277V6.26074C4.08398 6.31348 3.9375 6.52441 3.9375 6.90527V9.20801C3.9375 9.65918 4.13672 9.87012 4.55859 9.87012ZM5.08594 5.53418C5.08594 4.84863 5.47266 4.3916 6.05859 4.3916C6.64453 4.3916 7.03711 4.84277 7.03711 5.53418V6.24316L5.08594 6.24902V5.53418Z"
        fill="white"
      />
    </SvgIcon>
  );
}
