import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { AccountControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const AccountApi = AccountControllerApiFactory(ApiConfiguration);

export interface AccountLockRequest {
  type: 'lock' | 'unlock';
}

type AccountLockProviderType = UseMutationResult<object, AxiosError<unknown>, AccountLockRequest, unknown>;

const AccountLockContext = createContext<AccountLockProviderType | null>(null);
AccountLockContext.displayName = `${upperFirst(RESOURCE_NAME)}Lock`;

export function useAccountLockProvider(): AccountLockProviderType {
  const contextState = useContext(AccountLockContext);
  if (isNil(contextState)) {
    throw new Error(`${useAccountLockProvider.name} must be used within a ${AccountLockContext.displayName} context`);
  }
  return contextState;
}

interface AccountLockProviderProps {
  accountId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, AccountLockRequest, unknown>;
}

export function AccountLockProvider(props: React.PropsWithChildren<AccountLockProviderProps>) {
  const queryClient = useQueryClient();
  const defaultOptions: AccountLockProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const value = useMutation<object, AxiosError<unknown>, AccountLockRequest>(
    async ({ type }: AccountLockRequest) => {
      if (type === 'lock') {
        return AccountApi.lockPrimaryUser(props.accountId).then(resp => resp.data);
      } else {
        return AccountApi.unlockPrimaryUser(props.accountId).then(resp => resp.data);
      }
    },
    {
      ...defaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <AccountLockContext.Provider value={value}>{props.children}</AccountLockContext.Provider>;
}
