import { AxiosError } from 'axios';
import { compact, get, map } from 'lodash';

export function getErrorTextFromServerError(err: AxiosError): string {
  let errText: string;
  if (err) {
    const respErrs = get(err, 'response.data.errors', []);
    const respErr = get(err, 'response.data.error', '');
    const respErrsMessage = compact(map(respErrs, 'message')).join('\n') || respErr;
    errText = respErrsMessage || err.message;
  } else {
    errText = 'An unknown error has occurred';
  }
  return errText;
}
export function updateErrorTextFromServerError<T extends AxiosError>(err: T, cb: (oldMessage: string) => string): T {
  if (err?.response?.data?.errors?.[0]?.message) {
    err.response.data.errors[0].message = cb(err?.response?.data?.errors?.[0]?.message);
  } else if (err?.response?.data?.error?.message) {
    err.response.data.error.message = cb(err.response.data.error.message);
  } else if (err?.message) {
    err.message = cb(err.message);
  }
  return err;
}
