import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { PersonControllerApiFactory, PersonUpdateRequest } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const PersonApi = PersonControllerApiFactory(ApiConfiguration);

type PersonDetailsForUpdateProviderType = QueryObserverResult<PersonUpdateRequest, unknown>;

const PersonDetailsForUpdateContext = createContext<PersonDetailsForUpdateProviderType | null>(null);
PersonDetailsForUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}DetailsForUpdate`;

export function usePersonDetailsForUpdateProvider(): PersonDetailsForUpdateProviderType {
  const contextState = useContext(PersonDetailsForUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${usePersonDetailsForUpdateProvider.name} must be used within a ${PersonDetailsForUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface PersonDetailsForUpdateProviderProps {
  businessUnitId: number;
  personId: number;
}
export function PersonDetailsWithUpdateProvider(props: React.PropsWithChildren<PersonDetailsForUpdateProviderProps>) {
  const value = useQuery<PersonUpdateRequest>([RESOURCE_NAME, { id: props.personId, edit: true }], () =>
    PersonApi.getBusinessUnitPersonForEdit2(props.businessUnitId, props.personId).then(resp => resp.data)
  );
  return (
    <PersonDetailsForUpdateContext.Provider value={value}>{props.children}</PersonDetailsForUpdateContext.Provider>
  );
}
