/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Version } from './version';

/**
 * Short view for mobile app version
 * @export
 * @interface MobileAppVersionShortView
 */
export interface MobileAppVersionShortView {
  /**
   *
   * @type {Version}
   * @memberof MobileAppVersionShortView
   */
  appVersion: Version;
  /**
   *
   * @type {string}
   * @memberof MobileAppVersionShortView
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof MobileAppVersionShortView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MobileAppVersionShortView
   */
  platformHeader: MobileAppVersionShortViewPlatformHeaderEnum;
  /**
   *
   * @type {string}
   * @memberof MobileAppVersionShortView
   */
  publicationDate?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MobileAppVersionShortViewPlatformHeaderEnum {
  IOS = 'IOS',
  WEB = 'WEB',
  ANDROID = 'ANDROID',
}
