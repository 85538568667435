import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PinActiveIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="8" height="13" viewBox="0 0 8 13" fill="none" {...props}>
      <path
        d="M1.16788 12.0371H6.83212C7.6146 12.0371 8 11.6373 8 10.7529V6.21581C8 5.41621 7.68467 5.01036 7.03066 4.94372V3.44751C7.03066 1.14564 5.55328 0.0371094 4 0.0371094C2.44672 0.0371094 0.969343 1.14564 0.969343 3.44751V4.96189C0.362044 5.05882 0 5.45861 0 6.21581V10.7529C0 11.6373 0.385401 12.0371 1.16788 12.0371ZM2.09051 3.33847C2.09051 1.92706 2.95474 1.1517 4 1.1517C5.03942 1.1517 5.90949 1.92706 5.90949 3.33847V4.93161L2.09051 4.93766V3.33847Z"
        fill="white"
      />
    </SvgIcon>
  );
}
