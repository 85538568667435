import React from 'react';

import { Form } from 'react-final-form';
import { SubmissionErrors, ValidationErrors } from 'final-form';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { FileMetaViewWithUrl } from 'api/DocumentProviders/Documents/type-helpers';
import { DocumentUpdateRequestStatusEnum } from 'api/generated';

import { ModalToolbar, SpinnerButton } from 'components/material';
import { DropFileField, TextField } from 'components/fields';
import { required, maxLength } from 'components/fields/validators';

import trim from 'utils/trim';
import isFunction from 'lodash/isFunction';

export interface DocumentFormValues {
  name: string;
  nameEn: string;
  pdfFile?: File | FileMetaViewWithUrl;
  pdfFileEn?: File | FileMetaViewWithUrl;
  originalFile?: File | FileMetaViewWithUrl;
  originalFileEn?: File | FileMetaViewWithUrl;
  status?: DocumentUpdateRequestStatusEnum;
}

interface DocumentFormProps {
  initialValues?: DocumentFormValues;
  onSubmit: (values: DocumentFormValues, ...rest: unknown[]) => void | SubmissionErrors | Promise<SubmissionErrors>;
  onArchive?: () => void;
  onUnarchive?: () => void;
  onRemove?: any;
  submitLabel: string;
  archiveLabel?: string;
  unarchiveLabel?: string;
  removeLabel?: string;
}
export function DocumentForm({
  initialValues,
  onSubmit,
  onArchive,
  onUnarchive,
  onRemove,
  submitLabel,
  archiveLabel,
  unarchiveLabel,
  removeLabel,
}: DocumentFormProps) {
  const { t } = useTranslation();
  const emptyInitialValues = {};

  const canArchive = isFunction(onArchive);
  const canUnarchive = isFunction(onUnarchive);
  const canRemove = isFunction(onRemove);

  const classes = useStyles();
  return (
    <Form<DocumentFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues || emptyInitialValues}
      keepDirtyOnReinitialize
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Box className={classnames(classes.overlay, { [classes.disabled]: submitting })}></Box>
          <Box display="flex" flexDirection="column" height="100%" paddingBottom={4}>
            <Box>
              <TextField
                label={t('documents:form.fields.name')}
                placeholder={t('documents:form.fields.namePlaceholder')}
                name="name"
                margin="dense"
                fieldProps={{
                  format: trim,
                  formatOnBlur: true,
                }}
              />
            </Box>
            <Box display="flex">
              <Box flexGrow={1} flexBasis="50%" mr={1}>
                <DropFileField
                  label={t('documents:form.fields.pdfFile')}
                  placeholder={t('documents:form.fields.pdfFilePlaceholder')}
                  name="pdfFile"
                  margin="dense"
                  accept=".pdf"
                />
              </Box>
              <Box flexGrow={1} flexBasis="50%" ml={1}>
                <DropFileField
                  label={t('documents:form.fields.originalFile')}
                  placeholder={t('documents:form.fields.originalFilePlaceholder')}
                  name="originalFile"
                  margin="dense"
                />
              </Box>
            </Box>
            <Box mt={3}>
              <TextField
                label={t('documents:form.fields.nameEn')}
                placeholder={t('documents:form.fields.nameEnPlaceholder')}
                name="nameEn"
                margin="dense"
                fieldProps={{
                  format: trim,
                  formatOnBlur: true,
                }}
              />
            </Box>
            <Box display="flex">
              <Box flexGrow={1} flexBasis="50%" mr={1}>
                <DropFileField
                  label={t('documents:form.fields.pdfFileEn')}
                  placeholder={t('documents:form.fields.pdfFileEnPlaceholder')}
                  name="pdfFileEn"
                  margin="dense"
                  accept=".pdf"
                />
              </Box>
              <Box flexGrow={1} flexBasis="50%" ml={1}>
                <DropFileField
                  label={t('documents:form.fields.originalFileEn')}
                  placeholder={t('documents:form.fields.originalFileEnPlaceholder')}
                  name="originalFileEn"
                  margin="dense"
                />
              </Box>
            </Box>
          </Box>
          <Box mx={-4}>
            <ModalToolbar>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Box>
                  <Button type="submit" variant="contained" color="primary">
                    {submitting ? <SpinnerButton size={16} label={submitLabel} color="inherit" /> : submitLabel}
                  </Button>
                </Box>

                {(canArchive || canUnarchive || canRemove) && (
                  <Box display="flex" alignItems="center" ml="auto">
                    {(canArchive || canUnarchive) && (
                      <Box mr={1.5}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={
                            initialValues?.status === DocumentUpdateRequestStatusEnum.ARCHIVED ? onUnarchive : onArchive
                          }
                        >
                          {initialValues?.status === DocumentUpdateRequestStatusEnum.ARCHIVED
                            ? unarchiveLabel
                            : archiveLabel}
                        </Button>
                      </Box>
                    )}
                    {canRemove && (
                      <Button variant="contained" color="secondary" className={classes.removeButton} onClick={onRemove}>
                        {removeLabel}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </ModalToolbar>
          </Box>
        </form>
      )}
    />
  );
}

const nameValidator = maxLength(120);

const validateForm = (values: DocumentFormValues) => {
  const errors: ValidationErrors = {};

  const noNames = !(values.name || values.nameEn);
  const noRuFiles = !(values.pdfFile || values.originalFile);
  const noEnFiles = !(values.pdfFileEn || values.originalFileEn);

  if (noNames) {
    errors.name = required(values.name);
    errors.nameEn = required(values.nameEn);
  } else {
    errors.name = nameValidator(values.name);
    errors.nameEn = nameValidator(values.nameEn);
  }

  if (values.name && noRuFiles) {
    errors.pdfFile = required(values.pdfFile);
    errors.originalFile = required(values.originalFile);
  }

  if (!values.name && !noRuFiles) {
    errors.name = required(values.name);
  }

  if (values.nameEn && noEnFiles) {
    errors.pdfFileEn = required(values.pdfFileEn);
    errors.originalFileEn = required(values.originalFileEn);
  }

  if (!values.nameEn && !noEnFiles) {
    errors.nameEn = required(values.nameEn);
  }
  return errors;
};

const useStyles = makeStyles(theme => ({
  form: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(0, 4),
    margin: theme.spacing(0, -4),
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  disabled: {
    zIndex: 50000,
    opacity: 0.5,
    background: '#fff',
  },
  removeButton: {
    color: '#EB6060',
  },
}));
