import React, { useCallback, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { getFileOfDocumentURL } from '../../../api/http';
import MuiLink from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

export function DocumentActions({ document }: any) {
  const { t } = useTranslation();
  const [isMenuOpened, setMenuOpen] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);

  const handleToggleActionMenu = useCallback(event => {
    event.preventDefault();
    setMenuOpen(prevOpen => !prevOpen);
  }, []);

  const handleCloseActionMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const classes = useStyles();

  return (
    <>
      <IconButton ref={anchorButtonRef} onClick={handleToggleActionMenu} className={classes.actionsButton}>
        <MoreVertIcon style={{ color: '#C2C6D2' }} />
      </IconButton>
      <Popper
        open={isMenuOpened}
        anchorEl={anchorButtonRef.current}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            offset: '0, +8px',
          },
        }}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseActionMenu}>
                <MenuList autoFocusItem={isMenuOpened} onKeyDown={handleListKeyDown} classes={{ root: classes.list }}>
                  {document.originalFile && (
                    <MenuItem
                      className={classes.menuItem}
                      component={MuiLink}
                      href={getFileOfDocumentURL(
                        document.businessUnitId,
                        document.sectionId,
                        document.id,
                        document.originalFile.id
                      )}
                      download={document.originalFile.name}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleCloseActionMenu}
                    >
                      {t('common:searchResults.originalFileDownload')}
                    </MenuItem>
                  )}
                  {document.pdfFile && (
                    <Box>
                      <Divider />
                      <MenuItem
                        className={classes.menuItem}
                        component={MuiLink}
                        href={getFileOfDocumentURL(
                          document.businessUnitId,
                          document.sectionId,
                          document.id,
                          document.pdfFile.id
                        )}
                        download={document.pdfFile.name}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleCloseActionMenu}
                      >
                        {t('common:searchResults.pdfFileDownload')}
                      </MenuItem>
                    </Box>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  actionsButton: {
    marginRight: theme.spacing(-2),
  },
  list: {
    padding: 0,
    borderRadius: 8,
  },
  menuItem: {
    padding: theme.spacing(1.5, 2),
    minWidth: 230,
  },
}));
