import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { DocumentArchiveProvider, DocumentDetailsProvider } from 'api/DocumentProviders/Documents';
import { getDocumentIconURL, getFolderIconURL } from 'api/http';
import { useAuthProvider } from 'api/AuthProviders';
import {
  ContentItemView,
  ContentItemViewLanguagesEnum,
  ContentItemViewStatusEnum,
  UserSessionStateViewRoleEnum,
} from 'api/generated';
import { i18nDateFnsLocaleMap } from 'i18n';

import { DocumentsActionsButton } from '../ActionButtons/DocumentActionsButton';
import { useFolderItemStyles } from './DocumentsListItemFolder';
import { DocumentStatus } from './DocumentStatus';
import { DocumentLabel } from './DocumentLabel';
import { useLanguage } from 'hooks/common';
import { useErrorCatcher } from 'api/notifications';

interface DocumentsListItemDocumentProps {
  listItem: ContentItemView;
  businessUnitId: number;
  sectionId: number;
  parentFolderId?: number;
  forwardedRef?: React.MutableRefObject<HTMLDivElement> | ((instance: HTMLDivElement) => void) | null;
}

function DocumentsListItemDocument({
  listItem,
  businessUnitId,
  sectionId,
  parentFolderId,
  forwardedRef = null,
}: DocumentsListItemDocumentProps) {
  const catchError = useErrorCatcher();
  const currentLanguage = useLanguage();
  const location = useLocation();
  const history = useHistory();
  const classes = useFolderItemStyles();
  const { role } = useAuthProvider();
  const isReader = role === UserSessionStateViewRoleEnum.READER;

  const {
    i18n: { language },
  } = useTranslation();

  function openDocumentCard() {
    history.push(`${location.pathname}/document/${listItem.id}/view`);
  }

  const formatOptions = {
    locale: i18nDateFnsLocaleMap[language],
    weekStartsOn: 1 as any,
    addSuffix: true,
  };

  return (
    <>
      <ListItem classes={{ root: classes.root }} key={listItem.id} onClick={openDocumentCard} ref={forwardedRef} button>
        <ListItemAvatar classes={{ root: classes.icon }}>
          <img
            width={32}
            alt=""
            src={
              listItem.dataType === 'DOCUMENT'
                ? getDocumentIconURL(businessUnitId, sectionId, listItem.id)
                : getFolderIconURL(businessUnitId, sectionId, listItem.id)
            }
            className={classes.iconImage}
          />
        </ListItemAvatar>
        <ListItemText classes={{ primary: classes.info }}>
          <Box display="flex" alignItems="center" component="span" width="100%">
            <Box
              component="span"
              flexGrow={1}
              color={listItem.status === ContentItemViewStatusEnum.ARCHIVED ? 'text.disabled' : 'text.primary'}
            >
              {listItem.title}
            </Box>
            {!isReader && (
              <>
                {listItem.languages?.includes(ContentItemViewLanguagesEnum.RUSSIAN) && <DocumentLabel label="Ру" />}
                {listItem.languages?.includes(ContentItemViewLanguagesEnum.ENGLISH) && <DocumentLabel label="En" />}
              </>
            )}
            {listItem.status === ContentItemViewStatusEnum.ARCHIVED && <DocumentStatus status={listItem.status} />}
            <Box ml={2} className={classes.date}>
              {format(new Date(listItem.lastActivityDate!), 'dd.MM.yy HH:mm', formatOptions)}
            </Box>
          </Box>
        </ListItemText>
        <ListItemSecondaryAction className={classes.actions}>
          <DocumentDetailsProvider
            queryOptions={{
              enabled: listItem.languages?.includes(currentLanguage),
              onError: catchError,
            }}
            businessUnitId={businessUnitId}
            sectionId={sectionId}
            documentId={listItem.id}
          >
            <DocumentArchiveProvider businessUnitId={businessUnitId} sectionId={sectionId} documentId={listItem.id}>
              <DocumentsActionsButton document={listItem} />
            </DocumentArchiveProvider>
          </DocumentDetailsProvider>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default React.forwardRef((props: DocumentsListItemDocumentProps, ref) => (
  <DocumentsListItemDocument {...props} forwardedRef={ref as React.MutableRefObject<HTMLDivElement> | undefined} />
));
