import React from 'react';

import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { SearchResultsPage } from './SearchResultsPage';
import { PersonDetailsDialog } from '../All/PersonsAndGroups/PersonsDialogs/PersonDetailsDialog';
import { UpdatePersonDialog } from 'pages/All/PersonsAndGroups/PersonsDialogs/UpdatePersonDialog';

export function SearchRouter() {
  const { path, url } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();
  return (
    <Route path={path}>
      <SearchResultsPage />
      <Route
        path={[`${path}/:businessUnitId/person/:personId/show`]}
        render={routeProps => <PersonDetailsDialog routeProps={routeProps} />}
      />
      <Route
        path={[`${path}/:businessUnitId/person/:personId/edit`]}
        render={routeProps => (
          <UpdatePersonDialog routeProps={routeProps} redirectOnDelete={() => history.push(`${url}${search}`)} />
        )}
      />
    </Route>
  );
}
