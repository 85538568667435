/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileMetaView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload a file
     * @param {any} [file]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload: async (file?: any, name?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/file/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (name !== undefined) {
        localVarFormParams.append('name', name as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Upload a file
     * @param {any} [file]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upload(
      file?: any,
      name?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileMetaView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upload(file, name, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FileControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Upload a file
     * @param {any} [file]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload(file?: any, name?: string, options?: any): AxiosPromise<FileMetaView> {
      return localVarFp.upload(file, name, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FileControllerApi - interface
 * @export
 * @interface FileControllerApi
 */
export interface FileControllerApiInterface {
  /**
   *
   * @summary Upload a file
   * @param {any} [file]
   * @param {string} [name]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApiInterface
   */
  upload(file?: any, name?: string, options?: any): AxiosPromise<FileMetaView>;
}

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI implements FileControllerApiInterface {
  /**
   *
   * @summary Upload a file
   * @param {any} [file]
   * @param {string} [name]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public upload(file?: any, name?: string, options?: any) {
    return FileControllerApiFp(this.configuration)
      .upload(file, name, options)
      .then(request => request(this.axios, this.basePath));
  }
}
