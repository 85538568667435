import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { BusinessUnitControllerApiFactory, BusinessUnitShortView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const CompaniesApi = BusinessUnitControllerApiFactory(ApiConfiguration);

type CompaniesListProviderType = QueryObserverResult<BusinessUnitShortView[], unknown>;

const CompaniesListContext = createContext<CompaniesListProviderType | null>(null);
CompaniesListContext.displayName = `${upperFirst(RESOURCE_NAME)}List`;

export function useCompaniesListProvider(): CompaniesListProviderType {
  const contextState = useContext(CompaniesListContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useCompaniesListProvider.name} must be used within a ${CompaniesListContext.displayName} context`
    );
  }
  return contextState;
}

interface CompaniesListProviderProps {
  queryOptions?: any;
}

export function CompaniesListProvider(props: React.PropsWithChildren<CompaniesListProviderProps>) {
  const value = useQuery<BusinessUnitShortView[], AxiosError<unknown>, BusinessUnitShortView[]>(
    RESOURCE_NAME,
    () => CompaniesApi.getCompanies().then(resp => resp.data),
    props.queryOptions
  );
  return <CompaniesListContext.Provider value={value}>{props.children}</CompaniesListContext.Provider>;
}
