import { AccountView } from 'api/generated';

import upperFirst from 'lodash/upperFirst';
import head from 'lodash/head';

export function formatUserName(account: AccountView): string {
  let userName = 'Unknown';
  const upperFirstLetter = account.firstName ? `${head(upperFirst(account.firstName))}.` : '';
  const upperMiddleLetter = account.middleName ? `${head(upperFirst(account.middleName))}.` : '';
  if (account.lastName) {
    userName = [upperFirst(account.lastName), upperFirstLetter, upperMiddleLetter].join(' ');
  } else if (account.firstName) {
    userName = [upperFirst(account.firstName), upperMiddleLetter].join(' ');
  } else if (account.middleName) {
    userName = upperFirst(account.middleName);
  }
  return userName;
}
