/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FileMetaView } from './file-meta-view';

/**
 * Language-dependent fields of the document
 * @export
 * @interface DocumentCardMetaView
 */
export interface DocumentCardMetaView {
  /**
   * Describes language of the document
   * @type {string}
   * @memberof DocumentCardMetaView
   */
  language: DocumentCardMetaViewLanguageEnum;
  /**
   *
   * @type {FileMetaView}
   * @memberof DocumentCardMetaView
   */
  originalFileMetaView?: FileMetaView | null;
  /**
   *
   * @type {FileMetaView}
   * @memberof DocumentCardMetaView
   */
  pdfFileMetaView?: FileMetaView | null;
  /**
   * The title of the document in the specified language
   * @type {string}
   * @memberof DocumentCardMetaView
   */
  title: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentCardMetaViewLanguageEnum {
  ENGLISH = 'ENGLISH',
  RUSSIAN = 'RUSSIAN',
}
