import React, { useCallback, useRef, useState } from 'react';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { PersonDeleteProvider } from 'api/PersonsProvider';
import { PersonSimpleView } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { DeletePersonConfirmationDialog } from '../PersonsDialogs/DeletePersonConfirmationDialog';
import { PersonRequiredRouterParams } from '../interfaces';

interface PersonsActionsButtonProps {
  person: PersonSimpleView;
}
export function PersonsActionsButton({ person }: PersonsActionsButtonProps) {
  const catchError = useErrorCatcher();

  const match = useRouteMatch<PersonRequiredRouterParams>();
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);

  const [open, setOpen] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleToggleActionMenu = useCallback(event => {
    event.preventDefault();
    setOpen(prevOpen => !prevOpen);
  }, []);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleCloseActionMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const showConfirmDialog = useCallback(event => {
    event.stopPropagation();
    setOpen(false);
    setOpenConfirm(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const classes = useStyles();
  return (
    <Box>
      <IconButton ref={anchorButtonRef} onClick={handleToggleActionMenu} className={classes.actionsButton}>
        <MoreVertIcon style={{ color: '#C2C6D2' }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorButtonRef.current}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            offset: '0, +8px',
          },
        }}
        placement="bottom-end"
        style={{ zIndex: 1300 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <PersonActionsMenu
                person={person}
                open={open}
                onClose={handleCloseActionMenu}
                onDelete={showConfirmDialog}
                onKeyDown={handleListKeyDown}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
      <PersonDeleteProvider businessUnitId={businessUnitId} personId={person.id} queryOptions={{ onError: catchError }}>
        <DeletePersonConfirmationDialog open={openConfirm} onDelete={closeConfirmDialog} onClose={closeConfirmDialog} />
      </PersonDeleteProvider>
    </Box>
  );
}

interface PersonActionsMenuProps {
  person: PersonSimpleView;
  open: boolean;
  onClose: (event: React.MouseEvent<EventTarget>) => void;
  onDelete: (event: React.MouseEvent<EventTarget>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLUListElement> | undefined;
}

function PersonActionsMenu({ person, open, onClose, onDelete, onKeyDown }: PersonActionsMenuProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MenuList autoFocusItem={open} onKeyDown={onKeyDown} classes={{ root: classes.list }}>
        <MenuItem
          className={classes.menuItem}
          component={Link}
          to={`${location.pathname}/person/${person?.id}/edit`}
          onClick={onClose}
          data-test="EditPersonButton"
        >
          {t('persons:actionsMenu.edit')}
        </MenuItem>
        <Divider />
        <MenuItem
          className={classNames(classes.menuItem, classes.menuItemWarn)}
          onClick={onDelete}
          data-test="DeletePersonButton"
        >
          {t('persons:actionsMenu.remove')}
        </MenuItem>
      </MenuList>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles(theme => ({
  actionsButton: {
    marginRight: theme.spacing(-2),
  },
  list: {
    padding: 0,
    borderRadius: 8,
  },
  menuItem: {
    padding: theme.spacing(1.5, 2),
    minWidth: 230,
  },
  menuItemWarn: {
    color: theme.palette.error.main,
  },
}));
