export const ru = {
  Monaco: 'Монако',
  Cyprus: 'Кипр',
  'United Kingdom': 'Великобритания',
  France: 'Франция',
  Russia: 'Россия',
  'United States': 'США',
  Switzerland: 'Швейцария',
  Sweden: 'Швеция',
};
