import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SectionLockedIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" color="inherit" fill="none" {...props}>
      <path
        d="M11.3748 4.375H9.40601V2.84375C9.40601 2.20557 9.15249 1.59353 8.70123 1.14227C8.24997 0.691015 7.63793 0.4375 6.99976 0.4375C6.36158 0.4375 5.74954 0.691015 5.29828 1.14227C4.84702 1.59353 4.59351 2.20557 4.59351 2.84375V4.375H2.62476C2.39277 4.37527 2.17037 4.46754 2.00633 4.63157C1.84229 4.79561 1.75002 5.01802 1.74976 5.25V11.375C1.75002 11.607 1.84229 11.8294 2.00633 11.9934C2.17037 12.1575 2.39277 12.2497 2.62476 12.25H11.3748C11.6067 12.2497 11.8291 12.1575 11.9932 11.9934C12.1572 11.8294 12.2495 11.607 12.2498 11.375V5.25C12.2495 5.01802 12.1572 4.79561 11.9932 4.63157C11.8291 4.46754 11.6067 4.37527 11.3748 4.375ZM5.46851 2.84375C5.46851 2.43764 5.62983 2.04816 5.917 1.76099C6.20416 1.47383 6.59364 1.3125 6.99976 1.3125C7.40587 1.3125 7.79535 1.47383 8.08251 1.76099C8.36968 2.04816 8.53101 2.43764 8.53101 2.84375V4.375H5.46851V2.84375Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default SectionLockedIcon;
