export function formatFileSize(bytes: number): [number, string] {
  const sizes = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'];

  if (bytes === 0) {
    return [0, sizes[0]];
  }

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = (bytes / Math.pow(1024, sizeIndex)).toFixed(1);

  return [+value, sizes[sizeIndex]];
}
