import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { InfoIcon } from 'components/icons';
import { searchSubHeaderHeight } from 'styles/constants';

export function EmptyPage({ label }: { label: string }) {
  return (
    <Box
      width="100%"
      height={`calc(100% - ${searchSubHeaderHeight})`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <InfoIcon style={{ fontSize: 130 }} />
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
    </Box>
  );
}

export default EmptyPage;
