import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function FileIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="14" height="18" viewBox="0 0 14 18" color="inherit" fill="none" {...props}>
      <path
        d="M2.58026 18H11.4197C13.1427 18 14 17.1185 14 15.3722V7.74907C14 6.66605 13.8751 6.1959 13.2093 5.50746L8.62307 0.797575C7.99049 0.142724 7.46611 0 6.53389 0H2.58026C0.865636 0 0 0.889925 0 2.63619V15.3722C0 17.1269 0.857313 18 2.58026 18ZM2.63853 16.6483C1.78121 16.6483 1.34007 16.1866 1.34007 15.347V2.66138C1.34007 1.83022 1.78121 1.35168 2.64685 1.35168H6.35077V6.22948C6.35077 7.28731 6.87515 7.80784 7.91558 7.80784H12.6599V15.347C12.6599 16.1866 12.2188 16.6483 11.3532 16.6483H2.63853ZM8.0654 6.54011C7.73246 6.54011 7.60761 6.40578 7.60761 6.06996V1.61194L12.4019 6.54011H8.0654ZM10.063 10.1166H3.74554C3.4459 10.1166 3.22117 10.3433 3.22117 10.6287C3.22117 10.9226 3.4459 11.1493 3.74554 11.1493H10.063C10.3543 11.1493 10.5707 10.9226 10.5707 10.6287C10.5707 10.3433 10.3543 10.1166 10.063 10.1166ZM10.063 13.0466H3.74554C3.4459 13.0466 3.22117 13.2817 3.22117 13.5756C3.22117 13.861 3.4459 14.0793 3.74554 14.0793H10.063C10.3543 14.0793 10.5707 13.861 10.5707 13.5756C10.5707 13.2817 10.3543 13.0466 10.063 13.0466Z"
        fill="color"
      />
    </SvgIcon>
  );
}

export default FileIcon;
