/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AccountShortView } from './account-short-view';

/**
 * Represents the minimum structure describing a company or project to display in lists
 * @export
 * @interface BusinessUnitShortView
 */
export interface BusinessUnitShortView {
  /**
   *
   * @type {number}
   * @memberof BusinessUnitShortView
   */
  id: number;
  /**
   * Accounts of responsible for this business unit
   * @type {Array<AccountShortView>}
   * @memberof BusinessUnitShortView
   */
  responsible: Array<AccountShortView>;
  /**
   * Accounts ids of responsible for this business unit
   * @type {Array<number>}
   * @memberof BusinessUnitShortView
   * @deprecated
   */
  responsibleIds: Array<number>;
  /**
   *
   * @type {string}
   * @memberof BusinessUnitShortView
   */
  status: BusinessUnitShortViewStatusEnum;
  /**
   *
   * @type {string}
   * @memberof BusinessUnitShortView
   */
  title: string;
  /**
   * Technical information
   * @type {number}
   * @memberof BusinessUnitShortView
   */
  version: number;
}

/**
 * @export
 * @enum {string}
 */
export enum BusinessUnitShortViewStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
