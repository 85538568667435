import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { PersonFolderCreateRequest, PersonFolderUpdateRequest, PersonFolderControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';
import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from '../../SectionsProviders/constants';
import isNil from 'lodash/isNil';

const PersonFolderApi = PersonFolderControllerApiFactory(ApiConfiguration);

type MemberPositionCreateProviderType = UseMutationResult<
  PersonFolderUpdateRequest,
  AxiosError<unknown>,
  PersonFolderCreateRequest,
  unknown
>;

const MemberPositionCreateContext = createContext<MemberPositionCreateProviderType | null>(null);
MemberPositionCreateContext.displayName = 'MemberPositionCreate';

export function useMemberPositionCreateProvider(): MemberPositionCreateProviderType {
  const contextState = useContext(MemberPositionCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMemberPositionCreateProvider.name} must be used within a ${MemberPositionCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface MemberPositionCreateProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  queryOptions?: UseMutationOptions<PersonFolderUpdateRequest, AxiosError<unknown>, PersonFolderCreateRequest, unknown>;
}

export function MemberPositionCreateProvider(props: React.PropsWithChildren<MemberPositionCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: MemberPositionCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries([SECTION_RESOURCE_NAME, props.businessUnitId, props.sectionId]);
    },
  };
  const create = useMutation<PersonFolderUpdateRequest, AxiosError<unknown>, PersonFolderCreateRequest>(
    async (data: PersonFolderCreateRequest) => {
      return PersonFolderApi.createPersonFolders(props.businessUnitId, props.sectionId, props.groupFolderId, data).then(
        resp => resp.data
      );
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <MemberPositionCreateContext.Provider value={create}>{props.children}</MemberPositionCreateContext.Provider>;
}
