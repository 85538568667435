import React from 'react';

import { useTranslation } from 'react-i18next';

import { Form, FormProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import Box from '@material-ui/core/Box';

import { TextField } from 'components/fields';
import { required } from 'components/fields/validators';
import { Spinner } from 'components/material';

import { formatPhone } from 'utils/phone';

export function EnterSMSCodeForm({
  phone,
  smsCodeLength,
  onSubmit,
  loading,
}: {
  phone: string;
  smsCodeLength: number;
  onSubmit: FormProps['onSubmit'];
  loading: boolean;
}) {
  const { t } = useTranslation();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      render={formProps => (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Box mt={3} fontSize="h4.fontSize">
            {t('login:smsCodeVerification')}
          </Box>
          <Box mt={3} fontSize="body1.fontSize" fontWeight="bold" textAlign="center" maxWidth={326}>
            <span>{t('login:enterSMSCode')} </span>
            <span>{formatPhone(phone)}</span>
          </Box>
          <Box width="100%">
            <TextField
              name="otp"
              margin="dense"
              inputProps={{
                maxLength: smsCodeLength,
                autoComplete: 'off',
                style: { textAlign: 'center' },
              }}
              fieldProps={{ validate: required }}
              autoFocus
              fullWidth
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  formProps.handleSubmit();
                  event.preventDefault();
                }
              }}
            />
            <OnChange name="otp">
              {(otp: string) => {
                if (otp && otp.length === smsCodeLength) {
                  formProps.form.submit();
                }
              }}
            </OnChange>
          </Box>
        </Box>
      )}
    />
  );
}
