/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FileMetaView } from './file-meta-view';

/**
 * Basic business unit section information
 * @export
 * @interface BusinessUnitSectionShortView
 */
export interface BusinessUnitSectionShortView {
  /**
   *
   * @type {number}
   * @memberof BusinessUnitSectionShortView
   */
  businessUnitId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessUnitSectionShortView
   */
  permissions: Array<BusinessUnitSectionShortViewPermissionsEnum>;
  /**
   * Access to this section is restricted for readers or not
   * @type {boolean}
   * @memberof BusinessUnitSectionShortView
   */
  restrictedAccess?: boolean;
  /**
   * Access to this section is restricted for responsible or not
   * @type {boolean}
   * @memberof BusinessUnitSectionShortView
   */
  restrictedResponsibleAccess?: boolean;
  /**
   *
   * @type {string}
   * @memberof BusinessUnitSectionShortView
   */
  sectionDataType: BusinessUnitSectionShortViewSectionDataTypeEnum;
  /**
   *
   * @type {number}
   * @memberof BusinessUnitSectionShortView
   */
  sectionId: number;
  /**
   *
   * @type {FileMetaView}
   * @memberof BusinessUnitSectionShortView
   */
  sectionPreviewFileMetaView?: FileMetaView | null;
  /**
   *
   * @type {string}
   * @memberof BusinessUnitSectionShortView
   */
  sectionTitle: string;
  /**
   * Technical field
   * @type {number}
   * @memberof BusinessUnitSectionShortView
   */
  sectionVersion: number;
}

/**
 * @export
 * @enum {string}
 */
export enum BusinessUnitSectionShortViewPermissionsEnum {
  READ_BUSINESS_UNIT_SECTION = 'READ_BUSINESS_UNIT_SECTION',
  UPDATE_BUSINESS_UNIT_SECTION = 'UPDATE_BUSINESS_UNIT_SECTION',
  UPDATE_BUSINESS_UNIT_SECTION_CONTENT = 'UPDATE_BUSINESS_UNIT_SECTION_CONTENT',
  UPDATE_BUSINESS_UNIT_SECTION_PREVIEW = 'UPDATE_BUSINESS_UNIT_SECTION_PREVIEW',
  DELETE_BUSINESS_UNIT_SECTION = 'DELETE_BUSINESS_UNIT_SECTION',
  UPDATE_BUSINESS_UNIT_SECTION_ACCESS = 'UPDATE_BUSINESS_UNIT_SECTION_ACCESS',
  UPDATE_BUSINESS_UNIT_SECTION_RESPONSIBLE_ACCESS = 'UPDATE_BUSINESS_UNIT_SECTION_RESPONSIBLE_ACCESS',
}
/**
 * @export
 * @enum {string}
 */
export enum BusinessUnitSectionShortViewSectionDataTypeEnum {
  DOCUMENT = 'DOCUMENT',
  PERSON = 'PERSON',
}
