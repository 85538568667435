import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Field, useField, useForm } from 'react-final-form';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { CompaniesSelect } from './CompaniesSelect';
import { ProjectsSelect } from './ProjectsSelect';

export function ReaderFields() {
  const { t } = useTranslation();
  const form = useForm();
  const { input: allowByDefaultCompanies } = useField('allowByDefaultCompanies');
  const { input: allowByDefaultProjects } = useField('allowByDefaultProjects');

  const onChangeAllCompaniesCheckbox = useCallback(
    input => (event: React.ChangeEvent<HTMLInputElement>) => {
      input.onChange(event.target.checked);
      if (event.target.checked) {
        allowByDefaultCompanies.onChange(true);
      }
    },
    [allowByDefaultCompanies]
  );

  const onChangeAllProjectsCheckbox = useCallback(
    input => (event: React.ChangeEvent<HTMLInputElement>) => {
      input.onChange(event.target.checked);
      if (event.target.checked) {
        allowByDefaultProjects.onChange(true);
      }
    },
    [allowByDefaultProjects]
  );

  return (
    <>
      <Typography variant="h6">{t('accounts:roles.ROLE_READER')}</Typography>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Box pr={1.5}>
            <Field
              type="checkbox"
              name="allCompaniesForReader"
              render={({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox color="primary" onChange={onChangeAllCompaniesCheckbox(input)} value={input.value} />
                  }
                  name={input.name}
                  label={t('accounts:create.reader.allCompanies')}
                  checked={input.checked}
                />
              )}
            />
            <CompaniesSelect
              fieldName="readerCompaniesIds"
              disabled={form.getState().values.allCompaniesForReader}
              label={t('accounts:create.reader.companiesSection')}
              placeholder={t('accounts:create.fields.companiesPlaceholder')}
              secondaryListItemText={t('accounts:create.reader.reader')}
            />
            <Field
              type="checkbox"
              name="allowByDefaultCompanies"
              render={({ input }) => (
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={input.onChange} value={input.value} />}
                  name={input.name}
                  label={t('accounts:create.reader.allowByDefaultCompanies')}
                  disabled={form.getState().values.allCompaniesForReader}
                  checked={input.checked}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pl={1.5}>
            <Field
              type="checkbox"
              name="allProjectsForReader"
              render={({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox color="primary" onChange={onChangeAllProjectsCheckbox(input)} value={input.value} />
                  }
                  name={input.name}
                  label={t('accounts:create.reader.allProjects')}
                  checked={input.checked}
                />
              )}
            />
            <ProjectsSelect
              fieldName="readerProjectsIds"
              disabled={form.getState().values.allProjectsForReader}
              label={t('accounts:create.reader.projectsSection')}
              placeholder={t('accounts:create.fields.projectsPlaceholder')}
              secondaryListItemText={t('accounts:create.reader.reader')}
            />
            <Field
              type="checkbox"
              name="allowByDefaultProjects"
              render={({ input }) => (
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={input.onChange} value={input.value} />}
                  name={input.name}
                  label={t('accounts:create.reader.allowByDefaultProjects')}
                  disabled={form.getState().values.allProjectsForReader}
                  checked={input.checked}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
