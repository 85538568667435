import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { GroupFolderControllerApiFactory, GroupFolderView } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const GroupFolderApi = GroupFolderControllerApiFactory(ApiConfiguration);

type GroupDetailsProviderType = QueryObserverResult<GroupFolderView, unknown>;

const GroupDetailsContext = createContext<GroupDetailsProviderType | null>(null);
GroupDetailsContext.displayName = `${upperFirst(RESOURCE_NAME)}Details`;

export function useGroupDetailsProvider(): GroupDetailsProviderType {
  const contextState = useContext(GroupDetailsContext);
  if (isNil(contextState)) {
    throw new Error(`${useGroupDetailsProvider.name} must be used within a ${GroupDetailsContext.displayName} context`);
  }
  return contextState;
}

interface GroupDetailsProviderProps {
  businessUnitId: number;
  sectionId: number;
  groupFolderId: number;
  queryOptions?: UseQueryOptions<GroupFolderView>;
}
export function GroupDetailsProvider(props: React.PropsWithChildren<GroupDetailsProviderProps>) {
  const value = useQuery<GroupFolderView>([RESOURCE_NAME, { id: props.groupFolderId }], () =>
    GroupFolderApi.getGroupFolderById(props.businessUnitId, props.sectionId, props.groupFolderId).then(
      resp => resp.data
    )
  );
  return <GroupDetailsContext.Provider value={value}>{props.children}</GroupDetailsContext.Provider>;
}
