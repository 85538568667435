import React, { createContext, useContext } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';

import { ApiConfiguration } from 'api/http';
import { SecretaryUpdateRequest, UserControllerApiFactory } from 'api/generated';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const UserApi = UserControllerApiFactory(ApiConfiguration);

type SecretaryDetailsWithUpdateProviderType = QueryObserverResult<SecretaryUpdateRequest, unknown>;

const SecretaryDetailsWithUpdateContext = createContext<SecretaryDetailsWithUpdateProviderType | null>(null);
SecretaryDetailsWithUpdateContext.displayName = `${upperFirst(RESOURCE_NAME)}DetailsWithUpdate`;

export function useSecretaryDetailsWithUpdateProvider(): SecretaryDetailsWithUpdateProviderType {
  const contextState = useContext(SecretaryDetailsWithUpdateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useSecretaryDetailsWithUpdateProvider.name} must be used within a ${SecretaryDetailsWithUpdateContext.displayName} context`
    );
  }
  return contextState;
}

interface SecretaryDetailsWithUpdateProviderProps {
  secretaryId: number;
}
export function SecretaryDetailsWithUpdateProvider(
  props: React.PropsWithChildren<SecretaryDetailsWithUpdateProviderProps>
) {
  const value = useQuery<SecretaryUpdateRequest>([RESOURCE_NAME, { id: props.secretaryId }], () =>
    UserApi.getSecretaryUpdateRequestById(props.secretaryId).then(resp => resp.data)
  );
  return (
    <SecretaryDetailsWithUpdateContext.Provider value={value}>
      {props.children}
    </SecretaryDetailsWithUpdateContext.Provider>
  );
}
