import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Div100vh from 'react-div-100vh';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import mobilePhones from './assets/ru/mobilePhones.png';
import mobilePhones_2x from './assets/ru/mobilePhones@2x.png';
import mobilePhones_en from './assets/en/mobilePhonesEn.png';
import mobilePhones_2x_en from './assets/en/mobilePhonesEn@2x.png';

import get from 'lodash/get';

export function MobileAppsErrorPage() {
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const errorMsg = get(history, 'location.state.errorMsg', t('mobileApps:openInMobileBrowser'));

  const classes = useStyles();
  return (
    <Div100vh>
      <Box className={classes.root}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          flexWrap="wrap"
          flexGrow={1}
          mt={4}
          px={2}
        >
          <Box display="flex" flexDirection="column">
            <Box fontSize="h1.fontSize" mb={6}>
              {t('mobileApps:ooops')}
            </Box>
            <Box fontSize="h4.fontSize" maxWidth={530}>
              {errorMsg}
            </Box>
          </Box>
          <Box my={2}>
            <img
              src={language === 'ru' ? mobilePhones : mobilePhones_en}
              srcSet={
                language === 'ru'
                  ? `${mobilePhones}, ${mobilePhones_2x} 2x`
                  : `${mobilePhones_en}, ${mobilePhones_2x_en} 2x`
              }
              alt="screenshot"
              style={{ objectFit: 'contain', maxWidth: '100%' }}
            />
          </Box>
        </Box>
        <Box fontSize="caption.fontSize" color="grey.400" textAlign="center" maxWidth={530} mb={6}>
          {t('copyright:long')}
        </Box>
      </Box>
    </Div100vh>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F8F8F8',
    minHeight: '100%',
  },
}));
