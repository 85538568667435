import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import {
  MobileAppVersionControllerApiFactory,
  MobileAppVersionCreateRequest,
  MobileAppVersionUpdateRequest,
} from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

const MobileAppVersionApi = MobileAppVersionControllerApiFactory(ApiConfiguration);

export type MobileAppWithFilesCreateRequest = {
  appFile: File;
  logoFile: File;
  bigLogoFile: File;
} & MobileAppVersionCreateRequest;

type MobileAppCreateProviderType = UseMutationResult<
  MobileAppVersionUpdateRequest,
  AxiosError<unknown>,
  MobileAppWithFilesCreateRequest,
  unknown
>;

const MobileAppCreateContext = createContext<MobileAppCreateProviderType | null>(null);
MobileAppCreateContext.displayName = `${upperFirst(RESOURCE_NAME)}Create`;

export function useMobileAppCreateProvider(): MobileAppCreateProviderType {
  const contextState = useContext(MobileAppCreateContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useMobileAppCreateProvider.name} must be used within a ${MobileAppCreateContext.displayName} context`
    );
  }
  return contextState;
}

interface MobileAppCreateProviderProps {
  queryOptions?: UseMutationOptions<
    MobileAppVersionUpdateRequest,
    AxiosError<unknown>,
    MobileAppWithFilesCreateRequest,
    unknown
  >;
}
export function MobileAppCreateProvider(props: React.PropsWithChildren<MobileAppCreateProviderProps>) {
  const queryClient = useQueryClient();
  const createDefaultOptions: MobileAppCreateProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const create = useMutation<MobileAppVersionUpdateRequest, AxiosError<unknown>, MobileAppWithFilesCreateRequest>(
    async (data: MobileAppWithFilesCreateRequest) => {
      if (data.appFile) {
        const appFile = data.appFile;
        const file = await MobileAppVersionApi.uploadMobileAppVersionFiles(appFile, appFile.name).then(
          resp => resp.data
        );
        data.appFileId = file.id;
      }
      if (data.logoFile) {
        const logoFile = data.logoFile;
        const file = await MobileAppVersionApi.uploadMobileAppVersionFiles(logoFile, logoFile.name).then(
          resp => resp.data
        );
        data.logoId = file.id;
      } else {
        data.logoId = null;
      }
      if (data.bigLogoFile) {
        const bigLogoFile = data.bigLogoFile;
        const file = await MobileAppVersionApi.uploadMobileAppVersionFiles(bigLogoFile, bigLogoFile.name).then(
          resp => resp.data
        );
        data.bigLogoId = file.id;
      } else {
        data.bigLogoId = null;
      }
      const params = omit(data, ['appFile', 'logoFile', 'bigLogoFile']);
      return MobileAppVersionApi.create2(params).then(resp => resp.data);
    },
    {
      ...createDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <MobileAppCreateContext.Provider value={create}>{props.children}</MobileAppCreateContext.Provider>;
}
