import React, { useEffect } from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextFieldProps } from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';

import { getPhoneCode } from 'utils/phone';
import { CustomInput, CustomLabel } from './CustomInput';
import { PhoneCustomInput } from './PhoneCustomInput';

export type PhoneFieldProps = Partial<Omit<TextFieldProps, 'type' | 'onChange'>> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  showError?: ShowErrorFunc;
  resettable?: boolean;
};

export function PhoneField(props: PhoneFieldProps) {
  const { name, fieldProps, resettable = true, ...rest } = props;

  return (
    <Field
      name={name}
      type="text"
      render={({ input, meta }) => (
        <PhoneFieldWrapper input={input} meta={meta} name={name} type="text" resettable={resettable} {...rest} />
      )}
      {...fieldProps}
    />
  );
}

type PhoneWrapperProps = FieldRenderProps<TextFieldProps, HTMLElement>;

export function PhoneFieldWrapper(props: PhoneWrapperProps) {
  const {
    input: { name, value, type, onChange, onBlur, onFocus, ...restInput },
    meta,
    required,
    fullWidth = true,
    helperText,
    showError = showErrorOnChange,
    label,
    margin,
    variant,
    resettable,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  // if initial value is just country region e.g('7')
  // (previous implementation allowed it so database can have it.)
  // then we replace value with empty string - so that validation worked properly.
  useEffect(() => {
    if (value) {
      const countryCode = getPhoneCode(value as string);
      if (value === countryCode) {
        onChange('');
      }
    }
  }, [onChange, value]);
  return (
    <FormControl error={isError} margin={margin} variant={variant} fullWidth={fullWidth} required={required}>
      <CustomLabel shrink variant="standard">
        {label}
      </CustomLabel>
      <CustomInput
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        value={value}
        type={type}
        inputProps={{ required, ...restInput }}
        inputComponent={PhoneCustomInput as any}
        endAdornment={
          resettable && value ? (
            <InputAdornment position="end">
              <IconButton aria-label="clear" onClick={() => onChange('')} size="small">
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        {...rest}
      />
      <FormHelperText>{isError ? error || submitError : helperText}</FormHelperText>
    </FormControl>
  );
}
