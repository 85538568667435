import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useErrorCatcher } from 'api/notifications';
import { PersonMutatorsProvider, StructType } from 'api/PersonsProvider';
import { GroupsListProvider } from 'api/GroupsProvider';
import { PersonFoldersProvider } from 'api/PersonsProvider/PersonFoldersProvider';

import { Modal } from 'components/material';
import { goBackOrReplace, makeParentUrl } from 'utils';

import { CreatePersonParams } from '../interfaces';
import { PersonFolderFormValuesType, PersonForm, PersonFormValuesType } from './PersonForm/PersonForm';

import { ConfirmDialog } from 'pages/All/ConfirmDialogs';
import { ApiConfiguration } from 'api/http';
import { SearchControllerApiFactory, SearchPersonIsExistsRequest } from 'api/generated';
import { usePersonMutation } from './PersonDialogsUtils';
import { BusinessUnitType } from 'api/BusinessUnitProviders/constants';

const SearchApi = SearchControllerApiFactory(ApiConfiguration);

export function CreatePersonDialog({
  routeProps,
  structType,
}: {
  routeProps: RouteComponentProps<CreatePersonParams>;
  structType: StructType;
}) {
  const { t } = useTranslation();
  const catchError = useErrorCatcher();
  const match = routeProps.match;
  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);

  const closeHandler = () =>
    goBackOrReplace({ pathname: makeParentUrl(match.url), search: routeProps.location.search });

  return (
    <Modal
      title={t('persons:dialog.createTitle')}
      open={!!match}
      onCloseModal={closeHandler}
      maxWidth="md"
      fullWidth={true}
      data-test={CreatePersonDialog.name}
    >
      <PersonMutatorsProvider
        businessUnitId={businessUnitId}
        structType={structType}
        queryOptions={{ onError: catchError }}
      >
        <GroupsListProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
          <PersonFoldersProvider businessUnitId={businessUnitId} queryOptions={{ onError: catchError }}>
            <PersonCreate businessUnitId={businessUnitId} structType={structType} />
          </PersonFoldersProvider>
        </GroupsListProvider>
      </PersonMutatorsProvider>
    </Modal>
  );
}

function PersonCreate({ businessUnitId, structType }: { businessUnitId: number; structType: BusinessUnitType }) {
  const match = useRouteMatch<CreatePersonParams>();
  const history = useHistory();
  const { t } = useTranslation();
  const [openConfirmCreateDialog, setOpenConfirmCreateDialog] = React.useState(false);

  const { submitCreate, isLoading, isPersonRequestSuccess } = usePersonMutation();
  const makeCreatePersonRequest = useCallback(
    (formValues: PersonFormValuesType) => {
      submitCreate(formValues).then(() => {
        history.push(makeParentUrl(match.url + history.location.search));
      });
    },
    [history, match.url, submitCreate]
  );

  const formValuesRef = React.useRef<PersonFormValuesType>();

  const handleConfirm = async () => {
    if (formValuesRef.current) {
      await makeCreatePersonRequest(formValuesRef.current);
    }
    setOpenConfirmCreateDialog(false);
  };

  const onSubmit = useCallback(
    async (formValues: PersonFormValuesType) => {
      try {
        formValuesRef.current = formValues;
        const request: SearchPersonIsExistsRequest = {
          businessUnitId,
          firstName: formValues.firstNameRu,
          lastName: formValues.lastNameRu,
          middleName: formValues.middleNameRu,
        };
        if (formValues.companyId) {
          request.companyId = formValues.companyId;
        }

        // don't show popup if person was created in the same dialog
        if (isPersonRequestSuccess) {
          return await makeCreatePersonRequest(formValues);
        }

        const isPersonExist = await SearchApi.searchPersonIsExists(request);
        if (isPersonExist?.data?.result) {
          setOpenConfirmCreateDialog(true);
        } else {
          await makeCreatePersonRequest(formValues);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [businessUnitId, isPersonRequestSuccess, makeCreatePersonRequest]
  );

  const onCancel = useCallback(() => {
    history.push(makeParentUrl(match.url));
  }, [history, match.url]);

  const foldersInitialValues: PersonFolderFormValuesType[] = [];

  // get groupFolderId from url to preset value
  let groupFolderId = parseInt(match.params.groupFolderId || '', 10);
  if (isNaN(groupFolderId)) {
    const query = new URLSearchParams(history.location.search);
    groupFolderId = parseInt(query.get('groupFolderId') || '', 10);
  }

  // Hide delete button and lock group select according to https://jira.jsa-group.ru/browse/USMUN-931
  if (groupFolderId) {
    foldersInitialValues.push({
      uiOptions: { hideDelete: true, disableGroupSelect: true },
      groupFolderId,
    });
  }

  return (
    <>
      <PersonForm
        businessUnitId={businessUnitId}
        structType={structType}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitLabel={t('persons:dialog.create')}
        cancelLabel={t('persons:dialog.cancel')}
        foldersInitialValues={foldersInitialValues}
      />
      <ConfirmDialog
        open={openConfirmCreateDialog}
        title={
          structType === 'PROJECT'
            ? t('persons:create.project.memberAlreadyExist')
            : t('persons:create.company.memberAlreadyExist')
        }
        confirmText={
          structType === 'PROJECT'
            ? t('persons:create.project.memberAlreadyExistConfirmation')
            : t('persons:create.company.memberAlreadyExistConfirmation')
        }
        submitLabel={t('persons:dialog.create')}
        cancelLabel={t('persons:dialog.cancel')}
        onClose={() => setOpenConfirmCreateDialog(false)}
        handleSubmit={handleConfirm}
        primaryButtonDisabled={isLoading}
      />
    </>
  );
}
