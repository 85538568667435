import { makeStyles } from '@material-ui/core/styles';

export const useFormStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  smsCodeField: {
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  numberItemBox: {
    position: 'relative',
  },
  numberItem: {
    borderStyle: 'solid',
    borderColor: theme.palette.common.black,
    borderWidth: 1,
    borderRadius: 8,
    height: 48,
    width: 40,
    padding: theme.spacing(1.25, 1.75),
    marginRight: theme.spacing(1.5),
    position: 'relative',
    '& .MuiInputBase-input': {
      fontSize: '1rem',
    },
  },
  phoneButton: {
    display: '-webkit-flex',
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: 'flex',
    '-webkit-align-items': 'center',
    alignItems: 'center',
  },
}));
