import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/config';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { FileMetaViewWithUrl } from 'api/DocumentProviders/Documents/type-helpers';
import { DocumentCardViewStatusEnum, DocumentControllerApiFactory, UserSessionStateViewRoleEnum } from 'api/generated';
import { useErrorCatcher } from 'api/notifications';

import { ModalToolbar } from 'components/material';

import { DownloadIcon, FileIcon } from '../../../components/icons';
import { formatFileSize, makeParentUrl } from '../../../utils';
import { DocumentRequiredRouterParams } from './interfaces';
import { ApiConfiguration, getFileOfDocumentURL } from '../../../api/http';
import { PREVIEW_FILE_URL_SEARCH_PARAM_NAME } from '.';
import { PDF_VIEWER_PAGE_PATH } from '../../Router';
import { useAuthProvider } from '../../../api/AuthProviders';

export interface DocumentCardData {
  name?: string;
  nameEn?: string;
  pdfFile?: FileMetaViewWithUrl;
  pdfFileEn?: FileMetaViewWithUrl;
  originalFile?: FileMetaViewWithUrl;
  originalFileEn?: FileMetaViewWithUrl;
  status?: DocumentCardViewStatusEnum;
}

interface DocumentFormProps {
  data?: DocumentCardData;
  onArchive?: () => void;
  onUnarchive?: () => void;
  onRemove?: any;
  editLabel: string;
  archiveLabel?: string;
  unarchiveLabel?: string;
  removeLabel?: string;
}
export function DocumentCard({
  data,
  onArchive,
  onUnarchive,
  onRemove,
  editLabel,
  archiveLabel,
  unarchiveLabel,
  removeLabel,
}: DocumentFormProps) {
  const { t } = useTranslation();

  const history = useHistory();
  const match = useRouteMatch<DocumentRequiredRouterParams>();

  const businessUnitId = parseInt(match.params.businessUnitId || '', 10);
  const sectionId = parseInt(match.params.sectionId || '', 10);
  const documentId = parseInt(match.params.documentId || '', 10);

  const { role } = useAuthProvider();
  const isAdmin = role === UserSessionStateViewRoleEnum.ADMIN || role === UserSessionStateViewRoleEnum.ROOT;
  const isResponsible = role === UserSessionStateViewRoleEnum.RESPONSIBLE;

  function formatFile(value: number) {
    const [size, unit] = formatFileSize(value || 0);
    const formatter = new Intl.NumberFormat(i18n.language, {
      style: 'unit',
      unit,
      unitDisplay: 'short',
    } as any);

    return formatter.format(size || 0);
  }

  function goToEdit() {
    history.push(`${makeParentUrl(match.url)}/edit`);
  }

  const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

  const catchError = useErrorCatcher();

  const openDocument = useCallback(
    async event => {
      await DocumentApi.getDocumentById(businessUnitId, sectionId, documentId).then(
        ({ data: document }) => {
          if (document.pdfFileMeta) {
            const query = new URLSearchParams(history.location.search);
            query.set(
              PREVIEW_FILE_URL_SEARCH_PARAM_NAME,
              getFileOfDocumentURL(businessUnitId, sectionId, document.id, document.pdfFileMeta.id)
            );
            history.push(`${match.url}${PDF_VIEWER_PAGE_PATH}?${query.toString()}`, {
              title: `${document.title} ( ${document.pdfFileMeta.fileName} ) `,
            });
          } else if (document.originalFileMeta) {
            window.open(
              getFileOfDocumentURL(businessUnitId, sectionId, document.id, document.originalFileMeta.id),
              '_blank',
              'noopener,noreferrer'
            );
          }
          return document;
        },
        e => catchError(e)
      );
    },
    [DocumentApi, businessUnitId, sectionId, documentId, history, match.url, catchError]
  );

  const classes = useStyles();
  return (
    <div className={classes.form}>
      {data?.name && (
        <Box display="flex" flexDirection="column" height="100%" paddingBottom={4}>
          <Typography variant="h6">{t('documents:form.fields.name')}</Typography>
          <Typography variant="body1" className={classes.fieldValue}>
            {data?.name}
          </Typography>

          {data?.pdfFile && (
            <Box mt={3}>
              <Typography variant="caption">{t('documents:form.fields.pdfFile')}</Typography>
              <Box display="flex" mt={1}>
                <Box display="flex" onClick={openDocument} className={classes.preview}>
                  <Box marginRight={1.5}>
                    <FileIcon fontSize="small" />
                  </Box>
                  <Box display="flex" flexDirection="column" flexGrow={1} marginRight={1.5}>
                    <Typography variant="body1">{(data?.pdfFile as FileMetaViewWithUrl)?.fileName}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatFile((data?.pdfFile as FileMetaViewWithUrl)?.size)}
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft="auto" color="grey.600">
                  <Link href={data?.pdfFile?.downloadUrl} target="_blank" rel="noopener noreferrer" download>
                    <IconButton aria-label="clear" size="small">
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
          )}

          {data?.originalFile && (
            <Box mt={3}>
              <Typography variant="caption">{t('documents:form.fields.originalFile')}</Typography>
              <Box display="flex" mt={1}>
                <Box display="flex" onClick={openDocument} className={classes.preview}>
                  <Box marginRight={1.5}>
                    <FileIcon fontSize="small" />
                  </Box>
                  <Box display="flex" flexDirection="column" flexGrow={1} marginRight={1.5}>
                    <Typography variant="body1">{(data?.originalFile as FileMetaViewWithUrl)?.fileName}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatFile((data?.pdfFile as FileMetaViewWithUrl)?.size)}
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft="auto" color="grey.600">
                  <Link href={data?.originalFile?.downloadUrl} target="_blank" rel="noopener noreferrer" download>
                    <IconButton aria-label="clear" size="small">
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {data?.nameEn && (
        <Box display="flex" flexDirection="column" height="100%" paddingBottom={4}>
          <Typography variant="h6">{t('documents:form.fields.nameEn')}</Typography>
          <Typography variant="body1" className={classes.fieldValue}>
            {data?.nameEn}
          </Typography>

          {data?.pdfFileEn && (
            <Box mt={3}>
              <Typography variant="caption">{t('documents:form.fields.pdfFileEn')}</Typography>
              <Box display="flex" mt={1}>
                <Box display="flex" onClick={openDocument} className={classes.preview}>
                  <Box marginRight={1.5}>
                    <FileIcon fontSize="small" />
                  </Box>
                  <Box display="flex" flexDirection="column" flexGrow={1} marginRight={1.5}>
                    <Typography variant="body1">{(data?.pdfFileEn as FileMetaViewWithUrl)?.fileName}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatFile((data?.pdfFileEn as FileMetaViewWithUrl)?.size)}
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft="auto" color="grey.600">
                  <Link href={data?.pdfFileEn?.downloadUrl} target="_blank" rel="noopener noreferrer" download>
                    <IconButton aria-label="clear" size="small">
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
          )}

          {data?.originalFileEn && (
            <Box mt={3}>
              <Typography variant="caption">{t('documents:form.fields.originalFileEn')}</Typography>
              <Box display="flex" mt={1}>
                <Box display="flex" onClick={openDocument} className={classes.preview}>
                  <Box display="flex">
                    <Box marginRight={1.5}>
                      <FileIcon fontSize="small" />
                    </Box>
                    <Box display="flex" flexDirection="column" flexGrow={1} marginRight={1.5}>
                      <Typography variant="body1">{(data?.originalFileEn as FileMetaViewWithUrl)?.fileName}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {formatFile((data?.originalFileEn as FileMetaViewWithUrl)?.size)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box marginLeft="auto" color="grey.600">
                  <Link href={data?.originalFileEn?.downloadUrl} target="_blank" rel="noopener noreferrer" download>
                    <IconButton aria-label="clear" size="small">
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}

      <Box mx={-4}>
        {(isAdmin || isResponsible) && (
          <ModalToolbar>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Box>
                <Button type="button" variant="contained" color="primary" onClick={goToEdit}>
                  {editLabel}
                </Button>
              </Box>
              <Box display="flex" alignItems="center" ml="auto">
                <Box mr={1.5}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={data?.status === DocumentCardViewStatusEnum.ARCHIVED ? onUnarchive : onArchive}
                  >
                    {data?.status === DocumentCardViewStatusEnum.ARCHIVED ? unarchiveLabel : archiveLabel}
                  </Button>
                </Box>
                <Button variant="contained" color="secondary" className={classes.removeButton} onClick={onRemove}>
                  {removeLabel}
                </Button>
              </Box>
            </Box>
          </ModalToolbar>
        )}
      </Box>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(0, 4),
    margin: theme.spacing(0, -4),
  },
  disabled: {
    zIndex: 50000,
    opacity: 0.5,
    background: '#fff',
  },
  removeButton: {
    color: '#EB6060',
  },
  fieldValue: {
    marginTop: '8px',
  },
  preview: {
    cursor: 'pointer',
    wordBreak: 'break-word',
  },
}));
