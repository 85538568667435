/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DocumentMetaUpdateRequest } from './document-meta-update-request';

/**
 * Document update request
 * @export
 * @interface DocumentUpdateRequest
 */
export interface DocumentUpdateRequest {
  /**
   * description of file fields for each languages, can contain min 1 and max 2 DocumentMetaRequest
   * @type {Array<DocumentMetaUpdateRequest>}
   * @memberof DocumentUpdateRequest
   */
  documentMetas: Array<DocumentMetaUpdateRequest>;
  /**
   * Document id
   * @type {number}
   * @memberof DocumentUpdateRequest
   */
  id: number;
  /**
   * Document status
   * @type {string}
   * @memberof DocumentUpdateRequest
   */
  status?: DocumentUpdateRequestStatusEnum;
  /**
   * version of the document
   * @type {number}
   * @memberof DocumentUpdateRequest
   */
  version: number;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentUpdateRequestStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
