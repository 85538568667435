/* tslint:disable */
/* eslint-disable */
/**
 * BlackBook API definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FolderCreateRequest } from '../views';
// @ts-ignore
import { FolderUpdateRequest } from '../views';
// @ts-ignore
import { FolderView } from '../views';
// @ts-ignore
import { VendorErrorResponse } from '../views';
/**
 * FolderControllerApi - axios parameter creator
 * @export
 */
export const FolderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFolder: async (
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createFolder', 'sectionId', sectionId);
      // verify required parameter 'folderCreateRequest' is not null or undefined
      assertParamExists('createFolder', 'folderCreateRequest', folderCreateRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/folder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(folderCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFolder1: async (
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createFolder1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createFolder1', 'sectionId', sectionId);
      // verify required parameter 'folderCreateRequest' is not null or undefined
      assertParamExists('createFolder1', 'folderCreateRequest', folderCreateRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/folder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(folderCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFolder2: async (
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('createFolder2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('createFolder2', 'sectionId', sectionId);
      // verify required parameter 'folderCreateRequest' is not null or undefined
      assertParamExists('createFolder2', 'folderCreateRequest', folderCreateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/folder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(folderCreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteFolder', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('deleteFolder', 'folderId', folderId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder1: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteFolder1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteFolder1', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('deleteFolder1', 'folderId', folderId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder2: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('deleteFolder2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('deleteFolder2', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('deleteFolder2', 'folderId', folderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download a folder icon by the folder id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconOfFolder: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('downloadIconOfFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('downloadIconOfFolder', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('downloadIconOfFolder', 'folderId', folderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}/icon`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderById: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getFolderById', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getFolderById', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('getFolderById', 'folderId', folderId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderById1: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getFolderById1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getFolderById1', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('getFolderById1', 'folderId', folderId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderById2: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getFolderById2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getFolderById2', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('getFolderById2', 'folderId', folderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderForEdit: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getFolderForEdit', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getFolderForEdit', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('getFolderForEdit', 'folderId', folderId);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderForEdit1: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getFolderForEdit1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getFolderForEdit1', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('getFolderForEdit1', 'folderId', folderId);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderForEdit2: async (
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('getFolderForEdit2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('getFolderForEdit2', 'sectionId', sectionId);
      // verify required parameter 'folderId' is not null or undefined
      assertParamExists('getFolderForEdit2', 'folderId', folderId);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/folder/{folderId}/edit`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)))
        .replace(`{${'folderId'}}`, encodeURIComponent(String(folderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFolder: async (
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateFolder', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateFolder', 'sectionId', sectionId);
      // verify required parameter 'folderUpdateRequest' is not null or undefined
      assertParamExists('updateFolder', 'folderUpdateRequest', folderUpdateRequest);
      const localVarPath = `/struct/COMPANY/{businessUnitId}/section/DOCUMENT/{sectionId}/folder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(folderUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFolder1: async (
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateFolder1', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateFolder1', 'sectionId', sectionId);
      // verify required parameter 'folderUpdateRequest' is not null or undefined
      assertParamExists('updateFolder1', 'folderUpdateRequest', folderUpdateRequest);
      const localVarPath = `/struct/PROJECT/{businessUnitId}/section/DOCUMENT/{sectionId}/folder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(folderUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFolder2: async (
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'businessUnitId' is not null or undefined
      assertParamExists('updateFolder2', 'businessUnitId', businessUnitId);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('updateFolder2', 'sectionId', sectionId);
      // verify required parameter 'folderUpdateRequest' is not null or undefined
      assertParamExists('updateFolder2', 'folderUpdateRequest', folderUpdateRequest);
      const localVarPath = `/struct/ALL/{businessUnitId}/section/DOCUMENT/{sectionId}/folder`
        .replace(`{${'businessUnitId'}}`, encodeURIComponent(String(businessUnitId)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-key required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(folderUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FolderControllerApi - functional programming interface
 * @export
 */
export const FolderControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FolderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFolder(
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(
        businessUnitId,
        sectionId,
        folderCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFolder1(
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder1(
        businessUnitId,
        sectionId,
        folderCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFolder2(
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder2(
        businessUnitId,
        sectionId,
        folderCreateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFolder(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFolder1(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder1(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFolder2(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder2(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download a folder icon by the folder id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadIconOfFolder(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIconOfFolder(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFolderById(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderById(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFolderById1(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderById1(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFolderById2(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderById2(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFolderForEdit(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderForEdit(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFolderForEdit1(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderForEdit1(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFolderForEdit2(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderForEdit2(
        businessUnitId,
        sectionId,
        folderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFolder(
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder(
        businessUnitId,
        sectionId,
        folderUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFolder1(
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder1(
        businessUnitId,
        sectionId,
        folderUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFolder2(
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderUpdateRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder2(
        businessUnitId,
        sectionId,
        folderUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FolderControllerApi - factory interface
 * @export
 */
export const FolderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FolderControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFolder(
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .createFolder(businessUnitId, sectionId, folderCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFolder1(
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .createFolder1(businessUnitId, sectionId, folderCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderCreateRequest} folderCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFolder2(
      businessUnitId: number,
      sectionId: number,
      folderCreateRequest: FolderCreateRequest,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .createFolder2(businessUnitId, sectionId, folderCreateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteFolder(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder1(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteFolder1(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFolder2(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteFolder2(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download a folder icon by the folder id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadIconOfFolder(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .downloadIconOfFolder(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderById(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<FolderView> {
      return localVarFp
        .getFolderById(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderById1(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<FolderView> {
      return localVarFp
        .getFolderById1(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get folder by id
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderById2(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<FolderView> {
      return localVarFp
        .getFolderById2(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderForEdit(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .getFolderForEdit(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderForEdit1(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .getFolderForEdit1(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get folder for update
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {number} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFolderForEdit2(
      businessUnitId: number,
      sectionId: number,
      folderId: number,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .getFolderForEdit2(businessUnitId, sectionId, folderId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFolder(
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .updateFolder(businessUnitId, sectionId, folderUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFolder1(
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .updateFolder1(businessUnitId, sectionId, folderUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update folder
     * @param {number} businessUnitId
     * @param {number} sectionId
     * @param {FolderUpdateRequest} folderUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFolder2(
      businessUnitId: number,
      sectionId: number,
      folderUpdateRequest: FolderUpdateRequest,
      options?: any
    ): AxiosPromise<FolderUpdateRequest> {
      return localVarFp
        .updateFolder2(businessUnitId, sectionId, folderUpdateRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * FolderControllerApi - interface
 * @export
 * @interface FolderControllerApi
 */
export interface FolderControllerApiInterface {
  /**
   *
   * @summary Create folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderCreateRequest} folderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  createFolder(
    businessUnitId: number,
    sectionId: number,
    folderCreateRequest: FolderCreateRequest,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Create folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderCreateRequest} folderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  createFolder1(
    businessUnitId: number,
    sectionId: number,
    folderCreateRequest: FolderCreateRequest,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Create folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderCreateRequest} folderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  createFolder2(
    businessUnitId: number,
    sectionId: number,
    folderCreateRequest: FolderCreateRequest,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Delete folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  deleteFolder(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Delete folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  deleteFolder1(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Delete folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  deleteFolder2(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<object>;

  /**
   *
   * @summary Download a folder icon by the folder id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  downloadIconOfFolder(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<any>;

  /**
   *
   * @summary Get folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  getFolderById(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<FolderView>;

  /**
   *
   * @summary Get folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  getFolderById1(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<FolderView>;

  /**
   *
   * @summary Get folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  getFolderById2(businessUnitId: number, sectionId: number, folderId: number, options?: any): AxiosPromise<FolderView>;

  /**
   *
   * @summary Get folder for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  getFolderForEdit(
    businessUnitId: number,
    sectionId: number,
    folderId: number,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Get folder for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  getFolderForEdit1(
    businessUnitId: number,
    sectionId: number,
    folderId: number,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Get folder for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  getFolderForEdit2(
    businessUnitId: number,
    sectionId: number,
    folderId: number,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Update folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderUpdateRequest} folderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  updateFolder(
    businessUnitId: number,
    sectionId: number,
    folderUpdateRequest: FolderUpdateRequest,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Update folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderUpdateRequest} folderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  updateFolder1(
    businessUnitId: number,
    sectionId: number,
    folderUpdateRequest: FolderUpdateRequest,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;

  /**
   *
   * @summary Update folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderUpdateRequest} folderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApiInterface
   */
  updateFolder2(
    businessUnitId: number,
    sectionId: number,
    folderUpdateRequest: FolderUpdateRequest,
    options?: any
  ): AxiosPromise<FolderUpdateRequest>;
}

/**
 * FolderControllerApi - object-oriented interface
 * @export
 * @class FolderControllerApi
 * @extends {BaseAPI}
 */
export class FolderControllerApi extends BaseAPI implements FolderControllerApiInterface {
  /**
   *
   * @summary Create folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderCreateRequest} folderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public createFolder(
    businessUnitId: number,
    sectionId: number,
    folderCreateRequest: FolderCreateRequest,
    options?: any
  ) {
    return FolderControllerApiFp(this.configuration)
      .createFolder(businessUnitId, sectionId, folderCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderCreateRequest} folderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public createFolder1(
    businessUnitId: number,
    sectionId: number,
    folderCreateRequest: FolderCreateRequest,
    options?: any
  ) {
    return FolderControllerApiFp(this.configuration)
      .createFolder1(businessUnitId, sectionId, folderCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderCreateRequest} folderCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public createFolder2(
    businessUnitId: number,
    sectionId: number,
    folderCreateRequest: FolderCreateRequest,
    options?: any
  ) {
    return FolderControllerApiFp(this.configuration)
      .createFolder2(businessUnitId, sectionId, folderCreateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public deleteFolder(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .deleteFolder(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public deleteFolder1(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .deleteFolder1(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public deleteFolder2(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .deleteFolder2(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download a folder icon by the folder id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public downloadIconOfFolder(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .downloadIconOfFolder(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFolderById(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .getFolderById(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFolderById1(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .getFolderById1(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get folder by id
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFolderById2(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .getFolderById2(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get folder for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFolderForEdit(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .getFolderForEdit(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get folder for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFolderForEdit1(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .getFolderForEdit1(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get folder for update
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {number} folderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFolderForEdit2(businessUnitId: number, sectionId: number, folderId: number, options?: any) {
    return FolderControllerApiFp(this.configuration)
      .getFolderForEdit2(businessUnitId, sectionId, folderId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderUpdateRequest} folderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public updateFolder(
    businessUnitId: number,
    sectionId: number,
    folderUpdateRequest: FolderUpdateRequest,
    options?: any
  ) {
    return FolderControllerApiFp(this.configuration)
      .updateFolder(businessUnitId, sectionId, folderUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderUpdateRequest} folderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public updateFolder1(
    businessUnitId: number,
    sectionId: number,
    folderUpdateRequest: FolderUpdateRequest,
    options?: any
  ) {
    return FolderControllerApiFp(this.configuration)
      .updateFolder1(businessUnitId, sectionId, folderUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update folder
   * @param {number} businessUnitId
   * @param {number} sectionId
   * @param {FolderUpdateRequest} folderUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public updateFolder2(
    businessUnitId: number,
    sectionId: number,
    folderUpdateRequest: FolderUpdateRequest,
    options?: any
  ) {
    return FolderControllerApiFp(this.configuration)
      .updateFolder2(businessUnitId, sectionId, folderUpdateRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
