import React, { createContext, useContext, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  CodeLoginRequest,
  CodeLoginResponse,
  CompleteLoginRequest,
  LoginApiFactory,
  LogoutRequest,
  MeControllerApiFactory,
  PhoneLoginRequest,
  PhoneOnlyLoginResponse,
  VendorErrorResponse,
  UserSessionStateView,
  ChangeRoleRequest,
} from 'api/generated';
import { ApiConfiguration } from 'api/http';

import isNil from 'lodash/isNil';
import has from 'lodash/has';

///////////////////////////////////////////////////////////////////////////////
// Api methods used in AuthProvider
///////////////////////////////////////////////////////////////////////////////

const LoginApi = LoginApiFactory(ApiConfiguration);
const MeApi = MeControllerApiFactory(ApiConfiguration);

///////////////////////////////////////////////////////////////////////////////
// AuthProvider's types declaration
///////////////////////////////////////////////////////////////////////////////

// Sum type for AuthProvider value
type AuthProviderType = Authenticated | NotAuthenticated;

// type of AuthProvider value when the user is successfully authenticated
interface Authenticated {
  readonly isAuthenticated: true;
  controllers: AuthControllers;
  sessionId: UserSessionStateView['sessionId'];
  sessionUser: UserSessionStateView['sessionUser'];
  realUser: UserSessionStateView['realUser'];
  role: UserSessionStateView['role'];
  checkAuthenticated: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserSessionStateView, unknown>>;
  status: AuthStatus;
}

// type of AuthProvider value when the user is not authenticated
interface NotAuthenticated {
  readonly isAuthenticated: false;
  controllers: AuthControllers;
  sessionId: undefined;
  sessionUser: undefined;
  realUser: undefined;
  role: undefined;
  checkAuthenticated: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserSessionStateView, unknown>>;
  status: AuthStatus;
}

// type of auth methods provided by backend
interface AuthControllers {
  changeRole: UseMutationResult<UserSessionStateView, AxiosError<unknown>, ChangeRoleRequest>;
  loginPhone: UseMutationResult<
    PhoneOnlyLoginResponse | VendorErrorResponse,
    AxiosError<unknown>,
    PhoneLoginRequest,
    unknown
  >;
  loginCode: UseMutationResult<CodeLoginResponse, AxiosError<unknown>, CodeLoginRequest, unknown>;
  loginComplete: UseMutationResult<UserSessionStateView, AxiosError<unknown>, CompleteLoginRequest, unknown>;
  logout: UseMutationResult<object, AxiosError<unknown>, LogoutRequest | undefined, unknown>;
}

type AuthStatus = 'idle' | 'loading' | 'error' | 'loggedIn' | 'loggedOut';

///////////////////////////////////////////////////////////////////////////////
// Helper methods to check AuthProvider instance type
///////////////////////////////////////////////////////////////////////////////

export function isAuthenticated(value: AuthProviderType): value is Authenticated {
  return value.isAuthenticated;
}

export function isVendorErrorResponse(data: any): data is VendorErrorResponse {
  return has(data, 'errors');
}

export function isNotAuthenticated(value: AuthProviderType): value is NotAuthenticated {
  return !isAuthenticated(value);
}

///////////////////////////////////////////////////////////////////////////////
// constants used in AuthProvider
///////////////////////////////////////////////////////////////////////////////

const AUTHENTICATION_CHECK_INTERVAL = 60 * 1000; // one minute in ms
const AUTHENTICATION_CHECK_RETRY = 2;

///////////////////////////////////////////////////////////////////////////////
// AuthProvider declaration
///////////////////////////////////////////////////////////////////////////////

const AuthContext = createContext<AuthProviderType | null>(null);
AuthContext.displayName = 'Auth';

export function useAuthProvider(): AuthProviderType {
  const contextState = useContext(AuthContext);
  if (isNil(contextState)) {
    throw new Error(`${useAuthProvider.name} must be used within a ${AuthContext.displayName} context`);
  }
  return contextState;
}

interface AuthProviderProps {}
export function AuthProvider(props: React.PropsWithChildren<AuthProviderProps>) {
  const queryClient = useQueryClient();

  const [session, setSession] = useState<UserSessionStateView | undefined>(undefined);
  const [status, setStatus] = useState<AuthStatus>('idle');

  const loginPhoneRef = useRef(
    useMutation<PhoneOnlyLoginResponse | VendorErrorResponse, AxiosError<unknown>, PhoneLoginRequest, unknown>(
      (params: PhoneLoginRequest) => LoginApi.processPhone(params).then(resp => (resp.data as any).data),
      {
        onMutate: () => setStatus('loading'),
        onError: () => setStatus('error'),
      }
    )
  );
  const loginCodeRef = useRef(
    useMutation<CodeLoginResponse | VendorErrorResponse, AxiosError<unknown>, CodeLoginRequest, unknown>(
      (params: CodeLoginRequest) => LoginApi.processCode(params).then(resp => resp.data),
      {
        onMutate: () => setStatus('loading'),
        onError: () => setStatus('error'),
      }
    )
  );
  const loginCompleteRef = useRef(
    useMutation<UserSessionStateView | VendorErrorResponse, AxiosError<unknown>, CompleteLoginRequest, unknown>(
      (params: CompleteLoginRequest) => LoginApi.processComplete(params).then(resp => resp.data),
      {
        onMutate: () => setStatus('loading'),
        onError: () => setStatus('error'),
        onSuccess: () => queryClient.invalidateQueries('me'),
      }
    )
  );

  const logoutRef = useRef(
    useMutation<object, AxiosError<unknown>, LogoutRequest | undefined, unknown>(
      (params?: LogoutRequest) => LoginApi.logout(params || { devices: ['CURRENT'] }),
      {
        onMutate: () => setStatus('loading'),
        onSuccess: () => {
          setSession(undefined);
          setStatus('loggedOut');
          queryClient.clear();
        },
        onError: () => {
          setSession(undefined);
          setStatus('error');
        },
      }
    )
  );

  const changeRoleRef = useRef(
    useMutation<UserSessionStateView, AxiosError<unknown>, ChangeRoleRequest>(
      (params: ChangeRoleRequest) => MeApi.changeRole(params).then(resp => resp.data),
      {
        onSuccess: session => {
          setSession(session);
          queryClient.refetchQueries();
        },
      }
    )
  );

  const { refetch: checkAuthenticated } = useQuery('me', () => MeApi.getMe().then(resp => resp.data), {
    retry: AUTHENTICATION_CHECK_RETRY,
    refetchInterval: AUTHENTICATION_CHECK_INTERVAL,
    onSuccess: session => {
      setSession(session);
      setStatus('loggedIn');
    },
    onError: () => {
      setSession(undefined);
      setStatus('error');
    },
  });

  const controllers = {
    changeRole: changeRoleRef.current,
    loginPhone: loginPhoneRef.current,
    loginCode: loginCodeRef.current,
    loginComplete: loginCompleteRef.current,
    logout: logoutRef.current,
  };

  let value: AuthProviderType;
  if (isNil(session)) {
    value = {
      controllers,
      sessionUser: undefined,
      realUser: undefined,
      sessionId: undefined,
      role: undefined,
      checkAuthenticated,
      isAuthenticated: false,
      status,
    } as NotAuthenticated;
  } else {
    value = {
      controllers,
      sessionUser: session.sessionUser,
      realUser: session.realUser,
      sessionId: session.sessionId,
      role: session.role,
      checkAuthenticated,
      isAuthenticated: true,
      status,
    } as Authenticated;
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
