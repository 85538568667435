import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import {
  QueryObserverResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { ApiConfiguration } from 'api/http';
import {
  PersonFolderControllerApiFactory,
  PersonFolderMultiUpdateRequest,
  ProcessPersonFoldersRequest,
} from 'api/generated';

import { RESOURCE_NAME } from './constants';
import { RESOURCE_NAME as SECTION_RESOURCE_NAME } from '../SectionsProviders/constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { omit } from 'lodash';

const PersonFoldersApi = PersonFolderControllerApiFactory(ApiConfiguration);
export type UpdatePersonFoldersDataType = PersonFolderMultiUpdateRequest;
export type UpdatePersonFoldersVariablesType = PersonFolderMultiUpdateRequest & {
  earlyErrorHandler?: (e: any) => PersonFolderMultiUpdateRequest;
};
type PersonFoldersProviderType = {
  businessUnitId: number;
  personId?: number;
  controllers: {
    folders: QueryObserverResult<ProcessPersonFoldersRequest, AxiosError<unknown>>;
    updatePersonFolders: UseMutationResult<
      UpdatePersonFoldersDataType,
      AxiosError<unknown>,
      UpdatePersonFoldersVariablesType
    >;
  };
};

const PersonFoldersContext = createContext<PersonFoldersProviderType | null>(null);
PersonFoldersContext.displayName = `${upperFirst(RESOURCE_NAME)}Membership`;

export function usePersonFoldersProvider(): PersonFoldersProviderType {
  const contextState = useContext(PersonFoldersContext);
  if (isNil(contextState)) {
    throw new Error(
      `${usePersonFoldersProvider.name} must be used within a ${PersonFoldersContext.displayName} context`
    );
  }
  return contextState;
}

interface PersonFoldersProviderProps {
  businessUnitId: number;
  personId?: number;
  queryOptions?: Pick<UseQueryOptions, 'onError'>;
}

export function PersonFoldersProvider(props: React.PropsWithChildren<PersonFoldersProviderProps>) {
  const queryClient = useQueryClient();

  const folders = useQuery<ProcessPersonFoldersRequest, AxiosError<unknown>>(
    [RESOURCE_NAME, { businessUnitId: props.businessUnitId, personId: props.personId }, 'groupMembers'],
    () => {
      if (isNil(props.personId)) {
        throw new Error(`${PersonFoldersProvider.name} is missed 'personId' property`);
      }
      return PersonFoldersApi.getAllPersonFolders(props.businessUnitId, props.personId).then(resp => resp.data);
    },
    { enabled: !isNil(props.personId), ...(props.queryOptions || {}) }
  );

  const updatePersonFolders = useMutation<
    UpdatePersonFoldersDataType,
    AxiosError<unknown>,
    UpdatePersonFoldersVariablesType
  >(
    async data => {
      const earlyErrorHandler = data.earlyErrorHandler;
      return PersonFoldersApi.multiUpdatePersonFolders(props.businessUnitId, omit(data, 'earlyErrorHandler'))
        .then(resp => resp.data)
        .catch(
          earlyErrorHandler ||
            (e => {
              throw e;
            })
        );
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries([SECTION_RESOURCE_NAME, props.businessUnitId]);
        queryClient.invalidateQueries([
          RESOURCE_NAME,
          { businessUnitId: props.businessUnitId, personId: props.personId },
          'groupMembers',
        ]);
      },
      ...(props.queryOptions || {}),
    }
  );

  return (
    <PersonFoldersContext.Provider
      value={{
        businessUnitId: props.businessUnitId,
        personId: props.personId,
        controllers: {
          folders,
          updatePersonFolders,
        },
      }}
    >
      {props.children}
    </PersonFoldersContext.Provider>
  );
}
