export type Filter<TValue> = (val: TValue, preVal: TValue) => TValue;

export function numbersFilter() {
  return (val: string) => val.replace(/\D/g, '');
}

export function maxLengthFilter(maxSize: number) {
  return (val: string) => val.substring(0, maxSize);
}

export const composeFilters = <TValue>(...filters: Filter<TValue>[]) => {
  return (val: TValue, preVal: TValue) => {
    for (const filter of filters) {
      const newVal = filter(val, preVal);
      preVal = val;
      val = newVal;
    }
    return val;
  };
};
