import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function InfoIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="160" height="160" viewBox="0 0 160 160" fill="none" {...props}>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9992 15.332C44.2839 15.332 15.3325 44.2898 15.3325 79.9987C15.3325 115.721 44.2836 144.665 79.9992 144.665C115.715 144.665 144.666 115.721 144.666 79.9987C144.666 44.2898 115.714 15.332 79.9992 15.332ZM11.3325 79.9987C11.3325 42.0809 42.0745 11.332 79.9992 11.332C117.924 11.332 148.666 42.0809 148.666 79.9987C148.666 117.93 117.924 148.665 79.9992 148.665C42.0747 148.665 11.3325 117.93 11.3325 79.9987Z"
        fill="#E3E4EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8333 54.7376C85.8333 57.951 83.2266 60.571 80.0333 60.571C76.7466 60.571 74.1333 57.951 74.1333 54.7376C74.1333 51.5243 76.7466 48.9043 79.9666 48.9043C83.2 48.9043 85.8333 51.5243 85.8333 54.7376ZM74.1998 75.8896C74.1998 72.6763 76.8198 70.0563 80.0332 70.0563C83.2465 70.0563 85.8665 72.6763 85.8665 75.8896V105.356C85.8665 108.57 83.2465 111.19 80.0332 111.19C76.8198 111.19 74.1998 108.57 74.1998 105.356V75.8896Z"
        fill="#E3E4EB"
      />
    </SvgIcon>
  );
}

export default InfoIcon;
