import React from 'react';

import { useTranslation } from 'react-i18next';
import { Form, FormProps } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { PhoneField } from 'components/fields';
import { required } from 'components/fields/validators';
import { Spinner } from 'components/material';

export function EnterPhoneForm({ onSubmit, loading }: { onSubmit: FormProps['onSubmit']; loading: boolean }) {
  const { t } = useTranslation();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      render={formProps => (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Box mt={3} fontSize="h4.fontSize" fontWeight="bold">
            {t('login:authorization')}
          </Box>
          <Box mt={3} fontSize="subtitle1.fontSize">
            {t('login:enterPhoneNumber')}
          </Box>
          <Box width="100%">
            <PhoneField
              name="phone"
              margin="dense"
              fieldProps={{ validate: required }}
              autoFocus
              fullWidth
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  formProps.handleSubmit();
                  event.preventDefault();
                }
              }}
            />
          </Box>
          <Box mt={4} width="100%">
            <Button
              color="primary"
              variant="contained"
              disableElevation
              disabled={formProps.submitting}
              onClick={formProps.form.submit}
              fullWidth
            >
              {t('login:next')}
            </Button>
          </Box>
        </Box>
      )}
    />
  );
}
