import React, { createContext, useContext } from 'react';

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { DocumentControllerApiFactory } from 'api/generated';
import { ApiConfiguration } from 'api/http';

import { RESOURCE_NAME } from './constants';

import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

const DocumentApi = DocumentControllerApiFactory(ApiConfiguration);

export interface DocumentDeleteRequest {
  documentId?: number;
}

type DocumentDeleteProviderType = UseMutationResult<object, AxiosError<unknown>, DocumentDeleteRequest, unknown>;

const DocumentDeleteContext = createContext<DocumentDeleteProviderType | null>(null);
DocumentDeleteContext.displayName = `${upperFirst(RESOURCE_NAME)}Delete`;

export function useDocumentDeleteProvider(): DocumentDeleteProviderType {
  const contextState = useContext(DocumentDeleteContext);
  if (isNil(contextState)) {
    throw new Error(
      `${useDocumentDeleteProvider.name} must be used within a ${DocumentDeleteContext.displayName} context`
    );
  }
  return contextState;
}

interface DocumentDeleteProviderProps {
  businessUnitId: number;
  sectionId: number;
  documentId: number;
  queryOptions?: UseMutationOptions<object, AxiosError<unknown>, DocumentDeleteRequest, unknown>;
}
export function DocumentDeleteProvider(props: React.PropsWithChildren<DocumentDeleteProviderProps>) {
  const queryClient = useQueryClient();
  const removeDefaultOptions: DocumentDeleteProviderProps['queryOptions'] = {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE_NAME);
    },
  };
  const remove = useMutation<object, AxiosError<unknown>, DocumentDeleteRequest>(
    async ({ documentId }: DocumentDeleteRequest) => {
      return DocumentApi.deleteDocumentById2(
        props.businessUnitId,
        props.sectionId,
        documentId || props.documentId
      ).then(resp => resp.data);
    },
    {
      ...removeDefaultOptions,
      ...(props.queryOptions || {}),
    }
  );
  return <DocumentDeleteContext.Provider value={remove}>{props.children}</DocumentDeleteContext.Provider>;
}
